import React, { Component } from 'react';
import { connect } from 'react-redux';

class Dashboard extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
		};
	}
	
	render() {
		return <div className='flex flex-col flex-grow dashboard'>
			<div className='text-2xl text-center pt-4 font-thin'>Nadzorna plošča</div>
			
			<div className='flex-1 dashboard-grid'>
				<div className='dashboard-item dashboard-item-1 shadow-md'>
					<div className='dashboard-item-title'>
						Opomniki
					</div>
					<div className='stats-counter'>
						{[ 'test', 'lodgge' ].indexOf(this.props.client) === -1 ? null :
							<>
								<div className='flex flex-row mb-1'>
									<div className='mr-4'>11.10.</div>
									<div className='mr-2'>AP 100</div>
									<div className='flex-1 text-right'>Zamenjaj žarnico</div>
								</div>
								<div className='flex flex-row mb-1'>
									<div className='mr-4'>11.10.</div>
									<div className='mr-2'>AP 100</div>
									<div className='flex-1 text-right'>Zamenjaj WC školjko</div>
								</div>
							</>
						}
					</div>
				</div>
				<div className='dashboard-item dashboard-item-2 shadow-md'>
					<div className='dashboard-item-title'>
						Prijave
					</div>
					<div className='stats-counter'>
						{[ 'test', 'lodgge' ].indexOf(this.props.client) === -1 ? null :
							<>
								<div className='mb-8'>
									<div className='mb-4'>
										<div className='flex flex-row border-b mb-2'>
											<div>Torek, 11.10.</div>
											<div className='flex-1 text-right'>6 oseb</div>
										</div>
										<div className='pl-4'>
											<div className='flex flex-row mb-1'>
												<div className='mr-2'>4 osebe</div>
												<div className='flex-1 text-right'>Matej Vertelj, soba 102</div>
												<div className='ml-2 w-8 text-right'><img src='images/flags/si.svg' style={{ display: 'inline-block', height: 12 }} /></div>
											</div>
											<div className='flex flex-row mb-1'>
												<div className='mr-2'>2 osebi</div>
												<div className='flex-1 text-right'>Miha Vertelj, soba 99</div>
												<div className='ml-2 w-8 text-right'><img src='images/flags/si.svg' style={{ display: 'inline-block', height: 12 }} /></div>
											</div>
										</div>
									</div>
									<div className='mb-4'>
										<div className='flex flex-row border-b mb-2'>
											<div>Sreda, 12.10.</div>
											<div className='flex-1 text-right'>4 osebe</div>
										</div>
										<div className='pl-4'>
											<div className='flex flex-row mb-1'>
												<div className='mr-2'>4 osebe</div>
												<div className='flex-1 text-right'>Matej Vertelj, soba 23</div>
												<div className='ml-2 w-8 text-right'><img src='images/flags/at.svg' style={{ display: 'inline-block', height: 12 }} /></div>
											</div>
										</div>
									</div>
								</div>
							</>
						}
						<div className='mb-4 opacity-50'>
							{[ 'test', 'lodgge' ].indexOf(this.props.client) === -1 ? null :
								<>
									<div className='border-b mb-2 text-center'>
										Že prijavljeni
									</div>
									<div className='flex flex-row mb-1'>
										<div className='w-24'>8.11. ob 11:45</div>
										<div className='mr-2'>4 osebe</div>
										<div className='flex-1 text-right'>Matej Vertelj, soba 102</div>
										<div className='ml-2 w-8 text-right'><img src='images/flags/si.svg' style={{ display: 'inline-block', height: 12 }} /></div>
									</div>
									<div className='flex flex-row mb-1'>
										<div className='w-24'>7.11. ob 16:14</div>
										<div className='mr-2'>2 osebi</div>
										<div className='flex-1 text-right'>Miha Vertelj, soba 99</div>
										<div className='ml-2 w-8 text-right'><img src='images/flags/si.svg' style={{ display: 'inline-block', height: 12 }} /></div>
									</div>
								</>
							}
						</div>
					</div>
				</div>
				<div className='dashboard-item dashboard-item-3 shadow-md'>
					<div className='dashboard-item-title'>
						Odjave
					</div>
					<div className='stats-counter'>
						{[ 'test', 'lodgge' ].indexOf(this.props.client) === -1 ? null :
							<>
								<div className='mb-8'>
									<div className='mb-4'>
										<div className='flex flex-row border-b mb-2'>
											<div>Torek, 11.10.</div>
											<div className='flex-1 text-right'>6 oseb</div>
										</div>
										<div className='pl-4'>
											<div className='flex flex-row mb-1'>
												<div className='mr-2'>4 osebe</div>
												<div className='flex-1 text-right'>Matej Vertelj, soba 102, 12:00</div>
												<div className='ml-2 w-8 text-right'><img src='images/flags/si.svg' style={{ display: 'inline-block', height: 12 }} /></div>
											</div>
											<div className='flex flex-row mb-1'>
												<div className='mr-2'>2 osebi</div>
												<div className='flex-1 text-right'>Miha Vertelj, soba 102, 12:00</div>
												<div className='ml-2 w-8 text-right'><img src='images/flags/si.svg' style={{ display: 'inline-block', height: 12 }} /></div>
											</div>
										</div>
									</div>
									<div className='mb-4'>
										<div className='flex flex-row border-b mb-2'>
											<div>Sreda, 12.10.</div>
											<div className='flex-1 text-right'>4 osebe</div>
										</div>
										<div className='pl-4'>
											<div className='flex flex-row mb-1'>
												<div className='mr-2'>4 osebe</div>
												<div className='flex-1 text-right'>Matej Vertelj, soba 102, 12:00</div>
												<div className='ml-2 w-8 text-right'><img src='images/flags/at.svg' style={{ display: 'inline-block', height: 12 }} /></div>
											</div>
										</div>
									</div>
								</div>
								<div className='mb-4 opacity-50'>
									<div className='border-b mb-2 text-center'>
										Že odjavljeni
									</div>
									<div className='flex flex-row mb-1'>
										<div className='w-24'>8.11. ob 11:45</div>
										<div className='mr-2'>4 osebe</div>
										<div className='flex-1 text-right'>Matej Vertelj, soba 102</div>
										<div className='ml-2 w-8 text-right'><img src='images/flags/si.svg' style={{ display: 'inline-block', height: 12 }} /></div>
									</div>
									<div className='flex flex-row mb-1'>
										<div className='w-24'>7.11. ob 16:14</div>
										<div className='mr-2'>2 osebi</div>
										<div className='flex-1 text-right'>Miha Vertelj, soba 102</div>
										<div className='ml-2 w-8 text-right'><img src='images/flags/si.svg' style={{ display: 'inline-block', height: 12 }} /></div>
									</div>
								</div>
							</>
						}
					</div>
				</div>
			</div>
		</div>;
	}
}

function mapStateToProps(state) {
	return {
		client: state.ConstantsSlice.client,
	};
}

export default connect(mapStateToProps)(Dashboard);
