import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import {
	Button,
	ButtonGroup,
	Checkbox,
	RadioGroup,
	Radio,
	Alert,
	Intent,
} from '@blueprintjs/core';

import { increaseInvoicePrintCount, loadInvoiceFiscalVerification } from '../../api/Business';
import DateRangePickerDialog from '../DateRangePickerDialog';

import { enqueueInvoiceOnA4PrinterQueue } from '../../slices/AppSlice';

import { ResultHeader, ResultHeaderWithSort, Result, MultiSelectFilter, DateRangeFilter, TextFilter } from '../code_tables/ResultComponents';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		tableItemsFilters,
		originalTableItemsFilters,
		handleFilterChange,
		customers,
		table_items_checked_ids,
		rowCheckStateChanged,
		allRowsCheckStateChanged,
		findMatchingAdvanceConsumptions,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	const toIsoString = date => {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	};
	
	const toLocalString = date => {
		if (date === null || date === undefined) return '';
		
		if (!(date instanceof Date)) return date;
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear();
	};
	
	const check_count = table_items_sort_ids.filter(id => table_items_checked_ids[id] === true).length;
	const all_checked = table_items_sort_ids.length == check_count;
	
	const invoice_types = {
		'prepayment-invoice': { ord: 1, visible: true, key: 'prepayment-invoice', title: 'Avansni račun' },
	};
	
	let sum_amount = 0;
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table'>
				<ResultHeader columnIdx={1} title={
					<Checkbox
						checked={check_count > 0}
						indeterminate={!all_checked && check_count > 0}
						onChange={event => allRowsCheckStateChanged(event.target.checked)} />
				} />
				<ResultHeader columnIdx={2} title='Urejanje' />
				<ResultHeaderWithSort
					columnIdx={3} {...sort_props} title='Št. računa' fieldName='invoice_number_internal' />
				<ResultHeaderWithSort
					columnIdx={4} {...sort_props} title='Šifra' fieldName='customer_internal_code' />
				<ResultHeaderWithSort
					columnIdx={5} {...sort_props} title='Naziv stranke' fieldName='id_customer' />
				<ResultHeader columnIdx={6} title='Datum dokumenta' />
				<ResultHeaderWithSort
					columnIdx={7} {...sort_props} title='Datum DDV' fieldName='vat_date' />
				<ResultHeader columnIdx={8} title='Vrsta dokumenta' />
				<ResultHeader columnIdx={9} title='Osnova' />
				<ResultHeader columnIdx={10} title='Davek' />
				<ResultHeader columnIdx={11} title='Neobdavčeno' />
				<ResultHeader columnIdx={12} title='Skupaj' />
			</div>
			
			<div className='results-table filters-row'>
				<Result columnIdx={1} />
				<Result columnIdx={2} />
				<Result columnIdx={3} child={
					<TextFilter
						value={tableItemsFilters.invoice_number_internal}
						onChange={ event => handleFilterChange('invoice_number_internal', event) } />
				} />
				<Result columnIdx={4} child={
					<TextFilter
						value={tableItemsFilters.customer_internal_code}
						onChange={ event => handleFilterChange('customer_internal_code', event) } />
				} />
				<Result columnIdx={5} child={
					<TextFilter
						value={tableItemsFilters.customer_name}
						onChange={ event => handleFilterChange('customer_name', event) } />
				} />
				<Result columnIdx={6} />
				<Result columnIdx={7} child={
					<DateRangeFilter
						filter_values={tableItemsFilters.payment_date_range}
						original_start_date={originalTableItemsFilters.payment_date_range[0]}
						original_end_date={originalTableItemsFilters.payment_date_range[1]}
						onFilterChange={values => handleFilterChange('payment_date_range', values)}
						disable_from={tableItemsFilters.payment_date_range_disabled} />
				} />
				<Result columnIdx={8} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='key'
							title_field    ='title'
							items          ={invoice_types}
							filtered_keys  ={tableItemsFilters.invoice_types}
							displayFilter  ={x => x.visible}
							displaySort    ={(a, b) => a.ord - b.ord}
							onFilterChange ={values => handleFilterChange('invoice_types', values)} />
					</div>
				} />
				<Result columnIdx={9} />
				<Result columnIdx={10} />
				<Result columnIdx={11} />
				<Result columnIdx={12} />
			</div>
			
			{table_items_sort_ids.map((id, idx) => {
				const item         = table_items[id];
				const invoice_date = (item.invoice_date === null ? null : new Date(item.invoice_date));
				
				let payment_date = item.payment_date === null ? null : new Date(item.payment_date);
				let vat_date     = null;
				if (item.invoice_type == 'prepayment-invoice') {
					vat_date = payment_date;
				}
				
				const matching_advance_consumptions = findMatchingAdvanceConsumptions(
					item.id_invoice,
					tableItemsFilters.payment_date_range_disabled || tableItemsFilters.payment_date_range[0] === null ? null : toIsoString(tableItemsFilters.payment_date_range[0]),
					tableItemsFilters.payment_date_range[1] === null ? null : toIsoString(tableItemsFilters.payment_date_range[1]),
					tableItemsFilters.type,
					true
				);
				const matching_advance_consumptions_amount_sum =
					matching_advance_consumptions
						.map(x => x.amount)
						.reduce((prev, curr) => prev + curr, 0);
				
				const invoice_amount = tableItemsFilters.type == 'all' ?
					parseFloat(item.advance_payment_amount) :
					(tableItemsFilters.type == 'used' || tableItemsFilters.type == 'used-cash' ?
						-matching_advance_consumptions_amount_sum
						:
						(parseFloat(item.advance_payment_amount) - matching_advance_consumptions_amount_sum)
					);
				
				sum_amount += invoice_amount;
				
				return <div
					className='results-table'
					key={'result--result-' + id}>
					<Result columnIdx={1} child={
						<Checkbox
							checked={table_items_checked_ids[id] === true}
							onChange={event => rowCheckStateChanged(id, event.target.checked)} />
					} />
					<Result columnIdx={2} child={
						null
					} />
					<Result columnIdx={3} child={
						item.draft ? 'osnutek' : item.invoice_number_internal
					} />
					<Result columnIdx={4} child={
						customers[item.id_customer] === undefined ? '' :
							customers[item.id_customer].internal_code
					} />
					<Result columnIdx={5} child={
						customers[item.id_customer] === undefined ? '' :
							(customers[item.id_customer].type == 'natural' ?
								customers[item.id_customer].surname + ' ' + customers[item.id_customer].name
								:
								customers[item.id_customer].company_name)
					} />
					<Result columnIdx={6} child={
						toLocalString(invoice_date)
					} />
					<Result columnIdx={7} child={
						toLocalString(vat_date)
					} />
					<Result columnIdx={8} child={
						invoice_types[item.invoice_type].title
					} />
					<Result columnIdx={9} cls='text-right' child={
						''
					} />
					<Result columnIdx={10} cls='text-right' child={
						''
					} />
					<Result columnIdx={11} cls='text-right' child={
						''
					} />
					<Result columnIdx={12} cls='text-right font-bold col-invoice-amount' child={
						invoice_amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
					} />
				</div>;
			})}
		</div>
		<div className='results-sum pb-4'>
			<div className='col'>
				Število zapisov: {table_items_sort_ids.length}
			</div>
			<div className='col text-right font-bold'>
				{
					sum_amount
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
				}
			</div>
		</div>
	</>;
}

class PrepaymentBookItems extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.updateTableItemsFromProps  = this.updateTableItemsFromProps .bind(this);
		this.setTableItemsSort          = this.setTableItemsSort         .bind(this);
		this.refreshTableItemsSort      = this.refreshTableItemsSort     .bind(this);
		this.filterTableItems           = this.filterTableItems          .bind(this);
		this.handleFilterChange         = this.handleFilterChange        .bind(this);
		this.openDateRangePickerDialog  = this.openDateRangePickerDialog .bind(this);
		this.closeDateRangePickerDialog = this.closeDateRangePickerDialog.bind(this);
		this.printA4                    = this.printA4                   .bind(this);
		this.exportToExcel              = this.exportToExcel             .bind(this);
		this.findMatchingAdvanceConsumptions = this.findMatchingAdvanceConsumptions.bind(this);
		this.showDraftAlertIfNeeded     = this.showDraftAlertIfNeeded    .bind(this);
		
		const table_items_filters = {
			invoice_number_internal: '',
			customer_internal_code:  '',
			customer_name:           '',
			invoice_date_range:      [],
			payment_date_range:      [
				moment().subtract(1, 'month').set('date', 1).toDate(),
				moment().set('date', 1).subtract(1, 'day').toDate(),
			],
			payment_date_range_disabled: false,
			invoice_types:           [],
			type:                    'all',
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'invoice_number_internal',
			table_items_sort_direction: 'DESC',
		});
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'invoice_number_internal',
			table_items_sort_direction: 'DESC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			table_items_checked_ids: {},
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			add_edit_item_dialog_type: null,
			add_edit_item_dialog_advance_invoices_amounts: {},
			date_range_picker_dialog_open:        false,
			date_range_picker_dialog_filter_name: null,
			delete_item_dialog_open: false,
			delete_item_id_item:     null,
			create_invoice_from_ids:              null,
			create_invoice_from_ids_invoice_type: null,
			wait_fiscal_verification_dialog_open:       false,
			wait_fiscal_verification_dialog_id_invoice: null,
			draft_invoices_warning_open: false,
			draft_invoices_warning_callback: null,
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.invoices != this.state.invoices) {
			this.setState(
				this.updateTableItemsFromProps(this.props, this.state)
			);
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const filtered_invoices = {};
		const invoice_types = [
			'prepayment-invoice',
		];
		for (let id_invoice in props.invoices) {
			const invoice = props.invoices[id_invoice];
			
			if (invoice_types.indexOf(invoice.invoice_type) == -1) continue;
			
			filtered_invoices[invoice.id_invoice] = invoice;
		}
		
		const ids = Object.keys(filtered_invoices);
		
		let sort_ids = [];
		
		if (state !== undefined && state.table_items_sort_ids !== undefined) {
			for (let i=0; i<state.table_items_sort_ids.length; i++) {
				const id = state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const new_state = {
			invoices: props.invoices,
			table_items: filtered_invoices,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
			table_items_sort_field: state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a   = '';
			let b   = '';
			let dir = direction;
			
			if (state.table_items[a_key].draft != state.table_items[b_key].draft) {
				a = state.table_items[a_key].draft;
				b = state.table_items[b_key].draft;
				dir = 'DESC';
			}
			else if (field == 'id_customer') {
				const customer_a = this.props.customers[state.table_items[a_key].id_customer];
				const customer_b = this.props.customers[state.table_items[b_key].id_customer];
				
				if (customer_a === undefined && customer_b === undefined) {
					a = 0;
					b = 0;
				}
				else {
					a = customer_a === undefined ? '' : customer_a.name + ' ' + customer_a.surname;
					b = customer_b === undefined ? '' : customer_b.name + ' ' + customer_b.surname;
				}
				
				return a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'customer_internal_code') {
				const customer_a = this.props.customers[state.table_items[a_key].id_customer];
				const customer_b = this.props.customers[state.table_items[b_key].id_customer];
				
				if (customer_a === undefined && customer_b === undefined) {
					a = 0;
					b = 0;
				}
				else {
					a = customer_a === undefined ? 0 : customer_a.internal_code;
					b = customer_b === undefined ? 0 : customer_b.internal_code;
				}
			}
			else {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	toIsoString(date) {
		if (date === undefined) return null;
		
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_invoice_number_internal = filters.invoice_number_internal.toUpperCase();
		const filters_customer_internal_code  = filters.customer_internal_code .toUpperCase();
		const filters_customer_name           = filters.customer_name          .toUpperCase();
		const filters_invoice_date_start      = filters.invoice_date_range[0] === null ? null : this.toIsoString(filters.invoice_date_range[0]);
		const filters_invoice_date_end        = filters.invoice_date_range[1] === null ? null : this.toIsoString(filters.invoice_date_range[1]);
		const filters_payment_date_start      = filters.payment_date_range[0] === null ? null : this.toIsoString(filters.payment_date_range[0]);
		const filters_payment_date_end        = filters.payment_date_range[1] === null ? null : this.toIsoString(filters.payment_date_range[1]);
		const filters_payment_date_disabled   = filters.payment_date_range_disabled;
		const filters_invoice_types           = filters.invoice_types;
		const filter_used_unused              = filters.type;
		
		const draft_invoices = {};
		
		const ids = Object.keys(state.table_items);
		let filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			const matching_advance_consumptions = this.findMatchingAdvanceConsumptions(
				item.id_invoice,
				filters_payment_date_disabled === true ? null : filters_payment_date_start,
				filters_payment_date_end,
				filter_used_unused,
				true
			);
			const matching_advance_consumptions_amount_sum =
				matching_advance_consumptions
					.map(x => x.amount)
					.reduce((prev, curr) => prev + curr, 0);
			
			
//if (item.id_invoice == 'acbf49d9-3e62-4ce4-b8bf-4f490d6c8687') { // item.invoice_number_internal == '21-300-0412') {
if (item.id_invoice == '8d3b0b2e-3c55-45b6-9e96-f4be2e5d09f5') {
	console.log({
		item,
		matching_advance_consumptions,
	});
	console.log({
		advances_by_consumer: this.findMatchingAdvanceConsumptions(
			item.id_invoice,
			filters_payment_date_disabled === true ? null : filters_payment_date_start,
			filters_payment_date_end,
			filter_used_unused,
			false
		),
	});
	//matching_advance_consumptions_amount_sum = 0;
}
			
			/*const advances_by_consumer = this.findMatchingAdvanceConsumptions(
				item.id_invoice,
				filters_payment_date_disabled === true ? null : filters_payment_date_start,
				filters_payment_date_end,
				filter_used_unused,
				false
			);
			const matching_advance_consumptions_by_consumer_amount_sum =
				advances_by_consumer
					.map(x => x.amount)
					.reduce((prev, curr) => prev + curr, 0);*/
			
			// hide reverse prepayment invoices from all lists
			if (filter_used_unused != 'all' && item.reverse_invoice === true) {
				return false;
			}
			
			// hide reverse prepayment invoices from all lists, except all
			if (
				filter_used_unused != 'all' &&
				item.invoice_type == 'prepayment-invoice' &&
				(item.reverse_invoice === true /*|| item.reversed === true*/)
			) {
				// only exclude fully reversed invoices
				//if (filter_used_unused != 'unused' && item.reversed) {
				//	if (item.advance_payment_amount - matching_advance_consumptions_amount_sum /*+ matching_advance_consumptions_by_consumer_amount_sum*/ == 0) {
				//		return false;
				//	}
				//}
				//else {
				//	return false;
				//}
			}
			
			if (filter_used_unused == 'unused' && item.advance_payment_amount - matching_advance_consumptions_amount_sum /*+ matching_advance_consumptions_by_consumer_amount_sum*/ == 0) {
				return false;
			}
			
			if (filters_invoice_number_internal != '' && (item.draft ? 'osnutek' : item.invoice_number_internal).toUpperCase().indexOf(filters_invoice_number_internal) == -1) {
				return false;
			}
			
			if (filters_customer_internal_code != '' && (item.id_customer === null || this.props.customers[item.id_customer] === undefined || this.props.customers[item.id_customer].internal_code != filters_customer_internal_code)) {
				return false;
			}
			
			if (filters_customer_name != '' && (item.id_customer === null || this.props.customers[item.id_customer] === undefined || (this.props.customers[item.id_customer].surname + ' ' + this.props.customers[item.id_customer].name + ' ' + this.props.customers[item.id_customer].company_name).toUpperCase().indexOf(filters_customer_name) == -1)) {
				return false;
			}
			
			if (filters_invoice_date_start !== null && item.invoice_date < filters_invoice_date_start) {
				return false;
			}
			if (filters_invoice_date_end !== null && item.invoice_date > filters_invoice_date_end) {
				return false;
			}
			
			if (filter_used_unused == 'all') {
				if (filters_payment_date_start !== null && item.payment_date < filters_payment_date_start) {
					return false;
				}
				if (filters_payment_date_end !== null && item.payment_date > filters_payment_date_end) {
					return false;
				}
			}
			else if (filter_used_unused == 'unused') {
				if (!filters_payment_date_disabled && filters_payment_date_start !== null && item.payment_date < filters_payment_date_start) {
					return false;
				}
				if (filters_payment_date_end !== null && item.payment_date > filters_payment_date_end) {
					return false;
				}
			}
			else if (filter_used_unused == 'used' || filter_used_unused == 'used-cash') {
				if (matching_advance_consumptions_amount_sum == 0) return false;
				
				if (filters_payment_date_start !== null || filters_payment_date_end !== null) {
					const matching_advance_consumptions = this.findMatchingAdvanceConsumptions(
						item.id_invoice,
						filters_payment_date_start,
						filters_payment_date_end,
						filter_used_unused,
						true
					);
					const payment_date_found = matching_advance_consumptions.length > 0;
					
					if (!payment_date_found) return false;
				}
			}
			
			if (filters_invoice_types.length > 0) {
				let found = false;
				for (let i=0; i<filters_invoice_types.length; i++) {
					if (item.invoice_type == filters_invoice_types[i]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			if (item.draft === true) {
				draft_invoices[item.id_invoice] = item;
			}
			
			return true;
		});
		
		/*if (filter_used_unused == 'all') { //filter_used_unused == 'used' || filter_used_unused == 'used-cash') {
			if (document.location.search != '?test') {
			// add reverse invoices for filtered items above
			const additional_ids = ids.filter(id => {
				const item = state.table_items[id];
				return item.reverse_invoice === true &&
					item.reverse_invoice_reference !== null &&
					filtered_ids.indexOf(item.reverse_invoice_reference) != -1 &&
					filtered_ids.indexOf(item.id_invoice) == -1;
			});
			
			filtered_ids = [ ...filtered_ids, ...additional_ids ];
			}
		}*/
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
			draft_invoices,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	findMatchingAdvanceConsumptions(id_invoice, filters_payment_date_start, filters_payment_date_end, used_unused, by_advance) {
		const results = [];
		
		const advance_consumptions =
			by_advance ?
				this.props.id_advance_invoice_consumption_by_id_advance_invoices[id_invoice]
				:
				this.props.id_advance_invoice_consumption_by_id_consumer_invoices[id_invoice];
		
		if (advance_consumptions === undefined) return results;
		
		for (let i=0; i<advance_consumptions.length; i++) {
			const advance_invoice_consumption = this.props.advance_invoice_consumptions[advance_consumptions[i]];
			if (advance_invoice_consumption === undefined) continue;
			
			const invoice = this.props.invoices[
				by_advance ? advance_invoice_consumption.consumer_id_invoice : advance_invoice_consumption.advance_id_invoice
			];
			if (invoice === undefined) continue;
			
			let found = false;
			// compare to payment date for prepayment invoices
			if (invoice.invoice_type == 'prepayment-invoice') {
				if (
					(([ 'used-cash', 'used' ].indexOf(used_unused) != -1 && invoice.reverse_invoice === false) || [ 'used-cash', 'used' ].indexOf(used_unused) == -1) &&
					invoice.payment_date !== null
				) {
					if (filters_payment_date_start !== null && filters_payment_date_end === null) {
						if (invoice.payment_date >= filters_payment_date_start) {
							found = true;
						}
					}
					else if (filters_payment_date_start === null && filters_payment_date_end !== null) {
						if (invoice.payment_date <= filters_payment_date_end) {
							found = true;
						}
					}
					else {
						if (invoice.payment_date >= filters_payment_date_start && invoice.payment_date <= filters_payment_date_end) {
							found = true;
						}
					}
				}
				
				if (found) {
					results.push({
						dates:  [ invoice.payment_date ],
						amount: parseFloat(advance_invoice_consumption.amount),
					});
				}
			}
			
			// compare to service date range for invoices
			if (
				!found &&
				([ 'used', 'unused', 'all' ].indexOf(used_unused) != -1 && invoice.invoice_type == 'invoice')
			) {
				if (invoice.service_date_start !== null || invoice.service_date_end !== null) {
					const service_date = invoice.service_date_end ?? invoice.service_date_start;
					
					if (filters_payment_date_start !== null && filters_payment_date_end === null) {
						if (filters_payment_date_start <= service_date) {
							found = true;
						}
					}
					else if (filters_payment_date_start === null && filters_payment_date_end !== null) {
						if (filters_payment_date_end >= service_date) {
							found = true;
						}
					}
					else {
						if (filters_payment_date_start <= service_date && filters_payment_date_end >= service_date) {
							found = true;
						}
					}
				}
				
				if (found) {
					results.push({
						dates:  [ invoice.service_date_start, invoice.service_date_end ],
						amount: parseFloat(advance_invoice_consumption.amount),
					});
				}
			}
			
			// compare to service date range for invoices
			if (
				!found &&
				([ 'used-cash', 'unused' ].indexOf(used_unused) != -1 && invoice.invoice_type == 'cash-invoice')
			) {
				if (invoice.invoice_date !== null) {
					if (filters_payment_date_start !== null && filters_payment_date_end === null) {
						if (filters_payment_date_start <= invoice.invoice_date) {
							found = true;
						}
					}
					else if (filters_payment_date_start === null && filters_payment_date_end !== null) {
						if (filters_payment_date_end >= invoice.invoice_date) {
							found = true;
						}
					}
					else {
						if (filters_payment_date_start <= invoice.invoice_date && filters_payment_date_end >= invoice.invoice_date) {
							found = true;
						}
					}
				}
				
				if (found) {
					results.push({
						dates:  [ invoice.invoice_date, invoice.invoice_date ],
						amount: parseFloat(advance_invoice_consumption.amount),
					});
				}
			}
		}
		
		return results;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			invoice_number_internal: this.state.table_items_filters.invoice_number_internal,
			customer_internal_code:  this.state.table_items_filters.customer_internal_code,
			customer_name:           this.state.table_items_filters.customer_name,
			invoice_date_range:      this.state.table_items_filters.invoice_date_range,
			payment_date_range:      this.state.table_items_filters.payment_date_range,
			invoice_types:           this.state.table_items_filters.invoice_types,
			type:                    this.state.table_items_filters.type,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		/*if (field_name == 'type') {
			if (val == 'unused') {
				new_filters.payment_date_range = [
					null,
					moment().subtract(1, 'month').endOf('month').toDate(),
				];
			}
			else {
				new_filters.payment_date_range = [
					moment().subtract(1, 'month').set('date', 1).toDate(),
					moment().subtract(1, 'month').endOf('month').toDate(),
				];
			}
		}*/
		new_filters.payment_date_range_disabled = new_filters.type == 'unused';
		
		this.setState({
			table_items_checked_ids: {},
		});
		
		this.filterTableItems(new_filters);
	}
	
	openDateRangePickerDialog(filter_name) {
		this.setState({
			date_range_picker_dialog_open:        true,
			date_range_picker_dialog_filter_name: filter_name,
		});
	}
	closeDateRangePickerDialog(start_date, end_date) {
		this.setState({
			date_range_picker_dialog_open: false,
		});
		
		if (start_date !== undefined && end_date !== undefined) {
			this.handleFilterChange(
				this.state.date_range_picker_dialog_filter_name,
				[ start_date, (end_date === null ? start_date : end_date) ]
			);
		}
	}
	
	async printA4(lang) {
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		const ids = check_count == 0 ? this.state.table_items_sort_ids :
			this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
		
		for (let i=0; i<ids.length; i++) {
			const invoice = this.state.table_items[ids[i]];
			
			let invoice_fiscal_verification = null;
			if (invoice.id_invoice_fiscal_verification !== null && invoice.id_invoice_fiscal_verification !== undefined) {
				if (this.props.invoice_fiscal_verifications[invoice.id_invoice_fiscal_verification] !== undefined) {
					invoice_fiscal_verification = this.props.invoice_fiscal_verifications[invoice.id_invoice_fiscal_verification];
				}
				else {
					invoice_fiscal_verification = await loadInvoiceFiscalVerification(
						this.props.api_url,
						invoice.id_invoice_fiscal_verification,
						this.props.dispatch,
						this.props.token,
					);
				}
			}
			
			if (
				invoice_fiscal_verification            !== null    &&
				invoice_fiscal_verification.eor        !== null    &&
				invoice_fiscal_verification.signed_zoi !== null    &&
				invoice_fiscal_verification.eor        .length > 0 &&
				invoice_fiscal_verification.signed_zoi .length > 0
			) {
				await increaseInvoicePrintCount(this.props.api_url, ids[i], this.props.token);
			}
			
			this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'invoice', lang, invoice }));
		}
	}
	
	exportToExcel() {
		const ids = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
		
		const params = [];
		const data   = {
			id_invoices: ids.join(','),
		}
		
		/*let is_only_payment_date_filtered = true;
		if (
			this.state.table_items_filters.invoice_number_internal.length > 0
			||
			this.state.table_items_filters.customer_internal_code.length > 0
			||
			this.state.table_items_filters.customer_name.length > 0
			||
			(
				this.state.table_items_filters.invoice_date_range.length == 2 &&
				this.state.table_items_filters.invoice_date_range[0] !== undefined &&
				this.state.table_items_filters.invoice_date_range[1] !== undefined
			)
			||
			this.state.table_items_filters.invoice_types.length > 0
		) {
			is_only_payment_date_filtered = false;
		}
		
		if (is_only_payment_date_filtered && !this.state.table_items_filters.payment_date_range_disabled) {
			if (this.state.table_items_filters.payment_date_range[0] !== null) {
				params.push(
					'date_start=' + moment(this.state.table_items_filters.payment_date_range[0]).format('YYYY-MM-DD')
				);
			}
			if (this.state.table_items_filters.payment_date_range[1] !== null) {
				params.push(
					'date_end=' + moment(this.state.table_items_filters.payment_date_range[1]).format('YYYY-MM-DD')
				);
			}
		}*/
		
		if (!this.state.table_items_filters.payment_date_range_disabled && this.state.table_items_filters.payment_date_range[0] !== null) {
			params.push(
				'date_start=' + moment(this.state.table_items_filters.payment_date_range[0]).format('YYYY-MM-DD')
			);
		}
		if (this.state.table_items_filters.payment_date_range[1] !== null) {
			params.push(
				'date_end=' + moment(this.state.table_items_filters.payment_date_range[1]).format('YYYY-MM-DD')
			);
		}
		
		params.push('type=' + this.state.table_items_filters.type);
		
		const form = document.createElement('form');
		form.target = '_blank';
		form.method = 'POST';
		form.action = this.props.api_url +
			'Invoices/v1/invoice-prepayment-book-export-excel?' + 
			params.join('&');
		form.style.display = 'none';
		
		for (let key in data) {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = data[key];
			form.appendChild(input);
		}
		
		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	}
	
	showDraftAlertIfNeeded(callback) {
		if (Object.values(this.state.draft_invoices ?? {}).length > 0) {
			this.setState({
				draft_invoices_warning_open: true,
				draft_invoices_warning_callback: callback,
			});
		}
		else {
			callback();
		}
	}
	
	render() {
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		return <>
			{!this.state.date_range_picker_dialog_open ? null :
				<DateRangePickerDialog
					startValue={this.state.table_items_filters[this.state.date_range_picker_dialog_filter_name][0]}
					endValue=  {this.state.table_items_filters[this.state.date_range_picker_dialog_filter_name][1]}
					closeDialog={(start_date, end_date) => {
						this.closeDateRangePickerDialog(start_date, end_date);
					}} />
			}
			
			<Alert
				cancelButtonText='Prekliči'
				confirmButtonText='Nadaljuj'
				canEscapeKeyCancel={true}
				canOutsideClickCancel={true}
				icon='warning-sign'
				intent={Intent.WARNING}
				isOpen={this.state.draft_invoices_warning_open}
				onConfirm={() => {
					if (this.state.draft_invoices_warning_callback !== null) {
						this.state.draft_invoices_warning_callback();
					}
					
					this.setState({
						draft_invoices_warning_open: false,
					});
				}}
				onCancel={() => {
					this.setState({
						draft_invoices_warning_open: false,
					});
				}}>
				V izbranem obdobju obstajajo osnutki avansnih računov, ki jih knjiga PA ne upošteva. Prosimo, preverite pravilnost podatkov.
			</Alert>
			
			<div className='flex flex-col flex-grow sales-invoice-book-items-list'>
				<div className='pl-4 pt-4 pr-4 flex justify-between'>
					<div>
						<ButtonGroup className='align-middle'>
							<Button intent='success' icon='plus' onClick={() => this.showDraftAlertIfNeeded(this.exportToExcel)} disabled={check_count == 0}>
								Izvoz v Excel
							</Button>
						</ButtonGroup>
						<ButtonGroup className='ml-2 align-middle'>
							<Button intent='primary' icon='print' onClick={() => this.showDraftAlertIfNeeded(() => this.printA4('sl'))} disabled={true}>
								Natisni
							</Button>
							<Button intent='primary' className='pl-4 pr-4' onClick={() => this.showDraftAlertIfNeeded(() => this.printA4('en'))} disabled={true}>
								EN
							</Button>
							<Button intent='primary' className='pl-4 pr-4' onClick={() => this.showDraftAlertIfNeeded(() => this.printA4('de'))} disabled={true}>
								DE
							</Button>
						</ButtonGroup>
					</div>
					<div className='flex flex-row'>
						<div className='pt-1 pr-4 text-red-600'>
							{Object.values(this.state.draft_invoices ?? {}).length == 0 ? '' : 'V izbranem obdobju obstajajo osnutki avansnih računov, ki jih knjiga PA ne upošteva. Prosimo, preverite pravilnost podatkov.'}
						</div>
						<div className='p-1'>
							<RadioGroup
								onChange={event => this.handleFilterChange('type', event)}
								inline={true}
								selectedValue={this.state.table_items_filters.type}>
								<Radio label='Vsi'                  value='all'       />
								<Radio label='Koriščeni (fakture)'  value='used'      />
								<Radio label='Koriščeni (blagajna)' value='used-cash' />
								<Radio label='Nekoriščeni'          value='unused'    />
							</RadioGroup>
						</div>
					</div>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items={this.state.table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openAddEditItemDialog={this.openAddEditItemDialog}
							tableItemsFilters={this.state.table_items_filters}
							originalTableItemsFilters={this.state.original_table_items_filters}
							handleFilterChange={this.handleFilterChange}
							customers={this.props.customers}
							payment_types={this.props.payment_types}
							selectDateRangeFilter={filter_name => this.openDateRangePickerDialog(filter_name)}
							table_items_checked_ids={this.state.table_items_checked_ids}
							rowCheckStateChanged={(id, state) => {
								let table_items_checked_ids = this.state.table_items_checked_ids;
								
								if (state === false) {
									delete table_items_checked_ids[id];
								}
								else {
									table_items_checked_ids[id] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							allRowsCheckStateChanged={state => {
								const table_items_checked_ids = {};
								for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
									table_items_checked_ids[this.state.table_items_sort_ids[i]] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							deleteItem={this.deleteItem}
							findMatchingAdvanceConsumptions={this.findMatchingAdvanceConsumptions} />
					</div>
				</div>
			</div>
		</>;
	}
}
PrepaymentBookItems.propTypes = {
};

function mapStateToProps(state) {
	return {
		customers:                                              state.CodeTablesSlice.customers,
		payment_types:                                          state.CodeTablesSlice.payment_types,
		invoices:                                               state.BusinessSlice.invoices,
		api_url:                                                state.BusinessSlice.api_url,
		id_advance_invoice_consumption_by_id_advance_invoices:  state.BusinessSlice.id_advance_invoice_consumption_by_id_advance_invoices,
		id_advance_invoice_consumption_by_id_consumer_invoices: state.BusinessSlice.id_advance_invoice_consumption_by_id_consumer_invoices,
		advance_invoice_consumptions:                           state.BusinessSlice.advance_invoice_consumptions,
		token:                                                  state.UserSlice.token,
		current_id_cash_register:                               state.SettingsSlice.current_id_cash_register,
		document_types:                                         state.DocumentSlice.document_types,
		general_settings:                                       state.SettingsSlice.general,
	};
}

export default connect(mapStateToProps)(PrepaymentBookItems);
