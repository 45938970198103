import localStorage from 'local-storage';

const LocalStorageHelper = {
	GetValue: key => {
		let val = localStorage.get(key);
		
		if (key.indexOf('__save_path') != -1 && (val === null || val.trim().length == 0)) {
			val = 'C:\\AssistPRO\\pdf\\';
		}
		else if (key.indexOf('__pos_printer_name') != -1 && (val === null || val.trim().length == 0)) {
			val = 'HTML';
		}
		else if (key.indexOf('__pos_printer_width') != -1 && (val === null || val.trim().length == 0)) {
			val = '48';
		}
		
		return val;
	},
	SetValue: (key, value) => {
		localStorage.set(key, value);
	},
};

export default LocalStorageHelper;
