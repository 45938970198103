import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Intent,
	Navbar,
} from '@blueprintjs/core';

import moment from 'moment';

class AdvanceInvoiceListDialog extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeDialog()}
			className='advance-invoice-list-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Izbor glavnega računa</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='pb-4'>
						<div>
							Izberi račun, iz katerega naj sistem vzame podatke.
						</div>
						<div>
							Ostali računi se bodo uporabili samo za znesek predplačila.
						</div>
					</div>
					<div className='advance-invoice-list'>
						<div className='advance-invoice-list-table floating-header'>
							<div className='advance-invoice-list-table-header-col col-1'>
								Datum
							</div>
							<div className='advance-invoice-list-table-header-col col-2'>
								Št. dokumenta
							</div>
							<div className='advance-invoice-list-table-header-col col-3'>
								Tip
							</div>
							<div className='advance-invoice-list-table-header-col col-4'>
								Stranka
							</div>
							<div className='advance-invoice-list-table-header-col col-5 text-right'>
								Znesek
							</div>
							<div className='advance-invoice-list-table-header-col col-6 text-right'>
								Neizkoriščen znesek
							</div>
							<div className='advance-invoice-list-table-header-col col-7'></div>
						</div>
						<div className='advance-invoice-list-table'>
							{this.props.invoices.map(invoice_item => {
								const document_date            = invoice_item.invoice_date;
								const document_document_number = invoice_item.invoice_number_internal;
								
								let document_amount     = invoice_item.invoice_amount;
								let document_type_title = 'račun';
								
								switch (invoice_item.invoice_type) {
									case 'cash-invoice':
										document_type_title = 'blagajniški račun';
										break;
									case 'offer':
										document_type_title = 'ponudba';
										break;
									case 'credit-note':
										document_type_title = 'dobropis';
										break;
									case 'prepayment-invoice':
										document_type_title = 'avansni račun';
										document_amount     = invoice_item.advance_payment_amount;
										break;
								}
								
								const document_customer = invoice_item.id_customer === null ? null :
									this.props.customers[invoice_item.id_customer];
								
								return <React.Fragment key={'invoice-' + invoice_item.id_invoice}>
									<div className='advance-invoice-list-table-col col-1'>
										{document_date === null ? null : moment(document_date).format('DD.MM.YYYY')}
									</div>
									<div className='advance-invoice-list-table-col col-2'>
										{document_document_number}
									</div>
									<div className='advance-invoice-list-table-col col-3'>
										{document_type_title}
									</div>
									<div className='advance-invoice-list-table-col col-4'>
										{document_customer === null ? null :
											(document_customer.type == 'natural' ?
												document_customer.surname + ' ' + document_customer.name
												:
												document_customer.company_name
											)
										}
									</div>
									<div className='advance-invoice-list-table-col col-5 text-right'>
										{document_amount === null ? null :
											parseFloat(document_amount)
												.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) +
												' EUR'
										}
									</div>
									<div className='advance-invoice-list-table-col col-6 text-right'>
										{
											(parseFloat(invoice_item.advance_payment_amount) - parseFloat(invoice_item.used_advance_payment_amount))
												.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) +
												' EUR'
										}
									</div>
									<div className='advance-invoice-list-table-col col-7'>
										<Button
											small={true}
											intent={Intent.PRIMARY}
											className={'cell-button'}
											onClick={() => this.props.closeDialog(invoice_item.id_invoice)}>
											Izberi
										</Button>
									</div>
								</React.Fragment>;
							})}
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => this.props.closeDialog(null)}>
							Nadaljuj brez glavnega računa
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => this.props.closeDialog(this.props.invoices.map(invoice => invoice.id_invoice))}>
							Združi vse račune
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}

function mapStateToProps(state) {
	return {
		customers: state.CodeTablesSlice.customers,
	};
}

export default connect(mapStateToProps)(AdvanceInvoiceListDialog);
