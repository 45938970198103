import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Button,
	Intent,
	Alert,
	Checkbox,
	Icon,
} from '@blueprintjs/core';

import AddEditUser  from './AddEditUser';
import { saveUser } from '../../api/Users';
import { addUsers } from '../../slices/UserSlice';

import { ResultHeader, ResultHeaderWithSort, Result, TextFilter } from '../code_tables/ResultComponents';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		openAddEditItemDialog,
		deleteItem,
		tableItemsFilters,
		originalTableItemsFilters,
		handleFilterChange,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	return <div className='results mt-4 mb-4'>
		<div className='results-table'>
			<ResultHeader columnIdx={1} title='' />
			<ResultHeader columnIdx={2} title='Urejanje' />
			<ResultHeaderWithSort
				columnIdx={3} {...sort_props} title='Uporabniško ime' fieldName='username' />
			<ResultHeaderWithSort
				columnIdx={4} {...sort_props} title='Naziv' fieldName='name_surname' />
			<ResultHeaderWithSort
				columnIdx={5} {...sort_props} title='Davčna št.' fieldName='operator_tax_number' />
			<ResultHeaderWithSort
				columnIdx={6} {...sort_props} title='Delavec' fieldName='is_worker' />
			<ResultHeaderWithSort
				columnIdx={7} {...sort_props} title='Aktiven' fieldName='is_active' />
			<ResultHeaderWithSort
				columnIdx={8} {...sort_props} title='Pravice' fieldName='access_roles' />
		</div>
		
		<div className='results-table'>
			<Result columnIdx={1} cls='filters-col' />
			<Result columnIdx={2} cls='filters-col' />
			<Result columnIdx={3} cls='filters-col' child={
				<TextFilter
					value={tableItemsFilters.username}
					onChange={ event => handleFilterChange('username', event) } />
			} />
			<Result columnIdx={4} cls='filters-col' child={
				<TextFilter
					value={tableItemsFilters.name_surname}
					onChange={ event => handleFilterChange('name_surname', event) } />
			} />
			<Result columnIdx={5} cls='filters-col' child={
				<TextFilter
					value={tableItemsFilters.operator_tax_number}
					onChange={ event => handleFilterChange('operator_tax_number', event) } />
			} />
			<Result columnIdx={6} cls='filters-col' child={
				<Checkbox
					checked={tableItemsFilters.is_worker === true}
					indeterminate={tableItemsFilters.is_worker === null}
					inline={true}
					onChange={event => {
						let checked = null;
						if (tableItemsFilters.is_worker === true) {
							checked = false;
						}
						else if (tableItemsFilters.is_worker === null) {
							checked = true;
						}
						
						handleFilterChange('is_worker', checked);
					}} />
			} />
			<Result columnIdx={7} cls='filters-col' child={
				<Checkbox
					checked={tableItemsFilters.is_active === true}
					indeterminate={tableItemsFilters.is_active === null}
					inline={true}
					onChange={event => {
						let checked = null;
						if (tableItemsFilters.is_active === true) {
							checked = false;
						}
						else if (tableItemsFilters.is_active === null) {
							checked = true;
						}
						
						handleFilterChange('is_active', checked);
					}} />
			} />
			<Result columnIdx={8} cls='filters-col' />
		</div>
		
		{table_items_sort_ids.map((id, idx) => {
			const item = table_items[id];
			
			return <div className='results-table' key={'countries-result--result-' + id}>
				<Result columnIdx={1} child={
					item.serial_internal_code
				} />
				<Result columnIdx={2} child={
					<>
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => openAddEditItemDialog(item)} />
						{deleteItem === null ? null :
							<Button
								icon='trash'
								intent='danger'
								minimal={true}
								small={true}
								onClick={() => deleteItem(item)} />
						}
					</>
				} />
				<Result columnIdx={3} child={
					item.username
				} />
				<Result columnIdx={4} child={
					item.name + ' ' + item.surname
				} />
				<Result columnIdx={5} child={
					item.operator_tax_number
				} />
				<Result columnIdx={6} child={
					item.is_worker ?
						<Icon icon='tick' color='#394b59' />
						:
						<Icon icon='cross' color='#ff0000' />
				} />
				<Result columnIdx={7} child={
					item.is_active ?
						<Icon icon='tick' color='#394b59' />
						:
						<Icon icon='cross' color='#ff0000' />
				} />
				<Result columnIdx={8} child={
					[
						[ 'main',                       'Dostop - AssistPRO'                      ],
						[ 'doc',                        'Dostop - evidenca (vpis)'                ],
						[ 'doc-entry',                  'Dostop - evidenca (vpis)'                ],
						[ 'doc-entry-edit-bonus-1',     'Dostop - evidenca (vpis, dodatek I.)'    ],
						[ 'doc-entry-edit-bonus-2',     'Dostop - evidenca (vpis, dodatek II.)'   ],
						[ 'doc-entry-edit-bonus-3',     'Dostop - evidenca (vpis, dodatek III.)'  ],
						[ 'doc-entry-edit-bonus-extra', 'Dostop - evidenca (vpis, dodatek Extra)' ],
						[ 'doc-admin',                  'Dostop - evidenca (polni dostop)'        ],
						[ 'doc-statistics',             'Dostop - evidenca (statistika)'          ],
						[ 'doc-user-statistics',        'Dostop - evidenca (obračun)'             ],
						[ 'doc-analytics',              'Dostop - evidenca (analitika)'           ],
						[ 'doc-settings',               'Dostop - evidenca (nastavitve)'          ],
						[ 'doc-worker',                 'Dostop - evidenca (mobilna)'             ],
					]
						.filter(x => item.access_roles.indexOf(x[0]) > -1)
						.map(x => x[1])
						.join(', ')
				} />
			</div>;
		})}
	</div>;
}

class Users extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps = this.updateTableItemsFromProps.bind(this);
		this.setTableItemsSort         = this.setTableItemsSort        .bind(this);
		this.refreshTableItemsSort     = this.refreshTableItemsSort    .bind(this);
		this.openAddEditItemDialog     = this.openAddEditItemDialog    .bind(this);
		this.closeAddEditItemDialog    = this.closeAddEditItemDialog   .bind(this);
		this.filterTableItems          = this.filterTableItems         .bind(this);
		this.handleFilterChange        = this.handleFilterChange       .bind(this);
		
		const table_items_filters = {
			username:            '',
			name_surname:        '',
			operator_tax_number: '',
			is_worker:           null,
			is_active:           null,
			access_roles:        [],
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'name',
			table_items_sort_direction: 'ASC',
		});
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'name',
			table_items_sort_direction: 'ASC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			item_delete_requested: null,
		};
		
		const sorted = this.refreshTableItemsSort(
			this.state.table_items_sort_field,
			this.state.table_items_sort_direction,
			this.state.table_items_filtered_ids,
			table_items,
			true
		);
		this.state.table_items_sort_ids = sorted.table_items_sort_ids;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.users != this.state.table_items) {
			this.setState(
				this.updateTableItemsFromProps(this.props, this.state)
			);
		}
	}
	
	updateTableItemsFromProps(props, state) {
		//TODO this filter isn't working, so we've disabled is_worker filter and forced it to true
		const ids = Object.keys(props.users).filter(id_user =>
			props.users[id_user].username != 'admin'
		);
		
		let sort_ids = [];
		
		if (this.state !== undefined && this.state.table_items_sort_ids !== undefined) {
			for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
				const id = this.state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const new_state = {
			table_items: props.users,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
			table_items_sort_field: state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, items, return_only) {
		table_items_filtered_ids = table_items_filtered_ids || this.state.table_items_filtered_ids;
		items = items || this.state.table_items;
		return_only = return_only || false;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			const a = items[a_key][field];
			const b = items[b_key][field];
			
			if (field == 'name') {
				return a.localeCompare(b) * (direction == 'ASC' ? 1 : -1);
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (direction == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_username            = filters.username    .toUpperCase();
		const filters_name_surname        = filters.name_surname.toUpperCase();
		const filters_operator_tax_number = filters.operator_tax_number;
		const filters_is_worker           = filters.is_worker;
		const filters_is_active           = filters.is_active;
		//const filters_access_roles        = filters.access_roles;
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_is_worker !== null && item.is_worker !== filters_is_worker) {
				return false;
			}
			if (filters_is_active !== null && item.is_active !== filters_is_active) {
				return false;
			}
			
			if (item.username == 'admin') return false;
			
			if (filters_username != '' && item.username.toUpperCase().indexOf(filters_username) == -1) {
				return false;
			}
			if (filters_name_surname != '' && (item.name + ' ' + item.surname).toUpperCase().indexOf(filters_name_surname) == -1) {
				return false;
			}
			if (filters_operator_tax_number != '' && (item.operator_tax_number ?? '').indexOf(filters_operator_tax_number) == -1) {
				return false;
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			state.table_items,
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			username:            this.state.table_items_filters.username,
			name_surname:        this.state.table_items_filters.name_surname,
			operator_tax_number: this.state.table_items_filters.operator_tax_number,
			is_worker:           this.state.table_items_filters.is_worker,
			is_active:           this.state.table_items_filters.is_active,
			access_roles:        this.state.table_items_filters.access_roles,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: true, add_edit_item_dialog_item: item });
	}
	async closeAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: false });
		
		if (item !== undefined) {
			const new_item = await saveUser(this.props.api_url, item, this.props.token);
			if (new_item !== null && new_item.item !== null && new_item.item !== undefined) {
				this.props.dispatch(addUsers([ new_item.item ]));
			}
		}
	}
	
	render() {
		if (this.props.user === undefined || this.props.user === null || [ 'admin' ].indexOf(this.props.user.username) == -1) return null;
		
		return <>
			{!this.state.add_edit_item_dialog_open ? null :
				<AddEditUser
					closeAddEditItemDialog={this.closeAddEditItemDialog}
					item={this.state.add_edit_item_dialog_item} />
			}
			
			<div className='flex flex-col flex-grow users-list'>
				<div className='pl-4 pt-4'>
					<Button intent='primary' icon='plus' onClick={() => this.openAddEditItemDialog(null)}>
						Nov vnos
					</Button>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items={this.state.table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openAddEditItemDialog={this.openAddEditItemDialog}
							deleteItem={null}
							tableItemsFilters={this.state.table_items_filters}
							originalTableItemsFilters={this.state.original_table_items_filters}
							handleFilterChange={this.handleFilterChange} />
					</div>
				</div>
			</div>
		</>;
	}
}
Users.propTypes = {
};

function mapStateToProps(state) {
	return {
		users:   state.UserSlice.users,
		token:   state.UserSlice.token,
		api_url: state.UserSlice.api_url,
		user:    state.UserSlice.user,
	};
}

export default connect(mapStateToProps)(Users);
