import React, { useState, useEffect, Component } from 'react';

import { useDispatch, connect } from 'react-redux';

import { Provider as BusProvider, useListener } from 'react-bus';

import {
	Button,
	Navbar,
	Alignment,
	Icon,
	Dialog,
	Intent,
} from '@blueprintjs/core';

//import { Icons } from '@blueprintjs/icons';

import { useRoutes, navigate } from 'raviger';

import tinycolor from 'tinycolor2';
import moment from 'moment';

import Login                      from './components/Login';
import CashRegisterSelector       from './components/CashRegisterSelector';
import Reservations               from './components/reservations/Reservations';
import Dashboard                  from './components/dashboard/Dashboard';
import Countries                  from './components/code_tables/Countries';
import MeasuringUnits             from './components/code_tables/MeasuringUnits';
import PaymentTypes               from './components/code_tables/PaymentTypes';
import PostOffices                from './components/code_tables/PostOffices';
import Warehouses                 from './components/code_tables/Warehouses';
import CashRegisters              from './components/code_tables/CashRegisters';
import Channels                   from './components/code_tables/Channels';
import TaxRates                   from './components/code_tables/TaxRates';
import BusinessPremises           from './components/code_tables/BusinessPremises';
import Customers                  from './components/code_tables/Customers';
import Items                      from './components/code_tables/Items';
import MarketSegmentation         from './components/code_tables/MarketSegmentation';
import IdentificationTypes        from './components/code_tables/IdentificationTypes';
import TouristTaxes               from './components/code_tables/TouristTaxes';
import Activities                 from './components/code_tables/Activities';
import Users                      from './components/code_tables/users';
import Invoices                   from './components/business/Invoices';
import BusinessInvoices           from './components/business/BusinessInvoices';
import Offers                     from './components/business/Offers';
import SalesInvoiceBookItems      from './components/business/SalesInvoiceBookItems';
import PrepaymentBookItems        from './components/business/PrepaymentBookItems';
import GuestBook                  from './components/etourism/GuestBook';
import MonthlyGuestBookReports    from './components/etourism/MonthlyGuestBookReports';
import GuestBookTouristTaxes      from './components/etourism/TouristTaxes';
import ReservationList            from './components/reservations/ReservationList';
import ActivitiesList             from './components/reservations/ActivitiesList';
import GeneralSettings            from './components/settings/GeneralSettings';
import ParameterSettings          from './components/settings/ParameterSettings';
import PrintSettings              from './components/settings/PrintSettings';
import DocumentSettings           from './components/settings/DocumentSettings';
import Journals                   from './components/cash_registers/Journals';
import CashRegisterDocuments      from './components/cash_registers/CashRegisterDocuments';
import StockAcquisitionDocuments  from './components/stock/StockAcquisitionDocuments';
import StockInventory             from './components/stock/StockInventory';
import ItemsAndServices           from './components/reports/ItemsAndServices';
import ActivityReservations       from './components/reports/ActivityReservations';
import ReservationsReport         from './components/reports/ReservationsReport';
import { AppToaster }             from './components/AppToaster';
import ConnectedPosPrinterWatcher from './components/PosPrinterWatcher';
import ConnectedA4PrinterWatcher  from './components/A4PrinterWatcher';
import ConnectedLabelPrinterWatcher from './components/LabelPrinterWatcher';
import HealthLog                  from './components/HealthLog';
import ReservationRequestsList    from './components/reservation_requests/ReservationRequestsList';

import { setCurrentIdCashRegister } from './slices/SettingsSlice';

import {
	loadCountries,
	loadPostOffices,
	loadBusinessPremises,
	loadChannels,
	loadCustomers,
	loadMeasuringUnits,
	loadPaymentTypes,
	loadTaxRates,
	loadWarehouses,
	loadAccommodations,
	loadItems,
	loadItem,
	loadMarketSegmentation,
	loadActivities,
	loadIdentificationTypes,
	loadTouristTaxes,
	loadBusinessPremiseFiscalRegistrations,
	loadDailyLogbookActivities,
	loadWorkTypes,
} from './api/CodeTables';
import {
	loadReservations,
	loadActivityDailyLogbooks,
	loadReservationRequests,
	loadReservationRequestAccommodations,
} from './api/Reservations';
import {
	loadInvoice,
	loadInvoices,
	loadInvoiceFiscalVerification,
	loadLatestInvoiceFiscalVerifications,
	loadAdvanceInvoiceConsumption,
	loadAdvanceInvoiceConsumptions,
} from './api/Business';
import {
	loadUserInfo,
	loadUsers,
	loadUserWorkTypes,
} from './api/Users';
import {
	loadDocumentAssociations,
	loadDocumentTypes,
} from './api/Documents';
import {
	loadGuestBookItems,
	loadMonthlyGuestBookReports,
} from './api/Guests';
import {
	loadCashRegisters,
	loadCashRegisterDailyStates,
	loadCashRegisterDocument,
	loadCashRegisterDocuments,
} from './api/CashRegisters';
import {
	loadGeneralSettings,
} from './api/Settings';
import {
	loadStockAcquisitionDocument,
	loadStockAcquisitionDocuments,
	loadStockInventoryItems,
} from './api/Warehouses';

import {
	setUser,
} from './slices/UserSlice';

import {
	deleteCashRegisterDailyState as deleteCashRegisterDailyStateFromState,
} from './slices/CashRegisterSlice';

import { EditInvoiceDialogHelperCloseDialog, EditInvoiceDialogHelperComponent } from './components/EditInvoiceDialogHelper';

import LocalStorageHelper from './helpers/LocalStorageHelper';

//import './App.css';

// from https://stackoverflow.com/a/15762794
// improved for some situations, e.g. 10.5545 -> 10.56
Math.properRound = (x, digits) => {
	let n = x;
	let negative = false;
	if (digits === undefined) {
		digits = 0;
	}
	if (n < 0) {
		negative = true;
		n = n * -1;
	}
	
	for (let d = 8; d >= digits; d--) {
		const multiplicator = Math.pow(10, d);
		n = parseFloat((n * multiplicator).toFixed(11));
		n = (Math.round(n) / multiplicator).toFixed(d);
		
		if (negative) {
			n = (n * -1).toFixed(d);
		}
		n = parseFloat(n);
	}
	return n;
};

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing. The function also has a property 'clear' 
 * that is a function which will clear the timer to prevent previously scheduled executions. 
 *
 * @source underscore.js
 * @see http://unscriptable.com/2009/03/20/debouncing-javascript-methods/
 * @param {Function} function to wrap
 * @param {Number} timeout in ms (`100`)
 * @param {Boolean} whether to execute at the beginning (`false`)
 * @api public
 */
function debounce(func, wait, immediate){
	var timeout, args, context, timestamp, result;
	if (null == wait) wait = 100;

	function later() {
		var last = Date.now() - timestamp;

		if (last < wait && last >= 0) {
			timeout = setTimeout(later, wait - last);
		} else {
			timeout = null;
			if (!immediate) {
				result = func.apply(context, args);
				context = args = null;
			}
		}
	};

	var debounced = function(){
		context = this;
		args = arguments;
		timestamp = Date.now();
		var callNow = immediate && !timeout;
		if (!timeout) timeout = setTimeout(later, wait);
		if (callNow) {
			result = func.apply(context, args);
			context = args = null;
		}

		return result;
	};

	debounced.clear = function() {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
	};
	
	debounced.flush = function() {
		if (timeout) {
			result = func.apply(context, args);
			context = args = null;
			
			clearTimeout(timeout);
			timeout = null;
		}
	};

	return debounced;
};

// from https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
const throttle = (func, limit) => {
	let inThrottle;
	return function() {
		const args = arguments;
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => inThrottle = false, limit);
		}
	};
};

export { debounce, throttle };

function EmptyRoute() {
	return <div></div>;
}

let first_time = true;
let prev_load_token = null;
async function load(api_url, dispatch, token) {
	if (token === null || token === undefined) return;
	
	//if (first_time) {
	//	Icons.setLoaderOptions({ loader: 'all' });
	//	// optionally, load the icons up-front so that future usage does not trigger a network request
	//	await Icons.loadAll();
	//}
	
	if (first_time && token != prev_load_token) {
		first_time = false;
		prev_load_token = token;
		
		loadCountries                         (api_url, dispatch, token);
		loadPostOffices                       (api_url, dispatch, token);
		loadBusinessPremises                  (api_url, dispatch, token);
		loadChannels                          (api_url, dispatch, token);
		loadCustomers                         (api_url, dispatch, token);
		loadMeasuringUnits                    (api_url, dispatch, token);
		loadPaymentTypes                      (api_url, dispatch, token);
		loadTaxRates                          (api_url, dispatch, token);
		loadWarehouses                        (api_url, dispatch, token);
		loadAccommodations                    (api_url, dispatch, token);
		loadItems                             (api_url, dispatch, token);
		loadMarketSegmentation                (api_url, dispatch, token);
		loadActivities                        (api_url, dispatch, token);
		loadIdentificationTypes               (api_url, dispatch, token);
		loadTouristTaxes                      (api_url, dispatch, token);
		loadBusinessPremiseFiscalRegistrations(api_url, dispatch, token);
		
		loadReservations                      (api_url, dispatch, token);
		loadActivityDailyLogbooks             (api_url, dispatch, token);
		loadReservationRequests               (api_url, dispatch, token);
		loadReservationRequestAccommodations  (api_url, dispatch, token);
		
		loadInvoices                          (api_url, dispatch, token);
		loadLatestInvoiceFiscalVerifications  (api_url, dispatch, token);
		loadAdvanceInvoiceConsumptions        (api_url, dispatch, token);
		
		loadDocumentAssociations              (api_url, dispatch, token);
		loadDocumentTypes                     (api_url, dispatch, token);
		
		loadGuestBookItems                    (api_url, dispatch, token);
		loadMonthlyGuestBookReports           (api_url, dispatch, token);
		
		loadCashRegisters                     (api_url, dispatch, token);
		loadCashRegisterDailyStates           (api_url, dispatch, token);
		loadCashRegisterDocuments             (api_url, dispatch, token);
		
		loadGeneralSettings                   (api_url, dispatch, token);
		
		loadUsers                             (api_url, dispatch, token);
		
		loadStockAcquisitionDocuments         (api_url, dispatch, token);
		loadStockInventoryItems               (api_url, dispatch, token);
		
		loadDailyLogbookActivities            (api_url, dispatch, token);
		loadWorkTypes                         (api_url, dispatch, token);
		loadUserWorkTypes                     (api_url, dispatch, token);
	}
}
function useInitFirstTime(api_url, token) {
	const dispatch = useDispatch();
	useEffect(() => {
		load(api_url, dispatch, token);
	});
}

class UserStateWatcher extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	componentDidUpdate(prevProps) {
		// check if user changed
		if (this.props.token != prevProps.token) {
			first_time = true;
			load(this.props.api_url, this.props.dispatch, this.props.token);
		}
		
		if (this.props.token != prevProps.token && this.props.token !== null && (this.props.user === null || this.props.user === undefined)) {
			loadUserInfo(this.props.api_url, this.props.dispatch, this.props.token);
		}
	}
	
	componentDidMount() {
		if (this.props.token !== null && this.props.user === null) {
			loadUserInfo(this.props.api_url, this.props.dispatch, this.props.token);
		}
	}
	
	render() {
		return null;
	}
}
const ConnectedUserStateWatcher = connect(
	state => {
		return {
			token:   state.UserSlice.token,
			user:    state.UserSlice.user,
			api_url: state.ConstantsSlice.api_url,
		};
	}
)(UserStateWatcher);

const EventWatcher = () => {
	useListener('notification-message', React.useCallback(event => {
		let intent = '';
		let icon   = '';
		switch (event.type) {
			case 'error':
				intent = 'danger';
				icon   = 'issue';
				break;
			case 'warning':
				intent = 'warning';
				icon   = 'warning-sign';
				break;
			case 'info':
				intent = 'primary';
				icon   = 'info-sign';
				break;
			case 'success':
				intent = 'success';
				icon   = 'tick';
				break;
		}
		
		AppToaster.show({
			message: <div>
				{event.message}
			</div>,
			intent,
			icon,
		});
	}, []));
	
	return null;
};

const AppLoadBarrier = connect(
	state => { return {
		SettingsSlice:    state.SettingsSlice,
		CodeTablesSlice:  state.CodeTablesSlice,
		ReservationSlice: state.ReservationSlice,
		BusinessSlice:    state.BusinessSlice,
		UserSlice:        state.UserSlice,
		DocumentSlice:    state.DocumentSlice,
		GuestSlice:       state.GuestSlice,
		CashRegisterSlice: state.CashRegisterSlice,
		WarehouseSlice:   state.WarehouseSlice,
	} }
)(props => {
	const states = [
		props.SettingsSlice.general,
		
		props.CodeTablesSlice.countries,
		props.CodeTablesSlice.post_offices,
		props.CodeTablesSlice.business_premises,
		props.CodeTablesSlice.business_premise_fiscal_registrations,
		props.CodeTablesSlice.business_premise_fiscal_registrations_by_id_business_premises,
		props.CodeTablesSlice.channels,
		props.CodeTablesSlice.customers,
		props.CodeTablesSlice.measuring_units,
		props.CodeTablesSlice.payment_types,
		props.CodeTablesSlice.tax_rates,
		props.CodeTablesSlice.warehouses,
		props.CodeTablesSlice.accommodations,
		props.CodeTablesSlice.accommodation_items,
		props.CodeTablesSlice.accommodation_item_places,
		props.CodeTablesSlice.accommodation_item_items,
		props.CodeTablesSlice.accommodation_item_place_items,
		props.CodeTablesSlice.accommodation_item_schedules,
		props.CodeTablesSlice.accommodation_item_schedule_time_slots,
		props.CodeTablesSlice.accommodation_item_schedule_time_slot_activities,
		props.CodeTablesSlice.items,
		props.CodeTablesSlice.market_segmentation,
		props.CodeTablesSlice.activities,
		props.CodeTablesSlice.identification_types,
		props.CodeTablesSlice.tourist_taxes,
		props.CodeTablesSlice.daily_logbook_activities,
		props.CodeTablesSlice.work_types,
		
		props.ReservationSlice.reservations,
		props.ReservationSlice.reservation_customers,
		props.ReservationSlice.reservation_customers_by_id_reservations,
		props.ReservationSlice.activity_reservations,
		props.ReservationSlice.activity_daily_logbooks,
		
		props.BusinessSlice.invoices,
		props.BusinessSlice.invoice_fiscal_verifications,
		props.BusinessSlice.advance_invoice_consumptions,
		props.BusinessSlice.id_advance_invoice_consumption_by_id_advance_invoices,
		props.BusinessSlice.id_advance_invoice_consumption_by_id_consumer_invoices,
		
		props.UserSlice.users,
		props.UserSlice.user_work_types,
		
		props.DocumentSlice.document_associations,
		props.DocumentSlice.document_associations_id_documents,
		props.DocumentSlice.document_types,
		
		props.GuestSlice.guest_book_items,
		props.GuestSlice.monthly_guest_book_reports,
		
		props.CashRegisterSlice.cash_registers,
		props.CashRegisterSlice.cash_register_daily_states,
		props.CashRegisterSlice.cash_register_documents,
		
		props.WarehouseSlice.stock_acquisition_documents,
		props.WarehouseSlice.stock_inventory_items,
	];
	const loaded_count = states.filter(x => x !== null).length;
	
	if (loaded_count != states.length) {
		return <div className='text-center p-4'>
			<div className='p-2 text-xl'>nalagam</div>
			<pre>
				{'◼'.repeat(loaded_count)}
				{'◻'.repeat(states.length - loaded_count)}
			</pre>
		</div>;
	}
	return props.content();
});

let previous_settings = null;

const HealthLogger = props => {
	useEffect(() => {
		const timer = setInterval(async () => {
			const start = moment();
			
			let log_str = LocalStorageHelper.GetValue('health_log');
			if (log_str === null || log_str === undefined) log_str = '[]';
			
			let log = JSON.parse(log_str);
			if (log.length > 24 * 60 * 60) {
				log.shift();
			}
			
			// test assistpro server
			const start_1 = moment();
			let response_1 = null;
			let error_1    = null;
			try {
				response_1 = await fetch(props.api_url + 'health.php');
			} catch (e) {
				error_1 = e;
			}
			const end_1 = moment();
			
			// test other server
			const start_2 = moment();
			let response_2 = null;
			let error_2    = null;
			try {
				response_2 = await fetch('https://tadej.ncode.si:790');
			} catch (e) {
				error_2 = e;
			}
			const end_2 = moment();
			
			log.push({
				ts:                      start.toISOString(true),
				
				assistpro_latency:       end_1.diff(start_1),
				assistpro_status:        response_1 === null ? null : response_1.status,
				assistpro_error_message: error_1    === null ? null : error_1.message,
				
				other_latency:            end_2.diff(start_2),
				other_status:             response_2 === null ? null : response_2.status,
				other_error_message:      error_2    === null ? null : error_2.message,
			});
			
			LocalStorageHelper.SetValue('health_log', JSON.stringify(log));
		}, 5000);
		return () => clearInterval(timer);
	}, []);
	
	return null;
};
function mapStateToProps(state) {
	return {
		api_url: state.ConstantsSlice.api_url,
	};
}

const ConnectedHealthLogger = connect(mapStateToProps)(HealthLogger);

function App(props) {
	console.log('RENDERING APP');
	
	const dispatch = useDispatch();
	
	const [ invoice_dialog_open, setInvoiceDialogOpen ] = useState(false);
	const [ invoice_dialog_item, setInvoiceDialogItem ] = useState(null);
	const [ invoice_dialog_type, setInvoiceDialogType ] = useState(null);
	const [ invoice_dialog_advance_invoices_amounts, setInvoiceDialogAdvanceInvoicesAmounts ] = useState(null);
	
	const [ wait_fiscal_verification_dialog_id_invoice, setWaitFiscalVerificationDialogIdInvoice ] = useState(null);
	
	const openInvoiceDialog = (item, type, advance_invoices_amounts) => {
		setInvoiceDialogOpen(true);
		setInvoiceDialogItem(item);
		setInvoiceDialogType(type);
		setInvoiceDialogAdvanceInvoicesAmounts(advance_invoices_amounts || {});
	};
	
	const closeInvoiceDialog = async (item, open_create_invoice_type, finish_invoice) => {
		setInvoiceDialogOpen(false);
		setInvoiceDialogItem(null);
		setInvoiceDialogType(null);
		setInvoiceDialogAdvanceInvoicesAmounts(null);
		
		EditInvoiceDialogHelperCloseDialog(
			dispatch,
			props.token,
			props.api_url,
			props.document_types,
			setWaitFiscalVerificationDialogIdInvoice,
			openInvoiceDialog,
			item,
			open_create_invoice_type,
			finish_invoice
		);
	};
	
	const routes = {
		'/test': () => <div>test</div>,
		'/status':                           () => <HealthLog />,
		'/':                                 () => <Dashboard />,
		'/reservations*':                    () => <Reservations />,
		'/reservation-list':                 () => <ReservationList />,
		'/reservation-activities-list':      () => <ActivitiesList />,
		'/business':                         () => <EmptyRoute />,
		'/business/cash-invoices':           () => <Invoices openInvoiceDialog={openInvoiceDialog} />,
		'/business/invoices':                () => <BusinessInvoices />,
		'/business/offers':                  () => <Offers />,
		'/business/invoices-book':           () => <SalesInvoiceBookItems />,
		'/business/prepayment-book':         () => <PrepaymentBookItems />,
		'/business/:module':                 () => <EmptyRoute />,
		'/reservation-requests/list':        () => <ReservationRequestsList />,
		'/reservation-requests/:module':     () => <EmptyRoute />,
		'/cash-register':                    () => <EmptyRoute />,
		'/cash-register/journal':            () => <Journals />,
		'/cash-register/documents':          () => <CashRegisterDocuments />,
		'/cash-register/:module':            () => <EmptyRoute />,
		'/stock':                            () => <EmptyRoute />,
		'/stock/acquisition':                () => <StockAcquisitionDocuments />,
		'/stock/inventory':                  () => <StockInventory />,
		'/stock/:module':                    () => <EmptyRoute />,
		'/etourism':                         () => <EmptyRoute />,
		'/etourism/guest-book':              () => <GuestBook />,
		'/etourism/monthly-statistics':      () => <MonthlyGuestBookReports />,
		'/etourism/tourist-taxes':           () => <GuestBookTouristTaxes />,
		'/etourism/:module':                 () => <EmptyRoute />,
		'/reports':                          () => <EmptyRoute />,
		'/reports/items-and-services':       () => <ItemsAndServices />,
		'/reports/activity-reservations':    () => <ActivityReservations openInvoiceDialog={openInvoiceDialog} />,
		'/reports/reservations-report':      () => <ReservationsReport />,
		'/reports/:module':                  () => <EmptyRoute />,
		'/code-tables':                      () => <EmptyRoute />,
		'/code-tables/customers':            () => <Customers />,
		'/code-tables/items-and-services':   () => <Items />,
		'/code-tables/payment-types':        () => <PaymentTypes />,
		'/code-tables/measuring-units':      () => <MeasuringUnits />,
		'/code-tables/post-offices':         () => <PostOffices />,
		'/code-tables/countries':            () => <Countries />,
		'/code-tables/warehouses':           () => <Warehouses />,
		'/code-tables/cash-registers':       () => <CashRegisters />,
		'/code-tables/channels':             () => <Channels />,
		'/code-tables/tax-rates':            () => <TaxRates />,
		'/code-tables/business-premises':    () => <BusinessPremises />,
		'/code-tables/market-segmentation':  () => <MarketSegmentation />,
		'/code-tables/identification-types': () => <IdentificationTypes />,
		'/code-tables/tourist-taxes':        () => <TouristTaxes />,
		'/code-tables/activities':           () => <Activities />,
		'/code-tables/users':                () => <Users />,
		'/code-tables/:module':              () => <EmptyRoute />,
		'/settings':                         () => <EmptyRoute />,
		'/settings/general':                 () => <GeneralSettings />,
		'/settings/parameters':              () => <ParameterSettings />,
		'/settings/print':                   () => <PrintSettings />,
		'/settings/documents':               () => <DocumentSettings />,
		'/settings/:module':                 () => <EmptyRoute />,
	}
	
	let route = useRoutes(routes);
	
	let path = (route === null ? '/' : route.props.path);
	if (path.length == 0 || path.substring(path.length - 1) != '/') {
		path += '/';
	}
	
	const path_first_parts = /\/(.+?)\//.exec(path);
	const path_first_part = (path_first_parts === null || path_first_parts.length != 2 ? 'dashboard' : path_first_parts[1]);
	
	const path_second_parts = /\/.+?\/(.+?)\//.exec(path);
	const path_second_part = (path_second_parts === null || path_second_parts.length != 2 ? '' : path_second_parts[1]);
	
	if (
		props.general_settings !== null &&
		(
			previous_settings === null ||
			previous_settings.color_status_new                      != props.general_settings.color_status_new                      ||
			previous_settings.color_status_waiting_for_confirmation != props.general_settings.color_status_waiting_for_confirmation ||
			previous_settings.color_status_offer_sent               != props.general_settings.color_status_offer_sent               ||
			previous_settings.color_status_confirmed                != props.general_settings.color_status_confirmed                ||
			previous_settings.color_status_advance_invoice_sent     != props.general_settings.color_status_advance_invoice_sent     ||
			previous_settings.color_status_closed                   != props.general_settings.color_status_closed                   ||
			previous_settings.color_status_dragging                 != props.general_settings.color_status_dragging                 ||
			previous_settings.color_status_deleting                 != props.general_settings.color_status_deleting                 ||
			previous_settings.color_status_deleted                  != props.general_settings.color_status_deleting                 ||
			previous_settings.color_status_reversed                 != props.general_settings.color_status_reversed                 ||
			previous_settings.color_status_no_show                  != props.general_settings.color_status_no_show                  ||
			previous_settings.color_status_not_for_rent             != props.general_settings.color_status_not_for_rent             ||
			previous_settings.color_status_invalid                  != props.general_settings.color_status_invalid                  ||
			previous_settings.color_custom_status_1                 != props.general_settings.color_custom_status_1                 ||
			previous_settings.color_custom_status_2                 != props.general_settings.color_custom_status_2                 ||
			previous_settings.color_custom_status_3                 != props.general_settings.color_custom_status_3                 ||
			previous_settings.color_custom_status_4                 != props.general_settings.color_custom_status_4                 ||
			previous_settings.color_custom_status_5                 != props.general_settings.color_custom_status_5                 ||
			previous_settings.color_custom_status_6                 != props.general_settings.color_custom_status_6                 ||
			previous_settings.color_custom_status_7                 != props.general_settings.color_custom_status_7
		)
	) {
		previous_settings = props.general_settings;
		
		const text_color = color => tinycolor(color).getLuminance() > 0.25 ? '#000000' : '#ffffff';
		
		const sett = props.general_settings;
		
		const style = document.createElement('style');
		style.textContent = `
			.timeline .reservation-bar.status-new                      .reservation-bar-background { fill: ${sett.color_status_new                     }; }
			.timeline .reservation-bar.status-waiting-for-confirmation .reservation-bar-background { fill: ${sett.color_status_waiting_for_confirmation}; }
			.timeline .reservation-bar.status-offer-sent               .reservation-bar-background { fill: ${sett.color_status_offer_sent              }; }
			.timeline .reservation-bar.status-confirmed                .reservation-bar-background { fill: ${sett.color_status_confirmed               }; }
			.timeline .reservation-bar.status-advance-invoice-sent     .reservation-bar-background { fill: ${sett.color_status_advance_invoice_sent    }; }
			.timeline .reservation-bar.status-closed                   .reservation-bar-background { fill: ${sett.color_status_closed                  }; }
			.timeline .reservation-bar.status-dragging                 .reservation-bar-background { fill: ${sett.color_status_dragging                }; }
			.timeline .reservation-bar.status-deleting                 .reservation-bar-background { fill: ${sett.color_status_deleting                }; }
			.timeline .reservation-bar.status-deleted                  .reservation-bar-background { fill: ${sett.color_status_deleting                }; }
			.timeline .reservation-bar.status-reversed                 .reservation-bar-background { fill: ${sett.color_status_reversed                }; }
			.timeline .reservation-bar.status-no-show                  .reservation-bar-background { fill: ${sett.color_status_no_show                 }; }
			.timeline .reservation-bar.status-not-for-rent             .reservation-bar-background { fill: ${sett.color_status_not_for_rent            }; }
			.timeline .reservation-bar.status-invalid                  .reservation-bar-background { fill: ${sett.color_status_invalid                 }; }
			.timeline .reservation-bar.custom-status-1                 .custom-status-indicator    { fill: ${sett.color_custom_status_1                }; }
			.timeline .reservation-bar.custom-status-2                 .custom-status-indicator    { fill: ${sett.color_custom_status_2                }; }
			.timeline .reservation-bar.custom-status-3                 .custom-status-indicator    { fill: ${sett.color_custom_status_3                }; }
			.timeline .reservation-bar.custom-status-4                 .custom-status-indicator    { fill: ${sett.color_custom_status_4                }; }
			.timeline .reservation-bar.custom-status-5                 .custom-status-indicator    { fill: ${sett.color_custom_status_5                }; }
			.timeline .reservation-bar.custom-status-6                 .custom-status-indicator    { fill: ${sett.color_custom_status_6                }; }
			.timeline .reservation-bar.custom-status-7                 .custom-status-indicator    { fill: ${sett.color_custom_status_7                }; }
			
			.timeline .reservation-bar.status-new                      text { fill: ${text_color(sett.color_status_new                     )}; }
			.timeline .reservation-bar.status-waiting-for-confirmation text { fill: ${text_color(sett.color_status_waiting_for_confirmation)}; }
			.timeline .reservation-bar.status-offer-sent               text { fill: ${text_color(sett.color_status_offer_sent              )}; }
			.timeline .reservation-bar.status-confirmed                text { fill: ${text_color(sett.color_status_confirmed               )}; }
			.timeline .reservation-bar.status-advance-invoice-sent     text { fill: ${text_color(sett.color_status_advance_invoice_sent    )}; }
			.timeline .reservation-bar.status-closed                   text { fill: ${text_color(sett.color_status_closed                  )}; }
			.timeline .reservation-bar.status-dragging                 text { fill: ${text_color(sett.color_status_dragging                )}; }
			.timeline .reservation-bar.status-deleting                 text { fill: ${text_color(sett.color_status_deleting                )}; }
			.timeline .reservation-bar.status-deleted                  text { fill: ${text_color(sett.color_status_deleting                )}; }
			.timeline .reservation-bar.status-reversed                 text { fill: ${text_color(sett.color_status_reversed                )}; }
			.timeline .reservation-bar.status-no-show                  text { fill: ${text_color(sett.color_status_no_show                 )}; }
			.timeline .reservation-bar.status-not-for-rent             text { fill: ${text_color(sett.color_status_not_for_rent            )}; }
			.timeline .reservation-bar.status-invalid                  text { fill: ${text_color(sett.color_status_invalid                 )}; }
			.timeline .reservation-bar.custom-status-1                 text { fill: ${text_color(sett.color_custom_status_1                )}; }
			.timeline .reservation-bar.custom-status-2                 text { fill: ${text_color(sett.color_custom_status_2                )}; }
			.timeline .reservation-bar.custom-status-3                 text { fill: ${text_color(sett.color_custom_status_3                )}; }
			.timeline .reservation-bar.custom-status-4                 text { fill: ${text_color(sett.color_custom_status_4                )}; }
			.timeline .reservation-bar.custom-status-5                 text { fill: ${text_color(sett.color_custom_status_5                )}; }
			.timeline .reservation-bar.custom-status-6                 text { fill: ${text_color(sett.color_custom_status_6                )}; }
			.timeline .reservation-bar.custom-status-7                 text { fill: ${text_color(sett.color_custom_status_7                )}; }
			
			.timeline .reservation-request-bar.status-new              .reservation-bar-background { fill: ${sett.color_status_new                      }; }
			.timeline .reservation-request-bar.status-confirmed        .reservation-bar-background { fill: ${sett.color_status_waiting_for_confirmation }; }
			
			.timeline .reservation-request-bar.status-new              text { fill: ${text_color(sett.color_status_new                      )}; }
			.timeline .reservation-request-bar.status-confirmed        text { fill: ${text_color(sett.color_status_waiting_for_confirmation )}; }
			
			.activities-guests-table.custom-status-1 .custom-status path { fill: ${sett.color_custom_status_1}; }
			.activities-guests-table.custom-status-2 .custom-status path { fill: ${sett.color_custom_status_2}; }
			.activities-guests-table.custom-status-3 .custom-status path { fill: ${sett.color_custom_status_3}; }
			.activities-guests-table.custom-status-4 .custom-status path { fill: ${sett.color_custom_status_4}; }
			.activities-guests-table.custom-status-5 .custom-status path { fill: ${sett.color_custom_status_5}; }
			.activities-guests-table.custom-status-6 .custom-status path { fill: ${sett.color_custom_status_6}; }
			.activities-guests-table.custom-status-7 .custom-status path { fill: ${sett.color_custom_status_7}; }
			
			.reservation-list .results .results-table.custom-status-1 .custom-status path { fill: ${sett.color_custom_status_1}; }
			.reservation-list .results .results-table.custom-status-2 .custom-status path { fill: ${sett.color_custom_status_2}; }
			.reservation-list .results .results-table.custom-status-3 .custom-status path { fill: ${sett.color_custom_status_3}; }
			.reservation-list .results .results-table.custom-status-4 .custom-status path { fill: ${sett.color_custom_status_4}; }
			.reservation-list .results .results-table.custom-status-5 .custom-status path { fill: ${sett.color_custom_status_5}; }
			.reservation-list .results .results-table.custom-status-6 .custom-status path { fill: ${sett.color_custom_status_6}; }
			.reservation-list .results .results-table.custom-status-7 .custom-status path { fill: ${sett.color_custom_status_7}; }
			
			.activity-reservation-list .results .results-table.custom-status-1 .custom-status path { fill: ${sett.color_custom_status_1}; }
			.activity-reservation-list .results .results-table.custom-status-2 .custom-status path { fill: ${sett.color_custom_status_2}; }
			.activity-reservation-list .results .results-table.custom-status-3 .custom-status path { fill: ${sett.color_custom_status_3}; }
			.activity-reservation-list .results .results-table.custom-status-4 .custom-status path { fill: ${sett.color_custom_status_4}; }
			.activity-reservation-list .results .results-table.custom-status-5 .custom-status path { fill: ${sett.color_custom_status_5}; }
			.activity-reservation-list .results .results-table.custom-status-6 .custom-status path { fill: ${sett.color_custom_status_6}; }
			.activity-reservation-list .results .results-table.custom-status-7 .custom-status path { fill: ${sett.color_custom_status_7}; }
			
			.activities-guests-table.status-new {
				background-color: ${sett.color_status_new};
				color: ${text_color(sett.color_status_new)};
			}
			.activities-guests-table.status-waiting-for-confirmation {
				background-color: ${sett.color_status_waiting_for_confirmation};
				color: ${text_color(sett.color_status_waiting_for_confirmation)};
			}
			.activities-guests-table.status-offer-sent {
				background-color: ${sett.color_status_offer_sent};
				color: ${text_color(sett.color_status_offer_sent)};
			}
			.activities-guests-table.status-confirmed {
				background-color: ${sett.color_status_confirmed};
				color: ${text_color(sett.color_status_confirmed)};
			}
			.activities-guests-table.status-advance-invoice-sent {
				background-color: ${sett.color_status_advance_invoice_sent};
				color: ${text_color(sett.color_status_advance_invoice_sent)};
			}
			.activities-guests-table.status-closed {
				background-color: ${sett.color_status_closed};
				color: ${text_color(sett.color_status_closed)};
			}
			.activities-guests-table.status-dragging {
				background-color: ${sett.color_status_dragging};
				color: ${text_color(sett.color_status_dragging)};
			}
			.activities-guests-table.status-deleting {
				background-color: ${sett.color_status_deleting};
				color: ${text_color(sett.color_status_deleting)};
			}
			.activities-guests-table.status-deleted {
				background-color: ${sett.color_status_deleting};
				color: ${text_color(sett.color_status_deleting)};
			}
			.activities-guests-table.status-reversed {
				background-color: ${sett.color_status_reversed};
				color: ${text_color(sett.color_status_reversed)};
			}
			.activities-guests-table.status-no-show {
				background-color: ${sett.color_status_no_show};
				color: ${text_color(sett.color_status_no_show)};
			}
			.activities-guests-table.status-not-for-rent {
				background-color: ${sett.color_status_not_for_rent};
				color: ${text_color(sett.color_status_not_for_rent)};
			}
			.activities-guests-table.status-invalid {
				background-color: ${sett.color_status_invalid};
				color: ${text_color(sett.color_status_invalid)};
			}
			
			.reservation-list .results .results-table.status-new                      .results-table-col {
				background-color: ${sett.color_status_new};
				color: ${text_color(sett.color_status_new)};
			}
			.reservation-list .results .results-table.status-waiting-for-confirmation .results-table-col {
				background-color: ${sett.color_status_waiting_for_confirmation};
				color: ${text_color(sett.color_status_waiting_for_confirmation)};
			}
			.reservation-list .results .results-table.status-offer-sent               .results-table-col {
				background-color: ${sett.color_status_offer_sent};
				color: ${text_color(sett.color_status_offer_sent)};
			}
			.reservation-list .results .results-table.status-confirmed                .results-table-col {
				background-color: ${sett.color_status_confirmed};
				color: ${text_color(sett.color_status_confirmed)};
			}
			.reservation-list .results .results-table.status-advance-invoice-sent     .results-table-col {
				background-color: ${sett.color_status_advance_invoice_sent};
				color: ${text_color(sett.color_status_advance_invoice_sent)};
			}
			.reservation-list .results .results-table.status-closed                   .results-table-col {
				background-color: ${sett.color_status_closed};
				color: ${text_color(sett.color_status_closed)};
			}
			.reservation-list .results .results-table.status-dragging                 .results-table-col {
				background-color: ${sett.color_status_dragging};
				color: ${text_color(sett.color_status_dragging)};
			}
			.reservation-list .results .results-table.status-deleting                 .results-table-col {
				background-color: ${sett.color_status_deleting};
				color: ${text_color(sett.color_status_deleting)};
			}
			.reservation-list .results .results-table.status-deleted                  .results-table-col {
				background-color: ${sett.color_status_deleting};
				color: ${text_color(sett.color_status_deleting)};
			}
			.reservation-list .results .results-table.status-reversed                 .results-table-col {
				background-color: ${sett.color_status_reversed};
				color: ${text_color(sett.color_status_reversed)};
			}
			.reservation-list .results .results-table.status-no-show                  .results-table-col {
				background-color: ${sett.color_status_no_show};
				color: ${text_color(sett.color_status_no_show)};
			}
			.reservation-list .results .results-table.status-not-for-rent             .results-table-col {
				background-color: ${sett.color_status_not_for_rent};
				color: ${text_color(sett.color_status_not_for_rent)};
			}
			.reservation-list .results .results-table.status-invalid                  .results-table-col {
				background-color: ${sett.color_status_invalid};
				color: ${text_color(sett.color_status_invalid)};
			}
			
			.reservation-requests-list .results .results-table .results-table-col.status-new {
				background-color: ${sett.color_status_new};
				color: ${text_color(sett.color_status_new)};
			}
			.reservation-requests-list .results .results-table .results-table-col.status-confirmed {
				background-color: ${sett.color_status_waiting_for_confirmation};
				color: ${text_color(sett.color_status_waiting_for_confirmation)};
			}
			.reservation-requests-list .results .results-table .results-table-col.status-closed {
				background-color: ${sett.color_status_closed};
				color: ${text_color(sett.color_status_closed)};
			}
			.reservation-requests-list .results .results-table .results-table-col.status-reversed {
				background-color: ${sett.color_status_reversed};
				color: ${text_color(sett.color_status_reversed)};
			}
			.reservation-requests-list .results .results-table .results-table-col.status-processed {
				background-color: ${sett.color_status_confirmed};
				color: ${text_color(sett.color_status_confirmed)};
			}
			
			.activity-reservation-list .results .results-table.status-new                      .results-table-col {
				background-color: ${sett.color_status_new};
				color: ${text_color(sett.color_status_new)};
			}
			.activity-reservation-list .results .results-table.status-waiting-for-confirmation .results-table-col {
				background-color: ${sett.color_status_waiting_for_confirmation};
				color: ${text_color(sett.color_status_waiting_for_confirmation)};
			}
			.activity-reservation-list .results .results-table.status-offer-sent               .results-table-col {
				background-color: ${sett.color_status_offer_sent};
				color: ${text_color(sett.color_status_offer_sent)};
			}
			.activity-reservation-list .results .results-table.status-confirmed                .results-table-col {
				background-color: ${sett.color_status_confirmed};
				color: ${text_color(sett.color_status_confirmed)};
			}
			.activity-reservation-list .results .results-table.status-advance-invoice-sent     .results-table-col {
				background-color: ${sett.color_status_advance_invoice_sent};
				color: ${text_color(sett.color_status_advance_invoice_sent)};
			}
			.activity-reservation-list .results .results-table.status-closed                   .results-table-col {
				background-color: ${sett.color_status_closed};
				color: ${text_color(sett.color_status_closed)};
			}
			.activity-reservation-list .results .results-table.status-dragging                 .results-table-col {
				background-color: ${sett.color_status_dragging};
				color: ${text_color(sett.color_status_dragging)};
			}
			.activity-reservation-list .results .results-table.status-deleting                 .results-table-col {
				background-color: ${sett.color_status_deleting};
				color: ${text_color(sett.color_status_deleting)};
			}
			.activity-reservation-list .results .results-table.status-deleted                  .results-table-col {
				background-color: ${sett.color_status_deleting};
				color: ${text_color(sett.color_status_deleting)};
			}
			.activity-reservation-list .results .results-table.status-reversed                 .results-table-col {
				background-color: ${sett.color_status_reversed};
				color: ${text_color(sett.color_status_reversed)};
			}
			.activity-reservation-list .results .results-table.status-no-show                  .results-table-col {
				background-color: ${sett.color_status_no_show};
				color: ${text_color(sett.color_status_no_show)};
			}
			.activity-reservation-list .results .results-table.status-not-for-rent             .results-table-col {
				background-color: ${sett.color_status_not_for_rent};
				color: ${text_color(sett.color_status_not_for_rent)};
			}
			.activity-reservation-list .results .results-table.status-invalid                  .results-table-col {
				background-color: ${sett.color_status_invalid};
				color: ${text_color(sett.color_status_invalid)};
			}
			
			.reports-activity-reservations-list .col.status-new {
				background-color: ${sett.color_status_new};
				color: ${text_color(sett.color_status_new)};
			}
			.reports-activity-reservations-list .col.status-waiting-for-confirmation {
				background-color: ${sett.color_status_waiting_for_confirmation};
				color: ${text_color(sett.color_status_waiting_for_confirmation)};
			}
			.reports-activity-reservations-list .col.status-offer-sent {
				background-color: ${sett.color_status_offer_sent};
				color: ${text_color(sett.color_status_offer_sent)};
			}
			.reports-activity-reservations-list .col.status-confirmed {
				background-color: ${sett.color_status_confirmed};
				color: ${text_color(sett.color_status_confirmed)};
			}
			.reports-activity-reservations-list .col.status-advance-invoice-sent {
				background-color: ${sett.color_status_advance_invoice_sent};
				color: ${text_color(sett.color_status_advance_invoice_sent)};
			}
			.reports-activity-reservations-list .col.status-closed {
				background-color: ${sett.color_status_closed};
				color: ${text_color(sett.color_status_closed)};
			}
			.reports-activity-reservations-list .col.status-dragging {
				background-color: ${sett.color_status_dragging};
				color: ${text_color(sett.color_status_dragging)};
			}
			.reports-activity-reservations-list .col.status-deleting {
				background-color: ${sett.color_status_deleting};
				color: ${text_color(sett.color_status_deleting)};
			}
			.reports-activity-reservations-list .col.status-deleted {
				background-color: ${sett.color_status_deleting};
				color: ${text_color(sett.color_status_deleting)};
			}
			.reports-activity-reservations-list .col.status-reversed {
				background-color: ${sett.color_status_reversed};
				color: ${text_color(sett.color_status_reversed)};
			}
			.reports-activity-reservations-list .col.status-no-show {
				background-color: ${sett.color_status_no_show};
				color: ${text_color(sett.color_status_no_show)};
			}
			.reports-activity-reservations-list .col.status-not-for-rent {
				background-color: ${sett.color_status_not_for_rent};
				color: ${text_color(sett.color_status_not_for_rent)};
			}
			.reports-activity-reservations-list .col.status-invalid {
				background-color: ${sett.color_status_invalid};
				color: ${text_color(sett.color_status_invalid)};
			}
		`;
		document.head.appendChild(style);
	}
	
	const submenu_items = {
		'business': [
			<Button
				key={'submenu-3-1'}
				minimal={true}
				active={ path_first_part == 'business' && path_second_part == 'cash-invoices' }
				icon='floppy-disk'
				text='Blagajniški računi'
				onClick={() => navigate('/business/cash-invoices')} />,
			<Button
				key={'submenu-3-2'}
				minimal={true}
				active={ path_first_part == 'business' && path_second_part == 'invoices' }
				icon='document'
				text='Fakturiranje'
				onClick={() => navigate('/business/invoices')} />,
			<Button
				key={'submenu-3-4'}
				minimal={true}
				active={ path_first_part == 'business' && path_second_part == 'offers' }
				icon='bank-account'
				text='Ponudbe'
				onClick={() => navigate('/business/offers')} />,
			<Button
				key={'submenu-3-6'}
				minimal={true}
				active={ path_first_part == 'business' && path_second_part == 'invoices-book' }
				icon='bank-account'
				text='Knjiga izdanih računov'
				onClick={() => navigate('/business/invoices-book')} />,
			<Button
				key={'submenu-3-7'}
				minimal={true}
				active={ path_first_part == 'business' && path_second_part == 'prepayment-book' }
				icon='bank-account'
				text='Knjiga prejetih avansov'
				onClick={() => navigate('/business/prepayment-book')} />,
		],
		'reservation-requests': [
			<Button
				key={'submenu-2-1'}
				minimal={true}
				active={ path_first_part == 'reservation-requests' && path_second_part == 'list' }
				icon='floppy-disk'
				text='Povpraševanja'
				onClick={() => navigate('/reservation-requests/list')} />,
		],
		'cash-register': [
			<Button
				key={'submenu-4-2'}
				minimal={true}
				active={ path_first_part == 'cash-register' && path_second_part == 'journal' }
				icon='manually-entered-data'
				text='Blagajniški dnevnik'
				onClick={() => navigate('/cash-register/journal')} />,
			<Button
				key={'submenu-4-1'}
				minimal={true}
				active={ path_first_part == 'cash-register' && path_second_part == 'documents' }
				icon='manually-entered-data'
				text='Blagajniški dokumenti'
				onClick={() => navigate('/cash-register/documents')} />,
			<Button
				key={'submenu-4-3'}
				minimal={true}
				active={ path_first_part == 'cash-register' && path_second_part == 'recapitulation' }
				icon='manually-entered-data'
				text='Rekapitulacija blagajne'
				onClick={() => navigate('/cash-register/recapitulation')} />,
			//<Button
			//	key={'submenu-4-4'}
			//	minimal={true}
			//	active={ path_first_part == 'cash-register' && path_second_part == 'current-register' }
			//	icon='manually-entered-data'
			//	text='Trenutna blagajna'
			//	onClick={() => navigate('/cash-register/current-register')} />,
			//<Button
			//	key={'submenu-4-5'}
			//	minimal={true}
			//	active={ path_first_part == 'cash-register' && path_second_part == 'furs' }
			//	icon='manually-entered-data'
			//	text='Priprava podatkov za FURS'
			//	onClick={() => navigate('/cash-register/furs')} />,
		],
		'stock': [
			<Button
				key={'submenu-5-1'}
				minimal={true}
				active={ path_first_part == 'stock' && path_second_part == 'acquisition' }
				icon='floppy-disk'
				text='Prevzem'
				onClick={() => navigate('/stock/acquisition')} />,
			<Button
				key={'submenu-5-2'}
				minimal={true}
				active={ path_first_part == 'stock' && path_second_part == 'delivery-notes' }
				icon='document'
				text='Dobavnica'
				onClick={() => navigate('/stock/delivery-notes')} />,
			<Button
				key={'submenu-5-4'}
				minimal={true}
				active={ path_first_part == 'stock' && path_second_part == 'inventory' }
				icon='bank-account'
				text='Stanje zalog'
				onClick={() => navigate('/stock/inventory')} />,
		],
		'etourism': [
			<Button
				key={'submenu-6-1'}
				minimal={true}
				active={ path_first_part == 'etourism' && path_second_part == 'guest-book' }
				icon='bank-account'
				text='Prijava/Knjiga gostov'
				onClick={() => navigate('/etourism/guest-book')} />,
			<Button
				key={'submenu-6-2'}
				minimal={true}
				active={ path_first_part == 'etourism' && path_second_part == 'monthly-statistics' }
				icon='document'
				text='Mesečna statistika'
				onClick={() => navigate('/etourism/monthly-statistics')} />,
			<Button
				key={'submenu-6-3'}
				minimal={true}
				active={ path_first_part == 'etourism' && path_second_part == 'tourist-taxes' }
				icon='bank-account'
				text='Turistična taksa'
				onClick={() => navigate('/etourism/tourist-taxes')} />,
		],
		'reports': [
			/*<Button
				key={'submenu-7-1'}
				minimal={true}
				active={ path_first_part == 'reports' && path_second_part == 'reservations' }
				icon='floppy-disk'
				text='Rezervacije'
				onClick={() => navigate('/reports/reservations')} />,
			<Button
				key={'submenu-7-2'}
				minimal={true}
				active={ path_first_part == 'reports' && path_second_part == 'turnover' }
				icon='document'
				text='Promet'
				onClick={() => navigate('/reports/turnover')} />,*/
			<Button
				key={'submenu-7-3'}
				minimal={true}
				active={ path_first_part == 'reports' && path_second_part == 'items-and-services' }
				icon='bank-account'
				text='Artikli/storitve'
				onClick={() => navigate('/reports/items-and-services')} />,
			<Button
				key={'submenu-7-4'}
				minimal={true}
				active={ path_first_part == 'reports' && path_second_part == 'activity-reservations' }
				icon='bank-account'
				text='Realizacija'
				onClick={() => navigate('/reports/activity-reservations')} />,
			<Button
				key={'submenu-7-5'}
				minimal={true}
				active={ path_first_part == 'reports' && path_second_part == 'reservations-report' }
				icon='bank-account'
				text='Rezervacije'
				onClick={() => navigate('/reports/reservations-report')} />,
			/*<Button
				key={'submenu-7-5'}
				minimal={true}
				active={ path_first_part == 'reports' && path_second_part == 'cash-register' }
				icon='bank-account'
				text='Blagajna'
				onClick={() => navigate('/reports/cash-register')} />,*/
		],
		'code-tables': [
			<Button
				key={'submenu-8-1'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'customers' }
				icon='floppy-disk'
				text='Stranke'
				onClick={() => navigate('/code-tables/customers')} />,
			<Button
				key={'submenu-8-2'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'items-and-services' }
				icon='document'
				text='Artikli/storitve'
				onClick={() => navigate('/code-tables/items-and-services')} />,
			<Button
				key={'submenu-8-3'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'payment-types' }
				icon='bank-account'
				text='Načini plačila'
				onClick={() => navigate('/code-tables/payment-types')} />,
			<Button
				key={'submenu-8-4'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'measuring-units' }
				icon='bank-account'
				text='Enote mere'
				onClick={() => navigate('/code-tables/measuring-units')} />,
			<Button
				key={'submenu-8-5'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'post-offices' }
				icon='bank-account'
				text='Pošte'
				onClick={() => navigate('/code-tables/post-offices')} />,
			<Button
				key={'submenu-8-6'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'countries' }
				icon='bank-account'
				text='Države'
				onClick={() => navigate('/code-tables/countries')} />,
			<Button
				key={'submenu-8-7'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'warehouses' }
				icon='bank-account'
				text='Skladišča'
				onClick={() => navigate('/code-tables/warehouses')} />,
			<Button
				key={'submenu-8-8'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'cash-registers' }
				icon='bank-account'
				text='Blagajna'
				onClick={() => navigate('/code-tables/cash-registers')} />,
			<Button
				key={'submenu-8-9'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'users' }
				icon='bank-account'
				text='Uporabniki'
				onClick={() => navigate('/code-tables/users')} />,
			<Button
				key={'submenu-8-10'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'reservations' }
				icon='bank-account'
				text='Rezervacije'
				onClick={() => navigate('/code-tables/reservations')} />,
			<Button
				key={'submenu-8-11'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'channels' }
				icon='bank-account'
				text='Prodajni kanali'
				onClick={() => navigate('/code-tables/channels')} />,
			<Button
				key={'submenu-8-12'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'business-premises' }
				icon='bank-account'
				text='Poslovni prostor'
				onClick={() => navigate('/code-tables/business-premises')} />,
			<Button
				key={'submenu-8-13'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'tax-rates' }
				icon='bank-account'
				text='Davčne stopnje'
				onClick={() => navigate('/code-tables/tax-rates')} />,
			<Button
				key={'submenu-8-14'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'market-segmentation' }
				icon='bank-account'
				text='Tržni segment'
				onClick={() => navigate('/code-tables/market-segmentation')} />,
			<Button
				key={'submenu-8-15'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'identification-types' }
				icon='bank-account'
				text='Vrste osebnih dokumentov'
				onClick={() => navigate('/code-tables/identification-types')} />,
			<Button
				key={'submenu-8-16'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'tourist-taxes' }
				icon='bank-account'
				text='Turistične takse'
				onClick={() => navigate('/code-tables/tourist-taxes')} />,
			<Button
				key={'submenu-8-17'}
				minimal={true}
				active={ path_first_part == 'code-tables' && path_second_part == 'activities' }
				icon='bank-account'
				text='Aktivnosti'
				onClick={() => navigate('/code-tables/activities')} />,
		],
		'settings': [
			<Button
				key={'submenu-9-1'}
				minimal={true}
				active={ path_first_part == 'settings' && path_second_part == 'general' }
				icon='floppy-disk'
				text='Splošne nastavitve'
				onClick={() => navigate('/settings/general')} />,
			<Button
				key={'submenu-9-2'}
				minimal={true}
				active={ path_first_part == 'settings' && path_second_part == 'parameters' }
				icon='document'
				text='Statusi'
				onClick={() => navigate('/settings/parameters')} />,
			<Button
				key={'submenu-9-3'}
				minimal={true}
				active={ path_first_part == 'settings' && path_second_part == 'documents' }
				icon='document'
				text='Izpisi dokumentov'
				onClick={() => navigate('/settings/documents')} />,
			<Button
				key={'submenu-9-6'}
				minimal={true}
				active={ path_first_part == 'settings' && path_second_part == 'print' }
				icon='print'
				text='Tiskanje'
				onClick={() => navigate('/settings/print')} />,
		],
	};
	
	const curr_submenu_items = submenu_items[path_first_part] === undefined ?
		[] :
		submenu_items[path_first_part];
	
	useInitFirstTime(props.api_url, props.token);
	
	const [changes_web_socket, setChangesWS] = useState(null);
	
	const loadInvoicesDebounced                           = debounce(() => loadInvoices                          (props.api_url, dispatch, props.token), 800);
	const loadLatestInvoiceFiscalVerificationsDebounced   = debounce(() => loadLatestInvoiceFiscalVerifications  (props.api_url, dispatch, props.token), 800);
	const loadAdvanceInvoiceConsumptionsDebounced         = debounce(() => loadAdvanceInvoiceConsumptions        (props.api_url, dispatch, props.token), 800);
	const loadBusinessPremisesDebounced                   = debounce(() => loadBusinessPremises                  (props.api_url, dispatch, props.token), 800);
	const loadBusinessPremiseFiscalRegistrationsDebounced = debounce(() => loadBusinessPremiseFiscalRegistrations(props.api_url, dispatch, props.token), 800);
	const loadReservationsDebounced                       = debounce(() => loadReservations                      (props.api_url, dispatch, props.token), 800);
	const loadReservationRequestsDebounced                = debounce(() => loadReservationRequests               (props.api_url, dispatch, props.token), 800);
	const loadCashRegisterDailyStatesDebounced            = debounce(() => loadCashRegisterDailyStates           (props.api_url, dispatch, props.token), 800);
	const loadStockAcquisitionDocumentsDebounced          = debounce(() => loadStockAcquisitionDocuments         (props.api_url, dispatch, props.token), 800);
	const loadCustomersDebounced                          = debounce(() => loadCustomers                         (props.api_url, dispatch, props.token), 800);
	const loadGuestBookItemsDebounced                     = debounce(() => loadGuestBookItems                    (props.api_url, dispatch, props.token), 800);
	const loadAccommodationsDebounced                     = debounce(() => loadAccommodations                    (props.api_url, dispatch, props.token), 800);
	const loadItemsDebounced                              = debounce(() => loadItems                             (props.api_url, dispatch, props.token), 800);
	
	useEffect(() => {
		const init_ws = () => {
			console.log('init changes ws');
			
			const changes_ws = new WebSocket(props.ws_changes_url);
			changes_ws.onmessage = event => {
				console.log({ event });
				
				// handle empty token, which can happen after login
				if (props.token === null) {
					console.log('Changes WS onMessage, token is null');
					return;
				}
				
				const arr = event.data.split(':');
				if (arr.length != 3) return;
				
				const table_name = arr[0];
				const operation  = arr[1];
				const id_item    = arr[2].replaceAll('"', '');
				
				console.log({
					op: 'change',
					table_name,
					operation,
					id_item,
				});
				
				if (table_name == 'invoices.invoice') {
					if (operation == 'DELETE') {
						loadInvoicesDebounced();
					}
					else {
						loadInvoice(props.api_url, id_item, dispatch, props.token);
					}
				}
				else if (table_name == 'invoices.invoice_fiscal_verification') {
					if (operation == 'DELETE') {
						loadLatestInvoiceFiscalVerificationsDebounced();
					}
					else {
						loadInvoiceFiscalVerification(props.api_url, id_item, dispatch, props.token);
					}
				}
				else if (table_name == 'invoices.advance_invoice_consumption') {
					if (operation == 'DELETE') {
						loadAdvanceInvoiceConsumptionsDebounced();
					}
					else {
						loadAdvanceInvoiceConsumption(props.api_url, id_item, dispatch, props.token);
					}
				}
				else if (table_name == 'code_tables.business_premise') {
					loadBusinessPremisesDebounced();
				}
				else if (table_name == 'code_tables.business_premise_fiscal_registration') {
					loadBusinessPremiseFiscalRegistrationsDebounced();
				}
				else if (table_name == 'reservations.reservation' || table_name == 'reservations.activity_reservation') {
					loadReservationsDebounced();
				}
				else if (table_name == 'reservations.reservation_request') {
					loadReservationRequestsDebounced();
				}
				else if (table_name == 'cash_registers.cash_register_daily_state') {
					if (operation == 'DELETE') {
						dispatch(deleteCashRegisterDailyStateFromState(id_item));
					}
					else {
						loadCashRegisterDailyStatesDebounced();
					}
				}
				else if (table_name == 'cash_registers.cash_register_document') {
					if (operation == 'DELETE') {
						//dispatch(deleteCashRegisterDailyStateFromState(id_item));
					}
					else {
						loadCashRegisterDocument(props.api_url, id_item, dispatch, props.token);
					}
				}
				else if (table_name == 'warehouses.stock_acquisition_document') {
					if (operation == 'DELETE') {
						loadStockAcquisitionDocumentsDebounced();
					}
					else {
						loadStockAcquisitionDocument(props.api_url, id_item, dispatch, props.token);
					}
				}
				else if (table_name == 'customers.customer') {
					loadCustomersDebounced();
				}
				else if (table_name == 'guests.guest_book' || table_name == 'guests.guest_book_log') {
					loadGuestBookItemsDebounced();
					loadReservationsDebounced();
				}
				else if (table_name == 'accommodations.accommodation_item_place') {
					loadAccommodationsDebounced();
				}
				else if (table_name == 'items.item') {
					if (operation == 'DELETE') {
						loadItemsDebounced();
					}
					else {
						loadItem(props.api_url, id_item, dispatch, props.token);
					}
				}
			};
			changes_ws.onopen = event => {
				if (changes_ws.readyState != WebSocket.OPEN) return;
				changes_ws.send('{ "token": "' + props.token + '", "client": "' + props.client + '", "action": "listen", "data": "" }');
			};
			changes_ws.onclose = event => {
				setTimeout(init_ws, 5000);
			};
			
			setChangesWS(changes_ws);
		};
		
		init_ws();
		
		// cleanup when needed
		return () => {
			if (changes_web_socket !== undefined && changes_web_socket !== null) {
				// prevent auto reconnect
				changes_web_socket.onclose = event => {};
				
				changes_web_socket.close();
			}
		};
	}, [ props.ws_changes_url, props.token ]);
	
	let show_navigation = props.token !== null && props.current_id_cash_register !== null;
	
	route = props.current_id_cash_register === null ?
		<CashRegisterSelector />
		:
		route;
	
	if (props.user !== null && props.user !== undefined && (props.user.access_roles ?? []).indexOf('main') === -1) {
		show_navigation = false;
		route = <div className='flex flex-col items-center w-full p-10'>
			<div>Dostop zavrnjen</div>
			<Button
				intent='primary'
				className='mt-4'
				onClick={async () => {
					props.setUser({
						token: null,
						user:  null,
					});
				}}>
				Prijava
			</Button>
		</div>;
	}
	
	let label_printer_watcher = null;
	if (props.general_settings !== null && props.general_settings.label_printer_enabled) {
		label_printer_watcher = <ConnectedLabelPrinterWatcher />;
	}
	
	return <BusProvider>
		<div className='flex flex-col flex-grow'>
			<ConnectedUserStateWatcher />
			<EventWatcher />
			
			{props.critical_error === null ? null :
				<Dialog
					isOpen={true}
					className='bg-red-700 text-white text-2xl font-thin'
					style={{ width: 600 }}
					canOutsideClickClose={false}
					canEscapeKeyClose={false}
					isCloseButtonShown={false}>
					<div className='p-6 pb-3 text-center'>
						Pri zadnji operaciji je prišlo do napake!
						<br />
						<br />
						Osvežite aplikacijo (Ctrl+R oz. F5 oz. klik na spodnji gumb) in preverite zadnje podatke.
						<br />
						<br />
 						<Button
 							large={true}
 							onClick={() => window.location.reload()}>
 							Osveži aplikacijo
 						</Button>
					</div>
				</Dialog>
			}
			
			{props.token === null ?
				<div className='flex flex-col flex-grow main-content'>
					<Login />
				</div>
				:
				<AppLoadBarrier content={() => <>
					<ConnectedPosPrinterWatcher />
					<ConnectedA4PrinterWatcher />
					{label_printer_watcher}
					
					{!show_navigation ? null :
						<>
							<Navbar fixedToTop={false} className='bp5-dark'>
								<Navbar.Group>
									<Navbar.Heading>
										{ 'AssistPRO • ' + (props.client === undefined || props.client === null ? '' : props.client.toUpperCase()) }
									</Navbar.Heading>
									<Navbar.Divider />
									<Button
										minimal={true}
										active={ path == '/' }
										icon='home'
										text=''
										onClick={() => navigate('/')} />
									<Button
										minimal={true}
										active={ path_first_part == 'reservations' }
										icon='document'
										text='Rezervacije'
										onClick={() => navigate('/reservations/accommodation/0')} />
									{props.general_settings === null || props.general_settings.feature_flags.reservation_requests !== true ? null :
										<Button
											minimal={true}
											active={ path_first_part == 'reservation-requests' }
											icon='document'
											text='Povpraševanja'
											onClick={() => navigate('/reservation-requests/list')} />
									}
									<Button
										minimal={true}
										active={ path_first_part == 'business' }
										icon='bank-account'
										text='Poslovanje'
										onClick={() => navigate(
											props.current_id_cash_register == 0 ?
												'/business/invoices'
												:
												'/business/cash-invoices'
										)} />
									<Button
										minimal={true}
										active={ path_first_part == 'cash-register' }
										icon='manually-entered-data'
										text='Blagajna'
										onClick={() => navigate('/cash-register/journal')} />
									<Button
										minimal={true}
										active={ path_first_part == 'stock' }
										icon='manually-entered-data'
										text='Zaloge'
										onClick={() => navigate('/stock/acquisition')} />
									<Button
										minimal={true}
										active={ path_first_part == 'etourism' }
										icon='manually-entered-data'
										text='Poročanje - eTurizem'
										onClick={() => navigate('/etourism/guest-book')} />
									<Button
										minimal={true}
										active={ path_first_part == 'reports' }
										icon='manually-entered-data'
										text='Poročila'
										onClick={() => navigate('/reports/items-and-services')} />
									<Button
										minimal={true}
										active={ path_first_part == 'code-tables' }
										icon='manually-entered-data'
										text='Šifranti'
										onClick={() => navigate('/code-tables/customers')} />
									<Button
										minimal={true}
										active={ path_first_part == 'settings' }
										icon='manually-entered-data'
										text='Nastavitve'
										onClick={() => navigate('/settings/general')} />
								</Navbar.Group>
								<Navbar.Group align={Alignment.RIGHT}>
									{props.pos_printer_queue.length + props.a4_printer_queue.length == 0 ? null :
										<div className='mr-4' style={{ position: 'relative', width:60, height: 29 }}>
											<div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, top: 0 }}>
												<div className='bp5-progress-bar bp5-intent-primary' style={{ height: '100%' }}>
													<div className='bp5-progress-meter'></div>
												</div>
											</div>
											<div style={{ position: 'absolute', bottom: 0, left: 25, right: 3, top: 7, textAlign: 'center' }}>
												{ props.pos_printer_queue.length + props.a4_printer_queue.length }
											</div>
											<div style={{ position: 'absolute', left: 7, top: 5 }}>
												<Icon icon='print' iconSize={20} />
											</div>
										</div>
									}
									
									<div style={{ position: 'relative' }}>
										<Icon icon='notifications' />
										
										<div style={{ position: 'absolute', fontSize: 12, right: -4, top: -8, color: '#00E676' }}>
											{true ? null : 8}
										</div>
									</div>
									
									<div className='ml-4'>
										{props.user === null || props.user === undefined ? null :
											props.user.name +
											(props.user.surname === undefined || props.user.surname.length == 0 ?
												'' : ' ' + props.user.surname.substring(0, 1)
											)
										}
									</div>
									
									<Button
										className='ml-4'
										onClick={async () => {
											props.setCurrentIdCashRegister({ current_id_cash_register: null });
											LocalStorageHelper.SetValue(props.client + '__current_id_cash_register', null);
										}}>
										{
											props.current_id_cash_register == 0 || props.cash_registers[props.current_id_cash_register] === undefined ?
												'-'
												:
												props.cash_registers[props.current_id_cash_register].internal_code
										}
									</Button>
									
									<Button
										intent='primary'
										className='ml-4'
										onClick={async () => {
											props.setUser({
												token: null,
												user:  null,
											});
											navigate('/');
										}}>
										Odjava
									</Button>
								</Navbar.Group>
							</Navbar>
							{ curr_submenu_items.length == 0 ? null :
								<Navbar fixedToTop={false} className='auto'>
									<Navbar.Group>
										{ curr_submenu_items }
									</Navbar.Group>
								</Navbar>
							}
						</>
					}
					<div className='flex flex-col flex-grow main-content'>
						{ route }
					</div>
					
					<EditInvoiceDialogHelperComponent
						invoice_dialog_open                       ={invoice_dialog_open}
						closeInvoiceDialog                        ={closeInvoiceDialog}
						invoice_dialog_item                       ={invoice_dialog_item}
						invoice_dialog_type                       ={invoice_dialog_type}
						invoice_dialog_advance_invoices_amounts   ={invoice_dialog_advance_invoices_amounts}
						wait_fiscal_verification_dialog_id_invoice={wait_fiscal_verification_dialog_id_invoice}
						invoices                                  ={props.invoices}
						general_settings                          ={props.general_settings}
						dispatch                                  ={dispatch}
						setWaitFiscalVerificationDialogIdInvoice  ={setWaitFiscalVerificationDialogIdInvoice} />
				</>} />
			}
		</div>
	</BusProvider>;
}

function mapStateToProps(state) {
	return {
		token:                    state.UserSlice.token,
		user:                     state.UserSlice.user,
		api_url:                  state.ConstantsSlice.api_url,
		ws_changes_url:           state.ConstantsSlice.ws_changes_url,
		client:                   state.ConstantsSlice.client,
		current_id_cash_register: state.SettingsSlice.current_id_cash_register,
		general_settings:         state.SettingsSlice.general,
		cash_registers:           state.CashRegisterSlice.cash_registers,
		pos_printer_queue:        state.AppSlice.pos_printer_queue,
		a4_printer_queue:         state.AppSlice.a4_printer_queue,
		critical_error:           state.AppSlice.critical_error,
		document_types:           state.DocumentSlice.document_types,
		invoices:                 state.BusinessSlice.invoices,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setUser: (data) => {
			dispatch(setUser(data));
		},
		setCurrentIdCashRegister: (data) => {
			dispatch(setCurrentIdCashRegister(data));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
