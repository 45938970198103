import { createSlice } from '@reduxjs/toolkit'
import uuid from 'uuid';

function addItem(state_key_name, key_name, item, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	if (item[key_name] == -1) {
		item[key_name] = uuid.v4();
	}
	
	if (state_key_name == 'invoices') {
		if (item.items !== undefined) {
			for (let i=0; i<item.items.length; i++) {
				if (item.items[i].id_invoice_item == -1) {
					item.items[i].id_invoice_item = uuid.v4();
				}
			}
		}
	}
	
	state[state_key_name][item[key_name]] = item;
	
	return item;
}
function addItems(state_key_name, key_name, items, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	for (let i=0; i<items.length; i++) {
		state[state_key_name][items[i][key_name]] = items[i];
	}
}
function deleteItem(state_key_name, id_item, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	delete state[state_key_name][id_item];
}

function addAdvanceInvoiceConsumptionItems(items, state) {
	if (state.advance_invoice_consumptions === null) state.advance_invoice_consumptions = {};
	
	const advance_id_invoice_to_ids  = {};
	const consumer_id_invoice_to_ids = {};
	
	for (let i=0; i<items.length; i++) {
		const item = items[i];
		state.advance_invoice_consumptions[item.id_advance_invoice_consumption] = item;
		
		if (advance_id_invoice_to_ids[item.advance_id_invoice] === undefined) {
			advance_id_invoice_to_ids[item.advance_id_invoice] = [];
		}
		advance_id_invoice_to_ids[item.advance_id_invoice].push(item.id_advance_invoice_consumption);
		
		if (consumer_id_invoice_to_ids[item.consumer_id_invoice] === undefined) {
			consumer_id_invoice_to_ids[item.consumer_id_invoice] = [];
		}
		consumer_id_invoice_to_ids[item.consumer_id_invoice].push(item.id_advance_invoice_consumption);
	}
	
	state.id_advance_invoice_consumption_by_id_advance_invoices  = advance_id_invoice_to_ids;
	state.id_advance_invoice_consumption_by_id_consumer_invoices = consumer_id_invoice_to_ids;
}

function addAdvanceInvoiceConsumptionItem(item, state) {
	if (state.advance_invoice_consumptions === null) state.advance_invoice_consumptions = {};
	
	const advance_id_invoice_to_ids  = {...(state.id_advance_invoice_consumption_by_id_advance_invoices  || {})};
	const consumer_id_invoice_to_ids = {...(state.id_advance_invoice_consumption_by_id_consumer_invoices || {})};
	
	state.advance_invoice_consumptions[item.id_advance_invoice_consumption] = item;
	
	if (advance_id_invoice_to_ids[item.advance_id_invoice] === undefined) {
		advance_id_invoice_to_ids[item.advance_id_invoice] = [];
	}
	advance_id_invoice_to_ids[item.advance_id_invoice].push(item.id_advance_invoice_consumption);
	
	if (consumer_id_invoice_to_ids[item.consumer_id_invoice] === undefined) {
		consumer_id_invoice_to_ids[item.consumer_id_invoice] = [];
	}
	consumer_id_invoice_to_ids[item.consumer_id_invoice].push(item.id_advance_invoice_consumption);
	
	state.id_advance_invoice_consumption_by_id_advance_invoices  = advance_id_invoice_to_ids;
	state.id_advance_invoice_consumption_by_id_consumer_invoices = consumer_id_invoice_to_ids;
}

const BusinessSlice = createSlice({
	name: 'business',
	initialState: {}, // defined in AppContainer.js
	reducers: {
		addInvoice: (state, action) => {
			const item = addItem('invoices', 'id_invoice', action.payload.item, state);
		},
		addInvoices: (state, action) => addItems('invoices', 'id_invoice', action.payload, state),
		addInvoiceFiscalVerifications: (state, action) => {
			addItems('invoice_fiscal_verifications', 'id_invoice_fiscal_verification', action.payload, state);
			
			// add by id invoices
			const items = action.payload;
			const items_by_id_invoice = {...(state['invoice_fiscal_verifications_by_id_invoice'] || {})};
			
			for (let i=0; i<items.length; i++) {
				if (items_by_id_invoice[items[i].id_invoice] === undefined) {
					items_by_id_invoice[items[i].id_invoice] = [];
				}
				items_by_id_invoice[items[i].id_invoice].push(items[i]);
			}
			state['invoice_fiscal_verifications_by_id_invoice'] = items_by_id_invoice;
		},
		addInvoiceFiscalVerification: (state, action) => {
			const item = addItem('invoice_fiscal_verifications', 'id_invoice_fiscal_verification', action.payload.item, state);
			
			// add by id invoices
			const items_by_id_invoice = {...(state['invoice_fiscal_verifications_by_id_invoice'] || {})};
			
			if (items_by_id_invoice[item.id_invoice] === undefined) {
				items_by_id_invoice[item.id_invoice] = [];
			}
			items_by_id_invoice[item.id_invoice].push(item);
			
			state['invoice_fiscal_verifications_by_id_invoice'] = items_by_id_invoice;
		},
		deleteInvoice: (state, action) => deleteItem('invoices', action.payload.id_invoice, state),
		addAdvanceInvoiceConsumptions: (state, action) => addAdvanceInvoiceConsumptionItems(action.payload, state),
		addAdvanceInvoiceConsumption:  (state, action) => addAdvanceInvoiceConsumptionItem(action.payload.item, state),
	}
});

export const {
	addInvoice,
	addInvoices,
	addInvoiceFiscalVerification,
	addInvoiceFiscalVerifications,
	deleteInvoice,
	addAdvanceInvoiceConsumption,
	addAdvanceInvoiceConsumptions,
} = BusinessSlice.actions;
export default BusinessSlice.reducer;
