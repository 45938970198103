import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Navbar,
	FormGroup,
	InputGroup,
	Checkbox,
} from '@blueprintjs/core';

import moment from 'moment';

import TextWithTooltip          from './TextWithTooltip';
import { ResultHeader, Result } from './code_tables/ResultComponents';
import DateRangePickerDialog    from './DateRangePickerDialog';

function Results(props) {
	const uppercase_search_value = props.search_value.toUpperCase();
	const start_date_iso         = props.start_date === null ? null : moment(props.start_date).format('YYYY-MM-DD');
	const end_date_iso           = props.end_date   === null ? null : moment(props.end_date  ).format('YYYY-MM-DD');
	
	// find invoices
	let selected_invoice_types = Array.isArray(props.enabled_invoices_types) ? props.enabled_invoices_types : [];
	if (selected_invoice_types.length == 0) {
		selected_invoice_types = [
			'cash-prepayment-invoice',
			'prepayment-invoice',
		];
	}
	
	const found_invoices = props.enabled_invoices_types === false || (Array.isArray(props.enabled_invoices_types) && props.enabled_invoices_types.length == 0) ? [] :
		Object.values(props.invoices).filter(item => {
			if (selected_invoice_types.length > 0 && selected_invoice_types.indexOf(item.invoice_type) == -1) return false;
			
			const remaining_advance_payment_amount = parseFloat(item.advance_payment_amount) - parseFloat(item.used_advance_payment_amount);
			if (remaining_advance_payment_amount <= 0) return false;
			
			const issued_iso = item.issued === null ? null : moment(item.issued).format('YYYY-MM-DD');
			if (
				start_date_iso !== null && end_date_iso !== null &&
				(issued_iso === null || issued_iso < start_date_iso || issued_iso > end_date_iso)
			) {
				return false;
			}
			
			if (uppercase_search_value.length == 0) return true;
			
			const customer = item.id_customer === null ? null : props.customers[item.id_customer];
			
			return item.invoice_number_internal.toUpperCase().indexOf(uppercase_search_value) != -1
				||
				(customer !== null && (
					customer.internal_code.toString().indexOf(props.search_value) != -1 ||
					(customer.surname + ' ' + customer.name + ' ' + customer.company_name).toUpperCase().indexOf(uppercase_search_value) != -1
				));
		});
	
	if (found_invoices.length == 0) {
		return <div>Ni rezultatov</div>;
	}
	
	return <div>
		{found_invoices.length == 0 ? null :
			<InvoiceResults
				invoices={found_invoices}
				customers={props.customers}
				selectItem={props.selectItem} />
		}
	</div>;
}

function InvoiceResults(props) {
	const toLocalStringWithTime = date => {
		if (date === null) return '';
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear() + ' ' +
			date.getHours().toString().padStart(2, '0') + ':' +
			date.getMinutes().toString().padStart(2, '0') + ':' +
			date.getSeconds().toString().padStart(2, '0');
	};
	
	const invoice_types = {
		'cash-prepayment-invoice': { title: 'Račun za predplačilo' },
		'prepayment-invoice':      { title: 'Avansni račun'        },
	};
	
	return <div>
		<div className='document-search-prepayment-invoices-list'>
			<div className='results'>
				<div className='results-table'>
					<ResultHeader columnIdx={1} title='Št. računa' />
					<ResultHeader columnIdx={2} title='Šifra' />
					<ResultHeader columnIdx={3} title='Naziv stranke' />
					<ResultHeader columnIdx={4} title='Datum računa' />
					<ResultHeader columnIdx={5} title='Vrsta dokumenta' />
					<ResultHeader columnIdx={6} title='Znesek ponudbe' />
					<ResultHeader columnIdx={7} title='Neporabljen znesek' />
					<ResultHeader columnIdx={8} title='' />
					
					{Object.values(props.invoices).map(item => {
						const issued = (item.issued === null ? null : new Date(item.issued));
						
						return <React.Fragment key={'result-' + item.id_invoice}>
							<Result columnIdx={1} child={
								item.draft ? 'osnutek' : item.invoice_number_internal
							} />
							<Result columnIdx={2} child={
								props.customers[item.id_customer] === undefined ? '' :
									props.customers[item.id_customer].internal_code
							} />
							<Result columnIdx={3} child={
								props.customers[item.id_customer] === undefined ? '' :
									(props.customers[item.id_customer].type == 'natural' ?
										props.customers[item.id_customer].surname + ' ' + props.customers[item.id_customer].name
										:
										props.customers[item.id_customer].company_name
									)
							} />
							<Result columnIdx={4} child={
								toLocalStringWithTime(issued)
							} />
							<Result columnIdx={5} child={
								invoice_types[item.invoice_type] === undefined ?
									item.invoice_type : invoice_types[item.invoice_type].title
							} />
							<Result columnIdx={6} cls='text-right font-bold col-invoice-amount' child={
								(item.reversed ? '[S] ' : '') +
								parseFloat(item.invoice_amount).toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								) + ' EUR'
							} />
							<Result columnIdx={7} cls='text-right font-bold col-invoice-amount' child={
								(parseFloat(item.advance_payment_amount) - parseFloat(item.used_advance_payment_amount)).toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								) + ' EUR'
							} />
							<Result columnIdx={8} child={
								<Button
									className={'cell-button'}
									onClick={() => {
										props.selectItem(item.id_invoice, item.invoice_type);
									}}
									icon='tick'
									small={true}>
									Izberi
								</Button>
							} />
						</React.Fragment>;
					})}
				</div>
			</div>
		</div>
	</div>;
}

class PrepaymentDocumentSearchDialog extends Component {
	constructor(props) {
		super(props);
		
		this.openDateRangePickerDialog  = this.openDateRangePickerDialog .bind(this);
		this.closeDateRangePickerDialog = this.closeDateRangePickerDialog.bind(this);
		this.toggleEnabledType          = this.toggleEnabledType         .bind(this);
		this.toggleEnabledInvoiceType   = this.toggleEnabledInvoiceType  .bind(this);
		
		this.state = {
			search_value:              '',
			search_value_applied:      '',
			enabled_type_all:          true,
			enabled_types:             [],
			enabled_invoice_types:     [],
			
			date_range_picker_dialog_open: false,
			start_date:                    null,
			end_date:                      null,
		};
		
		this._search_timeout = null;
	}
	
	openDateRangePickerDialog() {
		this.setState({
			date_range_picker_dialog_open: true,
		});
	}
	closeDateRangePickerDialog(start_date, end_date) {
		this.setState({
			date_range_picker_dialog_open: false,
		});
		
		if (end_date === null) {
			end_date = start_date;
		}
		
		if (start_date !== undefined && end_date !== undefined) {
			this.setState({
				start_date,
				end_date,
			});
		}
	}
	
	toLocalString(date) {
		if (date === null || date === undefined) return '';
		
		if (!(date instanceof Date)) return date;
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear();
	}
	
	toggleEnabledType(type, checked) {
		const enabled_types = this.state.enabled_types;
		if (checked && enabled_types.indexOf(type) == -1) {
			enabled_types.push(type);
		}
		else if (!checked && enabled_types.indexOf(type) != -1) {
			enabled_types.splice(enabled_types.indexOf(type), 1);
		}
		
		this.setState({
			enabled_type_all: false,
			enabled_types,
		});
	}
	
	toggleEnabledInvoiceType(type, checked) {
		const enabled_invoice_types = this.state.enabled_invoice_types;
		if (checked && enabled_invoice_types.indexOf(type) == -1) {
			enabled_invoice_types.push(type);
		}
		else if (!checked && enabled_invoice_types.indexOf(type) != -1) {
			enabled_invoice_types.splice(enabled_invoice_types.indexOf(type), 1);
		}
		
		this.setState({
			enabled_type_all: false,
			enabled_invoice_types,
		});
	}
	
	render() {
		let enabled_invoice_types = this.state.enabled_invoice_types;
		let enabled_type_all      = this.state.enabled_type_all;
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => { this.props.closeDialog(); }}
			className='prepayment-document-search-dialog'>
			<div className='flex flex-col h-full'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Iskanje dokumenta</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => { this.props.closeDialog(); }} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					{!this.state.date_range_picker_dialog_open ? null :
						<DateRangePickerDialog
							startValue= {this.state.start_date}
							endValue=   {this.state.end_date}
							closeDialog={this.closeDateRangePickerDialog} />
					}
					
					<div className='flex flex-row h-full box-border'>
						<div className='bg-gray-300 p-4 box-border left-side'>
							<FormGroup
								labelFor='search-input'>
								<TextWithTooltip
									label='Iskalni niz'
									tooltip='Št. računa, št. rezervacije, itd' />
								<InputGroup id='search-input'
									value={this.state.search_value}
									onChange={event => {
										this.setState({ search_value: event.target.value });
										
										if (this._search_timeout !== null) {
											clearTimeout(this._search_timeout);
										}
										this._search_timeout = setTimeout(() => {
											this.setState({ search_value_applied: this.state.search_value });
										}, 500);
									}} />
							</FormGroup>
							
							<FormGroup>
								<Button fill={true} onClick={this.openDateRangePickerDialog}>
									{this.state.start_date === null || this.state.end_date === null ?
										'Izberi obdobje' :
										this.toLocalString(this.state.start_date) +
										' ↔ ' +
										this.toLocalString(this.state.end_date)
									}
								</Button>
							</FormGroup>
							
							<Checkbox
								checked={this.state.enabled_type_all}
								label='Vse'
								onChange={event => {
									if (!event.target.checked) return; // can't unselect all
									this.setState({
										enabled_type_all:      true,
										enabled_types:         [],
										enabled_invoice_types: [],
									});
								}} />
							
							{[
								{ key: 'cash-prepayment-invoice', title: 'Račun za predplačilo' },
								{ key: 'prepayment-invoice',      title: 'Avansni račun'        },
							].map(invoice_type =>
								<Checkbox
									key={'filter-invoice-type--' + invoice_type.key}
									checked={enabled_invoice_types.indexOf(invoice_type.key) != -1}
									label={invoice_type.title}
									onChange={event => this.toggleEnabledInvoiceType(invoice_type.key, event.target.checked)} />
							)}
						</div>
						<div className='flex-1 p-4 box-border overflow-y-auto'>
							<Results
								search_value          ={this.state.search_value_applied}
								start_date            ={this.state.start_date}
								end_date              ={this.state.end_date}
								enabled_invoices_types={enabled_type_all || enabled_invoice_types}
								invoices              ={this.props.invoices}
								customers             ={this.props.customers}
								countries             ={this.props.countries}
								selectItem            ={this.props.closeDialog} />
						</div>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
PrepaymentDocumentSearchDialog.propTypes = {
	closeDialog: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		customers: state.CodeTablesSlice.customers,
		invoices:  state.BusinessSlice.invoices,
		countries: state.CodeTablesSlice.countries,
	};
}

export default connect(mapStateToProps)(PrepaymentDocumentSearchDialog);
