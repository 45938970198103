import { createSlice } from '@reduxjs/toolkit'
import uuid from 'uuid';

function addItem(state_key_name, key_name, item, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	if (item[key_name] == -1) {
		item[key_name] = uuid.v4();
	}
	
	state[state_key_name][item[key_name]] = item;
	
	if (state_key_name == 'reservation_customers') {
		if (state.reservation_customers_by_id_reservations[item.id_reservation] === undefined) {
			state.reservation_customers_by_id_reservations[item.id_reservation] = {};
		}
		
		state.reservation_customers_by_id_reservations[item.id_reservation][item.id_reservation_customer] = item;
	}
	
	return item;
}
function addItems(state_key_name, key_name, items, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	if (state_key_name == 'reservation_customers') {
		state.reservation_customers_by_id_reservations = {};
	}
	
	for (let i=0; i<items.length; i++) {
		const item = items[i];
		if (state_key_name == 'reservations') {
			const items = {};
			for (let j=0; j<item.items.length; j++) {
				items[item.items[j].id_reservation_item] = item.items[j];
			}
			item.items = items;
		}
		else if (state_key_name == 'reservation_customers') {
			if (state.reservation_customers_by_id_reservations[item.id_reservation] === undefined) {
				state.reservation_customers_by_id_reservations[item.id_reservation] = {};
			}
			
			state.reservation_customers_by_id_reservations[item.id_reservation][item.id_reservation_customer] = item;
		}
		
		state[state_key_name][items[i][key_name]] = items[i];
	}
}

const ReservationSlice = createSlice({
	name: 'reservations',
	initialState: {}, // defined in AppContainer.js
	reducers: {
		addReservation: (state, action) => {
			const item = addItem('reservations', 'id_reservation', action.payload.item, state);
		},
		addReservations: (state, action) => addItems('reservations', 'id_reservation', action.payload, state),
		deleteReservation: (state, action) => {
			delete state.reservations[action.payload];
			
			/*saveReservations(state.api_url, {
				reservations:          Object.values(state.reservations),
				reservation_customers: Object.values(state.reservation_customers),
				activity_reservations: Object.values(state.activity_reservations),
			}, null);*/
		},
		addReservationCustomer: (state, action) => {
			addItem('reservation_customers', 'id_reservation_customer', action.payload.item, state);
		},
		batchAddReservationCustomers: (state, action) => {
			for (let i=0; i<action.payload.items.length; i++) {
				addItem('reservation_customers', 'id_reservation_customer', action.payload.items[i], state);
			}
		},
		addReservationCustomers: (state, action) => addItems('reservation_customers', 'id_reservation_customer', action.payload, state),
		removeReservationCustomer: (state, action) => {
			delete state.reservation_customers[action.payload.item.id_reservation_customer];
			delete state.reservation_customers_by_id_reservations[action.payload.item.id_reservation][action.payload.item.id_reservation_customer];
		},
		addActivityReservation: (state, action) => {
			const item = addItem('activity_reservations', 'id_activity_reservation', action.payload.item, state);
			/*
			if (action.payload.save_to_api === true) {
				saveActivityReservation(state.api_url, item, action.payload.token);
			}*/
		},
		addActivityReservations: (state, action) => addItems('activity_reservations', 'id_activity_reservation', action.payload, state),
		deleteActivityReservation: (state, action) => {
			delete state.reservation_customers[action.payload];
			
			/*saveReservations(state.api_url, {
				reservations:          Object.values(state.reservations),
				reservation_customers: Object.values(state.reservation_customers),
				activity_reservations: Object.values(state.activity_reservations),
			}, null);*/
		},
		addActivityDailyLogbook: (state, action) => { addItem('activity_daily_logbooks', 'id_activity_daily_logbook', action.payload.item, state) },
		addActivityDailyLogbooks: (state, action) => addItems('activity_daily_logbooks', 'id_activity_daily_logbook', action.payload, state),
		deleteActivityDailyLogbook: (state, action) => {
			delete state.activity_daily_logbooks[action.payload];
		},
		addReservationCustomerItem: (state, action) => {
			addItem('reservation_customer_items', 'id_reservation_customer_item', action.payload.item, state);
		},
		addReservationCustomerItems: (state, action) => addItems('reservation_customer_items', 'id_reservation_customer_item', action.payload, state),
		removeReservationCustomerItem: (state, action) => {
			delete state.reservation_customer_items[action.payload.item.id_reservation_customer_item];
		},
		addReservationRequest: (state, action) => { addItem('reservation_requests', 'id_reservation_request', action.payload.item, state) },
		addReservationRequests: (state, action) => addItems('reservation_requests', 'id_reservation_request', action.payload, state),
		addReservationRequestAccommodations: (state, action) => { state['reservation_request_accommodations'] = action.payload; },
		deleteReservationRequest: (state, action) => {
			delete state.reservation_requests[action.payload];
		},
	}
});

export const {
	addReservation,
	addReservations,
	deleteReservation,
	addReservationCustomer,
	batchAddReservationCustomers,
	addReservationCustomers,
	removeReservationCustomer,
	addActivityReservation,
	addActivityReservations,
	deleteActivityReservation,
	addActivityDailyLogbook,
	addActivityDailyLogbooks,
	deleteActivityDailyLogbook,
	addReservationCustomerItem,
	addReservationCustomerItems,
	removeReservationCustomerItem,
	addReservationRequest,
	addReservationRequests,
	addReservationRequestAccommodations,
	deleteReservationRequest,
} = ReservationSlice.actions;
export default ReservationSlice.reducer;
