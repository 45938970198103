const DocumentsHelper = {
	GetDocuments: (document_associations, id_document, max_depth, depth) => {
		depth = depth || 1;
		
		let items = {};
		if (depth > max_depth) return items;
		
		let documents = Object.values(document_associations[id_document] || {});
		for (let i=0; i<documents.length; i++) {
			let item                  = documents[i];
			let curr_id_document      = item.source_id_document;
			let curr_from_id_document = item.target_id_document;
			let document_type         = item.source_type;
			
			if (item.source_id_document == id_document) {
				curr_id_document      = item.target_id_document;
				curr_from_id_document = item.source_id_document;
				document_type         = item.target_type;
			}
			
			if (items[item.id_document_association] === undefined) {
				items[item.id_document_association] = {
					id_document_association: item.id_document_association,
					created:                 item.created,
					id_document:             curr_id_document,
					from_document:           curr_from_id_document,
					document_type,
				};
			}
			
			const subitems = DocumentsHelper.GetDocuments(document_associations, curr_id_document, max_depth, depth + 1);
			for (let id_document_association in subitems) {
				if (items[id_document_association] === undefined) {
					items[id_document_association] = subitems[id_document_association];
				}
			}
		}
		
		return items;
	},
	GetDocumentsForReservationAndCustomer: (document_associations, id_reservation, id_reservation_customer) => {
		let items = {
			reservation_documents: {},
			customer_documents:    {},
		};
		
		const reservation_documents = DocumentsHelper.GetDocuments(document_associations, id_reservation,          1);
		const customer_documents    = DocumentsHelper.GetDocuments(document_associations, id_reservation_customer, 1);
		
		const reservation_documents_by_id_document = {};
		for (let id_document_association in reservation_documents) {
			const doc         = reservation_documents[id_document_association];
			const id_document = doc.source_type == 'reservation' ?
				doc.target_id_document : doc.source_id_document;
			
			reservation_documents_by_id_document[id_document] = doc;
		}
		
		for (let id_document_association in customer_documents) {
			const doc         = customer_documents[id_document_association];
			const id_document = doc.source_type == 'reservation' ?
				doc.target_id_document : doc.source_id_document;
			
			if (reservation_documents_by_id_document[id_document] !== undefined) {
				items.reservation_documents[id_document_association] = reservation_documents[id_document_association];
				items.customer_documents   [id_document_association] = doc;
			}
		}
		
		return items;
	},
};

export default DocumentsHelper;
