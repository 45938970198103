import React from 'react';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Navbar,
} from '@blueprintjs/core';

import Documents from './Documents';

function DocumentListDialog(props) {
	const {
		id_document,
		closeDialog,
		deep,
	} = props;
	
	//const [ accommodation_is_open, setAccommodationIsOpen ] = useState([ 1 ]);
	
	return <Dialog
		isOpen={true}
		usePortal={true}
		canOutsideClickClose={true}
		canEscapeKeyClose={true}
		onClose={closeDialog}
		className='document-list-dialog'>
		<div className='flex flex-col flex-grow'>
			<Navbar fixedToTop={false} className='bp5-dark'>
				<Navbar.Group>
					<Navbar.Heading>Dokumenti</Navbar.Heading>
				</Navbar.Group>
				<Navbar.Group align={Alignment.RIGHT}>
					<Button minimal={true} icon='cross' onClick={closeDialog} />
				</Navbar.Group>
			</Navbar>
			<div className={Classes.DIALOG_BODY}>
				<Documents
					id_document={id_document}
					deep={deep}
					hide_customer={true}
					document_types={[ 'invoice', 'cash-invoice' ]}
					document_type={props.document_type} />
			</div>
		</div>
	</Dialog>;
}

export default DocumentListDialog;
