import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	Navbar,
	FileInput,
	RadioGroup,
	Radio,
	HTMLSelect,
} from '@blueprintjs/core';

import XLSX from 'xlsx';

class ImportStockAcquisitionFromInventoryListFileDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			type:         'deficit',
			current_data: null,
			sheet_names:  [],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeDialog()}
			className='import-stock-acquisition-file-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Uvoz iz Excela (inventurni list)</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<RadioGroup
							onChange={event => {
								this.setState({
									type: event.target.value,
								});
							}}
							selectedValue={this.state.type}
							inline={true}>
							<Radio label='Inventurni manko' value='deficit' />
							<Radio label='Inventurni višek' value='surplus' />
						</RadioGroup>
						
						<FormGroup
							className='flex-1'>
							<FileInput
								text="Izberi datoteko ..."
								onInputChange={async (e) => {
									e.preventDefault();
									const reader = new FileReader();
									reader.onload = async (e) => {
										const data     = e.target.result;
										const workbook = XLSX.read(data, {type: 'array'});
										
										this.setState({
											current_data: data,
											sheet_names: workbook.SheetNames,
										});
									};
									reader.readAsArrayBuffer(e.target.files[0]);
								}} />
						</FormGroup>
						
						<div className={'transition-opacity duration-500 ease-in-out ' + (this.state.sheet_names.length == 0 ? 'opacity-25' : 'opacity-100')}>
							<div className='mt-2'>Izberi dobavitelja:</div>
							
							<HTMLSelect
								options={
									(
										this.state.sheet_names.length == 0 ?
										[ 'Prazno' ]
										:
										[ 'Izberi', ...this.state.sheet_names ]
									)
									.map(
										item => {
											return { label: item, value: item };
										}
									)
								}
								value={this.state.inventory_export_id_warehouse}
								onChange={(e) => {
									this.props.closeDialog(
										new Uint8Array(this.state.current_data),
										this.state.type,
										e.currentTarget.value
									);
								}} />
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeDialog()}>
							Prekliči
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
ImportStockAcquisitionFromInventoryListFileDialog.propTypes = {
	closeDialog: PropTypes.func,
};

export default ImportStockAcquisitionFromInventoryListFileDialog;
