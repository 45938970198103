import {
	setUser,
	addUsers,
	addUserWorkType,
	addUserWorkTypes,
	addUserDailyLogbookActivity,
	addUserDailyLogbookActivities,
} from '../slices/UserSlice';

export async function login(api_url, username, password) {
	const form_data = new FormData();
	form_data.set('username', username);
	form_data.set('password', password);
	
	const response = await fetch(api_url + 'Users/v1/login', {
		method: 'post',
		body:   form_data,
	});
	const data = await response.json();
	
	return data;
}

export async function loadUserInfo(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Users/v1/user', { headers: { 'Authorization': 'Bearer ' + token } });
	let user = await response.json();
	
	if (user.error_code !== undefined) {
		if (user.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		console.log({ user });
		dispatch(setUser({
			user_info: user,
			token,
		}));
	}
}

export async function loadUsers(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Users/v1/users', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addUsers(items));
	}
}

export async function loadUserWorkTypes(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Users/v1/user-work-types', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addUserWorkTypes(items));
	}
}

export async function loadUserDailyLogbookActivities(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Users/v1/user-daily-logbook-activities', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addUserDailyLogbookActivities(items));
	}
}

export async function saveUser(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Users/v1/save-user', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}

export async function saveUserWorkTypes(api_url, id_user, items, token) {
	const form_data = new FormData();
	form_data.set('id_user', JSON.stringify(id_user));
	form_data.set('items', JSON.stringify(items));
	
	let response = await fetch(api_url + 'Users/v1/save-user-work-types', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let retval = await response.json();
	
	if (retval.error_code !== undefined) {
		if (retval.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return true;
	}
	return null;
}

export async function saveUserDailyLogbookActivities(api_url, id_user, items, token) {
	const form_data = new FormData();
	form_data.set('id_user', JSON.stringify(id_user));
	form_data.set('items', JSON.stringify(items));
	
	let response = await fetch(api_url + 'Users/v1/save-user-daily-logbook-activities', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let retval = await response.json();
	
	if (retval.error_code !== undefined) {
		if (retval.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return true;
	}
	return null;
}
