import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	NumericInput,
	HTMLSelect,
} from '@blueprintjs/core';
import { DateInput3, IDateFormatProps } from '@blueprintjs/datetime2';

import moment from 'moment';
import formatISO from 'date-fns/formatISO';

import TextWithTooltip from '../TextWithTooltip';
import { AppToaster }  from '../AppToaster';
import { loadDateFnsLocale } from '../../utils';

class AddEditTaxRate extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id_tax_rate:   props.item === null ? -1   : props.item.id_tax_rate,
			title:         props.item === null ? ''   : props.item.title,
			value:         props.item === null ? 0    : props.item.value,
			valid_from:    props.item === null ? null : (props.item.valid_from === null ? null : new Date(props.item.valid_from)),
			valid_until:   props.item === null ? null : (props.item.valid_until === null ? null : new Date(props.item.valid_until)),
			ord:           props.item === null ? 1    : props.item.ord,
			taxable:       props.item === null ? 'taxable' : props.item.taxable,
			validation_failed_fields: [],
		};
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
		}
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-tax-rate-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi davčno stopnjo</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeAddEditItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='add-edit-tax-rate-dialog--title'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('title') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Naziv'
								tooltip='Naziv' />
							<InputGroup
								id='add-edit-tax-rate-dialog--title'
								value={this.state.title}
								onChange={event => {
									this.setState({
										title: event.target.value,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-tax-rate-dialog--taxable'
							className='flex-1'>
							<TextWithTooltip
								label='Tip obdavčitve'
								tooltip='Tip obdavčitve' />
							<HTMLSelect
								id='add-edit-tax-rate-dialog--taxable'
								fill={true}
								options={
									[
										{ title: 'Obdavčeno',   value: 'taxable'    },
										{ title: 'Neobdavčeno', value: 'nontaxable' },
										{ title: 'Oproščeno',   value: 'tax-exempt' },
									].map(
										item => {
											return { label: item.title, value: item.value };
										}
									)
								}
								value={this.state.taxable}
								onChange={(e) => {
									this.setState({
										taxable: e.currentTarget.value,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-tax-rate-dialog--value'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('value') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Vrednost (%)'
								tooltip='Vrednost (%)' />
							<NumericInput
								id='add-edit-tax-rate-dialog--value'
								value={this.state.value}
								min={0}
								max={100}
								minorStepSize={0.0001}
								onValueChange={(val, val_str) => {
									this.setState({
										value: val_str,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-tax-rate-dialog--valid_from'>
							<TextWithTooltip
								label='Veljavno od'
								tooltip='Veljavno od' />
							<DateInput3
								id='add-edit-tax-rate-dialog--valid_from'
								dateFnsLocaleLoader={loadDateFnsLocale}
								dateFnsFormat={'dd. MM. yyyy'}
								locale='sl'
								inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
								selectAllOnFocus={true}
								minDate={new Date('1900-01-01')}
								maxDate={moment().add('10', 'years').toDate()}
								onChange={(selectedDate, isUserChange) => {
									if (isUserChange) this.setState({ valid_from: selectedDate === null ? null : new Date(selectedDate) });
								}}
								value={this.state.valid_from === null ? null : formatISO(this.state.valid_from)} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-tax-rate-dialog--valid_until'>
							<TextWithTooltip
								label='Veljavno do'
								tooltip='Veljavno do' />
							<DateInput3
								id='add-edit-tax-rate-dialog--valid_until'
								dateFnsLocaleLoader={loadDateFnsLocale}
								dateFnsFormat={'dd. MM. yyyy'}
								locale='sl'
								inputProps={{ placeholder: '31. 12. ' + new Date().getFullYear() }}
								selectAllOnFocus={true}
								minDate={new Date('1900-01-01')}
								maxDate={moment().add('10', 'years').toDate()}
								onChange={(selectedDate, isUserChange) => {
									if (isUserChange) this.setState({ valid_until: selectedDate === null ? null : new Date(selectedDate) });
								}}
								value={this.state.valid_until === null ? null : formatISO(this.state.valid_until)} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-tax-rate-dialog--ord'
							className='flex-1'>
							<TextWithTooltip
								label='Vrstni red'
								tooltip='Vrstni red' />
							<NumericInput
								id='add-edit-tax-rate-dialog--ord'
								value={this.state.ord}
								min={0}
								minorStepSize={1}
								onValueChange={val => {
									this.setState({
										ord: val,
									});
								}} />
						</FormGroup>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const new_item = {
									id_tax_rate:   this.state.id_tax_rate,
									title:         this.state.title,
									value:         parseFloat(this.state.value),
									valid_from:    this.state.valid_from === null ? null : this.state.valid_from.toISOString(true),
									valid_until:   this.state.valid_until === null ? null : this.state.valid_until.toISOString(true),
									ord:           this.state.ord,
									taxable:       this.state.taxable,
									serial_internal_code: this.props.item === null ? null : this.props.item.serial_internal_code,
								};
								
								// validate missing/empty fields
								const validation_failed_fields       = [];
								const validation_failed_field_titles = [];
								
								if (new_item.title.length == 0) {
									validation_failed_fields.push('title');
									validation_failed_field_titles.push('naziv');
								}
								
								this.setState({ validation_failed_fields });
								
								if (validation_failed_fields.length > 0) {
									AppToaster.show({
										message: <div>
											Za shranjevanje, izpolnite vsaj polja:
											<br />
											{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
										</div>,
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								
								// save item
								this.props.closeAddEditItemDialog(new_item);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditTaxRate.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item: PropTypes.object,
};

export default AddEditTaxRate;
