import React, { useFfect } from 'react';

import moment from 'moment';

import PrintHelper   from '../../helpers/Print';
import InvoiceHelper from '../../helpers/Invoice';
import T             from '../../helpers/Translation';

import {
	CompanyDetails,
	Logo,
	CustomerDetails,
	Footer,
} from './Common';

function formatNumber(val, decimal_digit_count) {
	decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
	
	return parseFloat(val)
		.toLocaleString(
			undefined,
			{
				minimumFractionDigits: decimal_digit_count,
				maximumFractionDigits: decimal_digit_count,
			}
		);
}

function MainInvoiceDetails(props) {
	const title = props.item.type == 'income' ? 'BL. PREJEMEK' : 'BL. IZDATEK';
	
	const user = props.users[props.item.id_user];
	
	return <div className='main-invoice-details'>
		<div style={{ gridColumn: '1 / 3' }} className='font-large-6 deemphasized mb-05-line'>
			{ title }
		</div>
		
		<div className='emphasized mb-05-line font-large-1'>{T.Print.MainInvoiceDetails.InvoiceNumber[props.lang]}</div>
		<div className='emphasized mb-05-line font-large-1'>{props.item.document_number}</div>
		
		<div>Blagajna</div>
		<div>{ props.cash_registers[props.item.id_cash_register].title }</div>
		
		<div>Blagajnik</div>
		<div>{ (user.name + ' ' + user.surname).trim() }</div>
	</div>;
}
function InvoiceItemsTable(props) {
	let items = [];
	
	items.push([
		'Način plačila',
		'Tip plačila',
		'Opis',
		'Znesek',
	]);
	
	const typeTypeTitles = {
		'cash':          'Gotovina',
		'credit-card':   'Kartice',
		'bank-transfer': 'TRR',
		'gift-card':     'Bon',
		'other':         'Drugo',
	};
	
	let sum = 0;
	for (let i=0; i<(props.item.payment_types || []).length; i++) {
		const payment_type_item = props.item.payment_types[i];
		const payment_type      = props.payment_types[payment_type_item.id_payment_type];
		
		items.push([
			payment_type.title,
			typeTypeTitles[payment_type.type],
			payment_type_item.note,
			formatNumber(payment_type_item.amount, 2),
		]);
		
		sum += parseFloat(payment_type_item.amount);
	}
	
	let bold_last_line = false;
	if (items.length > 1) {
		items.push([
			'Skupaj',
			'',
			'',
			formatNumber(sum, 2),
		]);
		bold_last_line = true;
	}
	
	return <div className='cash-register-document-items-table'>
		{items.map((item, idx) => {
			let cls = idx == 0 ? 'cell header-cell' : 'cell';
			if (idx == (items.length - 1) && bold_last_line) {
				cls += ' emphasized-2';
			}
			
			return <React.Fragment key={'item-' + idx}>
				<div className={cls}                >{item[0]}</div>
				<div className={cls}                >{item[1]}</div>
				<div className={cls}                >{item[2]}</div>
				<div className={cls + ' text-right'}>{item[3]}</div>
			</React.Fragment>
		})}
	</div>;
}
function AdditionalDescription(props) {
	return <div className='additional-description'>
		<div className='mb-1-line'>{ props.item.note }</div>
	</div>;
}

export default function CashRegisterDocument(props) {
	const {
		company_data,
		item,
		payment_types,
		cash_registers,
		users,
		customer_country,
		settings,
		lang,
	} = props;
	
	let cls = 'print-document print-invoice';
	if (settings.logo_position == 'left') {
		cls += ' reverse-logo';
	}
	else if (settings.logo_position == 'hidden') {
		cls += ' hide-logo';
	}
	
	return <div className={cls}>
		<div className='print-invoice-main'>
			<CompanyDetails     lang={lang} company_data={company_data} />
			<Logo               lang={lang} company_data={company_data} />
			<CustomerDetails    lang={lang} item={item} customer_country={customer_country} />
			<MainInvoiceDetails lang={lang} item={item} company_data={company_data} cash_registers={cash_registers} users={users} payment_types={payment_types} />
			<InvoiceItemsTable  lang={lang} item={item} payment_types={payment_types} />
			<AdditionalDescription lang={lang} item={item} />
		</div>
		<Footer lang={lang} settings={settings} />
	</div>;
}
