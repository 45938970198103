import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Icon,
	Button,
} from '@blueprintjs/core';

import { setGeneralSettings } from '../../slices/SettingsSlice';
import { saveGeneralSettings } from '../../api/Settings';

import LocalStorageHelper from '../../helpers/LocalStorageHelper';

import { ResultHeader, Result } from '../code_tables/ResultComponents';

import EditSettingsItemDialog             from './EditSettingsItemDialog';
import EditSettingsItemFileDialog         from './EditSettingsItemFileDialog';
import EditSettingsItemSwitchDialog       from './EditSettingsItemSwitchDialog';
import EditSettingsItemDropdownDialog     from './EditSettingsItemDropdownDialog';
import EditSettingsItemMultilineDialog    from './EditSettingsItemMultilineDialog';
import EditSettingsItemPrinterWidthDialog from './EditSettingsItemPrinterWidthDialog';

const AutoPrintTypes = {
	'none': 'Ne tiskaj',
	'a4':   'A4',
	'pos':  'POS',
};

function Results(props) {
	const {
		item,
		openEditSettingsItemDialog,
		subject_types,
	} = props;
	
	return <div className='results mb-4'>
		<div className='results-table mt-4'>
			<ResultHeader columnIdx={1} title='Tiskanje' />
			<ResultHeader columnIdx={2} title='Nastavitev' />
			<ResultHeader columnIdx={3} title='Vrednost' />
		</div>
		
		<div className='results-table' key={'settings-result-3'}>
			{[
				{ key: 'pos_printer_name',              title: 'POS tiskalnik',                    value: LocalStorageHelper.GetValue(props.client + '__pos_printer_name')  },
				{ key: 'pos_printer_width',             title: 'Širina tiskanja POS (št. znakov)', value: LocalStorageHelper.GetValue(props.client + '__pos_printer_width') },
				{ key: 'logo_pos',                      title: 'POS logo',                         value: item['logo_pos'] },
				{ key: 'auto_print_type',               title: 'Privzeto tiskaj na',               value: AutoPrintTypes[item['auto_print_type']] },
				{ key: 'label_printer_name',            title: 'Tiskalnik nalepk',                 value: LocalStorageHelper.GetValue(props.client + '__label_printer_name')  },
				{ key: 'label_printer_item_template',   title: 'Predloga za nalepke artiklov',     value: item['label_printer_item_template'] },
				{ key: 'label_printer_person_template', title: 'Predloga za nalepke oseb',         value: item['label_printer_person_template'] },
				{ key: 'print_footer_a4',               title: 'Natisni nogo na A4',               value: item['print_footer_a4'] },
			].map(property => {
				let key   = property.key;
				let value = property.value;
				let type  = 'general';
				
				if (property.key == 'pos_printer_name' || property.key == 'auto_print_type' || property.key == 'label_printer_name') {
					type = 'dropdown';
				}
				else if (property.key == 'logo_pos') {
					value = value === null || value === undefined || value.length < 60 ? value :
						value.substring(0, 20) +
						'...' +
						value.substring(value.length - 20);
					type = 'file';
				}
				else if (property.key == 'label_printer_item_template' || property.key == 'label_printer_person_template') {
					value = value === null || value === undefined || value.length < 10 ?
						'ni nastavljeno' : 'nastavljeno';
					type = 'file';
				}
				else if (property.key == 'print_footer_a4') {
					value = <Icon icon={value ? 'tick' : 'cross'} />;
					type = 'bool';
				}
				
				let new_item = { ...item };
				if (key == 'pos_printer_name') {
					new_item[key] = value;
				}
				else if (key == 'pos_printer_width') {
					new_item[key] = value;
					type          = 'printer_width';
				}
				else if (key == 'label_printer_name') {
					new_item[key] = value;
				}
				
				return <React.Fragment key={'settings-result--property-' + key}>
					<Result columnIdx={1} child={
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => {
								openEditSettingsItemDialog(
									type,
									new_item,
									key
								);
							}} />
					} />
					<Result columnIdx={2} child={
						property.title
					} />
					<Result columnIdx={3} child={
						value
					} />
				</React.Fragment>;
			})}
		</div>
	</div>;
}

class PrintSettings extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps   = this.updateTableItemsFromProps  .bind(this);
		this.openEditSettingsItemDialog  = this.openEditSettingsItemDialog .bind(this);
		this.closeEditSettingsItemDialog = this.closeEditSettingsItemDialog.bind(this);
		
		const {
			item,
		} = this.updateTableItemsFromProps(props);
		
		this.state = {
			item,
			edit_settings_item_dialog_open:         false,
			edit_settings_item_dialog_item:         null,
			edit_settings_item_dialog_property_key: null,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.general_settings != this.state.item) {
			this.setState(
				this.updateTableItemsFromProps(this.props)
			);
		}
	}
	
	updateTableItemsFromProps(props) {
		return {
			item: props.general_settings,
		};
	}
	
	openEditSettingsItemDialog(type, item, property_key) {
		this.setState({
			edit_settings_item_dialog_open:         true,
			edit_settings_item_dialog_type:         type,
			edit_settings_item_dialog_item:         item,
			edit_settings_item_dialog_property_key: property_key,
		});
	}
	async closeEditSettingsItemDialog(item, property_key, new_value) {
		this.setState({
			edit_settings_item_dialog_open:         false,
			edit_settings_item_dialog_type:         null,
			edit_settings_item_dialog_item:         null,
			edit_settings_item_dialog_property_key: null,
		});
		
		if (item !== undefined) {
			if (property_key == 'pos_printer_name') {
				LocalStorageHelper.SetValue(this.props.client + '__pos_printer_name', new_value);
			}
			else if (property_key == 'pos_printer_width') {
				LocalStorageHelper.SetValue(this.props.client + '__pos_printer_width', new_value);
			}
			else if (property_key == 'label_printer_name') {
				LocalStorageHelper.SetValue(this.props.client + '__label_printer_name', new_value);
			}
			else {
				let new_item = {...item};
				new_item[property_key] = new_value;
				
				this.props.dispatch(setGeneralSettings(new_item));
				
				const dispatch = this.props.dispatch;
				const token    = this.props.token;
				
				new_item = await saveGeneralSettings(this.props.api_url, new_item, token);
				if (new_item !== null) {
					dispatch(setGeneralSettings(new_item));
				}
			}
		}
	}
	
	render() {
		const printers = {};
		if (this.props.printers !== undefined) {
			for (let i=0; i<this.props.printers.length; i++) {
				printers[this.props.printers[i]] = this.props.printers[i];
			}
		}
		printers['HTML'] = 'HTML';
		
		const label_printers = {};
		if (this.props.label_printers !== undefined) {
			for (let i=0; i<this.props.label_printers.length; i++) {
				label_printers[this.props.label_printers[i]] = this.props.label_printers[i];
			}
		}
		
		let edit_settings_dialog = null;
		if (this.state.edit_settings_item_dialog_open) {
			if (this.state.edit_settings_item_dialog_type == 'dropdown') {
				let options = [];
				if (this.state.edit_settings_item_dialog_property_key == 'pos_printer_name') {
					options = printers;
				}
				else if (this.state.edit_settings_item_dialog_property_key == 'label_printer_name') {
					options = label_printers;
				}
				else if (this.state.edit_settings_item_dialog_property_key == 'auto_print_type') {
					options = AutoPrintTypes;
				}
				
				edit_settings_dialog =
					<EditSettingsItemDropdownDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						closeDialog={this.closeEditSettingsItemDialog}
						options={options} />
			}
			else if (this.state.edit_settings_item_dialog_type == 'file') {
				edit_settings_dialog =
					<EditSettingsItemFileDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'multiline') {
				edit_settings_dialog =
					<EditSettingsItemMultilineDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'bool') {
				edit_settings_dialog =
					<EditSettingsItemSwitchDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else if (this.state.edit_settings_item_dialog_type == 'printer_width') {
				edit_settings_dialog =
					<EditSettingsItemPrinterWidthDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
			else {
				edit_settings_dialog =
					<EditSettingsItemDialog
						item={this.state.edit_settings_item_dialog_item}
						property_key={this.state.edit_settings_item_dialog_property_key}
						closeDialog={this.closeEditSettingsItemDialog} />;
			}
		}
		
		return <>
			{edit_settings_dialog}
			
			<div className='flex flex-col flex-grow general-settings-list'>
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							item={this.state.item}
							openEditSettingsItemDialog={this.openEditSettingsItemDialog}
							client={this.props.client} />
					</div>
				</div>
			</div>
		</>;
	}
}
PrintSettings.propTypes = {
};

function mapStateToProps(state) {
	return {
		general_settings: state.SettingsSlice.general,
		api_url:          state.SettingsSlice.api_url,
		token:            state.UserSlice.token,
		printers:         state.AppSlice.printers,
		label_printers:   state.AppSlice.label_printers,
		client:           state.ConstantsSlice.client,
	};
}

export default connect(mapStateToProps)(PrintSettings);
