import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import {
	Button,
	ButtonGroup,
	Intent,
	Tooltip,
	Checkbox,
} from '@blueprintjs/core';

import { useQueryParams } from 'raviger';

import DatePickerDialog from '../DatePickerDialog';
import PriceHelper from '../../helpers/PriceHelper';
import EditActivityItemDialog from '../reservations/EditActivityItemDialog';
import { addActivityReservation  } from '../../slices/ReservationSlice';
import { saveActivityReservation } from '../../api/Reservations';

class MainFilter extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			date: props.date,
			date_picker_dialog_open: false,
			selected_activities: props.selected_activities,
		};
	}
	
	render() {
		const current_date = this.state.date;
		
		const offsetCurrentDate = (offset_days) => {
			setCurrentDate(moment(current_date).add(offset_days, 'days').toDate());
		};
		const setCurrentDate = date => {
			this.setState({ date });
			this.props.onChangeDate(date);
		};
		
		const sortAndExtractIdActivities = (activities) => {
			return Object.values(activities).sort((a, b) => {
				if (a.ord < b.ord)      return -1;
				else if (a.ord > b.ord) return  1;
				                        return  0;
			}).map(item => item.id_activity);
		};
		const all_activities = sortAndExtractIdActivities(this.props.activities);
		
		const isActivitySelected = (activity) => {
			return this.state.selected_activities.indexOf(activity) != -1;
		};
		const toggleActivity = (activity) => {
			const selected_activities_arr = Array.from(this.state.selected_activities);
			
			if (isActivitySelected(activity)) {
				selected_activities_arr.splice(selected_activities_arr.indexOf(activity), 1);
			}
			else {
				selected_activities_arr.push(activity);
			}
			
			this.setState({
				selected_activities: selected_activities_arr,
			});
			this.props.onChangeSelectedActivities(selected_activities_arr);
		};
		
		const openFilterDatePickerDialog = () => {
			this.setState({ date_picker_dialog_open: true });
		};
		const closeFilterDatePickerDialog = (newValue) => {
			this.setState({ date_picker_dialog_open: false });
			
			if (newValue instanceof Date) {
				setCurrentDate(newValue);
			}
		};
		
		return <>
			{!this.state.date_picker_dialog_open ? null :
				<DatePickerDialog
					closeDatePickerDialog={closeFilterDatePickerDialog}
					value={current_date} />
			}
			
			<div className='flex flex-col items-center main-filters pl-4 pt-4'>
				<ButtonGroup>
					<Button onClick={() => { offsetCurrentDate(-30); }}>
						<svg viewBox='0 0 20 18' width='20' height='18' className='inline-block mr-1'><g fillRule='evenodd'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m14.589 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/></g></svg>
						30 dni
					</Button>
					<Button onClick={() => { offsetCurrentDate(-15); }}>
						<svg viewBox='0 0 14 18' width='14' height='18' className='inline-block mr-1'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
						15 dni
					</Button>
					<Button onClick={() => { offsetCurrentDate(-1); }}>
						<svg viewBox='0 0 9 18' width='9' height='18' className='inline-block mr-1'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
						1 dan
					</Button>
					<Button
						className='w-24'
						onClick={() => { openFilterDatePickerDialog() }}
						intent={Intent.PRIMARY}>
						{
							current_date.toLocaleString(
								'sl-SI',
								{ year: 'numeric', month: 'numeric', day: 'numeric' }
							)
						}
					</Button>
					<Button
						className='w-24'
						onClick={() => { setCurrentDate(new Date()); }}
						intent={Intent.PRIMARY}
						disabled={moment(current_date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')}>
						Danes
					</Button>
					<Button onClick={() => { offsetCurrentDate(1); }}>
						1 dan
						<svg viewBox='0 0 9 18' width='9' height='18' className='inline-block ml-1' transform='scale(-1,1)'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
					</Button>
					<Button onClick={() => { offsetCurrentDate(15); }}>
						15 dni
						<svg viewBox='0 0 14 18' width='14' height='18' className='inline-block ml-1' transform='scale(-1,1)'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z' fillRule='evenodd'/></svg>
					</Button>
					<Button onClick={() => { offsetCurrentDate(30); }}>
						30 dni
						<svg viewBox='0 0 20 18' width='20' height='18' className='inline-block ml-1' transform='scale(-1,1)'><g fillRule='evenodd'><path fill='#92a8b8' d='m3.41 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m9 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/><path fill='#92a8b8' d='m14.589 8 3.29-3.29c0.19-0.18 0.3-0.43 0.3-0.71a1.003 1.003 0 0 0-1.71-0.71l-4 4c-0.18 0.18-0.29 0.43-0.29 0.71s0.11 0.53 0.29 0.71l4 4a1.0041 1.0041 0 0 0 1.42-1.42z'/></g></svg>
					</Button>
				</ButtonGroup>
				
				<div className='flex-1 h-4 mt-2'>
					{all_activities.map(id_activity => {
						return <Checkbox
							key={'activity-' + id_activity}
							inline={true}
							checked={isActivitySelected(id_activity)}
							label={this.props.activities[id_activity].title}
							onChange={() => {
								toggleActivity(id_activity);
							}} />;
					})}
				</div>
			</div>
		</>;
	}
}

function groupActivityReservationsByTimeSlotActivities(activity_reservations, show_deleted, show_no_show, show_reversed) {
	const grouped_activity_reservations = {};
	if (activity_reservations !== undefined) {
		for (let key in activity_reservations) {
			const activity_reservation = activity_reservations[key];
			
			if (!show_deleted  && activity_reservation.deleted === true     ) continue;
			if (!show_no_show  && activity_reservation.status  == 'no-show' ) continue;
			if (!show_reversed && activity_reservation.status  == 'reversed') continue;
			
			if (grouped_activity_reservations[activity_reservation.id_accommodation_item_schedule_time_slot_activity] === undefined) {
				grouped_activity_reservations[activity_reservation.id_accommodation_item_schedule_time_slot_activity] = [];
			}
			
			grouped_activity_reservations[activity_reservation.id_accommodation_item_schedule_time_slot_activity].push(activity_reservation);
		}
	}
	
	return grouped_activity_reservations;
}

function getDocuments(document_associations, id_document, max_depth, depth) {
	depth = depth || 1;
	
	let items = {};
	if (depth > max_depth) return items;
	
	let documents = Object.values(document_associations[id_document] || {});
	
	for (let i=0; i<documents.length; i++) {
		let item = documents[i];
		let curr_id_document = item.source_id_document;
		let document_type    = item.source_type;
		
		if (item.source_id_document == id_document) {
			curr_id_document = item.target_id_document;
			document_type    = item.target_type;
		}
		
		if (items[item.id_document_association] === undefined) {
			items[item.id_document_association] = {
				id_document_association: item.id_document_association,
				created:                 item.created,
				id_document:             curr_id_document,
				document_type,
			};
		}
		
		const subitems = getDocuments(document_associations, curr_id_document, max_depth, depth + 1);
		for (let id_document_association in subitems) {
			if (items[id_document_association] === undefined) {
				items[id_document_association] = subitems[id_document_association];
			}
		}
	}
	
	return items;
}

function ActivityReports(props) {
	const {
		accommodation_item,
		activities,
		accommodation_item_schedule_time_slots,
		accommodation_item_schedule_time_slot_activities,
		accommodation_item_schedules,
		activity_reservations,
		customers,
		document_associations,
		invoices,
		selected_activity_ids,
		date,
		all_items,
		show_deleted,
		setShowDeleted,
		show_no_show,
		setShowNoShow,
		show_reversed,
		setShowReversed,
		show_invoices,
		setShowInvoices,
		show_drafts,
		setShowDrafts,
		setCurrentDate,
		dispatch,
		token,
		api_url,
		openInvoiceDialog,
		payment_types,
	} = props;
	
	const [ edit_activity_item_dialog_open,             setEditActivityItemDialogOpen            ] = useState(false);
	const [ edit_activity_item_dialog_item,             setEditActivityItemDialogItem            ] = useState(null);
	const [ edit_activity_item_dialog_reservation_date, setEditActivityItemDialogReservationDate ] = useState(null);
	const [
		edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity,
		setEditActivityItemDialogIdAccommodationItemScheduleTimeSlotActivity
	] = useState(-1);
	
	const isActivitySelected = id_activity => selected_activity_ids.length == 0 || selected_activity_ids.indexOf(id_activity) !== -1;
	
	const current_date = moment(date);
	const curr_date_iso = current_date.format('YYYY-MM-DD');
	
	const id_accommodation_item = accommodation_item.id_accommodation_item;
	
	const weekdays = [
		'sunday',   'monday', 'tuesday', 'wednesday',
		'thursday', 'friday', 'saturday'
	];
	
	const item_schedules = Object.values(accommodation_item_schedules)
		.filter(item => item.id_accommodation_item == id_accommodation_item)
		.sort((a, b) => {
			if (a.valid_from > b.valid_from) return -1;
			if (a.valid_from < b.valid_from) return  1;
			
			if (a.valid_until === null) return -1;
			if (b.valid_until === null) return  1;
			
			return 0;
		});
	
	// find the most appropriate schedule
	const current_date_iso = current_date.format('YYYY-MM-DD');
	let accommodation_item_schedule = null;
	for (let j=0; j<item_schedules.length; j++) {
		const item = item_schedules[j];
		if (
			item.valid_from <= current_date_iso &&
			(item.valid_until === null || item.valid_until >= current_date_iso) &&
			(accommodation_item_schedule === null || item.valid_from > accommodation_item_schedule.valid_from)
		) {
			accommodation_item_schedule = item;
		}
	}
	
	const items = [];
	
	if (accommodation_item_schedule !== null) {
		const time_slots = Object.values(accommodation_item_schedule_time_slots).filter(item =>
			item.id_accommodation_item_schedule == accommodation_item_schedule.id_accommodation_item_schedule);
		
		let time_slots_for_current_date = [];
		for (let i=0; i<time_slots.length; i++) {
			if (time_slots[i].day_of_week == weekdays[current_date.day()]) {
				time_slots_for_current_date.push(time_slots[i]);
			}
		}
		
		time_slots_for_current_date.sort((a, b) => {
			if      (a.starts_at < b.starts_at) return -1;
			else if (a.starts_at > b.starts_at) return  1;
			                                    return  0;
		});
		
		const activities_values = Object.values(accommodation_item_schedule_time_slot_activities);
		for (let i=0; i<time_slots_for_current_date.length; i++) {
			const time_slot  = time_slots_for_current_date[i];
			const selected_activities = activities_values.filter(item =>
				item.id_accommodation_item_schedule_time_slot == time_slot.id_accommodation_item_schedule_time_slot &&
				isActivitySelected(item.id_activity));
			
			if (selected_activities.length > 0 && Object.keys(selected_activities).length > 0) {
				const starts_at_arr = time_slot.starts_at.split(':');
				
				const time_label = starts_at_arr.length != 3 ? '00:00' : starts_at_arr[0] + ':' + starts_at_arr[1];
				
				const item = {
					id_time_slot: time_slot.id_accommodation_item_schedule_time_slot,
					time_label,
					activities: selected_activities.sort((a, b) => {
						return activities[a.id_activity].ord - activities[b.id_activity].ord;
					}).map(activity => {
						return {
							key:             activity.id_accommodation_item_schedule_time_slot_activity,
							title:           activities[activity.id_activity].title,
							max_guest_count: activity.max_guest_count,
							guests:          [],
						};
					}),
				};
				
				items.push(item);
			}
		}
		
		//all_items.push(items);
	}
	
	const group_activity_reservations = groupActivityReservationsByTimeSlotActivities(activity_reservations, show_deleted, show_no_show, show_reversed);
	console.log({ group_activity_reservations, curr_date_iso });
	
	let sum_amount                             = 0;
	let sum_amount_by_id_time_slot             = {};
	let sum_amount_by_id_time_slot_id_activity = {};
	const invoice_documents_by_id_reservations = {};
	
	for (let i=0; i<items.length; i++) {
		const item = items[i];
	
		for (let j=0; j<item.activities.length; j++) {
			const activity     = item.activities[j];
			const reservations = (group_activity_reservations[activity.key] || [])
				.filter(x => x.reservation_date == curr_date_iso);
			
			for (let j=0; j<reservations.length; j++) {
				const reservation = reservations[j];
				
				let invoice_documents = Object.values(getDocuments(
					document_associations,
					reservation.id_activity_reservation,
					3
				))
				.filter(document => document.document_type == 'invoice' || document.document_type == 'cash-invoice')
				.filter(document => {
					const invoice = invoices[document.id_document];
					if (!show_invoices && invoice.draft === false) return false;
					if (!show_drafts   && invoice.draft === true ) return false;
					return true;
				})
				.sort((a, b) => { // sort by created, descending
					if (a.created < b.created) return  1;
					if (a.created > b.created) return -1;
					return 0;
				});
				
				// remove duplicates
				invoice_documents = invoice_documents.filter(
					(value, index, self) => {
						return self.findIndex(
							x => x.document_type == value.document_type && x.id_document == value.id_document
						) === index;
					}
				);
				
				invoice_documents_by_id_reservations[reservation.id_activity_reservation] = invoice_documents;
				
				const amount = invoice_documents.reduce(
					(acc, val) => {
						return acc + parseFloat(invoices[val.id_document].invoice_amount);
					},
					0
				);
				
				sum_amount += amount;
				sum_amount_by_id_time_slot[item.id_time_slot] =
					(sum_amount_by_id_time_slot[item.id_time_slot] ?? 0) + amount;
				sum_amount_by_id_time_slot_id_activity[item.id_time_slot + '--' + activity.key] =
					(sum_amount_by_id_time_slot_id_activity[item.id_time_slot + '--' + activity.key] ?? 0) + amount;
			}
		}
	}
	
	const openEditActivityItemDialog = (
		item,
		id_accommodation_item_schedule_time_slot_activity,
		reservation_date
	) => {
		setEditActivityItemDialogOpen(true);
		setEditActivityItemDialogItem(item);
		setEditActivityItemDialogReservationDate(reservation_date);
		setEditActivityItemDialogIdAccommodationItemScheduleTimeSlotActivity(id_accommodation_item_schedule_time_slot_activity);
	};
	const closeEditActivityItemDialog = async (original_item, item) => {
		setEditActivityItemDialogOpen(false);
		
		if (item !== undefined) {
			let new_item = {};
			
			if (original_item !== null) { // existing item
				new_item = {...original_item};
				new_item.guest_count       = item.guest_count;
				new_item.guest_child_count = item.guest_child_count;
				new_item.note              = item.note;
				new_item.id_channel        = item.id_channel;
				new_item.id_customer       = item.id_customer;
				new_item.phone             = '';
				new_item.email             = '';
				new_item.reservation_date  = item.reservation_date;
				new_item.status            = item.status;
				new_item.status_2          = item.status_2 == '' ? null : item.status_2;
				new_item.deleted           = item.deleted;
			}
			else { // new item
				new_item = {
					id_activity_reservation: -1,
					guest_count:             item.guest_count,
					guest_child_count:       item.guest_child_count,
					note:                    item.note,
					id_channel:              item.id_channel,
					id_customer:             item.id_customer,
					phone:                   '',
					email:                   '',
					status:                  item.status,
					status_2:                item.status_2 == '' ? null : item.status_2,
					reservation_date:        item.reservation_date,
					id_accommodation_item_schedule_time_slot_activity: edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity,
					deleted:                 false,
					username:                props.user.username,
				};
			}
			
			dispatch(addActivityReservation({
				item: new_item,
				token,
			}));
			new_item = await saveActivityReservation(api_url, new_item, token);
			if (new_item !== null) {
				dispatch(addActivityReservation({
					item: new_item,
					token,
				}));
			}
		}
	};
	
	return <>
		{!edit_activity_item_dialog_open ? null :
			<EditActivityItemDialog
				closeEditActivityItemDialog={closeEditActivityItemDialog}
				item={edit_activity_item_dialog_item}
				reservation_date={edit_activity_item_dialog_reservation_date}
				time_slot={
					accommodation_item_schedule_time_slots[
						accommodation_item_schedule_time_slot_activities[
							edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity
						].id_accommodation_item_schedule_time_slot
					]
				}
				activity={
					activities[
						accommodation_item_schedule_time_slot_activities[
							edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity
						].id_activity
					]
				}
				accommodation_item_schedule_time_slot_activity={
					accommodation_item_schedule_time_slot_activities[
						edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity
					]
				}
				setCurrentDate={setCurrentDate} />
		}
		
		<div className='reports-activity-reservations-list m-4 flex-1'>
			<div className='col col-1 time-header-col bg-gray-600 text-white flex flex-row items-center' style={{ backgroundColor: '#394b59' }}>
				<div className='pl-1 pr-4 pt-2'>
					<Checkbox
						checked={show_deleted}
						onChange={event => {
							setShowDeleted(event.target.checked);
						}}
						label='Brisane' />
				</div>
				<div className='pl-1 pr-4 pt-2'>
					<Checkbox
						checked={show_no_show}
						onChange={event => {
							setShowNoShow(event.target.checked);
						}}
						label='Neprihod' />
				</div>
				<div className='pl-1 pr-4 pt-2'>
					<Checkbox
						checked={show_reversed}
						onChange={event => {
							setShowReversed(event.target.checked);
						}}
						label='Stornirane' />
				</div>
				<div className='pl-8 pr-4 pt-2'>
					<Checkbox
						checked={show_invoices}
						onChange={event => {
							setShowInvoices(event.target.checked);
						}}
						label='Računi' />
				</div>
				<div className='pl-1 pr-4 pt-2'>
					<Checkbox
						checked={show_drafts}
						onChange={event => {
							setShowDrafts(event.target.checked);
						}}
						label='Osnutki' />
				</div>
			</div>
			<div className='col col-2 time-header-col bg-gray-600 text-white flex flex-row items-center' style={{ backgroundColor: '#394b59' }}>
			</div>
			<div className='col col-3 time-header-col bg-gray-600 text-white flex flex-row items-center' style={{ backgroundColor: '#394b59' }}>
			</div>
			<div className='col col-4 time-header-col bg-gray-600 text-white flex flex-row items-center' style={{ backgroundColor: '#394b59' }}>
			</div>
			<div className='col col-5 time-header-col bg-gray-600 text-white flex flex-row items-center' style={{ backgroundColor: '#394b59' }}>
			</div>
			<div className='col col-6 time-header-col bg-gray-600 text-white flex flex-row items-center' style={{ backgroundColor: '#394b59' }}>
			</div>
			<div className='col col-7 time-header-col bg-gray-600 text-white flex flex-row items-center' style={{ backgroundColor: '#394b59' }}>
			</div>
			<div className='col col-8 time-header-col bg-gray-600 text-white flex flex-row items-center justify-end text-right' style={{ backgroundColor: '#394b59' }}>
				<div className='pr-2'>{ sum_amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }</div>
			</div>
			{/*<div className='col col-9 time-header-col bg-gray-600 text-white flex flex-row items-center' style={{ backgroundColor: '#394b59' }}>
			</div>*/}
			
			{items.map((item, item_idx) => {
				let reservation_count = 0;
				for (let i=0; i<item.activities.length; i++) {
					const activity = item.activities[i];
					const reservations = (group_activity_reservations[activity.key] || [])
						.filter(x => x.reservation_date == curr_date_iso);
					
					reservation_count += reservations.length;
				}
				if (reservation_count == 0) return null;
				
				const counts = item.activities.reduce(
					(acc, val) => {
						const reservations = (group_activity_reservations[val.key] || [])
							.filter(x => x.reservation_date == curr_date_iso);
						
						return [
							acc[0] + reservations.reduce((accx, x) => accx + x.guest_count, 0),
							acc[1] + reservations.reduce((accx, x) => accx + x.guest_child_count, 0),
							acc[2] + val.max_guest_count
						];
					},
					[ 0, 0, 0 ]
				);
				
				return <React.Fragment key={item.id_time_slot}>
					<div className='col col-1 time-header-col bg-gray-600 text-white flex flex-row items-center time-label'>
						{ item.time_label }
					</div>
					<div className='col col-2 time-header-col bg-gray-600 text-white flex flex-row items-center'>
						{ counts[0] + ' / ' + counts[1] }
					</div>
					<div className='col col-3 time-header-col bg-gray-600 text-white flex flex-row items-center'>
					</div>
					<div className='col col-4 time-header-col bg-gray-600 text-white flex flex-row items-center'>
					</div>
					<div className='col col-5 time-header-col bg-gray-600 text-white flex flex-row items-center'>
					</div>
					<div className='col col-6 time-header-col bg-gray-600 text-white flex flex-row items-center'>
					</div>
					<div className='col col-7 time-header-col bg-gray-600 text-white flex flex-row items-center'>
					</div>
					<div className='col col-8 time-header-col bg-gray-600 text-white flex flex-row items-center justify-end text-right'>
						<div className='pr-2'>{ sum_amount_by_id_time_slot[item.id_time_slot].toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }</div>
					</div>
					{/*<div className='col col-9 time-header-col bg-gray-600 text-white flex flex-row items-center'>
						Osebe
					</div>*/}
					<>
						{item.activities.map(activity => {
							const reservations = (group_activity_reservations[activity.key] || [])
								.filter(x => x.reservation_date == curr_date_iso)
								.sort((a, b) => {
									if (a.created > b.created) return  1;
									if (a.created < b.created) return -1;
									return 0;
								});
							
							if (reservations.length == 0) return null;
							
							const reservation_items_grouped = {};
							for (let i=0; i<reservations.length; i++) {
								const reservation                                    = reservations[i];
								const accommodation_item_schedule_time_slot_activity = accommodation_item_schedule_time_slot_activities[reservation.id_accommodation_item_schedule_time_slot_activity];
								const activity                                       = activities[accommodation_item_schedule_time_slot_activity.id_activity];
								
								if (activity.id_item === null) continue;
								
								const item = all_items[activity.id_item];
								
								if (reservation.guest_child_count > 0) {
									const key = item.id_item + (activity.item_child_discount > 0 ? ('--' + activity.item_child_discount) : '');
									if (reservation_items_grouped[key] === undefined) {
										reservation_items_grouped[key] = {
											id_item:  item.id_item,
											count:    0,
											discount: activity.item_child_discount,
										};
									}
									reservation_items_grouped[key].count += reservation.guest_child_count;
								}
								if (reservation.guest_count > 0) {
									const key = item.id_item;
									if (reservation_items_grouped[key] === undefined) {
										reservation_items_grouped[key] = {
											id_item:  item.id_item,
											count:    0,
											discount: 0,
										};
									}
									reservation_items_grouped[key].count += reservation.guest_count;
								}
							}
							
							return <React.Fragment key={item.id_time_slot + '-' + activity.key}>
								<div className='col col-1 activity-header-col bg-gray-400 font-semibold flex flex-row items-center activity-label'>
									{ activity.title }
								</div>
								<div className='col col-2 activity-header-col bg-gray-400 font-semibold flex flex-row items-center'>Št. oseb</div>
								<div className='col col-3 activity-header-col bg-gray-400 font-semibold flex flex-row items-center'>Opomba</div>
								<div className='col col-4 activity-header-col bg-gray-400 font-semibold flex flex-row items-center'>Datum</div>
								<div className='col col-5 activity-header-col bg-gray-400 font-semibold flex flex-row items-center'>Št. računa</div>
								<div className='col col-6 activity-header-col bg-gray-400 font-semibold flex flex-row items-center'>Način plačila</div>
								<div className='col col-7 activity-header-col bg-gray-400 font-semibold flex flex-row items-center'>Blagajnik</div>
								<div className='col col-8 activity-header-col bg-gray-400 font-semibold flex flex-row items-center justify-end text-right'>
									<div className='pr-2'>Znesek (EUR)</div>
								</div>
								{/*<div className='col col-9 activity-header-col bg-gray-400 font-semibold flex flex-row items-center'></div>*/}
								
								{reservations.map((reservation, reservation_idx) => {
									const customer = customers[reservation.id_customer] ?? null;
									
									const invoice_documents = invoice_documents_by_id_reservations[reservation.id_activity_reservation];
									
									let pattern_class_name = '';
									if (reservation.status == 'offer-sent') {
										pattern_class_name = 'reservation-list-pattern-polka-dots';
									}
									else if (reservation.status == 'advance-invoice-sent' || reservation.status == 'not-for-rent' /*with red background*/) {
										pattern_class_name = 'reservation-list-pattern-diagonal-stripes';
									}
									
									let cls =
										pattern_class_name +
										' ' +
										'status-' + (reservation.deleted === true ? 'deleted' : reservation.status) +
										' ' +
										(reservation.status_2 === null ? '' : reservation.status_2);
									
									return <React.Fragment key={reservation.id_activity_reservation}>
										<div className={
											'border-b border-gray-500 border-solid flex flex-row col col-1 reservation' +
											' ' + cls
										}>
											<svg version='1.1'
												baseProfile='full'
												width='16' height='16'
												className='custom-status'
												xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M 0,0 L 16,0 L 0,16 L 0,0 z'
													fill='#ff0000' />
											</svg>
											
											<div className='pl-2 pr-3 w-24'>
												<Button className='cell-button' small={true} onClick={() => openEditActivityItemDialog(
													reservation,
													reservation.id_accommodation_item_schedule_time_slot_activity,
													reservation.reservation_date
												)}>
													{ reservation.internal_code }
												</Button>
											</div>
											<div className='customer-name'>{
												(customer === null ? '' :
													(customer.type == 'natural' ?
														customer.surname + ' ' + customer.name
														:
														customer.company_name
													)
												)
											}</div>
										</div>
										<div className={
											'border-b border-gray-500 border-solid flex flex-row col col-2 reservation' +
											' ' + cls
										}>
											{ reservation.guest_count } / { reservation.guest_child_count }
										</div>
										<div className={
											'border-b border-gray-500 border-solid flex flex-col col col-3 reservation' +
											' ' + cls
										}>
											<Tooltip content={<div dangerouslySetInnerHTML={{__html: reservation.note.replaceAll('\n', '<br />') }} />} targetClassName='w-full'>
												<div className='truncate'>
													{ reservation.note }
												</div>
											</Tooltip>
										</div>
										<div className={
											'border-b border-gray-500 border-solid flex flex-col col col-4 reservation' +
											' ' + cls
										}>
											{invoice_documents.map(invoice => {
												return <div key={'date-' + invoice.id_document} style={{ lineHeight: '25px' }}>
													{ moment(invoices[invoice.id_document].invoice_date).format('DD.MM.YYYY') }
												</div>;
											})}
										</div>
										<div className={
											'border-b border-gray-500 border-solid flex flex-col col col-5 reservation' +
											' ' + cls
										}>
											{invoice_documents.map(invoice => {
												return <div key={'invoice-number-internal-' + invoice.id_document}>
													<Button className='cell-button' small={true} onClick={() => openInvoiceDialog(
														invoices[invoice.id_document],
														invoices[invoice.id_document].invoice_type
													)}>
														{
															invoices[invoice.id_document].draft === true ? 'osnutek' :
																invoices[invoice.id_document].invoice_number_internal
														}
													</Button>
												</div>;
											})}
										</div>
										<div className={
											'border-b border-gray-500 border-solid flex flex-col col col-6 reservation' +
											' ' + cls
										}>
											{invoice_documents.map(invoice => {
												return <div key={'invoice-payment-type-' + invoice.id_document} style={{ lineHeight: '25px' }}>
													{ invoices[invoice.id_document].payments.map(x => payment_types[x.id_payment_type].title).join(', ') }
												</div>;
											})}
										</div>
										<div className={
											'border-b border-gray-500 border-solid flex flex-col col col-7 reservation' +
											' ' + cls
										}>
											{invoice_documents.map(invoice => {
												return <div key={'invoice-payment-type-' + invoice.id_document} style={{ lineHeight: '25px' }}>
													{ invoices[invoice.id_document].username }
												</div>;
											})}
										</div>
										<div className={
											'border-b border-gray-500 border-solid flex flex-col justify-end text-right col col-8 reservation' +
											' ' + cls
										}>
											<div className='pr-2'>
												{invoice_documents.map(invoice => {
													return <div key={'amount-' + invoice.id_document} style={{ lineHeight: '25px' }}>
														{ parseFloat(invoices[invoice.id_document].invoice_amount)
															.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }
														
														{/*(ikona za dol, ki prikaž specifikacijo računa)*/}
													</div>;
												})}
											</div>
										</div>
										{/*<div className={
											'border-b border-gray-500 border-solid flex flex-col col col-9 reservation' +
											' ' + cls
										}>
											{true ? null : <>
												<div>Grega K. (8)</div>
												<div>Nejc R. (6)</div>
												<div>Ana P. (6)</div>
											</>}
										</div>*/}
									</React.Fragment>;
								})}
								
								{reservations.length == 0 ? null :
									<>
										<div className='col col-1 border-b border-gray-500 border-solid flex flex-row items-center'>
											{true ? null : Object.keys(reservation_items_grouped).map(key => {
												const item = reservation_items_grouped[key];
												
												const id_price_list = PriceHelper.FindBestPriceListMatch(curr_date_iso, all_items[item.id_item].price_lists);
												const item_price_list = id_price_list === null ? null : all_items[item.id_item].price_lists[id_price_list];
												
												const price = item_price_list === null ? 0 : item_price_list.price;
												
												return <div key={'reservation-item-grouped--' + key} className='pr-4 pl-2'>
													<span>{item.count}</span>
													<span> × </span>
													<span>{all_items[item.id_item].internal_code}</span>
													<span> </span>
													<span>[{parseFloat(price).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}]</span>
													<span>{item.discount == 0 ? '' : (' - ' + parseFloat(item.discount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2}) + '%')}</span>
													<span> = </span>
													<span className='font-semibold'>{
														((price * item.count) * (1 - item.discount / 100))
															.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
													}</span>
												</div>;
											})}
										</div>
										<div className='col col-2 border-b border-gray-500 border-solid flex flex-row items-center'></div>
										<div className='col col-3 border-b border-gray-500 border-solid flex flex-row items-center'></div>
										<div className='col col-4 border-b border-gray-500 border-solid flex flex-row items-center'></div>
										<div className='col col-5 border-b border-gray-500 border-solid flex flex-row items-center'></div>
										<div className='col col-6 border-b border-gray-500 border-solid flex flex-row items-center'></div>
										<div className='col col-7 border-b border-gray-500 border-solid flex flex-row items-center'></div>
										<div className='col col-8 border-b border-gray-500 border-solid flex flex-row items-center justify-end text-right font-semibold'>
											<div className='pr-2'>{ (sum_amount_by_id_time_slot_id_activity[item.id_time_slot + '--' + activity.key] ?? 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }</div>
										</div>
										{/*<div className='col col-9 border-b border-gray-500 border-solid flex flex-row items-center'></div>*/}
									</>
								}
							</React.Fragment>;
						})}
					</>
				</React.Fragment>;
			})}
		</div>
	</>;
}

function ActivityReservations(props) {
	let [ { current_date, selected_activities, show_deleted, show_no_show, show_reversed, show_invoices, show_drafts }, setQuery ] = useQueryParams();
	
	current_date        = current_date        === undefined ? new Date() : new Date(current_date);
	selected_activities = selected_activities === undefined ? []         : selected_activities;
	
	show_deleted  = show_deleted  === 'true';
	show_no_show  = show_no_show  === 'true';
	show_reversed = show_reversed === 'true';
	show_invoices = show_invoices === undefined ? true : (show_invoices === 'true');
	show_drafts   = show_drafts   === 'true';
	
	const getCurrentQueryParams = () => {
		return {
			current_date: moment(current_date).format('YYYY-MM-DD'),
			selected_activities,
			show_deleted,
			show_no_show,
			show_reversed,
			show_invoices,
			show_drafts,
		};
	};
	const setCurrentDate = (value) => {
		setQuery({
			...getCurrentQueryParams(),
			current_date: moment(value).format('YYYY-MM-DD'),
		});
	};
	const setSelectedActivities = (value) => {
		setQuery({
			...getCurrentQueryParams(),
			selected_activities: value,
		});
	};
	const setShowDeleted = (value) => {
		setQuery({
			...getCurrentQueryParams(),
			show_deleted: value,
		});
	};
	const setShowNoShow = (value) => {
		setQuery({
			...getCurrentQueryParams(),
			show_no_show: value,
		});
	};
	const setShowReversed = (value) => {
		setQuery({
			...getCurrentQueryParams(),
			show_reversed: value,
		});
	};
	const setShowInvoices = (value) => {
		setQuery({
			...getCurrentQueryParams(),
			show_invoices: value,
		});
	};
	const setShowDrafts = (value) => {
		setQuery({
			...getCurrentQueryParams(),
			show_drafts: value,
		});
	};
	
	console.log({ client: props.client });
	if ([ 'test', 'lodgge', 'sr' ].indexOf(props.client) == -1) return null;
	if ([ 'admin', 'goran', 'carolina', 'martina', 'anita', 'simona' ].indexOf(props.user.username) == -1) return null;
	
	const accommodation_activities = Object.values(props.accommodations).filter(x => x.type == 'activities');
	const accommodation_activity   = accommodation_activities.length > 0 ? accommodation_activities[0] : null;
	
	if (accommodation_activity === null) {
		return null;
	}
	
	const accommodation_items = Object.values(props.accommodation_items).filter(x => x.id_accommodation == accommodation_activity.id_accommodation);
	const accommodation_item  = accommodation_items.length > 0 ? accommodation_items[0] : null;
	
	if (accommodation_item === null) {
		return null;
	}
	
	return <>
		<div className='flex flex-col'>
			<MainFilter
				activities={props.activities}
				date={current_date}
				onChangeDate={date => {
					setCurrentDate(date ?? new Date());
				}}
				selected_activities={selected_activities}
				onChangeSelectedActivities={selected_activities => {
					setSelectedActivities(selected_activities);
				}} />
		</div>
		
		<div className='flex flex-row'>
			<ActivityReports
				accommodation_item                               = {accommodation_item}
				activities                                       = {props.activities}
				accommodation_item_schedule_time_slots           = {props.accommodation_item_schedule_time_slots}
				accommodation_item_schedule_time_slot_activities = {props.accommodation_item_schedule_time_slot_activities}
				accommodation_item_schedules                     = {props.accommodation_item_schedules}
				activity_reservations                            = {props.activity_reservations}
				customers                                        = {props.customers}
				document_associations                            = {props.document_associations}
				invoices                                         = {props.invoices}
				selected_activity_ids                            = {selected_activities}
				date                                             = {current_date}
				all_items                                        = {props.items}
				show_deleted                                     = {show_deleted}
				setShowDeleted                                   = {setShowDeleted}
				show_no_show                                     = {show_no_show}
				setShowNoShow                                    = {setShowNoShow}
				show_reversed                                    = {show_reversed}
				setShowReversed                                  = {setShowReversed}
				show_invoices                                    = {show_invoices}
				setShowInvoices                                  = {setShowInvoices}
				show_drafts                                      = {show_drafts}
				setShowDrafts                                    = {setShowDrafts}
				setCurrentDate                                   = {setCurrentDate}
				dispatch                                         = {props.dispatch}
				token                                            = {props.token}
				api_url                                          = {props.api_url}
				openInvoiceDialog                                = {props.openInvoiceDialog}
				payment_types                                    = {props.payment_types} />
		</div>
	</>;
}

function mapStateToProps(state) {
	return {
		client:                                           state.ConstantsSlice.client,
		api_url:                                          state.CashRegisterSlice.api_url,
		token:                                            state.UserSlice.token,
		user:                                             state.UserSlice.user,
		users:                                            state.UserSlice.users,
		invoices:                                         state.BusinessSlice.invoices,
		items:                                            state.CodeTablesSlice.items,
		activities:                                       state.CodeTablesSlice.activities,
		payment_types:                                    state.CodeTablesSlice.payment_types,
		accommodations:                                   state.CodeTablesSlice.accommodations,
		accommodation_items:                              state.CodeTablesSlice.accommodation_items,
		accommodation_item_schedules:                     state.CodeTablesSlice.accommodation_item_schedules,
		accommodation_item_schedule_time_slots:           state.CodeTablesSlice.accommodation_item_schedule_time_slots,
		accommodation_item_schedule_time_slot_activities: state.CodeTablesSlice.accommodation_item_schedule_time_slot_activities,
		activity_reservations:                            state.ReservationSlice.activity_reservations,
		customers:                                        state.CodeTablesSlice.customers,
		document_associations:                            state.DocumentSlice.document_associations_id_documents,
		invoices:                                         state.BusinessSlice.invoices,
	};
}

export default connect(mapStateToProps)(ActivityReservations);
