import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Button,
	MenuItem,
	Alignment,
	Intent,
	Alert,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import AddEditPostOffice from './AddEditPostOffice';
import { addPostOffice, deletePostOffice } from '../../slices/CodeTablesSlice';

import { ResultHeader, ResultHeaderWithSort, Result, TextFilter } from './ResultComponents';

function Results(props) {
	const {
		countries,
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		openAddEditItemDialog,
		tableItemsFilters,
		handleFilterChange,
		deleteItem,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	return <div className='results mt-4 mb-4'>
		<div className='results-table'>
			<ResultHeader columnIdx={1} title='' />
			<ResultHeader columnIdx={2} title='Urejanje' />
			<ResultHeaderWithSort
				columnIdx={3} {...sort_props} title='Poštna številka' fieldName='post_code' />
			<ResultHeaderWithSort
				columnIdx={4} {...sort_props} title='Naziv' fieldName='title' />
			<ResultHeader columnIdx={5} title='Država' />
		</div>
		
		<div className='results-table filters-row'>
			<Result columnIdx={1} />
			<Result columnIdx={2} />
			<Result columnIdx={3} child={
				<TextFilter
					value={tableItemsFilters.post_code}
					onChange={ event => handleFilterChange('post_code', event) } />
			} />
			<Result columnIdx={4} child={
				<TextFilter
					value={tableItemsFilters.title}
					onChange={ event => handleFilterChange('title', event) } />
			} />
			<Result columnIdx={5} child={
				<Select
					items={Object.values(countries)}
					itemPredicate={(query, item, idx, exactMatch) => {
						return item.official_name_local.toUpperCase().indexOf(query.toUpperCase()) > -1;
					}}
					itemRenderer={(item, { handleClick, modifiers }) => {
						return <MenuItem
							active={modifiers.active}
							key={item.id_country}
							onClick={handleClick}
							text={item.official_name_local} />;
					}}
					onItemSelect={item => {
						handleFilterChange('id_country', item.id_country == 0 ? null : item.id_country);
					}}
					popoverProps={{
						targetTagName: 'div',
					}}>
					<Button
						text={
							tableItemsFilters.id_country == null ?
								'Izberi državo'
								:
								countries[tableItemsFilters.id_country].official_name_local
						}
						rightIcon='double-caret-vertical'
						fill={true}
						small={true}
						alignText={Alignment.LEFT} />
				</Select>
			} />
		</div>
		
		{table_items_sort_ids.map((id, idx) => {
			const item = table_items[id];
			
			return <div className='results-table' key={'countries-result--result-' + id}>
				<Result columnIdx={1} child={
					item.serial_internal_code
				} />
				<Result columnIdx={2} child={
					<>
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => openAddEditItemDialog(item)} />
						<Button
							icon='trash'
							intent='danger'
							minimal={true}
							small={true}
							onClick={() => deleteItem(item)} />
					</>
				} />
				<Result columnIdx={3} child={
					item.post_code
				} />
				<Result columnIdx={4} child={
					item.title
				} />
				<Result columnIdx={5} child={
					countries[item.id_country].official_name_local
				} />
			</div>;
		})}
	</div>;
}

class PostOffices extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps = this.updateTableItemsFromProps.bind(this);
		this.setTableItemsSort         = this.setTableItemsSort        .bind(this);
		this.refreshTableItemsSort     = this.refreshTableItemsSort    .bind(this);
		this.filterTableItems          = this.filterTableItems         .bind(this);
		this.handleFilterChange        = this.handleFilterChange       .bind(this);
		this.openAddEditItemDialog     = this.openAddEditItemDialog    .bind(this);
		this.closeAddEditItemDialog    = this.closeAddEditItemDialog   .bind(this);
		this.deleteItem                = this.deleteItem               .bind(this);
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props);
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: '',
			table_items_sort_direction: 'ASC',
			table_items_filters: {
				post_code:  '',
				title:      '',
				id_country: null,
			},
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			item_delete_requested: null,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.post_offices != this.state.table_items) {
			this.setState(
				this.updateTableItemsFromProps(this.props)
			);
		}
	}
	
	updateTableItemsFromProps(props) {
		const ids = Object.keys(props.post_offices);
		
		let sort_ids = [];
		
		if (this.state !== undefined && this.state.table_items_sort_ids !== undefined) {
			for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
				const id = this.state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		//TODO refresh sorting
		//TODO call this.filterTableItems(new_filters);
		
		return {
			table_items: props.post_offices,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
		};
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids) {
		table_items_filtered_ids = table_items_filtered_ids || this.state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			const a = this.state.table_items[a_key][field];
			const b = this.state.table_items[b_key][field];
			
			if (field == 'title') {
				return a.localeCompare(b) * (direction == 'ASC' ? 1 : -1);
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (direction == 'ASC' ? 1 : -1);
		});
		
		this.setState({
			table_items_sort_ids: ids,
		});
	}
	
	filterTableItems(filters) {
		const filters_post_code  = filters.post_code.toUpperCase();
		const filters_title      = filters.title.toUpperCase();
		const filters_id_country = filters.id_country;
		
		const ids = Object.keys(this.state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = this.state.table_items[id];
			
			if (filters_post_code != '' && item.post_code.toUpperCase().indexOf(filters_post_code) == -1) {
				return false;
			}
			
			if (filters_title != '' && item.title.toUpperCase().indexOf(filters_title) == -1) {
				return false;
			}
			
			if (filters_id_country !== null && item.id_country != filters_id_country) {
				return false;
			}
			
			return true;
		});
		
		this.setState({
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		});
		
		this.refreshTableItemsSort(
			this.state.table_items_sort_field,
			this.state.table_items_sort_direction,
			filtered_ids
		);
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			post_code:  this.state.table_items_filters.post_code,
			title:      this.state.table_items_filters.title,
			id_country: this.state.table_items_filters.id_country,
		};
		
		let val = event;
		if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: true, add_edit_item_dialog_item: item });
	}
	closeAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: false });
		
		if (item !== undefined) {
			this.props.dispatch(addPostOffice({ item, token: this.props.token, save_to_api: true }));
		}
	}
	deleteItem(item) {
		this.setState({ item_delete_requested: item, token: this.props.token });
	}
	
	render() {
		return <>
			{this.state.item_delete_requested === null ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Briši'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						this.props.dispatch(deletePostOffice(this.state.item_delete_requested.id_post_office));
						this.setState({ item_delete_requested: null });
					}}
					onCancel={() => {
						this.setState({ item_delete_requested: null });
					}}>
					Ali res želite izbrisati ta zapis?
				</Alert>
			}
			
			{!this.state.add_edit_item_dialog_open ? null :
				<AddEditPostOffice
					closeAddEditItemDialog={this.closeAddEditItemDialog}
					item={this.state.add_edit_item_dialog_item}
					countries={this.props.countries} />
			}
			
			<div className='flex flex-col flex-grow post-offices-list'>
				<div className='pl-4 pt-4'>
					<Button intent='primary' icon='plus' onClick={() => this.openAddEditItemDialog(null)}>
						Nov vnos
					</Button>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							countries={this.props.countries}
							table_items={this.state.table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openAddEditItemDialog={this.openAddEditItemDialog}
							tableItemsFilters={this.state.table_items_filters}
							handleFilterChange={this.handleFilterChange}
							deleteItem={this.deleteItem} />
					</div>
				</div>
			</div>
		</>;
	}
}
PostOffices.propTypes = {
};

function mapStateToProps(state) {
	return {
		countries:    state.CodeTablesSlice.countries,
		post_offices: state.CodeTablesSlice.post_offices,
		token:        state.UserSlice.token,
	};
}

export default connect(mapStateToProps)(PostOffices);
