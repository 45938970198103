import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Button,
	Intent,
	Alert,
} from '@blueprintjs/core';

import moment from 'moment';

import AddEditTaxRate  from './AddEditTaxRate';
import { addTaxRate, deleteTaxRate } from '../../slices/CodeTablesSlice';

import { ResultHeader, ResultHeaderWithSort, Result, TextFilter } from './ResultComponents';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		openAddEditItemDialog,
		tableItemsFilters,
		handleFilterChange,
		deleteItem,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	const taxable_titles = {
		'taxable':    'obdavčeno',
		'nontaxable': 'neobdavčeno',
		'tax-exempt': 'oproščeno',
	};
	
	return <div className='results mt-4 mb-4'>
		<div className='results-table'>
			<ResultHeader columnIdx={1} title='' />
			<ResultHeader columnIdx={2} title='Urejanje' />
			<ResultHeaderWithSort
				columnIdx={3} {...sort_props} title='Vrednost (%)' fieldName='value' />
			<ResultHeaderWithSort
				columnIdx={4} {...sort_props} title='Naziv' fieldName='title' />
			<ResultHeader
				columnIdx={5} title='Tip obdavčitve' />
			<ResultHeader
				columnIdx={6} title='Velja od/do' />
			<ResultHeaderWithSort
				columnIdx={7} {...sort_props} title='Vrstni red' fieldName='ord' />
		</div>
		
		<div className='results-table filters-row'>
			<Result columnIdx={1} />
			<Result columnIdx={2} />
			<Result columnIdx={3} />
			<Result columnIdx={4} child={
				<TextFilter
					value={tableItemsFilters.title}
					onChange={ event => handleFilterChange('title', event) } />
			} />
			<Result columnIdx={5} />
			<Result columnIdx={6} />
			<Result columnIdx={7} />
		</div>
		
		{table_items_sort_ids.map((id, idx) => {
			const item = table_items[id];
			
			let is_active = true;
			
			const current_date_iso =                                     moment()                .format('YYYY-MM-DD');
			const valid_from_iso   =                                     moment(item.valid_from) .format('YYYY-MM-DD');
			const valid_until_iso  = (item.valid_until === null ? null : moment(item.valid_until).format('YYYY-MM-DD'));
			
			if (current_date_iso < valid_from_iso) {
				is_active = false;
			}
			else if (valid_until_iso !== null && current_date_iso > valid_until_iso) {
				is_active = false;
			}
			
			const cls = 'validity-' + (is_active ? 'active' : 'inactive');
			
			return <div className='results-table' key={'countries-result--result-' + id}>
				<Result columnIdx={1} cls={cls} child={
					item.serial_internal_code
				} />
				<Result columnIdx={2} cls={cls} child={
					<>
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => openAddEditItemDialog(item)} />
						<Button
							icon='trash'
							intent='danger'
							minimal={true}
							small={true}
							onClick={() => deleteItem(item)} />
					</>
				} />
				<Result columnIdx={3} cls={cls + ' text-right'} child={
					parseFloat(item.value)
						.toLocaleString(
							undefined,
							{minimumFractionDigits: 2, maximumFractionDigits: 2}
						)
				} />
				<Result columnIdx={4} cls={cls} child={
					item.title
				} />
				<Result columnIdx={5} cls={cls} child={
					taxable_titles[item.taxable]
				} />
				<Result columnIdx={6} cls={cls} child={
					item.valid_until === null ?
						'od ' + moment(item.valid_from).format('DD. MM. YYYY')
						:
						moment(item.valid_from).format('DD. MM. YYYY') +
							' do ' +
							moment(item.valid_until).format('DD. MM. YYYY')
				} />
				<Result columnIdx={7} cls={cls + ' text-right'} child={
					item.ord
				} />
			</div>;
		})}
	</div>;
}

class TaxRates extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps = this.updateTableItemsFromProps.bind(this);
		this.setTableItemsSort         = this.setTableItemsSort        .bind(this);
		this.refreshTableItemsSort     = this.refreshTableItemsSort    .bind(this);
		this.filterTableItems          = this.filterTableItems         .bind(this);
		this.handleFilterChange        = this.handleFilterChange       .bind(this);
		this.openAddEditItemDialog     = this.openAddEditItemDialog    .bind(this);
		this.closeAddEditItemDialog    = this.closeAddEditItemDialog   .bind(this);
		this.deleteItem                = this.deleteItem               .bind(this);
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props);
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'ord',
			table_items_sort_direction: 'ASC',
			table_items_filters: {
				title: '',
			},
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			item_delete_requested: null,
		};
		
		this.state.table_items_sort_ids = this.refreshTableItemsSort(
			this.state.table_items_sort_field,
			this.state.table_items_sort_direction,
			this.state.table_items_filtered_ids,
			table_items,
			true
		);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.tax_rates != this.state.table_items) {
			this.setState(
				this.updateTableItemsFromProps(this.props)
			);
		}
	}
	
	updateTableItemsFromProps(props) {
		const ids = Object.keys(props.tax_rates);
		
		let sort_ids = [];
		
		if (this.state !== undefined && this.state.table_items_sort_ids !== undefined) {
			for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
				const id = this.state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const table_items_sort_ids = this.state === undefined ? sort_ids :
			this.refreshTableItemsSort(
				this.state.table_items_sort_field,
				this.state.table_items_sort_direction,
				ids,
				props.tax_rates,
				true
			);
		
		return {
			table_items: props.tax_rates,
			table_items_sort_ids,
			table_items_filtered_ids: ids,
		};
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, items, only_return) {
		table_items_filtered_ids = table_items_filtered_ids || this.state.table_items_filtered_ids;
		items = items || this.state.table_items;
		only_return = only_return || false;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			const a = items[a_key][field];
			const b = items[b_key][field];
			
			if (field == 'title') {
				return a.localeCompare(b) * (direction == 'ASC' ? 1 : -1);
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (direction == 'ASC' ? 1 : -1);
		});
		
		if (only_return) {
			return ids;
		}
		
		this.setState({
			table_items_sort_ids: ids,
		});
	}
	
	filterTableItems(filters) {
		const filters_title = filters.title.toUpperCase();
		
		const ids = Object.keys(this.state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = this.state.table_items[id];
			
			if (filters_title != '' && item.title.toUpperCase().indexOf(filters_title) == -1) {
				return false;
			}
			
			return true;
		});
		
		this.setState({
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		});
		
		this.refreshTableItemsSort(
			this.state.table_items_sort_field,
			this.state.table_items_sort_direction,
			filtered_ids
		);
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			title: this.state.table_items_filters.title,
		};
		
		let val = event;
		if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: true, add_edit_item_dialog_item: item });
	}
	closeAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: false });
		
		if (item !== undefined) {
			this.props.dispatch(addTaxRate({ item, token: this.props.token, save_to_api: true }));
		}
	}
	deleteItem(item) {
		this.setState({ item_delete_requested: item, token: this.props.token });
	}
	
	render() {
		return <>
			{this.state.item_delete_requested === null ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Briši'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						this.props.dispatch(deleteTaxRate(this.state.item_delete_requested.id_tax_rate));
						this.setState({ item_delete_requested: null });
					}}
					onCancel={() => {
						this.setState({ item_delete_requested: null });
					}}>
					Ali res želite izbrisati ta zapis?
				</Alert>
			}
			
			{!this.state.add_edit_item_dialog_open ? null :
				<AddEditTaxRate
					closeAddEditItemDialog={this.closeAddEditItemDialog}
					item={this.state.add_edit_item_dialog_item} />
			}
			
			<div className='flex flex-col flex-grow tax-rates-list'>
				<div className='pl-4 pt-4'>
					<Button intent='primary' icon='plus' onClick={() => this.openAddEditItemDialog(null)}>
						Nov vnos
					</Button>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items={this.state.table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openAddEditItemDialog={this.openAddEditItemDialog}
							tableItemsFilters={this.state.table_items_filters}
							handleFilterChange={this.handleFilterChange}
							deleteItem={this.deleteItem} />
					</div>
				</div>
			</div>
		</>;
	}
}
TaxRates.propTypes = {
};

function mapStateToProps(state) {
	return {
		tax_rates: state.CodeTablesSlice.tax_rates,
		token:     state.UserSlice.token,
	};
}

export default connect(mapStateToProps)(TaxRates);
