import React, { useFfect } from 'react';

import T from '../../helpers/Translation';

export function CompanyDetails(props) {
	return <div className='company-details'>
		<div className='emphasized mb-05-line font-large-1'>{props.company_data.subject_long_name}</div>
		<div>{props.company_data.subject_address}</div>
		<div>{props.company_data.subject_post_code + ' ' + props.company_data.subject_city}</div>
		<div>
			{props.company_data.subject_phone_1 === null || props.company_data.subject_phone_1.length == 0 ? null :
				<span>{'T: ' + props.company_data.subject_phone_1}</span>
			}
			<span>{' '}</span>
			{props.company_data.subject_phone_2 === null || props.company_data.subject_phone_2.length == 0 ? null :
				<span>{'M: ' + props.company_data.subject_phone_2}</span>
			}
		</div>
		<div>
			{
				'E: ' + props.company_data.subject_email +
				' ' +
				'W: ' + props.company_data.subject_website
			}
		</div>
	</div>;
}
export function Logo(props) {
	return <div className='logo'>
		<img src={'data:;base64,' + props.company_data.logo} />
	</div>;
}
export function CustomerDetails(props) {
	let customer_name = (
		(props.item.customer_name ?? '')
		+ ' ' +
		(props.item.customer_surname ?? '')
	).trim();
	if (props.item.customer_type == 'company') {
		if (props.item.customer_display_company_long_name_on_invoices && props.item.customer_company_long_name.length > 0) {
			customer_name = props.item.customer_company_long_name ?? '';
		}
		else {
			customer_name = props.item.customer_company_name ?? '';
		}
	}
	
	return <div className='customer-details'>
		<div className='emphasized font-large-1 mb-05-line'>{customer_name.trim()}</div>
		<div>{props.item.customer_address ?? ''}</div>
		<div>{(
			(props.item.customer_post_code ?? '')
			+ ' ' +
			(props.item.customer_post_office ?? '')
		).trim()}</div>
		<div className='mb-2-line'>{
			props.customer_country === null || props.customer_country === undefined ? '' : props.customer_country.common_name_international
		}</div>
		<div className='mb-05-line'>{
			props.item.customer_vat_registered === null ? '' :
			(
				(props.item.customer_vat_registered ? T.Print.CustomerDetails.VATNumber[props.lang] : T.Print.CustomerDetails.TaxNumber[props.lang]) +
				' ' +
				props.item.customer_vat_prefix +
				props.item.customer_tax_number
			)
		}</div>
		<div className='font-small'>{
			props.item.customer_internal_code === null || props.item.customer_internal_code === undefined ? '' :
			(T.Print.CustomerDetails.CustomerInternalCode[props.lang] + ' ' + (props.item.customer_internal_code ?? ''))
		}</div>
	</div>;
}
export function Footer(props) {
	return <div className={'footer ' + (props.inline === true ? 'inline' : '')}>
		{props.settings.footer}
	</div>;
}
