import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
} from '@blueprintjs/core';

import moment from 'moment';

import TextWithTooltip     from '../TextWithTooltip';
import { AppToaster }      from '../AppToaster';
import { parseFloatLocal } from '../../numbers';

import { ResultHeader, Result } from '../code_tables/ResultComponents';

function FormTextInput(props) {
	const {
		fieldName,
		title,
		tooltip,
		value,
		changeValue,
		className,
		disabled,
	} = props;
	
	return <FormGroup labelFor={'add-edit-item-dialog--' + fieldName} className={className}>
		<TextWithTooltip label={title} tooltip={tooltip} />
		<InputGroup
			id={'add-edit-item-dialog--' + fieldName}
			value={value}
			disabled={disabled}
			onChange={event => changeValue(event.target.value)} />
	</FormGroup>;
}

const typeTitles = {
	income:  'Prejemek',
	expense: 'Izdatek',
};

class AddJournalDialog extends Component {
	constructor(props) {
		super(props);
		
		const cash_id_payment_type = '2db820c7-a652-4f9e-8c93-7e1b09d399d5'; //TODO perhaps move this somewhere else?
		let expense_cash_register_document = null;
		
		let sums_by_payment_types = {};
		for (let i=0; i < props.documents.length; i++) {
			const document = props.documents[i];
			
			if (props.item !== null && document.id_cash_register_document == props.item.expense_id_cash_register_document) {
				expense_cash_register_document = document;
			}
			
			if (document.payment_types !== null && document.payment_types !== undefined) {
				for (let j=0; j < document.payment_types.length; j++) {
					const doc_payment_type = document.payment_types[j];
					if (sums_by_payment_types[doc_payment_type.id_payment_type] === undefined) {
						sums_by_payment_types[doc_payment_type.id_payment_type] = { income: 0, expense: 0 };
					}
					if (doc_payment_type.id_payment_type == cash_id_payment_type) {
						if (props.item !== null && document.id_cash_register_document == props.item.expense_id_cash_register_document) {
							// ignore this, since this expense represents manually entered expense cash
						}
						else {
							sums_by_payment_types[doc_payment_type.id_payment_type][document.type] += doc_payment_type.amount;
						}
					}
					else if (props.item !== null) {
						sums_by_payment_types[doc_payment_type.id_payment_type][document.type] += doc_payment_type.amount;
					}
					else {
						sums_by_payment_types[doc_payment_type.id_payment_type].income  += doc_payment_type.amount;
						sums_by_payment_types[doc_payment_type.id_payment_type].expense += doc_payment_type.amount;
					}
				}
			}
		}
		
		// add cash if it's not present yet
		if (sums_by_payment_types[cash_id_payment_type] === undefined) {
			sums_by_payment_types[cash_id_payment_type] = { income: 0, expense: 0 };
		}
		
		let cash_expense_amount = 0;
		if (props.item !== null) {
			if (expense_cash_register_document !== null) {
				let amount = 0;
				for (let i=0; i < (expense_cash_register_document.payment_types || []).length; i++) {
					const doc_payment_type = expense_cash_register_document.payment_types[i];
					
					if (doc_payment_type.id_payment_type == cash_id_payment_type) {
						amount += doc_payment_type.amount;
					}
				}
				cash_expense_amount = amount;
			}
		}
		else {
			cash_expense_amount = Math.properRound(
				Math.max(0, sums_by_payment_types[cash_id_payment_type].income - sums_by_payment_types[cash_id_payment_type].expense + parseFloat(props.start_balance))
			, 2);
		}
		
		this.state = {
			sums_by_payment_types,
			cash_expense_amount,
			cash_expense_amount_formatted: this.formatNumber(cash_expense_amount),
			saving: false,
		};
		
		this.saving = false; // since setState is async, we use a local variable as well
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	render() {
		let income        = 0;
		let expense       = 0;
		let start_balance = parseFloat(this.props.start_balance);
		let end_balance   = 0;
		
		for (let id_payment_type in this.state.sums_by_payment_types) {
			income  += this.state.sums_by_payment_types[id_payment_type].income;
			expense += this.state.sums_by_payment_types[id_payment_type].expense;
		}
		
		expense += this.state.cash_expense_amount;
		
		end_balance = Math.round((start_balance + income - expense) * 100) / 100;
		
		// get rid of -0
		if (end_balance == 0) {
			end_balance = Math.abs(end_balance);
		}
		
		if (this.props.item !== null) {
			income        = parseFloat(this.props.item.income);
			expense       = parseFloat(this.props.item.expense);
			start_balance = parseFloat(this.props.item.start_balance);
			end_balance   = parseFloat(this.props.item.end_balance);
		}
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			onClose={() => this.props.closeDialog()}
			className='add-journal-dialog'>
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Blagajniški dnevnik</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY + ' flex flex-col'}>
					<div className='flex flex-col flex-1'>
						<div className='flex flex-row'>
							<div className='font-bold mr-2'>Datum dnevnika:</div>
							<div>{ moment(this.props.date).format('DD. MM. YYYY') }</div>
							<div className='flex-1'></div>
							<div className='font-bold mr-2 ml-6'>Izbrana blagajna:</div>
							<div>{ this.props.cash_registers[this.props.id_cash_register].title }</div>
						</div>
						<div className='mt-4 flex flex-col flex-1 self-stretch'>
							<div className='base-list add-journal-dialog-sums-list'>
								<div className='results mt-4 mb-4'>
									<div className='results-table overflow-y-auto sticky-header'>
										<ResultHeader columnIdx={1} title='Tip plačila' />
										<ResultHeader columnIdx={2} cls='text-right' title='Prejemki' />
										<ResultHeader columnIdx={3} cls='text-right' title='Izdatki' />
										
										{Object.keys(this.state.sums_by_payment_types)
											.sort((a, b) => { // move cash to first place
												if (a == '2db820c7-a652-4f9e-8c93-7e1b09d399d5') { //TODO perhaps move this somewhere else?
													return -1;
												}
												return 0;
											})
											.map(id_payment_type => {
												return <React.Fragment key={'payment-type-' + id_payment_type}>
													<Result columnIdx={1} child={ this.props.payment_types[id_payment_type].title } />
													<Result columnIdx={2} cls='text-right' child={
														parseFloat(this.state.sums_by_payment_types[id_payment_type].income)
															.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
													} />
													<Result columnIdx={3} cls='text-right' child={
														parseFloat(this.state.sums_by_payment_types[id_payment_type].expense)
															.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
													} />
												</React.Fragment>;
											})
										}
									</div>
								</div>
							</div>
							<div className='flex flex-row justify-end'>
								<div className='add-journal-dialog-sums-field m-1'>
									<div className='text-right'>Skupaj prejemki</div>
									<div className='add-journal-dialog-sums-field-value text-right'>
										{ income.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }
									</div>
								</div>
								<div className='add-journal-dialog-sums-field m-1'>
									<div className='text-right'>Skupaj izdatki</div>
									<div className='add-journal-dialog-sums-field-value text-right'>
										{ expense.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }
									</div>
								</div>
							</div>
							<div className='flex flex-row justify-end'>
								<div className='add-journal-dialog-sums-field m-1'>
									<div className='text-right'>Začetni saldo</div>
									<div className='add-journal-dialog-sums-field-value text-right'>
										{ start_balance.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }
									</div>
								</div>
								{this.props.item === null ?
									<FormTextInput
										className=  'm-1 vibrant'
										fieldName=  'cash'
										title=      'Gotovina (izdatek)'
										tooltip=    'Gotovina (izdatek)'
										value=      {this.state.cash_expense_amount_formatted}
										changeValue={value => {
											let val = parseFloatLocal(value);
											if (Object.is(val, NaN)) {
												val = 0;
											}
											
											this.setState({
												cash_expense_amount:           val,
												cash_expense_amount_formatted: value,
											});
										}} />
									:
									<div className='add-journal-dialog-sums-field m-1'>
										<div className='text-right'>Gotovina (izdatek)</div>
										<div className='add-journal-dialog-sums-field-value text-right'>{
											this.state.cash_expense_amount_formatted
										}</div>
									</div>
								}
								<div className='add-journal-dialog-sums-field m-1'>
									<div className='text-right'>Končni saldo</div>
									<div className='add-journal-dialog-sums-field-value text-right'>
										{ end_balance.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }
									</div>
								</div>
							</div>
							<div className='base-list add-journal-dialog-documents-list mt-12 flex-1 relative'>
								<div className='results h-full relative'>
									<div className='results-table overflow-y-auto sticky-header absolute max-h-full inset-x-0 top-0'>
										<ResultHeader columnIdx={1} title='Tip' />
										<ResultHeader columnIdx={2} title='Veza' />
										<ResultHeader columnIdx={3} title='Datum dokumenta' />
										<ResultHeader columnIdx={4} title='Šifra stranke' />
										<ResultHeader columnIdx={5} title='Naziv stranke' />
										<ResultHeader columnIdx={6} title='Blagajnik' />
										<ResultHeader columnIdx={7} title='Način plačila' />
										<ResultHeader columnIdx={8} cls='text-right' title='Znesek' />
										
										{this.props.documents
											.sort((a, b) => {
												if (a.reference === null || b.reference === null) return 1;
												if (a.reference < b.reference) return  1;
												if (a.reference > b.reference) return -1;
												return 0;
											})
											.map(document => {
												const customer = document.id_customer === null ? null : (this.props.customers[document.id_customer] || null);
												const user     = this.props.users[document.id_user];
												
												const amount = document.payment_types === null ? 0 : document.payment_types.reduce((acc, curr) => acc + curr.amount, 0);
												
												let cls = '';
												if (document.type == 'expense') {
													cls += 'font-bold';
												}
												
												return <React.Fragment key={'document-' + document.id_cash_register_document}>
													<Result columnIdx={1} cls={cls} child={
														typeTitles[document.type] || document.type
													} />
													<Result columnIdx={2} cls={cls} child={
														document.reference
													} />
													<Result columnIdx={3} cls={cls} child={
														moment(document.document_date).format('DD. MM. YYYY')
													} />
													<Result columnIdx={4} cls={cls} child={
														customer === null ? '' : customer.internal_code
													} />
													<Result columnIdx={5} cls={cls} child={
														customer === null ? '' : (customer.type == 'natural' ? (customer.surname + ' ' + customer.name) : customer.company_name)
													} />
													<Result columnIdx={6} cls={cls} child={
														user.name + ' ' + user.surname
													} />
													<Result columnIdx={7} cls={cls} child={
														document.payment_types === null ? '' : (
															document.payment_types
																.map(x => x.id_payment_type)
																.filter((value, index, self) => {
																	return self.indexOf(value) === index;
																})
																.map(x => this.props.payment_types[x].title).join(', ')
														)
													} />
													<Result columnIdx={8} cls={cls + ' text-right'} child={
														parseFloat(amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
													} />
												</React.Fragment>;
											})
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						{this.props.item !== null ?
							<Button
								intent={Intent.PRIMARY}
								onClick={() => this.props.closeDialog()}>
								Zapri
							</Button>
							:
							<>
								<Button
									minimal={true}
									onClick={() => this.props.closeDialog()}>
									Prekliči
								</Button>
								<Button
									intent={Intent.PRIMARY}
									disabled={this.state.saving}
									onClick={() => {
										if (this.saving) return;
										
										if (end_balance < 0) {
											AppToaster.show({
												message: <div>
													Končni saldo ne sme biti manjši od 0
												</div>,
												intent: 'danger',
												icon:   'issue',
											});
											return;
										}
										
										if (this.props.documents.length == 0 && expense == 0) {
											AppToaster.show({
												message: <div>
													<div>{
														'Blagajniški dnevnik za dan ' + moment(this.props.date).format('DD. MM. YYYY') + ' ni mogoče zaključiti.'
													}</div>
													<br />
													<br />
													Zaključuje se lahko samo blagajniški dnevnik, na katerem so prikazani prejemki in/ali ročni izdatki, oz. če je znesek pri gotovinskem izdatku večji od 0.
												</div>,
												intent: 'danger',
												icon:   'issue',
											});
											return;
										}
										
										this.saving = true;
										this.setState({ saving: true });
										
										this.props.closeDialog(
											this.props.date,
											income,
											expense,
											start_balance,
											this.state.cash_expense_amount,
											end_balance,
											this.props.id_cash_register
										);
									}}>
									Zaključi
								</Button>
							</>
						}
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddJournalDialog.propTypes = {
	closeDialog:      PropTypes.func,
	id_cash_register: PropTypes.string,
	date:             PropTypes.string,
	documents:        PropTypes.array,
};

function mapStateToProps(state) {
	return {
		payment_types: state.CodeTablesSlice.payment_types,
		customers:     state.CodeTablesSlice.customers,
		users:         state.UserSlice.users,
		cash_registers: state.CashRegisterSlice.cash_registers,
	};
}

export default connect(mapStateToProps)(AddJournalDialog);
