import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import moment from 'moment';

import {
	Button,
	ButtonGroup,
	Checkbox,
	Alert,
	Intent,
	Tooltip,
	Dialog,
	ProgressBar,
	Classes,
} from '@blueprintjs/core';

import { loadItems } from '../../api/CodeTables';
import {
	saveStockAcquisitionDocument,
	loadStockAcquisitionDocuments,
	loadStockInventoryItems,
} from '../../api/Warehouses';
import {
	saveItem,
} from '../../api/Items';
import AddEditStockAcquisitionDocumentDialog from './AddEditStockAcquisitionDocumentDialog';
import ItemLabelPrintDialog from '../ItemLabelPrintDialog';
import PriceHelper from '../../helpers/PriceHelper';

import { enqueueInvoiceOnA4PrinterQueue, enqueueItemOnLabelPrinterQueue } from '../../slices/AppSlice';

import { ResultHeader, Result, MultiSelectFilter, DateRangeFilter, TextFilter } from '../code_tables/ResultComponents';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		openAddEditItemDialog,
		tableItemsFilters,
		originalTableItemsFilters,
		handleFilterChange,
		users,
		customers,
		tax_rates,
		table_items_checked_ids,
		rowCheckStateChanged,
		allRowsCheckStateChanged,
	} = props;
	
	const users_with_titles = {};
	for (let id_user in users) {
		users_with_titles[id_user] = { id_user, title: users[id_user].name + ' ' + users[id_user].surname };
	}
	
	const types = {
		'acquisition':       'Prevzem',
		'write-off':         'Odpis zaloge',
		'reclamation':       'Reklamacija',
		'representation':    'Reprezentanca',
		'commission':        'Komisija',
		'debt-release':      'Razdolžnica',
		'internal-transfer': 'Prenos med skladišči',
	};
	
	const types_with_titles = {};
	for (let type in types) {
		types_with_titles[type] = { value: type, title: types[type] };
	}
	
	let total_price_without_vat = 0;
	let total_price_with_vat    = 0;
	
	const check_count = table_items_sort_ids.filter(id => table_items_checked_ids[id] === true).length;
	const all_checked = table_items_sort_ids.length == check_count;
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table overflow-y-auto sticky-header'>
				<ResultHeader columnIdx={1} title={
					<Checkbox
						checked={check_count > 0}
						indeterminate={!all_checked && check_count > 0}
						onChange={event => allRowsCheckStateChanged(event.target.checked)} />
				} />
				<ResultHeader columnIdx={2} title='Urejanje' />
				<ResultHeader
					columnIdx={3} title='Številka prevzema' />
				<ResultHeader
					columnIdx={4} title='Šifra' />
				<ResultHeader
					columnIdx={5} title='Naziv dobavitelja' />
				<ResultHeader
					columnIdx={6} title='Datum prevzema' />
				<ResultHeader
					columnIdx={7} title='Datum knjiženja' />
				<ResultHeader
					columnIdx={8} title='Številka dobavnice' />
				<ResultHeader
					columnIdx={9} title='Vrsta prevzema' />
				<ResultHeader
					columnIdx={10} title='Uporabnik' />
				<ResultHeader
					columnIdx={11} cls='text-right' title='Znesek brez DDV' />
				<ResultHeader
					columnIdx={12} cls='text-right' title='Znesek z DDV' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} cls='filters-col' />
				<Result columnIdx={3} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.document_number}
						onChange={ event => handleFilterChange('document_number', event) } />
				} />
				<Result columnIdx={4} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.customer_internal_code}
						onChange={ event => handleFilterChange('customer_internal_code', event) } />
				} />
				<Result columnIdx={5} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.customer_name}
						onChange={ event => handleFilterChange('customer_name', event) } />
				} />
				<Result columnIdx={6} cls='filters-col' child={
					<DateRangeFilter
						filter_values={tableItemsFilters.document_date_range}
						original_start_date={originalTableItemsFilters.document_date_range[0]}
						original_end_date={originalTableItemsFilters.document_date_range[1]}
						onFilterChange={values => handleFilterChange('document_date_range', values)}
						short_year={true} />
				} />
				<Result columnIdx={7} cls='filters-col' child={
					<DateRangeFilter
						filter_values={tableItemsFilters.book_date_range}
						original_start_date={originalTableItemsFilters.book_date_range[0]}
						original_end_date={originalTableItemsFilters.book_date_range[1]}
						onFilterChange={values => handleFilterChange('book_date_range', values)}
						short_year={true} />
				} />
				<Result columnIdx={8} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.delivery_note_number}
						onChange={ event => handleFilterChange('delivery_note_number', event) } />
				} />
				<Result columnIdx={9} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='value'
							title_field    ='title'
							items          ={types_with_titles}
							filtered_keys  ={tableItemsFilters.types}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('types', values)} />
					</div>
				} />
				<Result columnIdx={10} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_user'
							title_field    ='title'
							items          ={users_with_titles}
							filtered_keys  ={tableItemsFilters.id_users}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('id_users', values)} />
					</div>
				} />
				<Result columnIdx={11} cls='filters-col' child={
					null
				} />
				<Result columnIdx={12} cls='filters-col' child={
					null
				} />
				
				{table_items_sort_ids.map((id, idx) => {
					const item     = table_items[id];
					const supplier = customers[item.supplier_id_customer] || null;
					const user     = users[item.id_user];
					
					let price_without_vat = 0;
					let price_with_vat    = 0;
					for (let i=0; i<(item.items ?? []).length; i++) {
						const purchase_price_without_vat =
							parseFloat(item.items[i].purchase_price_without_vat) *
							parseFloat(item.items[i].quantity) *
							(1 - parseFloat(item.items[i].purchase_discount) / 100);
						
						price_without_vat += purchase_price_without_vat;
						price_with_vat    += purchase_price_without_vat * (1 + tax_rates[item.items[i].purchase_id_tax_rate].value / 100);
					}
					
					total_price_without_vat += price_without_vat;
					total_price_with_vat    += price_with_vat;
					
					return <React.Fragment
						key={'result--result-' + id}>
						<Result columnIdx={1} child={
							<Checkbox
								checked={table_items_checked_ids[id] === true}
								onChange={event => rowCheckStateChanged(id, event.target.checked)} />
						} />
						<Result columnIdx={2} child={
							<>
								<Button
									icon='edit'
									intent='primary'
									minimal={true}
									small={true}
									onClick={() => openAddEditItemDialog(item)} />
							</>
						} />
						<Result columnIdx={3} child={ item.document_number } />
						<Result columnIdx={4} child={ supplier === null || supplier === undefined ? null : supplier.internal_code } />
						<Result columnIdx={5} child={
							supplier === null || supplier === undefined ? null :
								(supplier.type == 'natural' ?
									supplier.surname + ' ' + supplier.name
									:
									supplier.company_name
								)
						} />
						<Result columnIdx={6} child={ item.document_date === null ? null : moment(item.document_date).format('DD. MM. YYYY') } />
						<Result columnIdx={7} child={ item.book_date     === null ? null : moment(item.book_date)    .format('DD. MM. YYYY') } />
						<Result columnIdx={8} child={ item.delivery_note_number } />
						<Result columnIdx={9} child={ types[item.type] } />
						<Result columnIdx={10} child={
							user === null || user === undefined ? null : (user.name + ' ' + user.surname)
						} />
						<Result columnIdx={11} cls='text-right font-bold' child={
							price_without_vat.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
						} />
						<Result columnIdx={12} cls='text-right font-bold' child={
							price_with_vat.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
						} />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results borderless mt-4 mb-4'>
			<div className='results-table'>
				<Result columnIdx={1} span={2} child={
					'Število zapisov: ' + table_items_sort_ids.length
				} />
				<Result columnIdx={3} child={''} />
				<Result columnIdx={4} child={''} />
				<Result columnIdx={5} child={''} />
				<Result columnIdx={6} child={''} />
				<Result columnIdx={7} child={''} />
				<Result columnIdx={8} child={''} />
				<Result columnIdx={9} child={''} />
				<Result columnIdx={10} child={''} />
				<Result columnIdx={11} cls='text-right font-bold' child={
					total_price_without_vat
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
				} />
				<Result columnIdx={12} cls='text-right font-bold' child={
					total_price_with_vat
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
				} />
			</div>
		</div>
	</>;
}

class StockAcquisitionDocuments extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.openAddEditItemDialog      = this.openAddEditItemDialog     .bind(this);
		this.closeAddEditItemDialog     = this.closeAddEditItemDialog    .bind(this);
		this.updateTableItemsFromProps  = this.updateTableItemsFromProps .bind(this);
		this.refreshTableItemsSort      = this.refreshTableItemsSort     .bind(this);
		this.filterTableItems           = this.filterTableItems          .bind(this);
		this.handleFilterChange         = this.handleFilterChange        .bind(this);
		this.requestDeleteItem          = this.requestDeleteItem         .bind(this);
		this.deleteItem                 = this.deleteItem                .bind(this);
		this.exportToExcel              = this.exportToExcel             .bind(this);
		this.exportItemToExcel          = this.exportItemToExcel         .bind(this);
		this.printLabels                = this.printLabels               .bind(this);
		this.openLabelPrintDialog       = this.openLabelPrintDialog      .bind(this);
		this.closeLabelPrintDialog      = this.closeLabelPrintDialog     .bind(this);
		
		const table_items_filters = {
			document_number:        '',
			customer_internal_code: '',
			customer_name:          '',
			document_date_range:    [ moment().subtract(3, 'months').toDate(), null ],
			book_date_range:        [ null, null ],
			delivery_note_number:   '',
			types:                  [],
			id_users:               [],
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'document_number',
			table_items_sort_direction: 'DESC',
		});
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'document_number',
			table_items_sort_direction: 'DESC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			table_items_checked_ids: {},
			add_edit_item_dialog_open:             false,
			add_edit_item_dialog_id_cash_register: null,
			add_edit_item_dialog_date:             null,
			add_edit_item_dialog_start_balance:    0,
			add_edit_item_dialog_documents:        [],
			add_edit_item_dialog_item:             null,
			delete_request_id_item:                null,
			saving:                                false,
			label_print_dialog_open:               false,
			label_print_dialog_items:              [],
			label_print_dialog_counts:             {}
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.users                       != prevProps.users ||
			this.props.stock_acquisition_documents != prevProps.stock_acquisition_documents
		) {
			const state = this.updateTableItemsFromProps(this.props, this.state);
			this.setState(
				state
			);
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const ids = Object.keys(props.stock_acquisition_documents);
		
		let sort_ids = [];
		
		if (state !== undefined && state.table_items_sort_ids !== undefined) {
			for (let i=0; i<state.table_items_sort_ids.length; i++) {
				const id = state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const new_state = {
			invoices: props.invoices,
			table_items: props.stock_acquisition_documents,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
			table_items_sort_field: state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a = '';
			let b = '';
			let dir = direction;
			
			a = state.table_items[a_key][field];
			b = state.table_items[b_key][field];
			
			return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		console.log({
			filters,
			document_date_range: filters.document_date_range,
			book_date_range: filters.book_date_range,
		});
		
		const filters_document_number        = filters.document_number       .toUpperCase();
		const filters_customer_internal_code = filters.customer_internal_code.toUpperCase();
		const filters_customer_name          = filters.customer_name         .toUpperCase();
		const filters_document_date_start    = filters.document_date_range[0] === null ? null : this.toIsoString(filters.document_date_range[0]);
		const filters_document_date_end      = filters.document_date_range[1] === null ? null : this.toIsoString(filters.document_date_range[1]);
		const filters_book_date_start        = filters.book_date_range[0]     === null ? null : this.toIsoString(filters.book_date_range[0]);
		const filters_book_date_end          = filters.book_date_range[1]     === null ? null : this.toIsoString(filters.book_date_range[1]);
		const filters_delivery_note_number   = filters.delivery_note_number.toUpperCase();
		const filters_types                  = filters.types;
		const filters_id_users               = filters.id_users;
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_document_number != '' && (item.document_number === null || item.document_number.toUpperCase().indexOf(filters_document_number) == -1)) {
				return false;
			}
			
			if (filters_customer_internal_code != '' && (item.supplier_id_customer === null || this.props.customers[item.supplier_id_customer] === undefined || this.props.customers[item.supplier_id_customer].internal_code != filters_customer_internal_code)) {
				return false;
			}
			
			if (filters_customer_name != '' && (item.supplier_id_customer === null || this.props.customers[item.supplier_id_customer] === undefined || (this.props.customers[item.supplier_id_customer].surname + ' ' + this.props.customers[item.supplier_id_customer].name + ' ' + this.props.customers[item.supplier_id_customer].company_name).toUpperCase().indexOf(filters_customer_name) == -1)) {
				return false;
			}
			
			if (filters_document_date_start !== null && item.document_date !== null && this.toIsoString(new Date(item.document_date)) < filters_document_date_start) {
				return false;
			}
			if (filters_document_date_end !== null && item.document_date !== null && this.toIsoString(new Date(item.document_date)) > filters_document_date_end) {
				return false;
			}
			
			if (filters_book_date_start !== null && item.book_date !== null && this.toIsoString(new Date(item.book_date)) < filters_book_date_start) {
				return false;
			}
			if (filters_book_date_end !== null && item.book_date !== null && this.toIsoString(new Date(item.book_date)) > filters_book_date_end) {
				return false;
			}
			
			if (filters_delivery_note_number != '' && (item.delivery_note_number === null || item.delivery_note_number.toUpperCase().indexOf(filters_delivery_note_number) == -1)) {
				return false;
			}
			
			if (filters_id_users.length > 0) {
				if (item.id_user === null || filters_id_users.indexOf(item.id_user) == -1) {
					return false;
				}
			}
			
			if (filters_types.length > 0) {
				if (item.type === null || filters_types.indexOf(item.type) == -1) {
					return false;
				}
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			document_number:        this.state.table_items_filters.document_number,
			customer_internal_code: this.state.table_items_filters.customer_internal_code,
			customer_name:          this.state.table_items_filters.customer_name,
			document_date_range:    this.state.table_items_filters.document_date_range,
			book_date_range:        this.state.table_items_filters.book_date_range,
			delivery_note_number:   this.state.table_items_filters.delivery_note_number,
			types:                  this.state.table_items_filters.types,
			id_users:               this.state.table_items_filters.id_users,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openAddEditItemDialog(item) {
		this.setState({
			add_edit_item_dialog_open: true,
			add_edit_item_dialog_item: item,
		});
	}
	async closeAddEditItemDialog(item, ignore_prices_for_id_stock_acquisition_document_items) {
		if (item === undefined) {
			this.setState({
				add_edit_item_dialog_open: false,
			});
			return;
		}
		
		this.setState({ saving: true });
		
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		const today = moment().format('YYYY-MM-DD');
		for (let i=0; i<item.items.length; i++) {
			let subitem = item.items[i];
			
			if (subitem.id_item === null) { // add new item
				const id_item            = uuid.v4();
				const id_item_price_list = uuid.v4();
				
				const price_lists = {};
				price_lists[id_item_price_list] = {
					id_item_price_list,
					id_item,
					id_tax_rate: subitem.id_tax_rate,
					price:       subitem.price,
					valid_from:  today,
					valid_until: null,
				};
				
				subitem = await saveItem(
					this.props.api_url,
					{
						id_item,
						id_measuring_unit:             '8ff9c423-df6d-41ae-b12a-064f403e2487', //TODO move this to a constant somewhere
						type:                          'item',
						ean:                           subitem.ean,
						internal_code:                 subitem.internal_code,
						allowed_discount:              subitem.allowed_discount,
						title:                         subitem.title,
						allow_changing_price:          subitem.allow_changing_price,
						description:                   subitem.description,
						show_description_on_documents: subitem.show_description_on_documents,
						price_lists,
					},
					token
				);
				item.items[i].id_item = subitem.id_item;
			}
			else { // update price for existing item
				const item_item = {...this.props.items[subitem.id_item]};
				
				if (ignore_prices_for_id_stock_acquisition_document_items
					.indexOf(subitem.id_stock_acquisition_document_item) == -1
				) {
					if (subitem.id_item_price_list !== null) { // appropriate price found, let's update it
						item_item.price_lists = {...item_item.price_lists};
						item_item.price_lists[subitem.id_item_price_list] = {
							...item_item.price_lists[subitem.id_item_price_list],
							price: subitem.price,
						};
					}
					else { // create new price
						subitem.id_item_price_list = uuid.v4();
						
						item_item.price_lists = {...item_item.price_lists};
						item_item.price_lists[subitem.id_item_price_list] = {
							id_item_price_list: subitem.id_item_price_list,
							id_item:            item_item.id_item,
							id_tax_rate:        subitem.id_tax_rate,
							price:              subitem.price,
							valid_from:         today,
							valid_until:        null,
						};
					}
					
					await saveItem(
						this.props.api_url,
						item_item,
						token
					);
				}
			}
		}
		
		await saveStockAcquisitionDocument(
			this.props.api_url,
			item,
			token
		);
		//loadStockAcquisitionDocuments(this.props.api_url, dispatch, token);
		//loadStockInventoryItems      (this.props.api_url, dispatch, token);
		
		this.setState({
			add_edit_item_dialog_open: false,
			saving: false,
		});
	}
	
	requestDeleteItem(id_item) {
		this.setState({ delete_request_id_item: id_item });
	}
	async deleteItem(id_item) {
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		//await deleteCashRegisterDailyState(this.props.api_url, id_item, token);
	}
	
	exportToExcel() {
		const ids = this.state.table_items_sort_ids /*.filter(id => this.state.table_items_checked_ids[id] === true)*/;
		
		const params = [];
		const data   = {
			id_stock_acquisition_document: ids.join(','),
		}
		
		const form = document.createElement('form');
		form.target = '_blank';
		form.method = 'POST';
		form.action = this.props.api_url +
			'Warehouses/v1/acquisition-list-export-excel?' + 
			params.join('&');
		form.style.display = 'none';
		
		for (let key in data) {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = data[key];
			form.appendChild(input);
		}
		
		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	}
	
	exportItemToExcel() {
		const ids = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
		
		if (ids.length == 0) return;
		
		const params = [];
		const data   = {
			id_stock_acquisition_document: ids[0],
		}
		
		const form = document.createElement('form');
		form.target = '_blank';
		form.method = 'POST';
		form.action = this.props.api_url +
			'Warehouses/v1/acquisition-list-export-item-excel?' + 
			params.join('&');
		form.style.display = 'none';
		
		for (let key in data) {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = data[key];
			form.appendChild(input);
		}
		
		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	}
	
	openLabelPrintDialog(ids) {
		const today = new Date();
		
		let items  = [];
		let counts = {};
		
		for (let i=0; i<ids.length; i++) {
			const item_items = this.state.table_items[ids[i]].items;
			for (let j=0; j<item_items.length; j++) {
				const item = item_items[j];
				
				if (counts[item.id_item] === undefined) {
					const item_item = this.props.items[item.id_item];
					
					const id_price_list = PriceHelper.FindBestPriceListMatch(today, item_item.price_lists);
					const price_list    = id_price_list === null ? null : item_item.price_lists[id_price_list];
					
					const price = price_list === null ? 0 : price_list.price;
					
					items.push({
						item: item_item,
						price: parseFloat(price),
					});
					counts[item.id_item] = 0;
				}
				counts[item.id_item] += parseFloat(item.quantity);
			}
		}
		
		this.setState({
			label_print_dialog_open: true,
			label_print_dialog_items: items,
			label_print_dialog_counts: counts,
		});
	}
	closeLabelPrintDialog(items_with_counts) {
		this.setState({
			label_print_dialog_open: false,
			label_print_dialog_items: [],
			label_print_dialog_counts: {},
		});
		
		if (items_with_counts === null) return;
		
		this.printLabels(items_with_counts);
	}
	printLabels(items_with_counts) {
		for (let i=0; i<items_with_counts.length; i++) {
			for (let c=0; c<items_with_counts[i].count; c++) {
				this.props.dispatch(enqueueItemOnLabelPrinterQueue({
					type: 'item',
					item: items_with_counts[i].item,
				}));
			}
		}
	}
	
	render() {
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		return <>
			{!this.state.add_edit_item_dialog_open ? null :
				<AddEditStockAcquisitionDocumentDialog
					closeDialog={this.closeAddEditItemDialog}
					item={this.state.add_edit_item_dialog_item} />
			}
			
			{this.state.delete_request_id_item === null ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Briši'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						this.deleteItem(this.state.delete_request_id_item);
						this.setState({ delete_request_id_item: null });
					}}
					onCancel={() => {
						this.setState({ delete_request_id_item: null });
					}}>
					Ali res želite izbrisati ta zapis?
				</Alert>
			}
			
			{!this.state.saving ? null :
				<Dialog
					isOpen={true}
					usePortal={true}
					canOutsideClickClose={false}
					canEscapeKeyClose={false}>
					<div className='flex flex-col flex-grow'>
						<div className={Classes.DIALOG_BODY}>
							<ProgressBar animate={true} intent='primary' />
							
							<div className='text-xl text-center font-light pt-5'>Shranjevanje</div>
						</div>
					</div>
				</Dialog>
			}
			
			{!this.state.label_print_dialog_open ? null :
				<ItemLabelPrintDialog
					items={this.state.label_print_dialog_items}
					counts={this.state.label_print_dialog_counts}
					closeDialog={this.closeLabelPrintDialog} />
			}
			
			<div className='flex flex-col flex-grow base-list stock-acquisition-document-list'>
				<div className='pl-4 pt-4'>
					<ButtonGroup>
						<Button
							intent='success'
							icon='plus'
							className='align-middle'
							onClick={() => this.openAddEditItemDialog(null)}>
							Nov prevzem
						</Button>
					</ButtonGroup>
					
					<ButtonGroup className='ml-2'>
						<Button
							intent='primary'
							icon='print'
							className='align-middle'
							disabled={check_count == 0}
							onClick={() => {
								this.state.table_items_sort_ids
									.filter(id => this.state.table_items_checked_ids[id] === true)
									.forEach(id => {
										const item = this.state.table_items[id];
										this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'acquisition', lang: 'sl', item }));
									});
							}}>
							Natisni
						</Button>
						<Button
							intent='primary'
							icon='print'
							className='align-middle'
							disabled={check_count == 0}
							onClick={() => {
								this.openLabelPrintDialog(
									this.state.table_items_sort_ids
										.filter(id => this.state.table_items_checked_ids[id] === true)
								);
							}}>
							Natisni nalepke
						</Button>
					</ButtonGroup>
					
					<ButtonGroup className='ml-2'>
						<Button
							intent='primary'
							icon='cloud-download'
							disabled={false}
							onClick={this.exportToExcel}>
							Izvoz seznama v Excel
						</Button>
						<Button
							intent='primary'
							icon='cloud-download'
							disabled={Object.values(this.state.table_items_checked_ids).length != 1}
							onClick={this.exportItemToExcel}>
							Izvoz prevzema v Excel
						</Button>
					</ButtonGroup>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow' style={{ flexBasis: '0' }}>
						<Results
							table_items              ={this.state.table_items}
							table_items_sort_ids     ={this.state.table_items_sort_ids}
							openAddEditItemDialog    ={this.openAddEditItemDialog}
							tableItemsFilters        ={this.state.table_items_filters}
							originalTableItemsFilters={this.state.original_table_items_filters}
							handleFilterChange       ={this.handleFilterChange}
							users                    ={this.props.users}
							customers                ={this.props.customers}
							tax_rates                ={this.props.tax_rates}
							table_items_checked_ids={this.state.table_items_checked_ids}
							rowCheckStateChanged={(id, state) => {
								let table_items_checked_ids = this.state.table_items_checked_ids;
								
								if (state === false) {
									delete table_items_checked_ids[id];
								}
								else {
									table_items_checked_ids[id] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							allRowsCheckStateChanged={state => {
								const table_items_checked_ids = {};
								for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
									table_items_checked_ids[this.state.table_items_sort_ids[i]] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}} />
					</div>
				</div>
			</div>
		</>;
	}
}
StockAcquisitionDocuments.propTypes = {
};

function mapStateToProps(state) {
	return {
		api_url:                     state.CashRegisterSlice.api_url,
		token:                       state.UserSlice.token,
		user:                        state.UserSlice.user,
		users:                       state.UserSlice.users,
		items:                       state.CodeTablesSlice.items,
		customers:                   state.CodeTablesSlice.customers,
		tax_rates:                   state.CodeTablesSlice.tax_rates,
		stock_acquisition_documents: state.WarehouseSlice.stock_acquisition_documents,
	};
}

export default connect(mapStateToProps)(StockAcquisitionDocuments);
