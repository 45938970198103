import {
	addCountries,
	addPostOffices,
	addBusinessPremises,
	addChannels,
	addCustomers,
	addMeasuringUnits,
	addPaymentTypes,
	addTaxRates,
	addWarehouses,
	addAccommodations,
	addAccommodationItems,
	addAccommodationItemPlaces,
	addAccommodationItemItems,
	addAccommodationItemPlaceItems,
	addAccommodationItemSchedules,
	addAccommodationItemScheduleTimeSlots,
	addAccommodationItemScheduleTimeSlotActivities,
	addItems,
	addItem,
	addMarketSegmentation,
	addActivities,
	addIdentificationTypes,
	addTouristTaxes,
	addBusinessPremiseFiscalRegistrations,
	addDailyLogbookActivity,
	addDailyLogbookActivities,
	addWorkType,
	addWorkTypes,
} from '../slices/CodeTablesSlice';
import {
	setUser,
} from '../slices/UserSlice';

export async function loadCountries(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/countries', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addCountries(items));
	}
}

export async function loadPostOffices(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/post-offices', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addPostOffices(items));
	}
}

export async function loadBusinessPremises(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/business-premises', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addBusinessPremises(items));
	}
}

export async function loadChannels(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/channels', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addChannels(items));
	}
}

export async function loadCustomers(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Customers/v1/customers', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addCustomers(items));
	}
}

export async function loadMeasuringUnits(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/measuring-units', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addMeasuringUnits(items));
	}
}

export async function loadPaymentTypes(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/payment-types', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addPaymentTypes(items));
	}
}

export async function loadTaxRates(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/tax-rates', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addTaxRates(items));
	}
}

export async function loadWarehouses(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Warehouses/v1/warehouses', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addWarehouses(items));
	}
}

export async function loadAccommodations(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Accommodations/v1/accommodations', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addAccommodations                             (items.accommodations));
		dispatch(addAccommodationItems                         (items.accommodation_items));
		dispatch(addAccommodationItemPlaces                    (items.accommodation_item_places));
		dispatch(addAccommodationItemItems                     (items.accommodation_item_items));
		dispatch(addAccommodationItemPlaceItems                (items.accommodation_item_place_items));
		dispatch(addAccommodationItemSchedules                 (items.accommodation_item_schedules));
		dispatch(addAccommodationItemScheduleTimeSlots         (items.accommodation_item_schedule_time_slots));
		dispatch(addAccommodationItemScheduleTimeSlotActivities(items.accommodation_item_schedule_time_slot_activities));
	}
}

export async function loadItems(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Items/v1/items', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addItems(items));
	}
}

export async function loadItem(api_url, id_item, dispatch, token) {
	let response = await fetch(api_url + 'Items/v1/item?id_item=' + id_item, { headers: { 'Authorization': 'Bearer ' + token } });
	let item = await response.json();
	
	if (item.error_code !== undefined) {
		if (item.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addItem({ item }));
	}
	
	return item;
}

export async function loadMarketSegmentation(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/market-segmentations', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addMarketSegmentation(items));
	}
}

export async function loadActivities(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/activities', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addActivities(items));
	}
}

export async function loadIdentificationTypes(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/identification-types', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addIdentificationTypes(items));
	}
}

export async function loadTouristTaxes(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/tourist-taxes', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addTouristTaxes(items));
	}
}

export async function loadDailyLogbookActivities(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/daily-logbook-activities', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addDailyLogbookActivities(items));
	}
}

export async function loadWorkTypes(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/work-types', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addWorkTypes(items));
	}
}

export async function saveCodeTableItem(api_url, name, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	const table_names_to_urls = {
		countries:                 'CodeTables/v1/save-country',
		post_offices:              'CodeTables/v1/save-post-office',
		business_premises:         'CodeTables/v1/save-business-premise',
		channels:                  'CodeTables/v1/save-channel',
		customers:                 'Customers/v1/save-customer',
		measuring_units:           'CodeTables/v1/save-measuring-unit',
		payment_types:             'CodeTables/v1/save-payment-type',
		tax_rates:                 'CodeTables/v1/save-tax-rate',
		warehouses:                'Warehouses/v1/save-warehouse',
		accommodations:            'Accommodations/v1/save-accommodation',
		items:                     'Items/v1/save-item',
		market_segmentation:       'CodeTables/v1/save-market-segmentation',
		activities:                'CodeTables/v1/save-activity',
		identification_types:      'CodeTables/v1/save-identification-type',
		tourist_taxes:             'CodeTables/v1/save-tourist-tax',
		work_types:                'CodeTables/v1/save-work-type',
		daily_logbook_activities:  'CodeTables/v1/save-daily-logbook-activity',
	};
	const url = table_names_to_urls[name];
	if (url === undefined) return;
	
	let response = await fetch(api_url + url, {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}
export async function deleteCodeTableItem(api_url, name, id, token) {
	const form_data = new FormData();
	form_data.set('id', id);
	
	const table_names_to_urls = {
		countries:                 'CodeTables/v1/delete-country',
		post_offices:              'CodeTables/v1/delete-post-office',
		business_premises:         'CodeTables/v1/delete-business-premise',
		cash_registers:            'CashRegisters/v1/delete-cash-register',
		channels:                  'CodeTables/v1/delete-channel',
		customers:                 'Customers/v1/delete-customer',
		measuring_units:           'CodeTables/v1/delete-measuring-unit',
		payment_types:             'CodeTables/v1/delete-payment-type',
		tax_rates:                 'CodeTables/v1/delete-tax-rate',
		warehouses:                'Warehouses/v1/delete-warehouse',
		accommodations:            'Accommodations/v1/delete-accommodation',
		items:                     'Items/v1/delete-item',
		market_segmentation:       'CodeTables/v1/delete-market-segmentation',
		activities:                'CodeTables/v1/delete-activity',
		identification_types:      'CodeTables/v1/delete-identification-type',
		tourist_taxes:             'CodeTables/v1/delete-tourist-tax',
	};
	const url = table_names_to_urls[name];
	if (url === undefined) return;
	
	await fetch(api_url + url, {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
//TODO check for invalid token
}

export async function loadBusinessPremiseFiscalRegistrations(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CodeTables/v1/business-premise-fiscal-registrations', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addBusinessPremiseFiscalRegistrations(items));
	}
}

export async function saveAccommodationItemPlace(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Accommodations/v1/save-accommodation-item-place', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}
