import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	Checkbox,
} from '@blueprintjs/core';

class EditSettingsItemVATDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			subject_vat_registered:        props.item.subject_vat_registered,
			subject_vat_registered_reason: props.item.subject_vat_registered_reason,
			subject_vat_number:            props.item.subject_vat_number,
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeDialog()}
			className='edit-settings-item-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Uredi nastavitev</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='edit-settings-item-dialog--subject_vat_registered'
							className='flex-1'>
							<div>Zavezanec za DDV:</div>
							<Checkbox
								id='edit-settings-item-dialog--subject_vat_registered'
								checked={this.state.subject_vat_registered}
								onChange={event => {
									this.setState({ subject_vat_registered: event.target.checked });
								}} />
						</FormGroup>
						{this.state.subject_vat_registered === true ? null :
							<FormGroup
								labelFor='edit-settings-item-dialog--subject_vat_registered_reason'
								className='flex-1'>
								<div>Klavzula:</div>
								<InputGroup
									id='edit-settings-item-dialog--subject_vat_registered_reason'
									value={this.state.subject_vat_registered_reason}
									onChange={event => {
										this.setState({
											subject_vat_registered_reason: event.target.value,
										});
									}} />
							</FormGroup>
						}
						{this.state.subject_vat_registered === false ? null :
							<FormGroup
								labelFor='edit-settings-item-dialog--subject_vat_number'
								className='flex-1'>
								<div>ID za DDV:</div>
								<InputGroup
									id='edit-settings-item-dialog--subject_vat_number'
									value={this.state.subject_vat_number}
									onChange={event => {
										this.setState({
											subject_vat_number: event.target.value,
										});
									}} />
							</FormGroup>
						}
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const val = {
									subject_vat_registered:        this.state.subject_vat_registered,
									subject_vat_registered_reason: this.state.subject_vat_registered ? '' : this.state.subject_vat_registered_reason,
									subject_vat_number:            this.state.subject_vat_registered ? this.state.subject_vat_number : '',
								};
								
								this.props.closeDialog(this.props.item, this.props.property_key, val);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
EditSettingsItemVATDialog.propTypes = {
	closeDialog: PropTypes.func,
	item: PropTypes.object,
};

export default EditSettingsItemVATDialog;
