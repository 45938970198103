import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	HTMLSelect,
	Radio,
	RadioGroup,
	Checkbox,
	TextArea,
	Tab,
	Tabs,
	Divider,
	Tag,
	Alert,
} from '@blueprintjs/core';
import { DateInput3 } from '@blueprintjs/datetime2';

import moment from 'moment';
import formatISO from 'date-fns/formatISO';
import uuid from 'uuid';

import TextWithTooltip from '../TextWithTooltip';
import { parseFloatLocal } from '../../numbers';
import { loadDateFnsLocale } from '../../utils';

class AddEditItem extends Component {
	constructor(props) {
		super(props);
		
		this.setCurrentItemPriceList = this.setCurrentItemPriceList.bind(this);
		this.saveItem                = this.saveItem               .bind(this);
		
		const first_measuring_unit = Object.values(this.props.measuring_units).sort((a, b) => a.ord - b.ord)[0];
		
		this.state = {
			id_item:                       props.item === null ? uuid.v4() : props.item.id_item,
			active:                        props.item === null ? true : props.item.active,
			internal_code:                 props.item === null ? '' : props.item.internal_code,
			title:                         props.item === null ? '' : props.item.title,
			description:                   props.item === null ? '' : props.item.description,
			show_description_on_documents: props.item === null ? false : props.item.show_description_on_documents,
			ean:                           props.item === null ? '' : props.item.ean,
			id_measuring_unit:             props.item === null ? first_measuring_unit.id_measuring_unit : props.item.id_measuring_unit,
			type:                          props.item === null ? 'service' : props.item.type,
			allowed_discount:              props.item === null ? 0 : props.item.allowed_discount,
			allow_changing_price:          props.item === null ? false : props.item.allow_changing_price,
			price_lists:                   props.item === null ? [] : Object.values(this.props.item.price_lists),
			new_price_lists:               [],
			current_item_price_list:                   null,
			current_item_price_list_price_with_vat:    0,
			current_item_price_list_price_without_vat: 0,
			current_item_price_list_id_tax_rate:       null,
			current_item_price_list_valid_from:        null,
			current_item_price_list_valid_until:       null,
			validation_failed_fields: [],
			show_ean_warning:   false,
			show_error:         false,
			show_error_message: '',
			show_all_price_lists: false,
		};
		
		this.state = {
			...this.state,
			allowed_discount_formatted: parseFloat(this.state.allowed_discount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
		};
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
		}
	}
	
	setCurrentItemPriceList(price_list) {
		const tax_rate = this.props.tax_rates[price_list.id_tax_rate];
		
		const current_item_price_list_price_with_vat =
			parseFloat(price_list.price).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
		const current_item_price_list_price_without_vat =
			(parseFloat(price_list.price) / (1 + parseFloat(tax_rate.value) / 100)).toLocaleString(undefined, {minimumFractionDigits: 4, maximumFractionDigits: 4});
		
		this.setState({
			current_item_price_list: price_list,
			current_item_price_list_price_with_vat,
			current_item_price_list_price_without_vat,
			current_item_price_list_price_with_vat_formatted: parseFloatLocal(current_item_price_list_price_with_vat).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
			current_item_price_list_price_without_vat_formatted: parseFloatLocal(current_item_price_list_price_without_vat).toLocaleString(undefined, {minimumFractionDigits: 4, maximumFractionDigits: 4}),
			current_item_price_list_id_tax_rate: price_list.id_tax_rate,
			current_item_price_list_valid_from:  price_list.valid_from,
			current_item_price_list_valid_until: price_list.valid_until,
		});
	}
	
	saveItem(skip_warning) {
		skip_warning = skip_warning || false;
		
		if (this.state.internal_code.length == 0) {
			this.setState({
				show_error: true,
				show_error_message: 'Vnos šifre je obvezen',
			});
			return;
		}
		if (this.state.title.length == 0) {
			this.setState({
				show_error: true,
				show_error_message: 'Vnos naziva je obvezen',
			});
			return;
		}
		if (this.state.price_lists.length == 0) {
			this.setState({
				show_error: true,
				show_error_message: 'Vnesite vsaj eno ceno',
			});
			return;
		}
		
		if (this.props.item === null) {
			if (Object.values(this.props.items).find(x => x.internal_code.toUpperCase() == this.state.internal_code.toUpperCase()) !== undefined) {
				this.setState({
					show_error: true,
					show_error_message: 'Vpisana šifra že obstaja',
				});
				return;
			}
		}
		
		if (this.state.ean.length > 0 && Object.values(this.props.items).find(x => x.ean.toUpperCase() == this.state.ean.toUpperCase() && x.id_item != this.state.id_item) !== undefined && !skip_warning) {
			this.setState({
				show_ean_warning: true,
			});
			return;
		}
		
		let price_lists = {};
		for (let i=0; i<this.state.price_lists.length; i++) {
			price_lists[this.state.price_lists[i].id_item_price_list] = this.state.price_lists[i];
		}
		
		this.props.closeAddEditItemDialog({
			...(this.props.item === null ? {} : this.props.item),
			id_item:                       this.state.id_item,
			internal_code:                 this.state.internal_code,
			title:                         this.state.title,
			ean:                           this.state.ean,
			id_measuring_unit:             this.state.id_measuring_unit,
			type:                          this.state.type,
			allowed_discount:              this.state.allowed_discount,
			allow_changing_price:          this.state.allow_changing_price,
			active:                        this.state.active,
			description:                   this.state.description,
			show_description_on_documents: this.state.show_description_on_documents,
			price_lists,
		});
	}
	
	render() {
		const current_iso_date = moment().toISOString(true);
		const types = [
			{ title: 'Storitev',         value: 'service'     },
			{ title: 'Artikel',          value: 'item'        },
			{ title: 'Turistična taksa', value: 'tourist_tax' },
		];
		
		const todayIso = moment().format('YYYY-MM-DD');
		
		let displayed_price_lists = this.state.price_lists.sort((a, b) => a.valid_from < b.valid_from ? 1 : (a.valid_from > b.valid_from ? -1 : 0));
		const all_price_list_count = displayed_price_lists.length;
		
		if (!this.state.show_all_price_lists) {
			let filtered_price_lists = [];
			for (let i=0; i<displayed_price_lists.length; i++) {
				const price_list      = displayed_price_lists[i];
				const currently_valid = price_list.valid_until === null || price_list.valid_until >= todayIso;
				
				if (currently_valid || filtered_price_lists.length < 7) {
					filtered_price_lists.push(price_list);
				}
			}
			displayed_price_lists = filtered_price_lists;
		}
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={false}
			canEscapeKeyClose={false}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-item-dialog'>
			
			{!this.state.show_ean_warning ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Da'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='help'
					intent={Intent.WARNING}
					isOpen={true}
					onConfirm={() => {
						this.saveItem(true);
						this.setState({ show_ean_warning: false });
					}}
					onCancel={() => {
						this.setState({ show_ean_warning: false });
					}}>
					EAN koda že obstaja. Vseeno nadaljujem?
				</Alert>
			}
			{!this.state.show_error ? null :
				<Alert
					confirmButtonText='Zapri'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='error'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						this.setState({ show_error: false });
					}}>
					{ this.state.show_error_message }
				</Alert>
			}
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi artikel/storitev</Navbar.Heading>
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='form-fields'>
						<FormGroup
							labelFor='add-edit-item-dialog--internal_code'
							className='internal-code'>
							<TextWithTooltip
								label='Šifra artikla/storitve'
								tooltip='Šifra artikla/storitve' />
							<InputGroup
								tabIndex='1'
								id='add-edit-item-dialog--internal_code'
								value={this.state.internal_code}
								disabled={this.props.item !== null}
								onChange={event => {
									this.setState({
										internal_code: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-item-dialog--title'
							className='title'>
							<TextWithTooltip
								label='Naziv artikla/storitve'
								tooltip='Naziv artikla/storitve' />
							<InputGroup
								tabIndex='7'
								id='add-edit-item-dialog--title'
								value={this.state.title}
								onChange={event => {
									this.setState({
										title: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-item-dialog--description'
							className='description'>
							<TextWithTooltip
								label='Opis'
								tooltip='Opis' />
							<TextArea
								tabIndex='11'
								id='add-edit-item-dialog--description'
								value={this.state.description}
								fill={true}
								onChange={event => {
									this.setState({
										description: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-item-dialog--ean'
							className='ean'>
							<TextWithTooltip
								label='Črtna koda'
								tooltip='Črtna koda' />
							<InputGroup
								tabIndex='2'
								id='add-edit-item-dialog--ean'
								value={this.state.ean}
								onChange={event => {
									this.setState({
										ean: event.target.value,
									});
								}} />
						</FormGroup>
						
						<div className='flex flex-row'>
							<FormGroup
								labelFor='add-edit-item-dialog--id_measuring_unit'
								className='id-measuring-unit mr-2 flex-1'>
								<TextWithTooltip
									label='EM'
									tooltip='EM' />
								<HTMLSelect
									tabIndex='8'
									id='add-edit-item-dialog--id_measuring_unit'
									fill={true}
									options={
										Object.values(this.props.measuring_units).sort((a, b) => a.ord - b.ord).map(
											item => {
												return { label: item.title, value: item.id_measuring_unit };
											}
										)
									}
									value={this.state.id_measuring_unit}
									onChange={(e) => {
										this.setState({
											id_measuring_unit: e.currentTarget.value,
										});
									}} />
							</FormGroup>
							
							<FormGroup
								labelFor='add-edit-item-dialog--allowed_discount'
								className='price_with_tax flex-1'>
								<TextWithTooltip
									label='Dovoljen popust'
									tooltip='Dovoljen popust' />
								<InputGroup
									tabIndex='9'
									id='add-edit-item-dialog--allowed_discount'
									className='text-right'
									value={this.state.allowed_discount_formatted}
									rightElement={<Tag minimal={true}>%</Tag>}
									onChange={event => {
										const value = event.target.value;
										let val     = parseFloatLocal(value);
										if (Object.is(val, NaN)) {
											val = 0;
										}
										
										this.setState({ allowed_discount: val, allowed_discount_formatted: value });
									}}
									onBlur={event => {
										this.setState({
											allowed_discount_formatted: parseFloat(this.state.allowed_discount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
										});
									}} />
							</FormGroup>
						</div>
						
						<FormGroup
							labelFor='add-edit-item-dialog--type'
							className='type'>
							<TextWithTooltip
								label='Tip'
								tooltip='Tip' />
							<RadioGroup
								id='add-edit-item-dialog--type'
								onChange={event => {
									this.setState({
										type: event.target.value,
									});
								}}
								selectedValue={this.state.type}
								inline={true}>
								{types.map(function(item, idx) {
									return <Radio
										tabIndex={3 + idx}
										key={ item.value }
										value={ item.value }>
										<div className='inline-flex flex-row'>
											{ item.title }
										</div>
									</Radio>;
								})}
							</RadioGroup>
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-item-dialog--active'
							className='active'>
							<TextWithTooltip
								label='Aktiven'
								tooltip='Aktiven'
								cls='wrap' />
							<Checkbox
								tabIndex='6'
								id='add-edit-item-dialog--active'
								checked={this.state.active}
								onChange={event => {
									this.setState({ active: event.target.checked });
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-item-dialog--allow_changing_price'
							className='allow_changing_price'>
							<TextWithTooltip
								label='Sprememba prodajne cene na dokumentu'
								tooltip='Sprememba prodajne cene na dokumentu'
								cls='wrap' />
							<Checkbox
								tabIndex='10'
								id='add-edit-item-dialog--allow_changing_price'
								checked={this.state.allow_changing_price}
								onChange={event => {
									this.setState({ allow_changing_price: event.target.checked });
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-item-dialog--show_description_on_documents'
							className='show-description-on-documents'>
							<TextWithTooltip
								label='Izpiši opis na dokumentu'
								tooltip='Izpiši opis na dokumentu'
									cls='wrap' />
							<Checkbox
								tabIndex='12'
								id='add-edit-item-dialog--show_description_on_documents'
								checked={this.state.show_description_on_documents}
								onChange={event => {
									this.setState({ show_description_on_documents: event.target.checked });
								}} />
						</FormGroup>
						
						<Tabs selectedTabId='price-list' className='tabs'>
							<Tab id='price-list' title='Ceniki' panel={
								<div>
									<div className='mb-4'>
										<Button
											intent='primary'
											icon='plus'
											onClick={() => {
												const price_lists_sorted = this.state.price_lists.sort((a, b) => a.valid_from < b.valid_from ? 1 : (a.valid_from > b.valid_from ? -1 : 0));
												
												const id_item_price_list = uuid.v4();
												
												let valid_from  = price_lists_sorted.length > 0 ? price_lists_sorted[0].valid_until : null;
												let valid_until = price_lists_sorted.length > 0 ? price_lists_sorted[0].valid_until : null;
												
												valid_from  = valid_from  === null ? moment() : moment(valid_from ).add(1, 'd');
												valid_until = valid_until === null ? moment() : moment(valid_until).add(1, 'd');
												
												valid_from  = valid_from .format('YYYY-MM-DD');
												valid_until = valid_until.format('YYYY-MM-DD');
												
												const price_lists = [
													{
														id_item_price_list,
														id_item:            this.state.id_item,
														valid_from,
														valid_until,
														price:              price_lists_sorted.length > 0 ? price_lists_sorted[0].price : 0,
														id_tax_rate:        price_lists_sorted.length > 0 ? price_lists_sorted[0].id_tax_rate : 'ba475ef0-b6d2-4ac4-af12-c21db3e50f52' /* 22 % */,
													},
													...this.state.price_lists,
												];
												
												this.setState({
													price_lists,
													new_price_lists: [ ...this.state.new_price_lists, id_item_price_list ],
												});
												
												this.setCurrentItemPriceList(price_lists[0]);
											}}
											>Dodaj cenik
										</Button>
									</div>
									<div className='flex flex-row justify-between'>
										<div className='price-list'>
											<div className='price-list-items'>
												<div className='font-bold'></div>
												<div className='font-bold'>Velja od</div>
												<div className='font-bold'>Velja do</div>
												<div className='text-right font-bold'>Cena brez DDV</div>
												<div className='text-right font-bold'>Cena z DDV</div>
												<div className='text-right font-bold'>Stopnja davka</div>
												
												{displayed_price_lists.map(price_list => {
													const tax_rate = this.props.tax_rates[price_list.id_tax_rate];
													
													let cls = '';
													if (
														this.state.current_item_price_list !== null &&
														this.state.current_item_price_list.id_item_price_list == price_list.id_item_price_list
													) {
														cls += ' font-bold';
													}
													
													let price_cls = '';
													if (price_list.valid_until !== null && price_list.valid_until < todayIso) {
														cls += ' text-gray-500';
													}
													
													return <React.Fragment key={'price-list-' + price_list.id_item_price_list}>
														<div className={cls}>
															<Button
																icon='edit'
																intent='primary'
																minimal={true}
																small={true}
																onClick={() => {
																	this.setCurrentItemPriceList(price_list);
																}} />
														</div>
														<div className={cls}>{
															price_list.valid_from === null ? '' : moment(price_list.valid_from).format('DD.MM.YYYY')
														}</div>
														<div className={cls}>{
															price_list.valid_until === null ? '' : moment(price_list.valid_until).format('DD.MM.YYYY')
														}</div>
														<div className={cls + price_cls + ' text-right'}>{
															(
																parseFloat(price_list.price) /
																(1 + parseFloat(tax_rate.value) / 100)
															).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
														}</div>
														<div className={cls + price_cls + ' text-right'}>{
															parseFloat(price_list.price).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
														}</div>
														<div className={cls + ' text-right'}>{
															tax_rate.title + ' (' + parseFloat(tax_rate.value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%)'
														}</div>
													</React.Fragment>;
												})}
											</div>
											{this.state.show_all_price_lists || all_price_list_count == displayed_price_lists.length ? null :
												<Button
													className='mt-2'
													onClick={() => this.setState({ show_all_price_lists: true })}>
													Prikaži vse cenike
												</Button>
											}
										</div>
										<div className='price-list-edit'>
											{this.state.current_item_price_list === null ? null : <>
												<div>
													<FormGroup
														labelFor='add-edit-item-dialog--item-price-list--id_tax_rate'
														className='id-tax-rate'>
														<TextWithTooltip
															label='Stopnja davka'
															tooltip='Stopnja davka' />
														<HTMLSelect
															id='add-edit-item-dialog--item-price-list--id_tax_rate'
															fill={true}
															options={
																Object.values(this.props.tax_rates).sort((a, b) => a.ord - b.ord).filter(x => 
																	(x.valid_from === null || x.valid_from <= current_iso_date) &&
																	(x.valid_until === null || x.valid_until >= current_iso_date)
																).map(
																	item => {
																		return {
																			label: item.title + ' (' + parseFloat(item.value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%)',
																			value: item.id_tax_rate,
																		};
																	}
																)
															}
															value={this.state.current_item_price_list_id_tax_rate}
															onChange={(e) => {
																const tax_rate = this.props.tax_rates[e.currentTarget.value];
																
																const current_item_price_list_price_without_vat =
																	(parseFloat(this.state.current_item_price_list_price_with_vat) / (1 + parseFloat(tax_rate.value) / 100));
																
																this.setState({
																	current_item_price_list_id_tax_rate: e.currentTarget.value,
																	current_item_price_list_price_without_vat,
																	current_item_price_list_price_without_vat_formatted: current_item_price_list_price_without_vat
																		.toLocaleString(undefined, {minimumFractionDigits: 4, maximumFractionDigits: 4}),
																});
															}} />
													</FormGroup>
												</div>
												<div className='flex justify-end gap-4'>
													<FormGroup
														labelFor='add-edit-item-dialog--item-price-list--price'
														className='price w-24'>
														<TextWithTooltip
															label='Cena brez DDV'
															tooltip='Cena brez DDV' />
														<InputGroup
															id='add-edit-item-dialog--item-price-list--price'
															className='text-right'
															value={this.state.current_item_price_list_price_without_vat_formatted}
															onChange={event => {
																const value = event.target.value;
																let val     = parseFloatLocal(value);
																if (Object.is(val, NaN)) {
																	val = 0;
																}
																
																const tax_rate = this.props.tax_rates[this.state.current_item_price_list_id_tax_rate];
																
																const current_item_price_list_price_with_vat =
																	(parseFloat(val) * (1 + parseFloat(tax_rate.value) / 100));
																
																this.setState({
																	current_item_price_list_price_with_vat,
																	current_item_price_list_price_with_vat_formatted: current_item_price_list_price_with_vat
																		.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
																	current_item_price_list_price_without_vat: val,
																	current_item_price_list_price_without_vat_formatted: value,
																});
															}}
															onBlur={event => {
																this.setState({
																	current_item_price_list_price_without_vat_formatted: parseFloat(this.state.current_item_price_list_price_without_vat).toLocaleString(undefined, {minimumFractionDigits: 4, maximumFractionDigits: 4}),
																});
															}} />
													</FormGroup>
													
													<FormGroup
														labelFor='add-edit-item-dialog--item-price-list--price_with_tax'
														className='price_with_tax w-24'>
														<TextWithTooltip
															label='Cena z DDV'
															tooltip='Cena z DDV' />
														<InputGroup
															id='add-edit-item-dialog--item-price-list--price_with_tax'
															className='text-right'
															value={this.state.current_item_price_list_price_with_vat_formatted}
															onChange={event => {
																const value = event.target.value;
																let val     = parseFloatLocal(value);
																if (Object.is(val, NaN)) {
																	val = 0;
																}
																
																const tax_rate = this.props.tax_rates[this.state.current_item_price_list_id_tax_rate];
																
																const current_item_price_list_price_without_vat =
																	(parseFloat(val) / (1 + parseFloat(tax_rate.value) / 100));
																
																this.setState({
																	current_item_price_list_price_with_vat: val,
																	current_item_price_list_price_with_vat_formatted: value,
																	current_item_price_list_price_without_vat,
																	current_item_price_list_price_without_vat_formatted: current_item_price_list_price_without_vat
																		.toLocaleString(undefined, {minimumFractionDigits: 4, maximumFractionDigits: 4}),
																});
															}}
															onBlur={event => {
																this.setState({
																	current_item_price_list_price_with_vat_formatted: parseFloat(this.state.current_item_price_list_price_with_vat).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
																});
															}} />
													</FormGroup>
												</div>
												
												<div className='flex justify-end gap-4'>
													<FormGroup
														labelFor='add-edit-item-dialog--item-price-list--valid_from'
														className='valid_from w-24'>
														<TextWithTooltip
															label='Velja od'
															tooltip='Velja od' />
														<DateInput3
															id='add-edit-item-dialog--item-price-list--valid_from'
															dateFnsLocaleLoader={loadDateFnsLocale}
															dateFnsFormat={'dd. MM. yyyy'}
															locale='sl'
															inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
															selectAllOnFocus={true}
															minDate={new Date('1900-01-01')}
															maxDate={moment().add('10', 'years').toDate()}
															onChange={(selectedDate, isUserChange) => {
																if (!isUserChange) return;
																
																this.setState({
																	current_item_price_list_valid_from: selectedDate,
																});
															}}
															value={this.state.current_item_price_list_valid_from} />
													</FormGroup>
													<FormGroup
														labelFor='add-edit-item-dialog--item-price-list--without_valid_until'
														className='without-valid-until mr-2'>
														<TextWithTooltip
															label='Odprta cena'
															tooltip='Odprta cena'
															cls='wrap' />
														<Checkbox
															id='add-edit-item-dialog--item-price-list--without_valid_until'
															checked={this.state.current_item_price_list_valid_until === null}
															onChange={event => {
																this.setState({
																	current_item_price_list_valid_until: event.target.checked ? null : moment().format('YYYY-MM-DD'),
																});
															}} />
													</FormGroup>
													
													<FormGroup
														labelFor='add-edit-item-dialog--item-price-list--valid_until'
														className='valid_until w-24'>
														<TextWithTooltip
															label='Velja do'
															tooltip='Velja do' />
														<DateInput3
															id='add-edit-item-dialog--item-price-list--valid_until'
															dateFnsLocaleLoader={loadDateFnsLocale}
															dateFnsFormat={'dd. MM. yyyy'}
															locale='sl'
															inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
															selectAllOnFocus={true}
															minDate={new Date('1900-01-01')}
															maxDate={moment().add('10', 'years').toDate()}
															onChange={(selectedDate, isUserChange) => {
																if (!isUserChange) return;
																
																this.setState({
																	current_item_price_list_valid_until: selectedDate,
																});
															}}
															value={this.state.current_item_price_list_valid_until} />
													</FormGroup>
												</div>
												
												<div className='flex justify-end gap-4 pt-4'>
													<Button
														minimal={true}
														onClick={() => {
															let price_lists = [ ...this.state.price_lists ];
															if (this.state.new_price_lists.indexOf(this.state.current_item_price_list.id_item_price_list) != -1) {
																price_lists = price_lists.filter(x => x.id_item_price_list != this.state.current_item_price_list.id_item_price_list);
															}
															
															this.setState({
																current_item_price_list: null,
																price_lists,
															});
														}}>
														Prekliči
													</Button>
													<Button
														intent='primary'
														onClick={() => {
															const tax_rate = this.props.tax_rates[this.state.current_item_price_list_id_tax_rate];
															
															const item = {
																...this.state.current_item_price_list,
																price:       parseFloat(this.state.current_item_price_list_price_with_vat),
																id_tax_rate: this.state.current_item_price_list_id_tax_rate,
																valid_from:  this.state.current_item_price_list_valid_from,
																valid_until: this.state.current_item_price_list_valid_until,
															};
															
															const price_lists = [...this.state.price_lists];
															for (let i=0; i<price_lists.length; i++) {
																if (price_lists[i].id_item_price_list == item.id_item_price_list) {
																	price_lists[i] = item;
																	break;
																}
															}
															
															this.setState({
																current_item_price_list: null,
																price_lists,
															});
														}}>
														Shrani ceno
													</Button>
												</div>
											</>}
										</div>
									</div>
								</div>
							} />
							<Tab id='language' title='Jezik' disabled={true} panel={null} />
							<Tab id='group' title='Skupina' disabled={true} panel={null} />
						</Tabs>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							disabled={this.state.current_item_price_list !== null}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							disabled={this.state.current_item_price_list !== null}
							onClick={() => this.saveItem()}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditItem.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item: PropTypes.object,
	measuring_units: PropTypes.object,
	tax_rates: PropTypes.object,
};

export default AddEditItem;
