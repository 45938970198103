import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
	Collapse,
	Icon,
} from '@blueprintjs/core';

import { useRoutes, navigate } from 'raviger';

import TimelineWithFilter           from './TimelineWithFilter';
import TimelineActivitiesWithFilter from './TimelineActivitiesWithFilter';

const routes = {
	'/reservations/activities/:idAccommodation/:idAccommodationItem': ({ idAccommodation, idAccommodationItem }) =>
		<TimelineActivitiesWithFilter idAccommodation={idAccommodation} idAccommodationItem={idAccommodationItem} />,
	'/reservations/accommodation/:idAccommodation': ({ idAccommodation }) =>
		<TimelineWithFilter idAccommodation={idAccommodation} idAccommodationItem={-1} />,
	'/reservations/accommodation/:idAccommodation/:idAccommodationItem': ({ idAccommodation, idAccommodationItem }) =>
		<TimelineWithFilter idAccommodation={idAccommodation} idAccommodationItem={idAccommodationItem} />,
};

function Reservations(props) {
	const {
		accommodations,
		accommodation_items,
		accommodation_item_places,
	} = props;
	
	const [accommodation_is_open, setAccommodationIsOpen] = useState([ 1 ]);
	
	const toggleAccommodationItemOpenState = (item_id) => {
		const new_accommodation_is_open = [...accommodation_is_open];
		if (isAccommodationItemOpen(item_id)) {
			new_accommodation_is_open.splice(new_accommodation_is_open.indexOf(item_id), 1);
		}
		else {
			new_accommodation_is_open.push(item_id);
		}
		
		setAccommodationIsOpen(new_accommodation_is_open);
	};
	
	const isAccommodationItemOpen = (item_id) => {
		return accommodation_is_open.indexOf(item_id) != -1;
	};
	
	const route = useRoutes(routes);
	
	let selected_id_accommodation      = -1;
	let selected_id_accommodation_item = -1;
	if (
		route                      !== null &&
		route.props                !== undefined &&
		route.props.children       !== undefined &&
		route.props.children.props !== undefined
	) {
		selected_id_accommodation      = route.props.children.props.idAccommodation;
		selected_id_accommodation_item = route.props.children.props.idAccommodationItem;
	}
	
	const items = [];
	items.push({
		id_accommodation: 0,
		type:             'all',
		title:            'VSE KAPACITETE',
		items:            [],
	});
	
	const sorted_accommodations = Object.values(accommodations).sort((a, b) => {
		if (a.ord < b.ord) return -1;
		if (a.ord > b.ord) return  1;
		return 0;
	});
	for (let i=0; i<sorted_accommodations.length; i++) {
		if (
			[ 'socaland' ].indexOf(props.client) > -1 &&
			[ 'admin', 'tanja', 'gregor' ].indexOf(props.user.username) == -1 &&
			[ 'd0be0242-7515-4eec-a40e-f687eafb9dac', 'd2866441-8726-45ad-a7a7-e26b56b2c809' ].indexOf(sorted_accommodations[i].id_accommodation) > -1
		) continue;
		
		items.push(sorted_accommodations[i]);
	}
	
	const accommodation_item_places_values = Object.values(accommodation_item_places);
	
	return <div className='flex flex-row flex-grow'>
		<div className='accommodation-nav flex flex-col'>
			{items.map((item, idx) => {
				const subitems = Object.values(accommodation_items).filter(
					subitem => subitem.id_accommodation == item.id_accommodation
				);
				
				const only_default_subitem = (subitems.length == 1 && subitems[0].title == 'default');
				
				let cls = '';
				if (
					selected_id_accommodation == item.id_accommodation ||
					(selected_id_accommodation === undefined && item.type == 'activities')
				) {
					cls = 'item-active';
				}
				
				return <div key={'accommodation-' + item.id_accommodation}>
					<div
						className={'item flex flex-row items-center ' + cls}
						style={{ minHeight: '49px' }}
						onClick={() => {
							navigate(item.type == 'activities' ?
								'/reservations/activities/' + item.id_accommodation + '/' + subitems[0].id_accommodation_item :
								'/reservations/accommodation/' + item.id_accommodation + (!only_default_subitem ? '' : '/' + subitems[0].id_accommodation_item)
							);
						}}>
						<span className='flex-grow'>{ item.title }</span>
						
						{subitems.length == 0 || only_default_subitem ? null :
							<Icon
								icon={
									idx == 0 ? 'none' : 
										isAccommodationItemOpen(idx) ? 'chevron-up' : 'chevron-down'
								}
								className={'mr-2 item-open ' + (subitems.length > 0 ? 'active' : '')}
								onClick={e => {
									e.stopPropagation();
									toggleAccommodationItemOpenState(idx);
								}} />
						}
					</div>
					{only_default_subitem ? null :
						<Collapse isOpen={isAccommodationItemOpen(idx)}>
							{subitems.filter(subitem => {
								return accommodation_item_places_values
									.filter(x => x.id_accommodation_item == subitem.id_accommodation_item && x.hidden === false).length > 0;
							}).map((subitem, subidx) => {
								return <div
									key={'accommodation-item-' + subitem.id_accommodation_item}
									className={'subitem ' + (selected_id_accommodation_item == subitem.id_accommodation_item ? 'item-active' : '')}
									onClick={() => {
										navigate(item.id_accommodation_item == -1 ? '/reservations/activities' : '/reservations/accommodation/' + item.id_accommodation + '/' + subitem.id_accommodation_item);
									}}>
									{ subitem.title }
								</div>;
							})}
						</Collapse>
					}
				</div>;
			})}
		</div>
		<div className='flex-1 flex flex-col relative'>
			{route}
		</div>
	</div>;
}

function mapStateToProps(state) {
	return {
		client:                    state.ConstantsSlice.client,
		user:                      state.UserSlice.user,
		accommodations:            state.CodeTablesSlice.accommodations,
		accommodation_items:       state.CodeTablesSlice.accommodation_items,
		accommodation_item_places: state.CodeTablesSlice.accommodation_item_places,
	};
}

export default connect(mapStateToProps)(Reservations);
