import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Button,
	Intent,
	Alert,
	Checkbox,
	Icon,
} from '@blueprintjs/core';

import SelectCustomerDialog from '../reservations/SelectCustomerDialog';
import { deleteCustomer }  from '../../slices/CodeTablesSlice';

import { enqueueItemOnLabelPrinterQueue } from '../../slices/AppSlice';

import { ResultHeader, ResultHeaderWithSort, Result, MultiSelectFilter, TextFilter } from './ResultComponents';

import { debounce } from '../../App';

function Results(props) {
	const {
		table_items_total_count,
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		openAddEditItemDialog,
		tableItemsFilters,
		handleFilterChange,
		countries,
		deleteItem,
		table_items_checked_ids,
		rowCheckStateChanged,
		allRowsCheckStateChanged,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	const customer_types = {
		'natural': { ord: 1, key: 'natural', title: 'Fizična oseba' },
		'company': { ord: 2, key: 'company', title: 'Pravna oseba' },
	};
	
	const check_count = table_items_sort_ids.filter(id => table_items_checked_ids[id] === true).length;
	const all_checked = table_items_sort_ids.length == check_count;
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table overflow-y-auto sticky-header'>
				<ResultHeader columnIdx={1} title={
					<Checkbox
						checked={check_count > 0}
						indeterminate={!all_checked && check_count > 0}
						onChange={event => allRowsCheckStateChanged(event.target.checked)} />
				} />
				<ResultHeaderWithSort
					columnIdx={2} {...sort_props} title='' fieldName='internal_code' />
				<ResultHeader columnIdx={3} title='Urejanje' />
				<ResultHeaderWithSort
					columnIdx={4} {...sort_props} title='Naziv' fieldName='name' />
				<ResultHeaderWithSort
					columnIdx={5} {...sort_props} title='Naslov' fieldName='address' />
				<ResultHeaderWithSort
					columnIdx={6} {...sort_props} title='Pošta' fieldName='post_office' />
				<ResultHeaderWithSort
					columnIdx={7} {...sort_props} title='E-pošta' fieldName='email' />
				<ResultHeaderWithSort
					columnIdx={8} {...sort_props} title='Država' fieldName='country' />
				<ResultHeaderWithSort
					columnIdx={9} {...sort_props} title='Davčna št. / ID za DDV' fieldName='tax_number' />
				<ResultHeaderWithSort
					columnIdx={10} {...sort_props} title='Dobavitelj' fieldName='supplier' />
				<ResultHeaderWithSort
					columnIdx={11} {...sort_props} title='Tip stranke' fieldName='type' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.internal_code}
						onChange={ event => handleFilterChange('internal_code', event) } />
				} />
				<Result columnIdx={3} cls='filters-col' />
				<Result columnIdx={4} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.name}
						onChange={ event => handleFilterChange('name', event) } />
				} />
				<Result columnIdx={5} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.address}
						onChange={ event => handleFilterChange('address', event) } />
				} />
				<Result columnIdx={6} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.post_office}
						onChange={ event => handleFilterChange('post_office', event) } />
				} />
				<Result columnIdx={7} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.email}
						onChange={ event => handleFilterChange('email', event) } />
				} />
				<Result columnIdx={8} cls='filters-col' cls='text-right' child={
					<TextFilter
						value={tableItemsFilters.country}
						onChange={ event => handleFilterChange('country', event) } />
				} />
				<Result columnIdx={9} cls='filters-col' cls='text-right' child={
					<TextFilter
						value={tableItemsFilters.tax_number}
						onChange={ event => handleFilterChange('tax_number', event) } />
				} />
				<Result columnIdx={10} cls='filters-col' child={
					<Checkbox
						checked={tableItemsFilters.supplier === 1}
						indeterminate={tableItemsFilters.supplier === 0}
						onChange={event => {
							let checked = 0;
							if (tableItemsFilters.supplier === 1) {
								checked = -1;
							}
							else if (tableItemsFilters.supplier === 0) {
								checked = 1;
							}
							
							handleFilterChange('supplier', checked);
						}} />
				} />
				<Result columnIdx={11} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='key'
							title_field    ='title'
							items          ={customer_types}
							filtered_keys  ={tableItemsFilters.customer_types}
							displaySort    ={(a, b) => a.ord - b.ord}
							onFilterChange ={values => handleFilterChange('customer_types', values)} />
					</div>
				} />
				
				{table_items_sort_ids.map((id, idx) => {
					const item = table_items[id];
					
					return <React.Fragment key={'customers-result--result-' + id}>
						<Result columnIdx={1} child={
							<Checkbox
								checked={table_items_checked_ids[id] === true}
								onChange={event => rowCheckStateChanged(id, event.target.checked)} />
						} />
						<Result columnIdx={2} child={
							item.internal_code
						} />
						<Result columnIdx={3} child={
							<>
								<Button
									icon='edit'
									intent='primary'
									minimal={true}
									small={true}
									onClick={() => openAddEditItemDialog(item)} />
								{true ? null :
									<Button
										icon='trash'
										intent='danger'
										minimal={true}
										small={true}
										onClick={() => deleteItem(item)} />
								}
							</>
						} />
						<Result columnIdx={4} child={
							item.type == 'natural' ? item.surname + ' ' + item.name : item.company_name
						} />
						<Result columnIdx={5} child={
							item.address
						} />
						<Result columnIdx={6} child={
							item.post_code + ' ' + item.post_office
						} />
						<Result columnIdx={7} child={
							item.email
						} />
						<Result columnIdx={8} cls='text-right' child={
							countries[item.id_country].official_name_local
						} />
						<Result columnIdx={9} cls='text-right' child={
							item.vat_registered ?
								item.vat_prefix + item.tax_number : item.tax_number
						} />
						<Result columnIdx={10} child={
							item.supplier ?
								<Icon icon='tick' color='#394b59' />
								:
								<Icon icon='cross' color='#ff0000' />
						} />
						<Result columnIdx={11} cls='text-right' child={
							item.type == 'natural' ? 'Fizična' : 'Pravna'
						} />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results-sum pb-4'>
			<div className='col'>
				Število zapisov: {table_items_total_count}
				{table_items_total_count > table_items_sort_ids.length ? ' (prikazanih je ' + table_items_sort_ids.length + ' zapisov)' : ''}
			</div>
		</div>
	</>;
}

class Customers extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps = this.updateTableItemsFromProps.bind(this);
		this.setTableItemsSort         = this.setTableItemsSort        .bind(this);
		this.refreshTableItemsSort     = this.refreshTableItemsSort    .bind(this);
		this.filterTableItems          = this.filterTableItems         .bind(this);
		this.handleFilterChange        = this.handleFilterChange       .bind(this);
		this.openAddEditItemDialog     = this.openAddEditItemDialog    .bind(this);
		this.closeAddEditItemDialog    = this.closeAddEditItemDialog   .bind(this);
		this.deleteItem                = this.deleteItem               .bind(this);
		this.printLabels               = this.printLabels              .bind(this);
		
		this.updateTableItemsFromPropsDebounce = debounce((props, state) => {
			this.setState({
				...this.updateTableItemsFromProps(
					props,
					state
				),
			});
		}, 400);
		
		const table_items_filters = {
			internal_code: '',
			name:          '',
			address:       '',
			post_office:   '',
			email:         '',
			country:       '',
			tax_number:    '',
			customer_types: [],
			supplier:       0,
		};
		
		const state = {
			table_items_filters,
			table_items_sort_field:     'internal_code',
			table_items_sort_direction: 'DESC',
			customers: props.customers,
		};
		
		const {
			table_items_total_count,
			table_items,
			all_table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			...state
		});
		
		this.state = {
			table_items_total_count,
			table_items,
			all_table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			item_delete_requested: null,
			table_items_checked_ids: {},
			...state,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.customers != this.state.customers) {
			this.setState({
				customers: this.props.customers,
				...this.updateTableItemsFromProps(this.props, this.state),
			});
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const items_from_props = props.customers;
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(
			state.table_items_filters,
			{
				table_items:                items_from_props,
				table_items_sort_field:     state.table_items_sort_field,
				table_items_sort_direction: state.table_items_sort_direction,
			},
			true
		);
		
		let items             = {};
		let selected_sort_ids = [];
		let all_items         = {};
		let c                 = 0;
		
		for (let i=0; i<table_items_sort_ids.length; i++) {
			const key = table_items_sort_ids[i];
			if (table_items_filtered_ids.indexOf(key) == -1) continue;
			
			if (c < 500) {
				items[key] = items_from_props[key];
				selected_sort_ids.push(key);
			}
			all_items[key] = items_from_props[key];
			c++;
		}
		
		const new_state = {
			table_items_total_count:    c,
			table_items:                items,
			all_table_items:            all_items,
			table_items_sort_ids:       selected_sort_ids,
			table_items_filtered_ids:   selected_sort_ids,
			table_items_sort_field:     state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		new_state.table_items_filtered_ids = selected_sort_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = selected_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a   = '';
			let b   = '';
			let dir = direction;
			
			if (field == 'name') {
				const customer_a = state.table_items[a_key];
				const customer_b = state.table_items[b_key];
				
				if (customer_a === undefined && customer_b === undefined) {
					a = 0;
					b = 0;
				}
				else {
					a = customer_a === undefined ? '' : (
						customer_a.type == 'natural' ? customer_a.surname + ' ' + customer_a.name : customer_a.company_name
					);
					b = customer_b === undefined ? '' : (
						customer_b.type == 'natural' ? customer_b.surname + ' ' + customer_b.name : customer_b.company_name
					);
				}
				
				return a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'tax_number') {
				const customer_a = state.table_items[a_key];
				const customer_b = state.table_items[b_key];
				
				a = customer_a.vat_registered ? customer_a.vat_prefix + customer_a.tax_number : customer_a.tax_number;
				b = customer_b.vat_registered ? customer_b.vat_prefix + customer_b.tax_number : customer_b.tax_number;
			}
			else if (field == 'address') {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
				
				return a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'country') {
				const id_country_a = state.table_items[a_key].id_country;
				const id_country_b = state.table_items[b_key].id_country;
				
				a = this.props.countries[id_country_a].official_name_local;
				b = this.props.countries[id_country_b].official_name_local;
				
				return a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (direction == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_internal_code  = filters.internal_code.toString();
		const filters_name           = filters.name         .toUpperCase();
		const filters_address        = filters.address      .toUpperCase();
		const filters_post_office    = filters.post_office  .toUpperCase();
		const filters_email          = filters.email        .toUpperCase();
		const filters_country        = filters.country      .toUpperCase();
		const filters_tax_number     = filters.tax_number   .toUpperCase();
		const filters_customer_types = filters.customer_types;
		const filters_supplier       = filters.supplier;
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_internal_code != '' && item.internal_code.toString().indexOf(filters_internal_code) == -1) {
				return false;
			}
			
			if (filters_name != '' && (item.type == 'natural' ? item.surname + ' ' + item.name : item.company_name).toUpperCase().indexOf(filters_name) == -1) {
				return false;
			}
			
			if (filters_address != '' && item.address.toUpperCase().indexOf(filters_address) == -1) {
				return false;
			}
			
			if (filters_post_office != '' && (item.post_code + ' ' + item.post_office).toUpperCase().indexOf(filters_post_office) == -1) {
				return false;
			}
			
			if (filters_email != '' && item.email.toUpperCase().indexOf(filters_email) == -1) {
				return false;
			}
			
			if (filters_country != '' && this.props.countries[item.id_country] !== undefined && this.props.countries[item.id_country].official_name_local.toUpperCase().indexOf(filters_country) == -1) {
				return false;
			}
			
			if (filters_tax_number != '' && (item.vat_registered ? item.vat_prefix + item.tax_number : item.tax_number).toUpperCase().indexOf(filters_tax_number) == -1) {
				return false;
			}
			
			if (filters_customer_types.length > 0) {
				let found = false;
				for (let i=0; i<filters_customer_types.length; i++) {
					if (item.type == filters_customer_types[i]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			if (filters_supplier !== 0 &&
				(
					(item.supplier === true  && filters_supplier === -1) ||
					(item.supplier === false && filters_supplier ===  1)
				)
			) {
				return false;
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			internal_code:  this.state.table_items_filters.internal_code,
			name:           this.state.table_items_filters.name,
			address:        this.state.table_items_filters.address,
			post_office:    this.state.table_items_filters.post_office,
			email:          this.state.table_items_filters.email,
			country:        this.state.table_items_filters.country,
			tax_number:     this.state.table_items_filters.tax_number,
			customer_types: this.state.table_items_filters.customer_types,
			supplier:       this.state.table_items_filters.supplier,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.setState({ table_items_filters: new_filters });
		this.updateTableItemsFromPropsDebounce(
			this.props,
			{
				...this.state,
				table_items_filters: new_filters,
			}
		);
	}
	
	openAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: true, add_edit_item_dialog_item: item });
	}
	closeAddEditItemDialog(id_customer) {
		this.setState({ add_edit_item_dialog_open: false });
	}
	deleteItem(item) {
		this.setState({ item_delete_requested: item, token: this.props.token });
	}
	
	printLabels(ids) {
		for (var i=0; i<ids.length; i++) {
			this.props.dispatch(enqueueItemOnLabelPrinterQueue({
				type: 'person',
				item: {
					item: this.state.table_items[ids[i]],
				},
			}));
		}
	}
	
	render() {
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		return <>
			{this.state.item_delete_requested === null ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Briši'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						this.props.dispatch(deleteCustomer(this.state.item_delete_requested.id_customer));
						this.setState({ item_delete_requested: null });
					}}
					onCancel={() => {
						this.setState({ item_delete_requested: null });
					}}>
					Ali res želite izbrisati ta zapis?
				</Alert>
			}
			
			{!this.state.add_edit_item_dialog_open ? null :
				<SelectCustomerDialog
					closeSelectCustomerDialog={this.closeAddEditItemDialog}
					surname={''}
					item={this.state.add_edit_item_dialog_item} />
			}
			
			<div className='flex flex-col flex-grow customers-list'>
				<div className='pl-4 pt-4'>
					<Button intent='primary' icon='plus' onClick={() => this.openAddEditItemDialog(null)}>
						Nova stranka
					</Button>
					<Button
						intent='primary'
						icon='print'
						className='ml-2'
						disabled={check_count == 0}
						onClick={() => {
							this.printLabels(
								this.state.table_items_sort_ids
									.filter(id => this.state.table_items_checked_ids[id] === true)
							);
						}}>
						Natisni nalepke
					</Button>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items_total_count={this.state.table_items_total_count}
							table_items={this.state.table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openAddEditItemDialog={this.openAddEditItemDialog}
							tableItemsFilters={this.state.table_items_filters}
							handleFilterChange={this.handleFilterChange}
							countries={this.props.countries}
							deleteItem={this.deleteItem}
							table_items_checked_ids={this.state.table_items_checked_ids}
							rowCheckStateChanged={(id, state) => {
								let table_items_checked_ids = this.state.table_items_checked_ids;
								
								if (state === false) {
									delete table_items_checked_ids[id];
								}
								else {
									table_items_checked_ids[id] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							allRowsCheckStateChanged={state => {
								const table_items_checked_ids = {};
								for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
									table_items_checked_ids[this.state.table_items_sort_ids[i]] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}} />
					</div>
				</div>
			</div>
		</>;
	}
}
Customers.propTypes = {
};

function mapStateToProps(state) {
	return {
		customers: state.CodeTablesSlice.customers,
		countries: state.CodeTablesSlice.countries,
		api_url:   state.CodeTablesSlice.api_url,
		token:     state.UserSlice.token,
	};
}

export default connect(mapStateToProps)(Customers);
