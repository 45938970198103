import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import {
	Navbar,
	Dialog,
	Classes,
} from '@blueprintjs/core';
import { DatePicker3 } from '@blueprintjs/datetime2';

import { loadDateFnsLocale } from '../utils';

class DatePickerDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			selectedDate: this.props.value,
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={false}
			canEscapeKeyClose={false}
			className='date-picker-dialog'>
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Izbira datuma</Navbar.Heading>
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-grow flex-col'>
						<div className='self-center'>
							<DatePicker3
								dateFnsLocaleLoader={loadDateFnsLocale}
								dateFnsFormat={'dd. MM. yyyy'}
								locale='sl'
								todayButtonText='Danes'
								clearButtonText='Počisti'
								showActionsBar={true}
								canClearSelection={false}
								maxDate={moment().add(5, 'year').toDate()}
								onChange={(selectedDate, isUserChange) => {
									this.setState({
										selectedDate,
									});
									
									if (isUserChange) {
										this.props.closeDatePickerDialog(selectedDate);
									}
								}}
								value={this.state.selectedDate} />
						</div>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
DatePickerDialog.propTypes = {
	closeDatePickerDialog: PropTypes.func,
	value: PropTypes.object,
};

export default DatePickerDialog;
