import { createSlice } from '@reduxjs/toolkit'

const ConstantsSlice = createSlice({
	name: 'constants',
	initialState: {
		api_url: null,
	},
	reducers: { }
});

export const { } = ConstantsSlice.actions;
export default ConstantsSlice.reducer;
