import { createSlice } from '@reduxjs/toolkit'

//import ErrorReportingHelper from '../helpers/ErrorReportingHelper';

const AppSlice = createSlice({
	name: 'app',
	initialState: {}, // defined in AppContainer.js
	reducers: {
		setPosPrinterConnectedStatus: (state, action) => {
			state.pos_printer_connected = action.payload.connected;
		},
		enqueueInvoiceOnPosPrinterQueue: (state, action) => {
			console.log('Enqueuing POS invoice');
			
			/*if (action.payload.type == 'invoice') {
				ErrorReportingHelper.ReportPosPrintInfo(
					JSON.stringify({ op: 'AppSlice.enqueueInvoiceOnPosPrinterQueue: step 1', ts: (new Date()).toISOString(), id_invoice: action.payload.invoice.id_invoice }),
					{ tags: 'pos_print, step_1, item_' + action.payload.invoice.id_invoice }
				);
			}*/
			
			state.pos_printer_queue = state.pos_printer_queue.concat([ action.payload ]);
		},
		requeueInvoiceOnPosPrinterQueue: (state, action) => {
			const pos_printer_queue = state.pos_printer_queue;
			pos_printer_queue.splice(0, 0, action.payload.invoice);
			
			state.pos_printer_queue = pos_printer_queue;
		},
		removeInvoiceFromPosPrinterQueue: (state, action) => {
			const pos_printer_queue = state.pos_printer_queue;
			pos_printer_queue.splice(0, 1);
			state.pos_printer_queue = pos_printer_queue;
		},
		setA4PrinterConnectedStatus: (state, action) => {
			state.a4_printer_connected = action.payload.connected;
		},
		setLabelPrinterConnectedStatus: (state, action) => {
			state.label_printer_connected = action.payload.connected;
		},
		enqueueInvoiceOnA4PrinterQueue: (state, action) => {
			/*if (action.payload.type == 'invoice') {
				ErrorReportingHelper.ReportA4PrintInfo(
					JSON.stringify({ op: 'AppSlice.enqueueInvoiceOnA4PrinterQueue: step 1', ts: (new Date()).toISOString(), id_invoice: action.payload.invoice.id_invoice }),
					{ tags: 'a4_print, step_1, item_' + action.payload.invoice.id_invoice }
				);
			}*/
			
			state.a4_printer_queue = state.a4_printer_queue.concat([ action.payload ]);
		},
		requeueInvoiceOnA4PrinterQueue: (state, action) => {
			const a4_printer_queue = state.a4_printer_queue;
			a4_printer_queue.splice(0, 0, action.payload.invoice);
			
			state.a4_printer_queue = a4_printer_queue;
		},
		removeInvoiceFromA4PrinterQueue: (state, action) => {
			const a4_printer_queue = state.a4_printer_queue;
			a4_printer_queue.splice(0, 1);
			state.a4_printer_queue = a4_printer_queue;
		},
		setPrinters: (state, action) => {
			state.printers = action.payload.printers;
		},
		enqueueItemOnLabelPrinterQueue: (state, action) => {
			state.label_printer_queue = state.label_printer_queue.concat([ action.payload ]);
		},
		requeueItemOnLabelPrinterQueue: (state, action) => {
			const label_printer_queue = state.label_printer_queue;
			label_printer_queue.splice(0, 0, action.payload.item);
			
			state.label_printer_queue = label_printer_queue;
		},
		removeItemFromLabelPrinterQueue: (state, action) => {
			const label_printer_queue = state.label_printer_queue;
			label_printer_queue.splice(0, 1);
			state.label_printer_queue = label_printer_queue;
		},
		setLabelPrinters: (state, action) => {
			state.label_printers = action.payload.printers;
		},
		setCriticalError: (state, action) => {
			state.critical_error = action.payload;
		},
		enqueueAvailableAccommodationsPosPrinterQueue: (state, action) => {
			console.log('Enqueuing POS available accommodations');
			console.log({ action });
			
			state.pos_printer_queue = state.pos_printer_queue.concat([ action.payload ]);
		},
	}
});

export const {
	setPosPrinterConnectedStatus,
	enqueueInvoiceOnPosPrinterQueue,
	requeueInvoiceOnPosPrinterQueue,
	removeInvoiceFromPosPrinterQueue,
	setA4PrinterConnectedStatus,
	setLabelPrinterConnectedStatus,
	enqueueInvoiceOnA4PrinterQueue,
	requeueInvoiceOnA4PrinterQueue,
	removeInvoiceFromA4PrinterQueue,
	setPrinters,
	enqueueItemOnLabelPrinterQueue,
	requeueItemOnLabelPrinterQueue,
	removeItemFromLabelPrinterQueue,
	setLabelPrinters,
	setCriticalError,
	enqueueAvailableAccommodationsPosPrinterQueue,
} = AppSlice.actions;
export default AppSlice.reducer;
