export async function saveItem(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Items/v1/save-item', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}
