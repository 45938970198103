import moment from 'moment';
import uuid   from 'uuid';

import { Honeybadger } from '@honeybadger-io/react';

import WaitFiscalVerificationDialog from './business/WaitFiscalVerificationDialog';
import EditInvoiceDialog            from './business/EditInvoiceDialog';
import InvoiceCreator               from '../helpers/InvoiceCreator';

import { addInvoice, addInvoices, addAdvanceInvoiceConsumptions } from '../slices/BusinessSlice';
import { addDocumentAssociations                                } from '../slices/DocumentSlice';

import { enqueueInvoiceOnPosPrinterQueue, enqueueInvoiceOnA4PrinterQueue } from '../slices/AppSlice';

import {
	loadInvoices,
	loadAdvanceInvoiceConsumptions,
	saveInvoice,
} from '../api/Business';
import {
	loadCashRegisterDocuments,
} from '../api/CashRegisters';

export async function EditInvoiceDialogHelperCloseDialog(
	dispatch,
	token,
	api_url,
	document_types,
	setWaitFiscalVerificationDialogIdInvoice,
	openInvoiceDialog,
	item,
	open_create_invoice_type,
	finish_invoice
) {
	if (item !== undefined) {
		dispatch(addInvoice({
			item,
			token,
		}));
		
		const new_items = await saveInvoice(api_url, item, token, dispatch);
		if (new_items !== null) {
			if (new_items.invoices !== undefined) {
				dispatch(addInvoices(new_items.invoices));
			}
			if (new_items.document_associations !== undefined) {
				dispatch(addDocumentAssociations(new_items.document_associations));
			}
			if (new_items.advance_invoice_consumptions !== undefined) {
				dispatch(addAdvanceInvoiceConsumptions(new_items.advance_invoice_consumptions));
			}
			
			if (finish_invoice && document_types[item.invoice_type].fiscal_verification_enabled) {
				setWaitFiscalVerificationDialogIdInvoice(item.id_invoice);
			}
		}
	}
	
	if (open_create_invoice_type !== undefined) {
		const new_item = InvoiceCreator.Duplicate(item);
		new_item.id_invoice              = uuid.v4();
		new_item.invoice_number          = '';
		new_item.invoice_number_internal = '';
		new_item.invoice_type            = open_create_invoice_type;
		new_item.draft                   = true;
		new_item.reference               = item.invoice_number_internal;
		new_item.original_id_invoice     = item.id_invoice;
		new_item.original_invoice_type   = item.invoice_type;
		new_item.note                    = '';
		
		if (open_create_invoice_type == 'prepayment-invoice') {
			new_item.invoice_date = moment().format('YYYY-MM-DD');
			new_item.payment_date = moment().format('YYYY-MM-DD');
		}
		
		for (let i=0; i<new_item.payments.length; i++) {
			new_item.payments[i].id_invoice_payment = null;
		}
		for (let i=0; i<new_item.items.length; i++) {
			new_item.items[i].id_invoice_item = uuid.v4();
		}
		
		setTimeout(() => {
			openInvoiceDialog(new_item, open_create_invoice_type);
		}, 1000);
	}
};

export function EditInvoiceDialogHelperComponent(props) {
	const {
		invoice_dialog_open,
		closeInvoiceDialog,
		invoice_dialog_item,
		invoice_dialog_type,
		invoice_dialog_advance_invoices_amounts,
		wait_fiscal_verification_dialog_id_invoice,
		invoices,
		general_settings,
		dispatch,
		setWaitFiscalVerificationDialogIdInvoice,
	} = props;
	
	return <>
		{invoice_dialog_open === false ? null :
			<EditInvoiceDialog
				closeEditInvoiceDialog={closeInvoiceDialog}
				item={invoice_dialog_item}
				type={invoice_dialog_type}
				selected_advance_invoices_amounts={invoice_dialog_advance_invoices_amounts} />
		}
		
		{wait_fiscal_verification_dialog_id_invoice === null ? null :
			<WaitFiscalVerificationDialog
				closeWaitFiscalVerificationDialog={(print) => {
					setWaitFiscalVerificationDialogIdInvoice(null);
					
					if (print) {
						const invoice = invoices[wait_fiscal_verification_dialog_id_invoice];
						if (wait_fiscal_verification_dialog_id_invoice === undefined || invoice === undefined) {
							Honeybadger.notify('Invoice or id are null', {
								name: 'Warning',
								wait_fiscal_verification_dialog_id_invoice,
								invoice,
							});
						}
						console.log({ auto_print_type: general_settings.auto_print_type, invoice });
						
						if (general_settings.auto_print_type == 'a4') {
							dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'invoice', lang: 'sl', invoice }));
						}
						else if (general_settings.auto_print_type == 'pos') {
							dispatch(enqueueInvoiceOnPosPrinterQueue({ type: 'invoice', invoice }));
						}
					}
				}}
				id_invoice={wait_fiscal_verification_dialog_id_invoice} />
		}
	</>;
}
