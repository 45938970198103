import {
	addDocumentAssociations,
	addDocumentTypes,
} from '../slices/DocumentSlice';
import {
	setUser,
} from '../slices/UserSlice';

export async function loadDocumentAssociations(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Documents/v1/document-associations', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addDocumentAssociations(items));
	}
}

export async function saveDocumentAssociation(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Documents/v1/save-document-association', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		//delete new_item.items;
		//delete new_item.payments;
		
		//dispatch(addDocumentAssociation(new_item));
		
		return new_item;
	}
	return null;
}

export async function deleteDocumentAssociation(api_url, id, token) {
	const form_data = new FormData();
	form_data.set('id', id);
	
	let response = await fetch(api_url + 'Documents/v1/delete-document-association', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let response_json = await response.json();
	
	if (response_json.error_code !== undefined) {
		if (response_json.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		//delete response_json.items;
		//delete response_json.payments;
		
		//dispatch(addDocumentAssociation(response_json));
	}
	return null;
}

export async function loadDocumentTypes(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Documents/v1/document-types', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addDocumentTypes(items));
	}
}

export async function saveDocumentType(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Documents/v1/save-document-type', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		//delete new_item.items;
		//delete new_item.payments;
		
		//dispatch(addDocumentAssociation(new_item));
		
		return new_item;
	}
	return null;
}
