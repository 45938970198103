import { createSlice } from '@reduxjs/toolkit'
import uuid from 'uuid';

function addItem(state_key_name, key_name, item, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	if (item[key_name] == -1) {
		item[key_name] = uuid.v4();
	}
	
	state[state_key_name][item[key_name]] = item;
}
function addItems(state_key_name, key_name, items, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	for (let i=0; i<items.length; i++) {
		state[state_key_name][items[i][key_name]] = items[i];
	}
}
function deleteItemFromState(state_key_name, payload, state) {
	delete state[state_key_name][payload];
}

const CashRegisterSlice = createSlice({
	name: 'cash-registers',
	initialState: {}, // defined in AppContainer.js
	reducers: {
		addCashRegister:              (state, action) => addItem ('cash_registers',             'id_cash_register',             action.payload, state),
		addCashRegisters:             (state, action) => addItems('cash_registers',             'id_cash_register',             action.payload, state),
		addCashRegisterDailyState:    (state, action) => addItem ('cash_register_daily_states', 'id_cash_register_daily_state', action.payload, state),
		addCashRegisterDailyStates:   (state, action) => addItems('cash_register_daily_states', 'id_cash_register_daily_state', action.payload, state),
		addCashRegisterDocument:      (state, action) => addItem ('cash_register_documents',    'id_cash_register_document',    action.payload, state),
		addCashRegisterDocuments:     (state, action) => addItems('cash_register_documents',    'id_cash_register_document',    action.payload, state),
		deleteCashRegisterDailyState: (state, action) => deleteItemFromState('cash_register_daily_states',                      action.payload, state),
	}
});

export const {
	addCashRegister,
	addCashRegisters,
	addCashRegisterDailyState,
	addCashRegisterDailyStates,
	addCashRegisterDocument,
	addCashRegisterDocuments,
	deleteCashRegisterDailyState,
} = CashRegisterSlice.actions;
export default CashRegisterSlice.reducer;
