import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import {
	Button,
	Tooltip,
	FormGroup,
	Checkbox,
} from '@blueprintjs/core';

import { ResultHeader, Result, DateRangeFilter, TextFilter } from '../code_tables/ResultComponents';

import TextWithTooltip from '../TextWithTooltip';

class MainFilter extends Component {
	constructor(props) {
		super(props);
		
		this.getFilters = this.getFilters.bind(this);
		
		this.state = {
			main_filter__invoice_type: [ 'cash-invoice', 'invoice', 'credit-note' ],
			main_filter__date_range: [
				moment().startOf('month').toDate(),
				new Date()
			],
			main_filter__item_type:          null,
			main_filter__item_internal_code: '',
			main_filter__item_ean:           '',
			main_filter__item_title:         '',
		};
	}
	
	getFilters() {
		return {
			invoice_type:       this.state.main_filter__invoice_type,
			date_range:         this.state.main_filter__date_range,
			item_type:          this.state.main_filter__item_type,
			item_internal_code: this.state.main_filter__item_internal_code,
			item_ean:           this.state.main_filter__item_ean,
			item_title:         this.state.main_filter__item_title,
		};
	}
	
	render() {
		return <>
			<div className='flex flex-col items-start main-filters pl-4 pt-4'>
				<div className='flex flex-row flex-wrap'>
					{[
						{ value: 'cash-invoice', title: 'Blagajniški račun' },
						{ value: 'invoice',      title: 'Faktura'           },
						{ value: 'credit-note',  title: 'Dobropis'          },
					].map(item => {
						return <Checkbox
							key={'invoice-type-' + item.value}
							className='mr-4 mb-4'
							checked={this.state.main_filter__invoice_type.indexOf(item.value) > -1}
							label={item.title}
							onChange={event => {
								const main_filter__invoice_type = [...this.state.main_filter__invoice_type];
								if (event.target.checked) {
									if (main_filter__invoice_type.indexOf(item.value) == -1) {
										main_filter__invoice_type.push(item.value);
									}
								}
								else {
									if (main_filter__invoice_type.indexOf(item.value) > -1) {
										main_filter__invoice_type.splice(main_filter__invoice_type.indexOf(item.value), 1);
									}
								}
								
								this.setState({
									main_filter__invoice_type,
								});
							}} />;
					})}
				</div>
				
				<div className='flex flex-row'>
					<FormGroup className='mr-4' style={{ width: 200 }}>
						<TextWithTooltip
							label='Obdobje'
							tooltip='Obdobje' />
						<DateRangeFilter
							filter_values={this.state.main_filter__date_range}
							original_start_date={null}
							original_end_date={null}
							onFilterChange={values => {
								this.setState({
									main_filter__date_range: values,
								});
							}} />
					</FormGroup>
					
					<FormGroup className='mr-4'>
						<TextWithTooltip
							label='Šifra artikla'
							tooltip='Šifra artikla' />
						<TextFilter
							value={this.state.main_filter__item_internal_code}
							onChange={event => {
								const value = typeof event === 'string' ? event : event.target.value;
								this.setState({ main_filter__item_internal_code: value });
							}} />
					</FormGroup>
					
					<FormGroup className='mr-4'>
						<TextWithTooltip
							label='EAN'
							tooltip='EAN' />
						<TextFilter
							value={this.state.main_filter__item_ean}
							onChange={event => {
								const value = typeof event === 'string' ? event : event.target.value;
								this.setState({ main_filter__item_ean: value });
							}} />
					</FormGroup>
					
					<FormGroup className='mr-4'>
						<TextWithTooltip
							label='Naziv artikla'
							tooltip='Naziv artikla' />
						<TextFilter
							value={this.state.main_filter__item_title}
							onChange={event => {
								const value = typeof event === 'string' ? event : event.target.value;
								this.setState({ main_filter__item_title: value });
							}} />
					</FormGroup>
				</div>
			</div>
			<div className='flex justify-start pl-4 pb-4 mt-3'>
				<Button
					className='w-32'
					intent='primary'
					icon='search'
					disabled={!this.props.mainFiltersValid(this.getFilters())}
					onClick={() => this.props.onChange(this.getFilters())}>
					Prikaži
				</Button>
				<Button
					className='w-40 ml-2'
					intent='primary'
					icon='cloud-download'
					disabled={!this.props.mainFiltersValid(this.getFilters())}
					onClick={() => {
						const params = [
							'date_from='          + moment(this.state.main_filter__date_range[0]).format('YYYY-MM-DD'),
							'date_until='         + moment(this.state.main_filter__date_range[1]).format('YYYY-MM-DD'),
							'invoice_types='      + this.state.main_filter__invoice_type.join(','),
							'item_type='          + (this.state.main_filter__item_type ?? ''),
							'item_internal_code=' + this.state.main_filter__item_internal_code,
							'item_ean='           + this.state.main_filter__item_ean,
							'item_title='         + this.state.main_filter__item_title,
						];
						
						window.open(
							this.props.api_url +
							'Reports/v1/export-items-and-services-to-excel?' + 
							params.join('&')
						);
					}}>
					Izvoz v Excel
				</Button>
			</div>
		</>;
	}
}

class ItemsAndServices extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.mainFiltersValid = this.mainFiltersValid.bind(this);
		this.filterMainItems  = this.filterMainItems .bind(this);
		
		this.state = {
			main_filters:     null,
			main_table_items: null,
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	mainFiltersValid(filters) {
		if (filters.invoice_type.length == 0) return false;
		if (filters.date_range[0] === null || filters.date_range[1] === null) return false;
		/*
			main_filter__item_type:          null,
			main_filter__item_internal_code: null,
			main_filter__item_title:         null,
		*/
		
		return true;
	}
	filterMainItems(filters) {
		if (!this.mainFiltersValid(filters)) return null;
		
		const items = {
			items: {},
			sorted_id_items: [],
			total: {
				quantity:                        0,
				price_with_vat_without_discount: 0,
				discount_amount:                 0,
				price_without_vat_with_discount: 0,
				price_with_vat_with_discount:    0,
			},
		};
		
		const filter_from_date_iso  = moment(filters.date_range[0]).format('YYYY-MM-DD');
		const filter_until_date_iso = moment(filters.date_range[1]).format('YYYY-MM-DD');
		
		const item_title         = filters.item_title        .toLowerCase();
		const item_internal_code = filters.item_internal_code.toLowerCase();
		const item_ean           = filters.item_ean          .toLowerCase();
		
		for (let id_invoice in this.props.invoices) {
			const invoice = this.props.invoices[id_invoice];
			
			if (invoice.draft) continue;
			
			if (filters.invoice_type.indexOf(invoice.invoice_type) == -1) continue;
			
			if (invoice.invoice_date < filter_from_date_iso || invoice.invoice_date > filter_until_date_iso) {
				continue;
			}
			
			for (let i=0; i<invoice.items.length; i++) {
				const invoice_item = invoice.items[i];
				const item         = this.props.items[invoice_item.id_item];
				
				if (
					filters.item_type !== null &&
					item.type != filters.item_type
				) continue;
				
				if (
					item_internal_code.length > 0 &&
					item.internal_code.toLowerCase() != item_internal_code
				) continue;
				
				if (
					item_ean.length > 0 &&
					item.ean.toLowerCase().indexOf(item_ean)
				) continue;
				
				if (
					item_title.length > 0 &&
					item.title.toLowerCase().indexOf(item_title) == -1
				) continue;
				
				if (items.items[item.id_item] === undefined) {
					items.items[item.id_item] = {
						id_item:                         item.id_item,
						quantity:                        0,
						price_with_vat_without_discount: 0,
						discount_amount:                 0,
						price_without_vat_with_discount: 0,
						price_with_vat_with_discount:    0,
					};
				}
				
				const quantity                        = parseFloat(invoice_item.quantity);
				const price                           = parseFloat(invoice_item.price);
				const price_with_vat_without_discount = price * quantity;
				const discount_amount                 = price * quantity * (parseFloat(invoice_item.discount) / 100);
				const price_without_vat_with_discount = price * quantity * (1 - parseFloat(invoice_item.discount) / 100) / (1 + parseFloat(invoice_item.tax_rate) / 100);
				const price_with_vat_with_discount    = price * quantity * (1 - parseFloat(invoice_item.discount) / 100);
				
				items.items[item.id_item].quantity                        += quantity;
				items.items[item.id_item].price_with_vat_without_discount += price_with_vat_without_discount;
				items.items[item.id_item].discount_amount                 += discount_amount;
				items.items[item.id_item].price_without_vat_with_discount += price_without_vat_with_discount;
				items.items[item.id_item].price_with_vat_with_discount    += price_with_vat_with_discount;
				
				items.total.quantity                                      += quantity;
				items.total.price_with_vat_without_discount               += price_with_vat_without_discount;
				items.total.discount_amount                               += discount_amount;
				items.total.price_without_vat_with_discount               += price_without_vat_with_discount;
				items.total.price_with_vat_with_discount                  += price_with_vat_with_discount;
			}
		}
		
		const sorted_id_items = [...Object.keys(items.items)];
		sorted_id_items.sort((a, b) => {
			const item_a = this.props.items[a];
			const item_b = this.props.items[b];
			
			const title_a = item_a.internal_code + ' • ' + item_a.title;
			const title_b = item_b.internal_code + ' • ' + item_b.title;
			
			return title_a.localeCompare(title_b);
		});
		
		items.sorted_id_items = sorted_id_items;
		
		this.setState({
			main_table_items: items,
		});
	}
	
	render() {
		if (
			[ 'sr' ].indexOf(this.props.client) > -1 &&
			[ 'admin', 'goran', 'anita' ].indexOf(this.props.user.username) == -1
		) return null;
		
		return <>
			<div className='flex flex-col flex-grow base-list reports-items-and-services-list'>
				<MainFilter
					api_url={this.props.api_url}
					mainFiltersValid={this.mainFiltersValid}
					onChange={filters => {
						this.setState({
							main_filters: filters,
						});
						
						this.filterMainItems(filters);
					}} />
				
				{this.state.main_table_items === null ? null :
					<div className='flex-1 pl-4 pr-4'>
						<div className='flex-grow' style={{ flexBasis: '0' }}>
							<div className='results'>
								<div className='results-table without-vertical-lines'>
									<ResultHeader columnIdx={1} title='Artikel' />
									<ResultHeader columnIdx={2} title='EAN' />
									<ResultHeader columnIdx={3} cls='text-right' title='Količina' />
									<ResultHeader columnIdx={4} cls='text-right' title='Znesek z DDV brez pop.' />
									<ResultHeader columnIdx={5} cls='text-right' title='Znesek popusta' />
									<ResultHeader columnIdx={6} cls='text-right' title='Znesek brez DDV s pop.' />
									<ResultHeader columnIdx={7} cls='text-right' title='Znesek z DDV s pop.' />
									
									{this.state.main_table_items.sorted_id_items.map(id_item => {
										const item      = this.state.main_table_items.items[id_item];
										const item_item = this.props.items[item.id_item];
										
										return <React.Fragment key={'item-' + item.id_item}>
											<Result columnIdx={1} child={
												item_item.internal_code + ' • ' + item_item.title
											} />
											<Result columnIdx={2} child={
												item_item.ean
											} />
											<Result columnIdx={3} cls='text-right' child={
												item.quantity
													.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
											} />
											<Result columnIdx={4} cls='text-right' child={
												item.price_with_vat_without_discount
													.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
											} />
											<Result columnIdx={5} cls='text-right' child={
												item.discount_amount
													.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
											} />
											<Result columnIdx={6} cls='text-right' child={
												item.price_without_vat_with_discount
													.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
											} />
											<Result columnIdx={7} cls='text-right' child={
												item.price_with_vat_with_discount
													.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
											} />
										</React.Fragment>;
									})}
								
									<Result columnIdx={1} cls='font-bold' child={
										'SKUPAJ'
									} />
									<Result columnIdx={2} cls='font-bold' child={
										''
									} />
									<Result columnIdx={3} cls='font-bold text-right' child={
										this.state.main_table_items.total.quantity
											.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
									} />
									<Result columnIdx={4} cls='font-bold text-right' child={
										this.state.main_table_items.total.price_with_vat_without_discount
											.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
									} />
									<Result columnIdx={5} cls='font-bold text-right' child={
										this.state.main_table_items.total.discount_amount
											.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
									} />
									<Result columnIdx={6} cls='font-bold text-right' child={
										this.state.main_table_items.total.price_without_vat_with_discount
											.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
									} />
									<Result columnIdx={7} cls='font-bold text-right' child={
										this.state.main_table_items.total.price_with_vat_with_discount
											.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
									} />
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</>;
	}
}
ItemsAndServices.propTypes = {
};

function mapStateToProps(state) {
	return {
		client:   state.ConstantsSlice.client,
		api_url:  state.CashRegisterSlice.api_url,
		token:    state.UserSlice.token,
		user:     state.UserSlice.user,
		users:    state.UserSlice.users,
		invoices: state.BusinessSlice.invoices,
		items:    state.CodeTablesSlice.items,
	};
}

export default connect(mapStateToProps)(ItemsAndServices);
