const Invoice = {
	groupInvoiceItemsByTaxes: (invoice, id_advance_invoice_consumption_by_id_consumer_invoices, advance_invoice_consumptions) => {
		let amount_factor = 1;
		
		// with prepayment invoices, we need to calculate amounts from advance_payment_amount
		if (invoice.invoice_type == 'cash-prepayment-invoice' || invoice.invoice_type == 'prepayment-invoice') {
			amount_factor = invoice.invoice_amount == 0 ? 0 : (invoice.advance_payment_amount / invoice.invoice_amount);
		}
		
		// taxes
		let invoice_items_by_tax_rate = {};
		for (let i=0; i<invoice.items.length; i++) {
			const invoice_item = invoice.items[i];
			
			const item_price = invoice_item.price * amount_factor; // calculate using reduced price
			
			const partial_price            = invoice_item.quantity * item_price;
			const partial_price_discounted = partial_price * (1 - invoice_item.discount / 100);
			
			const full_price               = invoice_item.quantity * invoice_item.price;
			const full_price_discounted    = full_price * (1 - invoice_item.discount / 100);
			
			let key = invoice_item.tax_rate;
			if (invoice_item.taxable == 'nontaxable') {
				key = -2;
			}
			else if (invoice_item.taxable == 'tax-exempt') {
				key = -1;
			}
			
			if (invoice_items_by_tax_rate[key] === undefined) {
				invoice_items_by_tax_rate[key] = {
					tax_rate:                             invoice_item.tax_rate,
					partial_price:                        0,
					partial_price_discounted_without_tax: 0,
					partial_price_tax_amount:             0,
					partial_price_discounted:             0,
					partial_price_without_tax:            0,
					full_price:                           0,
					full_price_discounted_without_tax:    0,
					full_price_tax_amount:                0,
					full_price_discounted:                0,
					full_price_without_tax:               0,
				};
			}
			invoice_items_by_tax_rate[key].partial_price            += partial_price;
			invoice_items_by_tax_rate[key].partial_price_discounted += partial_price_discounted;
			invoice_items_by_tax_rate[key].full_price               += full_price;
			invoice_items_by_tax_rate[key].full_price_discounted    += full_price_discounted;
		}
		
		// remove already paid taxes via advance invoices
		if (invoice.invoice_type != 'cash-prepayment-invoice' && invoice.invoice_type != 'prepayment-invoice') {
			const id_advance_invoice_consumptions = id_advance_invoice_consumption_by_id_consumer_invoices[invoice.id_invoice] || [];
			for (let i=0; i<id_advance_invoice_consumptions.length; i++) {
				const advance_invoice_consumption = advance_invoice_consumptions[id_advance_invoice_consumptions[i]];
				for (let j=0; j<advance_invoice_consumption.taxes.length; j++) {
					const tax = advance_invoice_consumption.taxes[j];
					
					const price        = parseFloat(tax.amount);
					const total_amount = parseFloat(tax.amount);
					
					let key = tax.tax_rate;
					if (tax.taxable == 'nontaxable') {
						key = -2;
					}
					else if (tax.taxable == 'tax-exempt') {
						key = -1;
					}
					
					if (invoice_items_by_tax_rate[key] === undefined) {
						invoice_items_by_tax_rate[key] = {
							tax_rate:                             tax.tax_rate,
							partial_price:                        0,
							partial_price_discounted_without_tax: 0,
							partial_price_tax_amount:             0,
							partial_price_discounted:             0,
							partial_price_without_tax:            0,
							full_price:                           0,
							full_price_discounted_without_tax:    0,
							full_price_tax_amount:                0,
							full_price_discounted:                0,
							full_price_without_tax:               0,
						};
					}
					invoice_items_by_tax_rate[key].partial_price            -= price;
					invoice_items_by_tax_rate[key].partial_price_discounted -= total_amount;
				}
			}
		}
		
		let invoice_items_taxes_sums = [
			0, // sum price without VAT, with discount
			0, // sum VAT amount
			0, // sum price with VAT, with discount
			0, // sum price without VAT, without discount
			0, // sum discount value
		];
		
		for (let key in invoice_items_by_tax_rate) {
			invoice_items_by_tax_rate[key].partial_price_discounted_without_tax =
				invoice_items_by_tax_rate[key].partial_price_discounted / (1 + invoice_items_by_tax_rate[key].tax_rate / 100);
			
			invoice_items_by_tax_rate[key].partial_price_tax_amount =
				invoice_items_by_tax_rate[key].partial_price_discounted - invoice_items_by_tax_rate[key].partial_price_discounted_without_tax;
			
			invoice_items_by_tax_rate[key].partial_price_without_tax =
				invoice_items_by_tax_rate[key].partial_price / (1 + invoice_items_by_tax_rate[key].tax_rate / 100);
			
			invoice_items_by_tax_rate[key].full_price_discounted_without_tax =
				invoice_items_by_tax_rate[key].full_price_discounted / (1 + invoice_items_by_tax_rate[key].tax_rate / 100);
			
			invoice_items_by_tax_rate[key].full_price_tax_amount =
				invoice_items_by_tax_rate[key].full_price_discounted - invoice_items_by_tax_rate[key].full_price_discounted_without_tax;
			
			invoice_items_by_tax_rate[key].full_price_without_tax =
				invoice_items_by_tax_rate[key].full_price / (1 + invoice_items_by_tax_rate[key].tax_rate / 100);
			
			invoice_items_taxes_sums[0] += invoice_items_by_tax_rate[key].full_price_discounted_without_tax;
			invoice_items_taxes_sums[1] += invoice_items_by_tax_rate[key].full_price_tax_amount;
			invoice_items_taxes_sums[2] += invoice_items_by_tax_rate[key].full_price_discounted;
			invoice_items_taxes_sums[3] += invoice_items_by_tax_rate[key].full_price_without_tax;
			invoice_items_taxes_sums[4] += invoice_items_by_tax_rate[key].full_price_without_tax - invoice_items_by_tax_rate[key].full_price_discounted_without_tax;
			
			invoice_items_by_tax_rate[key].partial_price                        = Math.properRound(invoice_items_by_tax_rate[key].partial_price,                        2);
			invoice_items_by_tax_rate[key].partial_price_discounted_without_tax = Math.properRound(invoice_items_by_tax_rate[key].partial_price_discounted_without_tax, 2);
			invoice_items_by_tax_rate[key].partial_price_tax_amount             = Math.properRound(invoice_items_by_tax_rate[key].partial_price_tax_amount,             2);
			invoice_items_by_tax_rate[key].partial_price_discounted             = Math.properRound(invoice_items_by_tax_rate[key].partial_price_discounted,             2);
			invoice_items_by_tax_rate[key].partial_price_without_tax            = Math.properRound(invoice_items_by_tax_rate[key].partial_price_without_tax,            2);
			invoice_items_by_tax_rate[key].full_price                           = Math.properRound(invoice_items_by_tax_rate[key].full_price,                           2);
			invoice_items_by_tax_rate[key].full_price_discounted_without_tax    = Math.properRound(invoice_items_by_tax_rate[key].full_price_discounted_without_tax,    2);
			invoice_items_by_tax_rate[key].full_price_tax_amount                = Math.properRound(invoice_items_by_tax_rate[key].full_price_tax_amount,                2);
			invoice_items_by_tax_rate[key].full_price_discounted                = Math.properRound(invoice_items_by_tax_rate[key].full_price_discounted,                2);
			invoice_items_by_tax_rate[key].full_price_without_tax               = Math.properRound(invoice_items_by_tax_rate[key].full_price_without_tax,               2);
		}
		
		invoice_items_taxes_sums[0] = Math.properRound(invoice_items_taxes_sums[0], 2);
		invoice_items_taxes_sums[1] = Math.properRound(invoice_items_taxes_sums[1], 2);
		invoice_items_taxes_sums[2] = Math.properRound(invoice_items_taxes_sums[2], 2);
		invoice_items_taxes_sums[3] = Math.properRound(invoice_items_taxes_sums[3], 2);
		invoice_items_taxes_sums[4] = Math.properRound(invoice_items_taxes_sums[4], 2);
		
		return {
			invoice_items_by_tax_rate,
			invoice_items_taxes_sums,
		};
	},
	findCurrentIdWarehouse: (cash_register, warehouses) => {
		if (cash_register !== null && cash_register !== undefined) {
			return cash_register.id_warehouse;
		}
		for (let id_warehouse in warehouses) {
			const warehouse = warehouses[id_warehouse];
			if (warehouse.default_warehouse) return warehouse.id_warehouse;
		}
		return null;
	},
};

export default Invoice;
