import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	HTMLSelect,
	Intent,
	Navbar,
	Radio,
	RadioGroup,
} from '@blueprintjs/core';

import moment from 'moment';

class SelectPriceListDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id_item_price_list: this.props.item.id_item_price_list,
		};
	}
	
	formatNumber(val, decimal_digit_count) {
		decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
		
		return parseFloat(val)
			.toLocaleString(
				undefined,
				{
					minimumFractionDigits: decimal_digit_count,
					maximumFractionDigits: decimal_digit_count,
				}
			);
	}
	
	render() {
		const self = this;
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => { this.props.closeSelectPriceListDialog(-1); }}
			className='select-price-list-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Izberite cenik</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => { this.props.closeSelectPriceListDialog(-1); }} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<RadioGroup
							label=''
							onChange={event => {
								this.setState({
									id_item_price_list: event.target.value,
								});
							}}
							selectedValue={this.state.id_item_price_list}>
							{Object.values(this.props.priceListItems)
								.sort((a, b) => {
									if (a.valid_from === null && b.valid_from === null) return 0;
									
									if (a.valid_from === null) return  1;
									if (b.valid_from === null) return -1;
									
									if      (a.valid_from < b.valid_from) return  1;
									else if (a.valid_from > b.valid_from) return -1;
									
									return 0;
								})
								.map(function(item) {
									let title = '';
									if (item.valid_from !== null && item.valid_until !== null) {
										title =
											moment(item.valid_from).format('DD. MM. YYYY') +
											' • ' +
											moment(item.valid_until).format('DD. MM. YYYY');
									}
									else if (item.valid_from !== null) {
										title = moment(item.valid_from).format('DD. MM. YYYY');
									}
									else if (item.valid_until !== null) {
										title = moment(item.valid_until).format('DD. MM. YYYY');
									}
									
									return <Radio
										key={ item.id_item_price_list }
										value={ item.id_item_price_list }>
										<div className='inline-flex flex-row'>
											<div className='w-48'>
												{title}
											</div>
											<div className='w-20 text-right'>
												{
													self.formatNumber(self.props.priceListItems[item.id_item_price_list].price)
													+
													' EUR'
												}
											</div>
										</div>
									</Radio>;
								}
							)}
						</RadioGroup>
						
						{true ? null :
							<HTMLSelect
								options={Object.values(this.props.priceListItems).map(function(item) {
									return { label: item.title, value: item.id_item_price_list };
								})}
								onChange={(e) => {
									this.setState({
										id_item_price_list: e.currentTarget.value,
									});
								}}
								value={this.state.id_item_price_list} />
						}
						{true ? null :
							'Cena: ' + this.props.priceListItems[this.state.id_item_price_list].items[this.props.item.id_item].price
						}
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => { this.props.closeSelectPriceListDialog(-1); }}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => { this.props.closeSelectPriceListDialog(this.state.id_item_price_list); }}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
SelectPriceListDialog.propTypes = {
	closeSelectPriceListDialog: PropTypes.func,
	priceListItems: PropTypes.object,
	item: PropTypes.object,
};

export default SelectPriceListDialog;
