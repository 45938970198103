import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Button,
	Intent,
	Alert,
} from '@blueprintjs/core';

import AddEditCountry from './AddEditCountry';
import { addCountry, deleteCountry } from '../../slices/CodeTablesSlice';

import { ResultHeader, ResultHeaderWithSort, Result, TextFilter } from './ResultComponents';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		openAddEditItemDialog,
		tableItemsFilters,
		handleFilterChange,
		deleteItem,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	return <div className='results mt-4 mb-4'>
		<div className='results-table'>
			<ResultHeader columnIdx={1} title='' />
			<ResultHeader columnIdx={2} title='Urejanje' />
			<ResultHeaderWithSort
				columnIdx={3} {...sort_props} title='ISO koda - 2 črki' fieldName='iso_3166_1_a2' />
			<ResultHeaderWithSort
				columnIdx={4} {...sort_props} title='ISO koda - 3 črke' fieldName='iso_3166_1_a3' />
			<ResultHeaderWithSort
				columnIdx={5} {...sort_props} title='ISO koda - številčno' fieldName='iso_3166_1_n3' />
			<ResultHeaderWithSort
				columnIdx={6} {...sort_props} title='Uradni naziv' fieldName='official_name_international' />
			<ResultHeaderWithSort
				columnIdx={7} {...sort_props} title='Naziv' fieldName='official_name_local' />
		</div>
		
		<div className='results-table filters-row'>
			<Result columnIdx={1} />
			<Result columnIdx={2} />
			<Result columnIdx={3} child={
				<TextFilter
					value={tableItemsFilters.iso_3166_1_a2}
					onChange={ event => handleFilterChange('iso_3166_1_a2', event) } />
			} />
			<Result columnIdx={4} child={
				<TextFilter
					value={tableItemsFilters.iso_3166_1_a3}
					onChange={ event => handleFilterChange('iso_3166_1_a3', event) } />
			} />
			<Result columnIdx={5} child={
				<TextFilter
					value={tableItemsFilters.iso_3166_1_n3}
					onChange={ event => handleFilterChange('iso_3166_1_n3', event) } />
			} />
			<Result columnIdx={6} child={
				<TextFilter
					value={tableItemsFilters.official_name_international}
					onChange={ event => handleFilterChange('official_name_international', event) } />
			} />
			<Result columnIdx={7} child={
				<TextFilter
					value={tableItemsFilters.name}
					onChange={ event => handleFilterChange('name', event) } />
			} />
		</div>
		
		{table_items_sort_ids.map((id, idx) => {
			const item = table_items[id];
			
			return <div className='results-table' key={'countries-result--result-' + id}>
				<Result columnIdx={1} child={
					item.serial_internal_code
				} />
				<Result columnIdx={2} child={
					<>
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => openAddEditItemDialog(item)} />
						<Button
							icon='trash'
							intent='danger'
							minimal={true}
							small={true}
							onClick={() => deleteItem(item)} />
					</>
				} />
				<Result columnIdx={3} child={
					item.iso_3166_1_a2
				} />
				<Result columnIdx={4} child={
					item.iso_3166_1_a3
				} />
				<Result columnIdx={5} child={
					item.iso_3166_1_n3
				} />
				<Result columnIdx={6} child={
					item.official_name_international
				} />
				<Result columnIdx={7} child={
					item.official_name_local
				} />
			</div>;
		})}
	</div>;
}

//function Test() {
//	const {
//		CodeTablesSlice,
//	} = useSelector((state: RootState) => state);
//	
//	return <div>country count: { Object.keys(CodeTablesSlice.countries).length }</div>;
//}

class Countries extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps = this.updateTableItemsFromProps.bind(this);
		this.setTableItemsSort         = this.setTableItemsSort        .bind(this);
		this.refreshTableItemsSort     = this.refreshTableItemsSort    .bind(this);
		this.filterTableItems          = this.filterTableItems         .bind(this);
		this.handleFilterChange        = this.handleFilterChange       .bind(this);
		this.openAddEditItemDialog     = this.openAddEditItemDialog    .bind(this);
		this.closeAddEditItemDialog    = this.closeAddEditItemDialog   .bind(this);
		this.deleteItem                = this.deleteItem               .bind(this);
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props);
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: '',
			table_items_sort_direction: 'ASC',
			table_items_filters: {
				name:                        '',
				iso_3166_1_a3:               '',
				iso_3166_1_a2:               '',
				iso_3166_1_n3:               '',
				official_name_international: '',
			},
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			item_delete_requested: null,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.countries != this.state.table_items) {
			this.setState(
				this.updateTableItemsFromProps(this.props)
			);
		}
	}
	
	updateTableItemsFromProps(props) {
		const ids = Object.keys(props.countries);
		
		let sort_ids = [];
		
		if (this.state !== undefined && this.state.table_items_sort_ids !== undefined) {
			for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
				const id = this.state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		//TODO refresh sorting
		//TODO call this.filterTableItems(new_filters);
		
		return {
			table_items: props.countries,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
		};
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids) {
		table_items_filtered_ids = table_items_filtered_ids || this.state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			const a = this.state.table_items[a_key][field];
			const b = this.state.table_items[b_key][field];
			
			if (field == 'official_name_local' || field == 'official_name_international') {
				return a.localeCompare(b) * (direction == 'ASC' ? 1 : -1);
			}
			return (a < b ? -1 : a > b ? 1 : 0) * (direction == 'ASC' ? 1 : -1);
		});
		
		this.setState({
			table_items_sort_ids: ids,
		});
	}
	
	filterTableItems(filters) {
		const filters_name                        = filters.name.toUpperCase();
		const filters_iso_3166_1_a3               = filters.iso_3166_1_a3.toUpperCase();
		const filters_iso_3166_1_a2               = filters.iso_3166_1_a2.toUpperCase();
		const filters_iso_3166_1_n3               = filters.iso_3166_1_n3.toUpperCase();
		const filters_official_name_international = filters.official_name_international.toUpperCase();
		
		const ids = Object.keys(this.state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = this.state.table_items[id];
			
			if (filters_name != '' && item.official_name_local.toUpperCase().indexOf(filters_name) == -1) {
				return false;
			}
			
			if (filters_iso_3166_1_a3 != '' && item.iso_3166_1_a3.toUpperCase().indexOf(filters_iso_3166_1_a3) == -1) {
				return false;
			}
			
			if (filters_iso_3166_1_a2 != '' && item.iso_3166_1_a2.toUpperCase().indexOf(filters_iso_3166_1_a2) == -1) {
				return false;
			}
			
			if (filters_iso_3166_1_n3 != '' && item.iso_3166_1_n3.toString().indexOf(filters_iso_3166_1_n3) == -1) {
				return false;
			}
			
			if (filters_official_name_international != '' && item.official_name_international.toUpperCase().indexOf(filters_official_name_international) == -1) {
				return false;
			}
			
			return true;
		});
		
		this.setState({
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		});
		
		this.refreshTableItemsSort(
			this.state.table_items_sort_field,
			this.state.table_items_sort_direction,
			filtered_ids
		);
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			name:                        this.state.table_items_filters.name,
			iso_3166_1_a3:               this.state.table_items_filters.iso_3166_1_a3,
			iso_3166_1_a2:               this.state.table_items_filters.iso_3166_1_a2,
			iso_3166_1_n3:               this.state.table_items_filters.iso_3166_1_n3,
			official_name_international: this.state.table_items_filters.official_name_international,
		};
		
		let val = event;
		if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: true, add_edit_item_dialog_item: item });
	}
	closeAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: false });
		
		if (item !== undefined) {
			this.props.dispatch(addCountry({ item, token: this.props.token, save_to_api: true }));
		}
	}
	deleteItem(item) {
		this.setState({ item_delete_requested: item, token: this.props.token });
	}
	
	render() {
		return <>
			{this.state.item_delete_requested === null ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Briši'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						this.props.dispatch(deleteCountry(this.state.item_delete_requested.id_country));
						this.setState({ item_delete_requested: null });
					}}
					onCancel={() => {
						this.setState({ item_delete_requested: null });
					}}>
					Ali res želite izbrisati ta zapis?
				</Alert>
			}
			
			{!this.state.add_edit_item_dialog_open ? null :
				<AddEditCountry
					closeAddEditItemDialog={this.closeAddEditItemDialog}
					item={this.state.add_edit_item_dialog_item} />
			}
			
			<div className='flex flex-col flex-grow countries-list'>
				<div className='pl-4 pt-4'>
					<Button intent='primary' icon='plus' onClick={() => this.openAddEditItemDialog(null)}>
						Nov vnos
					</Button>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items={this.state.table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openAddEditItemDialog={this.openAddEditItemDialog}
							tableItemsFilters={this.state.table_items_filters}
							handleFilterChange={this.handleFilterChange}
							deleteItem={this.deleteItem} />
					</div>
				</div>
			</div>
		</>;
	}
}
Countries.propTypes = {
};

function mapStateToProps(state) {
	return {
		countries: state.CodeTablesSlice.countries,
		token:     state.UserSlice.token,
	};
}

export default connect(mapStateToProps)(Countries);
