import React, { useState } from 'react';

import {
	InputGroup,
	NumericInput,
	FormGroup,
	ControlGroup,
	Button,
} from '@blueprintjs/core';

export default function PeopleCountInput(props) {
	return <>
		<FormGroup
			className='mb-0'
			labelFor='adults'
			label={props.adult_title}>
			
			<ControlGroup className='mb-0'>
				<Button icon='minus' tabIndex='-1' minimal={false} small={true} onClick={() => {
					props.onChangeAdultCount(Math.max(0, props.adult_count - 1));
				}}
				disabled={props.disabled} />
				<div className='w-12'>
					<NumericInput
						id='adults'
						className='centered-numeric-input'
						fill={true}
						disabled={props.disabled}
						buttonPosition='none'
						value={props.adult_count}
						min={0}
						onValueChange={val => {
							props.onChangeAdultCount(val);
						}} />
				</div>
				<Button icon='plus' tabIndex='-1' minimal={false} small={true} onClick={() => {
					props.onChangeAdultCount(props.adult_count + 1);
				}}
				disabled={props.disabled} />
			</ControlGroup>
		</FormGroup>
		<FormGroup
			className='mb-0'
			labelFor='children-2'
			label={props.child_2_title}>
			
			<ControlGroup className='mb-0'>
				<Button icon='minus' tabIndex='-1' minimal={false} small={true} onClick={() => {
					props.onChangeChild2Count(Math.max(0, props.child_2_count - 1));
				}}
				disabled={props.disabled} />
				<div className='w-12'>
					<NumericInput
						id='children-2'
						className='centered-numeric-input'
						fill={true}
						disabled={props.disabled}
						buttonPosition='none'
						value={props.child_2_count}
						min={0}
						onValueChange={val => {
							props.onChangeChild2Count(val);
						}} />
				</div>
				<Button icon='plus' tabIndex='-1' minimal={false} small={true} onClick={() => {
					props.onChangeChild2Count(props.child_2_count + 1);
				}}
				disabled={props.disabled} />
			</ControlGroup>
		</FormGroup>
		<FormGroup
			className='mb-0'
			labelFor='children-1'
			label={props.child_1_title}>
			
			<ControlGroup className='mb-0'>
				<Button icon='minus' tabIndex='-1' minimal={false} small={true} onClick={() => {
					props.onChangeChild1Count(Math.max(0, props.child_1_count - 1));
				}}
				disabled={props.disabled} />
				<div className='w-12'>
					<NumericInput
						id='children-1'
						className='centered-numeric-input'
						fill={true}
						disabled={props.disabled}
						buttonPosition='none'
						value={props.child_1_count}
						min={0}
						onValueChange={val => {
							props.onChangeChild1Count(val);
						}} />
				</div>
				<Button icon='plus' tabIndex='-1' minimal={false} small={true} onClick={() => {
					props.onChangeChild1Count(props.child_1_count + 1);
				}}
				disabled={props.disabled} />
			</ControlGroup>
		</FormGroup>
	</>;
}
