import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
	FormGroup,
	InputGroup,
	Button,
	Card,
	Elevation,
} from '@blueprintjs/core';

import TextWithTooltip from './TextWithTooltip';

import { login } from '../api/Users';
import {
	setUser,
} from '../slices/UserSlice';

class Login extends Component {
	constructor(props) {
		super(props);
		
		this.username_input_ref = React.createRef();
		this.password_input_ref = React.createRef();
		
		this.login = this.login.bind(this);
		
		this.state = {
			username: '',
			password: '',
			failed_login: false,
		};
	}
	
	componentDidMount() {
		if (this.username_input_ref.current !== null) {
			this.username_input_ref.current.focus();
		}
	}
	
	async login(e) {
		e.preventDefault();
		
		const response = await login(this.props.api_url, this.state.username, this.state.password);
		
		if (response.error_code === 20002 || response.error_code === 20003) {
			this.setState({ failed_login: true, reason: response.error_code });
			
			if (this.password_input_ref.current !== null) {
				this.password_input_ref.current.focus();
				this.password_input_ref.current.select();
			}
		}
		else {
			this.props.setUser(response);
		}
	}
	
	render() {
		return <>
			<div className='flex flex-col flex-grow justify-center'>
				<Card className='w-64 p-8 pl-16 pr-16 mx-auto box-content' elevation={Elevation.TWO}>
					<div>
						<h4 className='bp5-heading text-center mb-6' style={{ color: '#394b59' }}>
							{ this.props.title || 'Prijava v aplikacijo AssistPRO' }
						</h4>
						
						<form className='flex flex-col items-center' onSubmit={this.login}>
							<div className='flex flex-1 flex-col mb-2 w-64'>
								<FormGroup
									labelFor='form--username'>
									<TextWithTooltip
										label='Uporabniško ime'
										tooltip='Uporabniško ime' />
									<InputGroup
										id='form--username'
										inputRef={this.username_input_ref}
										onChange={event => {
											this.setState({
												username:     event.target.value,
												failed_login: false,
											});
										}} />
								</FormGroup>
							</div>
							
							<div className='flex flex-1 flex-col mb-2 w-64'>
								<FormGroup
									labelFor='form--password'>
									<TextWithTooltip
										label='Geslo'
										tooltip='Geslo' />
									<InputGroup
										id='form--password'
										type='password'
										inputRef={this.password_input_ref}
										onChange={event => {
											this.setState({
												password:     event.target.value,
												failed_login: false,
											});
										}} />
								</FormGroup>
							</div>
							
							<div className='flex flex-row justify-end w-64'>
								<Button
									icon='log-in'
									type='submit'
									onClick={this.login}
									intent='primary'>
									Prijava
								</Button>
							</div>
							
							{!this.state.failed_login ? null :
								<div className='text-red-700 pt-4'>{(() => {
									if (this.state.reason == 20002) {
										return 'Napačno uporabniško ime ali geslo';
									}
									else if (this.state.reason == 20003) {
										return 'Uporabnik neaktiven';
									}
									return 'Neznana napaka';
								})()}</div>
							}
						</form>
					</div>
				</Card>
			</div>
		</>;
	}
}
Login.propTypes = {
	setUser: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		api_url: state.ConstantsSlice.api_url,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setUser: (data) => {
			dispatch(setUser(data));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
