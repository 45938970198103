import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Button,
	InputGroup,
	ControlGroup,
	FormGroup,
	MenuItem,
	Checkbox,
	Navbar,
	Dialog,
	Alignment,
	RadioGroup,
	Radio,
	Classes,
	Intent,
	Alert,
	Icon,
} from '@blueprintjs/core';
import { DateInput3, IDateFormatProps } from '@blueprintjs/datetime2';
import { Suggest } from '@blueprintjs/select';

import moment from 'moment';
import formatISO from 'date-fns/formatISO';

import TextWithTooltip      from '../TextWithTooltip';
import DateTimePickerDialog from '../DateTimePickerDialog';
import CustomerSelect       from '../CustomerSelect';
import SimpleSuggest        from '../SimpleSuggest';
import { AppToaster }       from '../AppToaster';
import { loadDateFnsLocale } from '../../utils';

class EditReservationCustomerDialog extends Component {
	constructor(props) {
		super(props);
		
		this.openDateTimePickerDialog  = this.openDateTimePickerDialog .bind(this);
		this.closeDateTimePickerDialog = this.closeDateTimePickerDialog.bind(this);
		this.closeWithItem             = this.closeWithItem            .bind(this);
		this.closeDialog               = this.closeDialog              .bind(this);
		this.getTouristTaxForBirthDate = this.getTouristTaxForBirthDate.bind(this);
		this.findSimilarCustomers      = this.findSimilarCustomers     .bind(this);
		
		let customer = props.item === null ? null : props.customers[props.item.id_customer];
		if (customer === undefined) customer = null;
		
		const accommodation_item_place = props.accommodation_item_places[props.id_accommodation_item_place];
		const accommodation_item       = props.accommodation_items      [accommodation_item_place.id_accommodation_item];
		const accommodation            = props.accommodations           [accommodation_item.id_accommodation];
		
		let default_check_out_time = accommodation.default_check_out_time;
		
		let check_in  = null;
		let check_out = null;
		
		if (props.item !== null) {
			check_in  = new Date(props.item.check_in);
			check_out = props.item.check_out === null ? null : new Date(props.item.check_out);
		}
		else {
			check_in = moment().toDate();
		}
		
		if (check_out === null) {
			check_out = props.default_check_out;
		}
		
		const birthdate = customer === null || customer.birth_date === null ? null : new Date(customer.birth_date);
		
		let id_tourist_tax = props.item === null ? null : props.item.id_tourist_tax;
		if (props.item !== null && id_tourist_tax === null && birthdate !== null) {
			id_tourist_tax = this.getTouristTaxForBirthDate(birthdate);
		}
		
		this.state = {
			id_customer:                   props.item === null ? -1   : props.item.id_customer,
			id_country:                    customer        === null ? -1   : customer.id_country,
			surname:                       customer        === null ? ''   : customer.surname,
			name:                          customer        === null ? ''   : customer.name,
			identification_number:         customer        === null ? ''   : customer.identification_number,
			id_identification_type:        customer        === null ? ''   : customer.id_identification_type,
			sex:                           customer        === null ? null : customer.sex,
			birthdate,
			check_in,
			check_out,
			default_check_out_time:        default_check_out_time,
			id_group:                      props.item === null ? ''   : props.item.id_group,
			tourist_tax_checked:           props.item === null ? true : props.item.pay_tourist_tax,
			e_tourist_checked:             props.item === null ? true : props.item.add_to_guest_book,
			agree_further_processing:      props.item === null ? false : props.item.agree_to_further_processing,
			date_picker_dialog_open:       false,
			date_picker_dialog_state_name: null,
			id_tourist_tax,
			validation_failed_fields:      [],
			close_dialog_confirm_open:     false,
			accommodation_item_place,
			accommodation_item,
			accommodation,
			similar_customers: [],
		};
		this.state.similar_customers = this.findSimilarCustomers(true);
	}
	
	componentDidMount() {
		const change_case = val => {
			if (val === null) return val;
			
			if (this.props.general_settings.scanner_change_case == 'uppercase') {
				return val.toUpperCase();
			}
			if (this.props.general_settings.scanner_change_case == 'lowercase') {
				return val.toLowerCase();
			}
			if (this.props.general_settings.scanner_change_case == 'title-case') {
				// from https://stackoverflow.com/a/37931321
				return val.replace(/\S+/g, str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase());
			}
			return val;
		};
		
		this._scanner_ws = new WebSocket('ws://127.0.0.1:9998/Scanner');
		this._scanner_ws.onmessage = event => {
			const document_data = JSON.parse(event.data);
			console.log(document_data);
			
			const new_state = {};
			
			if (document_data.person_name !== null && document_data.person_name.length > 0) {
				new_state.name = change_case(document_data.person_name);
			}
			if (document_data.person_surname !== null && document_data.person_surname.length > 0) {
				new_state.surname = change_case(document_data.person_surname);
			}
			if (document_data.person_nationality !== null) {
				for (let id_country in this.props.countries) {
					const country = this.props.countries[id_country];
					if (
						document_data.person_nationality == country.iso_3166_1_a3 ||
						document_data.person_nationality == country.iso_3166_1_a2
					) {
						new_state.id_country = id_country;
						break;
					}
				}
			}
			if (document_data.person_birth_date !== null) {
				new_state.birthdate      = new Date(document_data.person_birth_date);
				new_state.id_tourist_tax = this.getTouristTaxForBirthDate(new_state.birthdate);
				
				const input = document.querySelector('.check-in-button button');
				if (input !== null) {
					input.focus();
				}
			}
			if (document_data.document_type !== null) {
				for (let id_identification_type in this.props.identification_types) {
					const identification_type = this.props.identification_types[id_identification_type];
					if (
						identification_type.ocr_internal_code !== null &&
						identification_type.ocr_internal_code.indexOf(';' + document_data.document_type + ';') > -1
					) {
						new_state.id_identification_type = id_identification_type;
						break;
					}
				}
			}
			if (document_data.document_number !== null) {
				new_state.identification_number = document_data.document_number;
			}
			if (document_data.person_sex !== null) {
				new_state.sex = document_data.person_sex == 'F' ? 'female' : 'male';
			}
			
			this.setState(new_state);
		};
		this._scanner_ws.onopen = event => {
			if (this._scanner_ws.readyState == WebSocket.OPEN) {
				this._scanner_ws.send('START');
			}
		};
	}
	componentWillUnmount() {
		try {
			if (this._scanner_ws.readyState == WebSocket.OPEN) {
				this._scanner_ws.send('STOP');
			}
		}
		catch (err) { }
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.name != this.state.name || prevState.surname != this.state.surname || prevState.id_country != this.state.id_country) {
			this.findSimilarCustomers(false);
		}
	}
	
	findSimilarCustomers(only_return) {
		if (this.state.name.length >= 2 && this.state.surname.length >= 2) {
			const name    = this.state.name   .toLowerCase();
			const surname = this.state.surname.toLowerCase();
			
			const found_customers = [];
			for (let id_customer in this.props.customers) {
				// skip customer we're currently editing
				if (this.props.item !== null && this.props.item.id_customer === id_customer) continue;
				
				const customer = this.props.customers[id_customer];
				if (customer.name.toLowerCase().indexOf(name) != -1 && customer.surname.toLowerCase().indexOf(surname) != -1) {
					if (
						this.state.id_country === null ||
						this.state.id_country == '' ||
						this.state.id_country == -1 ||
						customer.id_country === null ||
						customer.id_country == this.state.id_country
					) {
						found_customers.push(customer);
					}
				}
			}
			
			if (!only_return) this.setState({ similar_customers: found_customers });
			return found_customers;
		}
		else {
			if (!only_return) this.setState({ similar_customers: [] });
			return [];
		}
	}
	
	openDateTimePickerDialog(state_name) {
		let value = this.state[state_name];
		if (state_name == 'check_out' && value === undefined) {
			value = moment();
			
			const default_check_out_time_arr = this.state.default_check_out_time.split(':');
			if (default_check_out_time_arr.length == 3) {
				value.hour  (parseInt(default_check_out_time_arr[0], 10));
				value.minute(parseInt(default_check_out_time_arr[1], 10));
			}
			
			value = value.toDate();
		}
		
		this.setState({
			date_picker_dialog_open: true,
			date_picker_dialog_state_name: state_name,
			date_picker_dialog_value: value,
		});
	}
	closeDateTimePickerDialog(newValue) {
		this.setState({ date_picker_dialog_open: false });
		
		if (newValue !== false) {
			const obj = {};
			obj[this.state.date_picker_dialog_state_name] = newValue;
			this.setState(obj);
		}
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
		}
	}
	
	// from https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd/7091965#7091965
	getAge(birthdate) {
		if (birthdate === null) return 0;
		
		const today = new Date();
		let   age   = today.getFullYear() - birthdate.getFullYear();
		const m     = today.getMonth() - birthdate.getMonth();
		
		if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
			age--;
		}
		
		return age;
	}
	
	getTouristTaxForBirthDate(birthdate) {
		const age_in_years = this.getAge(birthdate);
		
		let id_tourist_tax = 'f9a563f7-c2b8-457a-9efa-c23adc860902'; // full tax
		if (age_in_years < 7) {
			id_tourist_tax = 'a3ada055-a624-4eb5-baad-49a2c84e6f6e';
		}
		else if (age_in_years < 18) {
			id_tourist_tax = '5a8d808e-eb7e-4adf-abef-ed4afb76779b';
		}
		
		return id_tourist_tax;
	}
	
	closeWithItem(reopen) {
		const new_item = 
			{
				id_reservation_customer:  this.props.item === null ? -1 : this.props.item.id_reservation_customer,
				id_customer:              this.state.id_customer,
				id_country:               this.state.id_country,
				surname:                  this.state.surname,
				name:                     this.state.name,
				identification_number:    this.state.identification_number,
				sex:                      this.state.sex,
				birthdate:                this.state.birthdate,
				check_in:                 this.state.check_in,
				check_out:                this.state.check_out,
				id_group:                 this.state.id_group,
				tourist_tax_checked:      this.state.tourist_tax_checked,
				e_tourist_checked:        this.state.e_tourist_checked,
				agree_further_processing: this.state.agree_further_processing,
				id_tourist_tax:           this.state.id_tourist_tax,
				id_identification_type:   this.state.id_identification_type,
			};
		
		// validate missing/empty fields
		const validation_failed_fields       = [];
		const validation_failed_field_titles = [];
		
		if (
			new_item.name.length == 0 &&
			new_item.surname.length == 0
		) {
			validation_failed_fields.push('name');
			validation_failed_fields.push('surname');
			validation_failed_field_titles.push('ime in priimek');
		}
		if (new_item.birthdate === null) {
			validation_failed_fields.push('birthdate');
			validation_failed_field_titles.push('datum rojstva');
		}
		if (new_item.sex === null) {
			validation_failed_fields.push('sex');
			validation_failed_field_titles.push('spol');
		}
		if (new_item.id_country === null || new_item.id_country == -1) {
			validation_failed_fields.push('id_country');
			validation_failed_field_titles.push('država');
		}
		if (new_item.id_tourist_tax === null) {
			validation_failed_fields.push('id_tourist_tax');
			validation_failed_field_titles.push('turistična taksa');
		}
		if (new_item.id_identification_type === null || new_item.id_identification_type == '') {
			validation_failed_fields.push('id_identification_type');
			validation_failed_field_titles.push('vrsta osebnega dokumenta');
		}
		if (new_item.identification_number === null || new_item.identification_number.length == 0) {
			validation_failed_fields.push('identification_number');
			validation_failed_field_titles.push('številka osebnega dokumenta');
		}
		if (new_item.check_in === null) {
			validation_failed_fields.push('check_in');
			validation_failed_field_titles.push('prijava');
		}
		if (new_item.check_out === null) {
			validation_failed_fields.push('check_out');
			validation_failed_field_titles.push('odjava');
		}
		
		this.setState({ validation_failed_fields });
		
		if (validation_failed_fields.length > 0) {
			AppToaster.show({
				message: <div>
					Za prijavo gosta, je potrebno izpolniti polja:
					<br />
					{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
				</div>,
				intent: 'danger',
				icon: 'issue'
			});
			return false;
		}
		
		if (new_item.check_out !== null && new_item.check_out < new_item.check_in) {
			validation_failed_fields.push('check_in');
			validation_failed_fields.push('check_out');
			
			this.setState({ validation_failed_fields });
			
			AppToaster.show({
				message: 'Odjava ne sme biti pred prijavo',
				intent: 'danger',
				icon: 'issue'
			});
			return false;
		}
		
		this.props.closeEditReservationCustomerDialog(
			new_item,
			reopen
		);
	}
	
	closeDialog(confirm) {
		if (confirm) {
			this.setState({
				close_dialog_confirm_open: true,
			});
			return;
		}
		this.props.closeEditReservationCustomerDialog();
	}
	
	render() {
		const groups = [
			/*{ id_group: 1, title: 'Skupina 1' },
			{ id_group: 2, title: 'Nemci' },
			{ id_group: 3, title: 'Tavelika skupina' },*/
		];
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={
				!this.state.date_picker_dialog_open
			}
			onClose={() => this.closeDialog(true)}
			className='select-person-dialog'>
			
			{!this.state.date_picker_dialog_open ? null :
				<DateTimePickerDialog
					closeDateTimePickerDialog={this.closeDateTimePickerDialog}
					value={this.state.date_picker_dialog_value} />
			}
			
			{!this.state.close_dialog_confirm_open ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Zapri'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='help'
					intent={Intent.PRIMARY}
					isOpen={true}
					onConfirm={() => {
						this.closeDialog(false);
						this.setState({
							close_dialog_confirm_open: false,
						});
					}}
					onCancel={() => {
						this.setState({
							close_dialog_confirm_open: false,
						});
					}}>
					Res želite zapreti brez shranjevanja?
				</Alert>
			}
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Vpis gosta</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.closeDialog(true)} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-grow flex-row'>
						<div className='flex flex-1 pr-2 flex-col'>
							<div>
								<CustomerSelect
									filter_type='natural'
									itemSelected={item => {
										const id_tourist_tax = item.birth_date === null ? null : this.getTouristTaxForBirthDate(new Date(item.birth_date));
										
										this.setState({
											id_customer:            item.id_customer,
											name:                   item.name,
											surname:                item.surname,
											id_country:             item.id_country,
											sex:                    item.sex,
											birthdate:              item.birth_date === null ? null : new Date(item.birth_date),
											identification_number:  item.identification_number,
											id_identification_type: item.id_identification_type,
											id_tourist_tax,
										});
									}}
									fieldTitle='Oseba'
									focus={true} />
							</div>
							<div className='flex flex-row justify-between'>
								<FormGroup
									labelFor='select-person--surname'
									className={
										'flex-1 mr-1 ' +
										(this.state.validation_failed_fields.indexOf('surname') == -1 ?
											'' : 'validation-failed')
									}>
									<TextWithTooltip
										label='Priimek'
										tooltip='Priimek' />
									<InputGroup
										id='select-person--surname'
										value={this.state.surname}
										onChange={ event => this.setState({ surname: event.target.value }) } />
								</FormGroup>
								<FormGroup
									labelFor='select-person--name'
									className={
										'flex-1 mr-1 ' +
										(this.state.validation_failed_fields.indexOf('name') == -1 ?
											'' : 'validation-failed')
									}>
									<TextWithTooltip
										label='Ime'
										tooltip='Ime' />
									<InputGroup
										id='select-person--name'
										value={this.state.name}
										onChange={ event => this.setState({ name: event.target.value }) } />
								</FormGroup>
							</div>
							<div className='flex flex-row justify-between'>
								<FormGroup
									labelFor='select-person--sex'
									className={
										(this.state.validation_failed_fields.indexOf('sex') == -1 ?
											'' : 'validation-failed')
									}>
									<TextWithTooltip
										label='Spol'
										tooltip='Spol' />
									<RadioGroup
										id='select-person--sex'
										inline={true}
										onChange={(event) => {
											this.setState({
												sex: event.target.value,
											});
										}}
										selectedValue={this.state.sex}>
										
										<Radio label='Moški'  value='male' />
										<Radio label='Ženski' value='female' />
										
									</RadioGroup>
								</FormGroup>
								
								<div className='flex flex-row'>
									<FormGroup
										labelFor='select-person--birthdate'
										className={
											(this.state.validation_failed_fields.indexOf('birthdate') == -1 ?
												'' : 'validation-failed')
										}>
										<TextWithTooltip
											label='Datum rojstva'
											tooltip='Datum rojstva' />
										<DateInput3
											id='select-person--birthdate'
											dateFnsLocaleLoader={loadDateFnsLocale}
											dateFnsFormat={'dd. MM. yyyy'}
											locale='sl'
											inputProps={{ placeholder: '01. 01. ' + new Date().getFullYear() }}
											popoverProps={{ position: 'right' }}
											selectAllOnFocus={true}
											minDate={new Date('1900-01-01')}
											maxDate={moment().add('10', 'years').toDate()}
											onChange={(selectedDate, isUserChange) => {
												const id_tourist_tax = this.getTouristTaxForBirthDate(moment(selectedDate).toDate());
												
												if (isUserChange) this.setState({ birthdate: moment(selectedDate).toDate(), id_tourist_tax });
											}}
											value={this.state.birthdate === null ? null : formatISO(this.state.birthdate)} />
									</FormGroup>
									
									<div className='pt-5 ml-2 bp5-text-large font-bold select-person--age'>
										{this.state.birthdate === null ? '' :
											this.getAge(this.state.birthdate) + ' let'
										}
									</div>
								</div>
							</div>
							<FormGroup
								labelFor='select-person--identification-type'
								className={
									(this.state.validation_failed_fields.indexOf('id_identification_type') == -1 ?
										'' : 'validation-failed')
								}>
								<TextWithTooltip
									label='Vrsta osebnega dokumenta'
									tooltip='Vrsta osebnega dokumenta' />
								<SimpleSuggest
									matchKeyName='title'
									valueKeyName='id_identification_type'
									items={Object.values(this.props.identification_types).sort((a, b) => a.ord - b.ord)}
									onItemSelect={item => {
										this.setState({
											id_identification_type: item.id_identification_type,
										});
									}}
									selectedItem={this.props.identification_types[this.state.id_identification_type]}
									placeholder='Išči' />
							</FormGroup>
							<FormGroup
								labelFor='select-person--identification-number'
								className={
									(this.state.validation_failed_fields.indexOf('identification_number') == -1 ?
										'' : 'validation-failed')
								}>
								<TextWithTooltip
									label='Številka osebnega dokumenta'
									tooltip='Številka osebnega dokumenta' />
								<InputGroup
									id='select-person--identification-number'
									value={this.state.identification_number}
									onChange={ event => this.setState({ identification_number: event.target.value }) } />
							</FormGroup>
							<FormGroup
								labelFor='select-person--country'
								className={
									(this.state.validation_failed_fields.indexOf('id_country') == -1 ?
										'' : 'validation-failed')
								}>
								<TextWithTooltip
									label='Država'
									tooltip='Država' />
								<SimpleSuggest
									matchKeyName='official_name_local'
									valueKeyName='id_country'
									items={Object.values(this.props.countries)}
									onItemSelect={item => {
										this.setState({
											id_country: item.id_country,
										});
									}}
									selectedItem={this.props.countries[this.state.id_country]}
									placeholder='Išči' />
							</FormGroup>
							<FormGroup
								labelFor='select-person--tourist-tax'
								className={
									(this.state.validation_failed_fields.indexOf('id_tourist_tax') == -1 ?
										'' : 'validation-failed')
								}>
								<TextWithTooltip
									label='Turistična taksa'
									tooltip='Turistična taksa' />
								<SimpleSuggest
									matchKeyName='title'
									valueKeyName='id_tourist_tax'
									items={Object.values(this.props.tourist_taxes)
										.filter(x =>
											x.id_accommodations === null ||
											x.id_accommodations.indexOf(this.state.accommodation.id_accommodation) != -1
										)
										.sort((a, b) => a.ord - b.ord)
									}
									onItemSelect={item => {
										this.setState({
											id_tourist_tax: item.id_tourist_tax,
										});
									}}
									selectedItem={this.props.tourist_taxes[this.state.id_tourist_tax]}
									placeholder='Išči'
									className='tourist-tax-suggest' />
							</FormGroup>
							
							<div className='flex flex-row'>
								<FormGroup
									labelFor='select-person--check-in-time'
									className={
										'check-in-button mr-4 ' +
										(this.state.validation_failed_fields.indexOf('check_in') == -1 ?
											'' : 'validation-failed')
									}>
									<TextWithTooltip
										label='Prijava'
										tooltip='Prijava' />
									<Button
										id='select-person--check-in-time'
										onClick={() => { this.openDateTimePickerDialog('check_in') }}>
										{
											this.state.check_in === null || this.state.check_in === false ?
												'izberi datum in čas' :
												this.getMomentFormatter('DD. MM. YYYY HH:mm').formatDate(this.state.check_in, 'sl')
										}
									</Button>
								</FormGroup>
								<FormGroup
									labelFor='select-person--check-out-time'
									className={
										(this.state.validation_failed_fields.indexOf('check_out') == -1 ?
											'' : 'validation-failed')
									}>
									<TextWithTooltip
										label='Odjava'
										tooltip='Odjava' />
									<Button
										id='select-person--check-out-time'
										onClick={() => { this.openDateTimePickerDialog('check_out') }}>
										{
											this.state.check_out === null || this.state.check_out === false ?
												'izberi datum in čas' :
												this.getMomentFormatter('DD. MM. YYYY HH:mm').formatDate(this.state.check_out, 'sl')
										}
									</Button>
								</FormGroup>
								<div className='flex-grow'></div>
							</div>
							
							<FormGroup
								labelFor='select-person--group'>
								<TextWithTooltip
									label='Skupina'
									tooltip='Izberi skupino oz. vnesi novo' />
								<Suggest
									id='select-person--group'
									itemPredicate={(query, item, idx, exactMatch) => {
										return item.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
									}}
									itemRenderer={(item, { handleClick, modifiers }) => {
										return <MenuItem
											active={modifiers.active}
											key={item.id_group}
											onClick={handleClick}
											text={item.title} />;
									}}
									items={groups}
									onItemSelect={item => {
										this.setState({ group: item });
									}}
									openOnKeyDown={true}
									popoverProps={{
										targetProps: { style: { display: 'block' } },
										minimal: true,
									}}
									fill={true}
									createNewItemRenderer={(query, active, handleClick) => {
										return <MenuItem
											active={active}
											onClick={handleClick}
											text={'Ustvari novo: ' + query} />;
									}}
									createNewItemFromQuery={query => {
										return {
											id_group: Math.floor(Math.random() * Math.floor(999)) * -1,
											title: query,
										};
									}}
									selectedItem={this.state.id_group === null ? null : groups[this.state.id_group]}
									inputProps={{
										placeholder: 'Išči',
									}}
									inputValueRenderer={item => item.title} />
							</FormGroup>
							
							<FormGroup>
								<Checkbox
									checked={this.state.tourist_tax_checked}
									onChange={event => {
										this.setState({ tourist_tax_checked: event.target.checked });
									}}>
									Obračunaj turistično takso
								</Checkbox>
								<Checkbox
									className='mb-5'
									checked={this.state.e_tourist_checked}
									onChange={event => {
										this.setState({ e_tourist_checked: event.target.checked });
									}}>
									Upoštevaj za e-Turizem in knjigo gostov
								</Checkbox>
								<Checkbox
									checked={this.state.agree_further_processing}
									onChange={event => {
										this.setState({ agree_further_processing: event.target.checked });
									}}>
									<TextWithTooltip
										label='Soglasje za nadaljnjo obdelavo'
										tooltip='Gost s tem soglaša za nadaljnjo obdelavo njegovih podatkov za potrebe trženja/obveščanja'
										inline={true} />
								</Checkbox>
							</FormGroup>
							
							{this.state.similar_customers.length == 0 ? null :
								<div className='text-orange-600 flex flex-row'>
									<Icon icon='warning-sign' className='mr-2' size={32} />
									<div>
										<div>Najdene obstoječe stranke s podobnimi podatki:</div>
										<div>{ this.state.similar_customers.map(customer => {
											let value = customer.name + ' ' + customer.surname;
											
											let additional_data = [];
											if (customer.id_country !== null) {
												additional_data.push(this.props.countries[customer.id_country].official_name_local);
											}
											if (customer.birth_date !== null) {
												additional_data.push('RD ' + moment(customer.birth_date).format('DD.MM.YYYY'));
											}
											
											if (additional_data.length > 0) {
												value += ' (' + additional_data.join(', ') + ')';
											}
											
											return value;
										}).join(', ') }</div>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.closeDialog(false)}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => this.closeWithItem(true)}>
							Shrani in dodaj novega
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => this.closeWithItem(false)}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
EditReservationCustomerDialog.propTypes = {
	closeEditReservationCustomerDialog: PropTypes.func,
	checkOut: PropTypes.any,
};

function mapStateToProps(state) {
	return {
		countries:                 state.CodeTablesSlice.countries,
		customers:                 state.CodeTablesSlice.customers,
		identification_types:      state.CodeTablesSlice.identification_types,
		tourist_taxes:             state.CodeTablesSlice.tourist_taxes,
		accommodations:            state.CodeTablesSlice.accommodations,
		accommodation_items:       state.CodeTablesSlice.accommodation_items,
		accommodation_item_places: state.CodeTablesSlice.accommodation_item_places,
		items:                     state.CodeTablesSlice.items,
		general_settings:          state.SettingsSlice.general,
	};
}

export default connect(mapStateToProps)(EditReservationCustomerDialog);
