import {
	setGeneralSettings,
} from '../slices/SettingsSlice';
import {
	setUser,
} from '../slices/UserSlice';

export async function loadGeneralSettings(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Settings/v1/settings', { headers: { 'Authorization': 'Bearer ' + token } });
	let item = await response.json();
	
	if (item.error_code !== undefined) {
		if (item.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(setGeneralSettings(item));
	}
}

export async function saveGeneralSettings(api_url, settings, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(settings));
	
	let response = await fetch(api_url + 'Settings/v1/save-settings', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		//delete new_item.items;
		//delete new_item.payments;
		
		//dispatch(addInvoice(new_item));
		
		return new_item;
	}
	return null;
}
