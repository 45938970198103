export function getReservationRequestItemServices(item, accommodation, type, return_html) {
	const lang = 'sl';
	
	let services_info = null;
	if (accommodation[type] !== null) {
		const item_services = item[type] ?? {};
		const items = accommodation[type].filter(x => item_services[x.key] !== undefined);
		let results = items.length == 0
			? null
			: items.map(service => {
				let val = null;
				if (service.type == 'bool') {
					if (!item_services[service.key]) return null;
				}
				else if (service.type == 'count') {
					val = item_services[service.key] ?? 0;
					if (val == 0) return null;
				}
				else if (service.type == 'counts') {
					const vals = service.values.filter(val => item_services[service.key][val.key] !== undefined);
					if (vals.length == 0) return null;
					val = vals
						.map(val => {
							if (return_html) {
								return val.title[lang] + ': <span class=\'strong\'>' + item_services[service.key][val.key] + '</span>';
							}
							return val.title[lang] + ': ' + item_services[service.key][val.key];
						})
						.join(', ');
				}
				
				if (return_html) {
					return service.title[lang] + (val === null ? '' : (': <span class=\'strong\'>' + val + '</span>'));
				}
				return service.title[lang] + (val === null ? '' : (': ' + val));
			});
		
		if (results !== null) {
			if (return_html) {
				results = results.map((x, idx) => <div key={'service-' + idx} dangerouslySetInnerHTML={{ __html: x }} />);
				services_info = <div>{ results }</div>;
			}
			else {
				services_info = results.join('\n');
			}
		}
	}
	return services_info;
}
