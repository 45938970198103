import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import {
	Button,
	ButtonGroup,
	Checkbox,
	Tooltip,
} from '@blueprintjs/core';

import {
	saveCashRegisterDocument,
	saveCashRegisterDocumentPaymentType,
	loadCashRegisterDocuments,
} from '../../api/CashRegisters';
import AddCashRegisterDocumentDialog from './AddCashRegisterDocumentDialog';

import { ResultHeader, Result, MultiSelectFilter, DateRangeFilter, TextFilter } from '../code_tables/ResultComponents';

import { enqueueInvoiceOnA4PrinterQueue } from '../../slices/AppSlice';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_checked_ids,
		rowCheckStateChanged,
		allRowsCheckStateChanged,
		openAddEditItemDialog,
		tableItemsFilters,
		originalTableItemsFilters,
		handleFilterChange,
		deleteItem,
		cash_registers,
		users,
		payment_types,
		customers,
		allow_delete_ids,
	} = props;
	
	const users_with_titles = {};
	for (let id_user in users) {
		users_with_titles[id_user] = { id_user, title: users[id_user].name + ' ' + users[id_user].surname };
	}
	
	const payment_types_with_titles = {};
	for (let id_payment_type in payment_types) {
		payment_types_with_titles[id_payment_type] = { id_payment_type, title: payment_types[id_payment_type].title };
	}
	
	const check_count = table_items_sort_ids.filter(id => table_items_checked_ids[id] === true).length;
	const all_checked = table_items_sort_ids.length == check_count;
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table overflow-y-auto sticky-header'>
				<ResultHeader columnIdx={1} title={
					<Checkbox
						checked={check_count > 0}
						indeterminate={!all_checked && check_count > 0}
						onChange={event => allRowsCheckStateChanged(event.target.checked)} />
				} />
				<ResultHeader columnIdx={2} title='Urejanje' />
				<ResultHeader
					columnIdx={3} title='Tip' />
				<ResultHeader
					columnIdx={4} title='Številka' />
				<ResultHeader
					columnIdx={5} title='Št. dokumenta' />
				<ResultHeader
					columnIdx={6} title='Datum' />
				<ResultHeader
					columnIdx={7} title='Šifra' />
				<ResultHeader
					columnIdx={8} title='Naziv stranke' />
				<ResultHeader
					columnIdx={9} title='Blagajna' />
				<ResultHeader
					columnIdx={10} title='Blagajnik' />
				<ResultHeader
					columnIdx={11} title='Način plačila' />
				<ResultHeader
					columnIdx={12} cls='text-right' title='Znesek' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} cls='filters-col' />
				<Result columnIdx={3} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='value'
							title_field    ='title'
							items          ={{ income: { value: 'income', title: 'Prejemek' }, expense: { value: 'expense', title: 'Izdatek' } }}
							filtered_keys  ={tableItemsFilters.types}
							onFilterChange ={values => handleFilterChange('types', values)} />
					</div>
				} />
				<Result columnIdx={4} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.document_number}
						onChange={ event => handleFilterChange('document_number', event) } />
				} />
				<Result columnIdx={5} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.reference}
						onChange={ event => handleFilterChange('reference', event) } />
				} />
				<Result columnIdx={6} cls='filters-col' child={
					<DateRangeFilter
						filter_values={tableItemsFilters.document_date_range}
						original_start_date={originalTableItemsFilters.document_date_range[0]}
						original_end_date={originalTableItemsFilters.document_date_range[1]}
						onFilterChange={values => handleFilterChange('document_date_range', values)} />
				} />
				<Result columnIdx={7} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.customer_internal_code}
						onChange={ event => handleFilterChange('customer_internal_code', event) } />
				} />
				<Result columnIdx={8} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.customer_name}
						onChange={ event => handleFilterChange('customer_name', event) } />
				} />
				<Result columnIdx={9} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_cash_register'
							title_field    ='title'
							items          ={cash_registers}
							filtered_keys  ={tableItemsFilters.id_cash_registers}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('id_cash_registers', values)} />
					</div>
				} />
				<Result columnIdx={10} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_user'
							title_field    ='title'
							items          ={users_with_titles}
							filtered_keys  ={tableItemsFilters.id_users}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('id_users', values)} />
					</div>
				} />
				<Result columnIdx={11} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_payment_type'
							title_field    ='title'
							items          ={payment_types_with_titles}
							filtered_keys  ={tableItemsFilters.id_payment_types}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('id_payment_types', values)} />
					</div>
				} />
				<Result columnIdx={12} cls='filters-col' />
				
				{table_items_sort_ids.map((id, idx) => {
					const item = table_items[id];
					
					const id_payment_types = [];
					let   amount           = 0;
					
					if (item.payment_types !== null && item.payment_types !== undefined) {
						for (let i=0; i<item.payment_types.length; i++) {
							const payment_type = item.payment_types[i];
							
							if (id_payment_types.indexOf(payment_type.id_payment_type) == -1) {
								id_payment_types.push(payment_type.id_payment_type);
							}
							
							amount += payment_type.amount;
						}
					}
					
					const payment_type_titles = id_payment_types.map(id_payment_type => payment_types[id_payment_type].title);
					
					const customer = item.id_customer === null ? null : (customers[item.id_customer] || null);
					
					return <React.Fragment
						key={'result--result-' + id}>
						<Result columnIdx={1} child={
							<Checkbox
								checked={table_items_checked_ids[id] === true}
								onChange={event => rowCheckStateChanged(id, event.target.checked)} />
						} />
						<Result columnIdx={2} child={
							<>
								<Button
									icon='search'
									intent='primary'
									minimal={true}
									small={true}
									onClick={() => openAddEditItemDialog(item)} />
								{allow_delete_ids.indexOf(item.id_cash_register_daily_state) === -1 ? null :
									<Button
										icon='trash'
										intent='danger'
										minimal={true}
										small={true}
										onClick={() => {
											deleteItem(id);
										}} />
								}
							</>
						} />
						<Result columnIdx={3} child={ item.type == 'income' ? 'Prejemek' : 'Izdatek' } />
						<Result columnIdx={4} child={ item.document_number } />
						<Result columnIdx={5} child={ item.reference } />
						<Result columnIdx={6} child={ moment(item.document_date).format('DD. MM. YYYY') } />
						<Result columnIdx={7} child={ customer === null ? '' : customer.internal_code } />
						<Result columnIdx={8} child={ customer === null ? '' : (customer.type == 'natural' ? (customer.surname + ' ' + customer.name) : customer.company_name) } />
						<Result columnIdx={9} child={ cash_registers[item.id_cash_register].title } />
						<Result columnIdx={10} child={ users[item.id_user].name + ' ' + users[item.id_user].surname } />
						<Result columnIdx={11} child={ payment_type_titles.join(', ') } />
						<Result columnIdx={12} cls='text-right font-bold' child={ amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR' } />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results-sum pb-4'>
			<div className='col'>
				Število zapisov: {table_items_sort_ids.length}
			</div>
			<div className='col text-right font-bold'>
				{
					table_items_sort_ids
						.map((id, idx) => {
							const item = table_items[id];
							let   amount = 0;
							
							if (item.payment_types !== null && item.payment_types !== undefined) {
								for (let i=0; i<item.payment_types.length; i++) {
									const payment_type = item.payment_types[i];
									
									amount += payment_type.amount;
								}
							}
							
							return amount;
						})
						.reduce((acc, val) => acc + val, 0)
						.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' EUR'
				}
			</div>
		</div>
	</>;
}

class CashRegisterDocuments extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.openAddEditItemDialog      = this.openAddEditItemDialog     .bind(this);
		this.closeAddEditItemDialog     = this.closeAddEditItemDialog    .bind(this);
		this.updateTableItemsFromProps  = this.updateTableItemsFromProps .bind(this);
		this.refreshTableItemsSort      = this.refreshTableItemsSort     .bind(this);
		this.filterTableItems           = this.filterTableItems          .bind(this);
		this.handleFilterChange         = this.handleFilterChange        .bind(this);
		this.printA4                    = this.printA4                   .bind(this);
		
		const table_items_filters = {
			types:                  [],
			document_number:        '',
			reference:              '',
			document_date_range:    [moment().toDate(), moment().toDate()],
			customer_internal_code: '',
			customer_name:          '',
			id_cash_registers:      props.current_id_cash_register === null || props.current_id_cash_register == 0 ? [] : [ props.current_id_cash_register ],
			id_users:               [],
			id_payment_types:       [],
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'document_number',
			table_items_sort_direction: 'DESC',
		});
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'document_number',
			table_items_sort_direction: 'DESC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			table_items_checked_ids: {},
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_type: '',
			add_edit_item_dialog_item: null,
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	openAddEditItemDialog(item, type) {
		this.setState({
			add_edit_item_dialog_open: true,
			add_edit_item_dialog_type: type,
			add_edit_item_dialog_item: item,
		});
	}
	async closeAddEditItemDialog(item) {
		if (item === null || item === undefined) {
			this.setState({
				add_edit_item_dialog_open: false,
			});
			return;
		}
		
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		await saveCashRegisterDocument(this.props.api_url, item, token);
		
		for (let i=0; i<item.payment_types.length; i++) {
			await saveCashRegisterDocumentPaymentType(
				this.props.api_url,
				item.payment_types[i],
				token
			);
		}
		
		loadCashRegisterDocuments(this.props.api_url, dispatch, token);
		
		this.setState({
			add_edit_item_dialog_open: false,
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.users                      != prevProps.users          ||
			this.props.cash_registers             != prevProps.cash_registers ||
			this.props.cash_register_documents != prevProps.cash_register_documents
		) {
			const state = this.updateTableItemsFromProps(this.props, this.state);
			this.setState(
				state
			);
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const ids = Object.keys(props.cash_register_documents);
		
		let sort_ids = [];
		
		if (state !== undefined && state.table_items_sort_ids !== undefined) {
			for (let i=0; i<state.table_items_sort_ids.length; i++) {
				const id = state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const new_state = {
			invoices: props.invoices,
			table_items: props.cash_register_documents,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
			table_items_sort_field: state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a = '';
			let b = '';
			let dir = direction;
			
			a = state.table_items[a_key][field];
			b = state.table_items[b_key][field];
			
			return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_types                  = filters.types;
		const filters_document_number        = filters.document_number.toUpperCase();
		const filters_reference              = filters.reference      .toUpperCase();
		const filters_document_date_start    = filters.document_date_range[0] === null ? null : moment(filters.document_date_range[0]).format('YYYY-MM-DD');
		const filters_document_date_end      = filters.document_date_range[1] === null ? null : moment(filters.document_date_range[1]).format('YYYY-MM-DD');
		const filters_customer_internal_code = filters.customer_internal_code.toUpperCase();
		const filters_customer_name          = filters.customer_name         .toUpperCase();
		const filters_id_cash_registers      = filters.id_cash_registers;
		const filters_id_users               = filters.id_users;
		const filters_id_payment_types       = filters.id_payment_types;
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_types.length > 0) {
				if (filters_types.indexOf(item.type) == -1) {
					return false;
				}
			}
			
			if (filters_document_number != '' && item.document_number.indexOf(filters_document_number) == -1) {
				return false;
			}
			
			if (filters_reference != '' && item.reference.indexOf(filters_reference) == -1) {
				return false;
			}
			
			if (filters_document_date_start !== null && item.document_date !== null && item.document_date < filters_document_date_start) {
				return false;
			}
			if (filters_document_date_end !== null && item.document_date !== null && item.document_date > filters_document_date_end) {
				return false;
			}
			
			if (filters_customer_internal_code != '' && (item.id_customer === null || this.props.customers[item.id_customer] === undefined || this.props.customers[item.id_customer].internal_code != filters_customer_internal_code)) {
				return false;
			}
			
			if (filters_customer_name != '' && (item.id_customer === null || this.props.customers[item.id_customer] === undefined || (this.props.customers[item.id_customer].surname + ' ' + this.props.customers[item.id_customer].name + ' ' + this.props.customers[item.id_customer].company_name).toUpperCase().indexOf(filters_customer_name) == -1)) {
				return false;
			}
			
			if (filters_id_cash_registers.length > 0) {
				if (item.id_cash_register === null || filters_id_cash_registers.indexOf(item.id_cash_register) == -1) {
					return false;
				}
			}
			
			if (filters_id_users.length > 0) {
				if (item.id_user === null || filters_id_users.indexOf(item.id_user) == -1) {
					return false;
				}
			}
			
			const id_payment_types = item.payment_types === null || item.payment_types === undefined ?
				[] : item.payment_types.map(x => x.id_payment_type);
			if (filters_id_payment_types.length > 0) {
				let found = false;
				for (let i=0; i<filters_id_payment_types.length; i++) {
					if (id_payment_types.indexOf(filters_id_payment_types[i]) > -1) {
						found = true;
						break;
					}
				}
				
				if (!found) {
					return false;
				}
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			types:                  this.state.table_items_filters.types,
			document_number:        this.state.table_items_filters.document_number,
			reference:              this.state.table_items_filters.reference,
			document_date_range:    this.state.table_items_filters.document_date_range,
			customer_internal_code: this.state.table_items_filters.customer_internal_code,
			customer_name:          this.state.table_items_filters.customer_name,
			id_cash_registers:      this.state.table_items_filters.id_cash_registers,
			id_users:               this.state.table_items_filters.id_users,
			id_payment_types:       this.state.table_items_filters.id_payment_types,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	printA4(lang) {
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		const ids = check_count == 0 ? this.state.table_items_sort_ids :
			this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
		
		for (let i=0; i<ids.length; i++) {
			const item = this.state.table_items[ids[i]];
			
			this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'cash-register-document', lang, item }));
		}
	}
	
	render() {
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		return <>
			{!this.state.add_edit_item_dialog_open ? null :
				<AddCashRegisterDocumentDialog
					closeDialog={this.closeAddEditItemDialog}
					type            ={this.state.add_edit_item_dialog_type}
					item            ={this.state.add_edit_item_dialog_item}
					id_cash_register={this.props.current_id_cash_register}
					user            ={this.props.user} />
			}
			
			<div className='flex flex-col flex-grow base-list cash-register-documents-list'>
				<div className='pl-4 pt-4'>
					<ButtonGroup className='align-middle'>
						<Button
							intent='success'
							icon='plus'
							disabled={this.props.current_id_cash_register == 0}
							onClick={() => this.openAddEditItemDialog(null, 'income')}>
							Nov prejemek
						</Button>
						<Button
							intent='success'
							icon='plus'
							disabled={this.props.current_id_cash_register == 0}
							onClick={() => this.openAddEditItemDialog(null, 'expense')}>
							Nov izdatek
						</Button>
					</ButtonGroup>
					<ButtonGroup className='ml-2 align-middle'>
						<Button
							intent='primary'
							icon='print'
							disabled={check_count == 0}
							onClick={() => this.printA4('sl')}>
							Natisni
						</Button>
					</ButtonGroup>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow' style={{ flexBasis: '0' }}>
						<Results
							table_items              ={this.state.table_items}
							table_items_sort_ids     ={this.state.table_items_sort_ids}
							table_items_checked_ids  ={this.state.table_items_checked_ids}
							rowCheckStateChanged     ={(id, state) => {
								let table_items_checked_ids = this.state.table_items_checked_ids;
								
								if (state === false) {
									delete table_items_checked_ids[id];
								}
								else {
									table_items_checked_ids[id] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							allRowsCheckStateChanged  ={state => {
								const table_items_checked_ids = {};
								for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
									table_items_checked_ids[this.state.table_items_sort_ids[i]] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							openAddEditItemDialog    ={this.openAddEditItemDialog}
							tableItemsFilters        ={this.state.table_items_filters}
							originalTableItemsFilters={this.state.original_table_items_filters}
							handleFilterChange       ={this.handleFilterChange}
							deleteItem               ={this.requestDeleteItem}
							cash_registers           ={this.props.cash_registers}
							users                    ={this.props.users}
							payment_types            ={this.props.payment_types}
							customers                ={this.props.customers}
							allow_delete_ids         ={[]} />
					</div>
				</div>
			</div>
		</>;
	}
}
CashRegisterDocuments.propTypes = {
};

function mapStateToProps(state) {
	return {
		api_url:                    state.CashRegisterSlice.api_url,
		token:                      state.UserSlice.token,
		user:                       state.UserSlice.user,
		users:                      state.UserSlice.users,
		customers:                  state.CodeTablesSlice.customers,
		payment_types:              state.CodeTablesSlice.payment_types,
		current_id_cash_register:   state.SettingsSlice.current_id_cash_register,
		cash_registers:             state.CashRegisterSlice.cash_registers,
		cash_register_documents:    state.CashRegisterSlice.cash_register_documents,
	};
}

export default connect(mapStateToProps)(CashRegisterDocuments);
