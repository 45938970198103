import React from 'react';

import {
	Icon,
	Tooltip,
	Position,
} from '@blueprintjs/core';

function TextWithTooltip(props) {
	return <div className={'text-with-tooltip ' + (props.cls || '')} style={ props.inline === true ? { display: 'inline' } : {} }>
		<span className={'mr-1 ' + (props.className || '')}>{props.label}</span>
		{props.tooltip === undefined || props.tooltip === null || props.tooltip.length == 0 ? null :
			<Tooltip content={props.tooltip} position={Position.RIGHT} openOnTargetFocus={false}>
				<Icon
					icon='info-sign'
					iconSize={12} />
			</Tooltip>
		}
	</div>;
}

export default TextWithTooltip;
