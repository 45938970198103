import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Button,
} from '@blueprintjs/core';

import LocalStorageHelper from '../helpers/LocalStorageHelper';

import { ResultHeader, Result } from './code_tables/ResultComponents';

function Results(props) {
	const {
		log,
	} = props;
	
	return <div className='results mt-4 mb-4'>
		<div className='results-table'>
			<ResultHeader columnIdx={1} title='Čas' />
			<ResultHeader columnIdx={2} title='AssistPRO latency' />
			<ResultHeader columnIdx={3} title='AssistPRO status' />
			<ResultHeader columnIdx={4} title='AssistPRO napaka' />
			<ResultHeader columnIdx={5} title='Drug strežnik latency' />
			<ResultHeader columnIdx={6} title='Drug strežnik status' />
			<ResultHeader columnIdx={7} title='Drug strežnik napaka' />
		</div>
		
		<div className='results-table'>
			{log.map(item => {
				return <React.Fragment key={item.ts}>
					<Result columnIdx={1} child={
						item.ts
					} />
					<Result columnIdx={2} child={
						item.assistpro_latency + ' ms'
					} />
					<Result columnIdx={3} child={
						item.assistpro_status
					} />
					<Result columnIdx={4} child={
						item.assistpro_error_message
					} />
					<Result columnIdx={5} child={
						item.other_latency + ' ms'
					} />
					<Result columnIdx={6} child={
						item.other_status
					} />
					<Result columnIdx={7} child={
						item.other_error_message
					} />
				</React.Fragment>;
			})}
		</div>
	</div>;
}

class HealthLog extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		let log_str = LocalStorageHelper.GetValue('health_log');
		if (log_str === null || log_str === undefined) log_str = '[]';
		
		let log = JSON.parse(log_str);
		log = log.slice(-5000);
		log.reverse();
		
		return <>
			<div className='flex flex-col flex-grow health-log-list'>
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results log={log} />
					</div>
				</div>
			</div>
		</>;
	}
}
HealthLog.propTypes = {
};

function mapStateToProps(state) {
	return {};
}

export default connect(mapStateToProps)(HealthLog);
