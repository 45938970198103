import { createSlice } from '@reduxjs/toolkit'

function addItemToState(state_key_name, key_name, payload, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	const item  = payload.item;
	const token = payload.token;
	
	if (item[key_name] == -1) {
		item[key_name] = uuid.v4();
	}
	
	state[state_key_name][item[key_name]] = item;
	
	if (payload.save_to_api === true) {
		saveCodeTableItem(state.api_url, state_key_name, item, token);
	}
}
function addItemsToState(state_key_name, key_name, items, state, clear) {
	clear = clear || false;
	if (state[state_key_name] === null || clear) state[state_key_name] = {};
	
	for (let i=0; i<items.length; i++) {
		const item = items[i];
		state[state_key_name][items[i][key_name]] = items[i];
	}
}

const UserSlice = createSlice({
	name: 'user',
	initialState: {}, // defined in AppContainer.js
	reducers: {
		setUser: (state, action) => {
			state.token = action.payload.token;
			state.user  = action.payload.user_info;
		},
		addUsers: (state, action) => addItemsToState('users', 'id_user', action.payload, state),
		addUserWorkType:   (state, action) =>
			addItemToState ('user_work_types', 'id_user_work_type', action.payload, state),
		addUserWorkTypes: (state, action) =>
			addItemsToState('user_work_types', 'id_user_work_type', action.payload, state, true),
		addUserDailyLogbookActivity:   (state, action) =>
			addItemToState ('user_daily_logbook_activities', 'id_user_daily_logbook_activity', action.payload, state),
		addUserDailyLogbookActivities: (state, action) =>
			addItemsToState('user_daily_logbook_activities', 'id_user_daily_logbook_activity', action.payload, state, true),
	}
});

export const {
	setUser,
	addUsers,
	addUserWorkType,
	addUserWorkTypes,
	addUserDailyLogbookActivity,
	addUserDailyLogbookActivities,
} = UserSlice.actions;
export default UserSlice.reducer;
