import {
	addGuestBookItems,
	addMonthlyGuestBookReports,
} from '../slices/GuestSlice';
import {
	setUser,
} from '../slices/UserSlice';

export async function loadGuestBookItems(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Guests/v1/guests', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addGuestBookItems(items));
	}
}

export async function loadMonthlyGuestBookReports(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Guests/v1/monthly-guest-book-reports', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addMonthlyGuestBookReports(items));
	}
}

export async function saveMonthlyGuestBookReport(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Guests/v1/save-monthly-guest-book-report', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}

export async function sendReservationCustomersToETourism(api_url, token) {
	let response = await fetch(api_url + 'Guests/v1/send-reservation-customers-to-e-tourism', {
		method:  'post',
		body:    new FormData(),
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
}
