const InvoiceCreator = {
	CreateInvoiceFromInitialData: initialData => {
		return Object.assign({
			id_invoice:         null,
			reverse_invoice:    false,
			draft:              true,
			invoice_number:     '',
			invoice_number_internal: '',
			id_customer:        null,
			invoice_date:       null,
			invoice_type:       null,
			note:               '',
			payment_date:       null,
			reference:          '',
			reservation:        '',
			service_date_end:   null,
			service_date_start: null,
			invoice_amount:     0,
			id_cash_register:   null,
			advance_payment_amount: 0,
			credit_note_type:   null,
			paid:               false,
			items:              [],
			payments:           [],
		}, initialData);
	},
	CreateInvoiceItemFromInitialData: initialData => {
		return Object.assign({
			id_invoice_item:                         null,
			description:                             '',
			discount:                                0,
			id_item:                                 null,
			id_item_price_list:                      null,
			id_tax_rate:                             null,
			item_measuring_unit_code:                '',
			item_measuring_unit_decimal_digit_count: 0,
			price:                                   0,
			quantity:                                0,
			tax_rate:                                0,
			approved_percent:                        100,
			item_type:                               null,
			item_ean:                                '',
			item_title:                              '',
			ord:                                     0,
			taxable:                                 'taxable',
			item_internal_code:                      '',
		}, initialData);
	},
	CreateInvoicePaymentFromInitialData: initialData => {
		return Object.assign({
			id_invoice_payment: null,
			id_payment_type:    null,
			amount:             0,
			payment_date:       null,
		}, initialData);
	},
	Duplicate: item => InvoiceCreator.deepCopy(item),
	
	/**
	 * Deep copy an object (make copies of all its object properties, sub-properties, etc.)
	 * An improved version of http://keithdevens.com/weblog/archive/2007/Jun/07/javascript.clone
	 * that doesn't break if the constructor has required parameters
	 * 
	 * It also borrows some code from http://stackoverflow.com/a/11621004/560114
	 */ 
	deepCopy: (src, /* INTERNAL */ _visited, _copiesVisited) => {
		if (src === null || typeof(src) !== 'object') {
			return src;
		}

		//Honor native/custom clone methods
		if (typeof src.clone == 'function') {
			return src.clone(true);
		}

		//Special cases:
		//Date
		if (src instanceof Date) {
			return new Date(src.getTime());
		}
		//RegExp
		if (src instanceof RegExp) {
			return new RegExp(src);
		}
		//DOM Element
		if (src.nodeType && typeof src.cloneNode == 'function') {
			return src.cloneNode(true);
		}

		// Initialize the visited objects arrays if needed.
		// This is used to detect cyclic references.
		if (_visited === undefined) {
			_visited = [];
			_copiesVisited = [];
		}

		// Check if this object has already been visited
		var i, len = _visited.length;
		for (i = 0; i < len; i++) {
			// If so, get the copy we already made
			if (src === _visited[i]) {
				return _copiesVisited[i];
			}
		}

		//Array
		if (Object.prototype.toString.call(src) == '[object Array]') {
			//[].slice() by itself would soft clone
			var ret = src.slice();

			//add it to the visited array
			_visited.push(src);
			_copiesVisited.push(ret);

			var i = ret.length;
			while (i--) {
				ret[i] = InvoiceCreator.deepCopy(ret[i], _visited, _copiesVisited);
			}
			return ret;
		}

		//If we've reached here, we have a regular object

		//make sure the returned object has the same prototype as the original
		var proto = (Object.getPrototypeOf ? Object.getPrototypeOf(src): src.__proto__);
		if (!proto) {
			proto = src.constructor.prototype; //this line would probably only be reached by very old browsers 
		}
		var dest = Object.create(proto);

		//add this object to the visited array
		_visited.push(src);
		_copiesVisited.push(dest);

		for (var key in src) {
			//Note: this does NOT preserve ES5 property attributes like 'writable', 'enumerable', etc.
			//For an example of how this could be modified to do so, see the singleMixin() function
			dest[key] = InvoiceCreator.deepCopy(src[key], _visited, _copiesVisited);
		}
		return dest;
	},
};

export default InvoiceCreator;
