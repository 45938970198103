import React, { useFfect } from 'react';

import moment from 'moment';

import PrintHelper   from '../../helpers/Print';
import InvoiceHelper from '../../helpers/Invoice';
import T             from '../../helpers/Translation';

import {
	CompanyDetails,
	Logo,
	CustomerDetails,
	Footer,
} from './Common';

function formatNumber(val, decimal_digit_count) {
	decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
	
	let rounded_val = Math.properRound(parseFloat(val), decimal_digit_count);
	
	return rounded_val
		.toLocaleString(
			undefined,
			{
				minimumFractionDigits: decimal_digit_count,
				maximumFractionDigits: decimal_digit_count,
			}
		);
}

function MainInvoiceDetails(props) {
	// prepare data
	let place = null;
	if (props.business_premise !== null) {
		if (props.business_premise.type == '1') {
			place = props.business_premise.town;
		}
	}
	else {
		place = props.company_data.subject_city;
	}
	
	let timestamp_format = T.Print.MainInvoiceDetails.DateFormat[props.lang];
	let invoice_date     = props.item.invoice_date;
	if ([ 'cash-invoice', 'cash-prepayment-invoice' ].indexOf(props.item.invoice_type) != -1) {
		timestamp_format = T.Print.MainInvoiceDetails.TimestampFormat[props.lang];
		invoice_date     = props.item.issued;
	}
	
	const timestamp = invoice_date === null ? '' : moment(invoice_date).format(timestamp_format);
	
	let service_date_start = moment(props.item.service_date_start);
	let service_date_end   = moment(props.item.service_date_end);
	let service_date       = '';
	if (props.item.service_date_start == props.item.service_date_end) {
		service_date = service_date_start.format(T.Print.MainInvoiceDetails.DateFormat[props.lang]);
	}
	else {
		service_date = service_date_start.format(T.Print.MainInvoiceDetails.ShortDateFormat[props.lang]) + ' - ' + service_date_end.format(T.Print.MainInvoiceDetails.ShortDateFormat[props.lang]);
	}
	
	const title = T.Print.MainInvoiceDetails.InvoiceTitles[props.item.invoice_type];
	
	// prepare service date fragment
	let service_date_fragment = null;
	if ([ 'cash-invoice', 'invoice', 'cash-prepayment-invoice' ].includes(props.item.invoice_type)) {
		service_date_fragment = <>
			<div>{T.Print.MainInvoiceDetails.ServiceDate[props.lang]}</div>
			<div>{service_date}</div>
		</>;
	}
	else if ([ 'prepayment-invoice', 'offer' ].includes(props.item.invoice_type)) {
		service_date_fragment = <>
			<div>{T.Print.MainInvoiceDetails.EstimatedServiceDate[props.lang]}</div>
			<div>{service_date}</div>
		</>;
	}
	
	// prepare reference fragment
	let reference_fragment = null;
	if ([ 'offer' ].includes(props.item.invoice_type)) {
		reference_fragment = <>
			<div>{T.Print.MainInvoiceDetails.Reference[props.lang]}</div>
			<div>
				<div>{
					props.item.invoice_number === null ? 'osnutek' : ('SI00 ' + props.item.invoice_number.replace('Po', ''))
				}</div>
				{props.item.reference.length == 0 ? null :
					<div>{props.item.reference}</div>
				}
			</div>
		</>;
	}
	else if ([
		'cash-invoice', 'cash-prepayment-invoice', 'invoice', 'prepayment-invoice',
		'stock-acquisition', 'stock-delivery-document'
	].includes(props.item.invoice_type)) {
		reference_fragment = <>
			<div>{T.Print.MainInvoiceDetails.Reference[props.lang]}</div>
			<div>{props.item.reference}</div>
		</>;
	}
	
	// prepare payment date fragment
	let payment_date_fragment = null;
	if (
		props.item.payment_date !== null &&
		[ 'invoice', 'prepayment-invoice', 'offer' ].includes(props.item.invoice_type)
	) {
		let fragment_title = T.Print.MainInvoiceDetails.PaymentDate[props.lang];
		let fragment_value = moment(props.item.payment_date).format(T.Print.MainInvoiceDetails.DateFormat[props.lang]);
	
		if (props.item.invoice_type == 'prepayment-invoice') {
			fragment_title = T.Print.MainInvoiceDetails.DateOfPayment[props.lang];
		}
		else if (props.item.invoice_type == 'offer') {
			fragment_title = T.Print.MainInvoiceDetails.ValidityDate[props.lang];
		}
		else if (props.item.invoice_type == 'invoice') {
			if (props.item.paid === true) {
				fragment_value = <div>
					<div>{ T.Print.MainInvoiceDetails.Paid[props.lang] + ' ' + fragment_value }</div>
				</div>;
			}
		}
		
		payment_date_fragment = <>
			<div className='emphasized'>{fragment_title}</div>
			<div className='emphasized'>{fragment_value}</div>
		</>;
	}
	
	// prepare reservation fragment
	let reservation_fragment = null;
	if (
		props.item.reservation !== null &&
		props.item.reservation.length > 0 &&
		[ 'cash-invoice', 'cash-prepayment-invoice', 'invoice', 'prepayment-invoice', 'offer' ].includes(props.item.invoice_type)
	) {
		reservation_fragment = <>
			<div>{T.Print.MainInvoiceDetails.Reservation[props.lang]}</div>
			<div>{props.item.reservation}</div>
		</>;
	}
	
	// prepare delivery note fragment
	let delivery_note_fragment = null;
	if (false) { //TODO enable when we know what to display here
		if ([ 'invoice', 'stock-acquisition' ].includes(props.item.invoice_type)) {
			delivery_note_fragment = <>
				<div>{T.Print.MainInvoiceDetails.DeliveryNote[props.lang]}</div>
				<div>.. dobavnica ..</div>
			</>;
		}
	}
	
	// prepare user fragment
	let user_fragment = null;
	const user_titles = {
		'stock-acquisition':        T.Print.MainInvoiceDetails.UserReceivedBy [props.lang],
		'stock-delivery-document':  T.Print.MainInvoiceDetails.UserIssuedBy   [props.lang],
		'warehouse-transfer-order': T.Print.MainInvoiceDetails.UserFulfilledBy[props.lang],
	};
	if (user_titles[props.item.invoice_type] !== undefined) {
		let invoice_user = null;
		for (let id_user in props.users) {
			if (props.users[id_user].username == props.item.username) {
				invoice_user = props.users[id_user];
				break;
			}
		}
		
		if (invoice_user !== null) {
			user_fragment = <>
				<div>{ user_titles[props.item.invoice_type] }</div>
				<div>{ (invoice_user.name + ' ' + (
					invoice_user.surname.length == 0 ? '' : (invoice_user.surname.substring(0, 1) + '.')
				)).trim() }</div>
			</>;
		}
	}
	
	return <div className='main-invoice-details'>
		<div style={{ gridColumn: '1 / 3' }} className='font-large-6 deemphasized mb-05-line'>
			{ title === undefined ? '' : title[props.lang] }
		</div>
		
		<div className='emphasized mb-05-line font-large-1'>{T.Print.MainInvoiceDetails.InvoiceNumber[props.lang]}</div>
		<div className='emphasized mb-05-line font-large-1'>{props.item.invoice_number}</div>
		
		<div>{T.Print.MainInvoiceDetails.Place[props.lang]}</div>
		<div>{place}</div>
		
		<div>{T.Print.MainInvoiceDetails.Timestamp[props.lang]}</div>
		<div>{timestamp}</div>
		
		{ service_date_fragment }
		
		{ reference_fragment }
		
		{true ? null :
			<>
				<div>{T.Print.MainInvoiceDetails.Refer[props.lang]}</div>
				<div>.. sklic za plačilo, se generira avtomatično ..</div>
			</>
		}
		
		{ delivery_note_fragment }
		
		{ payment_date_fragment }
		
		{ reservation_fragment}
		
		{ user_fragment }
	</div>;
}
function InvoiceItemsTable(props) {
	let items = [];
	
	items.push([
		T.Print.InvoiceItemsTable.ItemInternalCode     [props.lang],
		T.Print.InvoiceItemsTable.ItemTitle            [props.lang],
		T.Print.InvoiceItemsTable.Quantity             [props.lang],
		T.Print.InvoiceItemsTable.ItemMeasuringUnitCode[props.lang],
		T.Print.InvoiceItemsTable.Price                [props.lang],
		T.Print.InvoiceItemsTable.Discount             [props.lang],
		T.Print.InvoiceItemsTable.AmountWithoutVAT     [props.lang],
		T.Print.InvoiceItemsTable.VAT                  [props.lang],
		T.Print.InvoiceItemsTable.AmountWithVAT        [props.lang],
	]);
	
	const item_items = [...props.item.items];
	item_items.sort((a, b) => {
		if      (a.ord < b.ord) return -1;
		else if (a.ord > b.ord) return  1;
		return 0;
	});
	
	for (let i=0; i<item_items.sort().length; i++) {
		const invoice_item = item_items[i];
		
		items.push([
			invoice_item.item_internal_code,
			invoice_item.item_title,
			formatNumber(invoice_item.quantity, invoice_item.item_measuring_unit_decimal_digit_count),
			invoice_item.item_measuring_unit_code,
			formatNumber(invoice_item.price,    2),
			formatNumber(invoice_item.discount, 2),
			formatNumber(invoice_item.quantity * invoice_item.price * (1 - invoice_item.discount / 100) / (1 + invoice_item.tax_rate / 100), 2),
			formatNumber(invoice_item.tax_rate, 1),
			formatNumber(invoice_item.quantity * invoice_item.price * (1 - invoice_item.discount / 100), 2),
		]);
		if (invoice_item.description !== null && invoice_item.description.length > 0) {
			items.push([
				invoice_item.description,
			]);
		}
	}
	
	return <div className='invoice-items-table'>
		{items.map((item, idx) => {
			const cls = idx == 0 ? 'cell header-cell' : 'cell';
			
			if (item.length == 1) {
				return <React.Fragment key={'item-' + idx}>
					<div className={cls}></div>
					<div className={cls + ' second-description-cell font-small'}>
						{item[0]}
					</div>
				</React.Fragment>;
			}
			
			return <React.Fragment key={'item-' + idx}>
				<div className={cls + ' wrap'}      >{item[0]}</div>
				<div className={cls + ' wrap'}      >{item[1]}</div>
				<div className={cls + ' text-right'}>{item[2]}</div>
				<div className={cls}                >{item[3]}</div>
				<div className={cls + ' text-right'}>{item[4]}</div>
				<div className={cls + ' text-right'}>{item[5]}</div>
				<div className={cls + ' text-right'}>{item[6]}</div>
				<div className={cls + ' text-right'}>{item[7]}</div>
				<div className={cls + ' text-right'}>{item[8]}</div>
			</React.Fragment>
		})}
	</div>;
}
function TaxesTable(props) {
	const {
		invoice_items_by_tax_rate,
		invoice_items_taxes_sums,
	} = InvoiceHelper.groupInvoiceItemsByTaxes(
		props.item,
		props.id_advance_invoice_consumption_by_id_consumer_invoices,
		props.advance_invoice_consumptions
	);
	
	let taxes = [];
	if (Object.keys(invoice_items_by_tax_rate).length > 0) {
		taxes.push([
			T.Print.TaxesTable.TaxRate  [props.lang],
			T.Print.TaxesTable.TaxBase  [props.lang],
			T.Print.TaxesTable.TaxAmount[props.lang],
			T.Print.TaxesTable.Total    [props.lang],
		]);
		
		let adjusted_tax_sums = [ 0, 0, 0 ];
		const invoice_items_by_tax_rate_keys = Object.keys(invoice_items_by_tax_rate).sort((a, b) => {
			if (parseFloat(a) < parseFloat(b)) return -1;
			if (parseFloat(a) > parseFloat(b)) return  1;
			return 0;
		});
		for (let i=0; i<invoice_items_by_tax_rate_keys.length; i++) {
			const key          = invoice_items_by_tax_rate_keys[i];
			const tax_rate     = parseFloat(invoice_items_by_tax_rate[key].tax_rate);
			const price        = parseFloat(invoice_items_by_tax_rate[key].partial_price_discounted_without_tax);
			const tax_amount   = parseFloat(invoice_items_by_tax_rate[key].partial_price_tax_amount);
			const total_amount = parseFloat(invoice_items_by_tax_rate[key].partial_price_discounted);
			
			adjusted_tax_sums[0] += price;
			adjusted_tax_sums[1] += tax_amount;
			adjusted_tax_sums[2] += total_amount;
			
			taxes.push([
				key == -2 ? T.Print.TaxesTable.Nontaxable[props.lang] : formatNumber(tax_rate, 1) + '%',
				formatNumber(price,        2),
				formatNumber(tax_amount,   2),
				formatNumber(total_amount, 2),
			]);
		}
		
		taxes.push([
			'',
			formatNumber(adjusted_tax_sums[0], 2),
			formatNumber(adjusted_tax_sums[1], 2),
			formatNumber(adjusted_tax_sums[2], 2),
		]);
	}
	
	return <div className='taxes-table'>
		{taxes.map((item, idx) => {
			let cls = idx == 0 ? 'cell text-right header-cell' : 'cell text-right';
			if (idx == (taxes.length - 1)) {
				cls += ' top-border';
			}
			
			return <React.Fragment key={'tax-' + idx}>
				<div className={cls}>{item[0]}</div>
				<div className={cls}>{item[1]}</div>
				<div className={cls}>{item[2]}</div>
				<div className={cls}>{item[3]}</div>
			</React.Fragment>;
		})}
	</div>;
}
function InvoiceSums(props) {
	const {
		invoice_items_by_tax_rate,
		invoice_items_taxes_sums,
	} = InvoiceHelper.groupInvoiceItemsByTaxes(
		props.item,
		props.id_advance_invoice_consumption_by_id_consumer_invoices,
		props.advance_invoice_consumptions
	);
	
	let print_items = [];
	
	if (invoice_items_taxes_sums[4] != 0) {
		print_items = print_items.concat([
			[ T.Print.InvoiceSums.AmountWithoutVAT[props.lang], formatNumber( invoice_items_taxes_sums[3], 2), false ],
			[ T.Print.InvoiceSums.DiscountAmount[props.lang],   formatNumber(-invoice_items_taxes_sums[4], 2), false ],
		]);
	}
	
	print_items = print_items.concat([
		[ T.Print.InvoiceSums.TaxBase[props.lang],   formatNumber(invoice_items_taxes_sums[0], 2), false ],
		[ T.Print.InvoiceSums.TaxAmount[props.lang], formatNumber(invoice_items_taxes_sums[1], 2), false ],
		[ T.Print.InvoiceSums.Total[props.lang],     formatNumber(invoice_items_taxes_sums[2], 2), true  ],
		...([ 'cash-prepayment-invoice', 'prepayment-invoice', 'invoice' ].indexOf(props.item.invoice_type) == -1 || props.item.advance_payment_amount == 0 ? [] : [
			[ T.Print.InvoiceSums.AdvancePaymentAmount[props.lang], formatNumber(props.item.advance_payment_amount, 2), false ],
			(props.item.invoice_type != 'invoice' ? null :
				[
					(props.id_advance_invoice_consumption_by_id_consumer_invoices[props.item.id_invoice] ?? []).map(
						x => props.invoices[props.advance_invoice_consumptions[x].advance_id_invoice].invoice_number
					).join(', '),
					false
				]
			),
			[ T.Print.InvoiceSums.RemainingAmount[props.lang], formatNumber(invoice_items_taxes_sums[2] - props.item.advance_payment_amount, 2), true ],
		]),
	]);
	
	return <div className='invoice-sums'>
		{print_items.map((item, idx) => {
			if (item === null) return null;
			
			let cls = 'text-right';
			
			if (item.length == 2) {
				cls += ' span-2';
				
				if (item[1]) {
					cls += ' emphasized top-border';
				}
				
				return <React.Fragment key={'sum-' + idx}>
					<div className={cls}>{item[0]}</div>
				</React.Fragment>;
			}
			
			if (item[2]) {
				cls += ' emphasized top-border';
			}
			
			return <React.Fragment key={'sum-' + idx}>
				<div className={cls}>{item[0]}</div>
				<div className={cls}>{item[1]}</div>
			</React.Fragment>;
		})}
	</div>;
}
function PaymentTypesTable(props) {
	const {
		payment_types,
		id_advance_invoice_consumption_by_id_consumer_invoices,
		invoices,
		advance_invoice_consumptions,
	} = props;
	
	const payment_type_items = [];
	
	let payment_types_sums = {};
	for (let i=0; i<props.item.payments.length; i++) {
		const invoice_payment = props.item.payments[i];
		
		const price = parseFloat(invoice_payment.amount);
		
		let key = invoice_payment.id_payment_type;
		if (payment_types_sums[key] === undefined) {
			payment_types_sums[key] = { price: 0 };
		}
		payment_types_sums[key].price += price;
	}
	
	for (let key in payment_types_sums) {
		const price        = payment_types_sums[key].price;
		const payment_type = payment_types[key];
		
		payment_type_items.push([
			payment_type.title,
			formatNumber(price, 2),
		]);
	}
	
	if (props.item.invoice_type != 'prepayment-invoice' && props.item.invoice_type != 'cash-prepayment-invoice' && props.item.advance_payment_amount != 0) {
		payment_type_items.push([
			T.Print.PaymentTypesTable.Advance[props.lang],
			formatNumber(props.item.advance_payment_amount, 2),
		]);
		
		const advance_invoices = id_advance_invoice_consumption_by_id_consumer_invoices[props.item.id_invoice] ?? [];
		const advance_invoice_numbers = advance_invoices.map(
			x => invoices[advance_invoice_consumptions[x].advance_id_invoice].invoice_number
		);
		payment_type_items.push([ advance_invoice_numbers.join(', ') ]);
	}
	
	return <div className='payment-types-table'>
		<div className='cell double-cell header-cell'>{T.Print.PaymentTypesTable.PaymentType[props.lang]}</div>
		{payment_type_items.map((item, idx) => {
			return <React.Fragment key={'payment-type-' + idx}>
				<div className={item.length < 2 ? 'cell double-cell' : 'cell'}>{item[0]}</div>
				{item.length < 2 ? null : <div className='cell text-right'>{item[1]}</div>}
			</React.Fragment>;
		})}
	</div>;
}
function AdditionalInvoiceDetails(props) {
	const {
		users,
		invoice_fiscal_verifications,
	} = props;
	
	let invoice_user = null;
	for (let id_user in users) {
		if (users[id_user].username == props.item.username) {
			invoice_user = users[id_user];
			break;
		}
	}
	
	const invoice_fiscal_verification = props.item.id_invoice_fiscal_verification === null ? null :
		(invoice_fiscal_verifications[props.item.id_invoice_fiscal_verification] || null);
	//TODO load invoice fiscal verification if it's not loaded yet
	
	let qr_value = null;
	if (invoice_fiscal_verification !== null && invoice_fiscal_verification.signed_zoi !== null) {
		qr_value = (
			PrintHelper.BigNumberHexToDecimal(invoice_fiscal_verification.signed_zoi).padStart(39, '0') +
			invoice_fiscal_verification.tax_number +
			moment(props.item.issued).format('YYYYMMDDHHmmss')
		);
		qr_value += PrintHelper.CalculateModulo10(qr_value);
	}
	
	return <div className='additional-invoice-details'>
		<div id='fiscal-verification-qr-code' data-contents={qr_value}></div>
		<div className='additional-invoice-details-table'>
			<div>{T.Print.AdditionalInvoiceDetails.User[props.lang]}</div>
			<div>{(invoice_user === null ? '' : (invoice_user.name + ' ' + (
				invoice_user.surname.length == 0 ? '' : (invoice_user.surname.substring(0, 1) + '.')
			)).trim())}</div>
			<div>{T.Print.AdditionalInvoiceDetails.InvoiceNumberInternal[props.lang]}</div>
			<div>{props.item.invoice_number_internal}</div>
			<div>{T.Print.AdditionalInvoiceDetails.ZOI[props.lang]}</div>
			<div>{(invoice_fiscal_verification === null ? '' : (invoice_fiscal_verification.signed_zoi ?? ''))}</div>
			<div>{T.Print.AdditionalInvoiceDetails.EOR[props.lang]}</div>
			<div>{(invoice_fiscal_verification === null ? '' : (invoice_fiscal_verification.eor        ?? ''))}</div>
			
			{props.item.copy_count == 0 ? null :
				<>
					<div>{T.Print.AdditionalInvoiceDetails.CopyCount[props.lang]}</div>
					<div>{props.item.copy_count}</div>
				</>
			}
		</div>
	</div>;
}
function AdditionalDescription(props) {
	let tt_item_exists = false;
	for (let i=0; i<props.item.items.length; i++) {
		if (props.item.items[i].item_type == 'tourist_tax') {
			tt_item_exists = true;
			break;
		}
	}
	
	let print_final_text = props.settings.print_final_text;
	if (props.lang == 'en') {
		print_final_text = props.settings.print_final_text_en;
	}
	else if (props.lang == 'de') {
		print_final_text = props.settings.print_final_text_de;
	}
	
	print_final_text = print_final_text || '';
	print_final_text = print_final_text
		.replaceAll('<right>', '<div style="text-align: right;">')
		.replaceAll('</right>', '</div>');
	
	return <div className='additional-description'>
		{props.item.note === null || props.item.note.length == 0 ? null :
			<div className='mb-1-line'>{T.Print.AdditionalDescription.Note[props.lang] + ' ' + props.item.note}</div>
		}
		
		{props.lang != 'sl' || props.settings.subject_vat_registered !== false ? null :
			<div className='mb-1-line'>{props.settings.subject_vat_registered_reason}</div>
		}
		
		{!tt_item_exists || props.settings.tourist_tax_text === undefined || props.settings.tourist_tax_text.length == 0 ? null :
			<div className='mb-1-line'>{props.settings.tourist_tax_text}</div>
		}
		
		{print_final_text === undefined || print_final_text.length == 0 ? null :
			<div className='mb-1-line'>
				<div dangerouslySetInnerHTML={{ __html: print_final_text }}></div>
			</div>
		}
	</div>;
}

export default function Invoice(props) {
	const {
		company_data,
		item,
		business_premise,
		payment_types,
		id_advance_invoice_consumption_by_id_consumer_invoices,
		invoices,
		advance_invoice_consumptions,
		users,
		invoice_fiscal_verifications,
		customer_country,
		settings,
		lang,
	} = props;
	
	let cls = 'print-document print-invoice';
	if (settings.logo_position == 'left') {
		cls += ' reverse-logo';
	}
	else if (settings.logo_position == 'hidden') {
		cls += ' hide-logo';
	}
	
	return <div className={cls}>
		<div className='print-invoice-main'>
			<CompanyDetails     lang={lang} company_data={company_data} />
			<Logo               lang={lang} company_data={company_data} />
			<CustomerDetails    lang={lang} item={item} customer_country={customer_country} />
			<MainInvoiceDetails lang={lang} item={item} company_data={company_data} business_premise={business_premise} users={users} />
			<InvoiceItemsTable  lang={lang} item={item} />
			<TaxesTable
				lang={lang}
				item=                                                  {item}
				id_advance_invoice_consumption_by_id_consumer_invoices={id_advance_invoice_consumption_by_id_consumer_invoices}
				advance_invoice_consumptions=                          {advance_invoice_consumptions} />
			<InvoiceSums
				lang={lang}
				item=                                                  {item}
				id_advance_invoice_consumption_by_id_consumer_invoices={id_advance_invoice_consumption_by_id_consumer_invoices}
				advance_invoice_consumptions=                          {advance_invoice_consumptions}
				invoices=                                              {invoices} />
			{[ 'cash-invoice', 'cash-prepayment-invoice' ].indexOf(item.invoice_type) == -1 ? null :
				<AdditionalInvoiceDetails lang={lang} item={item} users={users} invoice_fiscal_verifications={invoice_fiscal_verifications} />
			}
			{[ 'cash-invoice', 'cash-prepayment-invoice' ].indexOf(item.invoice_type) == -1 ? null :
				<PaymentTypesTable
					lang=                                                  {lang}
					item=                                                  {item}
					payment_types=                                         {payment_types}
					id_advance_invoice_consumption_by_id_consumer_invoices={id_advance_invoice_consumption_by_id_consumer_invoices}
					invoices=                                              {invoices}
					advance_invoice_consumptions=                          {advance_invoice_consumptions} />
			}
			<AdditionalDescription lang={lang} item={item} settings={settings} />
		</div>
		{!settings.print_footer_a4 ? null : <Footer lang={lang} settings={settings} />}
	</div>;
}
