const parseDecimalNumber = require('parse-decimal-number');

export function parseFloatLocal(val) {
	/*let language;
	if (window.navigator.languages) {
		language = window.navigator.languages[0];
	}
	else {
		language = window.navigator.userLanguage || window.navigator.language;
	}*/
	
	return parseDecimalNumber(
		val,
		1000.0.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }).replace(/[0-1]/g, '')
	);
}

export function toLocal(val, decimals) {
	return val.toLocaleString(undefined, {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
}
