const ReservationHelper = {
	FindOverlappingReservation: (reservation, reservations) => {
		let found_overlapping_reservation = null;
		for (let id_reservation in reservations) {
			const existing_reservation = reservations[id_reservation];
			
			if (
				existing_reservation.id_reservation              == reservation.id_reservation              ||
				existing_reservation.id_accommodation_item_place != reservation.id_accommodation_item_place ||
				existing_reservation.status                      == 'reversed'                              ||
				existing_reservation.status                      == 'no-show'                               ||
				existing_reservation.deleted
			) {
				continue;
			}
			
			// check overlap
			if (reservation.check_in < existing_reservation.check_out && reservation.check_out > existing_reservation.check_in) {
				found_overlapping_reservation = existing_reservation;
				break;
			}
		}
		
		return found_overlapping_reservation;
	},
};

export default ReservationHelper;
