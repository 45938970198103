export default {
	OpenPrint: (title, contents) => {
		const print_window = window.open('/print.html');
		print_window.focus();
		print_window.addEventListener('load', () => {
			print_window.postMessage({
				type: 'loadDocument',
				data: {
					title,
					contents,
				},
			});
			print_window.postMessage({
				type: 'print',
			});
		}, true);
	},
	
	// from https://stackoverflow.com/questions/16965915
	BigNumberHexToDecimal: hex => {
		let dec = [];
		const hex_len = hex.length;
		for (let h=0; h<hex_len; h++) {
			let carry = parseInt(hex[h], 16);
			
			for (let i=0; i<dec.length; i++) {
				const val = dec[i] * 16 + carry;
				dec[i] = val % 10;
				carry = Math.floor(val / 10);
			}
			
			while (carry > 0) {
				dec.push(carry % 10);
				carry = Math.floor(carry / 10);
			}
		}
		
		return dec.reverse().join('');
	},

	// translated from https://github.com/MPrtenjak/SLOTax/blob/master/SharedService/Modulo/Modulo10_Easy.cs
	CalculateModulo10: value => {
		let sum = 0;
		for (let i=0; i<value.length; i++) {
			sum += parseInt(value[i], 10);
		}

		return sum % 10;
	},
};
