import React, { Component } from 'react';

import { connect } from 'react-redux';

import moment from 'moment';

import {
	setLabelPrinterConnectedStatus,
	enqueueItemOnLabelPrinterQueue,
	requeueItemOnLabelPrinterQueue,
	removeItemFromLabelPrinterQueue,
	setLabelPrinters,
} from '../slices/AppSlice';

import LocalStorageHelper from '../helpers/LocalStorageHelper';

class LabelPrinterWatcher extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			current_item: null,
		};
		
		this.init         = this.init.bind(this);
		this.cleanup      = this.cleanup.bind(this);
		this.processQueue = this.processQueue.bind(this);
	}
	
	componentWillUnmount() {
		this.cleanup();
	}
	
	async init() {
		console.log('init label print');
		
		// wait to allow the whole app to load
		await new Promise(r => setTimeout(r, 3000));
		
		dymo.label.framework.init();
		const env = dymo.label.framework.checkEnvironment();
		console.log({ env });
		
		// wait for 2 seconds before fetching printers
		// why 2 seconds? not sure, maybe 1 second is enough
		// probably has to do with waiting for init to do its stuff
		await new Promise(r => setTimeout(r, 2000));
		const printers = dymo.label.framework.getPrinters();
		console.log({ printers });
		this.props.setLabelPrinters(printers.map(x => x.name));
		
		this.props.setLabelPrinterConnectedStatus(true);
		this.processQueue();
	}
	
	cleanup() {
		this.props.setLabelPrinterConnectedStatus(false);
	}
	
	componentDidUpdate(prevProps) {
		//if (this.props.ws_print_url != prevProps.ws_print_url) {
		//	this.cleanup();
		//	this.ws = this.init();
		//}
		
		console.log(JSON.stringify(this.props.label_printer_queue));
		if (this.props.label_printer_queue != prevProps.label_printer_queue) {
			this.processQueue();
		}
	}
	
	componentDidMount() {
		this.init();
	}
	
	async processQueue() {
		console.log('processQueue: queue length is ' + this.props.label_printer_queue.length);
		if (this.state.current_item !== null || this.props.label_printer_queue.length == 0) {
			return;
		}
		
		const printer_name = LocalStorageHelper.GetValue(this.props.client + '__label_printer_name');
		if (printer_name === undefined || printer_name === null || printer_name.length == 0) return;
		
		let item_data = this.props.label_printer_queue[0];
		console.log({ item_data });
		this.setState({ current_item: item_data });
		
		if (item_data.type == 'item') {
			const label_contents = atob(this.props.general_settings.label_printer_item_template);
			
			const label = dymo.label.framework.openLabelXml(
				label_contents
					.trim()
					.replace('ï»¿', '')
					.replaceAll('{INTERNAL_CODE}', item_data.item.item.internal_code)
					.replaceAll('{EAN}',           item_data.item.item.ean)
					.replaceAll('{NAME}',          item_data.item.item.title)
					.replaceAll('{PRICE}',         parseFloat(item_data.item.price).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}))
			);
			label.print(printer_name);
		}
		else if (item_data.type == 'person') {
			const label_contents = atob(this.props.general_settings.label_printer_person_template);
			
			console.log({ item_data });
			
			const label = dymo.label.framework.openLabelXml(
				label_contents
					.trim()
					.replace('ï»¿', '')
					.replaceAll('{NAME}',        item_data.item.item.type == 'natural' ? item_data.item.item.surname + ' ' + item_data.item.item.name : item_data.item.item.company_name)
					.replaceAll('{ADDRESS}',     item_data.item.item.address.trim())
					.replaceAll('{POST_CODE}',   item_data.item.item.post_code.trim())
					.replaceAll('{POST_OFFICE}', item_data.item.item.post_office.trim())
					.replaceAll('{COUNTRY}',     this.props.countries[item_data.item.item.id_country] === undefined ? '' : this.props.countries[item_data.item.item.id_country].official_name_local)
			);
			label.print(printer_name);
		}
		
		this.props.removeItemFromLabelPrinterQueue();
		this.setState({ current_item: null });
	}
	
	render() {
		return null;
	}
}
export default connect(
	state => {
		return {
			api_url:             state.ConstantsSlice.api_url,
			ws_print_url:        state.ConstantsSlice.ws_print_url,
			client:              state.ConstantsSlice.client,
			label_printer_queue: state.AppSlice.label_printer_queue,
			general_settings:    state.SettingsSlice.general,
			users:               state.UserSlice.users,
			token:               state.UserSlice.token,
			countries:           state.CodeTablesSlice.countries,
		};
	},
	dispatch => {
		return {
			dispatch,
			setLabelPrinterConnectedStatus:  connected => dispatch(setLabelPrinterConnectedStatus({ connected })),
			enqueueItemOnLabelPrinterQueue:  data      => dispatch(enqueueItemOnLabelPrinterQueue(data)),
			requeueItemOnLabelPrinterQueue:  data      => dispatch(requeueItemOnLabelPrinterQueue(data)),
			removeItemFromLabelPrinterQueue: data      => dispatch(removeItemFromLabelPrinterQueue(data)),
			setLabelPrinters:                data      => dispatch(setLabelPrinters({ printers: data })),
		};
	},
)(LabelPrinterWatcher);
