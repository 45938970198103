import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Intent,
	Overlay2,
	Dialog,
	Classes,
} from '@blueprintjs/core';
import { connect } from 'react-redux';

import moment from 'moment';

import { addReservation, addReservationCustomer }   from '../../slices/ReservationSlice';
import { addGuestBookItem }                         from '../../slices/GuestSlice';
import { saveReservation, saveReservationCustomer } from '../../api/Reservations';

import { saveAccommodationItemPlace }               from '../../api/CodeTables';
import { addAccommodationItemPlace }                from '../../slices/CodeTablesSlice';

import { throttle } from '../../App';

const STATUSES = {
	NEW:                      'new',
	WAITING_FOR_CONFIRMATION: 'waiting-for-confirmation',
	OFFER_SENT:               'offer-sent',
	CONFIRMED:                'confirmed',
	ADVANCE_INVOICE_SENT:     'advance-invoice-sent',
	CLOSED:                   'closed',
	NOT_FOR_RENT:             'not-for-rent',
	NO_SHOW:                  'no-show',
	REVERSED:                 'reversed',
	DRAGGING:                 'dragging',
};

const OFFSET_DAYS = 2;

const HOVER_STATE = {
	NONE:               0,
	ITEM_START:         1,
	ITEM_MIDDLE:        2,
	ITEM_END:           3,
	ITEM_OUTSIDE_START: 4,
	ITEM_OUTSIDE_END:   5,
};

const ReservationBar = React.memo((props) => {
	let {
		left_offset,
		top_offset,
		column_width,
		row_height,
		vertical_idx,
		idx_start,
		idx_end,
		status,
		channel,
		guest_count,
		title,
		status_2,
		is_mouse_over,
		dragging,
		guests_checked_in,
	} = props;
	
	const margin_left   = 2;
	const margin_top    = 2;
	const margin_bottom = 0;
	
	let cls = 'reservation-bar status-' + status;
	if (status_2 !== null) {
		cls += ' ' + status_2;
	}
	if (is_mouse_over) {
		cls += ' hover';
	}
	if (dragging) {
		cls += ' dragging';
	}
	
	let add_border = false;
	if (guests_checked_in && [ 'new', 'waiting-for-confirmation', 'offer-sent', 'confirmed', 'advance-invoice-sent' ].indexOf(status) != -1) {
		cls += ' guests-checked-in';
		add_border = true;
	}
	
	const hide_channel     = channel     === undefined || channel.length     == 0;
	const hide_guest_count = guest_count === undefined || guest_count.length == 0;
	
	let title_pos_x = 8;
	if (!hide_channel) {
		title_pos_x += 28;
	}
	if (!hide_guest_count) {
		title_pos_x += 28;
	}
	
	const width = column_width * (Math.max(1, Math.abs(idx_end - idx_start))) - margin_left;
	const height = row_height - (margin_top + margin_bottom);
	
	return <svg
		className={cls}
		x={
			margin_left + Math.min(idx_start, idx_end) * column_width + left_offset + column_width / 2
		}
		y={
			vertical_idx * row_height + top_offset + margin_top
		}
		width={
			width
		}
		height={height}
		>
			<rect
				x={ add_border ? '2' : '0' }
				y={ add_border ? '2' : '0' }
				width={ width - (add_border ? 4 : 0) }
				height={ height - (add_border ? 4 : 0) }
				className={'reservation-bar-background'}
				opacity='1'
				rx='12' />
			<rect
				x='0'
				y='0'
				width='100%'
				height='100%'
				className='reservation-bar-background-pattern'
				fill={'url(#' + (status == STATUSES.OFFER_SENT ? 'pattern-polka-dots' : 'pattern-diagonal-stripes') + ')'}
				opacity='1'
				rx='12' />
			
			<svg mask='url(#fade)'>
				<rect
					x='0'
					y='0'
					width='100%'
					height='100%'
					opacity='0.0' />
				
				{hide_channel ? null :
					<g>
						<rect
							x='8'
							y={ (row_height - 24) / 2 }
							width='24'
							height='24'
							fill='#000000'
							opacity='0.2'
							rx='8' />
						<text textAnchor='middle' x='20' y={row_height / 2 + 5}>
							{channel}
						</text>
					</g>
				}
				
				{hide_guest_count ? null :
					<g>
						<rect
							x='36'
							y={ (row_height - 24) / 2 }
							width='24'
							height='24'
							fill='#000000'
							opacity='0.2'
							rx='8' />
						<text textAnchor='middle' x='48' y={row_height / 2 + 5}>
							{guest_count}
						</text>
					</g>
				}
				
				<text textAnchor='left' x={title_pos_x} y={row_height / 2 + 5}>
					{title}
				</text>
			</svg>
			
			{status_2 === null ? null :
				<>
					<rect
						x='0'
						y='0'
						width='40'
						height='40'
						clipPath='url(#reservation-bar-corner-triangle-clip)'
						className='custom-status-indicator'
						rx='12' />
					
					<clipPath id={'reservation-bar-corner-triangle-clip--' + vertical_idx + '-' + idx_start + '-' + idx_end}>
						<path
							d={'M ' + width + ',' + (height - 20) + ' L ' + width + ',' + height + ' L ' + (width - 20) + ',' + height + ' L ' + width + ',' + (height - 20) + ' z'}
							fill='#000000' />
					</clipPath>
					<rect
						x={width - 40}
						y={height - 40}
						width='40'
						height='40'
						clipPath={'url(#' + 'reservation-bar-corner-triangle-clip--' + vertical_idx + '-' + idx_start + '-' + idx_end + ')'}
						className='custom-status-indicator'
						rx='12' />
				</>
			}
		</svg>;
});

const ReservationRequestBar = React.memo((props) => {
	let {
		left_offset,
		top_offset,
		column_width,
		row_height,
		vertical_idx,
		idx_start,
		idx_end,
		status,
		guest_count,
		title,
		is_mouse_over,
		dragging,
	} = props;
	
	const margin_left   = 2;
	const margin_top    = 2;
	const margin_bottom = 0;
	
	let cls = 'reservation-request-bar status-' + status;
	if (is_mouse_over) {
		cls += ' hover';
	}
	if (dragging) {
		cls += ' dragging';
	}
	
	const hide_guest_count = guest_count === undefined || guest_count.length == 0;
	
	let title_pos_x = 8;
	if (!hide_guest_count) {
		title_pos_x += 28;
	}
	
	const width = column_width * (Math.max(1, Math.abs(idx_end - idx_start))) - margin_left;
	const height = row_height - (margin_top + margin_bottom);
	
	return <svg
		className={cls}
		x={
			margin_left + Math.min(idx_start, idx_end) * column_width + left_offset + column_width / 2
		}
		y={
			vertical_idx * row_height + top_offset + margin_top
		}
		width={
			width
		}
		height={height}
		>
			<rect
				x='2'
				y='2'
				width={ width - 4 }
				height={ height - 4 }
				className='reservation-bar-background'
				opacity='1'
				rx='12' />
			<rect
				x='0'
				y='0'
				width='100%'
				height='100%'
				className='reservation-bar-background-pattern'
				fill={'url(#' + (status == STATUSES.OFFER_SENT ? 'pattern-polka-dots' : 'pattern-diagonal-stripes') + ')'}
				opacity='1'
				rx='12' />
			
			<svg mask='url(#fade)'>
				<rect
					x='0'
					y='0'
					width='100%'
					height='100%'
					opacity='0.0' />
				
				{hide_guest_count ? null :
					<g>
						<rect
							x='8'
							y={ (row_height - 24) / 2 }
							width='24'
							height='24'
							fill='#000000'
							opacity='0.2'
							rx='8' />
						<text textAnchor='middle' x='20' y={row_height / 2 + 5}>
							{guest_count}
						</text>
					</g>
				}
				
				<text textAnchor='left' x={title_pos_x} y={row_height / 2 + 5}>
					{title}
				</text>
			</svg>
		</svg>;
});

const ConfirmReservationMoveDialog = React.memo(props => {
	let {
		idReservation,
		type,
		onCancel,
		onConfirm,
		items,
		dates,
		horizontalIdx,
		verticalIdx,
		offset,
		convertIdxToDate,
		convertIdxToIdAccommodationItemPlace,
		customers,
		rooms,
		accommodationItemPlaces,
		reservationCustomersByIdReservations,
	} = props;
	
	const new_id_accommodation_item_place =
		convertIdxToIdAccommodationItemPlace(verticalIdx);
	
	const reservation = items[idReservation];
	
	let new_check_in  = reservation.check_in;
	let new_check_out = reservation.check_out;
	
	if (type == 'move') {
		new_check_in = convertIdxToDate(
			dates,
			horizontalIdx - offset
		);
		
		new_check_out = convertIdxToDate(
			dates,
			(
				horizontalIdx -
				offset +
				(
					reservation.idx_end -
					reservation.idx_start
				)
			)
		);
	}
	else if (type == 'start') {
		new_check_in = convertIdxToDate(
			dates,
			horizontalIdx
		);
	}
	else if (type == 'end') {
		new_check_out = convertIdxToDate(
			dates,
			horizontalIdx
		);
	}
	
	const range_source = moment(reservation.check_in).format('DD.MM.YYYY') + ' - ' + moment(reservation.check_out).format('DD.MM.YYYY');
	const range_target = moment(new_check_in).format('DD.MM.YYYY') + ' - ' + moment(new_check_out).format('DD.MM.YYYY');
	
	const room_title_source = accommodationItemPlaces[reservation.id_accommodation_item_place].title;
	const room_title_target = rooms[verticalIdx].title;
	
	const customer_name = reservation.id_customer === null || reservation.id_customer === undefined || customers[reservation.id_customer] === undefined ? '' :
		(
			customers[reservation.id_customer].type == 'natural' ?
				customers[reservation.id_customer].surname + ' ' + customers[reservation.id_customer].name
				:
				customers[reservation.id_customer].company_name
		);
	
	const reservation_customers    = reservationCustomersByIdReservations[reservation.id_reservation] || {};
	const id_reservation_customers = Object.keys(reservation_customers);
	const has_customers            = id_reservation_customers.length > 0;
	
	let buttons = <>
		<Button onClick={onCancel}>Prekliči</Button>
		<Button intent='primary' onClick={() => {
			onConfirm(
				idReservation,
				new_id_accommodation_item_place,
				new_check_in,
				new_check_out,
				false,
			);
		}}>Da</Button>
	</>;
	let additional_description = null;
	
	if (has_customers && range_source != range_target) {
		// if a reservation with customers is moved horizontally, customers must be sent to e-tourism
		buttons = <>
			<Button onClick={onCancel}>Prekliči</Button>
			<Button intent='primary' onClick={() => {
				onConfirm(
					idReservation,
					new_id_accommodation_item_place,
					new_check_in,
					new_check_out,
					false,
				);
			}}>Spremeni obdobje rezervacije</Button>
			<Button intent='primary' onClick={() => {
				onConfirm(
					idReservation,
					new_id_accommodation_item_place,
					new_check_in,
					new_check_out,
					true,
				);
			}}>Spremeni obdobje in datum odjave gostov</Button>
		</>;
		
		additional_description = <div className='mt-4'>
			Ali želite samo spremeniti obdobje rezervacije (kasneje lahko poljubno spremenite podatke o odhodih gostov) ali želite tudi poslati spremembe datuma odhoda gostov na e-Turizem?
		</div>;
	}
	
	return <Dialog
		canEscapeKeyCancel={true}
		canOutsideClickCancel={true}
		isOpen={true}
		style={{ minWidth: 580 }}>
			<div className={Classes.DIALOG_BODY}>
				<div>
					<div className='mb-3'><strong>Sprememba rezervacije?</strong></div>
					
					<div>Rezervacija: {room_title_source}, {range_source}</div>
					<div className='mb-3'>Nosilec: {customer_name}</div>
					
					<div><strong>Sprememba:</strong></div>
					<div>Rezervacija: {room_title_target}, {range_target}</div>
					
					{additional_description}
				</div>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					{buttons}
				</div>
			</div>
		</Dialog>;
});

const HeaderColumn = React.memo(props => {
	let {
		idx,
		column_width,
		header_height,
		is_first_day_of_month,
		is_current_date,
		is_today,
		is_hovered,
		day_title,
		day_date,
	} = props;
	
	return <svg key={'timeline-col-' + idx} x={ idx * column_width } y='0' width={column_width}>
		{
			is_current_date ?
				<rect
					x='0'
					y='0'
					width={column_width}
					height={header_height}
					fill='#000000'
					opacity='0.8' /> :
				is_today ?
					<rect
						x='0'
						y='0'
						width={column_width}
						height={header_height}
						fill='#000000'
						opacity='0.5' /> :
					is_first_day_of_month ?
						<rect
							x='0'
							y='0'
							width={column_width}
							height={header_height}
							fill='#e06e00'
							opacity='0.7' /> :
						null
		}
		{!is_hovered ? null :
			<rect
				x='0'
				y='0'
				width={column_width}
				height={header_height}
				fill='#000000'
				opacity='0.3' />
		}
		<path
			d={'M ' + (column_width - 1) + ',0 L ' + (column_width - 1) + ',' + header_height}
			stroke='#bbbbbb'
			strokeWidth={0.5} />
		<text textAnchor='middle' x='50%' y='18' fill='#ffffff'>
			{ day_title }
		</text>
		<text textAnchor='middle' x='50%' y='36' fill='#ffffff'>
			{ day_date }
		</text>
	</svg>;
});

class HeaderColumns extends PureComponent {
	constructor(props) {
		super(props);
		
		this.translatePositionToIndex = this.translatePositionToIndex.bind(this);
		this.translateDateToIdx       = this.translateDateToIdx.bind(this);
		this.setCursor                = this.setCursor.bind(this);
		
		let filter_index_start = (this.props.filterFrom === null ? -1 : this.translateDateToIdx(this.props.filterFrom));
		let filter_index_end   = (this.props.filterTo   === null ? -1 : this.translateDateToIdx(this.props.filterTo));
		
		this.state = {
			drag_hor_index_start: -1,
			drag_hor_index_end:   -1,
			filter_index_start:   filter_index_start,
			filter_index_end:     filter_index_end,
		};
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.filterFrom != prevProps.filterFrom || this.props.filterTo != prevProps.filterTo) {
			let filter_index_start = (this.props.filterFrom === null ? -1 : this.translateDateToIdx(this.props.filterFrom));
			let filter_index_end   = (this.props.filterTo   === null ? -1 : this.translateDateToIdx(this.props.filterTo));
			
			this.setState({
				filter_index_start,
				filter_index_end,
			});
		}
	}
	
	translatePositionToIndex(x) {
		const timeline_pos = this.props.timelineRef.current.getBoundingClientRect();
		const relative_x   = x - timeline_pos.left - this.props.left_side_width;
		const hor_index    = Math.floor(relative_x / this.props.column_width);
		
		return hor_index;
	}
	
	translateDateToIdx(date) {
		for (let i=0; i<this.props.dates.length; i++) {
			const d = this.props.dates[i][6].toDate();
			if (d.getYear() == date.getYear() && d.getMonth() == date.getMonth() && d.getDate() == date.getDate()) return i;
		}
		
		return -1;
	}
	
	setCursor(cursor) {
		this.props.timelineRef.current.style.cursor = cursor;
	}
	
	render() {
		const { timeline_width, header_height, left_side_width, column_width, dates, active_horizontal_idx } = this.props;
		
		return <div style={{ position: 'sticky', top: 0, width: timeline_width, height: header_height, zIndex: 16 }}>
			<svg width={timeline_width} height={header_height} style={{ backgroundColor: 'rgba(97, 127, 151, 0.8)' }}>
				<svg x={left_side_width}>
					{dates.map((item, idx) => {
						const is_first_day_of_month = (item[2] === true);
						const is_current_date       = (item[4] === true);
						const is_today              = (item[5] === true);
						const is_hovered            = (active_horizontal_idx == idx);
						
						return <HeaderColumn
							key={'header-column-' + item[1]}
							{...{
								idx,
								column_width,
								header_height,
								is_first_day_of_month,
								is_current_date,
								is_today,
								is_hovered,
								day_title: item[0],
								day_date:  item[1],
							}} />;
					})}
				</svg>
			</svg>
			<svg style={{ position: 'absolute', left: '0px', top: '0px', width: timeline_width, height: header_height }}
				onMouseDown={(e) => {
					const hor_index = this.translatePositionToIndex(e.pageX);
					
					this.setState({
						drag_hor_index_start: hor_index,
						drag_hor_index_end:   hor_index,
					});
				}}
				onMouseMove={(e) => {
					const hor_index = this.translatePositionToIndex(e.pageX);
					
					this.setState({
						drag_hor_index_end: hor_index,
					});
				}}
				onMouseUp={(e) => {
					const start_index = Math.min(this.state.drag_hor_index_start, this.state.drag_hor_index_end);
					const end_index   = Math.max(this.state.drag_hor_index_start, this.state.drag_hor_index_end);
					
					if (end_index - start_index > 0 && dates[start_index] !== undefined && dates[end_index] !== undefined) {
						this.props.setDateFilter(
							dates[start_index][6],
							dates[end_index][6]
						);
					}
					
					this.setState({
						drag_hor_index_start: -1,
						drag_hor_index_end:   -1,
					});
				}}>
				{this.state.filter_index_start == -1 || this.state.filter_index_end == -1 ? null :
					<rect
						x={Math.min(this.state.filter_index_start, this.state.filter_index_end) * column_width + left_side_width}
						y='0'
						height={header_height}
						width={
							(Math.max(this.state.filter_index_start, this.state.filter_index_end) * column_width + left_side_width)
							-
							(Math.min(this.state.filter_index_start, this.state.filter_index_end) * column_width + left_side_width)
							+
							column_width
						}
						fill='#00ff00'
						opacity='0.5'
						rx='10' />
				}
				
				{this.state.drag_hor_index_start == -1 || this.state.drag_hor_index_end == -1 ? null :
					<rect
						x={Math.min(this.state.drag_hor_index_start, this.state.drag_hor_index_end) * column_width + left_side_width}
						y='0'
						height={header_height}
						width={
							(Math.max(this.state.drag_hor_index_start, this.state.drag_hor_index_end) * column_width + left_side_width)
							-
							(Math.min(this.state.drag_hor_index_start, this.state.drag_hor_index_end) * column_width + left_side_width)
							+
							column_width
						}
						fill='#ff0000'
						opacity='0.5'
						rx='10' />
				}
			</svg>
		</div>;
	}
}

const LeftSideRow = React.memo(props => {
	let {
		idx,
		left_side_width,
		row_height,
		timeline_width,
		is_hovered,
		is_last,
		title,
		dirty_state,
		toggleDirty,
	} = props;
	
	return <svg key={'timeline-left-col-' + idx} x='0' y={ idx * row_height } width={left_side_width}>
		<rect
			x={0}
			y={0}
			width={left_side_width}
			height={row_height}
			className={'room-dirty-background ' + dirty_state} />
		
		{!is_hovered ? null :
			<rect
				x={0}
				y={0}
				width={left_side_width}
				height={row_height}
				className='room-hover-background' />
		}
		
		<path
			d={'M 0,1 L ' + timeline_width + ',1'}
			stroke='rgba(0, 0, 0, 0.5)'
			strokeWidth={0.5} />
		
		{!is_last ? null :
			<path
				d={'M 0,' + (row_height + 1) + ' L ' + timeline_width + ',' + (row_height + 1)}
				stroke='rgba(0, 0, 0, 0.5)'
				strokeWidth={0.5} />
		}
		
		<foreignObject x='12' y='10' width={left_side_width - 12 * 2 - 24} height={row_height - 14}>
			<div xmlns='http://www.w3.org/1999/xhtml' style={{ position: 'relative', height: '100%' }}>
				<div style={{ position: 'absolute', bottom: '0' }}>
					{ title }
				</div>
			</div>
		</foreignObject>
		
		<g
			className={'room-dirty-indicator ' + dirty_state}
			onClick={toggleDirty}>
			<rect
				x={left_side_width - 28 - 4}
				y={(row_height - 24) / 2 + 2}
				width={24}
				height={24}
				rx='8'
				className='room-dirty-indicator--background' />
			<svg
				x={left_side_width - 26 - 4}
				y={(row_height - 20) / 2 + 2}
				width={20}
				height={20}
				viewBox='0 0 24 24'
				className='room-dirty-indicator--icon'>
				<path
					d='M19.36,2.72L20.78,4.14L15.06,9.85C16.13,11.39 16.28,13.24 15.38,14.44L9.06,8.12C10.26,7.22 12.11,7.37 13.65,8.44L19.36,2.72M5.93,17.57C3.92,15.56 2.69,13.16 2.35,10.92L7.23,8.83L14.67,16.27L12.58,21.15C10.34,20.81 7.94,19.58 5.93,17.57Z' />
			</svg>
		</g>
	</svg>;
});

class LeftSide extends PureComponent {
	constructor(props) {
		super(props);
		
		this.toggleRoomDirty = this.toggleRoomDirty.bind(this);
	}
	
	toggleRoomDirty(id_accommodation_item_place) {
		switch (this.props.accommodation_item_places[id_accommodation_item_place].state) {
			case 'dirty':
				this.props.setDirtyState(id_accommodation_item_place, 'waiting');
				break;
			case 'waiting':
				this.props.setDirtyState(id_accommodation_item_place, 'clean');
				break;
			case 'clean':
				this.props.setDirtyState(id_accommodation_item_place, 'dirty');
				break;
		}
	}
	
	render() {
		const { left_side_width, timeline_width, timeline_height, row_height, rows, active_vertical_idx, accommodation_item_places } = this.props;
		
		let c = -1; // -1, so that first increment puts it at 0
		
		return <div style={{
			position: 'sticky',
			left: 0,
			width: left_side_width,
			height: timeline_height,
			backgroundColor: '#f6f6f6',
			borderRight: '2px solid #bbbbbb',
		}}>
			<svg x='0' y='0' width={left_side_width} height={timeline_height}>
				{rows.map((item, idx) => {
					if (item === undefined) return null;
					
					c++;
					
					return <LeftSideRow
						key={'left-side-row-' + item.id_accommodation_item_place}
						{...{
							idx: c,
							left_side_width,
							row_height,
							timeline_width,
							is_hovered: active_vertical_idx == idx,
							is_last: idx == (rows.length - 1),
							title: item.title,
							dirty_state: accommodation_item_places[item.id_accommodation_item_place].state,
							toggleDirty: () => { this.toggleRoomDirty(item.id_accommodation_item_place) },
						}} />;
				})}
			</svg>
		</div>;
	}
}

const HorizontalLines = React.memo((props) => {
	const { header_height, timeline_width, row_height, rows } = props;
	
	return <svg x='0' y={header_height} width={timeline_width}>
		{rows.map((item, idx) => {
			return <svg key={'timeline-hor-line-' + idx} x='0' y={ idx * row_height } width={timeline_width}>
				<path d={'M 0,1 L ' + timeline_width + ',1'} stroke='#bbbbbb' strokeWidth={0.5} />
			</svg>;
		})}
		<svg key={'timeline-hor-line-' + rows.length} x='0' y={ rows.length * row_height } width={timeline_width}>
			<path d={'M 0,1 L ' + timeline_width + ',1'} stroke='#bbbbbb' strokeWidth={0.5} />
		</svg>
	</svg>;
});

const VerticalLines = React.memo((props) => {
	const { left_side_width, timeline_width, timeline_height, column_width, header_height, dates } = props;
	
	return <svg x={left_side_width} y='0' width={timeline_width}>
		{true ? null : <path d={'M 1,0 L 1,' + timeline_height} stroke='#bbbbbb' strokeWidth={0.5} />}
		{dates.map((item, idx) => {
			const is_weekend      = (item[3] == 0 || item[3] == 6);
			const weekend_opacity = (item[3] == 0 ? 0.15 : 0.1);
			
			return <svg key={'timeline-col-lines-' + idx} x={ idx * column_width } y='0' width={column_width}>
				{
					!is_weekend ?
						null :
						<rect
							x='0'
							y='0'
							width={column_width}
							height={timeline_height}
							fill='#b71c1c'
							opacity={weekend_opacity} />
				}
				<path
					d={'M 1,0 L 1,' + timeline_height}
					stroke='#bbbbbb'
					strokeWidth={0.5} />
				<path
					d={'M ' + (column_width/2) + ',' + header_height + ' L ' + (column_width/2) + ',' + timeline_height}
					stroke='#e6e6e6'
					strokeWidth={0.5} />
				
				{idx < (dates.length - 1) ?
					null :
					<path
						d={'M ' + (column_width - 1) + ',0 L ' + (column_width - 1) + ',' + timeline_height}
						stroke='#bbbbbb'
						strokeWidth={0.5} />
				}
			</svg>;
		})}
	</svg>;
});

const NewReservationIndicator = React.memo((props) => {
	const { column_width, left_side_width, row_height, header_height, new_rect_position } = props;
	
	return <rect
		x={new_rect_position.hor_index * column_width + left_side_width + column_width / 2}
		y={new_rect_position.ver_index * row_height + header_height}
		width={column_width / 2}
		height={row_height}
		fill='#617f97'
		opacity='0.2' />;
});

const TimelineTooltip = React.memo((props) => {
	const {
		tooltip_open,
		tooltip_hidden,
		animation_ended,
		reservation,
		accommodation_item_place,
		channels,
		customers,
		general_settings,
	} = props;
	
	if (reservation === null || accommodation_item_place === null) {
		return null;
	}
	
	let status_titles = [];
	switch (reservation.status) {
		case STATUSES.NEW:
			status_titles.push('novo');
			break;
		case STATUSES.WAITING_FOR_CONFIRMATION:
			status_titles.push('v potrjevanju');
			break;
		case STATUSES.OFFER_SENT:
			status_titles.push('izdana ponudba');
			break;
		case STATUSES.CONFIRMED:
			status_titles.push('potrjena');
			break;
		case STATUSES.ADVANCE_INVOICE_SENT:
			status_titles.push('izdan avans');
			break;
		case STATUSES.CLOSED:
			status_titles.push('plačana');
			break;
		case STATUSES.REVERSED:
			status_titles.push('stornirana');
			break;
		case STATUSES.NOT_FOR_RENT:
			status_titles.push('ni za oddajo');
			break;
		case STATUSES.NO_SHOW:
			status_titles.push('neprihod');
			break;
		case STATUSES.DRAGGING:
			status_titles.push('');
			break;
	}
	
	switch (reservation.status_2) {
		case 'custom-status-1':
			status_titles.push(general_settings.title_custom_status_1);
			break;
		case 'custom-status-2':
			status_titles.push(general_settings.title_custom_status_2);
			break;
		case 'custom-status-3':
			status_titles.push(general_settings.title_custom_status_3);
			break;
		case 'custom-status-4':
			status_titles.push(general_settings.title_custom_status_4);
			break;
		case 'custom-status-5':
			status_titles.push(general_settings.title_custom_status_5);
			break;
		case 'custom-status-6':
			status_titles.push(general_settings.title_custom_status_6);
			break;
		case 'custom-status-7':
			status_titles.push(general_settings.title_custom_status_7);
			break;
	}
	
	const height = reservation.note.length == 0 ? 160 : 230;
	
	return <svg
		width='480'
		height={height + 10}
		style={{ filter: 'url(#timeline-tooltip-shadow)' }}
		className={
			'timeline-tooltip' +
				' ' +
				(tooltip_open   ? 'timeline-tooltip-visible' : 'timeline-tooltip-hidden') +
				' ' +
				(tooltip_hidden ? 'timeline-tooltip-gone'  : '')
		}
		onAnimationEnd={animation_ended}>
		<rect
			x='8'
			y='10'
			width={480 - 2 * 8}
			height={height - 10}
			fill='rgba(71, 94, 111, 0.8)'
			rx='20' />
		
		<svg
			x={480 / 2 - (20 / 2)}
			y='0'>
			<path
				d='M 11,0 L 21,10 L 0,10 Z'
				fill='rgba(71, 94, 111, 0.8)' />
		</svg>
		
		<foreignObject
			x='8'
			y='24'
			width={480 - 2 * 8}
			height={height}>
			<div xmlns='http://www.w3.org/1999/xhtml'>
				<div className='timeline-tooltip-contents'>
					<div className='timeline-tooltip-name'>{ accommodation_item_place.title }</div>
					
					<div className='flex'>
						<div className='flex-1'>
							<div className='timeline-tooltip-title'>šifra rezervacije</div>
							<div className='timeline-tooltip-value'>{ reservation.internal_code }</div>
						</div>
						
						<div className='flex-1'>
							<div className='timeline-tooltip-title'>število nočitev</div>
							<div className='timeline-tooltip-value'>{ reservation.idx_end - reservation.idx_start }</div>
						</div>
						
						<div className='flex-1'>
							<div className='timeline-tooltip-title'>prodajni kanal</div>
							<div className='timeline-tooltip-value'>{ channels[reservation.id_channel].title }</div>
						</div>
					</div>
					
					<div className='flex'>
						<div className='flex-1'>
							<div className='timeline-tooltip-title'>nosilec</div>
							<div className='timeline-tooltip-value'>{
								customers[reservation.id_customer] === undefined ? '' : (customers[reservation.id_customer].type == 'natural' ?
									customers[reservation.id_customer].surname + ' ' + customers[reservation.id_customer].name
								:
								customers[reservation.id_customer].company_name)
							}</div>
						</div>
						
						<div className='flex-1'>
							<div className='timeline-tooltip-title'>odrasli/otroci</div>
							<div className='timeline-tooltip-value'>{
								parseInt(reservation.guest_adult_count, 10).toString() +
								'/' +
								(parseInt(reservation.guest_child_1_count, 10) + parseInt(reservation.guest_child_2_count, 10)).toString()
							}</div>
						</div>
						
						<div className='flex-1'>
							<div className='timeline-tooltip-title'>status</div>
							<div className='timeline-tooltip-value'>{ status_titles.map(title => <div key={'status-title-' + title}>{ title }</div>) }</div>
						</div>
					</div>
					
					<div className='timeline-tooltip-note mt-4'>
						{ reservation.note }
					</div>
				</div>
			</div>
		</foreignObject>
	</svg>;
});

class Timeline extends Component {
	constructor(props) {
		super(props);
		
		this.setSizes                             = this.setSizes                            .bind(this);
		this.setCompact                           = this.setCompact                          .bind(this);
		this.findAllowedDragEndIndex              = this.findAllowedDragEndIndex             .bind(this);
		this.getItemHoverState                    = this.getItemHoverState                   .bind(this);
		this.generateReservationTable             = this.generateReservationTable            .bind(this);
		this.generateIdAccommodationItemPlaceIdxs = this.generateIdAccommodationItemPlaceIdxs.bind(this);
		this.convertIdAccommodationItemPlaceToIdx = this.convertIdAccommodationItemPlaceToIdx.bind(this);
		this.convertIdxToIdAccommodationItemPlace = this.convertIdxToIdAccommodationItemPlace.bind(this);
		this.fixDraggingReservationHorVerIdxs     = this.fixDraggingReservationHorVerIdxs    .bind(this);
		this.setDirtyState                        = this.setDirtyState                       .bind(this);
		this.showTooltip                          = this.showTooltip                         .bind(this);
		this.hideTooltip                          = this.hideTooltip                         .bind(this);
		this.changeCaseIfNeeded                   = this.changeCaseIfNeeded                  .bind(this);
		
		const id_accommodation_item_places_to_idxs = this.generateIdAccommodationItemPlaceIdxs(props.rooms, props.accommodation_item_places);
		const dates             = this.generateDates();
		const reservation_table = this.generateReservationTable(dates, props.rooms, Object.values(props.items), id_accommodation_item_places_to_idxs, Object.values(props.reservation_requests));
		
		this.state = {
			new_rect_position:        { hor_index: 0, ver_index: 0 },
			new_rect_visible:         false,
			new_dragging:             false,
			new_drag_hor_index_start: -1,
			new_drag_hor_index_end:   -1,
			new_drag_ver_index:       -1,
			dates:                    dates,
			reservation_table:        reservation_table,
			tooltip_open:             true,
			tooltip_horizontal_idx:   -1,
			tooltip_vertical_idx:     -1,
			tooltip_hidden:           true,
			tooltip_id_reservation:   -1,
			compact:                  false,
			current_hor_idx:          -1,
			current_ver_idx:          -1,
			current_fractional_hor_idx: 0,
			current_id_reservation:   -2, // -2 is to cause the first change to be detected, even if it's -1
			dragging_id_reservation:        -1,
			dragging_id_reservation_offset: 0,
			dragging_id_reservation_hor_index: -1,
			dragging_id_reservation_ver_index: -1,
			dragging_id_reservation_valid: true,
			request_move_id_reservation: -1,
			request_move_id_reservation_offset: 0,
			request_move_id_reservation_ver_index: -1,
			request_move_id_reservation_hor_index: -1,
			mouse_down_hor_idx:       -1,
			mouse_down_ver_idx:       -1,
			dragging_id_reservation_start:  -1,
			dragging_id_reservation_end:    -1,
			request_move_id_reservation_start: -1,
			request_move_id_reservation_end:   -1,
			id_accommodation_item_places_to_idxs,
			tiktok: 0, // meant to force rerender
		};
		
		this.timelineRef          = React.createRef();
		this.timelineContainerRef = React.createRef();
		
		this.forceRerenderWithoutThrottling = () => {
			this.setState({
				tiktok: this.state.tiktok == 0 ? 1 : 0,
			});
		};
		this.forceRerender = throttle(this.forceRerenderWithoutThrottling, 200);
		this.forceRerender = this.forceRerender.bind(this);
		
		this.setSizes(this.state.compact);
		
		this.tooltip_id_reservation = -1;
	}
	
	componentDidMount() {
		this.forceRerender();
	}
	
	setSizes(compact) {
		this.timeline_width = 3600;
		
		this.left_side_width = 150;
		this.header_height   = 46;
		this.column_width    = 40 * (30 / this.props.scaleDays);
		this.row_height      = compact ? 36 : 48;
		
		this.timeline_height = this.row_height * this.props.rooms.length + 300;
	}
	
	setCompact(enabled) {
		this.setSizes(enabled);
		
		this.setState({
			compact: enabled,
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		let id_accommodation_item_places_to_idxs = this.state.id_accommodation_item_places_to_idxs;
		
		if (prevProps.rooms != this.props.rooms) {
			id_accommodation_item_places_to_idxs = this.generateIdAccommodationItemPlaceIdxs(this.props.rooms, this.props.accommodation_item_places);
			
			this.setSizes(this.state.compact);
			
			this.setState({
				id_accommodation_item_places_to_idxs,
			});
		}
		
		if (prevProps.currentDate != this.props.currentDate) {
			const dates = this.generateDates();
			const reservation_table = this.generateReservationTable(
				dates,
				this.props.rooms,
				Object.values(this.props.items),
				id_accommodation_item_places_to_idxs,
				Object.values(this.props.reservation_requests)
			);
			
			this.setState({
				dates,
				reservation_table,
			});
		}
		
		if (prevProps.scaleDays != this.props.scaleDays) {
			this.setSizes();
			
			const dates = this.generateDates();
			const reservation_table = this.generateReservationTable(
				dates,
				this.props.rooms,
				Object.values(this.props.items),
				id_accommodation_item_places_to_idxs,
				Object.values(this.props.reservation_requests)
			);
			
			this.setState({
				dates,
				reservation_table,
			});
		}
		
		if (prevProps.items != this.props.items || prevProps.rooms != this.props.rooms) {
			const dates = this.generateDates();
			const reservation_table = this.generateReservationTable(
				dates,
				this.props.rooms,
				Object.values(this.props.items),
				id_accommodation_item_places_to_idxs,
				Object.values(this.props.reservation_requests)
			);
			
			this.setState({
				dates,
				reservation_table,
				current_id_reservation: -1,
			});
		}
	}
	
	generateDates() {
		const weekdays = {
			0: 'ned',
			1: 'pon',
			2: 'tor',
			3: 'sre',
			4: 'čet',
			5: 'pet',
			6: 'sob',
		};
		
		const dates = [];
		const curr_date = moment(this.props.currentDate).subtract(OFFSET_DAYS, 'days');
		const today = new Date();
		
		for (let i=0; i<120; i++) {
			dates.push([
				weekdays[curr_date.day()],
				curr_date.date() + '.' + (curr_date.month() + 1) + '.',
				curr_date.date() == 1,
				curr_date.day(),
				(
					curr_date.year()  == this.props.currentDate.getFullYear() &&
					curr_date.month() == this.props.currentDate.getMonth() &&
					curr_date.date()  == this.props.currentDate.getDate()
				),
				(
					curr_date.year()  == today.getFullYear() &&
					curr_date.month() == today.getMonth() &&
					curr_date.date()  == today.getDate()
				),
				curr_date.clone(), //.toDate(),
			]);
			
			curr_date.add(1, 'days');
		}
		
		return dates;
	}
	
	convertCheckInOutToIdxs(dates, check_in, check_out) {
		const a = dates[0][6].startOf('day');
		
		let b = moment(check_in).startOf('day');
		const idx_start = b.diff(a, 'days'); // + OFFSET_DAYS;
		
		b = moment(check_out).startOf('day');
		const idx_end = b.diff(a, 'days'); // + OFFSET_DAYS;
		
		return { idx_start, idx_end };
	}
	
	generateReservationTable(dates, rooms, items, id_accommodation_item_places_to_idxs, reservation_requests) {
		id_accommodation_item_places_to_idxs = id_accommodation_item_places_to_idxs || this.state.id_accommodation_item_places_to_idxs;
		const table = [];
		for (let y=0; y<rooms.length; y++) {
			const row = [];
			
			for (let x=0; x<dates.length; x++) {
				row.push(-1);
			}
			
			table.push(row);
		}
		const table_requests = [];
		for (let y=0; y<rooms.length; y++) {
			const row = [];
			
			for (let x=0; x<dates.length; x++) {
				row.push(-1);
			}
			
			table_requests.push(row);
		}
		
		// filter reservations by dates
		const from_iso  = dates[0               ][6].format('YYYY-MM-DD');
		const until_iso = dates[dates.length - 1][6].format('YYYY-MM-DD');
		
		const filtered_items = items.filter(item => from_iso <= item.check_out && until_iso >= item.check_in);
		
		// sort by check in ASC to improve hover detection
		const sorted_items = filtered_items.sort((a, b) => {
			if (a.check_in < b.check_in) return -1;
			if (a.check_in > b.check_in) return  1;
			return 0;
		});
		
		const new_items = {};
		for (let i=0; i<sorted_items.length; i++) {
			const item           = {...sorted_items[i]};
			const id_reservation = item.id_reservation;
			
			if (item.status == 'reversed' || item.status == 'no-show' || item.deleted) {
				continue;
			}
			
			new_items[id_reservation] = item;
			
			const { idx_start, idx_end } = this.convertCheckInOutToIdxs(dates, item.check_in, item.check_out);
			
			new_items[id_reservation].idx_start = idx_start;
			new_items[id_reservation].idx_end   = idx_end;
			
			for (let idx = idx_start; idx <= idx_end; idx++) {
				const vertical_idx = this.convertIdAccommodationItemPlaceToIdx(item.id_accommodation_item_place, id_accommodation_item_places_to_idxs);
				if (table[vertical_idx] === undefined) continue;
				table[vertical_idx][idx] = id_reservation;
			}
		}
		
		const new_items_reservation_requests = {};
		for (let i=0; i<reservation_requests.length; i++) {
			const item                   = {...reservation_requests[i]};
			const id_reservation_request = item.id_reservation_request;
			
			if (item.status != 'new' && item.status != 'confirmed') {
				continue;
			}
			if (item.id_accommodation_item_place === null) {
				continue;
			}
			
			new_items_reservation_requests[id_reservation_request] = item;
			
			const { idx_start, idx_end } = this.convertCheckInOutToIdxs(dates, item.check_in, item.check_out);
			
			new_items_reservation_requests[id_reservation_request].idx_start = idx_start;
			new_items_reservation_requests[id_reservation_request].idx_end   = idx_end;
			
			for (let idx = idx_start; idx <= idx_end; idx++) {
				const vertical_idx = this.convertIdAccommodationItemPlaceToIdx(item.id_accommodation_item_place, id_accommodation_item_places_to_idxs);
				if (table_requests[vertical_idx] === undefined) continue;
				table_requests[vertical_idx][idx] = id_reservation_request;
			}
		}
		
		return {
			items: new_items,
			reservation_request_items: new_items_reservation_requests,
			table,
			table_requests,
		};
	}
	
	generateIdAccommodationItemPlaceIdxs(rooms, accommodation_item_places) {
		const id_accommodation_item_places_to_idxs = {};
		for (let i=0; i<rooms.length; i++) {
			id_accommodation_item_places_to_idxs[rooms[i].id_accommodation_item_place] = i;
		}
		return id_accommodation_item_places_to_idxs;
	}
	convertIdAccommodationItemPlaceToIdx(id_accommodation_item_place, id_accommodation_item_places_to_idxs) {
		id_accommodation_item_places_to_idxs = id_accommodation_item_places_to_idxs || this.state.id_accommodation_item_places_to_idxs;
		if (id_accommodation_item_places_to_idxs === undefined) return -1;
		return id_accommodation_item_places_to_idxs[id_accommodation_item_place];
	}
	convertIdxToIdAccommodationItemPlace(idx) {
		return this.props.rooms[idx].id_accommodation_item_place;
	}
	
	convertIdxToDate(dates, idx) {
		return dates[0][6]
			//.subtract(OFFSET_DAYS, 'days')
			.clone()
			.add(idx, 'days')
			.toDate();
	}
	
	translatePositionToIndexes(x, y, reservation_table) {
		const timeline_pos = this.timelineRef.current.getBoundingClientRect();
		let relative_x = x - timeline_pos.left;
		let relative_y = y - timeline_pos.top;
		
		relative_x -= this.left_side_width;
		relative_y -= this.header_height;
		
		let   hor_index            = Math.floor(relative_x / this.column_width);
		let   ver_index            = Math.floor(relative_y / this.row_height);
		const fractional_hor_index = Math.round(relative_x * 2.0 / this.column_width) / 2.0 - hor_index;
		
		if (ver_index < 0) {
			ver_index = 0;
		}
		else if (ver_index >= reservation_table.table.length) {
			ver_index = reservation_table.table.length - 1;
		}
		
		const horizontal_length = (reservation_table.table.length == 0 ? 1 : reservation_table.table[0].length);
		if (hor_index < 0) {
			hor_index = 0;
		}
		else if (hor_index >= horizontal_length) {
			hor_index = horizontal_length - 1;
		}
		
		return [ hor_index, ver_index, fractional_hor_index ];
	}
	
	findAllowedDragEndIndex(ver_index, start_index, target_end_index) {
		if (start_index <= target_end_index) {
			for (let hor_index = start_index + 1; hor_index <= target_end_index; hor_index++) {
				if (
					(
						this.state.reservation_table.table         [ver_index][hor_index] != -1 &&
						this.state.reservation_table.table         [ver_index][hor_index] != -2
					) ||
					(
						this.state.reservation_table.table_requests[ver_index][hor_index] != -1 &&
						this.state.reservation_table.table_requests[ver_index][hor_index] != -2
					)
				) {
					return hor_index;
				}
			}
		}
		else {
			for (let hor_index = start_index; hor_index >= target_end_index; hor_index--) {
				if (
					(
						this.state.reservation_table.table         [ver_index][hor_index] != -1 &&
						this.state.reservation_table.table         [ver_index][hor_index] != -2
					) ||
					(
						this.state.reservation_table.table_requests[ver_index][hor_index] != -1 &&
						this.state.reservation_table.table_requests[ver_index][hor_index] != -2
					)
				) {
					return hor_index;
				}
			}
		}
		
		return target_end_index;
	}
	
	getItemHoverState(id_reservation, current_hor_idx, current_fractional_hor_idx, items) {
		if (items[id_reservation] === undefined) {
			return [ HOVER_STATE.NONE, -1 ];
		}
		
		if (id_reservation != -1 && id_reservation != -2) {
			if (
				current_hor_idx == items[id_reservation].idx_start
				&&
				current_fractional_hor_idx == 0.5
			) {
				return [ HOVER_STATE.ITEM_START, id_reservation ];
			}
			else if (
				current_hor_idx == items[id_reservation].idx_end
				&&
				current_fractional_hor_idx == 0.5
			) {
				return [ HOVER_STATE.ITEM_END, id_reservation ];
			}
			else if (
				(
					current_hor_idx == items[id_reservation].idx_start
					&&
					current_fractional_hor_idx == 1
				)
				||
				(
					current_hor_idx == items[id_reservation].idx_end
					&&
					current_fractional_hor_idx == 0
				)
				||
				(
					current_hor_idx > items[id_reservation].idx_start
					&&
					current_hor_idx < items[id_reservation].idx_end
				)
			) {
				return [ HOVER_STATE.ITEM_MIDDLE, id_reservation ];
			}
			else if (current_hor_idx == items[id_reservation].idx_start) {
				return [ HOVER_STATE.ITEM_OUTSIDE_START, id_reservation ];
			}
			else {
				return [ HOVER_STATE.ITEM_OUTSIDE_END, id_reservation ];
			}
		}
		
		return [ HOVER_STATE.NONE, -1 ];
	}
	
	numberOfNights(count) {
		if (count == 2) {
			return count + ' nočitvi';
		}
		else if (count == 3 || count == 4) {
			return count + ' nočitve';
		}
		return count + ' nočitev';
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	fixDraggingReservationHorVerIdxs(
		dragging_id_reservation,
		current_hor_idx,
		current_ver_idx,
		hor_index,
		ver_index,
		reservation_new_idx_start,
		reservation_new_idx_end,
		change_type, // can be one of: move, start, end
	) {
		const retval = {
			new_hor_index: hor_index,
			new_ver_index: ver_index,
			valid:         true,
		};
		
		const reservation_customers    = this.props.reservation_customers_by_id_reservations[dragging_id_reservation] || {};
		const id_reservation_customers = Object.keys(reservation_customers);
		
		if (id_reservation_customers.length > 0) {
			// only allow vertical movement for reservations with customers
			if (change_type == 'move' && retval.new_hor_index != current_hor_idx) {
				retval.new_hor_index = current_hor_idx;
				retval.valid         = false;
			}
			
			// only allow movement in the same idRNO
			const old_id_rno = this.props.accommodations[
				this.props.accommodation_items[
					this.props.accommodation_item_places[
						this.state.reservation_table.items[
							dragging_id_reservation
						].id_accommodation_item_place
					].id_accommodation_item
				].id_accommodation
			].etourism_id_rno;
			
			const new_id_rno = this.props.accommodations[
				this.props.accommodation_items[
					this.props.rooms[ver_index].id_accommodation_item
				].id_accommodation
			].etourism_id_rno;
			
			if (old_id_rno != new_id_rno) {
				retval.new_ver_index = current_ver_idx;
				retval.valid         = false;
			}
		}
		
		const idx_start = reservation_new_idx_start;
		const idx_end   = reservation_new_idx_end;
		
		let all_empty = true;
		for (let i=idx_start; i<=idx_end; i++) {
			if (
				(
					this.state.reservation_table.table[ver_index][i] == -1 ||
					this.state.reservation_table.table[ver_index][i] == dragging_id_reservation
				)
				&&
				(
					this.state.reservation_table.table_requests[ver_index][i] == -1 ||
					this.state.reservation_table.table_requests[ver_index][i] == dragging_id_reservation
				)
			) {
				continue;
			}
			
			const current_item_hover_state = this.getItemHoverState(
				this.state.reservation_table.table[ver_index][i],
				i,
				i == idx_start ? 1 : (i == idx_end ? 0 : 0.5),
				this.state.reservation_table.items
			);
			const current_item_hover_state_requests = this.getItemHoverState(
				this.state.reservation_table.table_requests[ver_index][i],
				i,
				i == idx_start ? 1 : (i == idx_end ? 0 : 0.5),
				this.state.reservation_table.reservation_request_items
			);
			
			if (
				current_item_hover_state[0]          != HOVER_STATE.ITEM_OUTSIDE_START &&
				current_item_hover_state[0]          != HOVER_STATE.ITEM_OUTSIDE_END   &&
				current_item_hover_state[0]          != HOVER_STATE.NONE
			) {
				all_empty = false;
				break;
			}
			if (
				current_item_hover_state_requests[0] != HOVER_STATE.ITEM_OUTSIDE_START &&
				current_item_hover_state_requests[0] != HOVER_STATE.ITEM_OUTSIDE_END   &&
				current_item_hover_state_requests[0] != HOVER_STATE.NONE
			) {
				all_empty = false;
				break;
			}
		}
		
		if (!all_empty) {
			retval.new_hor_index = current_hor_idx;
			retval.new_ver_index = current_ver_idx;
			retval.valid         = false;
		}
		
		return retval;
	}
	
	async setDirtyState(id_accommodation_item_place, state) {
		const item = {
			...this.props.accommodation_item_places[id_accommodation_item_place],
			state,
		};
		
		this.props.dispatch(addAccommodationItemPlace({
			item,
			token: this.props.token,
		}));
		
		const new_items = await saveAccommodationItemPlace(this.props.api_url, item, this.props.token);
		if (new_items !== null) {
			this.props.dispatch(addAccommodationItemPlace({
				item,
				token: this.props.token,
			}));
		}
	}
	
	showTooltip(item, id_reservation) {
		this.tooltip_id_reservation = id_reservation;
		setTimeout(() => {
			if (this.tooltip_id_reservation != id_reservation) return;
			
			this.setState({
				tooltip_open:           true,
				tooltip_hidden:         false,
				tooltip_horizontal_idx: (item.idx_start + item.idx_end) / 2,
				tooltip_vertical_idx:   this.convertIdAccommodationItemPlaceToIdx(item.id_accommodation_item_place) + 1,
				tooltip_id_reservation: id_reservation,
				new_rect_visible:       false,
			});
		}, 500);
	}
	hideTooltip() {
		this.tooltip_id_reservation = -1;
		this.setState({
			tooltip_open:           false,
			tooltip_id_reservation: -1,
		});
	}
	
	changeCaseIfNeeded(value) {
		if (this.props.general_settings.scanner_change_case == 'uppercase') {
			return value.toUpperCase();
		}
		if (this.props.general_settings.scanner_change_case == 'lowercase') {
			return value.toLowerCase();
		}
		if (this.props.general_settings.scanner_change_case == 'title-case') {
			// from https://stackoverflow.com/a/37931321
			return value.replace(/\S+/g, str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase());
		}
		return value;
	}
	
	render() {
		const rows = this.props.rooms;
		
		const new_rect_visible = this.state.new_rect_visible &&
			this.state.new_rect_position.hor_index >= 0 && this.state.new_rect_position.hor_index <= (this.timeline_width - this.left_side_width) / this.column_width &&
			this.state.new_rect_position.ver_index >= 0 && this.state.new_rect_position.ver_index <= (this.timeline_height - this.header_height) / this.row_height;
		
		const new_drag_hor_index_start = Math.max(0, this.state.new_drag_hor_index_start);
		const new_drag_hor_index_end   = Math.max(0, this.state.new_drag_hor_index_end);
		
		const drag_rect_visible = this.state.new_dragging;
		
		const item_hover_state = this.getItemHoverState(
			this.state.current_id_reservation,
			this.state.current_hor_idx,
			this.state.current_fractional_hor_idx,
			this.state.reservation_table.items
		);
		let cursor = 'default';
		if (item_hover_state[0] == HOVER_STATE.ITEM_START || item_hover_state[0] == HOVER_STATE.ITEM_END) {
			cursor = 'ew-resize';
		}
		else if (item_hover_state[0] == HOVER_STATE.ITEM_MIDDLE) {
			cursor = 'grab';
		}
		
		const timelineRect = this.timelineRef.current === null ? { x: 0, y: 0 } : this.timelineRef.current.getBoundingClientRect();
		
		return <div style={{ left: 0, right: 0, top: 0, bottom: 0, position: 'absolute' }}>
			<Overlay2 isOpen={true} hasBackdrop={false} enforceFocus={false} autoFocus={false}>
				<div style={{
					left: Math.max(
						250 /* left navigation width */ + this.left_side_width,
						Math.min(
							timelineRect.x + this.state.tooltip_horizontal_idx * this.column_width +
								this.left_side_width +
								this.column_width / 2 -
								480 /* tooltip width */ / 2,
							window.innerWidth - 480 /* tooltip width */
						)
					),
					top: timelineRect.y + this.state.tooltip_vertical_idx * this.row_height + this.header_height,
					pointerEvents: 'none',
				}}>
					<TimelineTooltip
						tooltip_open=          {this.state.tooltip_open}
						tooltip_hidden=        {this.state.tooltip_hidden}
						animation_ended=       {() => {
							if (!this.state.tooltip_open) {
								this.setState({ tooltip_hidden: true });
							}
						}}
						reservation={
							this.state.tooltip_id_reservation == -1 ? null :
								this.state.reservation_table.items[this.state.tooltip_id_reservation]
						}
						accommodation_item_place={
							this.state.tooltip_id_reservation == -1 ? null :
								this.state.reservation_table.items[this.state.tooltip_id_reservation] === undefined ? null :
									this.props.accommodation_item_places[
										this.state.reservation_table.items[this.state.tooltip_id_reservation].id_accommodation_item_place
									]
						}
						channels={this.props.channels}
						customers={this.props.customers}
						general_settings={this.props.general_settings} />
				</div>
			</Overlay2>
			
			<div
				style={{ left: 0, right: 0, top: 0, bottom: 0, position: 'absolute' }}
				className='timeline overflow-auto'
				ref={this.timelineContainerRef}
				onScroll={() => {
					if (this.scroll_end_timer !== undefined && this.scroll_end_timer !== null) clearTimeout(this.scroll_end_timer);
					this.scroll_end_timer = setTimeout(() => {
						this.forceRerenderWithoutThrottling();
						this.scroll_end_timer = null;
					}, 200);
					this.forceRerender();
				}}
				onMouseLeave={() => {
					this.hideTooltip();
				}}>
				<div style={{ position: 'absolute', left: 0, top: 0, width: this.timeline_width, height: this.timeline_height + this.header_height }}>
					<svg viewBox={'0 0 ' + this.timeline_width + ' ' + (this.timeline_height + this.header_height)} style={{ backgroundColor: '#ffffff' }}>
						<defs>
							<pattern id='pattern-diagonal-stripes' viewBox='0,0,40,40' width='18' height='18' patternUnits='userSpaceOnUse'>
								<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.1'>
									<g fill='#000000'>
										<polygon points='0 40 40 0 20 0 0 20'></polygon>
										<polygon points='40 40 40 20 20 40'></polygon>
									</g>
								</g>
							</pattern>
							<pattern id='pattern-polka-dots' viewBox='0,0,20,20' width='18' height='18' patternUnits='userSpaceOnUse'>
								<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.1'>
									<g fill='#000000'>
										<circle cx='3' cy='3' r='3'></circle>
										<circle cx='13' cy='13' r='3'></circle>
									</g>
								</g>
							</pattern>
							<pattern id='pattern-hideout' viewBox='0,0,40,40' width='36' height='36' patternUnits='userSpaceOnUse'>
								<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.2'>
									<g fill='#000000'>
										<path d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z' />
									</g>
								</g>
							</pattern>
							
							<linearGradient id='fadeGrad' y2='0' x2='1'>
								<stop offset='0' stopColor='white' stopOpacity='1'/>
								<stop offset='0.8' stopColor='white' stopOpacity='1'/>
								<stop offset='1' stopColor='white' stopOpacity='0'/>
							</linearGradient>
							<mask id='fade' maskContentUnits='objectBoundingBox'>
								<rect width='1' height='1' fill='url(#fadeGrad)'/>
							</mask>
							
							<clipPath id='reservation-bar-corner-triangle-clip'>
								<path d='M 0,0 L 20,0 L 0,20 L 0,0 z' fill='#000000'/>
							</clipPath>
							
							<filter id='timeline-tooltip-shadow'>
								<feDropShadow dx='0' dy='3' stdDeviation='5' floodOpacity='0.6' />
							</filter>
							
							<filter id='reservation-bar-dragging' x='-5%' y='-5%' width='110%' height='110%'>
								<feGaussianBlur in='SourceGraphic' stdDeviation='1' />
							</filter>
						</defs>
						
						<HorizontalLines
							header_height={this.header_height}
							timeline_width={this.timeline_width}
							row_height={this.row_height}
							rows={rows}
							/>
						
						<VerticalLines
							left_side_width={this.left_side_width}
							timeline_width={this.timeline_width}
							timeline_height={this.timeline_height + this.header_height}
							column_width={this.column_width}
							header_height={this.header_height}
							dates={this.state.dates}
							/>
						
						{!new_rect_visible || drag_rect_visible ?
							null :
							<NewReservationIndicator
								column_width={this.column_width}
								left_side_width={this.left_side_width}
								row_height={this.row_height}
								header_height={this.header_height}
								new_rect_position={this.state.new_rect_position}
								/>
						}
						
						{!drag_rect_visible ?
							null :
							<ReservationBar
								left_offset= {this.left_side_width}
								top_offset=  {this.header_height}
								column_width={this.column_width}
								row_height=  {this.row_height}
								vertical_idx={this.state.new_drag_ver_index}
								idx_start=   {new_drag_hor_index_start}
								idx_end=     {new_drag_hor_index_end}
								status=      {STATUSES.DRAGGING}
								channel=     {''}
								title=       {this.numberOfNights(
									Math.abs(new_drag_hor_index_end - new_drag_hor_index_start)
								)}
								on_click=    {null}
								dragging=    {false}
								guests_checked_in={false}
								/>
						}
						
						{Object.values(this.state.reservation_table.items).map((item, idx) => {
							if (item.id_reservation == this.state.dragging_id_reservation ||
								item.id_reservation == this.state.dragging_id_reservation_start ||
								item.id_reservation == this.state.dragging_id_reservation_end ||
								item.id_reservation == this.state.request_move_id_reservation ||
								item.id_reservation == this.state.request_move_id_reservation_start ||
								item.id_reservation == this.state.request_move_id_reservation_end) {
								return;
							}
							
							if (this.timelineContainerRef.current === null) return null;
							
							const idx_start = item.idx_start;
							const idx_end   = item.idx_end;
							
							if (idx_start === undefined || idx_end === undefined) return null;
							
							const container = this.timelineContainerRef.current;
							
							// hide reservations not currently visible
							const first_hor_visible_idx = Math.round((container.scrollLeft - this.left_side_width                        ) / this.column_width) - 1;
							const last_hor_visible_idx  = Math.round((container.scrollLeft - this.left_side_width + container.clientWidth) / this.column_width) + 1;
							
							if (idx_start > last_hor_visible_idx || idx_end < first_hor_visible_idx) return null;
							
							const vertical_idx = this.convertIdAccommodationItemPlaceToIdx(item.id_accommodation_item_place);
							if (vertical_idx === undefined) return null;
							
							// hide reservations not currently visible
							const first_vert_visible_idx = Math.round(container.scrollTop                            / this.row_height) - 1;
							const last_vert_visible_idx  = Math.round((container.scrollTop + container.clientHeight) / this.row_height) + 1;
							
							if (vertical_idx < first_vert_visible_idx || vertical_idx > last_vert_visible_idx) return null;
							
							return <ReservationBar
								key={'reservation-bar-' + idx}
								left_offset= {this.left_side_width}
								top_offset=  {this.header_height}
								column_width={this.column_width}
								row_height=  {this.row_height}
								vertical_idx={vertical_idx}
								idx_start=   {idx_start}
								idx_end=     {idx_end}
								status=      {this.props.deletingReservations ? 'deleting' : item.status}
								channel=     {this.props.channels[item.id_channel].internal_code.toUpperCase()}
								guest_count= {item.guest_adult_count + item.guest_child_1_count + item.guest_child_2_count}
								title=       {
									this.props.customers[item.id_customer] === undefined ? '' :
										(this.props.customers[item.id_customer].type == 'natural' ?
											this.props.customers[item.id_customer].surname :
											this.props.customers[item.id_customer].company_name
										)
								}
								status_2={item.status_2}
								is_mouse_over={
									item_hover_state[1] == idx
									&&
									item_hover_state[0] == HOVER_STATE.ITEM_MIDDLE
									&&
									!this.state.new_dragging
								}
								dragging=    {false}
								guests_checked_in={item.guests_checked_in}
								/>;
						})}
						
						{Object.values(this.state.reservation_table.reservation_request_items).map((item, idx) => {
							/*if (item.id_reservation == this.state.dragging_id_reservation ||
								item.id_reservation == this.state.dragging_id_reservation_start ||
								item.id_reservation == this.state.dragging_id_reservation_end ||
								item.id_reservation == this.state.request_move_id_reservation ||
								item.id_reservation == this.state.request_move_id_reservation_start ||
								item.id_reservation == this.state.request_move_id_reservation_end) {
								return;
							}*/
							
							if (this.timelineContainerRef.current === null) return null;
							
							const idx_start = item.idx_start;
							const idx_end   = item.idx_end;
							
							if (idx_start === undefined || idx_end === undefined) return null;
							
							const container = this.timelineContainerRef.current;
							
							// hide reservations not currently visible
							const first_hor_visible_idx = Math.round((container.scrollLeft - this.left_side_width                        ) / this.column_width) - 1;
							const last_hor_visible_idx  = Math.round((container.scrollLeft - this.left_side_width + container.clientWidth) / this.column_width) + 1;
							
							if (idx_start > last_hor_visible_idx || idx_end < first_hor_visible_idx) return null;
							
							const vertical_idx = this.convertIdAccommodationItemPlaceToIdx(item.id_accommodation_item_place);
							if (vertical_idx === undefined) return null;
							
							// hide reservations not currently visible
							const first_vert_visible_idx = Math.round(container.scrollTop                            / this.row_height) - 1;
							const last_vert_visible_idx  = Math.round((container.scrollTop + container.clientHeight) / this.row_height) + 1;
							
							if (vertical_idx < first_vert_visible_idx || vertical_idx > last_vert_visible_idx) return null;
							
							return <ReservationRequestBar
								key={'reservation-request-bar-' + idx}
								left_offset= {this.left_side_width}
								top_offset=  {this.header_height}
								column_width={this.column_width}
								row_height=  {this.row_height}
								vertical_idx={vertical_idx}
								idx_start=   {idx_start}
								idx_end=     {idx_end}
								status=      {item.status}
								guest_count= {item.guest_adult_count + item.guest_child_1_count + item.guest_child_2_count}
								title=       {
									this.changeCaseIfNeeded(item.customer_surname)
								}
								is_mouse_over={
									item_hover_state[1] == idx
									&&
									item_hover_state[0] == HOVER_STATE.ITEM_MIDDLE
									&&
									!this.state.new_dragging
								}
								dragging=    {false}
								/>;
						})}
						
						{this.state.dragging_id_reservation == -1 ?
							null :
							<ReservationBar
								left_offset= {this.left_side_width}
								top_offset=  {this.header_height}
								column_width={this.column_width}
								row_height=  {this.row_height}
								vertical_idx={this.state.current_ver_idx}
								idx_start=   {this.state.current_hor_idx - this.state.dragging_id_reservation_offset}
								idx_end=     {
									this.state.current_hor_idx -
									this.state.dragging_id_reservation_offset +
									(
										this.state.reservation_table.items[this.state.dragging_id_reservation].idx_end -
										this.state.reservation_table.items[this.state.dragging_id_reservation].idx_start
									)
								}
								status=      {this.state.dragging_id_reservation_valid ?
									this.state.reservation_table.items[this.state.dragging_id_reservation].status
									:
									'invalid'
								}
								channel=     {''}
								title=       {this.state.dragging_id_reservation_valid ?
									this.numberOfNights(
										this.state.current_hor_idx -
										this.state.dragging_id_reservation_offset +
										(
											this.state.reservation_table.items[this.state.dragging_id_reservation].idx_end -
											this.state.reservation_table.items[this.state.dragging_id_reservation].idx_start
										)
										-
										(this.state.current_hor_idx - this.state.dragging_id_reservation_offset)
									)
									:
									'neveljavno'
								}
								status_2={this.state.reservation_table.items[this.state.dragging_id_reservation].status_2}
								on_click=    {null}
								dragging=    {true}
								guests_checked_in={this.state.reservation_table.items[this.state.dragging_id_reservation].guests_checked_in}
								/>
						}
						{this.state.request_move_id_reservation == -1 ?
							null :
							<ReservationBar
								left_offset= {this.left_side_width}
								top_offset=  {this.header_height}
								column_width={this.column_width}
								row_height=  {this.row_height}
								vertical_idx={this.state.current_ver_idx}
								idx_start=   {this.state.current_hor_idx - this.state.request_move_id_reservation_offset}
								idx_end=     {
									this.state.current_hor_idx -
									this.state.request_move_id_reservation_offset +
									(
										this.state.reservation_table.items[this.state.request_move_id_reservation].idx_end -
										this.state.reservation_table.items[this.state.request_move_id_reservation].idx_start
									)
								}
								status=      {this.state.reservation_table.items[this.state.request_move_id_reservation].status}
								channel=     {''}
								title=       {this.numberOfNights(
									this.state.current_hor_idx -
									this.state.request_move_id_reservation_offset +
									(
										this.state.reservation_table.items[this.state.request_move_id_reservation].idx_end -
										this.state.reservation_table.items[this.state.request_move_id_reservation].idx_start
									)
									-
									(this.state.current_hor_idx - this.state.request_move_id_reservation_offset)
								)}
								status_2={this.state.reservation_table.items[this.state.request_move_id_reservation].status_2}
								on_click=    {null}
								dragging=    {true}
								guests_checked_in={this.state.reservation_table.items[this.state.request_move_id_reservation].guests_checked_in}
								/>
						}
						{this.state.dragging_id_reservation_start == -1 ?
							null :
							<ReservationBar
								left_offset= {this.left_side_width}
								top_offset=  {this.header_height}
								column_width={this.column_width}
								row_height=  {this.row_height}
								vertical_idx={this.convertIdAccommodationItemPlaceToIdx(this.state.reservation_table.items[this.state.dragging_id_reservation_start].id_accommodation_item_place)}
								idx_start=   {this.state.current_hor_idx}
								idx_end=     {this.state.reservation_table.items[this.state.dragging_id_reservation_start].idx_end}
								status=      {this.state.dragging_id_reservation_valid ?
									this.state.reservation_table.items[this.state.dragging_id_reservation_start].status
									:
									'invalid'
								}
								channel=     {''}
								title=       {this.state.dragging_id_reservation_valid ?
									this.numberOfNights(
										Math.max(1, Math.abs(this.state.reservation_table.items[this.state.dragging_id_reservation_start].idx_end - this.state.current_hor_idx))
									)
									:
									'neveljavno'
								}
								status_2={this.state.reservation_table.items[this.state.dragging_id_reservation_start].status_2}
								on_click=    {null}
								dragging=    {true}
								guests_checked_in={this.state.reservation_table.items[this.state.dragging_id_reservation_start].guests_checked_in}
								/>
						}
						{this.state.request_move_id_reservation_start == -1 ?
							null :
							<ReservationBar
								left_offset= {this.left_side_width}
								top_offset=  {this.header_height}
								column_width={this.column_width}
								row_height=  {this.row_height}
								vertical_idx={this.convertIdAccommodationItemPlaceToIdx(this.state.reservation_table.items[this.state.request_move_id_reservation_start].id_accommodation_item_place)}
								idx_start=   {this.state.current_hor_idx}
								idx_end=     {this.state.reservation_table.items[this.state.request_move_id_reservation_start].idx_end}
								status=      {this.state.reservation_table.items[this.state.request_move_id_reservation_start].status}
								channel=     {''}
								title=       {this.numberOfNights(
									Math.max(1, Math.abs(this.state.reservation_table.items[this.state.request_move_id_reservation_start].idx_end - this.state.current_hor_idx))
								)}
								status_2={this.state.reservation_table.items[this.state.request_move_id_reservation_start].status_2}
								on_click=    {null}
								dragging=    {true}
								guests_checked_in={this.state.reservation_table.items[this.state.request_move_id_reservation_start].guests_checked_in}
								/>
						}
						{this.state.dragging_id_reservation_end == -1 ?
							null :
							<ReservationBar
								left_offset= {this.left_side_width}
								top_offset=  {this.header_height}
								column_width={this.column_width}
								row_height=  {this.row_height}
								vertical_idx={this.convertIdAccommodationItemPlaceToIdx(this.state.reservation_table.items[this.state.dragging_id_reservation_end].id_accommodation_item_place)}
								idx_start=   {this.state.reservation_table.items[this.state.dragging_id_reservation_end].idx_start}
								idx_end=     {this.state.current_hor_idx}
								status=      {this.state.dragging_id_reservation_valid ?
									this.state.reservation_table.items[this.state.dragging_id_reservation_end].status
									:
									'invalid'
								}
								channel=     {''}
								title=       {this.state.dragging_id_reservation_valid ?
									this.numberOfNights(
										Math.max(1, Math.abs(this.state.current_hor_idx - this.state.reservation_table.items[this.state.dragging_id_reservation_end].idx_start))
									)
									:
									'neveljavno'
								}
								status_2={this.state.reservation_table.items[this.state.dragging_id_reservation_end].status_2}
								on_click=    {null}
								dragging=    {true}
								guests_checked_in={this.state.reservation_table.items[this.state.dragging_id_reservation_end].guests_checked_in}
								/>
						}
						{this.state.request_move_id_reservation_end == -1 ?
							null :
							<ReservationBar
								left_offset= {this.left_side_width}
								top_offset=  {this.header_height}
								column_width={this.column_width}
								row_height=  {this.row_height}
								vertical_idx={this.convertIdAccommodationItemPlaceToIdx(this.state.reservation_table.items[this.state.request_move_id_reservation_end].id_accommodation_item_place)}
								idx_start=   {this.state.reservation_table.items[this.state.request_move_id_reservation_end].idx_start}
								idx_end=     {this.state.current_hor_idx}
								status=      {this.state.reservation_table.items[this.state.request_move_id_reservation_end].status}
								channel=     {''}
								title=       {this.numberOfNights(
									Math.max(1, Math.abs(this.state.current_hor_idx - this.state.reservation_table.items[this.state.request_move_id_reservation_end].idx_start))
								)}
								status_2={this.state.reservation_table.items[this.state.request_move_id_reservation_end].status_2}
								on_click=    {null}
								dragging=    {true}
								guests_checked_in={this.state.reservation_table.items[this.state.request_move_id_reservation_end].guests_checked_in}
								/>
						}
						
						<rect
							ref={this.timelineRef}
							x='0'
							y='0'
							width={this.timeline_width}
							height={this.timeline_height + this.header_height}
							fill='#ffffff'
							opacity='0'
							style={{
								cursor,
							}}
							onMouseMove={(e) => {
								const indexes = this.translatePositionToIndexes(e.pageX, e.pageY, this.state.reservation_table);
								
								let   hor_index            = indexes[0];
								let   ver_index            = indexes[1];
								const fractional_hor_index = indexes[2];
								
								if (this.state.reservation_table.table[ver_index] === undefined ||
									this.state.reservation_table.table[ver_index][hor_index] === undefined) {
									return;
								}
								
								const current_item_hover_state = this.getItemHoverState(
									this.state.reservation_table.table[ver_index][hor_index],
									hor_index,
									fractional_hor_index,
									this.state.reservation_table.items
								);
								const current_item_hover_state_requests = this.getItemHoverState(
									this.state.reservation_table.table_requests[ver_index][hor_index],
									hor_index,
									fractional_hor_index,
									this.state.reservation_table.reservation_request_items
								);
								
								if (this.state.dragging_id_reservation != -1) {
									const { new_hor_index, new_ver_index, valid } = this.fixDraggingReservationHorVerIdxs(
										this.state.dragging_id_reservation,
										this.state.current_hor_idx,
										this.state.current_ver_idx,
										hor_index,
										ver_index,
										hor_index - this.state.dragging_id_reservation_offset,
										hor_index -
											this.state.dragging_id_reservation_offset +
											(
												this.state.reservation_table.items[this.state.dragging_id_reservation].idx_end -
												this.state.reservation_table.items[this.state.dragging_id_reservation].idx_start
											),
										'move',
									);
									
									hor_index = new_hor_index;
									ver_index = new_ver_index;
									
									this.setState({ dragging_id_reservation_valid: valid });
								}
								else if (this.state.dragging_id_reservation_start != -1) {
									const { new_hor_index, new_ver_index, valid } = this.fixDraggingReservationHorVerIdxs(
										this.state.dragging_id_reservation_start,
										this.state.current_hor_idx,
										this.state.current_ver_idx,
										hor_index,
										ver_index,
										this.state.current_hor_idx,
										this.state.reservation_table.items[this.state.dragging_id_reservation_start].idx_end,
										'start',
									);
									this.setState({ dragging_id_reservation_valid: valid });
								}
								else if (this.state.dragging_id_reservation_end != -1) {
									const { new_hor_index, new_ver_index, valid } = this.fixDraggingReservationHorVerIdxs(
										this.state.dragging_id_reservation_end,
										this.state.current_hor_idx,
										this.state.current_ver_idx,
										hor_index,
										ver_index,
										this.state.reservation_table.items[this.state.dragging_id_reservation_end].idx_start,
										this.state.current_hor_idx,
										'end',
									);
									this.setState({ dragging_id_reservation_valid: valid });
								}
								
								if (this.state.current_hor_idx            != hor_index ||
									this.state.current_ver_idx            != ver_index ||
									this.state.current_fractional_hor_idx != fractional_hor_index) {
									this.setState({
										current_hor_idx:            hor_index,
										current_ver_idx:            ver_index,
										current_fractional_hor_idx: fractional_hor_index,
									});
									
									if ((this.state.dragging_id_reservation != -1 && this.state.dragging_id_reservation != -2) ||
										(this.state.dragging_id_reservation_start != -1 && this.state.dragging_id_reservation_start != -2) ||
										(this.state.dragging_id_reservation_end != -1 && this.state.dragging_id_reservation_end != -2)) {
										this.hideTooltip();
									}
								}
								
								const id_reservation = this.state.reservation_table.table[ver_index][hor_index];
								if (this.state.current_id_reservation != id_reservation) {
									this.setState({
										current_id_reservation: id_reservation,
									});
								}
								
								if (!this.state.new_dragging &&
									this.state.dragging_id_reservation == -1 &&
									this.state.dragging_id_reservation_start == -1 &&
									this.state.dragging_id_reservation_end == -1) {
									if (current_item_hover_state[0] == HOVER_STATE.NONE ||
										current_item_hover_state[0] == HOVER_STATE.ITEM_OUTSIDE_END) {
										this.hideTooltip();
										this.setState({
											new_rect_visible: true,
											//new_dragging: false,
										});
									}
									else if (current_item_hover_state[0] == HOVER_STATE.ITEM_MIDDLE) {
										const item = this.state.reservation_table.items[id_reservation];
										this.showTooltip(item, id_reservation);
									}
								}
								
								//hor_index = Math.max(0, hor_index);
								//ver_index = Math.max(0, ver_index);
								
								//var x = hor_index * this.column_width;
								//var y = ver_index * this.row_height;
								
								if (this.state.new_rect_position.hor_index != hor_index || this.state.new_rect_position.ver_index != ver_index)
								{
									let new_rect_visible = (current_item_hover_state[0] == HOVER_STATE.NONE);
									//if ( id_reservation > -1) {
									//	const item = this.state.reservation_table.items[id_reservation];
									//	new_rect_visible = (hor_index == item.idx_end);
									//}
									
									this.setState({
										new_rect_position: { hor_index, ver_index },
										new_rect_visible,
									});
								}
								
								if (this.state.new_drag_hor_index_end != hor_index)
								{
									const allowed_drag_end_index = this.findAllowedDragEndIndex(
										ver_index,
										this.state.new_drag_hor_index_start,
										hor_index
									);
									
									this.setState({
										new_drag_hor_index_end: allowed_drag_end_index,
									});
								}
							}}
							onClick={(e) => {
								//if (new_rect_visible) {
								//	if (this.props.openReservation !== undefined) {
								//		this.props.openReservation();
								//	}
								//}
							}}
							onMouseDown={(e) => {
								const indexes = this.translatePositionToIndexes(e.pageX, e.pageY, this.state.reservation_table);
								
								const hor_index            = indexes[0];
								const ver_index            = indexes[1];
								const fractional_hor_index = indexes[2];
								
								const current_item_hover_state = this.getItemHoverState(
									this.state.reservation_table.table[ver_index][hor_index],
									hor_index,
									fractional_hor_index,
									this.state.reservation_table.items
								);
								const current_item_hover_state_requests = this.getItemHoverState(
									this.state.reservation_table.table_requests[ver_index][hor_index],
									hor_index,
									fractional_hor_index,
									this.state.reservation_table.reservation_request_items
								);
								
								this.setState({
									mouse_down_hor_idx: hor_index,
									mouse_down_ver_idx: ver_index,
								});
								
								if (
									current_item_hover_state_requests[0] == HOVER_STATE.ITEM_START ||
									current_item_hover_state_requests[0] == HOVER_STATE.ITEM_MIDDLE ||
									current_item_hover_state_requests[0] == HOVER_STATE.ITEM_END
								) return;
								
								let allowed_to_drag = true;
								if (current_item_hover_state[1] != -1 && current_item_hover_state[1] != -2) {
									allowed_to_drag = (
										current_item_hover_state[0] == HOVER_STATE.NONE ||
										current_item_hover_state[0] == HOVER_STATE.ITEM_OUTSIDE_END
									);
								}
								
								if (allowed_to_drag) {
									this.setState({
										new_dragging:             true,
										new_drag_hor_index_start: hor_index,
										new_drag_hor_index_end:   hor_index,
										new_drag_ver_index:       ver_index,
									});
								}
								
								if (current_item_hover_state[1] != -1 && current_item_hover_state[1] != -2) {
									const item = this.state.reservation_table.items[current_item_hover_state[1]];
									
									if (current_item_hover_state[0] == HOVER_STATE.ITEM_MIDDLE) {
										this.setState({
											dragging_id_reservation:           current_item_hover_state[1],
											dragging_id_reservation_offset:    hor_index - item.idx_start,
											dragging_id_reservation_hor_index: hor_index,
											dragging_id_reservation_ver_index: ver_index,
											dragging_id_reservation_valid:     true,
										});
									}
									else if (current_item_hover_state[0] == HOVER_STATE.ITEM_START) {
										const id_reservation           = current_item_hover_state[1];
										const reservation_customers    = this.props.reservation_customers_by_id_reservations[id_reservation] || {};
										const id_reservation_customers = Object.keys(reservation_customers);
										
										if (id_reservation_customers.length == 0) {
											this.setState({
												dragging_id_reservation_start: current_item_hover_state[1],
												dragging_id_reservation_offset: 0,
												dragging_id_reservation_hor_index: hor_index,
												dragging_id_reservation_ver_index: ver_index,
											});
										}
									}
									else if (current_item_hover_state[0] == HOVER_STATE.ITEM_END) {
										this.setState({
											dragging_id_reservation_end: current_item_hover_state[1],
											dragging_id_reservation_offset: item.idx_end - item.idx_start,
											dragging_id_reservation_hor_index: hor_index,
											dragging_id_reservation_ver_index: ver_index,
										});
									}
								}
							}}
							onMouseUp={(e) => {
								const indexes = this.translatePositionToIndexes(e.pageX, e.pageY, this.state.reservation_table);
								
								const hor_index            = indexes[0];
								const ver_index            = indexes[1];
								const fractional_hor_index = indexes[2];
								
								const current_item_hover_state = this.getItemHoverState(
									this.state.reservation_table.table[ver_index][hor_index],
									hor_index,
									fractional_hor_index,
									this.state.reservation_table.items
								);
								
								// check if we should interpret this as a click
								if (hor_index == this.state.mouse_down_hor_idx &&
									ver_index == this.state.mouse_down_ver_idx) {
									this.setState({
										new_dragging:                  false,
										dragging_id_reservation:       -1,
										dragging_id_reservation_start: -1,
										dragging_id_reservation_end:   -1,
									});
									
									if (
										current_item_hover_state[1] != -1 &&
										current_item_hover_state[1] != -2 &&
										current_item_hover_state[0] == HOVER_STATE.ITEM_MIDDLE
									) {
										const item = this.state.reservation_table.items[this.state.current_id_reservation];
										
										if (item !== undefined) {
											if (this.props.deletingReservations) {
												this.props.deleteReservation(item.id_reservation);
											}
											else {
												if (this.props.openReservation !== undefined) {
													this.props.openReservation(item);
												}
											}
										}
									}
									
									return;
								}
								
								if (this.state.new_dragging) {
									this.setState({
										new_dragging: false,
									});
									
									const start_date = this.convertIdxToDate(
										this.state.dates,
										Math.min(this.state.new_drag_hor_index_start, this.state.new_drag_hor_index_end)
									);
									const end_date = this.convertIdxToDate(
										this.state.dates,
										Math.max(this.state.new_drag_hor_index_start, this.state.new_drag_hor_index_end)
									);
									
									if (this.props.openReservation !== undefined) {
										this.props.openReservation({
											id_reservation:              -1,
											id_accommodation_item_place: this.convertIdxToIdAccommodationItemPlace(ver_index),
											internal_code:               '',
											id_customer:                 -1,
											check_in:                    start_date,
											check_out:                   end_date,
											guest_adult_count:           0,
											guest_child_1_count:         0,
											guest_child_2_count:         0,
											status:                      'new',
											status_2:                    null,
											id_channel:                  -1,
											custom_status:               '',
											deleted:                     false,
											items:                       {},
										});
									}
								}
								
								if (this.state.dragging_id_reservation != -1) {
									const { new_hor_index, new_ver_index, valid } = this.fixDraggingReservationHorVerIdxs(
										this.state.dragging_id_reservation,
										this.state.current_hor_idx,
										this.state.current_ver_idx,
										hor_index,
										ver_index,
										hor_index - this.state.dragging_id_reservation_offset,
										hor_index -
											this.state.dragging_id_reservation_offset +
											(
												this.state.reservation_table.items[this.state.dragging_id_reservation].idx_end -
												this.state.reservation_table.items[this.state.dragging_id_reservation].idx_start
											),
										'move',
									);
									
									if (
										(	new_hor_index == this.state.dragging_id_reservation_hor_index &&
											new_ver_index == this.state.dragging_id_reservation_ver_index
										) ||
										!valid
									) {
										this.setState({
											dragging_id_reservation: -1,
										});
									}
									else {
										this.setState({
											dragging_id_reservation: -1,
											
											request_move_id_reservation: this.state.dragging_id_reservation,
											request_move_id_reservation_start: -1,
											request_move_id_reservation_end: -1,
											request_move_id_reservation_offset: this.state.dragging_id_reservation_offset,
											request_move_id_reservation_ver_index: new_ver_index,
											request_move_id_reservation_hor_index: new_hor_index,
										});
									}
								}
								else if (this.state.dragging_id_reservation_start != -1) {
									const { new_hor_index, new_ver_index, valid } = this.fixDraggingReservationHorVerIdxs(
										this.state.dragging_id_reservation_start,
										this.state.current_hor_idx,
										this.state.current_ver_idx,
										hor_index,
										ver_index,
										this.state.current_hor_idx,
										this.state.reservation_table.items[this.state.dragging_id_reservation_start].idx_end,
										'start',
									);
									
									if (
										(	new_hor_index == this.state.dragging_id_reservation_hor_index &&
											new_ver_index == this.state.dragging_id_reservation_ver_index
										) ||
										!valid
									) {
										this.setState({
											dragging_id_reservation_start: -1,
										});
									}
									else {
										this.setState({
											dragging_id_reservation_start: -1,
											
											request_move_id_reservation: -1,
											request_move_id_reservation_start: this.state.dragging_id_reservation_start,
											request_move_id_reservation_end: -1,
											request_move_id_reservation_offset: 0,
											request_move_id_reservation_ver_index: new_ver_index,
											request_move_id_reservation_hor_index: new_hor_index,
										});
									}
								}
								else if (this.state.dragging_id_reservation_end != -1) {
									const { new_hor_index, new_ver_index, valid } = this.fixDraggingReservationHorVerIdxs(
										this.state.dragging_id_reservation_end,
										this.state.current_hor_idx,
										this.state.current_ver_idx,
										hor_index,
										ver_index,
										this.state.reservation_table.items[this.state.dragging_id_reservation_end].idx_start,
										this.state.current_hor_idx,
										'end',
									);
									
									if (
										(	new_hor_index == this.state.dragging_id_reservation_hor_index &&
											new_ver_index == this.state.dragging_id_reservation_ver_index
										) ||
										!valid
									) {
										this.setState({
											dragging_id_reservation_end: -1,
										});
									}
									else {
										this.setState({
											dragging_id_reservation_end: -1,
											
											request_move_id_reservation: -1,
											request_move_id_reservation_start: -1,
											request_move_id_reservation_end: this.state.dragging_id_reservation_end,
											request_move_id_reservation_offset: 0,
											request_move_id_reservation_ver_index: new_ver_index,
											request_move_id_reservation_hor_index: new_hor_index,
										});
									}
								}
							}}
							onMouseEnter={() => {
								this.setState({
									new_rect_visible: true,
								});
							}}
							onMouseLeave={() => {
								this.setState({
									new_rect_visible: false,
									new_dragging: false,
								});
							}} />
					</svg>
				</div>
				
				<HeaderColumns
					timeline_width={this.timeline_width}
					header_height={this.header_height}
					left_side_width={this.left_side_width}
					column_width={this.column_width}
					dates={this.state.dates}
					timelineRef={this.timelineRef}
					setDateFilter={this.props.setDateFilter}
					filterFrom={this.props.filterFrom}
					filterTo={this.props.filterTo}
					active_horizontal_idx={this.state.current_hor_idx}
					/>
				
				<LeftSide
					left_side_width={this.left_side_width}
					timeline_width={this.timeline_width}
					timeline_height={this.timeline_height}
					row_height={this.row_height}
					rows={rows}
					accommodation_item_places={this.props.accommodation_item_places}
					setDirtyState={this.setDirtyState}
					active_vertical_idx={this.state.current_ver_idx}
					/>
			</div>
			
			<div style={{
				position:        'absolute',
				left:            '0px',
				top:             '0px',
				width:           this.left_side_width,
				height:          this.header_height,
				backgroundColor: '#617f97',
				zIndex:          18,
			}}>
				<Button
					icon={this.state.compact ? 'minimize' : 'maximize'}
					minimal={true}
					onClick={() => {
						this.setCompact(!this.state.compact);
					}}
					intent={Intent.SUCCESS}
					style={{
						position: 'absolute',
						left:     '8px',
						top:      '10px',
						color:    '#ffffff',
					}} />
			</div>
			
			{this.state.request_move_id_reservation == -1 &&
				this.state.request_move_id_reservation_start == -1 &&
				this.state.request_move_id_reservation_end == -1 ? null :
				<ConfirmReservationMoveDialog
					idReservation={
						this.state.request_move_id_reservation != -1 ? this.state.request_move_id_reservation :
							(this.state.request_move_id_reservation_start != -1 ?
								this.state.request_move_id_reservation_start : this.state.request_move_id_reservation_end)
					}
					type={
						this.state.request_move_id_reservation != -1 ? 'move' :
							(this.state.request_move_id_reservation_start != -1 ? 'start' : 'end')
					}
					dates={this.state.dates}
					items={this.state.reservation_table.items}
					horizontalIdx={this.state.request_move_id_reservation_hor_index}
					verticalIdx={this.state.request_move_id_reservation_ver_index}
					offset={this.state.request_move_id_reservation_offset}
					convertIdxToDate={this.convertIdxToDate}
					convertIdxToIdAccommodationItemPlace={this.convertIdxToIdAccommodationItemPlace}
					customers={this.props.customers}
					rooms={this.props.rooms}
					accommodationItemPlaces={this.props.accommodation_item_places}
					reservationCustomersByIdReservations={this.props.reservation_customers_by_id_reservations}
					onCancel={() => {
						this.setState({
							request_move_id_reservation: -1,
							request_move_id_reservation_start: -1,
							request_move_id_reservation_end: -1,
							request_move_id_reservation_offset: 0,
							request_move_id_reservation_ver_index: -1,
							request_move_id_reservation_hor_index: -1,
						});
					}}
					onConfirm={async (
						id_reservation,
						new_id_accommodation_item_place,
						new_check_in,
						new_check_out,
						change_customers_if_needed,
					) => {
						// save item
						const new_reservation = {...this.state.reservation_table.items[id_reservation]};
						
						const old_check_in  = new_reservation.check_in;
						const old_check_out = new_reservation.check_out;
						
						new_reservation.id_accommodation_item_place = new_id_accommodation_item_place;
						
						delete new_reservation.idx_start;
						delete new_reservation.idx_end;
						new_reservation.check_in  = new_check_in instanceof Date ?
							this.toIsoString(new_check_in) : new_check_in;
						new_reservation.check_out = new_check_out instanceof Date ?
							this.toIsoString(new_check_out) : new_check_out;
						
						const { idx_start, idx_end } = this.convertCheckInOutToIdxs(
							this.state.dates,
							new_reservation.check_in,
							new_reservation.check_out
						);
						
						new_reservation.idx_start = idx_start;
						new_reservation.idx_end   = idx_end;
						
						this.state.reservation_table.items[id_reservation] = new_reservation;
						
						let new_item;
						
						const [ dispatch, token ] = [ this.props.dispatch, this.props.token ];
						dispatch(addReservation({ item: new_reservation, token }));
						new_item = await saveReservation(this.props.api_url, new_reservation, token);
						if (new_item !== null) {
							dispatch(addReservation({ item: new_item, token }));
						}
						
						const accommodation_item_place = this.props.accommodation_item_places[new_reservation.id_accommodation_item_place];
						const accommodation_item       = this.props.accommodation_items      [accommodation_item_place.id_accommodation_item];
						const accommodation            = this.props.accommodations           [accommodation_item.id_accommodation];
						
						// change customers' check out timestamps, if needed
						if (change_customers_if_needed) {
							const new_check_out_with_time = moment(new_check_out);
							
							const default_check_out_time_arr = accommodation.default_check_out_time.split(':');
							if (default_check_out_time_arr.length == 3) {
								new_check_out_with_time.hour  (parseInt(default_check_out_time_arr[0], 10));
								new_check_out_with_time.minute(parseInt(default_check_out_time_arr[1], 10));
							}
							
							const reservation_customers = this.props.reservation_customers_by_id_reservations[id_reservation] || {};
							
							const yesterday = this.toIsoString(moment().subtract(1, 'days').toDate());
							for (let id_reservation_customer in reservation_customers) {
								let reservation_customer = reservation_customers[id_reservation_customer];
								if (
									reservation_customer !== undefined &&
									reservation_customer.check_out >= yesterday &&
									reservation_customer.check_out.indexOf(old_check_out) === 0 &&
									reservation_customer.checked_out === false
								) {
									reservation_customer = {...reservation_customer};
									reservation_customer.check_out = new_check_out_with_time.toISOString(true);
									
									new_item = await saveReservationCustomer(this.props.api_url, reservation_customer, token);
									dispatch(addReservationCustomer({
										item: new_item.reservation_customer,
										token,
									}));
									dispatch(addGuestBookItem({
										item: new_item.guest_book_item,
										token,
									}));
								}
							}
						}
						
						// reset state
						this.setState({
							reservation_table: this.generateReservationTable(
								this.state.dates,
								this.props.rooms,
								Object.values(this.state.reservation_table.items),
								this.state.id_accommodation_item_places_to_idxs,
								Object.values(this.props.reservation_requests)
							),
							request_move_id_reservation: -1,
							request_move_id_reservation_start: -1,
							request_move_id_reservation_end: -1,
							request_move_id_reservation_offset: 0,
							request_move_id_reservation_ver_index: -1,
							request_move_id_reservation_hor_index: -1,
						});
					}} />
			}
		</div>;
	}
}
Timeline.propTypes = {
	openReservation: PropTypes.func,
	openExistingReservation: PropTypes.func,
	currentDate: PropTypes.object,
	scaleDays: PropTypes.number,
	setDateFilter: PropTypes.func,
	filterFrom: PropTypes.object,
	filterTo: PropTypes.object,
	rooms: PropTypes.array,
	items: PropTypes.object,
	deletingReservations: PropTypes.bool,
	deleteReservation: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		accommodation_item_places: state.CodeTablesSlice.accommodation_item_places,
		accommodation_items:       state.CodeTablesSlice.accommodation_items,
		accommodations:            state.CodeTablesSlice.accommodations,
		channels:                  state.CodeTablesSlice.channels,
		customers:                 state.CodeTablesSlice.customers,
		user:                      state.UserSlice.user,
		token:                     state.UserSlice.token,
		api_url:                   state.ReservationSlice.api_url,
		reservation_customers_by_id_reservations: state.ReservationSlice.reservation_customers_by_id_reservations,
		reservation_requests:                     state.ReservationSlice.reservation_requests,
		reservation_request_accommodations:       state.ReservationSlice.reservation_request_accommodations,
		general_settings:          state.SettingsSlice.general,
	};
}

export default connect(mapStateToProps)(Timeline);
