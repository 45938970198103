import React from 'react';

import { configureStore } from '@reduxjs/toolkit';
import RootReducer        from './reducers/RootReducer';
import { Provider }       from 'react-redux';
import jsCookies          from 'js-cookie';

import App from './App';
import { API_URL, WS_CHANGES_URL, WS_PRINT_URL } from './constants';

import {
	setUser,
} from './slices/UserSlice';

import LocalStorageHelper from './helpers/LocalStorageHelper';

export default function AppContainer(props) {
	const store = configureStore({
		reducer: RootReducer,
		preloadedState: {
			//if you add anything here, consider adding it to App.storeLoaded function
			ConstantsSlice: {
				api_url: API_URL.replace('{client}', props.client),
				client:  props.client,
				ws_changes_url: WS_CHANGES_URL,
				ws_print_url: WS_PRINT_URL,
			},
			SettingsSlice: {
				api_url: API_URL.replace('{client}', props.client),
				due_day_count: 8,
				current_id_cash_register: LocalStorageHelper.GetValue(props.client + '__current_id_cash_register'),
				general: null,
			},
			CodeTablesSlice: {
				api_url: API_URL.replace('{client}', props.client),
				countries:                                        null,
				post_offices:                                     null,
				business_premises:                                null,
				business_premise_fiscal_registrations:            null,
				business_premise_fiscal_registrations_by_id_business_premises: null,
				channels:                                         null,
				customers:                                        null,
				measuring_units:                                  null,
				payment_types:                                    null,
				tax_rates:                                        null,
				warehouses:                                       null,
				accommodations:                                   null,
				accommodation_items:                              null,
				accommodation_item_places:                        null,
				accommodation_item_items:                         null,
				accommodation_item_place_items:                   null,
				accommodation_item_schedules:                     null,
				accommodation_item_schedule_time_slots:           null,
				accommodation_item_schedule_time_slot_activities: null,
				items:                                            null,
				market_segmentation:                              null,
				activities:                                       null,
				identification_types:                             null,
				tourist_taxes:                                    null,
				daily_logbook_activities:                         null,
				work_types:                                       null,
			},
			ReservationSlice: {
				api_url: API_URL.replace('{client}', props.client),
				reservations:                             null,
				reservation_customers:                    null,
				reservation_customers_by_id_reservations: null,
				activity_reservations:                    null,
				activity_daily_logbooks:                  null,
				reservation_customer_items:               null,
				reservation_requests:                     null,
				reservation_request_accommodations:       null,
			},
			BusinessSlice: {
				api_url: API_URL.replace('{client}', props.client),
				invoices:                     null,
				invoice_fiscal_verifications: null,
				
				advance_invoice_consumptions:                           null,
				id_advance_invoice_consumption_by_id_advance_invoices:  null,
				id_advance_invoice_consumption_by_id_consumer_invoices: null,
			},
			UserSlice: {
				token: jsCookies.get('assistpro_token') === 'null' ? null : jsCookies.get('assistpro_token'),
				user:  null,
				api_url: API_URL.replace('{client}', props.client),
				users: null,
				user_work_types: null,
			},
			DocumentSlice: {
				api_url: API_URL.replace('{client}', props.client),
				document_associations:              null,
				document_associations_id_documents: null,
				document_types:                     null,
			},
			GuestSlice: {
				api_url: API_URL.replace('{client}', props.client),
				guest_book_items: null,
				monthly_guest_book_reports: null,
			},
			CashRegisterSlice: {
				api_url: API_URL.replace('{client}', props.client),
				cash_registers:             null,
				cash_register_daily_states: null,
				cash_register_documents:    null,
			},
			AppSlice: {
				pos_printer_queue:     [],
				pos_printer_connected: false,
				a4_printer_queue:      [],
				a4_printer_connected:  false,
				printers:              [],
				label_printer_queue:   [],
				label_printer_connected: false,
				label_printers:        [],
				critical_error:        null,
			},
			WarehouseSlice: {
				api_url: API_URL.replace('{client}', props.client),
				stock_acquisition_documents:             {},
				stock_inventory_items:                   {},
				stock_inventory_detailed:                {},
				stock_inventory_detailed_acquired_items: {},
				stock_inventory_detailed_spent_items:    {},
				stock_state:                             {},
				stock_inventory_by_date: {
					// keys are ISO dates, then:
					//   stock_inventory_items
					//   stock_inventory_detailed
					//   stock_inventory_detailed_acquired_items
					//   stock_inventory_detailed_spent_items
					//   stock_state
				},
				last_stock_data_by_date: {
					// keys are ISO dates, then last_stock_data, then keys are id_item + id_warehouse
				},
			},
		},
	});
	
	let prev_token = store.getState().UserSlice.token;
	store.subscribe(() => {
		let new_token = store.getState().UserSlice.token;
		
		if (prev_token != new_token)
		{
			prev_token = new_token; //should be set before we do any dispatching, to prevent an endless loop
			
			console.log([ 'token changed', prev_token, new_token ]);
			jsCookies.set('assistpro_token', (new_token === undefined ? null : new_token));
			store.dispatch(setUser({
				user:  null,
				token: new_token,
			}));
		}
	});
	
	return <Provider store={store}>
		<App />
	</Provider>;
}
