import { combineReducers } from 'redux';

import CodeTablesSlice  from '../slices/CodeTablesSlice';
import ReservationSlice from '../slices/ReservationSlice';
import BusinessSlice    from '../slices/BusinessSlice';
import UserSlice        from '../slices/UserSlice';
import ConstantsSlice   from '../slices/ConstantsSlice';
import DocumentSlice    from '../slices/DocumentSlice';
import SettingsSlice    from '../slices/SettingsSlice';
import GuestSlice       from '../slices/GuestSlice';
import CashRegisterSlice from '../slices/CashRegisterSlice';
import AppSlice         from '../slices/AppSlice';
import WarehouseSlice   from '../slices/WarehouseSlice';

export default combineReducers({
	CodeTablesSlice,
	ReservationSlice,
	BusinessSlice,
	UserSlice,
	ConstantsSlice,
	DocumentSlice,
	SettingsSlice,
	GuestSlice,
	CashRegisterSlice,
	AppSlice,
	WarehouseSlice,
});
