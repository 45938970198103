import React from 'react';

import {
	Button,
} from '@blueprintjs/core';

export default React.memo(props => {
	const { customer, countries, editClick, clearClick } = props;
	
	return <div className='customer-info' style={{ position: 'relative' }}>
		{
			customer === null ? null :
			<>
				<div className='customer-info-inner'>
					<div>
						{ customer.type == 'natural' ? customer.surname + ' ' + customer.name : customer.company_name }
					</div>
					<div>
						{(() => {
							const arr = [];
							if (customer.address.length > 0) {
								arr.push(<div key='address'>{customer.address}</div>);
							}
							if (customer.post_office.length > 0) {
								arr.push(<div key='post-office'>{(customer.post_code + ' ' + customer.post_office).trim()}</div>);
							}
							if (customer.id_country !== null) {
								arr.push(<div key='country'>{countries[customer.id_country].iso_3166_1_a2}</div>);
							}
							
							return arr;
						})()}
					</div>
					<div className='pt-2'>
						{ 
							customer.vat_registered ?
								('ID za DDV: ' + customer.vat_prefix + customer.tax_number) :
								('Davčna št.: ' + customer.tax_number)
						}
					</div>
				</div>
				<div className='opacity-50 text-right pr-2' style={{ position: 'absolute', right: 0, bottom: 0 }}>
					{ customer.internal_code }
				</div>
				{editClick === undefined || editClick === null ? null :
					<div style={{ position: 'absolute', right: 0, top: 11 }}>
						<Button
							icon='edit'
							minimal={true}
							onClick={editClick} />
					</div>
				}
				{clearClick === undefined || clearClick === null ? null :
					<div style={{ position: 'absolute', right: 0, top: (editClick === undefined || editClick === null ? 11 : 32) }}>
						<Button
							icon='cross'
							minimal={true}
							onClick={clearClick} />
					</div>
				}
			</>
		}
	</div>;
});
