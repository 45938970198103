import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	Icon,
	Navbar,
	Divider,
} from '@blueprintjs/core';

class BusinessPremiseFiscalRegistrationHistoryDialog extends Component {
	constructor(props) {
		super(props);
		
		const items = this.props.business_premise_fiscal_registrations_by_id_business_premises[this.props.id_business_premise];
		this.state = {
			selected_id_business_premise_fiscal_registration: items === null || items.length == 0 ? null : items[0].id_business_premise_fiscal_registration,
		};
	}
	
	toLocalStringWithTime(date) {
		if (date === null) return '';
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear() + ' ' +
			date.getHours().toString().padStart(2, '0') + ':' +
			date.getMinutes().toString().padStart(2, '0') + ':' +
			date.getSeconds().toString().padStart(2, '0');
	}
	
	getStatus(status) {
		if (status === null) {
			return 'v pošiljanju';
		}
		else if (status) {
			return 'potrjeno';
		}
		return 'napaka pri potrjevanju';
	}
	
	render() {
		const business_premise_fiscal_registration_for_business_premise =
			(this.props.business_premise_fiscal_registrations_by_id_business_premises === undefined ?
				undefined : this.props.business_premise_fiscal_registrations_by_id_business_premises[this.props.id_business_premise]) || [];
		
		let sel_index = -1;
		for (let i=0; i<business_premise_fiscal_registration_for_business_premise.length; i++) {
			if (this.state.selected_id_business_premise_fiscal_registration == business_premise_fiscal_registration_for_business_premise[i].id_business_premise_fiscal_registration) {
				sel_index = i;
				break;
			}
		}
		
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={false}
			canEscapeKeyClose={false}
			onClose={() => this.props.closeBusinessPremiseFiscalRegistrationHistoryDialog()}
			className='business-premise-fiscal-registration-history-dialog'>
			<div className='flex flex-col flex-grow h-full'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Zgodovina davčnega potrjevanja</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeBusinessPremiseFiscalRegistrationHistoryDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY + ' flex flex-row items-stretch'} style={{ height: 'calc(100% - 70px)' }}>
					<div className='flex flex-1 flex-row items-stretch'>
						<div className='w-1/4 fiscal-verification-items overflow-y-auto'>
							{business_premise_fiscal_registration_for_business_premise.map((business_premise_fiscal_registration, statusIdx) => {
								let icon  = '';
								let color = '';
								if (business_premise_fiscal_registration !== null) {
									if (business_premise_fiscal_registration.status === null) {
										icon   = 'history';
										color  = '#d9822b';
									}
									else if (business_premise_fiscal_registration.status) {
										icon   = 'tick-circle';
										color  = '#0f9960';
									}
									else {
										icon   = 'warning-sign';
										color  = '#db3737';
									}
								}
								
								const cls = business_premise_fiscal_registration.id_business_premise_fiscal_registration == this.state.selected_id_business_premise_fiscal_registration ?
									'selected' : '';
								
								return <div
									key={'business_premise-fiscal-verification-' + business_premise_fiscal_registration.id_business_premise_fiscal_registration}
									className={'fiscal-verification-item flex flex-row ' + cls}
									onClick={() => this.setState({ selected_id_business_premise_fiscal_registration: business_premise_fiscal_registration.id_business_premise_fiscal_registration })}>
									<div className='w-1/6'>
										<Icon
											icon={icon}
											color={color} />
									</div>
									<div className='flex-1'>
										<div className={statusIdx == 0 ? 'font-bold' : ''}>{
											this.toLocalStringWithTime(new Date(business_premise_fiscal_registration.created))
										}</div>
										<div className={statusIdx == 0 ? 'font-bold' : ''}>{
											this.getStatus(business_premise_fiscal_registration.status)
										}</div>
									</div>
								</div>;
							})}
						</div>
						<div className='flex-1 fiscal-verification-messages overflow-y-scroll'>
							{sel_index == -1 ? null :
								<>
									<div className='text-center uppercase text-lg font-light'>Odgovor</div>
									<pre>{
										business_premise_fiscal_registration_for_business_premise[sel_index].response === null ? null :
											JSON.stringify(JSON.parse(business_premise_fiscal_registration_for_business_premise[sel_index].response), null, 2)
									}</pre>
									
									<Divider className='mt-4 mb-4' />
									
									{(() => {
										let request     = null;
										let sent_message = business_premise_fiscal_registration_for_business_premise[sel_index].sent_message;
										
										if (sent_message !== null) {
											const sent_message_obj = JSON.parse(sent_message);
											if (sent_message_obj.token !== null) {
												const arr = sent_message_obj.token.split('.');
												if (arr.length == 3) {
													const request_obj = JSON.parse(atob(arr[1]));
													request = JSON.stringify(request_obj, null, 2);
												}
											}
										}
										
										return <>
											<div className='text-center uppercase text-lg font-light'>Zahtevek</div>
											<pre>{
												request
											}</pre>
										</>;
									})()}
								</>
							}
						</div>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}

function mapStateToProps(state) {
	return {
		business_premises:                                             state.CodeTablesSlice.business_premises,
		business_premise_fiscal_registrations_by_id_business_premises: state.CodeTablesSlice.business_premise_fiscal_registrations_by_id_business_premises,
	};
}

export default connect(mapStateToProps)(BusinessPremiseFiscalRegistrationHistoryDialog);
