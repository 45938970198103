import React from 'react';

import {
	Tag,
	Tooltip,
} from '@blueprintjs/core';

import moment from 'moment';

export default function UserTag(props) {
	const getUserInitials = user => {
		if (user === null) return '';
		return (
			(user.name.length == 0 ? '' : user.name.substr(0, 1)) +
			(user.surname === null || user.surname.length == 0 ? '' : user.surname.substr(0, 1))
		).toUpperCase();
	};
	const getFullUserName = user => {
		if (user === null) return '';
		return (user.name + ' ' + (user.surname ?? '')).trim();
	};
	
	const { user, reservation, className, ...other } = props;
	
	return <Tooltip
		content={
			getFullUserName(user) + (
				reservation === null ? '' : (
					', ' +
					moment(reservation.created).format('DD. MM. YYYY') +
					' ob ' +
					moment(reservation.created).format('HH:mm:ss')
				)
			)
		}
		className={(className || '') + ' leading-normal'}
		{...other}>
		<Tag round={true} icon='user' className='p-1'>
			{
				getUserInitials(user) }
		</Tag>
	</Tooltip>;
}
