import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import {
	Button,
	ButtonGroup,
	Intent,
	Tooltip,
	Checkbox,
	RadioGroup,
	Radio,
} from '@blueprintjs/core';

import { useQueryParams } from 'raviger';

import DateRangePickerDialog from '../DateRangePickerDialog';
import PriceHelper from '../../helpers/PriceHelper';
import EditActivityItemDialog from '../reservations/EditActivityItemDialog';
import { addActivityReservation  } from '../../slices/ReservationSlice';
import { saveActivityReservation } from '../../api/Reservations';

import { DateRangeFilter } from '../code_tables/ResultComponents';

import { toLocal } from '../../numbers';

const Type = {
	occupancy_income: 'occupancy_income',
	arrivals:         'arrivals',
};

class MainFilter extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			from_date:               props.date_range[0],
			until_date:              props.date_range[1],
			type:                    props.type,
			statuses:                props.statuses,
			date_picker_dialog_open: false,
			id_accommodation_items:  props.selected_id_accommodation_items,
			display_inactive:        props.display_inactive,
		};
	}
	
	render() {
		const setDates = (from_date, until_date) => {
			this.setState({ from_date, until_date });
			//this.props.onChangeDate([ from_date, until_date ]);
		};
		
		const openFilterDateRangePickerDialog = () => {
			this.setState({ date_picker_dialog_open: true });
		};
		const closeFilterDateRangePickerDialog = (newValueFrom, newValueUntil) => {
			this.setState({ date_picker_dialog_open: false });
			
			if (newValueFrom instanceof Date && newValueUntil instanceof Date) {
				setDates(newValueFrom, newValueUntil);
			}
		};
		
		return <>
			{!this.state.date_picker_dialog_open ? null :
				<DateRangePickerDialog
					closeDialog={closeFilterDateRangePickerDialog}
					startValue={this.state.from_date}
					endValue={this.state.until_date} />
			}
			
			<div className='flex flex-col items-start'>
				<div className='mt-4 flex flex-row'>
					<RadioGroup
						className='flex flex-row items-center'
						onChange={event => {
							this.setState({
								type: event.target.value,
							});
						}}
						selectedValue={this.state.type}>
						<Radio className='mr-4 mb-0' label='Zasedenost/prihodek' value={Type.occupancy_income} />
						<Radio className='mr-4 mb-0' label='Prihodi/nočitve'     value={Type.arrivals}         />
					</RadioGroup>
					
					<div className={'flex flex-row items-center border-solid border-gray-500 border-l-2 pl-4 ' + (this.state.type != Type.occupancy_income ? 'invisible' : '')}>
						<Checkbox
							className='mr-2 mb-0'
							checked={this.state.display_inactive}
							onChange={event => {
								this.setState({ display_inactive: event.target.checked });
							}}>
							Prikaži samo neaktivne
						</Checkbox>
						
						<Button disabled={this.state.display_inactive} onClick={() => {
							this.setState({ statuses: [
								'new',
								'waiting-for-confirmation',
								'offer-sent',
								'confirmed',
								'advance-invoice-sent',
							] });
						}}>Izberi aktivne</Button>
						<Button disabled={this.state.display_inactive} onClick={() => {
							this.setState({ statuses: [
								'closed',
							] });
						}}>Izberi plačane</Button>
						
						{[
							{ title: 'Nova',           key: 'new'                       },
							{ title: 'V potrjevanju',  key: 'waiting-for-confirmation'  },
							{ title: 'Izdana ponudba', key: 'offer-sent'                },
							{ title: 'Potrjena',       key: 'confirmed'                 },
							{ title: 'Izdan avans',    key: 'advance-invoice-sent'      },
							{ title: 'Plačana',        key: 'closed'                    },
						].map(x => <Checkbox
							key={'status-' + x.key}
							className='ml-2 mb-0'
							disabled={this.state.display_inactive}
							checked={this.state.statuses.indexOf(x.key) !== -1}
							onChange={event => {
								const statuses = event.target.checked
									? [ ...this.state.statuses, x.key ]
									: this.state.statuses.filter(status => status != x.key);
								
								this.setState({ statuses });
							}}>
							{ x.title }
						</Checkbox>)}
					</div>
				</div>
				<div className='mt-2' style={{ width: 200 }}>
					<DateRangeFilter
						filter_values={[
							this.state.from_date,
							this.state.until_date,
						]}
						original_start_date={null}
						original_end_date={null}
						onFilterChange={values => {
							setDates(values[0], values[1]);
						}} />
				</div>
				<div className='flex flex-row flex-wrap mt-2'>
					{Object.values(this.props.accommodations)
						.filter(accommodation => accommodation.type == 'regular')
						.map(accommodation => <div key={ accommodation.id_accommodation } className='pr-4 pl-2 mb-3 border-solid border-gray-500 border-l-2'>
						<div className='flex flex-row justify-between items-center' style={{ minHeight: 27 }}>
							<div>{
								(accommodation.etourism_id_rno === null ? '' : (accommodation.etourism_id_rno + ' • ')) +
								accommodation.title
							}</div>
							{Object.values(this.props.accommodation_items).filter(x => x.id_accommodation == accommodation.id_accommodation).length <= 1
								? null
								: <div>
									<Button text='Izberi vse' onClick={() => {
										const items = [ ...this.state.id_accommodation_items ];
										Object.values(this.props.accommodation_items)
											.filter(x => x.id_accommodation == accommodation.id_accommodation)
											.forEach(accommodation_item => {
												if (items.indexOf(accommodation_item.id_accommodation_item) === -1) {
													items.push(accommodation_item.id_accommodation_item);
												}
											});
										this.setState({
											id_accommodation_items: items,
										});
									}} />
									<Button text='Izberi nobenega' onClick={() => {
										const items = [ ...this.state.id_accommodation_items ];
										Object.values(this.props.accommodation_items)
											.filter(x => x.id_accommodation == accommodation.id_accommodation)
											.forEach(accommodation_item => {
												if (items.indexOf(accommodation_item.id_accommodation_item) !== -1) {
													items.splice(items.indexOf(accommodation_item.id_accommodation_item), 1);
												}
											});
										this.setState({
											id_accommodation_items: items,
										});
									}} />
								</div>
							}
						</div>
						<div className='flex flex-row flex-wrap mt-2'>
							{ Object.values(this.props.accommodation_items)
								.filter(x => x.id_accommodation == accommodation.id_accommodation)
								.map(accommodation_item =>
									<Checkbox
										key={ accommodation_item.id_accommodation_item }
										className='mr-4'
										label={ accommodation_item.title == 'default' ? '' : accommodation_item.title }
										checked={this.state.id_accommodation_items.indexOf(accommodation_item.id_accommodation_item) != -1}
										onChange={event => {
											const items = [ ...this.state.id_accommodation_items ];
											if (event.target.checked) {
												items.push(accommodation_item.id_accommodation_item);
											}
											else {
												items.splice(items.indexOf(accommodation_item.id_accommodation_item), 1);
											}
											this.setState({
												id_accommodation_items: items,
											});
										}} />
								)
							}
						</div>
					</div>)}
				</div>
				
				<div className='mt-2 mb-8 flex flex-row'>
					<Button
						icon='search'
						onClick={() => {
							this.props.onChangeFilters({
								type: this.state.type,
								statuses: this.state.statuses,
								dates: [ this.state.from_date, this.state.until_date ],
								id_accommodation_items: this.state.id_accommodation_items,
								display_inactive: this.state.display_inactive,
							});
						}}
						intent={Intent.PRIMARY}>
						Prikaži
					</Button>
					<Button
						className='ml-1'
						icon='cloud-download'
						onClick={() => {
							const params = [
								'date_from='              + moment(this.state.from_date ).format('YYYY-MM-DD'),
								'date_until='             + moment(this.state.until_date).format('YYYY-MM-DD'),
								'type='                   + this.state.type,
								'statuses='               + this.state.statuses              .join(','),
								'id_accommodation_items=' + this.state.id_accommodation_items.join(','),
								'display_inactive='       + (this.state.display_inactive ? 'true' : 'false'),
							];
							
							window.open(
								this.props.api_url +
								'Reports/v1/export-reservations-report-to-excel?' + 
								params.join('&')
							);
						}}
						intent={Intent.PRIMARY}>
						Izvoz v Excel
					</Button>
				</div>
			</div>
		</>;
	}
}

// when changing this method, also change ReportsServiceProvider.php
function Arrivals(props) {
	const sums_by_accommodation_item_places     = {};
	const sums_by_accommodation_items           = {};
	const sums_by_accommodation_items_countries = {};
	const sums_by_accommodations                = {};
	const sums_by_accommodations_countries      = {};
	const displayed_id_accommodations           = [];
	const displayed_id_accommodation_items      = [];
	
	const from_date_iso  = moment(props.from_date ).format('YYYY-MM-DD');
	const until_date_iso = moment(props.until_date).format('YYYY-MM-DD');
	
	for (let id_reservation_customer in props.reservation_customers) {
		const reservation_customer = props.reservation_customers[id_reservation_customer];
		const customer             = props.customers            [reservation_customer.id_customer];
		const guest_book_item      = props.guest_book_items     [reservation_customer.id_guest_book];
		const tourist_tax          = props.tourist_taxes        [reservation_customer.id_tourist_tax];
		
		if (guest_book_item === undefined) {
			console.log('Guest book item empty for reservation customer ' + id_reservation_customer);
			continue;
		}
		
		if (reservation_customer.check_in > until_date_iso || reservation_customer.check_out < from_date_iso) {
			continue;
		}
		
		const reservation              = props.reservations             [reservation_customer.id_reservation];
		const accommodation_item_place = props.accommodation_item_places[reservation.id_accommodation_item_place];
		const accommodation_item       = props.accommodation_items      [accommodation_item_place.id_accommodation_item];
		const accommodation            = props.accommodations           [accommodation_item.id_accommodation];
		
		if (props.selected_id_accommodation_items.indexOf(accommodation_item.id_accommodation_item) == -1) continue;
		
		let is_check_in_inside_selected_date_range = true;
		
		let check_in = moment(reservation_customer.check_in).startOf('day');
		if (check_in.format('YYYY-MM-DD') < from_date_iso) {
			check_in = moment(from_date_iso).startOf('day');
			is_check_in_inside_selected_date_range = false;
		}
		let check_out = moment(reservation_customer.check_out).startOf('day');
		if (check_out.format('YYYY-MM-DD') > until_date_iso) {
			check_out = moment(until_date_iso).startOf('day');
		}
		
		const reservation_count  = is_check_in_inside_selected_date_range ? 1 : 0;
		const reservation_length = check_out.diff(check_in, 'days');
		
		if (sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place] === undefined) {
			sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place] = {};
		}
		if (sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place][customer.id_country] === undefined) {
			sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place][customer.id_country] = { reservation_count: 0, night_count: 0 };
		}
		sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place][customer.id_country].reservation_count += reservation_count;
		sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place][customer.id_country].night_count       += reservation_length;
		
		if (sums_by_accommodation_items[accommodation_item.id_accommodation_item] === undefined) {
			sums_by_accommodation_items[accommodation_item.id_accommodation_item] = { reservation_count: 0, night_count: 0 };
		}
		sums_by_accommodation_items[accommodation_item.id_accommodation_item].reservation_count += reservation_count;
		sums_by_accommodation_items[accommodation_item.id_accommodation_item].night_count       += reservation_length;
		
		if (sums_by_accommodation_items_countries[accommodation_item.id_accommodation_item] === undefined) {
			sums_by_accommodation_items_countries[accommodation_item.id_accommodation_item] = {};
		}
		if (sums_by_accommodation_items_countries[accommodation_item.id_accommodation_item][customer.id_country] === undefined) {
			sums_by_accommodation_items_countries[accommodation_item.id_accommodation_item][customer.id_country] = { reservation_count: 0, night_count: 0 };
		}
		sums_by_accommodation_items_countries[accommodation_item.id_accommodation_item][customer.id_country].reservation_count += reservation_count;
		sums_by_accommodation_items_countries[accommodation_item.id_accommodation_item][customer.id_country].night_count       += reservation_length;
		
		if (sums_by_accommodations[accommodation.id_accommodation] === undefined) {
			sums_by_accommodations[accommodation.id_accommodation] = { reservation_count: 0, night_count: 0 };
		}
		sums_by_accommodations[accommodation.id_accommodation].reservation_count += reservation_count;
		sums_by_accommodations[accommodation.id_accommodation].night_count       += reservation_length;
		
		if (sums_by_accommodations_countries[accommodation.id_accommodation] === undefined) {
			sums_by_accommodations_countries[accommodation.id_accommodation] = {};
		}
		if (sums_by_accommodations_countries[accommodation.id_accommodation][customer.id_country] === undefined) {
			sums_by_accommodations_countries[accommodation.id_accommodation][customer.id_country] = { reservation_count: 0, night_count: 0 };
		}
		sums_by_accommodations_countries[accommodation.id_accommodation][customer.id_country].reservation_count += reservation_count;
		sums_by_accommodations_countries[accommodation.id_accommodation][customer.id_country].night_count       += reservation_length;
		
		if (displayed_id_accommodations.indexOf(accommodation.id_accommodation) == -1) {
			displayed_id_accommodations.push(accommodation.id_accommodation);
		}
		if (displayed_id_accommodation_items.indexOf(accommodation_item.id_accommodation_item) == -1) {
			displayed_id_accommodation_items.push(accommodation_item.id_accommodation_item);
		}
	}
	
	let c = 0;
	
	return <div className='flex flex-row items-start'>
		<div className='reservations-report--arrivals--table mr-4'>
			<div className='bg-gray-400 pt-2 pb-2 pl-2'>Objekt</div>
			<div className='bg-gray-400 pt-2 pb-2'>Narodnost</div>
			<div className='bg-gray-400 pt-2 pb-2 text-right'>Prihodi</div>
			<div className='bg-gray-400 pt-2 pb-2 pr-2 text-right'>Nočitve</div>
			
			{Object.values(props.accommodations)
				.filter(accommodation => displayed_id_accommodations.indexOf(accommodation.id_accommodation) !== -1)
				.sort((a, b) => a.ord - b.ord)
				.map(accommodation => {
					let color = c % 2 == 0 ? 'bg-gray-350' : '';
					c++;
					
					return <React.Fragment key={accommodation.id_accommodation}>
						<div className={'pt-1 pb-1 pl-2 border-solid border-gray-500 border-t font-extrabold ' + color}>{
							(accommodation.etourism_id_rno === null ? '' : (accommodation.etourism_id_rno + ' • ')) +
							accommodation.title
						}</div>
						<div className={'pt-1 pb-1 border-solid border-gray-500 border-t font-extrabold ' + color}></div>
						<div className={'pt-1 pb-1 border-solid border-gray-500 border-t font-extrabold text-right ' + color}>{
							sums_by_accommodations[accommodation.id_accommodation].reservation_count
						}</div>
						<div className={'pt-1 pb-1 pr-2 border-solid border-gray-500 border-t font-extrabold text-right ' + color}>{
							sums_by_accommodations[accommodation.id_accommodation].night_count
						}</div>
						
						{Object.values(props.accommodation_items)
							.sort((a, b) => a.ord - b.ord)
							.filter(accommodation_item =>
								accommodation_item.id_accommodation == accommodation.id_accommodation &&
								displayed_id_accommodation_items.indexOf(accommodation_item.id_accommodation_item) != -1
							).map(accommodation_item => {
								let color = c % 2 == 0 ? 'bg-gray-350' : '';
								c++;
								
								return <React.Fragment key={accommodation_item.id_accommodation_item}>
									{accommodation_item.title == 'default' ? null : <>
										<div className={'pt-1 pb-1 pl-4 font-bold ' + color}>{ accommodation_item.title == 'default' ? '' : accommodation_item.title }</div>
										<div className={color}></div>
										<div className={'pt-1 pb-1 text-right font-bold ' + color}>{
											sums_by_accommodation_items[accommodation_item.id_accommodation_item].reservation_count
										}</div>
										<div className={'pt-1 pb-1 text-right font-bold pr-2 ' + color}>{
											sums_by_accommodation_items[accommodation_item.id_accommodation_item].night_count
										}</div>
									</>}
									
									{Object.values(props.accommodation_item_places)
										.filter(accommodation_item_place =>
											accommodation_item_place.id_accommodation_item == accommodation_item.id_accommodation_item &&
											sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place] !== undefined
										)
										.sort((a, b) => a.ord - b.ord).map(accommodation_item_place => {
											const retval = <React.Fragment key={accommodation_item_place.id_accommodation_item_place}>
												{Object.keys(sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place] ?? {})
													.sort((a, b) => props.countries[a].official_name_local.localeCompare(props.countries[b].official_name_local))
													.map((id_country, idx) => {
														let color = c % 2 == 0 ? 'bg-gray-350' : '';
														
														return <React.Fragment key={accommodation_item_place.id_accommodation_item_place + '--' + id_country}>
															<div className={'pt-1 pb-1 pl-8 ' + color}>{ idx == 0 ? accommodation_item_place.title : '' }</div>
															<div className={'pt-1 pb-1 ' + color}>{
																props.countries[id_country].official_name_local
															}</div>
															<div className={'pt-1 pb-1 text-right ' + color}>{
																sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place][id_country].reservation_count
															}</div>
															<div className={'pt-1 pb-1 text-right pr-2 ' + color}>{
																sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place][id_country].night_count
															}</div>
														</React.Fragment>;
													})
												}
											</React.Fragment>;
											
											c++;
											
											return retval;
										})
									}
								</React.Fragment>;
							})
						}
					</React.Fragment>;
				})
			}
		</div>
		<div className='reservations-report--arrivals--countries--table'>
			<div className='bg-gray-400 pt-2 pb-2 pl-2'>Objekt</div>
			<div className='bg-gray-400 pt-2 pb-2'>Narodnost</div>
			<div className='bg-gray-400 pt-2 pb-2 text-right'>Prihodi</div>
			<div className='bg-gray-400 pt-2 pb-2 pr-2 text-right'>Nočitve</div>
			
			{Object.values(props.accommodations)
				.filter(accommodation => displayed_id_accommodations.indexOf(accommodation.id_accommodation) !== -1)
				.sort((a, b) => a.ord - b.ord)
				.map(accommodation => {
					let color = c % 2 == 0 ? 'bg-gray-350' : '';
					c++;
					
					const accommodation_items = Object.values(props.accommodation_items)
						.filter(accommodation_item =>
							accommodation_item.id_accommodation == accommodation.id_accommodation &&
							displayed_id_accommodation_items.indexOf(accommodation_item.id_accommodation_item) != -1
						);
					
					return <React.Fragment key={accommodation.id_accommodation}>
						<div className={'pt-1 pb-1 pl-2 border-solid border-gray-500 border-t font-extrabold ' + color}>{
							(accommodation.etourism_id_rno === null ? '' : (accommodation.etourism_id_rno + ' • ')) +
							accommodation.title
						}</div>
						<div className={'pt-1 pb-1 border-solid border-gray-500 border-t font-extrabold ' + color}></div>
						<div className={'pt-1 pb-1 border-solid border-gray-500 border-t font-extrabold text-right ' + color}>{
							sums_by_accommodations[accommodation.id_accommodation].reservation_count
						}</div>
						<div className={'pt-1 pb-1 pr-2 border-solid border-gray-500 border-t font-extrabold text-right ' + color}>{
							sums_by_accommodations[accommodation.id_accommodation].night_count
						}</div>
						
						<>
							{Object.keys(sums_by_accommodations_countries[accommodation.id_accommodation] ?? {})
								.sort((a, b) => props.countries[a].official_name_local.localeCompare(props.countries[b].official_name_local))
								.map((id_country, idx) => {
									let color = c % 2 == 0 ? 'bg-gray-350' : '';
									c++;
									
									return <React.Fragment key={accommodation.id_accommodation + '--' + id_country}>
										<div className={'pt-1 pb-1 pl-8 ' + color}></div>
										<div className={'pt-1 pb-1 ' + color}>{
											props.countries[id_country].official_name_local
										}</div>
										<div className={'pt-1 pb-1 text-right ' + color}>{
											sums_by_accommodations_countries[accommodation.id_accommodation][id_country].reservation_count
										}</div>
										<div className={'pt-1 pb-1 text-right pr-2 ' + color}>{
											sums_by_accommodations_countries[accommodation.id_accommodation][id_country].night_count
										}</div>
									</React.Fragment>;
								})
							}
						</>
						
						{accommodation_items.length <= 1 ? null : accommodation_items
							.sort((a, b) => a.ord - b.ord)
							.map(accommodation_item => {
								let color = c % 2 == 0 ? 'bg-gray-350' : '';
								c++;
								
								return <React.Fragment key={accommodation_item.id_accommodation_item}>
									{accommodation_item.title == 'default' ? null : <>
										<div className={'pt-1 pb-1 pl-4 font-bold ' + color}>{ accommodation_item.title == 'default' ? '' : accommodation_item.title }</div>
										<div className={color}></div>
										<div className={'pt-1 pb-1 text-right font-bold ' + color}>{
											sums_by_accommodation_items[accommodation_item.id_accommodation_item].reservation_count
										}</div>
										<div className={'pt-1 pb-1 text-right font-bold pr-2 ' + color}>{
											sums_by_accommodation_items[accommodation_item.id_accommodation_item].night_count
										}</div>
										
										{Object.keys(sums_by_accommodation_items_countries[accommodation_item.id_accommodation_item] ?? {})
											.sort((a, b) => props.countries[a].official_name_local.localeCompare(props.countries[b].official_name_local))
											.map((id_country, idx) => {
												let color = c % 2 == 0 ? 'bg-gray-350' : '';
												c++;
												
												return <React.Fragment key={accommodation_item.id_accommodation_item + '--' + id_country}>
													<div className={'pt-1 pb-1 pl-8 ' + color}></div>
													<div className={'pt-1 pb-1 ' + color}>{
														props.countries[id_country].official_name_local
													}</div>
													<div className={'pt-1 pb-1 text-right ' + color}>{
														sums_by_accommodation_items_countries[accommodation_item.id_accommodation_item][id_country].reservation_count
													}</div>
													<div className={'pt-1 pb-1 text-right pr-2 ' + color}>{
														sums_by_accommodation_items_countries[accommodation_item.id_accommodation_item][id_country].night_count
													}</div>
												</React.Fragment>;
											})
										}
									</>}
								</React.Fragment>;
							})
						}
					</React.Fragment>;
				})
			}
		</div>
	</div>;
}

// when changing this method, also change ReportsServiceProvider.php
function OccupancyIncome(props) {
	const sums_by_accommodation_item_places = {};
	const sums_by_accommodation_items       = {};
	const sums_by_accommodations            = {};
	const sums_total                        = { occupancy_count: 0, total_occupancy_count: 0, income: 0 };
	const displayed_id_accommodations       = [];
	let displayed_id_accommodation_items    = [];
	
	const from_date_iso  = moment(props.from_date ).format('YYYY-MM-DD');
	const until_date_iso = moment(props.until_date).format('YYYY-MM-DD');
	
	const total_occupancy_count = moment(until_date_iso).diff(moment(from_date_iso), 'days');
	
	const reservation_customers = Object.values(props.reservation_customers);
	
	// fill initial data
	Object.keys(props.accommodations)
		.filter(id_accommodation => props.accommodations[id_accommodation].type == 'regular')
		.forEach(id_accommodation => {
			sums_by_accommodations[id_accommodation] = {
				occupancy_count: 0,
				total_occupancy_count: 0,
				income: 0,
			};
		});
	Object.keys(props.accommodation_items).forEach(id_accommodation_item => {
		displayed_id_accommodation_items.push(id_accommodation_item);
		
		sums_by_accommodation_items[id_accommodation_item] = {
			occupancy_count: 0,
			total_occupancy_count: 0,
			income: 0,
		};
	});
	Object.keys(props.accommodation_item_places).forEach(id_accommodation_item_place => {
		sums_by_accommodation_item_places[id_accommodation_item_place] = {
			occupancy_count: 0,
			total_occupancy_count,
			income: 0,
		};
		
		sums_by_accommodation_items[
			props.accommodation_item_places[id_accommodation_item_place].id_accommodation_item
		].total_occupancy_count += total_occupancy_count;
		
		sums_by_accommodations[
			props.accommodation_items[
				props.accommodation_item_places[id_accommodation_item_place].id_accommodation_item
			].id_accommodation
		].total_occupancy_count += total_occupancy_count;
		
		sums_total.total_occupancy_count += total_occupancy_count;
	});
	
	props.selected_id_accommodation_items.map(id_accommodation_item => {
		const id_accommodation = props.accommodation_items[id_accommodation_item].id_accommodation;
		if (displayed_id_accommodations.indexOf(id_accommodation) == -1) {
			displayed_id_accommodations.push(id_accommodation);
		}
	});
	
	const invoices_by_reverse_invoice_references = {};
	for (let id_invoice in props.invoices) {
		const invoice = props.invoices[id_invoice];
		if (invoice.reverse_invoice_reference !== null) {
			if (invoices_by_reverse_invoice_references[invoice.reverse_invoice_reference] === undefined) {
				invoices_by_reverse_invoice_references[invoice.reverse_invoice_reference] = [];
			}
			invoices_by_reverse_invoice_references[invoice.reverse_invoice_reference].push(invoice);
		}
	}
	
	const used_invoices = [];
	for (let id_reservation in props.reservations) {
		const reservation = props.reservations[id_reservation];
		
		if (reservation.check_in > until_date_iso || reservation.check_out < from_date_iso) {
			continue;
		}
		
		if (props.display_inactive) {
			if (
				[ 'reversed', 'no-show', 'not-for-rent' ].indexOf(reservation.status) === -1 &&
				reservation.deleted === false
			) continue;
		}
		else {
			if (props.statuses.indexOf(reservation.status) === -1) continue;
		}
		
		const ignore_for_occupancy =
			[ 'reversed', 'no-show', 'not-for-rent' ].indexOf(reservation.status) !== -1 ||
			reservation.deleted === true;
		
		const accommodation_item_place = props.accommodation_item_places[reservation.id_accommodation_item_place];
		const accommodation_item       = props.accommodation_items      [accommodation_item_place.id_accommodation_item];
		const accommodation            = props.accommodations           [accommodation_item.id_accommodation];
		
		let check_in = moment(reservation.check_in).startOf('day');
		if (check_in.format('YYYY-MM-DD') < from_date_iso) {
			check_in = moment(from_date_iso).startOf('day');
		}
		let check_out = moment(reservation.check_out).startOf('day');
		if (check_out.format('YYYY-MM-DD') > until_date_iso) {
			check_out = moment(until_date_iso).startOf('day');
		}
		
		const occupancy_count = ignore_for_occupancy ? 0 : check_out.diff(check_in, 'days');
		
		const id_invoices = new Set();
		const reservation_docs = Object.values(props.document_associations[reservation.id_reservation] ?? {}).filter(x => [ 'invoice', 'cash-invoice' ].indexOf(x.source_type) !== -1);
		for (let i=0; i<reservation_docs.length; i++) {
			id_invoices.add(reservation_docs[i].source_id_document);
		}
		const customers = reservation_customers.filter(x => x.id_reservation == reservation.id_reservation);
		for (let j=0; j<customers.length; j++) {
			const customer_docs = Object.values(props.document_associations[customers[j].id_reservation_customer] ?? {}).filter(x => [ 'invoice', 'cash-invoice' ].indexOf(x.source_type) !== -1);
			for (let i=0; i<customer_docs.length; i++) {
				id_invoices.add(customer_docs[i].source_id_document);
			}
		}
		
		const id_invoices_copy = new Set(id_invoices);
		
		// take into account reverse invoices as well
		id_invoices_copy.forEach(id_invoice => {
			const reverse_invoices = invoices_by_reverse_invoice_references[id_invoice] || [];
			for (let i=0; i<reverse_invoices.length; i++) {
				id_invoices.add(reverse_invoices[i].id_invoice);
			}
		});
		
		let income = 0;
		id_invoices.forEach(id_invoice => {
			income += props.invoices[id_invoice].invoice_amount;
			used_invoices.push(props.invoices[id_invoice].invoice_number_internal);
		});
		
		sums_total.occupancy_count += occupancy_count;
		sums_total.income          += income;
		
		sums_by_accommodations[accommodation.id_accommodation].occupancy_count += occupancy_count;
		sums_by_accommodations[accommodation.id_accommodation].income          += income;
		
		sums_by_accommodation_items[accommodation_item.id_accommodation_item].occupancy_count += occupancy_count;
		sums_by_accommodation_items[accommodation_item.id_accommodation_item].income          += income;
		
		sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].occupancy_count += occupancy_count;
		sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].income          += income;
	}
	console.log({ used_invoices: used_invoices.join('\n') });
	
	//displayed_id_accommodation_items = props.selected_id_accommodation_items;
	
	let c = 0;
	
	const filtered_accommodations = Object.values(props.accommodations)
		.filter(accommodation => accommodation.type == 'regular');
	
	let level_1_count_sum  = 0;
	let level_1_income_sum = 0;
	for (let idx in filtered_accommodations) {
		level_1_count_sum  += sums_by_accommodations[filtered_accommodations[idx].id_accommodation]?.occupancy_count ?? 0;
		level_1_income_sum += sums_by_accommodations[filtered_accommodations[idx].id_accommodation]?.income          ?? 0;
	}
	
	return <div>
		<div className='reservations-report--occupancy_income--table'>
			<div className='bg-gray-400 pt-2 pb-2 pl-2'>Objekt</div>
			<div className='bg-gray-400 pt-2 pb-2'></div>
			<div className='bg-gray-400 pt-2 pb-2 col-span-3 text-center'>Zasedenost (dnevi)</div>
			<div className='bg-gray-400 pt-2 pb-2'></div>
			<div className='bg-gray-400 pt-2 pb-2 col-span-3 text-center'>Prihodek z DDV (EUR)</div>
			
			{filtered_accommodations
				//.filter(accommodation => displayed_id_accommodations.indexOf(accommodation.id_accommodation) !== -1)
				.sort((a, b) => a.ord - b.ord)
				.map(accommodation => {
					let color = c % 2 == 0 ? 'bg-gray-350' : '';
					c++;
					
					const filtered_accommodation_items = Object.values(props.accommodation_items)
						.sort((a, b) => a.ord - b.ord)
						.filter(accommodation_item =>
							accommodation_item.id_accommodation == accommodation.id_accommodation &&
							displayed_id_accommodation_items.indexOf(accommodation_item.id_accommodation_item) != -1
						);
					
					let level_2_count_sum  = 0;
					let level_2_income_sum = 0;
					for (let idx in filtered_accommodation_items) {
						level_2_count_sum  += sums_by_accommodation_items[filtered_accommodation_items[idx].id_accommodation_item].occupancy_count;
						level_2_income_sum += sums_by_accommodation_items[filtered_accommodation_items[idx].id_accommodation_item].income;
					}
					
					return <React.Fragment key={accommodation.id_accommodation}>
						<div className={'pt-1 pb-1 pl-2 border-solid border-gray-500 border-t font-extrabold ' + color}>{
							(accommodation.etourism_id_rno === null ? '' : (accommodation.etourism_id_rno + ' • ')) +
							accommodation.title
						}</div>
						<div className={'pt-1 pb-1 border-solid border-gray-500 border-t ' + color}></div>
						<div className={'pt-1 pb-1 border-solid border-gray-500 border-t font-extrabold text-right ' + color}>{
							(sums_by_accommodations[accommodation.id_accommodation]?.occupancy_count ?? 0) +
							'/' +
							(sums_by_accommodations[accommodation.id_accommodation]?.total_occupancy_count ?? 0)
						}</div>
						<div className={'pt-1 pb-1 pr-2 border-solid border-gray-500 border-t font-extrabold text-right ' + color}>{
							toLocal(
								(sums_by_accommodations[accommodation.id_accommodation]?.total_occupancy_count ?? 0) == 0
									? 0
									: (
										sums_by_accommodations[accommodation.id_accommodation].occupancy_count /
										sums_by_accommodations[accommodation.id_accommodation].total_occupancy_count * 100
									),
								2
							) + ' %'
						}</div>
						<div className={'pt-1 pb-1 pr-2 border-solid border-gray-500 border-t font-extrabold text-right ' + color}>
							<ProgressBar value={(sums_by_accommodations[accommodation.id_accommodation]?.total_occupancy_count ?? 0) == 0
								? 0
								: (sums_by_accommodations[accommodation.id_accommodation].occupancy_count / sums_by_accommodations[accommodation.id_accommodation].total_occupancy_count)} />
						</div>
						<div className={'pt-1 pb-1 border-solid border-gray-500 border-t ' + color}></div>
						<div className={'pt-1 pb-1 border-solid border-gray-500 border-t font-extrabold text-right ' + color}>{
							toLocal(sums_by_accommodations[accommodation.id_accommodation]?.income ?? 0, 2)
						}</div>
						<div className={'pt-1 pb-1 pr-2 border-solid border-gray-500 border-t font-extrabold text-right ' + color}>{
							toLocal(
								level_1_income_sum == 0
									? 0
									: ((sums_by_accommodations[accommodation.id_accommodation]?.income ?? 0) / level_1_income_sum * 100),
								2
							) + ' %'
						}</div>
						<div className={'pt-1 pb-1 pr-2 border-solid border-gray-500 border-t font-extrabold text-right ' + color}>
							<ProgressBar value={level_1_income_sum == 0
								? 0
								: ((sums_by_accommodations[accommodation.id_accommodation]?.income ?? 0) / level_1_income_sum)} />
						</div>
						
						{filtered_accommodation_items.filter(x => props.selected_id_accommodation_items.indexOf(x.id_accommodation_item) !== -1).map(accommodation_item => {
							let color = c % 2 == 0 ? 'bg-gray-350' : '';
							c++;
							
							const filtered_accommodation_item_places = Object.values(props.accommodation_item_places)
								.filter(accommodation_item_place =>
									accommodation_item_place.id_accommodation_item == accommodation_item.id_accommodation_item &&
									sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place] !== undefined
								);
							
							let level_3_count_sum  = 0;
							let level_3_income_sum = 0;
							for (let idx in filtered_accommodation_item_places) {
								level_3_count_sum  += sums_by_accommodation_item_places[filtered_accommodation_item_places[idx].id_accommodation_item_place].occupancy_count;
								level_3_income_sum += sums_by_accommodation_item_places[filtered_accommodation_item_places[idx].id_accommodation_item_place].income;
							}
							
							return <React.Fragment key={accommodation_item.id_accommodation_item}>
								{accommodation_item.title == 'default' ? null : <>
									<div className={'pt-1 pb-1 pl-4 font-bold ' + color}>{ accommodation_item.title == 'default' ? '' : accommodation_item.title }</div>
									<div className={'pt-1 pb-1 ' + color}></div>
									<div className={'pt-1 pb-1 text-right font-bold ' + color}>{
										sums_by_accommodation_items[accommodation_item.id_accommodation_item].occupancy_count +
										'/' +
										sums_by_accommodation_items[accommodation_item.id_accommodation_item].total_occupancy_count
									}</div>
									<div className={'pt-1 pb-1 text-right font-bold pr-2 ' + color}>{
										toLocal(
											sums_by_accommodation_items[accommodation_item.id_accommodation_item].total_occupancy_count == 0
												? 0
												: (
													sums_by_accommodation_items[accommodation_item.id_accommodation_item].occupancy_count /
													sums_by_accommodation_items[accommodation_item.id_accommodation_item].total_occupancy_count * 100
												),
											2
										) + ' %'
									}</div>
									<div className={'pt-1 pb-1 pr-2 text-right ' + color}>
										<ProgressBar value={sums_by_accommodation_items[accommodation_item.id_accommodation_item].total_occupancy_count == 0
											? 0
											: (sums_by_accommodation_items[accommodation_item.id_accommodation_item].occupancy_count / sums_by_accommodation_items[accommodation_item.id_accommodation_item].total_occupancy_count)} />
									</div>
									<div className={'pt-1 pb-1 ' + color}></div>
									<div className={'pt-1 pb-1 text-right font-bold ' + color}>{
										toLocal(sums_by_accommodation_items[accommodation_item.id_accommodation_item].income, 2)
									}</div>
									<div className={'pt-1 pb-1 text-right font-bold pr-2 ' + color}>{
										toLocal(
											level_2_income_sum == 0
												? 0
												: (sums_by_accommodation_items[accommodation_item.id_accommodation_item].income / level_2_income_sum * 100),
											2
										) + ' %'
									}</div>
									<div className={'pt-1 pb-1 pr-2 text-right ' + color}>
										<ProgressBar value={level_2_income_sum == 0
											? 0
											: (sums_by_accommodation_items[accommodation_item.id_accommodation_item].income / level_2_income_sum)} />
									</div>
								</>}
								
								{filtered_accommodation_item_places
									.sort((a, b) => a.ord - b.ord)
									.map(accommodation_item_place => {
										let color = c % 2 == 0 ? 'bg-gray-350' : '';
										c++;
										
										return <React.Fragment key={accommodation_item_place.id_accommodation_item_place}>
											<div className={'pt-1 pb-1 pl-8 ' + color}>{ accommodation_item_place.title }</div>
											<div className={'pt-1 pb-1 ' + color}></div>
											<div className={'pt-1 pb-1 text-right ' + color}>{
												sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].occupancy_count +
												'/' +
												sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].total_occupancy_count
											}</div>
											<div className={'pt-1 pb-1 text-right pr-2 ' + color}>{
												toLocal(
													sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].total_occupancy_count == 0
														? 0
														: (
															sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].occupancy_count /
															sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].total_occupancy_count * 100
														),
													2
												) + ' %'
											}</div>
											<div className={'pt-1 pb-1 pr-2 text-right ' + color}>
												<ProgressBar value={sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].total_occupancy_count == 0
													? 0
													: (sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].occupancy_count / sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].total_occupancy_count)} />
											</div>
											<div className={'pt-1 pb-1 ' + color}></div>
											<div className={'pt-1 pb-1 text-right ' + color}>{
												toLocal(sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].income, 2)
											}</div>
											<div className={'pt-1 pb-1 text-right pr-2 ' + color}>{
												toLocal(
													level_3_income_sum == 0
														? 0
														: (sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].income / level_3_income_sum * 100),
													2
												) + ' %'
											}</div>
											<div className={'pt-1 pb-1 pr-2 text-right ' + color}>
												<ProgressBar value={level_3_income_sum == 0
													? 0
													: (sums_by_accommodation_item_places[accommodation_item_place.id_accommodation_item_place].income / level_3_income_sum)} />
											</div>
										</React.Fragment>;
									})
								}
							</React.Fragment>;
							
							return retval;
						})}
					</React.Fragment>;
				})
			}
			
			{(() => {
				let color = c % 2 == 0 ? 'bg-gray-350' : '';
				c++;
				
				return <React.Fragment>
					<div className={'pt-1 pb-1 pl-2 font-bold border-solid border-gray-500 border-t-2 ' + color}>SKUPAJ</div>
					<div className={'pt-1 pb-1 border-solid border-gray-500 border-t-2 ' + color}></div>
					<div className={'pt-1 pb-1 text-right font-bold border-solid border-gray-500 border-t-2 ' + color}>{
						sums_total.occupancy_count +
						'/' +
						sums_total.total_occupancy_count
					}</div>
					<div className={'pt-1 pb-1 text-right font-bold pr-2 border-solid border-gray-500 border-t-2 ' + color}>{
						toLocal(
							sums_total.total_occupancy_count == 0
								? 0
								: (
									sums_total.occupancy_count /
									sums_total.total_occupancy_count * 100
								),
							2
						) + ' %'
					}</div>
					<div className={'pt-1 pb-1 pr-2 text-right border-solid border-gray-500 border-t-2 ' + color}>
						<ProgressBar value={sums_total.total_occupancy_count == 0
							? 0
							: (sums_total.occupancy_count / sums_total.total_occupancy_count)} />
					</div>
					<div className={'pt-1 pb-1 border-solid border-gray-500 border-t-2 ' + color}></div>
					<div className={'pt-1 pb-1 text-right font-bold border-solid border-gray-500 border-t-2 ' + color}>{
						toLocal(sums_total.income, 2)
					}</div>
					<div className={'pt-1 pb-1 text-right font-bold pr-2 border-solid border-gray-500 border-t-2 ' + color}>{
						toLocal(
							100,
							2
						) + ' %'
					}</div>
					<div className={'pt-1 pb-1 pr-2 text-right border-solid border-gray-500 border-t-2 ' + color}>
						<ProgressBar value={1} />
					</div>
				</React.Fragment>;
			})()}
		</div>
	</div>;
}

function ProgressBar(props) {
	return <div style={{ display: 'inline-block', width: 220, height: 8, position: 'relative' }}>
		<div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: '#ffffff', borderRadius: 4 }} />
		<div style={{ position: 'absolute', left: 0, top: 0, right: (100 - props.value * 100) + '%', bottom: 0, backgroundColor: '#394b59', borderRadius: 4 }} />
	</div>;
}

function ReservationsReport(props) {
	let [ {
		from_date,
		until_date,
		type,
		statuses,
		id_accommodation_items,
		display_inactive,
	}, setQuery ] = useQueryParams();
	
	from_date              = from_date              === undefined ? moment().startOf('month').toDate()     : new Date(from_date);
	until_date             = until_date             === undefined ? new Date()                             : new Date(until_date);
	type                   = type                   === undefined ? Type.occupancy_income                  : type;
	statuses               = statuses               === undefined ? [
		'new',
		'waiting-for-confirmation',
		'offer-sent',
		'confirmed',
		'advance-invoice-sent',
		'closed',
	] : statuses;
	id_accommodation_items = id_accommodation_items === undefined ? [] /*Object.keys(props.accommodation_items)*/ : id_accommodation_items;
	display_inactive       = display_inactive       === undefined ? false : (display_inactive === 'true');
	
	if (id_accommodation_items === '') id_accommodation_items = [];
	if (!Array.isArray(id_accommodation_items)) id_accommodation_items = id_accommodation_items.split(',');
	
	if (statuses === '') statuses = [];
	if (!Array.isArray(statuses)) statuses = statuses.split(',');
	
	const getCurrentQueryParams = () => {
		return {
			from_date: moment(from_date).format('YYYY-MM-DD'),
			until_date: moment(until_date).format('YYYY-MM-DD'),
			type,
			statuses,
			id_accommodation_items,
			display_inactive: display_inactive ? 'true': 'false',
		};
	};
	const setMainFilter = (from, until, type, statuses, id_accommodation_items, display_inactive) => {
		setQuery({
			...getCurrentQueryParams(),
			from_date: moment(from).format('YYYY-MM-DD'),
			until_date: moment(until).format('YYYY-MM-DD'),
			type,
			statuses: statuses.join(','),
			id_accommodation_items: id_accommodation_items.join(','),
			display_inactive: display_inactive ? 'true': 'false',
		});
	};
	
	let view = null;
	if (type == Type.occupancy_income) {
		view = <OccupancyIncome
			from_date             ={from_date}
			until_date            ={until_date}
			accommodations        ={props.accommodations}
			accommodation_items   ={props.accommodation_items}
			accommodation_item_places={props.accommodation_item_places}
			reservation_customers ={props.reservation_customers}
			countries             ={props.countries}
			customers             ={props.customers}
			tourist_taxes         ={props.tourist_taxes}
			guest_book_items      ={props.guest_book_items}
			reservations          ={props.reservations}
			selected_id_accommodation_items={id_accommodation_items}
			statuses              ={statuses}
			document_associations ={props.document_associations}
			invoices              ={props.invoices}
			display_inactive      ={display_inactive} />;
	}
	else if (type == Type.arrivals) {
		view = <Arrivals
			from_date             ={from_date}
			until_date            ={until_date}
			accommodations        ={props.accommodations}
			accommodation_items   ={props.accommodation_items}
			accommodation_item_places={props.accommodation_item_places}
			reservation_customers ={props.reservation_customers}
			countries             ={props.countries}
			customers             ={props.customers}
			tourist_taxes         ={props.tourist_taxes}
			guest_book_items      ={props.guest_book_items}
			reservations          ={props.reservations}
			selected_id_accommodation_items={id_accommodation_items} />;
	}
	
	return <div className='pl-4 pr-4'>
		<div className='flex flex-col'>
			<MainFilter
				date_range={[ from_date, until_date ]}
				onChangeDate={date_range => {
					setFromUntilDate(date_range[0], date_range[1]);
				}}
				type={type}
				statuses={statuses}
				accommodations={props.accommodations}
				accommodation_items={props.accommodation_items}
				selected_id_accommodation_items={id_accommodation_items}
				display_inactive={display_inactive}
				onChangeType={type => setType(type)}
				onChangeFilters={filters => {
					setMainFilter(
						filters.dates[0],
						filters.dates[1],
						filters.type,
						filters.statuses,
						filters.id_accommodation_items,
						filters.display_inactive
					);
				}}
				api_url={props.api_url} />
		</div>
		
		<div className='flex flex-row pb-2'>
			{view}
		</div>
	</div>;
}

function mapStateToProps(state) {
	return {
		client:                    state.ConstantsSlice.client,
		api_url:                   state.CashRegisterSlice.api_url,
		token:                     state.UserSlice.token,
		user:                      state.UserSlice.user,
		accommodations:            state.CodeTablesSlice.accommodations,
		accommodation_items:       state.CodeTablesSlice.accommodation_items,
		accommodation_item_places: state.CodeTablesSlice.accommodation_item_places,
		countries:                 state.CodeTablesSlice.countries,
		customers:                 state.CodeTablesSlice.customers,
		tourist_taxes:             state.CodeTablesSlice.tourist_taxes,
		reservation_customers:     state.ReservationSlice.reservation_customers,
		reservations:              state.ReservationSlice.reservations,
		guest_book_items:          state.GuestSlice.guest_book_items,
		document_associations:     state.DocumentSlice.document_associations_id_documents,
		invoices:                  state.BusinessSlice.invoices,
	};
}

export default connect(mapStateToProps)(ReservationsReport);
