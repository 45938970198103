import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import {
	Button,
	Navbar,
	Dialog,
	Classes,
	Intent,
} from '@blueprintjs/core';
import { DateRangePicker3 } from '@blueprintjs/datetime2';

import { loadDateFnsLocale } from '../utils';

class DateRangePickerDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			selectedDateStart: this.props.startValue,
			selectedDateEnd:   this.props.endValue,
		};
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
		}
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={false}
			canEscapeKeyClose={false}
			className='date-range-picker-dialog'>
			
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Izbira datuma</Navbar.Heading>
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-grow flex-col'>
						<div className='self-center'>
							<DateRangePicker3
								dateFnsLocaleLoader={loadDateFnsLocale}
								dateFnsFormat={'dd. MM. yyyy'}
								locale='sl'
								minDate={new Date('1900-01-01')}
								maxDate={moment().add('10', 'years').toDate()}
								clearButtonText='Počisti'
								allowSingleDayRange={true}
								shortcuts={false}
								reverseMonthAndYearMenus={true}
								onChange={ selectedDates => {
									//handleFilterChange('invoice_date_from', selectedDate)
									this.setState({
										selectedDateStart: selectedDates[0],
										selectedDateEnd:   selectedDates[1],
									});
									
									//this.props.closeDialog(this.state.selectedDateStart, this.state.selectedDateEnd)
								}}
								value={[ this.state.selectedDateStart, this.state.selectedDateEnd ]} />
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => { this.props.closeDialog() }}>
							Prekliči
						</Button>
						<Button
							intent={Intent.DANGER}
							onClick={() => { this.props.closeDialog(null, null) }}>
							Počisti
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => { this.props.closeDialog(this.state.selectedDateStart, this.state.selectedDateEnd) }}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
DateRangePickerDialog.propTypes = {
	closeDialog: PropTypes.func,
	startValue: PropTypes.object,
	endValue: PropTypes.object,
};

export default DateRangePickerDialog;
