import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Button,
	MenuItem,
	FormGroup,
} from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';

import moment from 'moment';

class CustomerSelect extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			query: '',
		};
		
		this.itemRenderer = this.itemRenderer.bind(this);
		this.filterItems  = this.filterItems .bind(this);
		
		this.search_field_id = Math.floor(Math.random() * Math.floor(99999999));
		this.has_focused = false;
	}
	
	componentDidMount() {
		if (this.props.focus === true && !this.has_focused) {
			const main_person_input = document.querySelector('.main-person-input-' + this.search_field_id + ' input');
			if (main_person_input !== null) {
				main_person_input.focus();
				this.has_focused = true;
			}
		}
		
		//if (this.state.names.length == 0)
		//{
		//	Promise.all([
		//		fetch('names.txt')
		//			.then(function(response) {
		//				return response.text();
		//			})
		//			.then(function(text) {
		//				return text.split('\n');
		//			}),
		//		fetch('surnames.txt')
		//			.then(function(response) {
		//				return response.text();
		//			})
		//			.then(function(text) {
		//				return text.split('\n');
		//			}),
		//	]).then(values => {
		//		let names = [];
		//		/*for (let i=0; i<values[1].length; i++)
		//		{
		//			names.push(values[0][i % values[0].length] + ' ' + values[1][i]);
		//		}*/
		//		names = values[1];
		//		
		//		console.log('Name count: ' + names.length);
		//		
		//		this.setState({
		//			names: names.map(x => { return { name: x }; }),
		//		});
		//	});
		//}
	}
	
	itemRenderer(item, props) {
		/*const text = <div className='flex flex-row'>
			<div className='w-1/4 mr-2'>
				{this.highlightText(item.surname + ' ' + item.name, props.query)}
			</div>
			<div className='w-1/4 mr-2'>
				{ item.email }
			</div>
			<div className='w-1/4 mr-2'>
				{ this.props.countries[item.id_country].official_name_local }
			</div>
			<div className='w-1/4'>
				{ item.phone }
			</div>
		</div>;*/
		
		const label_arr = [];
		
		/*const birth_date = (item.birth_date === null ? null : new Date(item.birth_date));
		if (birth_date !== null) {
			label_arr.push(birth_date.getDate() + '.' + (birth_date.getMonth()+1) + '.' + birth_date.getFullYear());
		}
		if (item.identification_number !== null && item.identification_number.length > 0) {
			label_arr.push(
				(item.id_identification_type === null ?
					'' : this.props.identification_types[item.id_identification_type].internal_code + ' '
				) +
				item.identification_number
			);
		}*/
		if (item.tax_number !== null && item.tax_number.length > 0) {
			label_arr.push(
				(item.vat_prefix === null ? '' : item.vat_prefix) +
				item.tax_number
			);
		}
		if (item.phone !== null && item.phone.length > 0) {
			label_arr.push(item.phone);
		}
		if (item.email !== null && item.email.length > 0) {
			label_arr.push(item.email);
		}
		if (item.id_country !== null) {
			label_arr.push(this.props.countries[item.id_country].common_name_international);
		}
		
		return <MenuItem
			key={'item-' + item.id_customer}
			active={props.modifiers.active}
			disabled={props.modifiers.disabled}
			text={
				<>
					<div className='flex justify-between'>
						<div>
							{this.highlightText(
								item.type == 'company' ? item.company_name : (item.surname + ' ' + item.name),
								props.query
							)}
						</div>
						<div>{item.birth_date === null ? '' : moment(item.birth_date).format('DD.MM.YYYY')}</div>
					</div>
				</>
			}
			className='items-stretch'
			label={label_arr.join(', ')}
			onClick={props.handleClick} />;
		// return <div>
		// 	{this.highlightText(item.name, props.query)}
		// </div>;
	}
	
	filterItems(query, items) {
		let filtered_items = [...items];
		
		if (this.props.itemFilter !== undefined && this.props.itemFilter !== null) {
			filtered_items = filtered_items.filter(this.props.itemFilter);
		}
		
		if (query.length > 0)
		{
			const normalized_query = query.toLowerCase();
			
			filtered_items = filtered_items.filter(item => {
				if (this.props.filter_type == 'natural' && item.type == 'company') return false;
				if (this.props.filter_type == 'company' && item.type == 'natural') return false;
				
				const normalized_name = 
					item.type == 'company' ?
						item.company_name.toLowerCase() :
						(item.surname.toLowerCase() + ' ' + item.name.toLowerCase());
				
				if (`${normalized_name}`.indexOf(normalized_query) > -1)
				{
					return true;
				}
				return false;
			});
		}
		
		// limit results to 50 items
		if (filtered_items.length >= 50) {
			filtered_items = filtered_items.slice(0, 50 + 1);
		}
		
		return filtered_items;
	}
	
	highlightText(text, query) {
		let lastIndex = 0;
		const words = query
			.split(/\s+/)
			.filter(word => word.length > 0)
			.map(this.escapeRegExpChars);
		if (words.length === 0) {
			return [text];
		}
		const regexp = new RegExp(words.join("|"), "gi");
		const tokens = [];
		while (true) {
			const match = regexp.exec(text);
			if (!match) {
				break;
			}
			const length = match[0].length;
			const before = text.slice(lastIndex, regexp.lastIndex - length);
			if (before.length > 0) {
				tokens.push(before);
			}
			lastIndex = regexp.lastIndex;
			tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
		}
		const rest = text.slice(lastIndex);
		if (rest.length > 0) {
			tokens.push(rest);
		}
		return tokens;
	}
	escapeRegExpChars(text) {
		return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	}
	
	render() {
		const form_group_cls = (this.props.formGroupCls === undefined ? '' : this.props.formGroupCls);
		
		return (
			<FormGroup
				labelFor='main-person-input'
				className={'flex-grow ' + form_group_cls}>
				<div className={this.props.titleClassName || ''}>{this.props.fieldTitle}</div>
				<Suggest
					id='main-person-input'
					inputValueRenderer={item => item.type == 'natural' ? item.surname + ' ' + item.name : item.company_name}
					itemListPredicate={this.filterItems}
					itemRenderer={this.itemRenderer}
					items={Object.values(this.props.customers)}
					onItemSelect={item => {
						this.props.itemSelected(item);
						
						this.setState({
							query: '',
						});
					}}
					selectedItem={null}
					openOnKeyDown={true}
					inputProps={{
						rightElement: this.props.addPersonClick === undefined ? null : <Button
							icon={'plus'}
							minimal={true}
							disabled={this.state.query.length < 3}
							onClick={() => {
								this.props.addPersonClick(this.state.query);
								this.setState({ query: '' });
							}} />,
						className: 'main-person-input main-person-input-' + this.search_field_id,
						placeholder: 'Išči',
					}}
					popoverProps={{
						targetProps: { style: { display: 'block' } },
						minimal: true,
					}}
					query={this.state.query}
					onQueryChange={query => {
						this.setState({
							query,
						});
						
						if (this.props.queryChanged !== undefined) {
							this.props.queryChanged(query);
						}
					}} />
			</FormGroup>
		);
	}
}
CustomerSelect.propTypes = {
	itemSelected: PropTypes.func,
	addPersonClick: PropTypes.func,
	queryChanged: PropTypes.func,
	fieldTitle: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		customers:            state.CodeTablesSlice.customers,
		countries:            state.CodeTablesSlice.countries,
		identification_types: state.CodeTablesSlice.identification_types,
	};
}

export default connect(mapStateToProps)(CustomerSelect);
