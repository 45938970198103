import {
	addInvoice,
	addInvoices,
	addInvoiceFiscalVerification,
	addInvoiceFiscalVerifications,
	addAdvanceInvoiceConsumption,
	addAdvanceInvoiceConsumptions,
} from '../slices/BusinessSlice';
import {
	setUser,
} from '../slices/UserSlice';
import {
	setCriticalError,
} from '../slices/AppSlice';

import ErrorReportingHelper from '../helpers/ErrorReportingHelper';

export async function loadInvoices(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Invoices/v2/invoices', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addInvoices(items));
	}
}

export async function loadInvoice(api_url, id_invoice, dispatch, token) {
	let response = await fetch(api_url + 'Invoices/v1/invoice?id_invoice=' + id_invoice, { headers: { 'Authorization': 'Bearer ' + token } });
	let item = await response.json();
	
	if (item.error_code !== undefined) {
		if (item.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addInvoice({ item }));
	}
	
	return item;
}

export async function loadInvoiceFiscalVerifications(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Invoices/v2/invoice-fiscal-verifications', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addInvoiceFiscalVerifications(items));
	}
}

export async function loadLatestInvoiceFiscalVerifications(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Invoices/v2/latest-invoice-fiscal-verifications', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addInvoiceFiscalVerifications(items));
	}
}

export async function loadInvoiceFiscalVerificationsByIds(api_url, ids, dispatch, token) {
	const form_data = new FormData();
	form_data.set('ids', ids.join(','));
	
	let response = await fetch(api_url + 'Invoices/v2/invoice-fiscal-verifications-by-ids', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addInvoiceFiscalVerifications(items));
	}
}

export async function loadInvoiceFiscalVerification(api_url, id_invoice_fiscal_verification, dispatch, token) {
	let response = await fetch(
		api_url + 'Invoices/v1/invoice-fiscal-verification?id_invoice_fiscal_verification=' + id_invoice_fiscal_verification,
		{ headers: { 'Authorization': 'Bearer ' + token } }
	);
	let item = await response.json();
	
	if (item.error_code !== undefined) {
		if (item.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addInvoiceFiscalVerification({ item }));
	}
	
	return item;
}

export async function saveInvoice(api_url, item, token, dispatch) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let text_response = 'n/a';
	try {
		let response = await fetch(api_url + 'Invoices/v1/save-invoice', {
			method:  'post',
			body:    form_data,
			headers: { 'Authorization': 'Bearer ' + token },
		});
		text_response = await response.text();
		let new_item = JSON.parse(text_response);
		
		if (new_item.error_code !== undefined) {
			if (new_item.error_code == 20001) { // invalid token
				//dispatch(setUser({ user_info: null, token: null }));
			}
		}
		else {
			//delete new_item.items;
			//delete new_item.payments;
			
			//dispatch(addInvoice(new_item));
			
			return new_item;
		}
	}
	catch (e) {
		ErrorReportingHelper.ReportError(
			e.message,
			{
				context: {
					method: 'api.Business.saveInvoice',
					item,
					server_response: text_response,
				},
			}
		);
		dispatch(setCriticalError(JSON.stringify(e)));
	}
	return null;
}

export async function deleteInvoice(api_url, id, token) {
	const form_data = new FormData();
	form_data.set('id_invoice', id);
	
	let response = await fetch(api_url + 'Invoices/v1/delete-invoice', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let data = await response.json();
	
	if (data.error_code !== undefined) {
		if (data.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return {
			id_invoice_items:                   data.id_invoice_items,
			id_invoice_payments:                data.id_invoice_payments,
			id_cash_register_document_invoices: data.id_cash_register_document_invoices,
			id_advance_invoice_consumptions:    data.id_advance_invoice_consumptions,
			id_document_associations:           data.id_document_associations,
			id_invoices:                        data.id_invoices,
		};
	}
	return null;
}

export async function loadAdvanceInvoiceConsumptions(api_url, dispatch, token) {
	let response = await fetch(
		api_url + 'Invoices/v1/advance-invoice-consumptions',
		{ headers: { 'Authorization': 'Bearer ' + token } }
	);
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addAdvanceInvoiceConsumptions(items));
	}
}

export async function loadAdvanceInvoiceConsumption(api_url, id_advance_invoice_consumption, dispatch, token) {
	let response = await fetch(
		api_url + 'Invoices/v1/advance-invoice-consumption?id_advance_invoice_consumption=' + id_advance_invoice_consumption,
		{ headers: { 'Authorization': 'Bearer ' + token } }
	);
	let item = await response.json();
	
	if (item.error_code !== undefined) {
		if (item.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addAdvanceInvoiceConsumption({ item }));
	}
}

export async function fiscallyVerifyInvoices(api_url, id_invoices, token) {
	const form_data = new FormData();
	form_data.set('id_invoices', id_invoices.join(','));
	
	await fetch(api_url + 'Invoices/v1/fiscally-verify-invoices', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
}

export async function increaseInvoicePrintCount(api_url, id_invoice, token) {
	const form_data = new FormData();
	form_data.set('id_invoice', id_invoice);
	
	await fetch(api_url + 'Invoices/v1/increase-invoice-print-count', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
}
