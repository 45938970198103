import { createSlice } from '@reduxjs/toolkit';
import uuid from 'uuid';

import { saveCodeTableItem, deleteCodeTableItem } from '../api/CodeTables';

function addItemToState(state_key_name, key_name, payload, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	const item  = payload.item;
	const token = payload.token;
	
	if (item[key_name] == -1) {
		item[key_name] = uuid.v4();
	}
	
	state[state_key_name][item[key_name]] = item;
	
	if (payload.save_to_api === true) {
		saveCodeTableItem(state.api_url, state_key_name, item, token);
	}
}
function addItemsToState(state_key_name, key_name, items, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	for (let i=0; i<items.length; i++) {
		const item = items[i];
		if (state_key_name == 'items') {
			const price_lists = {};
			for (let j=0; j<item.price_lists.length; j++) {
				price_lists[item.price_lists[j].id_item_price_list] = item.price_lists[j];
			}
			item.price_lists = price_lists;
		}
		
		state[state_key_name][items[i][key_name]] = items[i];
	}
}
function deleteItemFromState(state_key_name, payload, state) {
	const key_value = payload.key_value;
	const token     = payload.token;
	
	delete state[state_key_name][key_value];
	
	deleteCodeTableItem(state.api_url, state_key_name, key_value, token);
}

const CodeTablesSlice = createSlice({
	name: 'code_tables',
	initialState: {}, // defined in AppContainer.js
	reducers: {
		addCountry:                   (state, action) =>
			addItemToState     ('countries',                 'id_country',                  action.payload, state),
		addCountries:                 (state, action) =>
			addItemsToState    ('countries',                 'id_country',                  action.payload, state),
		deleteCountry:                (state, action) =>
			deleteItemFromState('countries',                                                action.payload, state),
		addPostOffice:                (state, action) =>
			addItemToState     ('post_offices',              'id_post_office',              action.payload, state),
		addPostOffices:               (state, action) =>
			addItemsToState    ('post_offices',              'id_post_office',              action.payload, state),
		deletePostOffice:             (state, action) =>
			deleteItemFromState('post_offices',                                             action.payload, state),
		addBusinessPremise:           (state, action) =>
			addItemToState     ('business_premises',         'id_business_premise',         action.payload, state),
		addBusinessPremises:          (state, action) =>
			addItemsToState    ('business_premises',         'id_business_premise',         action.payload, state),
		deleteBusinessPremise:        (state, action) =>
			deleteItemFromState('business_premises',                                        action.payload, state),
		addChannel:                   (state, action) =>
			addItemToState     ('channels',                  'id_channel',                  action.payload, state),
		addChannels:                  (state, action) =>
			addItemsToState    ('channels',                  'id_channel',                  action.payload, state),
		deleteChannel:                (state, action) =>
			deleteItemFromState('channels',                                                 action.payload, state),
		addCustomer:                  (state, action) =>
			addItemToState     ('customers',                 'id_customer',                 action.payload, state),
		addCustomers:                 (state, action) =>
			addItemsToState    ('customers',                 'id_customer',                 action.payload, state),
		deleteCustomer:               (state, action) =>
			deleteItemFromState('customers',                                                action.payload, state),
		addMeasuringUnit:             (state, action) =>
			addItemToState     ('measuring_units',           'id_measuring_unit',           action.payload, state),
		addMeasuringUnits:            (state, action) =>
			addItemsToState    ('measuring_units',           'id_measuring_unit',           action.payload, state),
		deleteMeasuringUnit:          (state, action) =>
			deleteItemFromState('measuring_units',                                          action.payload, state),
		addPaymentType:               (state, action) =>
			addItemToState     ('payment_types',             'id_payment_type',             action.payload, state),
		addPaymentTypes:              (state, action) =>
			addItemsToState    ('payment_types',             'id_payment_type',             action.payload, state),
		deletePaymentType:            (state, action) =>
			deleteItemFromState('payment_types',                                            action.payload, state),
		addTaxRate:                   (state, action) =>
			addItemToState     ('tax_rates',                 'id_tax_rate',                 action.payload, state),
		addTaxRates:                  (state, action) =>
			addItemsToState    ('tax_rates',                 'id_tax_rate',                 action.payload, state),
		deleteTaxRate:                (state, action) =>
			deleteItemFromState('tax_rates',                                                action.payload, state),
		addWarehouse:                 (state, action) =>
			addItemToState     ('warehouses',                'id_warehouse',                action.payload, state),
		addWarehouses:                (state, action) =>
			addItemsToState    ('warehouses',                'id_warehouse',                action.payload, state),
		deleteWarehouse:              (state, action) =>
			deleteItemFromState('warehouses',                                               action.payload, state),
		addAccommodation:             (state, action) =>
			addItemToState     ('accommodations',            'id_accommodation',            action.payload, state),
		addAccommodations:            (state, action) =>
			addItemsToState    ('accommodations',            'id_accommodation',            action.payload, state),
		deleteAccommodation:          (state, action) =>
			deleteItemFromState('accommodations',                                           action.payload, state),
		addAccommodationItem:         (state, action) =>
			addItemToState     ('accommodation_items',       'id_accommodation_item',       action.payload, state),
		addAccommodationItems:        (state, action) =>
			addItemsToState    ('accommodation_items',       'id_accommodation_item',       action.payload, state),
		deleteAccommodationItem:      (state, action) =>
			deleteItemFromState('accommodation_items',                                      action.payload, state),
		addAccommodationItemPlace:    (state, action) =>
			addItemToState     ('accommodation_item_places', 'id_accommodation_item_place', action.payload, state),
		addAccommodationItemPlaces:   (state, action) =>
			addItemsToState    ('accommodation_item_places', 'id_accommodation_item_place', action.payload, state),
		deleteAccommodationItemPlace: (state, action) =>
			deleteItemFromState('accommodation_item_places',                                action.payload, state),
		addAccommodationItemItem: (state, action) =>
			addItemToState     ('accommodation_item_items',  'id_accommodation_item_item',  action.payload, state),
		addAccommodationItemItems: (state, action) =>
			addItemsToState    ('accommodation_item_items',  'id_accommodation_item_item',  action.payload, state),
		deleteAccommodationItemItem: (state, action) =>
			deleteItemFromState('accommodation_item_items',                                 action.payload, state),
		addAccommodationItemPlaceItem: (state, action) =>
			addItemToState     ('accommodation_item_place_items', 'id_accommodation_item_place_item', action.payload, state),
		addAccommodationItemPlaceItems: (state, action) =>
			addItemsToState    ('accommodation_item_place_items', 'id_accommodation_item_place_item', action.payload, state),
		deleteAccommodationItemPlaceItem: (state, action) =>
			deleteItemFromState('accommodation_item_place_items',                                     action.payload, state),
		addAccommodationItemSchedule: (state, action) =>
			addItemToState     ('accommodation_item_schedules',   'id_accommodation_item_schedule',   action.payload, state),
		addAccommodationItemSchedules: (state, action) =>
			addItemsToState    ('accommodation_item_schedules',   'id_accommodation_item_schedule',   action.payload, state),
		deleteAccommodationItemSchedule: (state, action) =>
			deleteItemFromState('accommodation_item_schedules',                                       action.payload, state),
		addAccommodationItemScheduleTimeSlot: (state, action) =>
			addItemToState     ('accommodation_item_schedule_time_slots', 'id_accommodation_item_schedule_time_slot', action.payload, state),
		addAccommodationItemScheduleTimeSlots: (state, action) =>
			addItemsToState    ('accommodation_item_schedule_time_slots', 'id_accommodation_item_schedule_time_slot', action.payload, state),
		deleteAccommodationItemScheduleTimeSlot: (state, action) =>
			deleteItemFromState('accommodation_item_schedule_time_slots',                                             action.payload, state),
		addAccommodationItemScheduleTimeSlotActivity: (state, action) =>
			addItemToState     ('accommodation_item_schedule_time_slot_activities', 'id_accommodation_item_schedule_time_slot_activity',
				action.payload, state),
		addAccommodationItemScheduleTimeSlotActivities: (state, action) =>
			addItemsToState    ('accommodation_item_schedule_time_slot_activities', 'id_accommodation_item_schedule_time_slot_activity',
				action.payload, state),
		deleteAccommodationItemScheduleTimeSlotActivity: (state, action) =>
			deleteItemFromState('accommodation_item_schedule_time_slot_activities',
				action.payload, state),
		addItem:                      (state, action) =>
			addItemToState     ('items',                     'id_item',                     action.payload, state),
		addItems:                     (state, action) =>
			addItemsToState    ('items',                     'id_item',                     action.payload, state),
		deleteItem:                   (state, action) =>
			deleteItemFromState('items',                                                    action.payload, state),
		addMarketSegmentationItem:    (state, action) =>
			addItemToState     ('market_segmentation',       'id_market_segmentation',      action.payload, state),
		addMarketSegmentation:        (state, action) =>
			addItemsToState    ('market_segmentation',       'id_market_segmentation',      action.payload, state),
		deleteMarketSegmentationItem: (state, action) =>
			deleteItemFromState('market_segmentation',                                      action.payload, state),
		addActivity:                  (state, action) =>
			addItemToState     ('activities',                'id_activity',                action.payload, state),
		addActivities:                (state, action) =>
			addItemsToState    ('activities',                'id_activity',                action.payload, state),
		deleteActivity:               (state, action) =>
			deleteItemFromState('activities',                                              action.payload, state),
		addIdentificationType:        (state, action) =>
			addItemToState     ('identification_types',      'id_identification_type',     action.payload, state),
		addIdentificationTypes:       (state, action) =>
			addItemsToState    ('identification_types',      'id_identification_type',     action.payload, state),
		deleteIdentificationType:     (state, action) =>
			deleteItemFromState('identification_types',                                    action.payload, state),
		addTouristTax:        (state, action) =>
			addItemToState     ('tourist_taxes',             'id_tourist_tax',             action.payload, state),
		addTouristTaxes:       (state, action) =>
			addItemsToState    ('tourist_taxes',             'id_tourist_tax',             action.payload, state),
		deleteTouristTax:     (state, action) =>
			deleteItemFromState('tourist_taxes',                                           action.payload, state),
		addBusinessPremiseFiscalRegistrations: (state, action) => {
			addItemsToState('business_premise_fiscal_registrations', 'id_business_premise_fiscal_registration', action.payload, state);
			
			// add by id business premise
			const items = action.payload;
			const items_by_id_business_premise = {};
			
			for (let i=0; i<items.length; i++) {
				if (items_by_id_business_premise[items[i].id_business_premise] === undefined) {
					items_by_id_business_premise[items[i].id_business_premise] = [];
				}
				items_by_id_business_premise[items[i].id_business_premise].push(items[i]);
			}
			state['business_premise_fiscal_registrations_by_id_business_premises'] = items_by_id_business_premise;
		},
		//	const todo = state.find(todo => todo.id === action.payload)
		//	if (todo) {
		//		todo.completed = !todo.completed
		//	}
		//}
		addDailyLogbookActivity:   (state, action) =>
			addItemToState ('daily_logbook_activities', 'id_daily_logbook_activity', action.payload, state),
		addDailyLogbookActivities: (state, action) =>
			addItemsToState('daily_logbook_activities', 'id_daily_logbook_activity', action.payload, state),
		addWorkType:   (state, action) =>
			addItemToState ('work_types', 'id_work_type', action.payload, state),
		addWorkTypes: (state, action) =>
			addItemsToState('work_types', 'id_work_type', action.payload, state),
	}
});

export const {
	addCountry,
	addCountries,
	deleteCountry,
	addPostOffice,
	addPostOffices,
	deletePostOffice,
	addBusinessPremise,
	addBusinessPremises,
	deleteBusinessPremise,
	addChannel,
	addChannels,
	deleteChannel,
	addCustomer,
	addCustomers,
	deleteCustomer,
	addMeasuringUnit,
	addMeasuringUnits,
	deleteMeasuringUnit,
	addPaymentType,
	addPaymentTypes,
	deletePaymentType,
	addTaxRate,
	addTaxRates,
	deleteTaxRate,
	addWarehouse,
	addWarehouses,
	deleteWarehouse,
	addAccommodation,
	addAccommodations,
	deleteAccommodation,
	addAccommodationItem,
	addAccommodationItems,
	deleteAccommodationItem,
	addAccommodationItemPlace,
	addAccommodationItemPlaces,
	deleteAccommodationItemPlace,
	addAccommodationItemItem,
	addAccommodationItemItems,
	deleteAccommodationItemItem,
	addAccommodationItemPlaceItem,
	addAccommodationItemPlaceItems,
	deleteAccommodationItemPlaceItem,
	addAccommodationItemSchedule,
	addAccommodationItemSchedules,
	deleteAccommodationItemSchedule,
	addAccommodationItemScheduleTimeSlot,
	addAccommodationItemScheduleTimeSlots,
	deleteAccommodationItemScheduleTimeSlot,
	addAccommodationItemScheduleTimeSlotActivity,
	addAccommodationItemScheduleTimeSlotActivities,
	deleteAccommodationItemScheduleTimeSlotActivity,
	addItem,
	addItems,
	deleteItem,
	addMarketSegmentationItem,
	addMarketSegmentation,
	deleteMarketSegmentationItem,
	addActivity,
	addActivities,
	deleteActivity,
	addIdentificationType,
	addIdentificationTypes,
	deleteIdentificationType,
	addTouristTax,
	addTouristTaxes,
	deleteTouristTax,
	addBusinessPremiseFiscalRegistrations,
	addDailyLogbookActivity,
	addDailyLogbookActivities,
	addWorkType,
	addWorkTypes,
} = CodeTablesSlice.actions;
export default CodeTablesSlice.reducer;
