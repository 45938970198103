import { createSlice } from '@reduxjs/toolkit'
import uuid from 'uuid';

function addItem(state_key_name, key_name, item, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	if (item[key_name] == -1) {
		item[key_name] = uuid.v4();
	}
	
	state[state_key_name][item[key_name]] = item;
	
	return item;
}
function addItems(state_key_name, key_name, items, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	
	for (let i=0; i<items.length; i++) {
		state[state_key_name][items[i][key_name]] = items[i];
	}
}

const GuestSlice = createSlice({
	name: 'guests',
	initialState: {}, // defined in AppContainer.js
	reducers: {
		addGuestBookItem: (state, action) => {
			const item = addItem('guest_book_items', 'id_guest_book', action.payload.item, state);
		},
		batchAddGuestBookItems: (state, action) => {
			for (let i=0; i<action.payload.items.length; i++) {
				addItem('guest_book_items', 'id_guest_book', action.payload.items[i], state);
			}
		},
		addGuestBookItems: (state, action) => addItems('guest_book_items', 'id_guest_book', action.payload, state),
		addMonthlyGuestBookReport: (state, action) => {
			const item = addItem('monthly_guest_book_reports', 'id_monthly_guest_book_report', action.payload.item, state);
		},
		addMonthlyGuestBookReports: (state, action) => addItems('monthly_guest_book_reports', 'id_monthly_guest_book_report', action.payload, state),
	}
});

export const {
	addGuestBookItem,
	batchAddGuestBookItems,
	addGuestBookItems,
	addMonthlyGuestBookReport,
	addMonthlyGuestBookReports,
} = GuestSlice.actions;
export default GuestSlice.reducer;
