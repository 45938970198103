import { Honeybadger } from '@honeybadger-io/react';

const ErrorReportingHelper = {
	ReportError: (message, context) => {
		console.log('Error: ' + message, context);
		Honeybadger.notify('Error: ' + message, { name: 'Error', ...( context || {} ) });
	},
	ReportWarning: (message, context) => {
		console.log('Warning: ' + message, context);
		Honeybadger.notify('Warning: ' + message, context || {});
	},
	ReportInfo: (message, context) => {
		console.log([ 'Info', message ], context);
		Honeybadger.notify(message, { name: 'Info', ...( context || {} ) });
	},
	ReportPosPrintInfo: (message, context) => {
		console.log([ 'ReportPosPrintInfo', message ], context);
		Honeybadger.notify(message, { name: 'ReportPosPrintInfo', ...( context || {} ) });
	},
	ReportA4PrintInfo: (message, context) => {
		console.log([ 'ReportA4PrintInfo', message ], context);
		Honeybadger.notify(message, { name: 'ReportA4PrintInfo', ...( context || {} ) });
	},
};

export default ErrorReportingHelper;
