import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	Intent,
	Navbar,
	FileInput,
} from '@blueprintjs/core';

class EditSettingsItemFileDialog extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			value: props.item[props.property_key],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeDialog()}
			className='edit-settings-item-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Uredi nastavitev</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							className='flex-1'>
							<FileInput
								text="Izberi novo datoteko ..."
								onInputChange={async (e) => {
									e.preventDefault();
									const reader = new FileReader();
									reader.onload = async (e) => {
										const contents = e.target.result;
										const base64_contents = contents.substring(
											contents.indexOf(';base64,') + ';base64,'.length
										);
										
										this.setState({
											value: base64_contents,
										});
									};
									reader.readAsDataURL(e.target.files[0]);
								}} />
						</FormGroup>
						<div>
							Trenutna vsebina:
						</div>
						<div style={{ wordBreak: 'break-all' }}>
							{this.state.value === undefined || this.state.value === null || this.state.value.length < 60 ? this.state.value :
								this.state.value.substring(0, 20) +
								'...' +
								this.state.value.substring(this.state.value.length - 20)
							}
						</div>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.WARNING}
							onClick={() => {
								this.props.closeDialog(this.props.item, this.props.property_key, '');
							}}>
							Odstrani
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								this.props.closeDialog(this.props.item, this.props.property_key, this.state.value);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
EditSettingsItemFileDialog.propTypes = {
	closeDialog: PropTypes.func,
	item: PropTypes.object,
};

export default EditSettingsItemFileDialog;
