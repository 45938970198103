import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import moment from 'moment';

import {
	Button,
	Tooltip,
} from '@blueprintjs/core';

import { ResultHeader, ResultHeaderWithSort, Result, MultiSelectFilter, DateRangeFilter, TextFilter } from '../code_tables/ResultComponents';

import { addInvoice, addInvoices, addAdvanceInvoiceConsumptions } from '../../slices/BusinessSlice';
import { saveInvoice, loadInvoices, loadAdvanceInvoiceConsumptions } from '../../api/Business';
import { addDocumentAssociations } from '../../slices/DocumentSlice';
import { addActivityReservation  } from '../../slices/ReservationSlice';
import { saveActivityReservation } from '../../api/Reservations';
import EditActivityItemDialog      from './EditActivityItemDialog';
import WaitFiscalVerificationDialog from '../business/WaitFiscalVerificationDialog';
import EditInvoiceDialog           from '../business/EditInvoiceDialog';
import InvoiceCreator              from '../../helpers/InvoiceCreator';
import DocumentListDialog          from '../DocumentListDialog';
import UserTag from '../UserTag';

import { enqueueInvoiceOnA4PrinterQueue, enqueueInvoiceOnPosPrinterQueue } from '../../slices/AppSlice';

function Documents(props) {
	if (props.documents.length == 0) return null;
	
	let items = [];
	
	const document = props.documents[0];
	let id_document   = document.id_document;
	let document_type = document.document_type;
	
	let document_document_number = null;
	let document_click_handler   = null;
	
	if (document_type == 'cash-invoice' || document_type == 'invoice' || document_type == 'offer' ||
		document_type == 'prepayment-invoice' || document_type == 'credit-note') {
		const invoice_item = props.invoices[id_document];
		if (invoice_item !== undefined) {
			document_document_number = invoice_item.invoice_number_internal;
			
			document_click_handler = () => {
				props.openEditInvoiceDialog(invoice_item.invoice_type, invoice_item);
			};
		}
	}
	items.push(<Button
		key='latest-document'
		small={true}
		className='cell-button'
		onClick={document_click_handler}>
		{document_document_number}
	</Button>);
	
	if (props.documents.length > 1) {
		items.push(<Button
			key='more-documents'
			small={true}
			className='cell-button ml-1'
			onClick={props.openDocumentListDialog}
			icon='multi-select' />);
	}
	
	return items;
}

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		tableItemsFilters,
		originalTableItemsFilters,
		handleFilterChange,
		customers,
		accommodation_item_schedule_time_slot_activities,
		accommodation_item_schedule_time_slots,
		activities,
		countries,
		statuses,
		openEditActivityItemDialog,
		openEditInvoiceDialog,
		channels,
		openDocumentListDialog,
		invoices,
		document_associations,
		users,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	const toLocalString = date => {
		if (date === null || date === undefined) return '';
		
		if (!(date instanceof Date)) return date;
		
		return moment(date).format('DD.MM.YYYY');
	};
	
	const customer_countries = {};
	for (let id_customer in customers) {
		const id_country = customers[id_customer].id_country;
		if (customer_countries[id_country] !== undefined) continue;
		
		customer_countries[id_country] = {
			id_country,
			title: countries[id_country] === undefined ? '' : countries[id_country].common_name_local,
		};
	}
	
	const getDocuments = (id_document, max_depth, depth) => {
		depth = depth || 1;
		
		let items = {};
		if (depth > max_depth) return items;
		
		let documents = Object.values(document_associations[id_document] || {});
		
		for (let i=0; i<documents.length; i++) {
			let item = documents[i];
			let curr_id_document = item.source_id_document;
			let document_type    = item.source_type;
			
			if (item.source_id_document == id_document) {
				curr_id_document = item.target_id_document;
				document_type    = item.target_type;
			}
			
			if (items[item.id_document_association] === undefined) {
				items[item.id_document_association] = {
					id_document_association: item.id_document_association,
					created:                 item.created,
					id_document:             curr_id_document,
					document_type,
				};
			}
			
			const subitems = getDocuments(curr_id_document, max_depth, depth + 1);
			for (let id_document_association in subitems) {
				if (items[id_document_association] === undefined) {
					items[id_document_association] = subitems[id_document_association];
				}
			}
		}
		
		return items;
	};
	
	const getUserByUsername = username => {
		if (users === null) return null;
		
		for (let id_user in users) {
			const user = users[id_user];
			if (user !== undefined && user.username == username) {
				return user;
			}
		}
		
		return null;
	};
	
	let columnIdx        = 1;
	let filtersColumnIdx = 1;
	
	let total_guest_count       = 0;
	let total_guest_child_count = 0;
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table without-vertical-lines'>
				<ResultHeader columnIdx={columnIdx++} title='' />
				<ResultHeader columnIdx={columnIdx++} title='' />
				<ResultHeaderWithSort
					columnIdx={columnIdx++} {...sort_props} title='Datum in čas' fieldName='reservation_date' />
				<ResultHeaderWithSort
					columnIdx={columnIdx++} {...sort_props} title='Aktivnost' fieldName='id_activity' />
				<ResultHeaderWithSort
					columnIdx={columnIdx++} {...sort_props} title='Status' fieldName='status' />
				<ResultHeaderWithSort
					columnIdx={columnIdx++} {...sort_props} title='Šifra' fieldName='internal_code' />
				<ResultHeaderWithSort
					columnIdx={columnIdx++} {...sort_props} title='Nosilec' fieldName='customer' />
				<ResultHeaderWithSort
					columnIdx={columnIdx++} {...sort_props} title='Država' fieldName='customer_id_country' />
				<ResultHeaderWithSort
					columnIdx={columnIdx++} {...sort_props} title='Kanal' fieldName='id_channel' />
				<ResultHeaderWithSort
					columnIdx={columnIdx++} {...sort_props} title='Osebe' fieldName='guest_count' />
				<ResultHeader columnIdx={columnIdx++} title='Vnesel' />
				<ResultHeader columnIdx={columnIdx++} title='Opomba' />
			</div>
			
			<div className='results-table without-vertical-lines filters-row'>
				<Result columnIdx={filtersColumnIdx++} />
				<Result columnIdx={filtersColumnIdx++} />
				<Result columnIdx={filtersColumnIdx++} child={
					<DateRangeFilter
						filter_values={tableItemsFilters.reservation_date_range}
						short_year={true}
						original_start_date={originalTableItemsFilters.reservation_date_range[0]}
						original_end_date={originalTableItemsFilters.reservation_date_range[1]}
						onFilterChange={values => handleFilterChange('reservation_date_range', values)} />
				} />
				<Result columnIdx={filtersColumnIdx++} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_activity'
							title_field    ='title'
							items          ={activities}
							filtered_keys  ={tableItemsFilters.id_activities}
							displayFilter  ={null}
							displaySort    ={(a, b) => a.title.localeCompare(b.title)}
							onFilterChange ={values => handleFilterChange('id_activities', values)} />
					</div>
				} />
				<Result columnIdx={filtersColumnIdx++} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='key'
							title_field    ='title'
							items          ={statuses}
							filtered_keys  ={tableItemsFilters.statuses}
							onFilterChange ={values => handleFilterChange('statuses', values)} />
					</div>
				} />
				<Result columnIdx={filtersColumnIdx++} child={
					<TextFilter
						value={tableItemsFilters.internal_code}
						onChange={ event => handleFilterChange('internal_code', event) } />
				} />
				<Result columnIdx={filtersColumnIdx++} child={
					<TextFilter
						value={tableItemsFilters.customer_name}
						onChange={ event => handleFilterChange('customer_name', event) } />
				} />
				<Result columnIdx={filtersColumnIdx++} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_country'
							title_field    ='title'
							items          ={customer_countries}
							filtered_keys  ={tableItemsFilters.customer_id_countries}
							onFilterChange ={values => handleFilterChange('customer_id_countries', values)} />
					</div>
				} />
				<Result columnIdx={filtersColumnIdx++} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_channel'
							title_field    ='title'
							items          ={channels}
							filtered_keys  ={tableItemsFilters.id_channels}
							displayFilter  ={null}
							displaySort    ={(a, b) => a.title.localeCompare(b.title)}
							onFilterChange ={values => handleFilterChange('id_channels', values)} />
					</div>
				} />
				<Result columnIdx={filtersColumnIdx++} />
				<Result columnIdx={filtersColumnIdx++} />
				<Result columnIdx={filtersColumnIdx++} />
			</div>
			
			{table_items_sort_ids.map((id, idx) => {
				const item     = table_items[id];
				const customer = customers[item.id_customer] ?? null;
				
				const accommodation_item_schedule_time_slot_activity = accommodation_item_schedule_time_slot_activities[
					item.id_accommodation_item_schedule_time_slot_activity
				];
				const time_slot = accommodation_item_schedule_time_slots[
					accommodation_item_schedule_time_slot_activity.id_accommodation_item_schedule_time_slot
				];
				const activity = activities[
					accommodation_item_schedule_time_slot_activity.id_activity
				];
				const starts_at_arr = time_slot.starts_at.split(':');
				const time_label    = starts_at_arr.length != 3 ? '00:00' : starts_at_arr[0] + ':' + starts_at_arr[1];
				
				let pattern_class_name = '';
				if (item.status == 'offer-sent') {
					pattern_class_name = 'reservation-list-pattern-polka-dots';
				}
				else if (item.status == 'advance-invoice-sent' || item.status == 'not-for-rent' /*with red background*/) {
					pattern_class_name = 'reservation-list-pattern-diagonal-stripes';
				}
				
				const documents = Object.values(getDocuments(
					item.id_activity_reservation,
					3
				)).sort((a, b) => { // sort by created, descending
					if (a.created < b.created) return  1;
					if (a.created > b.created) return -1;
					return 0;
				}).filter(document => document.document_type == 'invoice' || document.document_type == 'cash-invoice');
				
				total_guest_count       += item.guest_count;
				total_guest_child_count += item.guest_child_count;
				
				columnIdx = 1;
				
				return <div
					className={
						'results-table' +
						' ' +
						'without-vertical-lines' +
						' ' +
						'status-' + (item.deleted === true ? 'deleted' : item.status) +
						' ' +
						(item.status_2 === null ? '' : item.status_2)
					}
					key={'result--result-' + id}>
					<svg version='1.1'
						baseProfile='full'
						width='16' height='16'
						className='custom-status'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M 0,0 L 16,0 L 0,16 L 0,0 z'
							fill='#ff0000' />
					</svg>
					
					<Result columnIdx={columnIdx++} child={
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => openEditActivityItemDialog(
								item,
								item.id_accommodation_item_schedule_time_slot_activity,
								item.reservation_date
							)} />
					} />
					<Result columnIdx={columnIdx++} child={
						pattern_class_name == '' ? null : <div className={pattern_class_name} />
					} />
					<Result columnIdx={columnIdx++} child={
						toLocalString(new Date(item.reservation_date)) + ' ob ' + time_label
					} />
					<Result columnIdx={columnIdx++} child={
						activity.title
					} />
					<Result columnIdx={columnIdx++} child={
						statuses[item.deleted === true ? 'deleted' : item.status].title
					} />
					<Result columnIdx={columnIdx++} child={
						item.internal_code
					} />
					<Result columnIdx={columnIdx++} child={
						item.id_customer === null || customers[item.id_customer] === undefined ? null :
							<div>
								<div className='grid grid-cols-2'>
									<div>
									{
										customers[item.id_customer].type == 'natural' ?
											customers[item.id_customer].surname + ' ' + customers[item.id_customer].name
											:
											customers[item.id_customer].company_name
									}
									</div>
									<div className='text-right'>{
										<Documents
											documents={documents}
											invoices={invoices}
											openEditInvoiceDialog={openEditInvoiceDialog}
											openDocumentListDialog={() => {
												openDocumentListDialog(item.id_activity_reservation);
											}} />
									}</div>
								</div>
							</div>
					} />
					<Result columnIdx={columnIdx++} child={
						customer === null || customer.id_country === null || countries[customer.id_country] === undefined ? 
							'' : countries[customer.id_country].common_name_local
					} />
					<Result columnIdx={columnIdx++} child={
						channels[item.id_channel].title
					} />
					<Result columnIdx={columnIdx++} child={
						item.guest_count + ' / ' + item.guest_child_count
					} />
					<Result columnIdx={columnIdx++} cls='flex items-center' child={
						<UserTag user={getUserByUsername(item.username)} reservation={item} />
					} />
					<Result columnIdx={columnIdx++} cls='note' child={
						<Tooltip content={item.note}>
							<div>{item.note}</div>
						</Tooltip>
					} />
				</div>;
			})}
		</div>
		<div className='results borderless mb-4'>
			<div className='results-table'>
				<Result columnIdx={1} span={4} child={
					'Število zapisov: ' + table_items_sort_ids.length
				} />
				<Result columnIdx={5} child={''} />
				<Result columnIdx={6} child={''} />
				<Result columnIdx={7} child={''} />
				<Result columnIdx={8} child={''} />
				<Result columnIdx={9} child={''} />
				<Result columnIdx={10} cls='font-bold' child={total_guest_count + ' / ' + total_guest_child_count} />
				<Result columnIdx={11} child={''} />
			</div>
		</div>
	</>;
}

class ActivitiesList extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.updateTableItemsFromProps   = this.updateTableItemsFromProps  .bind(this);
		this.setTableItemsSort           = this.setTableItemsSort          .bind(this);
		this.refreshTableItemsSort       = this.refreshTableItemsSort      .bind(this);
		this.filterTableItems            = this.filterTableItems           .bind(this);
		this.handleFilterChange          = this.handleFilterChange         .bind(this);
		this.openEditActivityItemDialog  = this.openEditActivityItemDialog .bind(this);
		this.closeEditActivityItemDialog = this.closeEditActivityItemDialog.bind(this);
		//this.generateAccommodationItemPlaceTitles = this.generateAccommodationItemPlaceTitles.bind(this);
		//this.generateAccommodationItemPlaceOrds   = this.generateAccommodationItemPlaceOrds  .bind(this);
		this.openDocumentListDialog      = this.openDocumentListDialog     .bind(this);
		this.closeDocumentListDialog     = this.closeDocumentListDialog    .bind(this);
		this.openEditInvoiceDialog       = this.openEditInvoiceDialog      .bind(this);
		this.closeEditInvoiceDialog      = this.closeEditInvoiceDialog     .bind(this);
		
		const table_items_filters = {
			id_activities:          [],
			id_channels:            [],
			statuses:               [],
			internal_code:          '',
			customer_name:          '',
			customer_id_countries:  [],
			reservation_date_range: [moment({hour: 0, minute: 0}).toDate(), null],
		};
		
		/*const accommodation_item_place_titles = this.generateAccommodationItemPlaceTitles(
			props.accommodations,
			props.accommodation_items,
			props.accommodation_item_places,
		);
		const accommodation_item_place_ords = this.generateAccommodationItemPlaceOrds(
			props.accommodations,
			props.accommodation_items,
			props.accommodation_item_places,
		);*/
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'reservation_date',
			table_items_sort_direction: 'ASC',
			//accommodation_item_place_titles,
			//accommodation_item_place_ords,
		});
		
		const statuses = {
			'new':                      { title: 'Nova',           key: 'new'                       },
			'waiting-for-confirmation': { title: 'V potrjevanju',  key: 'waiting-for-confirmation'  },
			'offer-sent':               { title: 'Izdana ponudba', key: 'offer-sent'                },
			'confirmed':                { title: 'Potrjena',       key: 'confirmed'                 },
			'advance-invoice-sent':     { title: 'Izdan avans',    key: 'advance-invoice-sent'      },
			'closed':                   { title: 'Plačana',        key: 'closed'                    },
			'reversed':                 { title: 'Stornirana',     key: 'reversed',                 },
			'no-show':                  { title: 'Neprihod',       key: 'no-show',                  },
			'deleted':                  { title: 'Brisana',        key: 'deleted'                   },
		};
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'reservation_date',
			table_items_sort_direction: 'ASC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			date_range_picker_dialog_open:        false,
			date_range_picker_dialog_filter_name: null,
			//accommodation_item_place_titles,
			//accommodation_item_place_ords,
			statuses,
			edit_activity_item_dialog_open:             false,
			edit_activity_item_dialog_item:             null,
			edit_activity_item_dialog_reservation_date: null,
			edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity: -1,
			document_list_dialog_open:        false,
			document_list_dialog_id_document: null,
			invoice_dialog_open: false,
			invoice_dialog_item: null,
			invoice_dialog_type: '',
			wait_fiscal_verification_dialog_open:       false,
			wait_fiscal_verification_dialog_id_invoice: null,
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.activity_reservations != this.state.activity_reservations) {
			this.setState(
				this.updateTableItemsFromProps(this.props, this.state)
			);
		}
		
		if (
			this.props.accommodations            != prevProps.accommodations ||
			this.props.accommodation_items       != prevProps.accommodation_items ||
			this.props.accommodation_item_places != prevProps.accommodation_item_places
		) {
			/*this.setState({
				accommodation_item_place_titles: this.generateAccommodationItemPlaceTitles(
					this.props.accommodations,
					this.props.accommodation_items,
					this.props.accommodation_item_places,
				),
				accommodation_item_place_ords: this.generateAccommodationItemPlaceOrds(
					this.props.accommodations,
					this.props.accommodation_items,
					this.props.accommodation_item_places,
				),
			});*/
			
			this.refreshTableItemsSort(this.state.table_items_sort_field, this.state.table_items_sort_direction);
		}
		
		/*if (
			this.state.accommodation_item_place_titles != prevState.accommodation_item_place_titles ||
			this.state.accommodation_item_place_ords   != prevState.accommodation_item_place_ords
		) {
			this.refreshTableItemsSort(this.state.table_items_sort_field, this.state.table_items_sort_direction);
		}*/
	}
	
	updateTableItemsFromProps(props, state) {
		const filtered_reservations = {};
		for (let id_activity_reservation in props.activity_reservations) {
			const reservation = props.activity_reservations[id_activity_reservation];
			
			filtered_reservations[id_activity_reservation] = reservation;
		}
		
		const ids = Object.keys(filtered_reservations);
		
		let sort_ids = [];
		
		if (state !== undefined && state.table_items_sort_ids !== undefined) {
			for (let i=0; i<state.table_items_sort_ids.length; i++) {
				const id = state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const new_state = {
			activity_reservations:      props.activity_reservations,
			table_items:                filtered_reservations,
			table_items_sort_ids:       sort_ids,
			table_items_filtered_ids:   ids,
			table_items_sort_field:     state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a = '';
			let b = '';
			let dir = direction;
			
			let sort_val = 0;
			
			if (field == 'id_activity') {
				var id_activity_a = this.props.accommodation_item_schedule_time_slot_activities[
					state.table_items[a_key].id_accommodation_item_schedule_time_slot_activity
				].id_activity;
				var id_activity_b = this.props.accommodation_item_schedule_time_slot_activities[
					state.table_items[b_key].id_accommodation_item_schedule_time_slot_activity
				].id_activity;
				
				a = this.props.activities[id_activity_a].title;
				b = this.props.activities[id_activity_b].title;
				
				sort_val = a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'status') {
				a = state.statuses[state.table_items[a_key].deleted === true ? 'deleted' : state.table_items[a_key][field]].title;
				b = state.statuses[state.table_items[b_key].deleted === true ? 'deleted' : state.table_items[b_key][field]].title;
				
				sort_val = a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'customer') {
				const reservation_a = state.table_items[a_key];
				const reservation_b = state.table_items[b_key];
				
				const customer_a = this.props.customers[reservation_a.id_customer];
				const customer_b = this.props.customers[reservation_b.id_customer];
				
				a = customer_a === undefined ? '' : (customer_a.type == 'natural' ? customer_a.surname + ' ' + customer_a.name : customer_a.company_name);
				b = customer_b === undefined ? '' : (customer_b.type == 'natural' ? customer_b.surname + ' ' + customer_b.name : customer_b.company_name);
				
				sort_val = a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'customer_id_country') {
				const reservation_a = state.table_items[a_key];
				const reservation_b = state.table_items[b_key];
				
				const customer_a = this.props.customers[reservation_a.id_customer];
				const customer_b = this.props.customers[reservation_b.id_customer];
				
				a = customer_a === undefined ? '' : (customer_a.id_country === null ? '' : this.props.countries[customer_a.id_country].common_name_local);
				b = customer_b === undefined ? '' : (customer_b.id_country === null ? '' : this.props.countries[customer_b.id_country].common_name_local);
				
				sort_val = a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else if (field == 'reservation_date') {
				const reservation_a = state.table_items[a_key];
				const reservation_b = state.table_items[b_key];
				
				const time_slot_a = this.props.accommodation_item_schedule_time_slots[
					this.props.accommodation_item_schedule_time_slot_activities[
						reservation_a.id_accommodation_item_schedule_time_slot_activity
					].id_accommodation_item_schedule_time_slot
				];
				const starts_at_arr_a = time_slot_a.starts_at.split(':');
				let   time_label_a    = starts_at_arr_a.length != 3 ? '00:00' : starts_at_arr_a[0] + ':' + starts_at_arr_a[1];
				
				const time_slot_b = this.props.accommodation_item_schedule_time_slots[
					this.props.accommodation_item_schedule_time_slot_activities[
						reservation_b.id_accommodation_item_schedule_time_slot_activity
					].id_accommodation_item_schedule_time_slot
				];
				const starts_at_arr_b = time_slot_b.starts_at.split(':');
				let   time_label_b    = starts_at_arr_b.length != 3 ? '00:00' : starts_at_arr_b[0] + ':' + starts_at_arr_b[1];
				
				time_label_a = reservation_a.reservation_date + ' ' + time_label_a;
				time_label_b = reservation_b.reservation_date + ' ' + time_label_b;
				
				sort_val = time_label_a.localeCompare(time_label_b) * (dir == 'ASC' ? 1 : -1);
			}
			else {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
				
				sort_val = (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
			}
			
			// if otherwise equal, sort by created, older first
			if (sort_val == 0) {
				a = state.table_items[a_key].created;
				b = state.table_items[b_key].created;
				
				sort_val = (a < b ? -1 : a > b ? 1 : 0);
			}
			
			return sort_val;
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_id_activities         = filters.id_activities;
		const filters_id_channels           = filters.id_channels;
		const filters_statuses              = filters.statuses;
		const filters_internal_code         = filters.internal_code.toUpperCase();
		const filters_customer_name         = filters.customer_name.toUpperCase();
		const filters_customer_id_countries = filters.customer_id_countries;
		const filter_reservation_date_start = filters.reservation_date_range[0]  === null ? null : this.toIsoString(filters.reservation_date_range[0]);
		const filter_reservation_date_end   = filters.reservation_date_range[1]  === null ? null : this.toIsoString(filters.reservation_date_range[1]);
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_id_activities.length > 0) {
				let found = false;
				
				for (let i=0; i<filters_id_activities.length; i++) {
					const id_activity = this.props.accommodation_item_schedule_time_slot_activities[
						item.id_accommodation_item_schedule_time_slot_activity
					].id_activity;
					
					if (id_activity == filters_id_activities[i]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			if (filters_id_channels.length > 0) {
				let found = false;
				
				for (let i=0; i<filters_id_channels.length; i++) {
					if (item.id_channel == filters_id_channels[i]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			if (filters_statuses.length > 0) {
				let found = false;
				for (let i=0; i<filters_statuses.length; i++) {
					if (
						(filters_statuses[i] == 'deleted' && item.deleted === true) ||
						(item.status == filters_statuses[i] && item.deleted !== true)
					) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			if (filters_internal_code != '') {
				if (item.internal_code.toUpperCase().indexOf(filters_internal_code) == -1) {
					return false;
				}
			}
			
			if (filters_customer_name != '') {
				const filter = (customer) => {
					if (customer === undefined) return false;
					return (customer.surname + ' ' + customer.name + ' ' + customer.company_name)
						.toUpperCase()
						.indexOf(filters_customer_name) != -1;
				};
				let found = filter(this.props.customers[item.id_customer]);
				
				if (!found) {
					return false;
				}
			}
			
			if (filters_customer_id_countries.length > 0) {
				const filter = (customer) => {
					if (customer === undefined) return false;
					return customer.id_country !== null && filters_customer_id_countries.indexOf(customer.id_country) != -1;
				};
				let found = filter(this.props.customers[item.id_customer]);
				
				if (!found) {
					return false;
				}
			}
			
			if (filter_reservation_date_start !== null) {
				const filter = (customer) => {
					if (customer === undefined) return false;
					return customer.reservation_date === null || this.toIsoString(new Date(customer.reservation_date)) >= filter_reservation_date_start;
				};
				let found = filter(item);
				
				if (!found) {
					return false;
				}
			}
			if (filter_reservation_date_end !== null) {
				const filter = (customer) => {
					if (customer === undefined) return false;
					return customer.reservation_date === null || this.toIsoString(new Date(customer.reservation_date)) <= filter_reservation_date_end;
				};
				let found = filter(item);
				
				if (!found) {
					return false;
				}
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
				//accommodation_item_place_titles: state.accommodation_item_place_titles,
				//accommodation_item_place_ords:   state.accommodation_item_place_ords,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			id_activities:          this.state.table_items_filters.id_activities,
			id_channels:            this.state.table_items_filters.id_channels,
			statuses:               this.state.table_items_filters.statuses,
			internal_code:          this.state.table_items_filters.internal_code,
			customer_name:          this.state.table_items_filters.customer_name,
			customer_id_countries:  this.state.table_items_filters.customer_id_countries,
			reservation_date_range: this.state.table_items_filters.reservation_date_range,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openEditActivityItemDialog(
		item,
		id_accommodation_item_schedule_time_slot_activity,
		reservation_date
	) {
		this.setState({
			edit_activity_item_dialog_open:             true,
			edit_activity_item_dialog_item:             item,
			edit_activity_item_dialog_reservation_date: reservation_date,
			edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity: id_accommodation_item_schedule_time_slot_activity,
		});
	}
	
	async closeEditActivityItemDialog(original_item, item) {
		this.setState({
			edit_activity_item_dialog_open: false,
		});
		
		if (item !== undefined) {
			let new_item = {};
			
			if (original_item !== null) { // existing item
				new_item = {...original_item};
				new_item.guest_count       = item.guest_count;
				new_item.guest_child_count = item.guest_child_count;
				new_item.note              = item.note;
				new_item.id_channel        = item.id_channel;
				new_item.id_customer       = item.id_customer;
				new_item.phone             = '';
				new_item.email             = '';
				new_item.reservation_date  = item.reservation_date;
				new_item.status            = item.status;
				new_item.status_2          = item.status_2 == '' ? null : item.status_2;
				new_item.deleted           = item.deleted;
			}
			else { // new item
				new_item = {
					id_activity_reservation: -1,
					guest_count:             item.guest_count,
					guest_child_count:       item.guest_child_count,
					note:                    item.note,
					id_channel:              item.id_channel,
					id_customer:             item.id_customer,
					phone:                   '',
					email:                   '',
					status:                  item.status,
					status_2:                item.status_2 == '' ? null : item.status_2,
					reservation_date:        item.reservation_date,
					id_accommodation_item_schedule_time_slot_activity: this.state.edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity,
					deleted:                 false,
					username:                this.props.user.username,
				};
			}
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			dispatch(addActivityReservation({
				item: new_item,
				token,
			}));
			new_item = await saveActivityReservation(this.props.api_url, new_item, token);
			if (new_item !== null) {
				dispatch(addActivityReservation({
					item: new_item,
					token,
				}));
			}
		}
	}
	
	openDocumentListDialog(id_document) {
		this.setState({
			document_list_dialog_open:        true,
			document_list_dialog_id_document: id_document,
		});
	}
	closeDocumentListDialog() {
		this.setState({
			document_list_dialog_open:        false,
			document_list_dialog_id_document: null,
		});
	}
	
	openEditInvoiceDialog(type, item) {
		this.setState({
			invoice_dialog_open: true,
			invoice_dialog_type: type,
			invoice_dialog_item: item,
		});
	}
	async closeEditInvoiceDialog(item, open_create_invoice_type, finish_invoice) {
		this.setState({
			invoice_dialog_open: false,
			invoice_dialog_item: null,
		});
		
		if (item !== undefined) {
			this.props.dispatch(addInvoice({
				item,
				token: this.props.token,
			}));
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			const new_items = await saveInvoice(this.props.api_url, item, token, dispatch);
			if (new_items !== null) {
				if (new_items.invoices !== undefined) {
					dispatch(addInvoices(new_items.invoices));
				}
				if (new_items.document_associations !== undefined) {
					dispatch(addDocumentAssociations(new_items.document_associations));
				}
				if (new_items.advance_invoice_consumptions !== undefined) {
					dispatch(addAdvanceInvoiceConsumptions(new_items.advance_invoice_consumptions));
				}
				
				if (finish_invoice && this.props.document_types[item.invoice_type].fiscal_verification_enabled) {
					this.setState({
						wait_fiscal_verification_dialog_open:       true,
						wait_fiscal_verification_dialog_id_invoice: item.id_invoice,
					});
				}
			}
		}
		
		if (open_create_invoice_type !== undefined) {
			const new_item = InvoiceCreator.Duplicate(item);
			new_item.id_invoice              = uuid.v4();
			new_item.invoice_number          = '';
			new_item.invoice_number_internal = '';
			new_item.invoice_type            = open_create_invoice_type;
			new_item.draft                   = true;
			new_item.reference               = item.invoice_number_internal;
			new_item.original_id_invoice     = item.id_invoice;
			new_item.original_invoice_type   = item.invoice_type;
			new_item.note                    = '';
			
			if (open_create_invoice_type == 'prepayment-invoice') {
				new_item.invoice_date = this.toIsoString(new Date());
				new_item.payment_date = this.toIsoString(new Date());
			}
			
			for (let i=0; i<new_item.payments.length; i++) {
				new_item.payments[i].id_invoice_payment = null;
			}
			for (let i=0; i<new_item.items.length; i++) {
				new_item.items[i].id_invoice_item = uuid.v4();
			}
			
			setTimeout(() => {
				if (!this._ismounted) return;
				this.openEditInvoiceDialog(open_create_invoice_type, new_item);
			}, 1000);
		}
	}
	
	render() {
		return <>
			{!this.state.edit_activity_item_dialog_open ? null :
				<EditActivityItemDialog
					closeEditActivityItemDialog={this.closeEditActivityItemDialog}
					item={this.state.edit_activity_item_dialog_item}
					reservation_date={this.state.edit_activity_item_dialog_reservation_date}
					time_slot={
						this.props.accommodation_item_schedule_time_slots[
							this.props.accommodation_item_schedule_time_slot_activities[
								this.state.edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity
							].id_accommodation_item_schedule_time_slot
						]
					}
					activity={
						this.props.activities[
							this.props.accommodation_item_schedule_time_slot_activities[
								this.state.edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity
							].id_activity
						]
					}
					accommodation_item_schedule_time_slot_activity={
						this.props.accommodation_item_schedule_time_slot_activities[
							this.state.edit_activity_item_dialog_id_accommodation_item_schedule_time_slot_activity
						]
					}
					setCurrentDate={null} />
			}
			
			{!this.state.invoice_dialog_open ? null :
				<EditInvoiceDialog
					closeEditInvoiceDialog={this.closeEditInvoiceDialog}
					item={this.state.invoice_dialog_item}
					type={this.state.invoice_dialog_type} />
			}
			
			{!this.state.document_list_dialog_open ? null :
				<DocumentListDialog
					id_document={this.state.document_list_dialog_id_document}
					closeDialog={this.closeDocumentListDialog} />
			}
			
			{!this.state.wait_fiscal_verification_dialog_open ? null :
				<WaitFiscalVerificationDialog
					closeWaitFiscalVerificationDialog={(print) => {
						this.setState({ wait_fiscal_verification_dialog_open: false });
						
						if (print) {
							const invoice = this.props.invoices[this.state.wait_fiscal_verification_dialog_id_invoice];
							if (this.props.general_settings.auto_print_type == 'a4') {
								this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'invoice', lang: 'sl', invoice }));
							}
							else if (this.props.general_settings.auto_print_type == 'pos') {
								this.props.dispatch(enqueueInvoiceOnPosPrinterQueue({ type: 'invoice', invoice }));
							}
						}
					}}
					id_invoice={this.state.wait_fiscal_verification_dialog_id_invoice} />
			}
			
			<div className='flex flex-col flex-grow activity-reservation-list mt-16'>
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items=                    {this.state.table_items}
							table_items_sort_ids=           {this.state.table_items_sort_ids}
							table_items_sort_field=         {this.state.table_items_sort_field}
							table_items_sort_direction=     {this.state.table_items_sort_direction}
							setTableItemsSort=              {this.setTableItemsSort}
							tableItemsFilters=              {this.state.table_items_filters}
							originalTableItemsFilters=      {this.state.original_table_items_filters}
							handleFilterChange=             {this.handleFilterChange}
							customers=                      {this.props.customers}
							activities=                     {this.props.activities}
							countries=                      {this.props.countries}
							statuses=                       {this.state.statuses}
							openEditActivityItemDialog=     {this.openEditActivityItemDialog}
							openEditInvoiceDialog=          {this.openEditInvoiceDialog}
							channels=                       {this.props.channels}
							accommodation_item_schedule_time_slots=          {this.props.accommodation_item_schedule_time_slots}
							accommodation_item_schedule_time_slot_activities={this.props.accommodation_item_schedule_time_slot_activities}
							openDocumentListDialog=         {this.openDocumentListDialog}
							document_associations=          {this.props.document_associations}
							invoices=                       {this.props.invoices}
							users=                          {this.props.users} />
					</div>
				</div>
			</div>
		</>;
	}
}
ActivitiesList.propTypes = {
};

function mapStateToProps(state) {
	return {
		customers:                                        state.CodeTablesSlice.customers,
		api_url:                                          state.ReservationSlice.api_url,
		token:                                            state.UserSlice.token,
		user:                                             state.UserSlice.user,
		users:                                            state.UserSlice.users,
		channels:                                         state.CodeTablesSlice.channels,
		countries:                                        state.CodeTablesSlice.countries,
		accommodation_item_schedules:                     state.CodeTablesSlice.accommodation_item_schedules,
		accommodation_item_schedule_time_slots:           state.CodeTablesSlice.accommodation_item_schedule_time_slots,
		accommodation_item_schedule_time_slot_activities: state.CodeTablesSlice.accommodation_item_schedule_time_slot_activities,
		activities:                                       state.CodeTablesSlice.activities,
		activity_reservations:                            state.ReservationSlice.activity_reservations,
		document_associations:                            state.DocumentSlice.document_associations_id_documents,
		invoices:                                         state.BusinessSlice.invoices,
		document_types:                                   state.DocumentSlice.document_types,
		general_settings:                                 state.SettingsSlice.general,
	};
}

export default connect(mapStateToProps)(ActivitiesList);
