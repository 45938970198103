import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
	ButtonGroup,
	Button,
	Card,
	Elevation,
} from '@blueprintjs/core';

import { setCurrentIdCashRegister } from '../slices/SettingsSlice';

import LocalStorageHelper from '../helpers/LocalStorageHelper';

class CashRegisterSelector extends Component {
	constructor(props) {
		super(props);
		
		this.setCurrentIdCashRegister = this.setCurrentIdCashRegister.bind(this);
		
		this.state = {};
	}
	
	setCurrentIdCashRegister(id_cash_register) {
		this.props.dispatch(setCurrentIdCashRegister({ current_id_cash_register: id_cash_register }));
		LocalStorageHelper.SetValue(this.props.client + '__current_id_cash_register', id_cash_register);
	}
	
	render() {
		return <>
			<div className='flex flex-col flex-grow justify-center'>
				<Card className='w-64 p-8 pl-16 pr-16 mx-auto box-content' elevation={Elevation.TWO}>
					<div>
						<h4 className='bp5-heading text-center mb-6' style={{ color: '#394b59' }}>
							Izberi blagajno
						</h4>
						
						<div className='flex flex-col items-center'>
							<div className='flex flex-1 flex-col mb-2 w-64'>
								<ButtonGroup vertical={true}>
									{Object.values(this.props.cash_registers).map(cash_register => {
										return <Button
											key={'cash-register-' + cash_register.id_cash_register}
											onClick={() => {
												this.setCurrentIdCashRegister(cash_register.id_cash_register);
											}}>
											{ cash_register.internal_code } • { cash_register.title }
										</Button>;
									})}
									<Button
										onClick={() => {
											this.setCurrentIdCashRegister(0);
										}}>
										Nadaljuj brez izbora blagajne
									</Button>
								</ButtonGroup>
								<div className='p-2 text-gray-600 text-center'>
									če nadaljuješ brez izbora blagajne, nekatere funkcije ne bodo na voljo
								</div>
							</div>
						</div>
					</div>
				</Card>
			</div>
		</>;
	}
}
CashRegisterSelector.propTypes = {};

function mapStateToProps(state) {
	return {
		cash_registers: state.CashRegisterSlice.cash_registers,
		client:         state.ConstantsSlice.client,
	};
}

export default connect(mapStateToProps)(CashRegisterSelector);
