import {
	addCashRegisters,
	addCashRegisterDailyStates,
	addCashRegisterDocument,
	addCashRegisterDocuments,
} from '../slices/CashRegisterSlice';
import {
	setUser,
} from '../slices/UserSlice';

export async function loadCashRegisters(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CashRegisters/v1/cash-registers', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addCashRegisters(items));
	}
}

export async function loadCashRegisterDailyStates(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CashRegisters/v1/cash-register-daily-states', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addCashRegisterDailyStates(items));
	}
}
export async function loadCashRegisterDocuments(api_url, dispatch, token) {
	let response = await fetch(api_url + 'CashRegisters/v1/cash-register-documents', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addCashRegisterDocuments(items));
	}
}
export async function loadCashRegisterDocument(api_url, id_cash_register_document, dispatch, token) {
	let response = await fetch(
		api_url + 'CashRegisters/v1/cash-register-document?id_cash_register_document=' + id_cash_register_document,
		{ headers: { 'Authorization': 'Bearer ' + token } }
	);
	let item = await response.json();
	
	if (item.error_code !== undefined) {
		if (item.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addCashRegisterDocument(item));
	}
}

export async function saveCashRegister(api_url, item, token) {
	return await saveCashRegisterItem(api_url, 'cash_registers', item, token);
}
export async function saveCashRegisterDailyState(api_url, item, token) {
	return await saveCashRegisterItem(api_url, 'cash_register_daily_states', item, token);
}
export async function saveCashRegisterDocument(api_url, item, token) {
	return await saveCashRegisterItem(api_url, 'cash_register_documents', item, token);
}
export async function saveCashRegisterDocumentPaymentType(api_url, item, token) {
	return await saveCashRegisterItem(api_url, 'cash_register_document_payment_types', item, token);
}
export async function saveCashRegisterDocumentInvoice(api_url, item, token) {
	return await saveCashRegisterItem(api_url, 'cash_register_document_invoices', item, token);
}

async function saveCashRegisterItem(api_url, name, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	const table_names_to_urls = {
		cash_registers:                       'CashRegisters/v1/save-cash-register',
		cash_register_daily_states:           'CashRegisters/v1/save-cash-register-daily-state',
		cash_register_documents:              'CashRegisters/v1/save-cash-register-document',
		cash_register_document_payment_types: 'CashRegisters/v1/save-cash-register-document-payment-type',
		cash_register_document_invoices:      'CashRegisters/v1/save-cash-register-document-invoice',
	};
	const url = table_names_to_urls[name];
	if (url === undefined) return;
	
	let response = await fetch(api_url + url, {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}

export async function deleteCashRegisterDailyState(api_url, id_cash_register_daily_state, token) {
	const form_data = new FormData();
	form_data.set('id_cash_register_daily_state', id_cash_register_daily_state);
	
	let response = await fetch(api_url + 'CashRegisters/v1/delete-cash-register-daily-state', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let status = await response.json();
	
	if (status.error_code !== undefined) {
		if (status.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
}
