import React from 'react';

import {
	MenuItem,
} from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';

function SimpleSuggest(props) {
	const { matchKeyName, valueKeyName, items, onItemSelect, selectedItem, placeholder, className } = props;
	
	return <Suggest
		itemPredicate={(query, item, idx, exactMatch) => {
			return items[idx][matchKeyName].toLowerCase().indexOf(query.toLowerCase()) > -1;
		}}
		itemRenderer={(item, { handleClick, modifiers }) => {
			return <MenuItem
				active={modifiers.active}
				key={item[valueKeyName]}
				onClick={handleClick}
				text={item[matchKeyName]} />;
		}}
		items={items}
		onItemSelect={item => {
			onItemSelect(item);
		}}
		openOnKeyDown={false}
		resetOnClose={true}
		popoverProps={{
			targetProps: { style: { display: 'block' } },
			minimal: true,
		}}
		fill={true}
		selectedItem={selectedItem}
		inputProps={{
			placeholder: placeholder,
			className:   className,
		}}
		inputValueRenderer={item => item[matchKeyName]} />;
}

export default SimpleSuggest;
