import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Button,
	ButtonGroup,
	Checkbox,
} from '@blueprintjs/core';

import AddEditItem from './AddEditItem';
import { addItem } from '../../slices/CodeTablesSlice';
import { loadItems } from '../../api/CodeTables';
import PriceHelper from '../../helpers/PriceHelper';
import ItemLabelPrintDialog from '../ItemLabelPrintDialog';

import { enqueueItemOnLabelPrinterQueue } from '../../slices/AppSlice';

import { ResultHeader, ResultHeaderWithSort, Result, MultiSelectFilter, TextFilter } from './ResultComponents';

import { debounce } from '../../App';

function Results(props) {
	const {
		table_items_total_count,
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		openAddEditItemDialog,
		tableItemsFilters,
		handleFilterChange,
		measuring_units,
		tax_rates,
		table_items_checked_ids,
		rowCheckStateChanged,
		allRowsCheckStateChanged,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	const item_types = {
		'item':        { ord: 1, key: 'item',        title: 'artikel'          },
		'service':     { ord: 2, key: 'service',     title: 'storitev'         },
		'tourist_tax': { ord: 3, key: 'tourist_tax', title: 'turistična taksa' },
	};
	
	const today = new Date();
	
	const check_count = table_items_sort_ids.filter(id => table_items_checked_ids[id] === true).length;
	const all_checked = table_items_sort_ids.length == check_count;
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table overflow-y-auto sticky-header'>
				<ResultHeader columnIdx={1} title={
					<Checkbox
						checked={check_count > 0}
						indeterminate={!all_checked && check_count > 0}
						onChange={event => allRowsCheckStateChanged(event.target.checked)} />
				} />
				<ResultHeaderWithSort
					columnIdx={2} {...sort_props} title='' fieldName='serial_internal_code' />
				<ResultHeader columnIdx={3} title='Urejanje' />
				<ResultHeaderWithSort
					columnIdx={4} {...sort_props} title='Šifra artikla/storitve' fieldName='internal_code' />
				<ResultHeaderWithSort
					columnIdx={5} {...sort_props} title='Naziv artikla/storitve' fieldName='title' />
				<ResultHeaderWithSort
					columnIdx={6} {...sort_props} title='Črtna koda' fieldName='ean' />
				<ResultHeaderWithSort
					columnIdx={7} {...sort_props} title='EM' fieldName='id_measuring_unit' />
				<ResultHeaderWithSort
					columnIdx={8} {...sort_props} title='Tip' fieldName='type' />
				<ResultHeaderWithSort
					columnIdx={9} {...sort_props} title='Cena z DDV' fieldName='amount_with_vat' cls='text-right' />
				<ResultHeaderWithSort
					columnIdx={10} {...sort_props} title='Stopnja davka' fieldName='tax_rate' />
				<ResultHeaderWithSort
					columnIdx={11} {...sort_props} title='Dovoljen popust (%)' fieldName='allowed_discount' cls='text-right' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.serial_internal_code}
						onChange={ event => handleFilterChange('serial_internal_code', event) } />
				} />
				<Result columnIdx={3} cls='filters-col' />
				<Result columnIdx={4} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.internal_code}
						onChange={ event => handleFilterChange('internal_code', event) } />
				} />
				<Result columnIdx={5} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.title}
						onChange={ event => handleFilterChange('title', event) } />
				} />
				<Result columnIdx={6} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.ean}
						onChange={ event => handleFilterChange('ean', event) } />
				} />
				<Result columnIdx={7} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_measuring_unit'
							title_field    ='title'
							items          ={measuring_units}
							filtered_keys  ={tableItemsFilters.measuring_units}
							displayFilter  ={x => x.visible}
							displaySort    ={(a, b) => a.ord - b.ord}
							onFilterChange ={values => handleFilterChange('measuring_units', values)} />
					</div>
				} />
				<Result columnIdx={8} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='key'
							title_field    ='title'
							items          ={item_types}
							filtered_keys  ={tableItemsFilters.item_types}
							displaySort    ={(a, b) => a.ord - b.ord}
							onFilterChange ={values => handleFilterChange('item_types', values)} />
					</div>
				} />
				<Result columnIdx={9} cls='filters-col' />
				<Result columnIdx={10} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_tax_rate'
							title_field    ='title'
							items          ={tax_rates}
							filtered_keys  ={tableItemsFilters.tax_rates}
							displaySort    ={(a, b) => a.ord - b.ord}
							onFilterChange ={values => handleFilterChange('tax_rates', values)} />
					</div>
				} />
				<Result columnIdx={11} cls='filters-col' />
				
				{table_items_sort_ids.map((id, idx) => {
					const item = table_items[id];
					
					const id_price_list = PriceHelper.FindBestPriceListMatch(today, item.price_lists);
					const price_list    = id_price_list === null ? null : item.price_lists[id_price_list];
					
					return <React.Fragment key={'items-result--result-' + id}>
						<Result columnIdx={1} child={
							<Checkbox
								checked={table_items_checked_ids[id] === true}
								onChange={event => rowCheckStateChanged(id, event.target.checked)} />
						} />
						<Result columnIdx={2} child={
							item.serial_internal_code
						} />
						<Result columnIdx={3} child={
							<Button
								icon='edit'
								intent='primary'
								minimal={true}
								small={true}
								disabled={false}
								onClick={() => openAddEditItemDialog(item)} />
						} />
						<Result columnIdx={4} child={
							item.internal_code
						} />
						<Result columnIdx={5} child={
							item.title
						} />
						<Result columnIdx={6} child={
							item.ean
						} />
						<Result columnIdx={7} child={
							measuring_units[item.id_measuring_unit].title
						} />
						<Result columnIdx={8} child={
							item_types[item.type].title
						} />
						<Result columnIdx={9} cls='text-right' child={
							price_list === null ? '' :
								parseFloat(price_list.price)
									.toLocaleString(
										undefined,
										{minimumFractionDigits: 2, maximumFractionDigits: 2}
									)
						} />
						<Result columnIdx={10} child={
							price_list === null ? '' :
								(
									parseFloat(tax_rates[price_list.id_tax_rate].value)
										.toLocaleString(
											undefined,
											{minimumFractionDigits: 0, maximumFractionDigits: 2}
										)
									+
									'% • '
									+
									tax_rates[price_list.id_tax_rate].title
								)
						} />
						<Result columnIdx={11} cls='text-right' child={
							parseFloat(item.allowed_discount)
								.toLocaleString(
									undefined,
									{minimumFractionDigits: 2, maximumFractionDigits: 2}
								)
						} />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results-sum pb-4'>
			<div className='col'>
				Število zapisov: {table_items_total_count}
				{table_items_total_count > table_items_sort_ids.length ? ' (prikazanih je ' + table_items_sort_ids.length + ' zapisov)' : ''}
			</div>
		</div>
	</>;
}

class Items extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps = this.updateTableItemsFromProps.bind(this);
		this.setTableItemsSort         = this.setTableItemsSort        .bind(this);
		this.refreshTableItemsSort     = this.refreshTableItemsSort    .bind(this);
		this.filterTableItems          = this.filterTableItems         .bind(this);
		this.handleFilterChange        = this.handleFilterChange       .bind(this);
		this.openAddEditItemDialog     = this.openAddEditItemDialog    .bind(this);
		this.closeAddEditItemDialog    = this.closeAddEditItemDialog   .bind(this);
		this.exportToExcel             = this.exportToExcel            .bind(this);
		this.printLabels               = this.printLabels              .bind(this);
		this.openLabelPrintDialog      = this.openLabelPrintDialog     .bind(this);
		this.closeLabelPrintDialog     = this.closeLabelPrintDialog    .bind(this);
		
		this.updateTableItemsFromPropsDebounce = debounce((props, state) => {
			this.setState({
				...this.updateTableItemsFromProps(
					props,
					state
				),
			});
		}, 400);
		
		const table_items_filters = {
			serial_internal_code: '',
			internal_code:        '',
			title:                '',
			ean:                  '',
			measuring_units:      [],
			item_types:           [],
			tax_rates:            [],
		};
		
		const state = {
			table_items_filters,
			table_items_sort_field:     'serial_internal_code',
			table_items_sort_direction: 'DESC',
			items: props.items,
		};
		
		const {
			table_items_total_count,
			table_items,
			all_table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			...state
		});
		
		this.state = {
			table_items_total_count,
			table_items,
			all_table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			table_items_checked_ids: {},
			label_print_dialog_open: false,
			label_print_dialog_items: [],
			...state,
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.items != this.state.items) {
			this.setState({
				items: this.props.items,
				...this.updateTableItemsFromProps(this.props, this.state),
			});
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const items_from_props = props.items;
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(
			state.table_items_filters,
			{
				table_items:                items_from_props,
				table_items_sort_field:     state.table_items_sort_field,
				table_items_sort_direction: state.table_items_sort_direction,
			},
			true
		);
		
		let items             = {};
		let selected_sort_ids = [];
		let all_items         = {};
		let c                 = 0;
		
		for (let i=0; i<table_items_sort_ids.length; i++) {
			const key = table_items_sort_ids[i];
			if (table_items_filtered_ids.indexOf(key) == -1) continue;
			
			if (c < 500) {
				items[key] = items_from_props[key];
				selected_sort_ids.push(key);
			}
			all_items[key] = items_from_props[key];
			c++;
		}
		
		const new_state = {
			table_items_total_count:    c,
			table_items:                items,
			all_table_items:            all_items,
			table_items_sort_ids:       selected_sort_ids,
			table_items_filtered_ids:   selected_sort_ids,
			table_items_sort_field:     state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		new_state.table_items_filtered_ids = selected_sort_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = selected_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a   = '';
			let b   = '';
			let dir = direction;
			
			if (field == 'amount_with_vat' || field == 'tax_rate') {
				const customer_a = state.table_items[a_key];
				const customer_b = state.table_items[b_key];
				
				if (customer_a === undefined && customer_b === undefined) {
					a = 0;
					b = 0;
				}
				else {
					if (customer_a === undefined) {
						a = 0;
					}
					else {
						const price_lists = Object.values(customer_a.price_lists);
						//TODO select price list based on date
						const price_list  = price_lists.length == 0 ? null : price_lists[0];
						
						if (field == 'amount_with_vat') {
							a = (price_list === null ? 0 : price_list.price);
						}
						else if (field == 'tax_rate') {
							a = (price_list === null ? 0 : this.props.tax_rates[price_list.id_tax_rate].title);
						}
					}
					
					if (customer_b === undefined) {
						b = 0;
					}
					else {
						const price_lists = Object.values(customer_b.price_lists);
						//TODO select price list based on date
						const price_list  = price_lists.length == 0 ? null : price_lists[0];
						
						if (field == 'amount_with_vat') {
							b = (price_list === null ? 0 : parseFloat(price_list.price));
						}
						else if (field == 'tax_rate') {
							b = (price_list === null ? 0 : this.props.tax_rates[price_list.id_tax_rate].title);
						}
					}
				}
			}
			else if (field == 'title') {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
				
				return a.localeCompare(b) * (dir == 'ASC' ? 1 : -1);
			}
			else {
				a = state.table_items[a_key][field];
				b = state.table_items[b_key][field];
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (direction == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_serial_internal_code = filters.serial_internal_code;
		const filters_internal_code        = filters.internal_code.trim().toUpperCase();
		const filters_title                = filters.title.trim().toUpperCase();
		const filters_ean                  = filters.ean.trim().toUpperCase();
		const filters_measuring_units      = filters.measuring_units;
		const filters_item_types           = filters.item_types;
		const filters_tax_rates            = filters.tax_rates;
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_serial_internal_code != '' && item.serial_internal_code.toString().indexOf(filters_serial_internal_code) == -1) {
				return false;
			}
			
			if (filters_internal_code != '' && item.internal_code.toUpperCase().indexOf(filters_internal_code) == -1) {
				return false;
			}
			
			if (filters_title != '' && item.title.toUpperCase().indexOf(filters_title) == -1) {
				return false;
			}
			
			if (filters_ean != '' && item.ean.toUpperCase().indexOf(filters_ean) == -1) {
				return false;
			}
			
			if (filters_measuring_units.length > 0) {
				let found = false;
				for (let i=0; i<filters_measuring_units.length; i++) {
					if (item.id_measuring_unit == filters_measuring_units[i]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			if (filters_item_types.length > 0) {
				let found = false;
				for (let i=0; i<filters_item_types.length; i++) {
					if (item.type == filters_item_types[i]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			if (filters_tax_rates.length > 0) {
				let found = false;
				for (let i=0; i<filters_tax_rates.length; i++) {
					const price_lists = Object.values(item.price_lists);
					
					//TODO select price list based on date
					const price_list  = price_lists.length == 0 ? null : price_lists[0];
					
					if (price_list.id_tax_rate == filters_tax_rates[i]) {
						found = true;
						break;
					}
				}
				if (!found) {
					return false;
				}
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			serial_internal_code: this.state.table_items_filters.serial_internal_code,
			internal_code:        this.state.table_items_filters.internal_code,
			title:                this.state.table_items_filters.title,
			ean:                  this.state.table_items_filters.ean,
			measuring_units:      this.state.table_items_filters.measuring_units,
			item_types:           this.state.table_items_filters.item_types,
			tax_rates:            this.state.table_items_filters.tax_rates,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.setState({ table_items_filters: new_filters });
		this.updateTableItemsFromPropsDebounce(
			this.props,
			{
				...this.state,
				table_items_filters: new_filters,
			}
		);
	}
	
	openAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: true, add_edit_item_dialog_item: item });
	}
	closeAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: false });
		
		if (item !== undefined) {
			this.props.dispatch(addItem({ item, token: this.props.token, save_to_api: true }));
			setTimeout(() => {
				loadItems(this.props.api_url, this.props.dispatch, this.props.token);
			}, 2000);
		}
	}
	
	exportToExcel() {
		const params = [];
		const data   = {
			serial_internal_code: this.state.table_items_filters.serial_internal_code,
			internal_code:        this.state.table_items_filters.internal_code,
			title:                this.state.table_items_filters.title,
			ean:                  this.state.table_items_filters.ean,
			measuring_units:      this.state.table_items_filters.measuring_units.join(','),
			item_types:           this.state.table_items_filters.item_types     .join(','),
			tax_rates:            this.state.table_items_filters.tax_rates      .join(','),
			sort_field:           this.state.table_items_sort_field,
			sort_direction:       this.state.table_items_sort_direction,
		}
		
		const form = document.createElement('form');
		form.target = '_blank';
		form.method = 'POST';
		form.action = this.props.api_url +
			'CodeTables/v1/export-items-to-excel?' + 
			params.join('&');
		form.style.display = 'none';
		
		for (let key in data) {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = data[key];
			form.appendChild(input);
		}
		
		document.body.appendChild(form);
		form.submit();
		document.body.removeChild(form);
	}
	
	openLabelPrintDialog(ids) {
		const today = new Date();
		
		const items = [];
		for (let i=0; i<ids.length; i++) {
			const item = this.state.table_items[ids[i]];
			
			const id_price_list = PriceHelper.FindBestPriceListMatch(today, item.price_lists);
			const price_list    = id_price_list === null ? null : item.price_lists[id_price_list];
			
			const price = price_list === null ? 0 : price_list.price;
			
			items.push({
				item,
				price: parseFloat(price),
			});
		}
		
		this.setState({
			label_print_dialog_open: true,
			label_print_dialog_items: items,
		});
	}
	closeLabelPrintDialog(items_with_counts) {
		this.setState({
			label_print_dialog_open: false,
			label_print_dialog_items: [],
		});
		
		if (items_with_counts === null) return;
		
		this.printLabels(items_with_counts);
	}
	printLabels(items_with_counts) {
		for (let i=0; i<items_with_counts.length; i++) {
			for (let c=0; c<items_with_counts[i].count; c++) {
				this.props.dispatch(enqueueItemOnLabelPrinterQueue({
					type: 'item',
					item: items_with_counts[i].item,
				}));
			}
		}
	}
	
	render() {
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		return <>
			{!this.state.add_edit_item_dialog_open ? null :
				<AddEditItem
					closeAddEditItemDialog={this.closeAddEditItemDialog}
					item={this.state.add_edit_item_dialog_item}
					measuring_units={this.props.measuring_units}
					tax_rates={this.props.tax_rates}
					items={this.props.items} />
			}
			
			{!this.state.label_print_dialog_open ? null :
				<ItemLabelPrintDialog
					items={this.state.label_print_dialog_items}
					closeDialog={this.closeLabelPrintDialog} />
			}
			
			<div className='flex flex-col flex-grow items-list'>
				<div className='pl-4 pt-4'>
					<Button intent='primary' icon='plus' onClick={() => this.openAddEditItemDialog(null)}>
						Nov artikel/storitev
					</Button>
					<Button intent='primary' icon='cloud-download' className='ml-2' onClick={() => this.exportToExcel()}>
						Izvoz v Excel
					</Button>
					<Button
						intent='primary'
						icon='print'
						className='ml-2'
						disabled={check_count == 0}
						onClick={() => {
							this.openLabelPrintDialog(
								this.state.table_items_sort_ids
									.filter(id => this.state.table_items_checked_ids[id] === true)
							);
						}}>
						Natisni nalepke
					</Button>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items_total_count={this.state.table_items_total_count}
							table_items={this.state.table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openAddEditItemDialog={this.openAddEditItemDialog}
							tableItemsFilters={this.state.table_items_filters}
							handleFilterChange={this.handleFilterChange}
							measuring_units={this.props.measuring_units}
							tax_rates={this.props.tax_rates}
							table_items_checked_ids={this.state.table_items_checked_ids}
							rowCheckStateChanged={(id, state) => {
								let table_items_checked_ids = this.state.table_items_checked_ids;
								
								if (state === false) {
									delete table_items_checked_ids[id];
								}
								else {
									table_items_checked_ids[id] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							allRowsCheckStateChanged={state => {
								const table_items_checked_ids = {};
								for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
									table_items_checked_ids[this.state.table_items_sort_ids[i]] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}} />
					</div>
				</div>
			</div>
		</>;
	}
}
Items.propTypes = {
};

function mapStateToProps(state) {
	return {
		items:           state.CodeTablesSlice.items,
		measuring_units: state.CodeTablesSlice.measuring_units,
		tax_rates:       state.CodeTablesSlice.tax_rates,
		token:           state.UserSlice.token,
		api_url:         state.ConstantsSlice.api_url,
	};
}

export default connect(mapStateToProps)(Items);
