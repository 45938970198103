import { createSlice } from '@reduxjs/toolkit'

const SettingsSlice = createSlice({
	name: 'settings',
	initialState: {}, // defined in AppContainer.js
	reducers: {
		setCurrentIdCashRegister: (state, action) => {
			state.current_id_cash_register = action.payload.current_id_cash_register;
		},
		setGeneralSettings: (state, action) => {
			state.general = action.payload;
		},
	}
});

export const {
	setCurrentIdCashRegister,
	setGeneralSettings,
} = SettingsSlice.actions;
export default SettingsSlice.reducer;
