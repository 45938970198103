import {
	addReservations,
	addReservationCustomers,
	addActivityReservations,
	addActivityDailyLogbooks,
	addReservationCustomerItems,
	addReservationRequests,
	addReservationRequestAccommodations,
} from '../slices/ReservationSlice';
import {
	setUser,
} from '../slices/UserSlice';

export async function loadReservations(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Reservations/v1/reservations', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addReservations(items.reservations));
		dispatch(addReservationCustomers(items.reservation_customers));
		dispatch(addActivityReservations(items.activity_reservations));
		dispatch(addActivityReservations(items.activity_reservations));
		dispatch(addReservationCustomerItems(items.reservation_customer_items));
	}
}

export async function saveActivityReservation(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Reservations/v1/save-activity-reservation', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}

export async function saveReservation(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Reservations/v1/save-reservation', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}

export async function saveReservationCustomer(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Reservations/v1/save-reservation-customer', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}

export async function batchSaveReservationCustomer(api_url, items, token) {
	const form_data = new FormData();
	form_data.set('items', JSON.stringify(items));
	
	let response = await fetch(api_url + 'Reservations/v1/batch-save-reservation-customer', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_items = await response.json();
	
	if (new_items.error_code !== undefined) {
		if (new_items.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_items;
	}
	return null;
}

export async function deleteReservationCustomer(api_url, id_reservation_customer, token) {
	const form_data = new FormData();
	form_data.set('id_reservation_customer', id_reservation_customer);
	
	let response = await fetch(api_url + 'Reservations/v1/delete-reservation-customer', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let result = await response.json();
	
	if (result.error_code !== undefined) {
		if (result.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return result;
	}
	return null;
}

export async function sendReservationCustomersToETourism(
	api_url,
	id_guest_books,
	status, // can be normal or reverse
	token
) {
	const form_data = new FormData();
	form_data.append('status', status);
	
	for (let i=0; i<id_guest_books.length; i++) {
		form_data.append('id_guest_book[]', id_guest_books[i]);
	}
	
	await fetch(api_url + 'Reservations/v1/send-reservation-customers-to-e-tourism', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	//TODO check for invalid token
}

export async function loadActivityDailyLogbooks(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Reservations/v1/activity-daily-logbooks', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addActivityDailyLogbooks(items));
	}
}

export async function saveActivityDailyLogbook(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Reservations/v1/save-activity-daily-logbook', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}

export async function deleteActivityDailyLogbook(api_url, id_activity_daily_logbook, token) {
	const form_data = new FormData();
	form_data.set('id_activity_daily_logbook', id_activity_daily_logbook);
	
	let response = await fetch(api_url + 'Reservations/v1/delete-activity-daily-logbook', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let result = await response.json();
	
	if (result.error_code !== undefined) {
		if (result.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return result;
	}
	return null;
}

export async function saveReservationCustomerItem(api_url, item, token) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Reservations/v1/save-reservation-customer-item', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}
export async function saveReservationCustomerItems(api_url, items, token) {
	const form_data = new FormData();
	form_data.set('items', JSON.stringify(items));
	
	let response = await fetch(api_url + 'Reservations/v1/save-reservation-customer-items', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_items = await response.json();
	
	if (new_items.error_code !== undefined) {
		if (new_items.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_items;
	}
	return null;
}

export async function deleteReservationCustomerItem(api_url, id_reservation_customer_item, token) {
	const form_data = new FormData();
	form_data.set('id_reservation_customer_item', id_reservation_customer_item);
	
	let response = await fetch(api_url + 'Reservations/v1/delete-reservation-customer-item', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let result = await response.json();
	
	if (result.error_code !== undefined) {
		if (result.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return result;
	}
	return null;
}

export async function loadReservationRequests(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Reservations/v1/reservation-requests', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addReservationRequests(items));
	}
}

export async function loadReservationRequestAccommodations(api_url, dispatch, token) {
	let response = await fetch(api_url + 'Reservations/v1/reservation-request-accommodations', { headers: { 'Authorization': 'Bearer ' + token } });
	let items = await response.json();
	
	if (items.error_code !== undefined) {
		if (items.error_code == 20001) { // invalid token
			dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		dispatch(addReservationRequestAccommodations(items));
	}
}

export async function changeReservationRequestStatus(api_url, dispatch, token, id_reservation_requests, status) {
	const form_data = new FormData();
	form_data.set('id_reservation_requests', id_reservation_requests);
	form_data.set('status',                  status);
	
	let response = await fetch(api_url + 'Reservations/v1/change-reservation-request-status', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let result = await response.json();
	
	if (result.error_code !== undefined) {
		if (result.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return result;
	}
	return null;
}

export async function confirmReservationRequest(api_url, dispatch, token, id_reservation_requests) {
	const form_data = new FormData();
	form_data.set('id_reservation_requests', id_reservation_requests);
	
	let response = await fetch(api_url + 'Reservations/v1/confirm-reservation-request', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let result = await response.json();
	
	if (result.error_code !== undefined) {
		if (result.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return result;
	}
	return null;
}

export async function cancelReservationRequest(api_url, dispatch, token, id_reservation_requests) {
	const form_data = new FormData();
	form_data.set('id_reservation_requests', id_reservation_requests);
	
	let response = await fetch(api_url + 'Reservations/v1/cancel-reservation-request', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let result = await response.json();
	
	if (result.error_code !== undefined) {
		if (result.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return result;
	}
	return null;
}

export async function saveReservationRequest(api_url, token, item) {
	const form_data = new FormData();
	form_data.set('item', JSON.stringify(item));
	
	let response = await fetch(api_url + 'Reservations/v1/save-reservation-request', {
		method:  'post',
		body:    form_data,
		headers: { 'Authorization': 'Bearer ' + token },
	});
	let new_item = await response.json();
	
	if (new_item.error_code !== undefined) {
		if (new_item.error_code == 20001) { // invalid token
			//dispatch(setUser({ user_info: null, token: null }));
		}
	}
	else {
		return new_item;
	}
	return null;
}
