import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import {
	Button,
	Tooltip,
	FormGroup,
	Checkbox,
} from '@blueprintjs/core';

import { ResultHeader, Result, DateRangeFilter } from '../code_tables/ResultComponents';

import TextWithTooltip from '../TextWithTooltip';

class TouristTaxes extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.getIdRNOs        = this.getIdRNOs       .bind(this);
		this.mainFiltersValid = this.mainFiltersValid.bind(this);
		this.filterMainItems  = this.filterMainItems .bind(this);
		
		const id_no_items = this.getIdRNOs();
		
		this.state = {
			main_filter__idrno:      Object.keys(id_no_items).length == 1 ? [ id_no_items[Object.keys(id_no_items)[0]].value ] : [],
			main_filter__date_range: [
				moment().subtract(1, 'month').startOf('month')              .toDate(),
				moment().subtract(1, 'month').endOf  ('month').add(1, 'day').toDate()
			],
			main_table_items: null,
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	getIdRNOs() {
		const id_no_items = {};
		for (let id_accommodation in this.props.accommodations) {
			const accommodation_title = this.props.accommodations[id_accommodation].title;
			const id_rno              = this.props.accommodations[id_accommodation].etourism_id_rno;
			if (id_rno === null || id_rno.length == 0) {
				continue;
			}
			if (id_no_items[id_rno] === undefined) {
				id_no_items[id_rno] = {
					title: id_rno + ' • ' + accommodation_title,
					value: id_rno,
				};
			}
		}
		
		return id_no_items;
	}
	
	mainFiltersValid(state) {
		if (state.main_filter__idrno.length == 0) return false;
		if (state.main_filter__date_range[0] === null || state.main_filter__date_range[1] === null) return false;
		
		return true;
	}
	filterMainItems() {
		if (!this.mainFiltersValid(this.state)) return null;
		
		const items = {
			by_country:          {},
			by_tourist_discount: {
				0:   { price_sum: 0, night_count: 0 },
				50:  { price_sum: 0, night_count: 0 },
				100: { price_sum: 0, night_count: 0 },
			},
			domestic:            { reservation_count: 0, night_count: 0 },
			foreign:             { reservation_count: 0, night_count: 0 },
			total:               { price_sum: 0, reservation_count: 0, night_count: 0 },
		};
		
		const filter_from_date_iso  = moment(this.state.main_filter__date_range[0]).format('YYYY-MM-DD');
		const filter_until_date_iso = moment(this.state.main_filter__date_range[1]).format('YYYY-MM-DD');
		
		for (let id_reservation_customer in this.props.reservation_customers) {
			const reservation_customer = this.props.reservation_customers[id_reservation_customer];
			const customer             = this.props.customers            [reservation_customer.id_customer];
			const guest_book_item      = this.props.guest_book_items     [reservation_customer.id_guest_book];
			const tourist_tax          = this.props.tourist_taxes        [reservation_customer.id_tourist_tax];
			
			if (guest_book_item === undefined) {
				console.log('Guest book item empty for reservation customer ' + id_reservation_customer);
				continue;
			}
			
			if (reservation_customer.check_in > filter_until_date_iso || reservation_customer.check_out < filter_from_date_iso) {
				continue;
			}
			
			const reservation              = this.props.reservations             [reservation_customer.id_reservation];
			const accommodation_item_place = this.props.accommodation_item_places[reservation.id_accommodation_item_place];
			const accommodation_item       = this.props.accommodation_items      [accommodation_item_place.id_accommodation_item];
			const accommodation            = this.props.accommodations           [accommodation_item.id_accommodation];
			
			if (this.state.main_filter__idrno.indexOf(accommodation.etourism_id_rno) == -1) continue;
			
			let is_check_in_inside_selected_date_range = true;
			
			let check_in = moment(reservation_customer.check_in).startOf('day');
			if (check_in.format('YYYY-MM-DD') < filter_from_date_iso) {
				check_in = moment(filter_from_date_iso).startOf('day');
				is_check_in_inside_selected_date_range = false;
			}
			let check_out = moment(reservation_customer.check_out).startOf('day');
			if (check_out.format('YYYY-MM-DD') > filter_until_date_iso) {
				check_out = moment(filter_until_date_iso).startOf('day');
			}
			
			const reservation_count  = is_check_in_inside_selected_date_range ? 1 : 0;
			const reservation_length = check_out.diff(check_in, 'days');
			
			if (items.by_country[customer.id_country] === undefined) {
				items.by_country[customer.id_country] = { reservation_count: 0, night_count: 0 };
			}
			items.by_country[customer.id_country].reservation_count += reservation_count;
			items.by_country[customer.id_country].night_count       += reservation_length;
			
			if (customer.id_country == '3d9bf9d9-f261-4e82-9080-d67b62df7fcf' /* Slovenia */) {
				items.domestic.reservation_count += reservation_count;
				items.domestic.night_count       += reservation_length;
			}
			else {
				items.foreign.reservation_count += reservation_count;
				items.foreign.night_count       += reservation_length;
			}
			
			const discount = Math.round(tourist_tax.discount);
			const price    = reservation_length * guest_book_item.tt_visina * (1 - tourist_tax.discount / 100);
			if (items.by_tourist_discount[discount] === undefined) {
				items.by_tourist_discount[discount] = { price_sum: 0, night_count: 0 };
			}
			items.by_tourist_discount[discount].price_sum   += price;
			items.by_tourist_discount[discount].night_count += reservation_length;
			
			items.total.price_sum         += price;
			items.total.night_count       += reservation_length;
			items.total.reservation_count += reservation_count;
		}
		
		this.setState({
			main_table_items: items,
		});
	}
	
	render() {
		const id_no_items = this.getIdRNOs();
		
		return <>
			<div className='flex flex-col flex-grow base-list guest-tourist-taxes-list'>
				<div className='flex flex-col items-start main-filters pl-4 pt-4'>
					<div className='flex flex-row flex-wrap'>
						{Object.values(id_no_items).map((item, idx) => {
							return <Checkbox
								key={'idrno-' + item.value}
								className='mr-4 mb-4'
								checked={this.state.main_filter__idrno.indexOf(item.value) > -1}
								label={item.title}
								onChange={event => {
									const main_filter__idrno = [...this.state.main_filter__idrno];
									if (event.target.checked) {
										if (main_filter__idrno.indexOf(item.value) == -1) {
											main_filter__idrno.push(item.value);
										}
									}
									else {
										if (main_filter__idrno.indexOf(item.value) > -1) {
											main_filter__idrno.splice(main_filter__idrno.indexOf(item.value), 1);
										}
									}
									
									this.setState({
										main_filter__idrno,
									});
								}} />;
						})}
					</div>
					
					<FormGroup className='mr-4'>
						<TextWithTooltip
							label='Obdobje'
							tooltip='Za mesečni prikaz TT je pri obdobju potrebno izbrati prvi dan v mesecu (primer: 1.1.2021 do 1.2.2021)' />
						<DateRangeFilter
							filter_values={this.state.main_filter__date_range}
							original_start_date={null}
							original_end_date={null}
							onFilterChange={values => {
								this.setState({
									main_filter__date_range: values,
								});
							}} />
					</FormGroup>
				</div>
				<div className='flex justify-start pl-4 pb-4 mt-3'>
					<Button
						className='w-32'
						intent='primary'
						icon='search'
						disabled={!this.mainFiltersValid(this.state)}
						onClick={this.filterMainItems}>
						Prikaži
					</Button>
					<Button
						className='w-40 ml-2'
						intent='primary'
						icon='cloud-download'
						disabled={!this.mainFiltersValid(this.state)}
						onClick={() => {
							const params = [
								'date_from='  + moment(this.state.main_filter__date_range[0]).format('YYYY-MM-DD'),
								'date_until=' + moment(this.state.main_filter__date_range[1]).format('YYYY-MM-DD'),
								'id_rnos='    + this.state.main_filter__idrno.join(','),
							];
							
							window.open(
								this.props.api_url +
								'Guests/v1/export-tourist-taxes-to-excel?' + 
								params.join('&')
							);
						}}>
						Izvoz v Excel
					</Button>
				</div>
				
				{this.state.main_table_items === null ? null :
					<div className='flex-1 pl-4 pr-4'>
						<div className='flex-grow' style={{ flexBasis: '0' }}>
							<div className='by-countries-table'>
								<div className='results'>
									<div className='results-table without-vertical-lines'>
										<ResultHeader columnIdx={1} title='GOSTJE' />
										<ResultHeader columnIdx={2} cls='text-right' title='Št. prihodov' />
										<ResultHeader columnIdx={3} cls='text-right' title='Št. prenočitev' />
										
										{Object.values(this.props.countries)
										.sort((a, b) => a.common_name_local.localeCompare(b.common_name_local))
										.map(country => {
											const item = this.state.main_table_items.by_country[country.id_country];
											if (item === undefined) return null;
											
											return <React.Fragment key={'country-' + country.id_country}>
												<Result columnIdx={1} child={
													country.common_name_local
												} />
												<Result columnIdx={2} cls='text-right' child={
													item.reservation_count
														.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
												} />
												<Result columnIdx={3} cls='text-right' child={
													item.night_count
														.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
												} />
											</React.Fragment>;
										})}
										
										<Result columnIdx={1} cls='font-semibold' child={
											'SKUPAJ'
										} />
										<Result columnIdx={2} child={
											''
										} />
										<Result columnIdx={3} child={
											''
										} />
										
										<Result columnIdx={1} child={
											'Domači'
										} />
										<Result columnIdx={2} cls='font-semibold text-right' child={
											this.state.main_table_items.domestic.reservation_count
												.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
										} />
										<Result columnIdx={3} cls='font-semibold text-right' child={
											this.state.main_table_items.domestic.night_count
												.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
										} />
										
										<Result columnIdx={1} child={
											'Tuji skupaj'
										} />
										<Result columnIdx={2} cls='font-semibold text-right' child={
											this.state.main_table_items.foreign.reservation_count
												.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
										} />
										<Result columnIdx={3} cls='font-semibold text-right' child={
											this.state.main_table_items.foreign.night_count
												.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
										} />
										
										<Result columnIdx={1} cls='font-semibold' child={
											'SKUPAJ'
										} />
										<Result columnIdx={2} cls='font-semibold text-right' child={
											this.state.main_table_items.total.reservation_count
												.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
										} />
										<Result columnIdx={3} cls='font-semibold text-right' child={
											this.state.main_table_items.total.night_count
												.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
										} />
									</div>
								</div>
							</div>
							<div className='by-tourist-tax-types-table pt-4'>
								<div className='results'>
									<div className='results-table without-vertical-lines'>
										<ResultHeader columnIdx={1} title='TURISTIČNA TAKSA' />
										<ResultHeader columnIdx={2} cls='text-right' title='Št. prenočitev' />
										<ResultHeader columnIdx={3} cls='text-right' title='Obračunana taksa (EUR)' />
										
										{Object.keys(this.state.main_table_items.by_tourist_discount)
										.sort((a, b) => a - b)
										.map(discount => {
											return <React.Fragment key={'tax-' + discount}>
												<Result columnIdx={1} child={
													discount == 0 ? 'Polna taksa:' : 'Oproščeno ' + discount + '%'
												} />
												<Result columnIdx={2} cls='text-right' child={
													this.state.main_table_items.by_tourist_discount[discount].night_count
														.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
												} />
												<Result columnIdx={3} cls='text-right' child={
													this.state.main_table_items.by_tourist_discount[discount].price_sum
														.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
												} />
											</React.Fragment>;
										})}
										
										<Result columnIdx={1} cls='font-semibold' child={
											'SKUPAJ TURISTIČNA TAKSA'
										} />
										<Result columnIdx={2} cls='font-semibold text-right' child={
											this.state.main_table_items.total.night_count
												.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
										} />
										<Result columnIdx={3} cls='font-semibold text-right' child={
											this.state.main_table_items.total.price_sum
												.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
										} />
										
										<Result columnIdx={1} cls='font-semibold' child={
											'PROMOCIJSKA TAKSA'
										} />
										<Result columnIdx={2} child={
											''
										} />
										<Result columnIdx={3} cls='font-semibold text-right' child={
											(this.state.main_table_items.total.price_sum * 0.25)
												.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
										} />
										
										<Result columnIdx={1} cls='font-semibold ' child={
											'SKUPAJ'
										} />
										<Result columnIdx={2} child={
											''
										} />
										<Result columnIdx={3} cls='font-semibold text-right' child={
											(this.state.main_table_items.total.price_sum + this.state.main_table_items.total.price_sum * 0.25)
												.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
										} />
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</>;
	}
}
TouristTaxes.propTypes = {
};

function mapStateToProps(state) {
	return {
		api_url:                   state.CashRegisterSlice.api_url,
		token:                     state.UserSlice.token,
		user:                      state.UserSlice.user,
		users:                     state.UserSlice.users,
		countries:                 state.CodeTablesSlice.countries,
		customers:                 state.CodeTablesSlice.customers,
		tourist_taxes:             state.CodeTablesSlice.tourist_taxes,
		accommodations:            state.CodeTablesSlice.accommodations,
		guest_book_items:          state.GuestSlice.guest_book_items,
		reservation_customers:     state.ReservationSlice.reservation_customers,
		reservations:              state.ReservationSlice.reservations,
		accommodation_item_places: state.CodeTablesSlice.accommodation_item_places,
		accommodation_items:       state.CodeTablesSlice.accommodation_items,
		accommodations:            state.CodeTablesSlice.accommodations,
	};
}

export default connect(mapStateToProps)(TouristTaxes);
