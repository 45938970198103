import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import moment from 'moment';
import formatISO from 'date-fns/formatISO';

import {
	Button,
	ButtonGroup,
	Alignment,
	Checkbox,
	Alert,
	Intent,
	Tooltip,
	Dialog,
	Navbar,
	Classes,
	FormGroup,
} from '@blueprintjs/core';
import { IDateFormatProps, DateRangeInput3 } from '@blueprintjs/datetime2';

import { addCashRegisterDailyState, deleteCashRegisterDailyState as deleteCashRegisterDailyStateFromSlice } from '../../slices/CashRegisterSlice';
import {
	saveCashRegisterDailyState,
	saveCashRegisterDocument,
	saveCashRegisterDocumentPaymentType,
	loadCashRegisterDocuments,
	deleteCashRegisterDailyState,
	loadCashRegisterDailyStates,
} from '../../api/CashRegisters';
import AddJournalDialog from './AddJournalDialog';
import { AppToaster } from '../AppToaster';
import TextWithTooltip from '../TextWithTooltip';
import { loadDateFnsLocale } from '../../utils';

import { ResultHeader, Result, MultiSelectFilter, DateRangeFilter, TextFilter } from '../code_tables/ResultComponents';

import { enqueueInvoiceOnA4PrinterQueue } from '../../slices/AppSlice';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_checked_ids,
		rowCheckStateChanged,
		allRowsCheckStateChanged,
		openAddEditItemDialog,
		tableItemsFilters,
		originalTableItemsFilters,
		handleFilterChange,
		deleteItem,
		cash_registers,
		users,
		allow_delete_ids,
		cash_register_documents,
	} = props;
	
	const users_with_titles = {};
	for (let id_user in users) {
		users_with_titles[id_user] = { id_user, title: users[id_user].name + ' ' + users[id_user].surname };
	}
	
	const check_count = table_items_sort_ids.filter(id => table_items_checked_ids[id] === true).length;
	const all_checked = table_items_sort_ids.length == check_count;
	
	const sums = {
		income:              0,
		expense:             0,
		cash_expense_amount: 0,
	};
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table overflow-y-scroll sticky-header'>
				<ResultHeader columnIdx={1} title={
					<Checkbox
						checked={check_count > 0}
						indeterminate={!all_checked && check_count > 0}
						onChange={event => allRowsCheckStateChanged(event.target.checked)} />
				} />
				<ResultHeader columnIdx={2} title='Urejanje' />
				<ResultHeader
					columnIdx={3} title='Številka dnevnika' />
				<ResultHeader
					columnIdx={4} title='Datum' />
				<ResultHeader
					columnIdx={5} title='Blagajnik' />
				<ResultHeader
					columnIdx={6} title='Blagajna' />
				<ResultHeader
					columnIdx={7} cls='text-right' title='Začetni saldo' />
				<ResultHeader
					columnIdx={8} cls='text-right' title='Prejemki' />
				<ResultHeader
					columnIdx={9} cls='text-right' title='Izdatki' />
				<ResultHeader
					columnIdx={10} cls='text-right' title='Gotovina (izdatek)' />
				<ResultHeader
					columnIdx={11} cls='text-right' title='Končni saldo' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} cls='filters-col' />
				<Result columnIdx={3} cls='filters-col' child={
					<TextFilter
						value={tableItemsFilters.document_number}
						onChange={ event => handleFilterChange('document_number', event) } />
				} />
				<Result columnIdx={4} cls='filters-col' child={
					<DateRangeFilter
						filter_values={tableItemsFilters.document_date_range}
						original_start_date={originalTableItemsFilters.document_date_range[0]}
						original_end_date={originalTableItemsFilters.document_date_range[1]}
						onFilterChange={values => handleFilterChange('document_date_range', values)} />
				} />
				<Result columnIdx={5} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_user'
							title_field    ='title'
							items          ={users_with_titles}
							filtered_keys  ={tableItemsFilters.id_users}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('id_users', values)} />
					</div>
				} />
				<Result columnIdx={6} cls='filters-col' child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='id_cash_register'
							title_field    ='title'
							items          ={cash_registers}
							filtered_keys  ={tableItemsFilters.id_cash_registers}
							displaySort    ={(a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0)}
							onFilterChange ={values => handleFilterChange('id_cash_registers', values)} />
					</div>
				} />
				<Result columnIdx={7} cls='filters-col' />
				<Result columnIdx={8} cls='filters-col' />
				<Result columnIdx={9} cls='filters-col' />
				<Result columnIdx={10} cls='filters-col' />
				<Result columnIdx={11} cls='filters-col' />
				
				{table_items_sort_ids.map((id, idx) => {
					const item = table_items[id];
					
					const cash_id_payment_type = '2db820c7-a652-4f9e-8c93-7e1b09d399d5'; //TODO perhaps move this somewhere else?
					let cash_expense_amount = 0;
					if (item.expense_id_cash_register_document !== null) {
						const expense_cash_register_document = cash_register_documents[item.expense_id_cash_register_document];
						
						if (expense_cash_register_document !== undefined) {
							for (let i=0; i < (expense_cash_register_document.payment_types || []).length; i++) {
								const doc_payment_type = expense_cash_register_document.payment_types[i];
								
								if (doc_payment_type.id_payment_type == cash_id_payment_type) {
									cash_expense_amount += parseFloat(doc_payment_type.amount);
								}
							}
						}
					}
					
					sums.income              += parseFloat(item.income        );
					sums.expense             += parseFloat(item.expense       );
					sums.cash_expense_amount += parseFloat(cash_expense_amount);
					
					return <React.Fragment
						key={'result--result-' + id}>
						<Result columnIdx={1} child={
							<Checkbox
								checked={table_items_checked_ids[id] === true}
								onChange={event => rowCheckStateChanged(id, event.target.checked)} />
						} />
						<Result columnIdx={2} child={
							<>
								<Button
									icon='search'
									intent='primary'
									minimal={true}
									small={true}
									onClick={() => openAddEditItemDialog(item)} />
								{allow_delete_ids.indexOf(item.id_cash_register_daily_state) === -1 ? null :
									<Button
										icon='trash'
										intent='danger'
										minimal={true}
										small={true}
										onClick={() => {
											deleteItem(id);
										}} />
								}
							</>
						} />
						<Result columnIdx={3} child={ item.document_number } />
						<Result columnIdx={4} child={ moment(item.document_date).format('DD. MM. YYYY') } />
						<Result columnIdx={5} child={ users[item.id_user].name + ' ' + users[item.id_user].surname } />
						<Result columnIdx={6} child={ cash_registers[item.id_cash_register].title } />
						<Result columnIdx={7}  cls='text-right' child={ parseFloat(item.start_balance) .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) } />
						<Result columnIdx={8}  cls='text-right' child={ parseFloat(item.income)        .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) } />
						<Result columnIdx={9}  cls='text-right' child={ parseFloat(item.expense)       .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) } />
						<Result columnIdx={10} cls='text-right' child={ parseFloat(cash_expense_amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) } />
						<Result columnIdx={11} cls='text-right' child={ parseFloat(item.end_balance)   .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) } />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results-sum pb-4'>
			<Result columnIdx={1} span={6} child={
				'Število zapisov: ' + table_items_sort_ids.length
			} />
			<Result columnIdx={7}  cls='text-right font-bold' child={''} />
			<Result columnIdx={8}  cls='text-right font-bold' child={ sums.income             .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) } />
			<Result columnIdx={9}  cls='text-right font-bold' child={ sums.expense            .toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) } />
			<Result columnIdx={10} cls='text-right font-bold' child={ sums.cash_expense_amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) } />
			<Result columnIdx={11} cls='text-right font-bold' child={''} />
		</div>
	</>;
}

class Journals extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.openAddEditItemDialog      = this.openAddEditItemDialog     .bind(this);
		this.closeAddEditItemDialog     = this.closeAddEditItemDialog    .bind(this);
		this.updateTableItemsFromProps  = this.updateTableItemsFromProps .bind(this);
		this.refreshTableItemsSort      = this.refreshTableItemsSort     .bind(this);
		this.filterTableItems           = this.filterTableItems          .bind(this);
		this.handleFilterChange         = this.handleFilterChange        .bind(this);
		this.requestDeleteItem          = this.requestDeleteItem         .bind(this);
		this.deleteItem                 = this.deleteItem                .bind(this);
		this.findLastCashRegisterDailyStates = this.findLastCashRegisterDailyStates.bind(this);
		this.printA4                    = this.printA4                   .bind(this);
		this.openSummaryPrintDialog     = this.openSummaryPrintDialog    .bind(this);
		this.closeSummaryPrintDialog    = this.closeSummaryPrintDialog   .bind(this);
		this.getMomentFormatter         = this.getMomentFormatter        .bind(this);
		
		const table_items_filters = {
			document_number:     '',
			document_date_range: [moment().subtract(1, 'month').toDate(), null],
			id_users:            [],
			id_cash_registers:   props.current_id_cash_register === null || props.current_id_cash_register == 0 ? [] : [ props.current_id_cash_register ],
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'document_date',
			table_items_sort_direction: 'DESC',
		});
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'document_date',
			table_items_sort_direction: 'DESC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			table_items_checked_ids: {},
			add_edit_item_dialog_open:             false,
			add_edit_item_dialog_id_cash_register: null,
			add_edit_item_dialog_date:             null,
			add_edit_item_dialog_start_balance:    0,
			add_edit_item_dialog_documents:        [],
			add_edit_item_dialog_item:             null,
			delete_request_id_item:                null,
			summary_print_dialog_open:             false,
			summary_print_dialog_date_start:       null,
			summary_print_dialog_date_end:         null,
			summary_print_dialog_display_detailed_list: false,
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.users                      != prevProps.users          ||
			this.props.cash_registers             != prevProps.cash_registers ||
			this.props.cash_register_daily_states != prevProps.cash_register_daily_states
		) {
			const state = this.updateTableItemsFromProps(this.props, this.state);
			this.setState(
				state
			);
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const ids = Object.keys(props.cash_register_daily_states);
		
		let sort_ids = [];
		
		if (state !== undefined && state.table_items_sort_ids !== undefined) {
			for (let i=0; i<state.table_items_sort_ids.length; i++) {
				const id = state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const new_state = {
			invoices: props.invoices,
			table_items: props.cash_register_daily_states,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
			table_items_sort_field: state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a = '';
			let b = '';
			let dir = direction;
			
			a = state.table_items[a_key][field];
			b = state.table_items[b_key][field];
			
			return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		const filters_document_number     = filters.document_number;
		const filters_document_date_start = filters.document_date_range[0] === null ? null : moment(filters.document_date_range[0]).format('YYYY-MM-DD');
		const filters_document_date_end   = filters.document_date_range[1] === null ? null : moment(filters.document_date_range[1]).format('YYYY-MM-DD');
		const filters_id_users            = filters.id_users;
		const filters_id_cash_registers   = filters.id_cash_registers;
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_document_number != '' && item.document_number.indexOf(filters_document_number) == -1) {
				return false;
			}
			
			if (filters_document_date_start !== null && item.document_date !== null && item.document_date < filters_document_date_start) {
				return false;
			}
			if (filters_document_date_end !== null && item.document_date !== null && item.document_date > filters_document_date_end) {
				return false;
			}
			
			if (filters_id_users.length > 0) {
				if (item.id_user === null || filters_id_users.indexOf(item.id_user) == -1) {
					return false;
				}
			}
			
			if (filters_id_cash_registers.length > 0) {
				if (item.id_cash_register === null || filters_id_cash_registers.indexOf(item.id_cash_register) == -1) {
					return false;
				}
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			document_number:     this.state.table_items_filters.document_number,
			document_date_range: this.state.table_items_filters.document_date_range,
			id_users:            this.state.table_items_filters.id_users,
			id_cash_registers:   this.state.table_items_filters.id_cash_registers,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openAddEditItemDialog(item) {
		if (this.props.current_id_cash_register === null || this.props.current_id_cash_register == 0) {
			//TODO display message
			return;
		}
		
		if (item !== null && item !== undefined) {
			this.setState({
				add_edit_item_dialog_open:             true,
				add_edit_item_dialog_id_cash_register: item.id_cash_register,
				add_edit_item_dialog_date:             item.document_date,
				add_edit_item_dialog_start_balance:    item.start_balance,
				add_edit_item_dialog_documents:        Object.values(this.props.cash_register_documents).filter(x => x.id_cash_register_daily_state === item.id_cash_register_daily_state),
				add_edit_item_dialog_item:             item,
			});
			return;
		}
		
		// find latest daily state (for start balance)
		let last_daily_state = null;
		const sorted = Object.keys(this.props.cash_register_daily_states)
			.filter(x => this.props.cash_register_daily_states[x].id_cash_register == this.props.current_id_cash_register)
			.map(x => { return { id_cash_register_daily_state: x, document_date: this.props.cash_register_daily_states[x].document_date }; })
			.sort((a, b) => a.document_date < b.document_date ? 1 : (a.document_date > b.document_date ? -1 : 0));
		if (sorted.length > 0) {
			last_daily_state = this.props.cash_register_daily_states[sorted[0].id_cash_register_daily_state];
		}
		
		// only use documents for currently selected cash register
		const filtered_ids = Object.keys(this.props.cash_register_documents).filter(x => {
			if (this.props.current_id_cash_register == this.props.cash_register_documents[x].id_cash_register) return true;
			return false;
		});
		
		// group document dates
		const available_documents_by_date = {};
		for (let i=0; i < filtered_ids.length; i++) {
			const item = this.props.cash_register_documents[filtered_ids[i]];
			if (item !== undefined && item.id_cash_register_daily_state !== null) continue;
			
			if (available_documents_by_date[item.document_date] === undefined) {
				available_documents_by_date[item.document_date] = [];
			}
			available_documents_by_date[item.document_date].push(item);
		}
		
		// find min document date
		const document_dates = Object.keys(available_documents_by_date);
		let min_document_date = null;
		for (let i=0; i < document_dates.length; i++) {
			if (min_document_date === null || document_dates[i] < min_document_date) {
				min_document_date = document_dates[i];
			}
		}
		
		let selected_documents    = [];
		if (min_document_date !== null) {
			selected_documents = available_documents_by_date[min_document_date];
		}
		
		if (min_document_date === null) {
			min_document_date = moment().format('YYYY-MM-DD');
		}
		
		const last_cash_register_daily_states = this.findLastCashRegisterDailyStates();
		if (Object.values(last_cash_register_daily_states).find(
			x =>
				this.props.cash_register_daily_states[x.id_cash_register_daily_state].document_date == min_document_date
				&&
				x.id_cash_register == this.props.current_id_cash_register
		) !== undefined) {
			AppToaster.show({
				message: <div>
					Blagajniški dnevnik za dan {moment(min_document_date).format('DD. MM. YYYY')} je bil že zaključen.
					<br />
					<br />
					Če želite ponovno zaključiti blagajniški dnevnik, je potrebno že zaključen blagajniški dnevnik najprej stornirati.
				</div>,
				intent: 'warning',
				icon:   'issue',
			});
			return;
		}
		
		this.setState({
			add_edit_item_dialog_open:             true,
			add_edit_item_dialog_id_cash_register: this.props.current_id_cash_register,
			add_edit_item_dialog_date:             min_document_date,
			add_edit_item_dialog_start_balance:    last_daily_state === null ? 0 : (last_daily_state.end_balance === undefined ? 0 : last_daily_state.end_balance),
			add_edit_item_dialog_documents:        selected_documents,
			add_edit_item_dialog_item:             null,
		});
	}
	async closeAddEditItemDialog(document_date, income, expense, start_balance, cash_expense_amount, end_balance, id_cash_register) {
		if (document_date === undefined) {
			this.setState({
				add_edit_item_dialog_open: false,
			});
			return;
		}
		
		const item = {
			id_cash_register_daily_state: uuid.v4(),
			document_number: null,
			document_date,
			income,
			expense,
			start_balance,
			end_balance,
			id_cash_register,
			id_user: this.props.user.id_user,
		};
		
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		this.props.dispatch(addCashRegisterDailyState(item));
		
		let new_item = await saveCashRegisterDailyState(this.props.api_url, item, token);
		if (new_item !== null) {
			dispatch(addCashRegisterDailyState(new_item));
			
			const expense_doc = await saveCashRegisterDocument(
				this.props.api_url,
				{
					id_cash_register_document:    uuid.v4(),
					id_user:                      this.props.user.id_user,
					id_customer:                  null,
					customer_id_country:          null,
					customer_type:                null,
					customer_address:             null,
					customer_birth_date:          null,
					customer_company_long_name:   null,
					customer_company_name:        null,
					customer_email:               null,
					customer_name:                null,
					customer_surname:             null,
					customer_phone:               null,
					customer_post_code:           null,
					customer_post_office:         null,
					customer_sex:                 null,
					customer_tax_number:          null,
					customer_vat_prefix:          null,
					customer_vat_registered:      null,
					type:                         'expense',
					document_number:              null,
					reference:                    new_item.document_number,
					document_date:                new_item.document_date,
					note:                         '',
					status:                       'new',
					id_cash_register_daily_state: new_item.id_cash_register_daily_state,
					id_cash_register,
					manual: false,
				},
				token
			);
			
			new_item = {...new_item, expense_id_cash_register_document: expense_doc.id_cash_register_document };
			
			await saveCashRegisterDailyState(this.props.api_url, new_item, token);
			dispatch(addCashRegisterDailyState(new_item));
			
			const cash_id_payment_type = '2db820c7-a652-4f9e-8c93-7e1b09d399d5'; //TODO perhaps move this somewhere else?
			
			let sums_by_payment_types = {};
			sums_by_payment_types[cash_id_payment_type] = { income: 0, expense: cash_expense_amount };
			
			for (let i=0; i < this.state.add_edit_item_dialog_documents.length; i++) {
				// update original document
				const doc = {...this.state.add_edit_item_dialog_documents[i]};
				doc.id_cash_register_daily_state = new_item.id_cash_register_daily_state;
				
				await saveCashRegisterDocument(this.props.api_url, doc, token);
				
				if (doc.payment_types !== null && doc.payment_types !== undefined) {
					for (let j=0; j < doc.payment_types.length; j++) {
						const doc_payment_type = doc.payment_types[j];
						if (sums_by_payment_types[doc_payment_type.id_payment_type] === undefined) {
							sums_by_payment_types[doc_payment_type.id_payment_type] = { income: 0, expense: 0 };
						}
						if (doc_payment_type.id_payment_type == cash_id_payment_type) {
							// ignore
						}
						else {
							sums_by_payment_types[doc_payment_type.id_payment_type].income  += doc_payment_type.amount;
							sums_by_payment_types[doc_payment_type.id_payment_type].expense += doc_payment_type.amount;
						}
					}
				}
			}
			
			for (let id_payment_type in sums_by_payment_types) {
				if (sums_by_payment_types[id_payment_type].expense == 0) continue;
				
				await saveCashRegisterDocumentPaymentType(
					this.props.api_url,
					{
						id_cash_register_document_payment_type: uuid.v4(),
						id_cash_register_document:              expense_doc.id_cash_register_document,
						id_payment_type:                        id_payment_type,
						note:                                   '',
						amount:                                 sums_by_payment_types[id_payment_type].expense,
						full_amount:                            sums_by_payment_types[id_payment_type].expense,
						id_tax_rate:                            null,
						tax_rate:                               null,
					},
					token
				);
			}
			
			loadCashRegisterDocuments(this.props.api_url, dispatch, token);
		}
		
		this.setState({
			add_edit_item_dialog_open: false,
		});
	}
	
	requestDeleteItem(id_item) {
		this.setState({ delete_request_id_item: id_item });
	}
	async deleteItem(id_item) {
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		await deleteCashRegisterDailyState(this.props.api_url, id_item, token);
		
		dispatch(deleteCashRegisterDailyStateFromSlice(id_item));
		
		await loadCashRegisterDailyStates(this.props.api_url, dispatch, token);
		await loadCashRegisterDocuments  (this.props.api_url, dispatch, token);
	}
	
	findLastCashRegisterDailyStates() {
		const last_cash_register_daily_states = {};
		
		for (let id_cash_register_daily_state in this.state.table_items) {
			const item             = this.state.table_items[id_cash_register_daily_state];
			const year             = moment(item.document_date).format('YYYY');
			const id_cash_register = item.id_cash_register;
			
			const key = year + '-' + id_cash_register;
			if (last_cash_register_daily_states[key] === undefined || last_cash_register_daily_states[key].document_date < item.document_date) {				
				last_cash_register_daily_states[key] = {
					id_cash_register_daily_state: item.id_cash_register_daily_state,
					document_date:                item.document_date,
					id_cash_register,
				};
			}
		}
		
		return last_cash_register_daily_states;
	}
	
	printA4(lang) {
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		const ids = check_count == 0 ? this.state.table_items_sort_ids :
			this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true);
		
		for (let i=0; i<ids.length; i++) {
			const item = this.state.table_items[ids[i]];
			
			this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'journal', lang, item }));
		}
	}
	
	openSummaryPrintDialog() {
		this.setState({
			summary_print_dialog_open:       true,
			summary_print_dialog_date_start: null,
			summary_print_dialog_date_end:   null,
		});
	}
	closeSummaryPrintDialog(start_date, end_date, display_detailed_list) {
		this.setState({ summary_print_dialog_open: false });
		
		if (start_date === null || end_date === null) return;
		
		const start_date_iso = moment(start_date).format('YYYY-MM-DD');
		const end_date_iso   = moment(end_date)  .format('YYYY-MM-DD');
		
		const items = Object.keys(this.state.table_items)
			.filter(id_cash_register_daily_state => {
				const item = this.state.table_items[id_cash_register_daily_state];
				return this.props.current_id_cash_register == item.id_cash_register &&
					item.document_date >= start_date_iso &&
					item.document_date <= end_date_iso;
			})
			.map(id_cash_register_daily_state => this.state.table_items[id_cash_register_daily_state]);
		
		this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({
			type: 'journal-summary',
			lang: 'sl',
			items,
			display_detailed_list,
		}));
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return { 
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
		}
	}
	
	render() {
		// find last daily states for each year/cash register, since those should be the only ones deletable
		const last_cash_register_daily_states = this.findLastCashRegisterDailyStates();
		
		const last_cash_register_daily_state_ids =
			Object.values(last_cash_register_daily_states)
				.map(x => x.id_cash_register_daily_state)
				.filter(x => {
					if (this.props.current_id_cash_register === null || this.props.current_id_cash_register == 0) return true;
					if (this.props.current_id_cash_register == this.state.table_items[x].id_cash_register) return true;
					return false;
				});
		
		const check_count = this.state.table_items_sort_ids.filter(id => this.state.table_items_checked_ids[id] === true).length;
		
		return <>
			{!this.state.add_edit_item_dialog_open ? null :
				<AddJournalDialog
					closeDialog     ={this.closeAddEditItemDialog}
					id_cash_register={this.state.add_edit_item_dialog_id_cash_register}
					date            ={this.state.add_edit_item_dialog_date}
					start_balance   ={this.state.add_edit_item_dialog_start_balance}
					documents       ={this.state.add_edit_item_dialog_documents}
					item            ={this.state.add_edit_item_dialog_item} />
			}
			
			{this.state.delete_request_id_item === null ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Storniraj'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						this.deleteItem(this.state.delete_request_id_item);
						this.setState({ delete_request_id_item: null });
					}}
					onCancel={() => {
						this.setState({ delete_request_id_item: null });
					}}>
					{'Stornirali boste blagajniški dnevnik št. ' + this.props.cash_register_daily_states[this.state.delete_request_id_item].document_number}
				</Alert>
			}
			
			<Dialog
				isOpen={this.state.summary_print_dialog_open}
				onClose={this.closeSummaryPrintDialog}
				className='summary-print-dialog'>
				<div className='flex flex-col flex-grow'>
					<Navbar fixedToTop={false} className='bp5-dark'>
						<Navbar.Group>
							<Navbar.Heading>Izberi obdobje</Navbar.Heading>
						</Navbar.Group>
						<Navbar.Group align={Alignment.RIGHT}>
							<Button minimal={true} icon='cross' onClick={this.closeSummaryPrintDialog} />
						</Navbar.Group>
					</Navbar>
					<div className={Classes.DIALOG_BODY}>
						<FormGroup>
							<TextWithTooltip
								label='Izberi obdobje'
								tooltip='Izberi obdobje' />
							<DateRangeInput3
								dateFnsLocaleLoader={loadDateFnsLocale}
								dateFnsFormat={'dd. MM. yyyy'}
								locale='sl'
								allowSingleDayRange={true}
								contiguousCalendarMonths={false}
								shortcuts={false}
								startInputProps={{ placeholder: 'Od', className: 'from-until' }}
								endInputProps={{ placeholder: 'Do', className: 'from-until' }}
								minDate={new Date('1900-01-01')}
								maxDate={new Date('2100-01-01')}
								selectAllOnFocus={true}
								onChange={(selectedRange) => {
									this.setState({
										summary_print_dialog_date_start: selectedRange[0] === null ? null : selectedRange[0],
										summary_print_dialog_date_end:   selectedRange[1] === null ? null : selectedRange[1],
									});
								}}
								value={[
									this.state.summary_print_dialog_date_start,
									this.state.summary_print_dialog_date_end,  
								]} />
						</FormGroup>
						<FormGroup>
							<TextWithTooltip
								label='Prikaži podrobni seznam dokumentov'
								tooltip='Prikaži podrobni seznam dokumentov' />
							<Checkbox
								checked={this.state.summary_print_dialog_display_detailed_list}
								onChange={event => this.setState({ summary_print_dialog_display_detailed_list: event.target.checked })} />
						</FormGroup>
					</div>
					<div className={Classes.DIALOG_FOOTER}>
						<div className={Classes.DIALOG_FOOTER_ACTIONS}>
							<Button
								minimal={true}
								onClick={this.closeSummaryPrintDialog}>
								Prekliči
							</Button>
							<Button
								intent={Intent.PRIMARY}
								onClick={() => this.closeSummaryPrintDialog(
									this.state.summary_print_dialog_date_start,
									this.state.summary_print_dialog_date_end,
									this.state.summary_print_dialog_display_detailed_list
								)}>
								Natisni
							</Button>
						</div>
					</div>
				</div>
			</Dialog>
			
			<div className='flex flex-col flex-grow base-list journal-list'>
				<div className='pl-4 pt-4'>
					<ButtonGroup className='align-middle'>
						<Button
							intent='success'
							icon='plus'
							disabled={this.props.current_id_cash_register == 0}
							onClick={() => this.openAddEditItemDialog()}>
							Zaključi dnevnik
						</Button>
					</ButtonGroup>
					<ButtonGroup className='ml-2 align-middle'>
						<Button
							intent='primary'
							icon='print'
							disabled={check_count == 0}
							onClick={() => this.printA4('sl')}>
							Natisni
						</Button>
					</ButtonGroup>
					<ButtonGroup className='ml-2 align-middle'>
						<Button
							intent='primary'
							icon='print'
							disabled={this.props.current_id_cash_register === null || this.props.current_id_cash_register == 0}
							onClick={() => this.openSummaryPrintDialog()}>
							Zbirni dnevnik
						</Button>
					</ButtonGroup>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items              ={this.state.table_items}
							table_items_sort_ids     ={this.state.table_items_sort_ids}
							table_items_checked_ids  ={this.state.table_items_checked_ids}
							rowCheckStateChanged     ={(id, state) => {
								let table_items_checked_ids = this.state.table_items_checked_ids;
								
								if (state === false) {
									delete table_items_checked_ids[id];
								}
								else {
									table_items_checked_ids[id] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							allRowsCheckStateChanged  ={state => {
								const table_items_checked_ids = {};
								for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
									table_items_checked_ids[this.state.table_items_sort_ids[i]] = state;
								}
								
								this.setState({ table_items_checked_ids });
							}}
							openAddEditItemDialog    ={this.openAddEditItemDialog}
							tableItemsFilters        ={this.state.table_items_filters}
							originalTableItemsFilters={this.state.original_table_items_filters}
							handleFilterChange       ={this.handleFilterChange}
							deleteItem               ={this.requestDeleteItem}
							cash_registers           ={this.props.cash_registers}
							users                    ={this.props.users}
							allow_delete_ids         ={last_cash_register_daily_state_ids}
							cash_register_documents  ={this.props.cash_register_documents} />
					</div>
				</div>
			</div>
		</>;
	}
}
Journals.propTypes = {
};

function mapStateToProps(state) {
	return {
		api_url:                    state.CashRegisterSlice.api_url,
		token:                      state.UserSlice.token,
		user:                       state.UserSlice.user,
		users:                      state.UserSlice.users,
		current_id_cash_register:   state.SettingsSlice.current_id_cash_register,
		cash_registers:             state.CashRegisterSlice.cash_registers,
		cash_register_daily_states: state.CashRegisterSlice.cash_register_daily_states,
		cash_register_documents:    state.CashRegisterSlice.cash_register_documents,
	};
}

export default connect(mapStateToProps)(Journals);
