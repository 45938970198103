import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import {
	Button,
	ButtonGroup,
	Alert,
	Intent,
	Tooltip,
	Dialog,
	ProgressBar,
	Classes,
} from '@blueprintjs/core';

import {
	saveMonthlyGuestBookReport,
	loadMonthlyGuestBookReports,
	sendReservationCustomersToETourism,
} from '../../api/Guests';
import AddMonthlyGuestBookReportDialog from './AddMonthlyGuestBookReportDialog';

import { ResultHeader, Result, MultiSelectFilter } from '../code_tables/ResultComponents';

const monthly_titles = {
	 1: 'januar',
	 2: 'februar',
	 3: 'marec',
	 4: 'april',
	 5: 'maj',
	 6: 'junij',
	 7: 'julij',
	 8: 'avgust',
	 9: 'september',
	10: 'oktober',
	11: 'november',
	12: 'december',
};
const etourism_statuses = {
	none:    'NEPOSLAN',
	waiting: 'ČAKA NA POŠILJANJE',
	success: 'POSLANO USPEŠNO',
	failure: 'NAPAKA PRI POŠILJANJU',
};

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		tableItemsFilters,
		handleFilterChange,
		users,
		payment_types,
		accommodations,
	} = props;
	
	const users_with_titles = {};
	for (let id_user in users) {
		users_with_titles[id_user] = { id_user, title: users[id_user].name + ' ' + users[id_user].surname };
	}
	
	const payment_types_with_titles = {};
	for (let id_payment_type in payment_types) {
		payment_types_with_titles[id_payment_type] = { id_payment_type, title: payment_types[id_payment_type].title };
	}
	
	const etourism_status_items = {
		none:    { title: 'Neposlan',              value: 'none'    },
		waiting: { title: 'Čaka na pošiljanje',    value: 'waiting' },
		success: { title: 'Poslano uspešno',       value: 'success' },
		failure: { title: 'Napaka pri pošiljanju', value: 'failure' },
	};
	
	const accommodations_by_id_nos = {};
	for (let id_accommodation in accommodations) {
		const accommodation = accommodations[id_accommodation];
		if (
			accommodation.etourism_id_rno === null ||
			accommodation.etourism_id_rno == 0     ||
			accommodation.etourism_id_rno === undefined
		) continue;
		
		accommodations_by_id_nos[accommodation.etourism_id_rno] = accommodation;
	}
	
	return <>
		<div className='results mt-4 mb-4'>
			<div className='results-table overflow-y-auto sticky-header'>
				<ResultHeader columnIdx={1} title='Urejanje' />
				<ResultHeader
					columnIdx={2} title='Status' />
				<ResultHeader
					columnIdx={3} title='Leto' />
				<ResultHeader
					columnIdx={4} title='Mesec' />
				<ResultHeader
					columnIdx={5} title='idRNO' />
				<ResultHeader
					columnIdx={6} title='Status' />
				<ResultHeader
					columnIdx={7} title='Dod. ležišča' />
				<ResultHeader
					columnIdx={8} title='Prodane NE' />
				<ResultHeader
					columnIdx={9} title='Odprto št. dni' />
				
				<Result columnIdx={1} cls='filters-col' />
				<Result columnIdx={2} child={
					<div className='flex flex-row'>
						<MultiSelectFilter
							empty_title    ='Izberi'
							value_field    ='value'
							title_field    ='title'
							items          ={etourism_status_items}
							filtered_keys  ={tableItemsFilters.etourism_statuses}
							onFilterChange ={values => handleFilterChange('etourism_statuses', values)} />
					</div>
				} />
				<Result columnIdx={3} cls='filters-col' />
				<Result columnIdx={4} cls='filters-col' />
				<Result columnIdx={5} cls='filters-col' />
				<Result columnIdx={6} cls='filters-col' />
				<Result columnIdx={7} cls='filters-col' />
				<Result columnIdx={8} cls='filters-col' />
				<Result columnIdx={9} cls='filters-col' />
				
				{table_items_sort_ids.map((id, idx) => {
					const item = table_items[id];
					
					return <React.Fragment
						key={'result--result-' + id}>
						<Result columnIdx={1} child={
							<>
								<Button
									icon='search'
									intent='primary'
									minimal={true}
									small={true}
									disabled={true}
									onClick={() => {
										//openAddEditItemDialog(item);
									}} />
								<Button
									icon='trash'
									intent='danger'
									minimal={true}
									small={true}
									disabled={true}
									onClick={() => {
										//TODO change
										//deleteItem(id);
									}} />
							</>
						} />
						<Result columnIdx={2} child={ etourism_statuses[
							item.etourism_status === null ? 'none' : item.etourism_status
						] } />
						<Result columnIdx={3} child={ item.year } />
						<Result columnIdx={4} child={ monthly_titles[item.month] } />
						<Result columnIdx={5} child={
							item.id_no +
							' • ' +
							accommodations_by_id_nos[item.id_no].title
						} />
						<Result columnIdx={6} child={ item.etourism_report_status } />
						<Result columnIdx={7} child={ item.extra_bed_count } />
						<Result columnIdx={8} child={ item.sold_accommodation_unit_count } />
						<Result columnIdx={9} child={ item.active_day_count } />
					</React.Fragment>;
				})}
			</div>
		</div>
		<div className='results-sum pb-4'>
			<div className='col'>
				Število zapisov: {table_items_sort_ids.length}
			</div>
		</div>
	</>;
}

class MonthlyGuestBookReports extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted = false;
		
		this.openAddEditItemDialog     = this.openAddEditItemDialog    .bind(this);
		this.closeAddEditItemDialog    = this.closeAddEditItemDialog   .bind(this);
		this.updateTableItemsFromProps = this.updateTableItemsFromProps.bind(this);
		this.refreshTableItemsSort     = this.refreshTableItemsSort    .bind(this);
		this.filterTableItems          = this.filterTableItems         .bind(this);
		this.handleFilterChange        = this.handleFilterChange       .bind(this);
		
		const table_items_filters = {
			etourism_statuses: [],
		};
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props, {
			table_items_filters,
			table_items_sort_field: 'year-month-id_no',
			table_items_sort_direction: 'DESC',
		});
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'year-month-id_no',
			table_items_sort_direction: 'DESC',
			table_items_filters,
			original_table_items_filters: {...table_items_filters},
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			saving: false,
			existing_item_warning_dialog_open:  false,
			existing_item_warning_dialog_year:  -1,
			existing_item_warning_dialog_month: -1,
			old_year_month_warning_dialog_open: false,
			warning_dialog_items:               [],
		};
	}
	
	componentDidMount() {
		this._ismounted = true;
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	openAddEditItemDialog(item) {
		this.setState({
			add_edit_item_dialog_open: true,
			add_edit_item_dialog_item: item,
		});
	}
	async closeAddEditItemDialog(items, force) {
		force = force === undefined ? false : force;
		
		if (items === null || items === undefined || items.length == 0) {
			this.setState({
				add_edit_item_dialog_open: false,
			});
			return;
		}
		
		if (!force) {
			let warning_state = {};
			
			const year  = items[0].year;
			const month = items[0].month;
			
			// check for 
			let found_year_month = false;
			for (let id_monthly_guest_book_report in this.props.monthly_guest_book_reports) {
				const item = this.props.monthly_guest_book_reports[id_monthly_guest_book_report];
				if (item.year == year && item.month == month) {
					found_year_month = true;
					break;
				}
			}
			
			if (found_year_month) {
				warning_state = {
					...warning_state,
					...{
						existing_item_warning_dialog_open:  true,
						existing_item_warning_dialog_year:  year,
						existing_item_warning_dialog_month: month,
					},
				};
			}
			
			if (moment().diff({ year, month: month - 1 }, 'months') > 1) {
				warning_state = {
					...warning_state,
					...{
						old_year_month_warning_dialog_open: true,
					},
				};
			}
			
			if (Object.keys(warning_state).length > 0) {
				warning_state = {
					...warning_state,
					...{
						warning_dialog_items: items,
					},
				};
				
				this.setState(warning_state);
				return;
			}
		}
		
		this.setState({ saving: true });
		
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		for (let i=0; i<items.length; i++) {
			await saveMonthlyGuestBookReport(
				this.props.api_url,
				items[i],
				token
			);
		}
		
		await sendReservationCustomersToETourism(this.props.api_url, token);
		
		loadMonthlyGuestBookReports(this.props.api_url, dispatch, token);
		
		this.setState({
			add_edit_item_dialog_open: false,
			saving: false,
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.props.users                      != prevProps.users ||
			this.props.monthly_guest_book_reports != prevProps.monthly_guest_book_reports
		) {
			const state = this.updateTableItemsFromProps(this.props, this.state);
			this.setState(
				state
			);
		}
	}
	
	updateTableItemsFromProps(props, state) {
		const ids = Object.keys(props.monthly_guest_book_reports);
		
		let sort_ids = [];
		
		if (state !== undefined && state.table_items_sort_ids !== undefined) {
			for (let i=0; i<state.table_items_sort_ids.length; i++) {
				const id = state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		//TODO change
		const new_state = {
			invoices: props.invoices,
			table_items: props.monthly_guest_book_reports,
			table_items_sort_ids: sort_ids,
			table_items_filtered_ids: ids,
			table_items_sort_field: state.table_items_sort_field,
			table_items_sort_direction: state.table_items_sort_direction,
		};
		
		const {
			table_items_filtered_ids,
			table_items_filters,
			table_items_sort_ids,
		} = this.filterTableItems(state.table_items_filters, new_state, true);
		
		new_state.table_items_filtered_ids = table_items_filtered_ids;
		new_state.table_items_filters      = table_items_filters;
		new_state.table_items_sort_ids     = table_items_sort_ids;
		
		return new_state;
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, state, return_only) {
		state                    = state || this.state;
		table_items_filtered_ids = table_items_filtered_ids || state.table_items_filtered_ids;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			let a = '';
			let b = '';
			let dir = direction;
			
			a = state.table_items[a_key][field];
			b = state.table_items[b_key][field];
			
			if (field == 'year-month-id_no') {
				a =
					state.table_items[a_key]['year'] +
					'-' +
					(state.table_items[a_key]['month'] + '').padStart(2, '0') +
					'-' +
					(state.table_items[a_key]['id_no'] + '').padStart(20, '0');
				b =
					state.table_items[b_key]['year'] +
					'-' +
					(state.table_items[b_key]['month'] + '').padStart(2, '0') +
					'-' +
					(state.table_items[b_key]['id_no'] + '').padStart(20, '0');
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (dir == 'ASC' ? 1 : -1);
		});
		
		const new_state = {
			table_items_sort_ids: ids,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		return new_state;
	}
	
	toIsoString(date) {
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	
	filterTableItems(filters, state, return_only) {
		state = state || this.state;
		
		//TODO change
		const filters_etourism_statuses = filters.etourism_statuses;
		
		const ids = Object.keys(state.table_items);
		const filtered_ids = ids.filter(id => {
			const item = state.table_items[id];
			
			if (filters_etourism_statuses.length > 0) {
				if (filters_etourism_statuses.indexOf(item.etourism_status) == -1) {
					return false;
				}
			}
			
			return true;
		});
		
		const new_state = {
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		};
		
		if (!return_only) {
			this.setState(new_state);
		}
		
		const { table_items_sort_ids } = this.refreshTableItemsSort(
			state.table_items_sort_field,
			state.table_items_sort_direction,
			filtered_ids,
			{
				table_items_filtered_ids: new_state,
				table_items_filters:      new_state,
				table_items:              state.table_items,
			},
			return_only
		);
		
		new_state.table_items_sort_ids = table_items_sort_ids;
		
		return new_state;
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {
			etourism_statuses: this.state.table_items_filters.etourism_statuses,
		};
		
		let val = event;
		if (val === null) { }
		if (val instanceof Date) { }
		else if (Array.isArray(val)) { }
		else if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	render() {
		return <>
			{!this.state.add_edit_item_dialog_open ? null :
				<AddMonthlyGuestBookReportDialog
					closeDialog={this.closeAddEditItemDialog}
					item       ={this.state.add_edit_item_dialog_item} />
			}
			
			<Dialog
				isOpen={this.state.saving}
				usePortal={true}
				canOutsideClickClose={false}
				canEscapeKeyClose={false}>
				<div className='flex flex-col flex-grow'>
					<div className={Classes.DIALOG_BODY}>
						<ProgressBar animate={true} intent='primary' />
						
						<div className='text-xl text-center font-light pt-5'>Shranjevanje</div>
					</div>
				</div>
			</Dialog>
			
			{!this.state.existing_item_warning_dialog_open && !this.state.old_year_month_warning_dialog_open ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Pošlji poročilo'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='warning-sign'
					intent={Intent.WARNING}
					isOpen={true}
					onConfirm={() => {
						this.closeAddEditItemDialog(this.state.warning_dialog_items, true);
						
						this.setState({
							existing_item_warning_dialog_open:  false,
							old_year_month_warning_dialog_open: false,
						});
					}}
					onCancel={() => {
						this.setState({
							existing_item_warning_dialog_open:  false,
							old_year_month_warning_dialog_open: false,
						});
					}}>
					<div>
						{!this.state.existing_item_warning_dialog_open ? null :
							<div className='p-1'>{
								'Poročilo za mesec ' +
									monthly_titles[parseInt(this.state.existing_item_warning_dialog_month, 10)] +
									' ' + 
									this.state.existing_item_warning_dialog_year +
									' je že bilo oddano. V kolikor ga želite ponovno poslati, se bodo obstoječi podatki prepisali z novimi.'
							}</div>
						}
						{!this.state.old_year_month_warning_dialog_open ? null :
							<div className='p-1'>
								<div>Mesečno poročilo je možno posredovati zgolj v omejenem obdobju.</div>
								<div>Mesečno poročilo za pretekle mesece (dovoljeno število preteklih mesecev je 1), lahko oddate le do zadnjega dne v tekočem mesecu.</div>
								<div>Mesečno poročilo za tekoči mesec, lahko oddate po prvem dnevu v tekočem mesecu.</div>
							</div>
						}
					</div>
				</Alert>
			}
			
			<div className='flex flex-col flex-grow base-list monthly-guest-book-report-list'>
				<div className='pl-4 pt-4'>
					<ButtonGroup className='align-middle'>
						<Button
							intent='success'
							icon='plus'
							onClick={() => this.openAddEditItemDialog(null)}>
							Novo mesečno poročilo
						</Button>
					</ButtonGroup>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow' style={{ flexBasis: '0' }}>
						<Results
							table_items          ={this.state.table_items}
							table_items_sort_ids ={this.state.table_items_sort_ids}
							tableItemsFilters    ={this.state.table_items_filters}
							handleFilterChange   ={this.handleFilterChange}
							users                ={this.props.users}
							payment_types        ={this.props.payment_types}
							accommodations       ={this.props.accommodations} />
					</div>
				</div>
			</div>
		</>;
	}
}
MonthlyGuestBookReports.propTypes = {
};

function mapStateToProps(state) {
	return {
		api_url:                    state.CashRegisterSlice.api_url,
		token:                      state.UserSlice.token,
		user:                       state.UserSlice.user,
		users:                      state.UserSlice.users,
		monthly_guest_book_reports: state.GuestSlice.monthly_guest_book_reports,
		accommodations:             state.CodeTablesSlice.accommodations,
	};
}

export default connect(mapStateToProps)(MonthlyGuestBookReports);
