import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	NumericInput,
} from '@blueprintjs/core';

import TextWithTooltip from '../TextWithTooltip';
import { AppToaster }  from '../AppToaster';

class AddEditCountry extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id_country:                  props.item === null ? -1 : props.item.id_country,
			common_name_international:   props.item === null ? '' : props.item.common_name_international,
			official_name_international: props.item === null ? '' : props.item.official_name_international,
			common_name_local:           props.item === null ? '' : props.item.common_name_local,
			official_name_local:         props.item === null ? '' : props.item.official_name_local,
			iso_3166_1_a2:               props.item === null ? '' : props.item.iso_3166_1_a2,
			iso_3166_1_a3:               props.item === null ? '' : props.item.iso_3166_1_a3,
			iso_3166_1_n3:               props.item === null ? '' : props.item.iso_3166_1_n3,
			currency:                    props.item === null ? '' : props.item.currency,
			calling_code:                props.item === null ? '' : props.item.calling_code,
			validation_failed_fields: [],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-country-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi državo</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeAddEditItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='add-edit-country-dialog--common_name_international'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('common_name_international') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Naziv'
								tooltip='Naziv' />
							<InputGroup
								id='add-edit-country-dialog--common_name_international'
								value={this.state.common_name_international}
								onChange={event => {
									this.setState({
										common_name_international: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-country-dialog--official_name_international'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('official_name_international') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Uradni naziv'
								tooltip='Uradni naziv' />
							<InputGroup
								id='add-edit-country-dialog--official_name_international'
								value={this.state.official_name_international}
								onChange={event => {
									this.setState({
										official_name_international: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-country-dialog--common_name_local'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('common_name_local') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Lokalni naziv'
								tooltip='Lokalni Naziv' />
							<InputGroup
								id='add-edit-country-dialog--common_name_local'
								value={this.state.common_name_local}
								onChange={event => {
									this.setState({
										common_name_local: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-country-dialog--official_name_local'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('official_name_local') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Lokalni uradni naziv'
								tooltip='Lokalni uradni naziv' />
							<InputGroup
								id='add-edit-country-dialog--official_name_local'
								value={this.state.official_name_local}
								onChange={event => {
									this.setState({
										official_name_local: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-country-dialog--iso_3166_1_a2'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('iso_3166_1_a2') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='ISO 3166-1 koda (2 črki)'
								tooltip='ISO 3166-1 koda (2 črki)' />
							<InputGroup
								id='add-edit-country-dialog--iso_3166_1_a2'
								value={this.state.iso_3166_1_a2}
								onChange={event => {
									this.setState({
										iso_3166_1_a2: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-country-dialog--iso_3166_1_a3'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('iso_3166_1_a3') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='ISO 3166-1 koda (3 črke)'
								tooltip='ISO 3166-1 koda (3 črke)' />
							<InputGroup
								id='add-edit-country-dialog--iso_3166_1_a3'
								value={this.state.iso_3166_1_a3}
								onChange={event => {
									this.setState({
										iso_3166_1_a3: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-country-dialog--iso_3166_1_n3'
							className={
								(this.state.validation_failed_fields.indexOf('iso_3166_1_n3') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='ISO 3166-1 koda (3 cifre)'
								tooltip='ISO 3166-1 koda (3 cifre)' />
							<NumericInput
								id='add-edit-country-dialog--iso_3166_1_n3'
								fill={true}
								min={0}
								value={this.state.iso_3166_1_n3}
								onValueChange={val => {
									this.setState({
										iso_3166_1_n3: val,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-country-dialog--currency'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('currency') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Koda valute'
								tooltip='Koda valute' />
							<InputGroup
								id='add-edit-country-dialog--currency'
								value={this.state.currency}
								onChange={event => {
									this.setState({
										currency: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-country-dialog--calling_code'
							className={
								(this.state.validation_failed_fields.indexOf('calling_code') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Klicna koda (brez +)'
								tooltip='Klicna koda (brez +)' />
							<NumericInput
								id='add-edit-country-dialog--calling_code'
								fill={true}
								min={0}
								value={this.state.calling_code}
								onValueChange={val => {
									this.setState({
										calling_code: val,
									});
								}} />
						</FormGroup>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const new_item = {
									id_country:                  this.state.id_country,
									common_name_international:   this.state.common_name_international,
									official_name_international: this.state.official_name_international,
									common_name_local:           this.state.common_name_local,
									official_name_local:         this.state.official_name_local,
									iso_3166_1_a2:               this.state.iso_3166_1_a2,
									iso_3166_1_a3:               this.state.iso_3166_1_a3,
									iso_3166_1_n3:               this.state.iso_3166_1_n3,
									currency:                    this.state.currency,
									calling_code:                this.state.calling_code,
									serial_internal_code: this.props.item === null ? null : this.props.item.serial_internal_code,
								};
								
								// validate missing/empty fields
								const validation_failed_fields       = [];
								const validation_failed_field_titles = [];
								
								if (new_item.common_name_international.length == 0) {
									validation_failed_fields.push('common_name_international');
									validation_failed_field_titles.push('Naziv');
								}
								if (new_item.official_name_international.length == 0) {
									validation_failed_fields.push('official_name_international');
									validation_failed_field_titles.push('Uradni naziv');
								}
								if (new_item.common_name_local.length == 0) {
									validation_failed_fields.push('common_name_local');
									validation_failed_field_titles.push('Lokalni naziv');
								}
								if (new_item.official_name_local.length == 0) {
									validation_failed_fields.push('official_name_local');
									validation_failed_field_titles.push('Lokalni uradni naziv');
								}
								if (new_item.iso_3166_1_a2.length == 0) {
									validation_failed_fields.push('iso_3166_1_a2');
									validation_failed_field_titles.push('ISO 3166-1 koda (2 črki)');
								}
								if (new_item.iso_3166_1_a3.length == 0) {
									validation_failed_fields.push('iso_3166_1_a3');
									validation_failed_field_titles.push('ISO 3166-1 koda (3 črke)');
								}
								if (new_item.iso_3166_1_n3.length == 0) {
									validation_failed_fields.push('iso_3166_1_n3');
									validation_failed_field_titles.push('ISO 3166-1 koda (3 cifre)');
								}
								if (new_item.currency.length == 0) {
									validation_failed_fields.push('currency');
									validation_failed_field_titles.push('Koda valute');
								}
								if (new_item.calling_code.length == 0) {
									validation_failed_fields.push('calling_code');
									validation_failed_field_titles.push('Klicna koda (brez +)');
								}
								
								this.setState({ validation_failed_fields });
								
								if (validation_failed_fields.length > 0) {
									AppToaster.show({
										message: <div>
											Za shranjevanje, izpolnite vsaj polja:
											<br />
											{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
											</div>,
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								
								if (new_item.iso_3166_1_a2.length != 2) {
									validation_failed_fields.push('iso_3166_1_a2');
									this.setState({ validation_failed_fields });
									
									AppToaster.show({
										message: 'ISO 3166-1 koda (2 črki) mora vsebovati točno 2 znaka',
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								if (new_item.iso_3166_1_a3.length != 3) {
									validation_failed_fields.push('iso_3166_1_a3');
									this.setState({ validation_failed_fields });
									
									AppToaster.show({
										message: 'ISO 3166-1 koda (3 črke) mora vsebovati točno 3 znake',
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								if (new_item.iso_3166_1_n3 < 100 || new_item.iso_3166_1_n3 > 999) {
									validation_failed_fields.push('new_item.iso_3166_1_n3');
									this.setState({ validation_failed_fields });
									
									AppToaster.show({
										message: 'ISO 3166-1 koda (3 cifre) mora biti med 100 in 999',
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								
								// save item
								this.props.closeAddEditItemDialog(new_item);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditCountry.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item: PropTypes.object,
};

export default AddEditCountry;
