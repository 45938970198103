import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Button,
	Intent,
	Alert,
	Tooltip,
	Icon,
} from '@blueprintjs/core';

import moment from 'moment';

import AddEditBusinessPremise from './AddEditBusinessPremise';
import BusinessPremiseFiscalRegistrationHistoryDialog from './BusinessPremiseFiscalRegistrationHistoryDialog';
import { addBusinessPremise, deleteBusinessPremise } from '../../slices/CodeTablesSlice';

import { ResultHeader, ResultHeaderWithSort, Result } from './ResultComponents';

function Results(props) {
	const {
		table_items,
		table_items_sort_ids,
		table_items_sort_field,
		table_items_sort_direction,
		setTableItemsSort,
		openAddEditItemDialog,
		deleteItem,
		business_premise_fiscal_registrations,
		business_premise_fiscal_registrations_by_id_business_premises,
		openBusinessPremiseFiscalRegistrationHistoryDialog,
	} = props;
	
	const sort_props = {
		setTableItemsSort:       setTableItemsSort,
		tableItemsSortField:     table_items_sort_field,
		tableItemsSortDirection: table_items_sort_direction,
	};
	
	const toLocalStringWithTime = date => {
		if (date === null) return '';
		
		return date.getDate().toString().padStart(2, '0') + '.' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '.' +
			date.getFullYear() + ' ' +
			date.getHours().toString().padStart(2, '0') + ':' +
			date.getMinutes().toString().padStart(2, '0') + ':' +
			date.getSeconds().toString().padStart(2, '0');
	};
	
	return <div className='results mt-4 mb-4'>
		<div className='results-table'>
			<ResultHeader columnIdx={1} title='' />
			<ResultHeader columnIdx={2} title='Urejanje' />
				<ResultHeader
					columnIdx={3} title='Status' />
			<ResultHeaderWithSort
				columnIdx={4} {...sort_props} title='Oznaka prostora (FURS)' fieldName='furs_code' />
			<ResultHeaderWithSort
				columnIdx={5} {...sort_props} title='Št. katastrske občine' fieldName='cadastral_municipality' />
			<ResultHeaderWithSort
				columnIdx={6} {...sort_props} title='Št. stavbe' fieldName='building_number' />
			<ResultHeaderWithSort
				columnIdx={7} {...sort_props} title='Št. dela stavbe' fieldName='building_part_number' />
			<ResultHeaderWithSort
				columnIdx={8} {...sort_props} title='Ulica' fieldName='street' />
			<ResultHeaderWithSort
				columnIdx={9} {...sort_props} title='Hišna št.' fieldName='house_number' />
			<ResultHeaderWithSort
				columnIdx={10} {...sort_props} title='Dod. k hišni št.' fieldName='house_number_appendix' />
			<ResultHeaderWithSort
				columnIdx={11} {...sort_props} title='Naselje' fieldName='town' />
			<ResultHeaderWithSort
				columnIdx={12} {...sort_props} title='Poštna št.' fieldName='post_code' />
			<ResultHeaderWithSort
				columnIdx={13} {...sort_props} title='Pošta' fieldName='post_office' />
			<ResultHeaderWithSort
				columnIdx={14} {...sort_props} title='Kraj' fieldName='city' />
			<ResultHeaderWithSort
				columnIdx={15} {...sort_props} title='Veljaven od' fieldName='valid_from' />
			<ResultHeaderWithSort
				columnIdx={16} {...sort_props} title='Prijavljen na FURS od' fieldName='registered_at_furs' />
		</div>
		
		{table_items_sort_ids.map((id, idx) => {
			const item = table_items[id];
			
			const business_premise_fiscal_registration = item.id_business_premise_fiscal_registration === null ? null :
				(business_premise_fiscal_registrations[item.id_business_premise_fiscal_registration] || null);
			
			const business_premise_fiscal_registrations_for_business_premise =
				(business_premise_fiscal_registrations_by_id_business_premises === undefined ?
					undefined : business_premise_fiscal_registrations_by_id_business_premises[item.id_business_premise]) || [];
			
			const getStatus = status => {
				if (status === null) {
					return 'v pošiljanju';
				}
				else if (status) {
					return 'potrjeno';
				}
				return 'napaka pri potrjevanju';
			};
			
			let icon  = '';
			let color = '';
			if (business_premise_fiscal_registration !== null) {
				if (business_premise_fiscal_registration.status === null) {
					icon   = 'history';
					color  = '#d9822b';
				}
				else if (business_premise_fiscal_registration.status) {
					icon   = 'tick-circle';
					color  = '#0f9960';
				}
				else {
					icon   = 'warning-sign';
					color  = '#db3737';
				}
			}
			
			const tooltip_content = <div>
				<div className='grid grid-cols-2 col-gap-4 gap-x-4 row-gap-2 gap-y-2'>
					<>{business_premise_fiscal_registrations_for_business_premise.map((business_premise_fiscal_registration, statusIdx) => {
						return <React.Fragment key={'business-premise-fiscal-registration-' + business_premise_fiscal_registration.id_business_premise_fiscal_registration}>
							<div className={statusIdx == 0 ? 'font-bold' : ''}>{toLocalStringWithTime(new Date(business_premise_fiscal_registration.created + '00'))}</div>
							<div className={statusIdx == 0 ? 'font-bold' : ''}>{getStatus(business_premise_fiscal_registration.status)}</div>
						</React.Fragment>;
					})}</>
				</div>
			</div>;
			
			return <div className='results-table' key={'countries-result--result-' + id}>
				<Result columnIdx={1} child={item.serial_internal_code} />
				<Result columnIdx={2} child={
					<>
						<Button
							icon='edit'
							intent='primary'
							minimal={true}
							small={true}
							onClick={() => openAddEditItemDialog(item)} />
						<Button
							icon='trash'
							intent='danger'
							minimal={true}
							small={true}
							onClick={() => deleteItem(item)} />
					</>
				} />
				<Result columnIdx={3} child={
					<Tooltip content={tooltip_content} position='right-bottom'>
						<Button
							minimal={true}
							icon={<Icon color={color} icon={icon} />}
							className='ml-1'
							onClick={() => openBusinessPremiseFiscalRegistrationHistoryDialog(id)} />
					</Tooltip>
				} />
				<Result columnIdx={4} child={item.furs_code} />
				<Result columnIdx={5} child={item.cadastral_municipality} />
				<Result columnIdx={6} child={item.building_number} />
				<Result columnIdx={7} child={item.building_part_number} />
				<Result columnIdx={8} child={item.street} />
				<Result columnIdx={9} child={item.house_number} />
				<Result columnIdx={10} child={item.house_number_appendix} />
				<Result columnIdx={11} child={item.town} />
				<Result columnIdx={12} child={item.post_code} />
				<Result columnIdx={13} child={item.post_office} />
				<Result columnIdx={14} child={item.city} />
				<Result columnIdx={15} child={moment(item.valid_from).format('DD. MM. YYYY')} />
				<Result columnIdx={16} child={item.registered_at_furs === null ? '' : moment(item.registered_at_furs).format('DD. MM. YYYY HH:mm')} />
			</div>;
		})}
	</div>;
}

class BusinessPremises extends Component {
	constructor(props) {
		super(props);
		
		this.updateTableItemsFromProps                           = this.updateTableItemsFromProps                          .bind(this);
		this.setTableItemsSort                                   = this.setTableItemsSort                                  .bind(this);
		this.refreshTableItemsSort                               = this.refreshTableItemsSort                              .bind(this);
		this.filterTableItems                                    = this.filterTableItems                                   .bind(this);
		this.handleFilterChange                                  = this.handleFilterChange                                 .bind(this);
		this.openAddEditItemDialog                               = this.openAddEditItemDialog                              .bind(this);
		this.closeAddEditItemDialog                              = this.closeAddEditItemDialog                             .bind(this);
		this.deleteItem                                          = this.deleteItem                                         .bind(this);
		this.openBusinessPremiseFiscalRegistrationHistoryDialog  = this.openBusinessPremiseFiscalRegistrationHistoryDialog .bind(this);
		this.closeBusinessPremiseFiscalRegistrationHistoryDialog = this.closeBusinessPremiseFiscalRegistrationHistoryDialog.bind(this);
		
		const {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
		} = this.updateTableItemsFromProps(props);
		
		this.state = {
			table_items,
			table_items_sort_ids,
			table_items_filtered_ids,
			table_items_sort_field: 'ord',
			table_items_sort_direction: 'ASC',
			table_items_filters: {},
			add_edit_item_dialog_open: false,
			add_edit_item_dialog_item: null,
			item_delete_requested: null,
			business_premise_fiscal_registration_history_dialog_open:       false,
			business_premise_fiscal_registration_history_dialog_id_business_premise: null,
		};
		
		this.state.table_items_sort_ids = this.refreshTableItemsSort(
			this.state.table_items_sort_field,
			this.state.table_items_sort_direction,
			this.state.table_items_filtered_ids,
			table_items,
			true
		);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.business_premises != this.state.table_items) {
			this.setState(
				this.updateTableItemsFromProps(this.props)
			);
		}
	}
	
	updateTableItemsFromProps(props) {
		const ids = Object.keys(props.business_premises);
		
		let sort_ids = [];
		
		if (this.state !== undefined && this.state.table_items_sort_ids !== undefined) {
			for (let i=0; i<this.state.table_items_sort_ids.length; i++) {
				const id = this.state.table_items_sort_ids[i];
				if (ids.indexOf(id) != -1) {
					sort_ids.push(id);
				}
			}
		}
		
		for (let i=0; i<ids.length; i++) {
			const id = ids[i];
			if (sort_ids.indexOf(id) == -1) {
				sort_ids.push(id);
			}
		}
		
		const table_items_sort_ids = this.state === undefined ? sort_ids :
			this.refreshTableItemsSort(
				this.state.table_items_sort_field,
				this.state.table_items_sort_direction,
				ids,
				props.business_premises,
				true
			);
		
		return {
			table_items: props.business_premises,
			table_items_sort_ids,
			table_items_filtered_ids: ids,
		};
	}
	
	setTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.table_items_sort_field == field) {
			direction = (this.state.table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		this.setState({
			table_items_sort_field:     field,
			table_items_sort_direction: direction,
		});
		
		this.refreshTableItemsSort(field, direction);
	}
	
	refreshTableItemsSort(field, direction, table_items_filtered_ids, items, only_return) {
		table_items_filtered_ids = table_items_filtered_ids || this.state.table_items_filtered_ids;
		items = items || this.state.table_items;
		only_return = only_return || false;
		
		const ids = table_items_filtered_ids;
		ids.sort((a_key, b_key) => {
			const a = items[a_key][field];
			const b = items[b_key][field];
			
			if (field == 'street' || field == 'town' || field == 'city') {
				return a.localeCompare(b) * (direction == 'ASC' ? 1 : -1);
			}
			
			return (a < b ? -1 : a > b ? 1 : 0) * (direction == 'ASC' ? 1 : -1);
		});
		
		if (only_return) {
			return ids;
		}
		
		this.setState({
			table_items_sort_ids: ids,
		});
	}
	
	filterTableItems(filters) {
		const ids = Object.keys(this.state.table_items);
		const filtered_ids = ids.filter(id => {
			return true;
		});
		
		this.setState({
			table_items_filtered_ids: filtered_ids,
			table_items_filters:      filters,
		});
		
		this.refreshTableItemsSort(
			this.state.table_items_sort_field,
			this.state.table_items_sort_direction,
			filtered_ids
		);
	}
	
	handleFilterChange(field_name, event) {
		const new_filters = {};
		
		let val = event;
		if (val instanceof Object) {
			val = event.target.value;
		}
		new_filters[field_name] = val;
		
		this.filterTableItems(new_filters);
	}
	
	openAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: true, add_edit_item_dialog_item: item });
	}
	closeAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: false });
		
		if (item !== undefined) {
			this.props.dispatch(addBusinessPremise({ item, token: this.props.token, save_to_api: true }));
		}
	}
	deleteItem(item) {
		this.setState({ item_delete_requested: item, token: this.props.token });
	}
	
	openBusinessPremiseFiscalRegistrationHistoryDialog(id_business_premise) {
		this.setState({
			business_premise_fiscal_registration_history_dialog_open:                true,
			business_premise_fiscal_registration_history_dialog_id_business_premise: id_business_premise,
		});
	}
	closeBusinessPremiseFiscalRegistrationHistoryDialog() {
		this.setState({
			business_premise_fiscal_registration_history_dialog_open:                false,
			business_premise_fiscal_registration_history_dialog_id_business_premise: null,
		});
	}
	
	render() {
		return <>
			{this.state.item_delete_requested === null ? null :
				<Alert
					cancelButtonText='Prekliči'
					confirmButtonText='Briši'
					canEscapeKeyCancel={true}
					canOutsideClickCancel={true}
					icon='trash'
					intent={Intent.DANGER}
					isOpen={true}
					onConfirm={() => {
						this.props.dispatch(deleteBusinessPremise(this.state.item_delete_requested.id_business_premise));
						this.setState({ item_delete_requested: null });
					}}
					onCancel={() => {
						this.setState({ item_delete_requested: null });
					}}>
					Ali res želite izbrisati ta zapis?
				</Alert>
			}
			
			{!this.state.add_edit_item_dialog_open ? null :
				<AddEditBusinessPremise
					closeAddEditItemDialog={this.closeAddEditItemDialog}
					item={this.state.add_edit_item_dialog_item} />
			}
			
			{!this.state.business_premise_fiscal_registration_history_dialog_open ? null :
				<BusinessPremiseFiscalRegistrationHistoryDialog
					closeBusinessPremiseFiscalRegistrationHistoryDialog={this.closeBusinessPremiseFiscalRegistrationHistoryDialog}
					id_business_premise={this.state.business_premise_fiscal_registration_history_dialog_id_business_premise} />
			}
			
			<div className='flex flex-col flex-grow business-premises-list'>
				<div className='pl-4 pt-4'>
					<Button intent='primary' icon='plus' onClick={() => this.openAddEditItemDialog(null)}>
						Nov vnos
					</Button>
				</div>
				
				<div className='flex-1 pl-4 pr-4'>
					<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
						<Results
							table_items={this.state.table_items}
							table_items_sort_ids={this.state.table_items_sort_ids}
							table_items_sort_field={this.state.table_items_sort_field}
							table_items_sort_direction={this.state.table_items_sort_direction}
							setTableItemsSort={this.setTableItemsSort}
							openAddEditItemDialog={this.openAddEditItemDialog}
							deleteItem={this.deleteItem}
							business_premise_fiscal_registrations={this.props.business_premise_fiscal_registrations}
							business_premise_fiscal_registrations_by_id_business_premises={this.props.business_premise_fiscal_registrations_by_id_business_premises}
							openBusinessPremiseFiscalRegistrationHistoryDialog={this.openBusinessPremiseFiscalRegistrationHistoryDialog} />
					</div>
				</div>
			</div>
		</>;
	}
}
BusinessPremises.propTypes = {
};

function mapStateToProps(state) {
	return {
		business_premises:                                             state.CodeTablesSlice.business_premises,
		business_premise_fiscal_registrations:                         state.CodeTablesSlice.business_premise_fiscal_registrations,
		business_premise_fiscal_registrations_by_id_business_premises: state.CodeTablesSlice.business_premise_fiscal_registrations_by_id_business_premises,
		token:                                                         state.UserSlice.token,
	};
}

export default connect(mapStateToProps)(BusinessPremises);
