import { createSlice } from '@reduxjs/toolkit'
import uuid from 'uuid';

function addItem(state_key_name, key_name, item, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	if (state.document_associations_id_documents === null) state.document_associations_id_documents = {};
	
	if (item[key_name] == -1) {
		item[key_name] = uuid.v4();
	}
	
	if (state_key_name == 'document_associations') {
		addDocumentToAssociations(state, item);
	}
	
	state[state_key_name][item[key_name]] = item;
	
	return item;
}
function addDocumentToAssociations(state, item) {
	if (state.document_associations_id_documents === null) state.document_associations_id_documents = {};
	
	if (state.document_associations_id_documents[item.source_id_document] === undefined) {
		state.document_associations_id_documents[item.source_id_document] = {};
	}
	state.document_associations_id_documents[item.source_id_document][item.id_document_association] = item;
	if (item.target_id_document != item.source_id_document) {
		if (state.document_associations_id_documents[item.target_id_document] === undefined) {
			state.document_associations_id_documents[item.target_id_document] = {};
		}
		state.document_associations_id_documents[item.target_id_document][item.id_document_association] = item;
	}
}
function addItems(state_key_name, key_name, items, state) {
	if (state[state_key_name] === null) state[state_key_name] = {};
	if (state.document_associations_id_documents === null) state.document_associations_id_documents = {};
	
	for (let i=0; i<items.length; i++) {
		if (state_key_name == 'document_associations') {
			addDocumentToAssociations(state, items[i]);
		}
		
		state[state_key_name][items[i][key_name]] = items[i];
	}
}
function deleteItemFromState(state_key_name, payload, state) {
	const key_value = payload;
	
	for (let id_document in state.document_associations_id_documents) {
		if (state.document_associations_id_documents[id_document][key_value] !== undefined) {
			delete state.document_associations_id_documents[id_document][key_value];
		}
	}
	
	delete state[state_key_name][key_value];
}

const DocumentSlice = createSlice({
	name: 'documents',
	initialState: {}, // defined in AppContainer.js
	reducers: {
		addDocumentAssociation: (state, action) => {
			const item = addItem('document_associations', 'id_document_association', action.payload.item, state);
		},
		addDocumentAssociations: (state, action) => addItems('document_associations', 'id_document_association', action.payload, state),
		addDocumentTypes: (state, action) => addItems('document_types', 'type', action.payload, state),
		deleteDocumentAssociation: (state, action) => {
			console.log({ action, payload: action.payload });
			deleteItemFromState('document_associations', action.payload.id_document_association, state);
		},
		addDocumentType: (state, action) => {
			const item = addItem('document_types', 'type', action.payload.item, state);
		},
	}
});

export const {
	addDocumentAssociation,
	addDocumentAssociations,
	addDocumentTypes,
	deleteDocumentAssociation,
	addDocumentType,
} = DocumentSlice.actions;
export default DocumentSlice.reducer;
