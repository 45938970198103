import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	Button,
	ButtonGroup,
	InputGroup,
	ControlGroup,
	Icon,
	NumericInput,
	FormGroup,
	Tab,
	Tabs,
	TextArea,
	Text,
	Checkbox,
	Navbar,
	Dialog,
	Alignment,
	HTMLSelect,
	Intent,
	Alert,
	Tooltip,
	ProgressBar,
} from '@blueprintjs/core';
import { IDateFormatProps, DateRangeInput3 } from '@blueprintjs/datetime2';

import moment from 'moment';
import uuid   from 'uuid';

import EditReservationCustomerDialog                                 from './EditReservationCustomerDialog';
import SelectCustomerDialog                                          from './SelectCustomerDialog';
import TextWithTooltip                                               from '../TextWithTooltip';
import DateTimePickerDialog                                          from '../DateTimePickerDialog';
import InvoiceDialog                                                 from './InvoiceDialog';
import CustomerSelect                                                from '../CustomerSelect';
import EditInvoiceDialog                                             from '../business/EditInvoiceDialog';
import WaitFiscalVerificationDialog                                  from '../business/WaitFiscalVerificationDialog';
import { addCustomer }                                               from '../../slices/CodeTablesSlice';
import { saveCodeTableItem }                                         from '../../api/CodeTables';
import { AppToaster }                                                from '../AppToaster';
import { addInvoice, addInvoices, addAdvanceInvoiceConsumptions }    from '../../slices/BusinessSlice';
import { saveInvoice, loadInvoices, loadAdvanceInvoiceConsumptions } from '../../api/Business';
import { addDocumentAssociation, addDocumentAssociations }           from '../../slices/DocumentSlice';
import { saveDocumentAssociation }                                   from '../../api/Documents';
import Documents                                                     from '../Documents';
import { sendReservationCustomersToETourism }                        from '../../api/Reservations';
import { loadGuestBookItems }                                        from '../../api/Guests';
import PriceHelper                                                   from '../../helpers/PriceHelper';
import InvoiceCreator                                                from '../../helpers/InvoiceCreator';
import DocumentsHelper                                               from '../../helpers/DocumentsHelper';
import ReservationHelper                                             from '../../helpers/ReservationHelper';
import DocumentListDialog                                            from '../DocumentListDialog';
import InvoiceHelper                                                 from '../../helpers/Invoice';
import AddEditItemWithCountDialog                                    from './AddEditItemWithCountDialog';
import { toLocal }                                                   from '../../numbers';
import PeopleCountInput                                              from './PeopleCountInput';

import {
	addReservation,
	batchAddReservationCustomers,
	removeReservationCustomer,
	addReservationCustomerItems,
	removeReservationCustomerItem,
} from '../../slices/ReservationSlice';
import { batchAddGuestBookItems } from '../../slices/GuestSlice';
import {
	saveReservation,
	batchSaveReservationCustomer,
	deleteReservationCustomer,
	saveReservationCustomerItems,
	deleteReservationCustomerItem,
} from '../../api/Reservations';

import { enqueueInvoiceOnA4PrinterQueue, enqueueInvoiceOnPosPrinterQueue } from '../../slices/AppSlice';

import { loadDateFnsLocale } from '../../utils';

function ReservationGuests(props) {
	const {
		main_table_items,
		main_table_items_sort_ids,
		main_table_items_sort_field,
		main_table_items_sort_direction,
		main_table_items_checked,
		customers,
		countries,
		guest_book_items,
		guest_book_items_state,
		openDateTimePickerDialog,
		openInvoiceDialog,
		onCheckedItem,
		setMainTableItemsSort,
		editItem,
		deleteItem,
		documents,
		invoices,
		openEditInvoiceDialog,
		openDocumentListDialog,
		getAge,
		display_copy_guest_to_reservation_customer,
		copyGuestToReservationCustomer,
	} = props;
	
	return <div className='reservation-guests-table mt-4'>
		<div className='reservation-guests-table-header-col col-1' style={{ gridColumn: '1' }}>
			&nbsp;
		</div>
		<div className='reservation-guests-table-header-col col-2' style={{ gridColumn: '2' }}>
			Urejanje
		</div>
		<div
			className='reservation-guests-table-header-col col-3'
			style={{ gridColumn: '3' }}
			onClick={() => { setMainTableItemsSort('surname'); }}>
			Priimek
			{main_table_items_sort_field != 'surname' ? null :
				<Icon icon={'caret-' + (main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
			}
		</div>
		<div
			className='reservation-guests-table-header-col col-4'
			style={{ gridColumn: '4' }}
			onClick={() => { setMainTableItemsSort('name'); }}>
			Ime
			{main_table_items_sort_field != 'name' ? null :
				<Icon icon={'caret-' + (main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
			}
		</div>
		<div className='reservation-guests-table-header-col col-5' style={{ gridColumn: '5' }}>
			Datum rojstva
		</div>
		<div className='reservation-guests-table-header-col col-6' style={{ gridColumn: '6' }}>
			Država
		</div>
		<div
			className='reservation-guests-table-header-col col-7'
			style={{ gridColumn: '7' }}
			onClick={() => { setMainTableItemsSort('check_in'); }}>
			Prijava
			{main_table_items_sort_field != 'check_in' ? null :
				<Icon icon={'caret-' + (main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
			}
		</div>
		<div
			className='reservation-guests-table-header-col col-8'
			style={{ gridColumn: '8' }}
			onClick={() => { setMainTableItemsSort('check_out'); }}>
			Odjava
			{main_table_items_sort_field != 'check_out' ? null :
				<Icon icon={'caret-' + (main_table_items_sort_direction == 'ASC' ? 'up' : 'down')} color='#888888' />
			}
		</div>
		<div className='reservation-guests-table-header-col col-9' style={{ gridColumn: '9' }}>
			Številka računa
		</div>
		{main_table_items_sort_ids.map((id, idx) => {
			const item = main_table_items[id];
			if (item === undefined) return null;
			
			const birth_date = customers[item.id_customer] === undefined ? new Date() : new Date(customers[item.id_customer].birth_date);
			
			let etourism_status_color = '#cccccc';
			let etourism_status_title = 'NEPOSLAN';
			
			const guest_book_item = item.id_guest_book === null || item.id_guest_book === undefined ? null :
				(guest_book_items_state[item.id_guest_book] || guest_book_items[item.id_guest_book] || null);
			if (guest_book_item !== undefined && guest_book_item !== null) {
				if (guest_book_item.zst !== null) {
					switch (guest_book_item.etourism_status) {
						case 'waiting':
							etourism_status_color = 'orange';
							etourism_status_title  = 'ČAKA NA POŠILJANJE';
							break;
						case 'success':
							etourism_status_color = 'green';
							etourism_status_title  = 'POSLANO USPEŠNO';
							break;
						case 'failure':
							etourism_status_color = 'red';
							etourism_status_title  = 'NAPAKA PRI POŠILJANJU';
							break;
					}
				}
			}
			
			const etourism_tooltip_content = <div>
				<div>Stanje: {etourism_status_title}</div>
				{guest_book_item === null || guest_book_item.log === null || guest_book_item.log.length == 0 ? null :
					<>
						<div className='mt-6 mb-1'>Dnevnik dogodkov:</div>
						{[...guest_book_item.log]
							.sort((log_item_a, log_item_b) => log_item_a.created.localeCompare(log_item_b.created) * -1)
							.map(guest_book_log => {
								let status_title = 'neznano';
								switch (guest_book_log.status) {
									case 'success':
										status_title  = 'POSLANO USPEŠNO';
										break;
									case 'failure':
										status_title  = 'NAPAKA PRI POŠILJANJU';
										break;
								}
								
								return <div key={'guest-book-log-' + guest_book_log.id_guest_book_log} style={{ maxWidth: 480, marginBottom: '1rem' }}>
									<div className='flex flex-row justify-between mb-1'>
										<div className='mr-12'>{status_title}</div>
										<div>{new Date(guest_book_log.created).toLocaleString('sl-SI')}</div>
									</div>
									<div>
										{
											JSON.parse(guest_book_log.message)
												.map(message_item => message_item.error_message)
												.join(', ')
										}
									</div>
								</div>;
							})
						}
					</>
				}
			</div>;
			
			const reservation_customer_documents = Object.values(documents[item.id_reservation_customer] || {})
				.filter(document => document.document_type == 'invoice' || document.document_type == 'cash-invoice');
			
			return <React.Fragment key={'reservation-guests-' + item.id_reservation_customer}>
				<div className='reservation-guests-table-col col-1' style={{ gridColumn: '1', gridRow: idx+2, }}>
					<Checkbox
						checked={ main_table_items_checked.indexOf(item.id_reservation_customer) != -1 }
						onChange={event => {
							onCheckedItem(item.id_reservation_customer, event.target.checked);
						}} />
				</div>
				<div className='reservation-guests-table-col col-2 flex flex-row items-center' style={{ gridColumn: '2', gridRow: idx+2, }}>
					<Button icon='edit' intent='primary' minimal={true} small={true} onClick={() => {
						editItem(item.id_reservation_customer);
					}} />
					<Button icon='trash' intent='danger' minimal={true} small={true} onClick={() => {
						deleteItem(item.id_reservation_customer);
					}} />
					<Tooltip content={etourism_tooltip_content}>
						<Icon
							icon={item.checked_out === false ? 'upload' : 'tick'}
							className='ml-1'
							color={etourism_status_color} />
					</Tooltip>
					{!display_copy_guest_to_reservation_customer ? null :
						<Tooltip content={'Prepiši v nosilca'}>
							<Button icon='follower' minimal={true} small={true} onClick={() => {
								copyGuestToReservationCustomer(item.id_customer);
							}} />
						</Tooltip>
					}
				</div>
				<div className='reservation-guests-table-col col-3' style={{ gridColumn: '3', gridRow: idx+2, }}>
					{ customers[item.id_customer] === undefined ? '' : customers[item.id_customer].surname }
				</div>
				<div className='reservation-guests-table-col col-4' style={{ gridColumn: '4', gridRow: idx+2, }}>
					{ customers[item.id_customer] === undefined ? '' : customers[item.id_customer].name }
				</div>
				<div className='reservation-guests-table-col col-5' style={{ gridColumn: '5', gridRow: idx+2, }}>
					<span className='mr-2'>
						{ moment(birth_date).format('DD.MM.YYYY') }
					</span>
					<span>{ '(' + getAge(birth_date) + ' let)' }</span>
				</div>
				<div className='reservation-guests-table-col col-6' style={{ gridColumn: '6', gridRow: idx+2, }}>
					{ customers[item.id_customer] === undefined ? '' : countries[customers[item.id_customer].id_country].official_name_local }
				</div>
				<div className='reservation-guests-table-col col-7' style={{ gridColumn: '7', gridRow: idx+2, }}>
					<Button
						small={true}
						className={'cell-button'}
						onClick={() => { openDateTimePickerDialog([ id, 'check_in' ]) }}>
						{
							item.check_in === null ? '' : new Date(item.check_in).toLocaleString(
								'sl-SI',
								{ year: 'numeric', month: 'numeric', day: 'numeric' }
							)
						}
						<span className='mr-2'></span>
						{
							item.check_in === null ? '' : new Date(item.check_in).toLocaleString(
								'sl-SI',
								{ hour: 'numeric', minute: 'numeric' }
							)
						}
					</Button>
				</div>
				<div className='reservation-guests-table-col col-8' style={{ gridColumn: '8', gridRow: idx+2, }}>
					<Button
						small={true}
						className={'cell-button'}
						onClick={() => { openDateTimePickerDialog([ id, 'check_out' ]) }}>
						{
							item.check_out === null ? '' : new Date(item.check_out).toLocaleString(
								'sl-SI',
								{ year: 'numeric', month: 'numeric', day: 'numeric' }
							)
						}
						<span className='mr-2'></span>
						{
							item.check_out === null ? '' : new Date(item.check_out).toLocaleString(
								'sl-SI',
								{ hour: 'numeric', minute: 'numeric' }
							)
						}
					</Button>
				</div>
				<div className='reservation-guests-table-col col-9' style={{ gridColumn: '9', gridRow: idx+2, }}>
					<div className='flex flex-row'>
						<div>
						{item.invoice_paid ?
							<>
								{item.invoice_paid_on.toLocaleString(
									'sl-SI',
									{ year: 'numeric', month: 'numeric', day: 'numeric' }
								)}
								<span className='ml-2 font-bold'>#12345</span>
							</>
							:
							<Button
								icon='euro'
								intent='success'
								minimal={true}
								small={true}
								onClick={() => openInvoiceDialog([ item.id_reservation_customer ])} />
						}
						</div>
						<div>
							<div>
								{reservation_customer_documents.length == 0 ? null :
									<Button
										small={true}
										className='cell-button'
										onClick={() => {
											openEditInvoiceDialog(
												invoices[reservation_customer_documents[0].id_document].invoice_type,
												invoices[reservation_customer_documents[0].id_document],
												[],
												[]
											);
										}}>
										{invoices[reservation_customer_documents[0].id_document].invoice_number_internal}
									</Button>
								}
								<Button
									small={true}
									className='cell-button ml-1'
									onClick={() => openDocumentListDialog(item.id_reservation_customer, 'reservation-customer')}
									icon='multi-select' />
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>;
		})}
	</div>;
}

class Reservation extends Component {
	constructor(props) {
		super(props);
		
		this._ismounted      = false;
		this.loading         = 0;
		this.phone_input_ref = React.createRef();
		
		this.openEditReservationCustomerDialog    = this.openEditReservationCustomerDialog   .bind(this);
		this.closeEditReservationCustomerDialog   = this.closeEditReservationCustomerDialog  .bind(this);
		this.openSelectCustomerDialog             = this.openSelectCustomerDialog            .bind(this);
		this.closeSelectCustomerDialog            = this.closeSelectCustomerDialog           .bind(this);
		this.openInvoiceDialog                    = this.openInvoiceDialog                   .bind(this);
		this.closeInvoiceDialog                   = this.closeInvoiceDialog                  .bind(this);
		this.openDateTimePickerDialog             = this.openDateTimePickerDialog            .bind(this);
		this.closeDateTimePickerDialog            = this.closeDateTimePickerDialog           .bind(this);
		this.getDateFromStateByName               = this.getDateFromStateByName              .bind(this);
		this.setMainTableItemChecked              = this.setMainTableItemChecked             .bind(this);
		this.setMainTableItemsChecked             = this.setMainTableItemsChecked            .bind(this);
		this.setMainTableItemsSort                = this.setMainTableItemsSort               .bind(this);
		this.openEditInvoiceDialog                = this.openEditInvoiceDialog               .bind(this);
		this.closeEditInvoiceDialog               = this.closeEditInvoiceDialog              .bind(this);
		this.deleteMainTableItem                  = this.deleteMainTableItem                 .bind(this);
		this.calculateReservationPrice            = this.calculateReservationPrice           .bind(this);
		this.validate                             = this.validate                            .bind(this);
		this.addAndSaveDocumentAssociation        = this.addAndSaveDocumentAssociation       .bind(this);
		this.openOfferDialog                      = this.openOfferDialog                     .bind(this);
		this.increaseLoading                      = this.increaseLoading                     .bind(this);
		this.decreaseLoading                      = this.decreaseLoading                     .bind(this);
		this.saveReservation                      = this.saveReservation                     .bind(this);
		this.openDocumentListDialog               = this.openDocumentListDialog              .bind(this);
		this.closeDocumentListDialog              = this.closeDocumentListDialog             .bind(this);
		this.copyGuestToReservationCustomer       = this.copyGuestToReservationCustomer      .bind(this);
		
		this.startDateRef    = React.createRef();
		this.addPersonRef    = React.createRef();
		
		let reservation_customers = {};
		if (this.props.reservation !== null) {
			reservation_customers =
				this.props.reservation_customers_by_id_reservations[this.props.reservation.id_reservation] || {};
		}
		
		const reservation_items = {};
		if (this.props.reservation !== null) {
			for (let key in this.props.accommodation_item_place_items) {
				if (
					this.props.accommodation_item_place_items[key].id_accommodation_item_place ==
						this.props.reservation.id_accommodation_item_place
				) {
					reservation_items[key] = this.props.accommodation_item_place_items[key];
				}
			}
		}
		
		const id_customer = this.props.reservation === null ? null : this.props.reservation.id_customer;
		const customer    = id_customer === null || id_customer == -1 ? null : this.props.customers[id_customer];
		
		const open_id_reservation_customer =
			this.props.open_id_reservation_customer !== undefined && this.props.open_id_reservation_customer !== null ?
				this.props.open_id_reservation_customer : null;
		
		const reservation_customers_ids = Object.keys(reservation_customers);
		
		this.state = {
			reservation:                 this.props.reservation,
			check_in:                    this.props.reservation === null || this.props.reservation.check_in  === null ?
											null : new Date(this.props.reservation.check_in),
			check_out:                   this.props.reservation === null || this.props.reservation.check_out === null ?
											null : new Date(this.props.reservation.check_out),
			id_customer,
			phone:                       customer === null ? '' : customer.phone,
			email:                       customer === null ? '' : customer.email,
			note:                        this.props.reservation.note                        || '',
			id_channel:                  this.props.reservation.id_channel                  || -1,
			id_market_segmentation:      this.props.reservation.id_market_segmentation      || -1,
			id_accommodation_item_place: this.props.reservation.id_accommodation_item_place || -1,
			guest_adult_count:           this.props.reservation.guest_adult_count           ||  0,
			guest_child_1_count:         this.props.reservation.guest_child_1_count         ||  0,
			guest_child_2_count:         this.props.reservation.guest_child_2_count         ||  0,
			custom_status:               this.props.reservation.custom_status               || '',
			internal_code:               this.props.reservation.internal_code               || '',
			status:                      this.props.reservation.status                      || '',
			status_2:                    this.props.reservation.status_2                    || null,
			deleted:                     this.props.reservation.deleted,
			guests_checked_in:           this.props.reservation.guests_checked_in ?? false,
			main_table_items_changed: false,
			main_table_items: reservation_customers,
			main_table_items_sort_ids: Object.values(reservation_customers).sort((a, b) => a.ord - b.ord).map(item => item.id_reservation_customer),
			main_table_items_sort_field: '',
			main_table_items_sort_direction: 'ASC',
			main_table_items_checked: [],
			reservation_items: Object.values(reservation_items).map(reservation_item => {
				let selected = false;
				for (let key in this.props.reservation.items) {
					if (this.props.reservation.items[key].id_item == reservation_item.id_item) {
						selected = true;
						break;
					}
				}
				
				// preselect items on new reservations
				if (this.props.reservation.id_reservation == -1) {
					selected = reservation_item.mandatory || reservation_item.preselected;
				}
				
				return {
					item:                        this.props.items[reservation_item.id_item],
					age_from:                    reservation_item.age_from,
					age_until:                   reservation_item.age_until,
					calculation_type:            reservation_item.calculation_type,
					mandatory:                   reservation_item.mandatory,
					preselected:                 reservation_item.preselected,
					enable_short_term_surcharge: reservation_item.enable_short_term_surcharge,
					selected,
				};
			}),
			edit_reservation_customer_dialog_open: open_id_reservation_customer !== null,
			edit_reservation_customer_dialog_item: open_id_reservation_customer === null ? null : reservation_customers[open_id_reservation_customer],
			select_customer_dialog_open: false,
			select_customer_dialog_name: '',
			select_customer_dialog_surname: '',
			select_customer_dialog_email: '',
			select_customer_dialog_id_country: '',
			select_customer_dialog_phone: '',
			select_customer_dialog_post_office: '',
			select_customer_dialog_address: '',
			select_customer_dialog_item: null,
			invoice_dialog_open: false,
			invoice_dialog_preselect: null,
			invoice_dialog_calculated_state: null,
			invoice_dialog_advance_invoices: [],
			invoice_dialog_id_customer: null,
			invoice_dialog_id_reservation_customers: null,
			date_picker_dialog_open: false,
			date_picker_dialog_state_name: null,
			delete_requested: false,
			status_change_requested: false,
			status_change_requested_value: '',
			create_invoice_dialog_open: false,
			create_invoice_dialog_item: null,
			create_invoice_dialog_type: '',
			create_invoice_dialog_selected_advance_invoices_amounts: {},
			create_invoice_dialog_id_reservation_customers: [],
			copy_customer_to_guest: false,
			validation_failed_fields: [],
			close_dialog_save_item: false,
			close_dialog_confirm_open: false,
			additional_info_tab_id: 'comment',
			delete_confirm_dialog_open: false,
			delete_confirm_dialog_id_reservation_customer: null,
			guest_book_items: {},
			loading: 0,
			error_message: null,
			document_list_dialog_open:          false,
			document_list_dialog_id_document:   null,
			document_list_dialog_document_type: null,
			wait_fiscal_verification_dialog_open:       false,
			wait_fiscal_verification_dialog_id_invoice: null,
			add_edit_item_with_count_dialog_visible: false,
			add_edit_item_with_count_dialog_id_reservation_customer: null,
			add_edit_item_with_count_dialog_quantity: 0,
			add_edit_item_with_count_dialog_id_item: null,
			customer_items:
				Object.values(props.reservation_customer_items)
					// only select items for customers on this reservation
					.filter(
						x => reservation_customers_ids.indexOf(x.id_reservation_customer) != -1
					)
					// make copies to allow changing them
					.map(x => { return {...x}; }),
			customer_items_customers_selected: [],
			delete_request__id_reservation_customer: null,
			delete_request__id_item: null,
		};
		
		if (props.initial_customer_query !== null && props.initial_customer_query !== undefined) {
			this.state = {
				...this.state,
				...this.openSelectCustomerDialog(props.initial_customer_query, null, true),
			};
		}
	}
	
	getMomentFormatter(format) {
		// note that locale argument comes from locale prop and may be undefined
		return {
			formatDate: (date, locale) => moment(date).locale(locale).format(format),
		};
	}
	
	getMaxDate() {
		const date = new Date();
		date.setFullYear(date.getFullYear() + 10);
		return date;
	}
	
	openEditReservationCustomerDialog(item) {
		this.setState({ edit_reservation_customer_dialog_open: true, edit_reservation_customer_dialog_item: item });
	}
	openSelectCustomerDialog(query, item, only_return) {
		item = item || null;
		
		const new_state = {
			select_customer_dialog_open:        true,
			select_customer_dialog_name:        query.name,
			select_customer_dialog_surname:     query.surname,
			select_customer_dialog_email:       query.email,
			select_customer_dialog_id_country:  query.id_country,
			select_customer_dialog_phone:       query.phone,
			select_customer_dialog_post_office: query.post_office,
			select_customer_dialog_address:     query.address,
			select_customer_dialog_item:        item,
		};
		if (only_return) {
			return new_state;
		}
		
		this.setState(new_state);
	}
	openReservationDialog() {
		this.setState({ reservation_dialog_open: true });
	}
	openInvoiceDialog(id_reservation_customers, additional_items_id_reservation_customers) {
		const specific_customers = id_reservation_customers !== undefined;
		
		if (id_reservation_customers === undefined) {
			id_reservation_customers = Object.keys(this.state.main_table_items);
			if (id_reservation_customers.length == 0) {
				AppToaster.show({
					message: <div>Na rezervaciji mora biti vsaj ena oseba</div>,
					intent: 'danger',
					icon: 'issue'
				});
				return;
			}
		}
		
		if (additional_items_id_reservation_customers === undefined) {
			additional_items_id_reservation_customers = this.state.customer_items.map(item => item.id_reservation_customer);
		}
		
		if (id_reservation_customers.length == 0) {
			AppToaster.show({
				message: <div>Izbrana mora biti vsaj ena oseba</div>,
				intent: 'danger',
				icon: 'issue'
			});
			return;
		}
		
		const invoice_dialog_id_customer = specific_customers && id_reservation_customers.length == 1 ?
			this.state.main_table_items[id_reservation_customers[0]].id_customer
			:
			this.state.id_customer;
		
		const advance_invoices = {};
		if (this.state.reservation !== null && this.state.reservation !== undefined) {
			const documents = Object.values(DocumentsHelper.GetDocuments(
				this.props.document_associations,
				this.state.reservation.id_reservation,
				1
			));
			for (let i=0; i<documents.length; i++) {
				if (documents[i].document_type == 'prepayment-invoice') {
					const invoice = this.props.invoices[documents[i].id_document];
					if (invoice !== undefined && parseFloat(invoice.used_advance_payment_amount) < parseFloat(invoice.advance_payment_amount)) {
						if (advance_invoices[invoice.id_invoice] === undefined) {
							advance_invoices[invoice.id_invoice] = invoice;
						}
					}
				}
				else if (documents[i].document_type == 'offer') {
					const subdocuments = Object.values(DocumentsHelper.GetDocuments(
						this.props.document_associations,
						documents[i].id_document,
						1
					));
					for (let i=0; i<subdocuments.length; i++) {
						if (subdocuments[i].document_type == 'prepayment-invoice') {
							const invoice = this.props.invoices[subdocuments[i].id_document];
							if (invoice !== undefined && parseFloat(invoice.used_advance_payment_amount) < parseFloat(invoice.advance_payment_amount)) {
								if (advance_invoices[invoice.id_invoice] === undefined) {
									advance_invoices[invoice.id_invoice] = invoice;
								}
							}
						}
					}
				}
			}
		}
		
		this.setState({
			invoice_dialog_open:             true,
			invoice_dialog_preselect:        true,
			invoice_dialog_calculated_state: this.calculateReservationPrice(id_reservation_customers, additional_items_id_reservation_customers),
			invoice_dialog_advance_invoices: Object.values(advance_invoices),
			invoice_dialog_id_customer,
			invoice_dialog_id_reservation_customers: id_reservation_customers,
		});
	}
	openDateTimePickerDialog(state_name) {
		this.setState({ date_picker_dialog_open: true, date_picker_dialog_state_name: state_name });
	}
	async closeEditReservationCustomerDialog(item, reopen) {
		this.setState({ edit_reservation_customer_dialog_open: false, edit_reservation_customer_dialog_item: null });
		
		if (reopen) {
			setTimeout(() => {
				if (!this._ismounted) return;
				
				this.openEditReservationCustomerDialog(null);
			}, 1000);
		}
		
		if (item !== undefined) {
			let id_customer = item.id_customer;
			let new_customer;
			let changed_for_etourism = false;
			
			if (id_customer == -1) {
				id_customer = uuid.v4();
				
				new_customer = {
					id_customer:       id_customer,
					type:              'natural',
					name:              item.name,
					surname:           item.surname,
					company_name:      '',
					company_long_name: '',
					address:           '',
					id_country:        item.id_country,
					post_code:         '',
					post_office:       '',
					tax_number:        '',
					vat_registered:    false,
					vat_prefix:        '',
					phone:             '',
					email:             '',
					sex:               item.sex,
					birth_date:        item.birthdate === null ? null : this.toIsoString(item.birthdate),
					supplier:          false,
					due_day_count:     0,
					display_company_long_name_on_invoices: false,
					identification_number:  item.identification_number,
					id_identification_type: item.id_identification_type,
				};
			}
			else {
				const existing_customer = this.props.customers[id_customer];
				new_customer = {...existing_customer};
				
				new_customer.name       = item.name;
				new_customer.surname    = item.surname;
				new_customer.id_country = item.id_country;
				new_customer.sex        = item.sex;
				new_customer.birth_date = item.birthdate === null ? null : this.toIsoString(item.birthdate);
				new_customer.identification_number  = item.identification_number;
				new_customer.id_identification_type = item.id_identification_type;
				
				changed_for_etourism =
					existing_customer.name                   != new_customer.name                  ||
					existing_customer.surname                != new_customer.surname               ||
					existing_customer.id_country             != new_customer.id_country            ||
					existing_customer.sex                    != new_customer.sex                   ||
					existing_customer.birth_date             != new_customer.birth_date            ||
					existing_customer.identification_number  != new_customer.identification_number ||
					existing_customer.id_identification_type != new_customer.id_identification_type
				;
			}
			
			this.props.dispatch(addCustomer({ item: new_customer, token: this.props.token }));
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			this.increaseLoading();
			const new_item = await saveCodeTableItem(this.props.api_url, 'customers', new_customer, token);
			if (new_item !== null) {
				this.decreaseLoading();
				
				dispatch(addCustomer({
					item: new_item,
					token,
				}));
			}
			
			const main_table_items          = {...this.state.main_table_items};
			const main_table_items_sort_ids = this.state.main_table_items_sort_ids;
			
			let guest_book_items = this.state.guest_book_items;
			
			if (item.id_reservation_customer != -1) {
				const existing_reservation_customer = this.state.main_table_items[item.id_reservation_customer];
				const reservation_customer          = {...existing_reservation_customer};
				
				reservation_customer.check_in                    = this.toIsoStringWithTime(item.check_in);
				reservation_customer.check_out                   = this.toIsoStringWithTime(item.check_out);
				reservation_customer.id_customer                 = id_customer;
				reservation_customer.identification_number       = item.identification_number;
				reservation_customer.id_group                    = item.id_group;
				reservation_customer.pay_tourist_tax             = item.tourist_tax_checked;
				reservation_customer.add_to_guest_book           = item.e_tourist_checked;
				reservation_customer.agree_to_further_processing = item.agree_further_processing;
				reservation_customer.id_tourist_tax              = item.id_tourist_tax;
				reservation_customer.id_identification_type      = item.id_identification_type;
				
				main_table_items[item.id_reservation_customer] = reservation_customer;
				
				if (reservation_customer.id_guest_book !== null) {
					changed_for_etourism =
						changed_for_etourism ||
						reservation_customer.check_in                    != existing_reservation_customer.check_in              ||
						reservation_customer.check_out                   != existing_reservation_customer.check_out             ||
						reservation_customer.identification_number       != existing_reservation_customer.identification_number ||
						reservation_customer.id_tourist_tax              != existing_reservation_customer.id_tourist_tax        ||
						reservation_customer.id_identification_type      != existing_reservation_customer.id_identification_type
					;
					
					if (changed_for_etourism) {
						guest_book_items = {...this.state.guest_book_items};
						const guest_book_item = {...
							guest_book_items[reservation_customer.id_guest_book] ||
							this.props.guest_book_items[reservation_customer.id_guest_book]
						};
						guest_book_item.etourism_status = 'waiting';
						guest_book_items[reservation_customer.id_guest_book] = guest_book_item;
					}
				}
			}
			else {
				let max_ord = 0;
				for (let id_reservation_customer in main_table_items) {
					max_ord = Math.max(max_ord, main_table_items[id_reservation_customer].ord);
				}
				
				const reservation_customer = {
					id_reservation_customer:     uuid.v4(),
					id_reservation:              -1,
					id_customer:                 id_customer,
					check_in:                    this.toIsoStringWithTime(item.check_in),
					check_out:                   this.toIsoStringWithTime(item.check_out),
					invoice_paid:                false,
					invoice_paid_on:             null,
					identification_number:       item.identification_number,
					id_group:                    item.id_group,
					pay_tourist_tax:             item.tourist_tax_checked,
					add_to_guest_book:           item.e_tourist_checked,
					agree_to_further_processing: item.agree_further_processing,
					id_tourist_tax:              item.id_tourist_tax,
					id_identification_type:      item.id_identification_type,
					checked_out:                 false,
					ord:                         max_ord + 1,
				};
				
				main_table_items[reservation_customer.id_reservation_customer] = reservation_customer;
				main_table_items_sort_ids.push(reservation_customer.id_reservation_customer);
			}
			
			this.setState({
				main_table_items_changed: true,
				main_table_items,
				main_table_items_sort_ids,
				guest_book_items,
			});
		}
	}
	closeSelectCustomerDialog(id_customer, new_customer) {
		const state = {
			select_customer_dialog_open: false,
		};
		if (id_customer !== undefined) {
			state.id_customer = id_customer;
			
			state.phone = new_customer.phone;
			state.email = new_customer.email;
		}
		
		this.setState(state);
	}
	closeReservationDialog() {
		this.setState({ reservation_dialog_open: false });
	}
	openOfferDialog() {
		const accommodation_item_place = this.props.accommodation_item_places[this.state.reservation.id_accommodation_item_place];
		const accommodation_item       = this.props.accommodation_items[accommodation_item_place.id_accommodation_item];
		
		let reservation_title = '';
		if (accommodation_item.title != 'default') {
			reservation_title += accommodation_item.title + ' → ';
		}
		reservation_title += accommodation_item_place.title;
		
		const calculated_state = this.calculateReservationPrice(undefined, undefined, false);
		const items            = calculated_state.items;
		
		const new_item = InvoiceCreator.CreateInvoiceFromInitialData({
			id_invoice:         uuid.v4(),
			reverse_invoice:    false,
			id_customer:        this.state.id_customer,
			invoice_date:       moment().toISOString(true),
			invoice_type:       'offer',
			reservation:        reservation_title,
			service_date_end:   this.state.check_out === null ? null : moment(this.state.check_out).toISOString(true),
			service_date_start: this.state.check_in === null ? null : moment(this.state.check_in).toISOString(true),
			items: items.map((item_item, idx) => {
				const item            = this.props.items[item_item.id_item];
				const measuring_unit  = this.props.measuring_units[item.id_measuring_unit];
				const item_price_list = item.price_lists[item.id_item_price_list];
				const tax_rate        = item_price_list === undefined ? null : this.props.tax_rates[item_price_list.id_tax_rate];
				
				return InvoiceCreator.CreateInvoiceItemFromInitialData({
					id_invoice_item:                         uuid.v4(),
					description:                             item_item.id_tourist_tax === undefined ? item.description : this.props.tourist_taxes[item_item.id_tourist_tax].title,
					id_item:                                 item_item.id_item,
					id_item_price_list:                      item_item.id_item_price_list,
					id_tax_rate:                             item_price_list === undefined ? null : item_price_list.id_tax_rate,
					item_measuring_unit_code:                measuring_unit.title,
					item_measuring_unit_decimal_digit_count: measuring_unit.decimal_digit_count,
					price:                                   item_item.single_price,
					quantity:                                item_item.quantity,
					tax_rate,
					item_type:                               item.type,
					item_ean:                                item.ean,
					item_title:                              item.title,
					ord:                                     idx,
					id_warehouse: item.item_type != 'item' ? null : InvoiceHelper.findCurrentIdWarehouse(
						this.props.current_id_cash_register === undefined || this.props.current_id_cash_register === null ? null :
							this.props.cash_registers[this.props.current_id_cash_register],
						this.props.warehouses
					),
				});
			}),
		});
		
		this.openEditInvoiceDialog('offer', new_item, {}, []);
	}
	closeInvoiceDialog(type, items, id_customer, selected_advance_invoices_amounts) {
		const id_reservation_customers =
			this.state.invoice_dialog_id_reservation_customers === null ||
			this.state.invoice_dialog_id_reservation_customers === undefined ||
			this.state.invoice_dialog_id_reservation_customers.length === 0 ?
				Object.keys(this.state.main_table_items) :
				this.state.invoice_dialog_id_reservation_customers;
		
		this.setState({
			invoice_dialog_open:             false,
			invoice_dialog_preselect:        null,
			invoice_dialog_calculated_state: null,
			invoice_dialog_id_customer:      null,
			invoice_dialog_id_reservation_customers: null,
		});
		
		if (type !== undefined) {
			const accommodation_item_place = this.props.accommodation_item_places[this.state.reservation.id_accommodation_item_place];
			const accommodation_item       = this.props.accommodation_items[accommodation_item_place.id_accommodation_item];
			
			if (this.state.id_customer === null || this.state.id_customer == -1) return;
			
			let reservation_title = '';
			if (accommodation_item.title != 'default') {
				reservation_title += accommodation_item.title + ' → ';
			}
			reservation_title += accommodation_item_place.title;
			
			const advance_payment_amount = Object.values(selected_advance_invoices_amounts)
				.reduce((curr_value, item_val) => curr_value + item_val, 0);
			
			const new_item = InvoiceCreator.CreateInvoiceFromInitialData({
				id_invoice:         uuid.v4(),
				reverse_invoice:    false,
				id_customer:        id_customer,
				invoice_date:       moment().toISOString(true),
				invoice_type:       type,
				reservation:        reservation_title,
				service_date_end:   this.state.check_out === null ? null : moment(this.state.check_out).toISOString(true),
				service_date_start: this.state.check_in === null ? null : moment(this.state.check_in).toISOString(true),
				advance_payment_amount,
				items: items.map((item_item, idx) => {
					const item            = this.props.items[item_item.id_item];
					const measuring_unit  = this.props.measuring_units[item.id_measuring_unit];
					const item_price_list = item.price_lists[item.id_item_price_list];
					const tax_rate        = item_price_list === undefined ? null : this.props.tax_rates[item_price_list.id_tax_rate];
					
					return InvoiceCreator.CreateInvoiceItemFromInitialData({
						id_invoice_item:                         uuid.v4(),
						description:                             item_item.id_tourist_tax === undefined ? item.description : this.props.tourist_taxes[item_item.id_tourist_tax].title,
						id_item:                                 item_item.id_item,
						id_item_price_list:                      item_item.id_item_price_list,
						id_tax_rate:                             item_price_list === undefined ? null : item_price_list.id_tax_rate,
						item_measuring_unit_code:                measuring_unit.title,
						item_measuring_unit_decimal_digit_count: measuring_unit.decimal_digit_count,
						price:                                   item_item.single_price,
						quantity:                                item_item.quantity,
						tax_rate,
						item_type:                               item.type,
						item_ean:                                item.ean,
						item_title:                              item.title,
						ord:                                     idx,
						id_warehouse: item.item_type != 'item' ? null : InvoiceHelper.findCurrentIdWarehouse(
							this.props.current_id_cash_register === undefined || this.props.current_id_cash_register === null ? null :
								this.props.cash_registers[this.props.current_id_cash_register],
							this.props.warehouses
						),
					});
				}),
			});
			
			this.openEditInvoiceDialog(type, new_item, selected_advance_invoices_amounts, id_reservation_customers);
		}
	}
	closeDateTimePickerDialog(newValue) {
		this.setState({ date_picker_dialog_open: false });
		
		if (newValue instanceof Date) {
			const obj = {
				main_table_items_changed: true,
			};
			
			if (Array.isArray(this.state.date_picker_dialog_state_name)) {
				if (this.state.date_picker_dialog_state_name.length == 2) {
					const main_table_items        = {...this.state.main_table_items};
					const guest_book_items        = {...this.state.guest_book_items};
					const id_reservation_customer = this.state.date_picker_dialog_state_name[0];
					const property_name           = this.state.date_picker_dialog_state_name[1];
					
					const reservation_customer          = {...this.state.main_table_items[id_reservation_customer]};
					reservation_customer[property_name] = this.toIsoStringWithTime(newValue);
					
					main_table_items[reservation_customer.id_reservation_customer] = reservation_customer;
					
					if (reservation_customer.id_guest_book !== null) {
						const guest_book_item = {...
							guest_book_items[reservation_customer.id_guest_book] ||
							this.props.guest_book_items[reservation_customer.id_guest_book]
						};
						guest_book_item.etourism_status = 'waiting';
						guest_book_items[reservation_customer.id_guest_book] = guest_book_item;
					}
					
					obj.main_table_items = main_table_items;
					obj.guest_book_items = guest_book_items;
				}
			}
			else {
				obj[this.state.date_picker_dialog_state_name] = newValue;
			}
			
			this.setState(obj);
		}
	}
	getDateFromStateByName(name) {
		if (Array.isArray(name)) {
			if (name.length == 2) {
				return this.state.main_table_items[name[0]][name[1]] === null ?
					null : new Date(this.state.main_table_items[name[0]][name[1]]);
			}
		}
		else {
			return this.state[name];
		}
		
		return null;
	}
	
	setMainTableItemChecked(id, checked) {
		const main_table_items_checked = [...this.state.main_table_items_checked];
		
		if (checked) {
			main_table_items_checked.push(id);
		}
		else {
			main_table_items_checked.splice(
				main_table_items_checked.indexOf(id),
				1
			);
		}
		
		// select/unselect customer items/services
		let ids = [...this.state.customer_items_customers_selected];
		
		if (checked) {
			ids.push(id);
		}
		else {
			const idx = ids.indexOf(id);
			if (idx != -1) ids.splice(idx, 1);
		}
		
		this.setState({
			main_table_items_checked,
			customer_items_customers_selected: ids,
		});
	}
	setMainTableItemsChecked(checked) {
		const main_table_items_checked = checked ? [...Object.keys(this.state.main_table_items)] : [];
		
		this.setState({
			main_table_items_checked,
			customer_items_customers_selected: main_table_items_checked,
		});
	}
	
	setMainTableItemsSort(field) {
		let direction = 'ASC';
		if (this.state.main_table_items_sort_field == field) {
			direction = (this.state.main_table_items_sort_direction == 'ASC' ? 'DESC' : 'ASC');
		}
		
		const ids = this.state.main_table_items_sort_ids;
		ids.sort((a_key, b_key) => {
			const a = this.state.main_table_items[a_key][field];
			const b = this.state.main_table_items[b_key][field];
			
			return (a < b ? -1 : a > b ? 1 : 0) * (direction == 'ASC' ? 1 : -1);
		});
		
		this.setState({
			main_table_items_sort_ids:       ids,
			main_table_items_sort_field:     field,
			main_table_items_sort_direction: direction,
		});
	}
	
	componentDidMount() {
		this._ismounted = true;
		
		setTimeout(() => {
			if (!this._ismounted) return;
			
			if (false && // actually, it probably makes sense to never do this, since we already have check in and check out dates on new reservations as well?
				this.state.reservation.id_reservation < 0) { // existing reservation
				if (this.startDateRef.current !== null) {
					this.startDateRef.current.focus();
				}
			}
			else { // new reservation
				const id_customer_input = document.querySelector('.main-person-input > input');
				if (id_customer_input !== null) {
					id_customer_input.focus();
				}
				//if (this.addPersonRef.current !== null) {
				//	this.addPersonRef.current.focus();
				//}
			}
		}, 1000);
	}
	componentWillUnmount() {
		this._ismounted = false;
	}
	
	openEditInvoiceDialog(type, item, selected_advance_invoices_amounts, id_reservation_customers) {
		this.setState({
			create_invoice_dialog_open: true,
			create_invoice_dialog_type: type,
			create_invoice_dialog_item: item,
			create_invoice_dialog_selected_advance_invoices_amounts: selected_advance_invoices_amounts,
			create_invoice_dialog_id_reservation_customers: id_reservation_customers,
		});
	}
	async closeEditInvoiceDialog(item, open_create_invoice_type, finish_invoice) {
		this.setState({
			create_invoice_dialog_open: false,
			create_invoice_dialog_item: null,
		});
		
		if (item !== undefined) {
			this.setState({
				additional_info_tab_id: 'offers',
			});
			
			this.props.dispatch(addInvoice({
				item,
				token: this.props.token,
			}));
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			const self = this;
			self.increaseLoading();
			const new_items = await saveInvoice(this.props.api_url, item, token, dispatch);
			if (new_items !== null) {
				self.decreaseLoading();
				
				if (new_items.invoices !== undefined) {
					dispatch(addInvoices(new_items.invoices));
				}
				if (new_items.document_associations !== undefined) {
					dispatch(addDocumentAssociations(new_items.document_associations));
				}
				if (new_items.advance_invoice_consumptions !== undefined) {
					dispatch(addAdvanceInvoiceConsumptions(new_items.advance_invoice_consumptions));
				}
				
				for (let i=0; i<new_items.invoices.length; i++) {
					self.addAndSaveDocumentAssociation(
						new_items.invoices[i].id_invoice,
						new_items.invoices[i].invoice_type,
						self.state.reservation.id_reservation,
						'reservation'
					);
				}
				
				for (let i=0; i<new_items.invoices.length; i++) {
					for (let j=0; j<this.state.create_invoice_dialog_id_reservation_customers.length; j++) {
						self.addAndSaveDocumentAssociation(
							new_items.invoices[i].id_invoice,
							new_items.invoices[i].invoice_type,
							this.state.create_invoice_dialog_id_reservation_customers[j],
							'reservation-customer'
						);
					}
				}
				
				if (finish_invoice && this.props.document_types[item.invoice_type].fiscal_verification_enabled) {
					this.setState({
						wait_fiscal_verification_dialog_open:       true,
						wait_fiscal_verification_dialog_id_invoice: item.id_invoice,
					});
				}
			}
		}
	}
	
	async addAndSaveDocumentAssociation(
		source_id_document,
		source_type,
		target_id_document,
		target_type
	) {
		const item = {
			id_document_association: uuid.v4(),
			source_id_document,
			source_type,
			target_id_document,
			target_type
		};
		
		this.props.dispatch(addDocumentAssociation({
			item,
			token: this.props.token,
		}));
		
		const dispatch = this.props.dispatch;
		const token    = this.props.token;
		
		this.increaseLoading();
		const new_item = await saveDocumentAssociation(this.props.api_url, item, token);
		if (new_item !== null) {
			this.decreaseLoading();
			
			dispatch(addDocumentAssociation({
				item: new_item,
				token,
			}));
		}
	}
	
	deleteMainTableItem(id_reservation_customer, force) {
		if (force !== true) {
			this.setState({
				delete_confirm_dialog_open:                    true,
				delete_confirm_dialog_id_reservation_customer: id_reservation_customer,
			});
			return;
		}
		
		const main_table_items = {...this.state.main_table_items};
		delete main_table_items[id_reservation_customer];
		
		const main_table_items_sort_ids = this.state.main_table_items_sort_ids.filter(id => id != id_reservation_customer);
		const main_table_items_checked  = this.state.main_table_items_checked .filter(id => id != id_reservation_customer);
		
		this.setState({
			main_table_items_changed:  true,
			main_table_items,
			main_table_items_sort_ids,
			main_table_items_checked,
		});
	}
	
	toIsoString(date) {
		if (date === null) return null;
		
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	}
	toIsoStringWithTime(date) {
		if (date === null) return null;
		
		const year  = date.getFullYear();
		const month = date.getMonth() + 1;
		const day   = date.getDate();
		
		const hour   = date.getHours();
		const minute = date.getMinutes();
		const second = date.getSeconds();
		
		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) +
			'T' + (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute) + ':' + (second < 10 ? '0' + second : second);
	}
	
	async closeDialog(save, confirm) {
		if (confirm) {
			this.setState({
				close_dialog_save_item: save,
				close_dialog_confirm_open: true,
			});
			return;
		}
		
		if (!save) {
			this.props.closeReservation();
			return;
		}
		
		if (await this.saveReservation()) {
			this.props.closeReservation();
		}
	}
	
	async saveReservation(state) {
		state = state || this.state;
		
		this.increaseLoading();
		
		const item = state.reservation === null ? {
			id_reservation: uuid.v4(),
		} : {...state.reservation};
		
		const customers = Object.values(state.main_table_items);
		
		item.check_in                    = this.toIsoString(state.check_in);
		item.check_out                   = this.toIsoString(state.check_out);
		item.id_customer                 = state.id_customer;
		item.phone                       = state.phone;
		item.email                       = state.email;
		item.note                        = state.note;
		item.id_channel                  = state.id_channel;
		item.id_market_segmentation      = state.id_market_segmentation;
		item.id_accommodation_item_place = state.id_accommodation_item_place;
		item.guest_adult_count           = state.guest_adult_count;
		item.guest_child_1_count         = state.guest_child_1_count;
		item.guest_child_2_count         = state.guest_child_2_count;
		item.custom_status               = state.custom_status;
		item.internal_code               = state.internal_code;
		item.status                      = state.status;
		item.status_2                    = state.status_2 == '' ? null : state.status_2;
		item.deleted                     = state.status != 'reversed' && state.status != 'no-show' && state.deleted;
		item.guests_checked_in           = state.guests_checked_in;
		item.username                    = this.props.user.username;
		
		// when at least one guest is added, overwrite guest counts from added guests
		if (customers.length > 0) {
			let guest_adult_count   = 0;
			let guest_child_1_count = 0;
			let guest_child_2_count = 0;
			
			for (let id_item in state.main_table_items) {
				const item     = state.main_table_items[id_item];
				const customer = this.props.customers[item.id_customer];
				if (customer === undefined) continue;
				
				console.log({ item, customer });
				
				const birth_date = new Date(customer.birth_date);
				const check_in   = new Date(item.check_in);
				const age        = this.getAge(birth_date, check_in);
				
				if (age < this.props.general_settings.reservation_child_age_limit_1) {
					guest_child_1_count++;
				}
				else if (age < this.props.general_settings.reservation_child_age_limit_2) {
					guest_child_2_count++;
				}
				else {
					guest_adult_count++;
				}
			}
			
			item.guest_adult_count   = guest_adult_count;
			item.guest_child_1_count = guest_child_1_count;
			item.guest_child_2_count = guest_child_2_count;
		}
		
		if (
			item.status != 'reversed' &&
			item.status != 'no-show'  &&
			ReservationHelper.FindOverlappingReservation(item, this.props.reservations) !== null
		) {
			this.setState({
				error_message:
					'V izbranem obdobju (' +
						moment(item.check_in).format('DD.MM.YYYY') +
						' - ' +
						moment(item.check_out).format('DD.MM.YYYY') +
					') že obstaja rezervacija.',
			});
			this.decreaseLoading();
			return;
		}
		
		if (item.id_reservation === undefined || item.id_reservation == -1) {
			item.id_reservation = uuid.v4();
		}
		
		item.items = {};
		for (let key in state.reservation_items) {
			const reservation_item = state.reservation_items[key];
			if (!reservation_item.selected) continue;
			
			//NOTE this code relies on only one id_item being used for each accommodation item's item set
			let new_reservation_item = null;
			for (let existing_key in state.reservation.items) {
				const existing_reservation_item = state.reservation.items[existing_key];
				if (existing_reservation_item.id_item == reservation_item.item.id_item) {
					new_reservation_item = existing_reservation_item;
					break;
				}
			}
			
			if (new_reservation_item === null) {
				new_reservation_item = {
					id_reservation_item:         uuid.v4(),
					id_reservation:              item.id_reservation,
					id_item:                     reservation_item.item.id_item,
					calculation_type:            reservation_item.calculation_type,
					mandatory:                   reservation_item.mandatory,
					enable_short_term_surcharge: reservation_item.enable_short_term_surcharge,
					age_from:                    reservation_item.age_from,
					age_until:                   reservation_item.age_until,
				};
			}
			
			item.items[new_reservation_item.id_reservation_item] = new_reservation_item;
		}
		
		if (item.id_market_segmentation == -1) {
			item.id_market_segmentation = null;
		}
		
		if (!this.validate(item)) {
			this.decreaseLoading();
			return false;
		}
		
		let new_item;
		
		// if user changed customer's email or phone, update customer with it
		const customer = this.props.customers[item.id_customer];
		if (customer.email != item.email || customer.phone != item.phone) {
			const new_customer = {...customer};
			
			new_customer.email = item.email;
			new_customer.phone = item.phone;
			
			this.props.dispatch(addCustomer({ item: new_customer, token: this.props.token }));
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			new_item = await saveCodeTableItem(this.props.api_url, 'customers', new_customer, token);
			if (new_item !== null) {
				dispatch(addCustomer({
					item: new_item,
					token,
				}));
			}
		}
		
		const [ dispatch, token ] = [ this.props.dispatch, this.props.token ];
		dispatch(addReservation({ item, token }));
		new_item = await saveReservation(this.props.api_url, item, token);
		if (new_item !== null) {
			dispatch(addReservation({ item: new_item, token }));
			
			if (this.props.savedReservationCallback !== undefined) {
				this.props.savedReservationCallback(new_item);
			}
			
			if (this._ismounted) {
				this.setState({
					reservation:              new_item,
					internal_code:            new_item.internal_code,
					main_table_items_changed: false,
				});
			}
		}
		
		const current_customers = this.props.reservation_customers_by_id_reservations[item.id_reservation];
		const old_customer_ids  = current_customers === undefined || current_customers === null ? [] : Object.keys(current_customers);
		const new_customer_ids  = customers.map(customer => customer.id_reservation_customer);
		
		let missing_customer_ids = [];
		for (let i=0; i<old_customer_ids.length; i++) {
			if (new_customer_ids.indexOf(old_customer_ids[i]) == -1) {
				missing_customer_ids.push(old_customer_ids[i]);
			}
		}
		
		const main_table_items = {...state.main_table_items};
		const items = [];
		for (let i=0; i<customers.length; i++) {
			const customer = {...customers[i]};
			customer.id_reservation = item.id_reservation;
			if (customer.id_group == '') customer.id_group = null;
			
			items.push(customer);
		}
		const new_items = await batchSaveReservationCustomer(
			this.props.api_url,
			customers.map(x => {
				return { ...x, id_reservation: item.id_reservation, id_group: (x.id_group == '' ? null : x.id_group) };
			}),
			token
		);
		for (let i=0; i<new_items.reservation_customers.length; i++) {
			if (this._ismounted) {
				if (main_table_items[new_items.reservation_customers[i].id_reservation_customer] !== undefined) {
					main_table_items[new_items.reservation_customers[i].id_reservation_customer] = new_items.reservation_customers[i];
				}
			}
		}
		dispatch(batchAddReservationCustomers({
			items: new_items.reservation_customers,
			token,
		}));
		dispatch(batchAddGuestBookItems({
			items: new_items.guest_book_items,
			token,
		}));
		this.setState({ main_table_items });
		
		for (let i=0; i<missing_customer_ids.length; i++) {
			await deleteReservationCustomer(this.props.api_url, missing_customer_ids[i], token);
			dispatch(removeReservationCustomer({
				item: current_customers[missing_customer_ids[i]],
			}));
		}
		
		// save items/services for customers
		let reservation_customers = {};
		if (this.props.reservation !== null) {
			reservation_customers =
				this.props.reservation_customers_by_id_reservations[this.props.reservation.id_reservation] || {};
		}
		const reservation_customers_ids = Object.keys(reservation_customers);
		const current_reservation_customer_item_ids =
			Object.values(this.props.reservation_customer_items).filter(
				x => reservation_customers_ids.indexOf(x.id_reservation_customer) != -1
			).map(x => x.id_reservation_customer_item);
		const processed_reservation_customer_item_ids = this.state.customer_items.map(item => item.id_reservation_customer_item);
		
		await saveReservationCustomerItems(this.props.api_url, this.state.customer_items, token);
		
		for (let i=0; i<current_reservation_customer_item_ids.length; i++) {
			if (processed_reservation_customer_item_ids.indexOf(current_reservation_customer_item_ids[i]) == -1) {
				await deleteReservationCustomerItem(this.props.api_url, current_reservation_customer_item_ids[i], token);
				dispatch(removeReservationCustomerItem({ item: { id_reservation_customer_item: current_reservation_customer_item_ids[i] } }));
			}
		}
		
		dispatch(addReservationCustomerItems(this.state.customer_items));
		
		this.setState({
			guest_book_items: {},
		});
		
		this.decreaseLoading();
		
		return true;
	}
	
	validate(item) {
		// validate missing/empty fields
		const validation_failed_fields       = [];
		const validation_failed_field_titles = [];
		
		if (item.id_customer == -1 || item.id_customer === null) {
			validation_failed_fields.push('id_customer');
			validation_failed_field_titles.push('nosilec');
		}
		if (item.id_channel == -1) {
			validation_failed_fields.push('id_channel');
			validation_failed_field_titles.push('prodajni kanal');
		}
		if (item.check_in === null || item.check_out === null) {
			validation_failed_fields.push('check_in_out');
			validation_failed_field_titles.push('Obdobje');
		}
		
		this.setState({ validation_failed_fields });
		
		if (validation_failed_fields.length > 0) {
			AppToaster.show({
				message: <div>
					Za shranjevanje rezervacije, izpolnite vsaj polja:
					<br />
					{validation_failed_field_titles
						.map(title => <div key={title}>{'• ' + title}</div>)
					}
				</div>,
				intent: 'danger',
				icon: 'issue'
			});
			return false;
		}
		
		return true;
	}
	
	diffByIgnoringTimes(check_out, check_in) {
		return Math.ceil(check_out.clone().set('hour', 12).set('minute', 0).set('second', 0).diff(
			check_in.clone().set('hour', 12).set('minute', 0).set('second', 0),
			'days',
			true
		));
	}
	
	calculateReservationPrice(id_reservation_customers, additional_items_id_reservation_customers, include_tourist_tax_in_items) {
		//TODO cache this somehow
		
		id_reservation_customers                  = id_reservation_customers                  || Object.keys(this.state.main_table_items);
		additional_items_id_reservation_customers = additional_items_id_reservation_customers || this.state.customer_items.map(item => item.id_reservation_customer);
		include_tourist_tax_in_items              = include_tourist_tax_in_items === undefined ? true : false;
		
		let accommodation_items_price       = 0;
		let additional_customer_items_price = 0;
		let tourist_taxes_by_ids            = {};
		let total_tourist_tax               = 0;
		
		const reservation_check_in  = moment(this.state.check_in);
		const reservation_check_out = moment(this.state.check_out);
		const reservation_diff_days = this.diffByIgnoringTimes(reservation_check_out, reservation_check_in);
		
		const reservation_daily_items = {};
		
		// sum up prices from items
		for (let i=0; i<this.state.reservation_items.length; i++) {
			const reservation_item = this.state.reservation_items[i];
			if (!reservation_item.selected) continue;
			
			if (reservation_item.calculation_type == 'reservation') {
				const id_item_price_list = PriceHelper.FindBestPriceListMatch(reservation_check_in, reservation_item.item.price_lists);
				const price              = id_item_price_list === null ? 0 : parseFloat(reservation_item.item.price_lists[id_item_price_list].price);
				
				const item = {
					type:               'reservation-item',
					id_item:            reservation_item.item.id_item,
					quantity:           1,
					id_item_price_list,
					description:        '',
					price,
					single_price:       price,
				};
				const key = item.id_item + '--' + (item.id_item_price_list === null ? 'null' : item.id_item_price_list);
				if (reservation_daily_items[key] !== undefined) {
					reservation_daily_items[key].quantity += item.quantity;
					reservation_daily_items[key].price    += item.price;
				}
				else {
					reservation_daily_items[key] = item;
				}
				
				accommodation_items_price += price;
			}
			else if (reservation_item.calculation_type == 'reservation_day') {
				let date = moment(reservation_check_in);
				for (let i=0; i<reservation_diff_days; i++) {
					const id_item_price_list = PriceHelper.FindBestPriceListMatch(date, reservation_item.item.price_lists);
					const price              = id_item_price_list === null ? 0 : parseFloat(reservation_item.item.price_lists[id_item_price_list].price);
					
					const item = {
						type:               'reservation-item',
						id_item:            reservation_item.item.id_item,
						quantity:           1,
						id_item_price_list,
						description:        '',
						price,
						single_price:       price,
					};
					const key = item.id_item + '--' + (item.id_item_price_list === null ? 'null' : item.id_item_price_list);
					if (reservation_daily_items[key] !== undefined) {
						reservation_daily_items[key].quantity += item.quantity;
						reservation_daily_items[key].price    += item.price;
					}
					else {
						reservation_daily_items[key] = item;
					}
					
					accommodation_items_price += price;
					
					date.add(1, 'day');
				}
			}
			else if (reservation_item.calculation_type == 'person_day' || reservation_item.calculation_type == 'person') {
				// if there are no checked-in guests, use guest count from guest_adult_count, guest_child_1_count and
				//   guest_child_2_count fields
				if (id_reservation_customers.length == 0) {
					const counts_with_ages = [
						{ age: this.props.general_settings.reservation_child_age_limit_1 - 0.01, count: this.state.guest_child_1_count },
						{ age: this.props.general_settings.reservation_child_age_limit_2 - 0.01, count: this.state.guest_child_2_count },
						{ age: 100,   count: this.state.guest_adult_count },
					];
					for (let j=0; j<counts_with_ages.length; j++) {
						const age   = counts_with_ages[j].age;
						const count = counts_with_ages[j].count;
						
						if (count == 0) continue;
						
						if (
							(
								reservation_item.age_from === null &&
								reservation_item.age_until === null
							) ||
							(
								reservation_item.age_from === null &&
								reservation_item.age_until !== null &&
								age < reservation_item.age_until
							) ||
							(
								reservation_item.age_until === null &&
								reservation_item.age_from !== null &&
								age >= reservation_item.age_from
							) ||
							(
								reservation_item.age_from !== null &&
								reservation_item.age_until !== null &&
								age >= reservation_item.age_from &&
								age <  reservation_item.age_until
							)
						) {
							let diff_days = reservation_diff_days;
							if (reservation_item.calculation_type == 'person') { // only use price once, not one for each day
								diff_days = 1;
							}
							
							let date = moment(reservation_check_in);
							for (let i=0; i<diff_days; i++) {
								const id_item_price_list = PriceHelper.FindBestPriceListMatch(date, reservation_item.item.price_lists);
								const price              = id_item_price_list === null ? 0 : parseFloat(reservation_item.item.price_lists[id_item_price_list].price);
								
								const item = {
									type:               'reservation-item',
									id_item:            reservation_item.item.id_item,
									quantity:           count,
									id_item_price_list,
									description:        '',
									price:              price * count,
									single_price:       price,
								};
								const key = item.id_item + '--' + (item.id_item_price_list === null ? 'null' : item.id_item_price_list);
								if (reservation_daily_items[key] !== undefined) {
									reservation_daily_items[key].quantity += item.quantity;
									reservation_daily_items[key].price    += item.price;
								}
								else {
									reservation_daily_items[key] = item;
								}
								
								accommodation_items_price += price * count;
								
								date.add(1, 'day');
							}
						}
					}
				}
				else {
					for (let idx in id_reservation_customers) {
						const reservation_customer = this.state.main_table_items[id_reservation_customers[idx]];
						const customer             = this.props.customers[reservation_customer.id_customer];
						
						if (customer === undefined) continue;
						
						const check_in = moment(
							reservation_customer.check_in !== null ? reservation_customer.check_in : this.state.check_in
						);
						const check_out = moment(
							reservation_customer.check_out !== null ? reservation_customer.check_out : this.state.check_out
						);
						let diff_days = this.diffByIgnoringTimes(check_out, check_in);
						if (reservation_item.calculation_type == 'person') { // only use price once, not one for each day
							diff_days = 1;
						}
						
						const curr_date = check_in;
						for (let x=0; x<diff_days; x++) {
							const age = curr_date.diff(customer.birth_date, 'years');
							
							if (
								(
									reservation_item.age_from === null &&
									reservation_item.age_until === null
								) ||
								(
									reservation_item.age_from === null &&
									reservation_item.age_until !== null &&
									age < reservation_item.age_until
								) ||
								(
									reservation_item.age_until === null &&
									reservation_item.age_from !== null &&
									age >= reservation_item.age_from
								) ||
								(
									reservation_item.age_from !== null &&
									reservation_item.age_until !== null &&
									age >= reservation_item.age_from &&
									age <  reservation_item.age_until
								)
							) {
								//accommodation_items_price += price;
								////item.price     += price;
								//item.quantity  += 1;
								
								const id_item_price_list = PriceHelper.FindBestPriceListMatch(curr_date, reservation_item.item.price_lists);
								const price              = id_item_price_list === null ? 0 : parseFloat(reservation_item.item.price_lists[id_item_price_list].price);
								
								const item = {
									type:               'reservation-item',
									id_item:            reservation_item.item.id_item,
									quantity:           1,
									id_item_price_list,
									description:        '',
									price,
									single_price:       price,
								};
								const key = item.id_item + '--' + (item.id_item_price_list === null ? 'null' : item.id_item_price_list);
								if (reservation_daily_items[key] !== undefined) {
									reservation_daily_items[key].quantity += item.quantity;
									reservation_daily_items[key].price    += item.price;
								}
								else {
									reservation_daily_items[key] = item;
								}
								
								accommodation_items_price += price;
							}
							
							curr_date.add(1, 'days');
						}
					}
				}
			}
		}
		
		const items = Object.values(reservation_daily_items).filter(x => x.quantity != 0);
		
		// sum up tourist taxes
		const accommodation_item_place = this.props.accommodation_item_places[this.state.reservation.id_accommodation_item_place];
		const accommodation_item       = this.props.accommodation_items[accommodation_item_place.id_accommodation_item];
		const accommodation            = this.props.accommodations[accommodation_item.id_accommodation];
		
		const tourist_tax_item = accommodation.tourist_tax_id_item === null ? null : this.props.items[accommodation.tourist_tax_id_item];
		
		if (tourist_tax_item !== null && tourist_tax_item !== undefined) {
			const id_item_price_list = PriceHelper.FindBestPriceListMatch(reservation_check_in, tourist_tax_item.price_lists);
			const price              = id_item_price_list === null ? 0 : parseFloat(tourist_tax_item.price_lists[id_item_price_list].price);
			
			if (price > 0) {
				// if there are no checked-in guests, use guest count from guest_adult_count, guest_child_1_count and
				//   guest_child_2_count fields
				if (id_reservation_customers.length == 0) {
					const tourist_taxes             = Object.values(this.props.tourist_taxes);
					const tourist_tax_adults        = tourist_taxes.find(tourist_tax => tourist_tax.internal_code == '0');
					const tourist_tax_children_0_7  = tourist_taxes.find(tourist_tax => tourist_tax.internal_code == '1');
					const tourist_tax_children_7_18 = tourist_taxes.find(tourist_tax => tourist_tax.internal_code == '11');
					
					const taxes_with_counts = [
						[ tourist_tax_adults,        this.state.guest_adult_count   ],
						[ tourist_tax_children_0_7,  this.state.guest_child_1_count ],
						[ tourist_tax_children_7_18, this.state.guest_child_2_count ],
					];
					
					for (let i=0; i<taxes_with_counts.length; i++) {
						const tourist_tax = taxes_with_counts[i][0];
						const count       = taxes_with_counts[i][1];
						
						if (tourist_tax === undefined) continue;
						if (count == 0)                continue;
						
						if (tourist_taxes_by_ids[tourist_tax.id_tourist_tax] === undefined) {
							tourist_taxes_by_ids[tourist_tax.id_tourist_tax] = {
								price: 0,
								price_with_discount: 0,
								quantity: 0,
							};
						}
						
						const price_with_discount = price * (1 - tourist_tax.discount / 100);
						const c = count * reservation_diff_days;
						total_tourist_tax                                                    += price_with_discount * c;
						tourist_taxes_by_ids[tourist_tax.id_tourist_tax].price               += price               * c;
						tourist_taxes_by_ids[tourist_tax.id_tourist_tax].price_with_discount  = price_with_discount;
						tourist_taxes_by_ids[tourist_tax.id_tourist_tax].quantity            += c;
					}
				}
				else {
					for (let idx in id_reservation_customers) {
						const reservation_customer = this.state.main_table_items[id_reservation_customers[idx]];
						const tourist_tax          = this.props.tourist_taxes[reservation_customer.id_tourist_tax];
						
						const check_in = moment(
							reservation_customer.check_in !== null ? reservation_customer.check_in : this.state.check_in
						);
						const check_out = moment(
							reservation_customer.check_out !== null ? reservation_customer.check_out : this.state.check_out
						);
						const diff_days = this.diffByIgnoringTimes(check_out, check_in);
						
						const curr_date = check_in;
						for (let x=0; x<diff_days; x++) {
							if (tourist_taxes_by_ids[tourist_tax.id_tourist_tax] === undefined) {
								tourist_taxes_by_ids[tourist_tax.id_tourist_tax] = { price: 0, quantity: 0 };
							}
							
							const price_with_discount = price * (1 - tourist_tax.discount / 100);
							total_tourist_tax                                                    += price_with_discount;
							tourist_taxes_by_ids[tourist_tax.id_tourist_tax].price               += price;
							tourist_taxes_by_ids[tourist_tax.id_tourist_tax].price_with_discount  = price_with_discount;
							tourist_taxes_by_ids[tourist_tax.id_tourist_tax].quantity            += 1;
							
							curr_date.add(1, 'days');
						}
					}
				}
			}
			
			if (include_tourist_tax_in_items) {
				for (let id_tourist_tax in tourist_taxes_by_ids) {
					items.push({
						type:               'tourist-tax',
						id_item:            accommodation.tourist_tax_id_item,
						quantity:           tourist_taxes_by_ids[id_tourist_tax].quantity,
						price:              tourist_taxes_by_ids[id_tourist_tax].price_with_discount * tourist_taxes_by_ids[id_tourist_tax].quantity,
						single_price:       tourist_taxes_by_ids[id_tourist_tax].price_with_discount,
						id_item_price_list,
						id_tourist_tax,
						description:        '',
					});
				}
			}
		}
		
		for (let i=0; i<this.state.customer_items.length; i++) {
			const item = this.state.customer_items[i];
			if (additional_items_id_reservation_customers.indexOf(item.id_reservation_customer) == -1) continue;
			
			const quantity    = parseFloat(item.quantity);
			const price       = parseFloat(this.props.items[item.id_item].price_lists[item.id_item_price_list].price);
			const total_price = parseFloat(this.props.items[item.id_item].price_lists[item.id_item_price_list].price) * quantity;
			
			items.push({
				type:               'customer-item',
				id_item:            item.id_item,
				quantity:           quantity,
				price:              total_price,
				single_price:       price,
				id_item_price_list: item.id_item_price_list,
				description:        '',
			});
			
			additional_customer_items_price += total_price;
		}
		
		return {
			accommodation_items_price,
			additional_customer_items_price,
			tourist_taxes: tourist_taxes_by_ids,
			total_tourist_tax,
			total: accommodation_items_price + total_tourist_tax + additional_customer_items_price,
			items,
		};
	}
	
	/*openAddEditItemDialog(item, type) {
		this.setState({ add_edit_item_dialog_open: true, add_edit_item_dialog_item: item, add_edit_item_dialog_type: type });
	}
	closeAddEditItemDialog(item) {
		this.setState({ add_edit_item_dialog_open: false });
		
		if (item !== undefined) {
			this.props.dispatch(addInvoice({
				item,
				token: this.props.token,
			}));
			
			const dispatch = this.props.dispatch;
			const token    = this.props.token;
			
			const new_items = await saveInvoice(this.props.api_url, item, token, dispatch);
			if (new_items !== null) {
				if (new_items.invoices !== undefined) {
					dispatch(addInvoices(new_items.invoices));
				}
				if (new_items.document_associations !== undefined) {
					dispatch(addDocumentAssociations(new_items.document_associations));
				}
			}
		}
	}*/
	
	increaseLoading() {
		if (!this._ismounted) return;
		
		this.loading++;
		this.setState({ loading: this.loading });
	}
	decreaseLoading() {
		if (!this._ismounted) return;
		
		this.loading--;
		this.setState({ loading: this.loading });
	}
	
	openDocumentListDialog(id_document, document_type) {
		this.setState({
			document_list_dialog_open:          true,
			document_list_dialog_id_document:   id_document,
			document_list_dialog_document_type: document_type,
		});
	}
	closeDocumentListDialog() {
		this.setState({
			document_list_dialog_open:          false,
			document_list_dialog_id_document:   null,
			document_list_dialog_document_type: null,
		});
	}
	
	// from https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd/7091965#7091965
	getAge(birthdate, today) {
		today       = today || new Date();
		let   age   = today.getFullYear() - birthdate.getFullYear();
		const m     = today.getMonth() - birthdate.getMonth();
		
		if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
			age--;
		}
		
		return age;
	}
	
	copyGuestToReservationCustomer(id_customer) {
		this.setState({ id_customer });
	}
	
	render() {
		const any_checked_items = this.state.main_table_items_checked.length;
		const calculated_reservation_price = this.calculateReservationPrice();
		
		const reservation_items_without_person_day = this.state.reservation_items.filter(item => {
			return item.calculation_type != 'person_day' || (item.age_from === null && item.age_until === null);
		});
		
		const accommodation_item_place = this.props.accommodation_item_places[this.state.reservation.id_accommodation_item_place];
		const accommodation_item       = this.props.accommodation_items[accommodation_item_place.id_accommodation_item];
		const accommodation            = this.props.accommodations[accommodation_item.id_accommodation];
		
		const customer_documents = {};
		let   can_log_out_customers = true;
		for (let id_reservation_customer in this.state.main_table_items) {
			if (this.props.reservation_customers[id_reservation_customer] === undefined) continue;
			
			const documents = DocumentsHelper.GetDocumentsForReservationAndCustomer(
				this.props.document_associations,
				this.state.reservation.id_reservation,
				id_reservation_customer
			);
			
			customer_documents[id_reservation_customer] = documents.customer_documents;
			
			if (this.state.main_table_items_checked.indexOf(id_reservation_customer) != -1) {
				const id_guest_book = this.props.reservation_customers[id_reservation_customer].id_guest_book;
				if (id_guest_book !== undefined) {
					const guest_book_item = this.props.guest_book_items[id_guest_book];
					if (guest_book_item === undefined || guest_book_item === null || guest_book_item.zst === null) {
						can_log_out_customers = false;
					}
				}
			}
		}
		
		const consider_deleted = this.state.deleted || this.state.status == 'reversed' || this.state.status == 'no-show';
		
		return (
			<Dialog
				isOpen={true}
				usePortal={true}
				canOutsideClickClose={false}
				canEscapeKeyClose={
					!this.state.edit_reservation_customer_dialog_open &&
					!this.state.invoice_dialog_open &&
					!this.state.select_customer_dialog_open &&
					!this.state.date_picker_dialog_open
				}
				onClose={() => {
					this.closeDialog(false, true);
				}}
				className='main-dialog'>
				
				{!this.state.edit_reservation_customer_dialog_open ? null :
					<EditReservationCustomerDialog
						closeEditReservationCustomerDialog={this.closeEditReservationCustomerDialog}
						item={this.state.edit_reservation_customer_dialog_item}
						id_accommodation_item_place={this.state.reservation.id_accommodation_item_place}
						default_check_out={
							new Date(
								moment(this.state.check_out).format('YYYY-MM-DD') +
								'T' +
								accommodation.default_check_out_time
							)
						} />
				}
				
				{!this.state.select_customer_dialog_open ? null :
					<SelectCustomerDialog
						closeSelectCustomerDialog ={this.closeSelectCustomerDialog}
						name                      ={this.state.select_customer_dialog_name}
						surname                   ={this.state.select_customer_dialog_surname}
						email                     ={this.state.select_customer_dialog_email}
						id_country                ={this.state.select_customer_dialog_id_country}
						phone                     ={this.state.select_customer_dialog_phone}
						post_office               ={this.state.select_customer_dialog_post_office}
						address                   ={this.state.select_customer_dialog_address}
						item={this.state.select_customer_dialog_item} />
				}
				
				{!this.state.invoice_dialog_open ? null :
					<InvoiceDialog
						closeInvoiceDialog={this.closeInvoiceDialog}
						preselect={this.state.invoice_dialog_preselect}
						calculatedState={this.state.invoice_dialog_calculated_state}
						idCustomer={this.state.invoice_dialog_id_customer}
						guestIdCustomers={[...new Set([
							...(
								this.state.id_customer === null ||
								this.state.id_customer === undefined ||
								this.state.id_customer == -1 ?
								[] : [this.state.id_customer]
							),
							...Object.values(this.state.main_table_items).map(guest => guest.id_customer),
						])]}
						advance_invoices={this.state.invoice_dialog_advance_invoices} />
				}
				
				{!this.state.date_picker_dialog_open ? null :
					<DateTimePickerDialog
						closeDateTimePickerDialog={this.closeDateTimePickerDialog}
						value={this.getDateFromStateByName(this.state.date_picker_dialog_state_name)} />
				}
				
				{!this.state.create_invoice_dialog_open ? null :
					<EditInvoiceDialog
						closeEditInvoiceDialog={this.closeEditInvoiceDialog}
						item={this.state.create_invoice_dialog_item}
						type={this.state.create_invoice_dialog_type}
						selected_advance_invoices_amounts={this.state.create_invoice_dialog_selected_advance_invoices_amounts} />
				}
				
				{!this.state.delete_requested ? null :
					<Alert
						cancelButtonText='Prekliči'
						confirmButtonText='Da'
						canEscapeKeyCancel={true}
						canOutsideClickCancel={true}
						icon='trash'
						intent={Intent.DANGER}
						isOpen={true}
						onConfirm={async () => {
							this.setState({ deleted: true, delete_requested: false });
							
							const state = {...this.state};
							state.deleted = true;
							if (await this.saveReservation(state)) {
								this.closeDialog(false, false);
							}
						}}
						onCancel={() => {
							this.setState({ delete_requested: false });
						}}>
						<div>
							<div className='mb-3'><strong>Brisanje rezervacije?</strong></div>
							
							<div>
								Rezervacija:
								<span> </span>
								{this.props.accommodation_item_places[this.state.reservation.id_accommodation_item_place].title}
								<span>, </span>
								{moment(this.state.check_in).format('DD.MM.YYYY') + ' - ' + moment(this.state.check_out).format('DD.MM.YYYY')}
							</div>
							<div className='mb-3'>Nosilec: {
								this.state.id_customer === null || this.state.id_customer === undefined || this.state.id_customer == -1 || this.props.customers[this.state.id_customer] === undefined ? '' :
								(
									this.props.customers[this.state.id_customer].type == 'natural' ?
										this.props.customers[this.state.id_customer].surname + ' ' + this.props.customers[this.state.id_customer].name
										:
										this.props.customers[this.state.id_customer].company_name
								)
							}</div>
							
							<div className='mt-4 mb-4'>
								Izbrisana rezervacija se bo evidentirala v seznamu rezervacij.
							</div>
						</div>
					</Alert>
				}
				
				{!this.state.status_change_requested ? null :
					<Alert
						cancelButtonText='Prekliči'
						confirmButtonText='Da'
						canEscapeKeyCancel={true}
						canOutsideClickCancel={true}
						icon='help'
						intent={Intent.PRIMARY}
						isOpen={true}
						onConfirm={() => {
							this.setState({ status_change_requested: false, status: this.state.status_change_requested_value });
						}}
						onCancel={() => {
							this.setState({ status_change_requested: false });
						}}>
						<div>
							<div className='mb-3'><strong>
								{this.state.status_change_requested_value == 'reversed' ?
									'Storniranje rezervacije?'
									:
									(this.state.status_change_requested_value == 'no-show' ?
										'Neprihod?'
										:
										null
									)
								}
							</strong></div>
							
							<div>
								Rezervacija:
								<span> </span>
								{this.props.accommodation_item_places[this.state.reservation.id_accommodation_item_place].title}
								<span>, </span>
								{moment(this.state.check_in).format('DD.MM.YYYY') + ' - ' + moment(this.state.check_out).format('DD.MM.YYYY')}
							</div>
							<div className='mb-3'>Nosilec: {
								this.state.id_customer === null || this.state.id_customer === undefined || this.state.id_customer == -1 || this.props.customers[this.state.id_customer] === undefined ? '' :
								(
									this.props.customers[this.state.id_customer].type == 'natural' ?
										this.props.customers[this.state.id_customer].surname + ' ' + this.props.customers[this.state.id_customer].name
										:
										this.props.customers[this.state.id_customer].company_name
								)
							}</div>
							
							<div className='mt-4 mb-4'>
								{this.state.status_change_requested_value == 'reversed' ?
									'Stornirana rezervacija se bo evidentirala v seznamu rezervacij.'
									:
									(this.state.status_change_requested_value == 'no-show' ?
										'Neprihod se bo evidentiral v seznamu rezervacij.'
										:
										null
									)
								}
							</div>
						</div>
					</Alert>
				}
				
				{!this.state.copy_customer_to_guest ? null :
					<Alert
						cancelButtonText='Ne'
						confirmButtonText='Da'
						canEscapeKeyCancel={true}
						canOutsideClickCancel={true}
						icon='duplicate'
						intent={Intent.PRIMARY}
						isOpen={true}
						onConfirm={() => {
							const reservation_customer = {
								id_reservation_customer:     -1,
								id_reservation:              -1,
								id_customer:                 this.state.id_customer,
								check_in:                    this.toIsoStringWithTime(new Date()),
								check_out:                   null,
								invoice_paid:                false,
								invoice_paid_on:             null,
								identification_number:       '',
								id_group:                    null,
								pay_tourist_tax:             true,
								add_to_guest_book:           true,
								agree_to_further_processing: false,
								id_tourist_tax:              null,
								id_identification_type:      null,
								checked_out:                 false,
							};
							this.openEditReservationCustomerDialog(reservation_customer);
							
							this.setState({ copy_customer_to_guest: false });
						}}
						onCancel={() => {
							this.openEditReservationCustomerDialog(null);
							this.setState({ copy_customer_to_guest: false });
						}}>
						Prepišem nosilca v goste?
					</Alert>
				}
				
				{!this.state.close_dialog_confirm_open ? null :
					<Alert
						cancelButtonText='Prekliči'
						confirmButtonText='Zapri'
						canEscapeKeyCancel={true}
						canOutsideClickCancel={true}
						icon='help'
						intent={Intent.PRIMARY}
						isOpen={true}
						onConfirm={async () => {
							await this.closeDialog(this.state.close_dialog_save_item, false);
							this.setState({
								close_dialog_save_item:    false,
								close_dialog_confirm_open: false,
							});
						}}
						onCancel={() => {
							this.setState({
								close_dialog_save_item:    false,
								close_dialog_confirm_open: false,
							});
						}}>
						<div>
							<div className='mb-3'><strong>Shranjevanje rezervacije?</strong></div>
							
							<div>
								Rezervacija:
								<span> </span>
								{this.props.accommodation_item_places[this.state.reservation.id_accommodation_item_place].title}
								<span>, </span>
								{moment(this.state.check_in).format('DD.MM.YYYY') + ' - ' + moment(this.state.check_out).format('DD.MM.YYYY')}
							</div>
							<div className='mb-3'>Nosilec: {
								this.state.id_customer === null || this.state.id_customer === undefined || this.state.id_customer == -1 || this.props.customers[this.state.id_customer] === undefined ? '' :
								(
									this.props.customers[this.state.id_customer].type == 'natural' ?
										this.props.customers[this.state.id_customer].surname + ' ' + this.props.customers[this.state.id_customer].name
										:
										this.props.customers[this.state.id_customer].company_name
								)
							}</div>
							
							<div className='mt-4 mb-4'>
								Želite zapreti rezervacijo brez shranjevanja?
							</div>
						</div>
					</Alert>
				}
				
				{!this.state.delete_confirm_dialog_open ? null :
					<Alert
						cancelButtonText='Prekliči'
						confirmButtonText='Da'
						canEscapeKeyCancel={true}
						canOutsideClickCancel={true}
						icon='trash'
						intent={Intent.DANGER}
						isOpen={true}
						onConfirm={() => {
							this.deleteMainTableItem(
								this.state.delete_confirm_dialog_id_reservation_customer,
								true
							);
							
							this.setState({ delete_confirm_dialog_open: false });
						}}
						onCancel={() => {
							this.setState({ delete_confirm_dialog_open: false });
						}}>
						<div>
							<div className='mb-3'><strong>{
								this.state.main_table_items[
									this.state.delete_confirm_dialog_id_reservation_customer
								].id_guest_book !== null
								&&
								this.props.guest_book_items[this.state.main_table_items[
									this.state.delete_confirm_dialog_id_reservation_customer
								].id_guest_book] !== undefined
								&&
								this.props.guest_book_items[this.state.main_table_items[
									this.state.delete_confirm_dialog_id_reservation_customer
								].id_guest_book].zst !== null
								?
								'Storniranje gosta?'
								:
								'Brisanje gosta?'
							}</strong></div>
							
							<div>
								Rezervacija:
								<span> </span>
								{this.props.accommodation_item_places[this.state.reservation.id_accommodation_item_place].title}
								<span>, </span>
								{moment(this.state.check_in).format('DD.MM.YYYY') + ' - ' + moment(this.state.check_out).format('DD.MM.YYYY')}
							</div>
							<div className='mb-3'>Gost: {
								this.props.customers[
									this.state.main_table_items[this.state.delete_confirm_dialog_id_reservation_customer].id_customer
								].type == 'natural' ?
									this.props.customers[
										this.state.main_table_items[this.state.delete_confirm_dialog_id_reservation_customer].id_customer
									].surname + ' ' + this.props.customers[
										this.state.main_table_items[this.state.delete_confirm_dialog_id_reservation_customer].id_customer
									].name
									:
									this.props.customers[
										this.state.main_table_items[this.state.delete_confirm_dialog_id_reservation_customer].id_customer
									].company_name
							}</div>
							
							<div className='mt-4 mb-4'>{
								this.state.main_table_items[
									this.state.delete_confirm_dialog_id_reservation_customer
								].id_guest_book !== null
								&&
								this.props.guest_book_items[this.state.main_table_items[
									this.state.delete_confirm_dialog_id_reservation_customer
								].id_guest_book] !== undefined
								&&
								this.props.guest_book_items[this.state.main_table_items[
									this.state.delete_confirm_dialog_id_reservation_customer
								].id_guest_book].zst !== null
								?
								'Gost bo evidentiran kot storniran v knjigi gostov in kot tak tudi poslan na e-Turizem. Ne bo upoštevan pri obračunu turistične takse.'
								:
								'Gost bo izbrisan in ne bo evidentiran v knjigi gostov ter pri obračunu turistične takse.'
							}
							</div>
						</div>
					</Alert>
				}
				
				{!this.state.error_message ? null :
					<Alert
						confirmButtonText='Nadaljuj'
						canEscapeKeyCancel={true}
						canOutsideClickCancel={true}
						icon='error'
						intent={Intent.DANGER}
						isOpen={true}
						onConfirm={() => {
							this.setState({ error_message: null });
						}}>
						<div>
							<div className='mb-3'><strong>Napaka</strong></div>
							
							<div>
								{this.state.error_message}
							</div>
						</div>
					</Alert>
				}
				
				{this.state.delete_request__id_reservation_customer === null || this.state.delete_request__id_item === null ? null :
					<Alert
						cancelButtonText='Prekliči'
						confirmButtonText='Odstrani'
						canEscapeKeyCancel={true}
						canOutsideClickCancel={true}
						icon='trash'
						intent={Intent.DANGER}
						isOpen={true}
						onConfirm={() => {
							const existing_idx = this.state.customer_items.findIndex(x =>
								x.id_reservation_customer == this.state.delete_request__id_reservation_customer &&
								x.id_item == this.state.delete_request__id_item
							);
							
							if (existing_idx != -1) {
								const customer_items = this.state.customer_items.map(x => { return {...x}; });;
								customer_items.splice(existing_idx, 1);
								this.setState({ customer_items });
							}
							
							this.setState({
								delete_request__id_reservation_customer: null,
								delete_request__id_item: null,
							});
						}}
						onCancel={() => {
							this.setState({
								delete_request__id_reservation_customer: null,
								delete_request__id_item: null,
							});
						}}>
						<div>
							<div>
								Res želite odstraniti postavko?
							</div>
						</div>
					</Alert>
				}
				
				{!this.state.document_list_dialog_open ? null :
					<DocumentListDialog
						id_document={this.state.document_list_dialog_id_document}
						closeDialog={this.closeDocumentListDialog}
						deep={false}
						document_type={this.state.document_list_dialog_document_type} />
				}
				
				{!this.state.wait_fiscal_verification_dialog_open ? null :
					<WaitFiscalVerificationDialog
						closeWaitFiscalVerificationDialog={(print) => {
							this.setState({ wait_fiscal_verification_dialog_open: false });
							
							if (print) {
								const invoice = this.props.invoices[this.state.wait_fiscal_verification_dialog_id_invoice];
								if (this.props.general_settings.auto_print_type == 'a4') {
									this.props.dispatch(enqueueInvoiceOnA4PrinterQueue({ type: 'invoice', lang: 'sl', invoice }));
								}
								else if (this.props.general_settings.auto_print_type == 'pos') {
									this.props.dispatch(enqueueInvoiceOnPosPrinterQueue({ type: 'invoice', invoice }));
								}
							}
						}}
						id_invoice={this.state.wait_fiscal_verification_dialog_id_invoice} />
				}
				
				{!this.state.add_edit_item_with_count_dialog_visible ? null :
					<AddEditItemWithCountDialog
						date       ={this.state.check_in}
						id_item    ={this.state.add_edit_item_with_count_dialog_id_item}
						quantity   ={this.state.add_edit_item_with_count_dialog_quantity}
						closeDialog={(id_item, quantity) => {
							if (id_item === undefined || quantity === undefined) {
								this.setState({
									add_edit_item_with_count_dialog_visible: false,
									add_edit_item_with_count_dialog_id_reservation_customer: null,
									add_edit_item_with_count_dialog_quantity: 0,
									add_edit_item_with_count_dialog_id_item: null,
								});
								return;
							}
							
							// if new item, add it
							if (this.state.add_edit_item_with_count_dialog_id_item === null) {
								if (id_item !== null && quantity > 0) {
									const existing_idx = id_item === null
										? -1
										: this.state.customer_items.findIndex(item =>
											item.id_reservation_customer == this.state.add_edit_item_with_count_dialog_id_reservation_customer &&
											item.id_item == id_item
										);
									
									const customer_items = this.state.customer_items.map(x => { return {...x}; });
									if (existing_idx != -1) {
										customer_items[existing_idx].id_item   = id_item;
										customer_items[existing_idx].quantity += quantity;
									}
									else {
										customer_items.push({
											id_reservation_customer_item: uuid.v4(),
											id_reservation_customer: this.state.add_edit_item_with_count_dialog_id_reservation_customer,
											id_item,
											id_item_price_list: PriceHelper.FindBestPriceListMatch(
												this.state.check_in ?? new Date(),
												this.props.items[id_item].price_lists
											),
											quantity,
										});
									}
									this.setState({ customer_items });
								}
							}
							// if existing item, change it (including changing id_item)
							else {
								const existing_idx = this.state.customer_items.findIndex(item =>
									item.id_reservation_customer == this.state.add_edit_item_with_count_dialog_id_reservation_customer &&
									item.id_item == this.state.add_edit_item_with_count_dialog_id_item
								);
								const new_existing_idx = id_item === null
									? -1
									: this.state.customer_items.findIndex(item =>
										item.id_reservation_customer == this.state.add_edit_item_with_count_dialog_id_reservation_customer &&
										item.id_item == id_item
									);
								
								const customer_items = this.state.customer_items.map(x => { return {...x}; });
								
								// delete item if nothing was selected or if quantity is set to 0
								if (
									existing_idx != -1 &&
									new_existing_idx == existing_idx &&
									quantity <= 0
								) {
									customer_items.splice(existing_idx, 1);
								}
								else if (
									existing_idx != -1 &&
									id_item === null
								) {
									customer_items.splice(existing_idx, 1);
								}
								// change existing item's quantity
								else if (
									existing_idx != -1 &&
									new_existing_idx != -1 &&
									new_existing_idx != existing_idx &&
									quantity > 0
								) {
									customer_items[existing_idx    ].quantity  = 0;
									customer_items[new_existing_idx].quantity += quantity;
								}
								// change existing item
								else if (
									existing_idx != -1 &&
									quantity > 0
								) {
									customer_items[existing_idx].id_item  = id_item;
									customer_items[existing_idx].quantity = quantity;
								}
								// add new item
								else if (
									existing_idx == -1 &&
									quantity > 0
								) {
									customer_items.push({
										id_reservation_customer_item: uuid.v4(),
										id_reservation_customer: this.state.add_edit_item_with_count_dialog_id_reservation_customer,
										id_item,
										id_item_price_list: PriceHelper.FindBestPriceListMatch(
											this.state.check_in ?? new Date(),
											this.props.items[id_item].price_lists
										),
										quantity,
									});
								}
								
								this.setState({ customer_items });
							}
							
							this.setState({
								add_edit_item_with_count_dialog_visible: false,
								add_edit_item_with_count_dialog_id_reservation_customer: null,
								add_edit_item_with_count_dialog_quantity: 0,
								add_edit_item_with_count_dialog_id_item: null,
							});
						}} />
				}
				
				<div className='flex flex-col flex-grow'>
					<Navbar fixedToTop={false} className='bp5-dark' style={consider_deleted ? { backgroundColor: '#e53e3e' } : {}}>
						<Navbar.Group>
							<Navbar.Heading>
								<span>Rezervacija • </span>
								<span className='font-bold uppercase'>
									{ this.props.accommodation_item_places[this.state.reservation.id_accommodation_item_place].title }
								</span>
								<span> • </span>
								<span>
									{ this.state.reservation.internal_code == '' ? 'NOVA REZERVACIJA' : this.state.reservation.internal_code }
								</span>
							</Navbar.Heading>
							<Navbar.Divider />
							<ButtonGroup className='mr-6'>
								<Button minimal={false} icon='floppy-disk' text='Shrani' intent='success' onClick={() => {
									this.saveReservation();
								}} />
								<Button minimal={false} icon='floppy-disk' text='Shrani in zapri' intent='success' onClick={() => {
									this.closeDialog(true, false);
								}} />
							</ButtonGroup>
							{this.state.reservation.id_reservation < 0 ? null :
								<Button
									minimal={false}
									icon='trash'
									text='Izbriši'
									intent='danger'
									className='mr-6'
									disabled={Object.keys(this.state.main_table_items).length > 0 || consider_deleted}
									onClick={() => {
										this.setState({ delete_requested: true });
									}} />
							}
							<HTMLSelect
								style={{ backgroundColor: '#2c85c1' }}
								options={
									[
										{ label: 'Nova',           value: 'new',                      disabled: consider_deleted },
										{ label: 'V potrjevanju',  value: 'waiting-for-confirmation', disabled: consider_deleted },
										{ label: 'Izdana ponudba', value: 'offer-sent',               disabled: consider_deleted },
										{ label: 'Potrjena',       value: 'confirmed',                disabled: consider_deleted },
										{ label: 'Izdan avans',    value: 'advance-invoice-sent',     disabled: consider_deleted },
										{ label: 'Plačana',        value: 'closed',                   disabled: consider_deleted },
										{ label: 'Stornirana',     value: 'reversed',                 disabled: Object.keys(this.state.main_table_items).length > 0 },
										{ label: 'Neprihod',       value: 'no-show',                  disabled: Object.keys(this.state.main_table_items).length > 0 },
										{ label: 'Ni za oddajo',   value: 'not-for-rent',             disabled: consider_deleted },
									]
								}
								value={this.state.status}
								onChange={(e) => {
									const val = e.currentTarget.value;
									
									if (val == 'reversed' || val == 'no-show') {
										this.setState({ status_change_requested: true, status_change_requested_value: val });
										return;
									}
									
									this.setState({
										status: val,
									});
								}} />
							<HTMLSelect
								style={{ backgroundColor: '#2c85c1' }}
								className='mr-6'
								options={
									[
										{ label: 'Izberi status po meri', value: '' },
										{ label: this.props.general_settings.title_custom_status_1, value: 'custom-status-1' },
										{ label: this.props.general_settings.title_custom_status_2, value: 'custom-status-2' },
										{ label: this.props.general_settings.title_custom_status_3, value: 'custom-status-3' },
										{ label: this.props.general_settings.title_custom_status_4, value: 'custom-status-4' },
										{ label: this.props.general_settings.title_custom_status_5, value: 'custom-status-5' },
										{ label: this.props.general_settings.title_custom_status_6, value: 'custom-status-6' },
										{ label: this.props.general_settings.title_custom_status_7, value: 'custom-status-7' },
									]
								}
								value={this.state.status_2 ?? ''}
								onChange={(e) => {
									this.setState({
										status_2: e.currentTarget.value,
									});
								}} />
							{this.state.reservation.id_reservation == -1 ? null :
								<Button
									minimal={true}
									icon='document'
									text='Ponudba'
									onClick={this.openOfferDialog} />
							}
							{[ 'test', 'lodgge' ].indexOf(this.props.client) === -1 ? null :
								<Button
									minimal={true}
									icon='projects'
									text='Priprava računa'
									onClick={() => this.openInvoiceDialog()} />
							}
							{[ 'test', 'lodgge' ].indexOf(this.props.client) === -1 ? null :
								<Navbar.Group className='ml-12'>
									<Button minimal={true} icon='projects' text='Depozit' className='mr-2' />
									<Button minimal={true} icon='projects' text='Potrditev rezervacije' />
								</Navbar.Group>
							}
						</Navbar.Group>
						<Navbar.Group align={Alignment.RIGHT}>
							<Button minimal={true} icon='help' />
							<Button minimal={true} icon='cross' onClick={() => {
								this.closeDialog(false, true);
							}} />
						</Navbar.Group>
					</Navbar>
					<div className='flex flex-col flex-grow main-content'>
						<div className='h-1'>
							{this.state.loading == 0 ? null :
								<ProgressBar animate={true} intent='warning' className='h-1' />
							}
						</div>
						<div className='flex flex-row -mx-2 px-4 pt-4 pb-2'>
							<div className='flex-none px-2'>
								<div>
									<FormGroup
										className={
											this.state.validation_failed_fields.indexOf('check_in_out') == -1 ?
												'' : 'validation-failed'
										}
										labelFor='from-until-input'>
										<TextWithTooltip
											label='Obdobje'
											tooltip='Obdobje rezervacije' />
										<DateRangeInput3
											id='from-until-input'
											dateFnsLocaleLoader={loadDateFnsLocale}
											dateFnsFormat={'dd. MM. yyyy'}
											locale='sl'
											allowSingleDayRange={false}
											contiguousCalendarMonths={false}
											shortcuts={false}
											startInputProps={{ placeholder: 'Od', className: 'from-until', inputRef: (ref) => {
												this.startDateRef.current= ref;
											}}}
											endInputProps={{ placeholder: 'Do', className: 'from-until' }}
											minDate={new Date('1900-01-01')}
											maxDate={ this.getMaxDate() }
											selectAllOnFocus={true}
											onChange={(selectedRange) => {
												this.setState({
													check_in:  selectedRange[0] === null ? null : moment(selectedRange[0]).toDate(),
													check_out: selectedRange[1] === null ? null : moment(selectedRange[1]).toDate(),
												});
											}}
											value={[
												this.state.check_in,
												this.state.check_out,
											]} />
									</FormGroup>
								</div>
								<div className='flex flex-col'>
									<FormGroup
										className={
											'flex ' +
											(this.state.validation_failed_fields.indexOf('id_customer') == -1 ?
												'' : 'validation-failed')
										}>
										<CustomerSelect
											itemSelected={item => {
												this.setState({
													id_customer: item.id_customer,
													phone:       item.phone,
													email:       item.email,
												});
												
												if (this.phone_input_ref.current !== null) {
													this.phone_input_ref.current.focus();
												}
											}}
											addPersonClick={query => { this.openSelectCustomerDialog({
												name:        '',
												surname:     query,
												email:       '',
												id_country:  '',
												phone:       '',
												post_office: '',
												address:     '',
											}); }}
											fieldTitle='Nosilec' />
									</FormGroup>
									<div className='flex flex-row justify-between items-start'>
										{
											this.state.id_customer == null || this.state.id_customer == -1 ?
											null
											:
											(() => {
												const customer = this.props.customers[this.state.id_customer];
												if (customer === undefined) return null;
												
												const customer_data_line_1 = customer.type == 'natural' ?
													customer.surname + ' ' + customer.name :
													customer.company_name;
												
												const customer_data_line_2 = customer.address;
												
												const customer_data_line_3_arr = [];
												if (customer.post_code.length > 0 || customer.post_office.length > 0) {
													customer_data_line_3_arr.push(
														(
															customer.post_code +
															' ' +
															customer.post_office
														).trim()
													);
												}
												if (customer.id_country !== null) {
													customer_data_line_3_arr.push(
														this.props.countries[customer.id_country].iso_3166_1_a2
													);
												}
												
												const customer_data_line_3 = customer_data_line_3_arr.join(', ');
												
												return <>
													<div className='leading-relaxed'>
														<div className='font-medium'>{ customer_data_line_1 }</div>
														<div>{ customer_data_line_2 }</div>
														<div>{ customer_data_line_3 }</div>
													</div>
													<Button
														icon='edit'
														minimal={true}
														onClick={() => {
															this.openSelectCustomerDialog(
																{
																	name:        '',
																	surname:     '',
																	email:       '',
																	id_country:  '',
																	phone:       '',
																	post_office: '',
																	address:     '',
																},
																customer
															);
														}} />
												</>;
											})()
										}
									</div>
								</div>
							</div>
							<div className='flex-none px-2 w-1/3'>
								<div className='flex'>
									<div className='w-1/2 flex flex-col mr-4'>
										<FormGroup
											labelFor='phone-input'>
											<TextWithTooltip
												label='Telefon'
												tooltip='Vpišite telefon' />
											<InputGroup id='phone-input'
												value={this.state.phone}
												onChange={event => {
													this.setState({ phone: event.target.value });
												}}
												inputRef={this.phone_input_ref}
												disabled={this.state.id_customer === null || this.state.id_customer == -1} />
										</FormGroup>
									</div>
									<div className='w-1/2 flex flex-col mr-4'>
										<FormGroup
											labelFor='channel-input'
											className={
												this.state.validation_failed_fields.indexOf('id_channel') == -1 ?
													'' : 'validation-failed'
											}>
											<TextWithTooltip
												label='Prodajni kanal'
												tooltip='Prodajni kanal' />
											<HTMLSelect
												id='channel-input'
												fill={true}
												options={[ { label: 'Izberi prodajni kanal', value: -1 } ].concat(
													Object.values(this.props.channels).sort((a, b) => a.ord - b.ord).map(
														item => { return { label: item.title, value: item.id_channel }; }
													)
												)}
												value={this.state.id_channel}
												onChange={(e) => {
													this.setState({
														id_channel: e.currentTarget.value,
													});
												}} />
										</FormGroup>
									</div>
								</div>
								<div className='flex'>
									<div className='w-1/2 flex flex-col mr-4'>
										<FormGroup
											labelFor='email-input'>
											<TextWithTooltip
												label='E-pošta'
												tooltip='Vpišite e-poštni naslov' />
											<InputGroup id='email-input'
												value={this.state.email}
												onChange={event => {
													this.setState({ email: event.target.value });
												}}
												disabled={this.state.id_customer === null || this.state.id_customer == -1} />
										</FormGroup>
									</div>
									<div className='w-1/2 flex flex-col mr-4'>
										<FormGroup
											labelFor='market-segmentation'>
											<TextWithTooltip
												label='Tržni segment'
												tooltip='Tržni segment' />
											<HTMLSelect
												id='market-segmentation'
												fill={true}
												options={[ { label: 'Izberi tržni segment', value: -1 } ].concat(
													Object.values(this.props.market_segmentation).sort((a, b) => a.ord - b.ord).map(item => {
														return { label: item.title, value: item.id_market_segmentation };
													})
												)}
												value={this.state.id_market_segmentation}
												onChange={(e) => {
													this.setState({
														id_market_segmentation: e.currentTarget.value,
													});
												}} />
										</FormGroup>
									</div>
								</div>
								<div className='flex'>
									<div className='flex flex-col flex-grow mr-4'>
										<div className='flex flex-row content-start justify-between pt-3'>
											<PeopleCountInput
												adult_title={'Odrasli'}
												child_1_title={'0-' + this.props.general_settings.reservation_child_age_limit_1 + ' let'}
												child_2_title={this.props.general_settings.reservation_child_age_limit_1 + '-' + this.props.general_settings.reservation_child_age_limit_2 + ' let'}
												adult_count={this.state.guest_adult_count}
												child_1_count={this.state.guest_child_1_count}
												child_2_count={this.state.guest_child_2_count}
												onChangeAdultCount={val => {
													this.setState({ guest_adult_count: val });
												}}
												onChangeChild1Count={val => {
													this.setState({ guest_child_1_count: val });
												}}
												onChangeChild2Count={val => {
													this.setState({ guest_child_2_count: val });
												}}
												disabled={Object.keys(this.state.main_table_items).length > 0} />
										</div>
									</div>
								</div>
							</div>
							<div className='flex flex-col flex-1 px-2'>
								<Tabs
									className='flex flex-col flex-grow reservation-additional-info-tabs'
									id='reservation-additional-info-tabs'
									vertical={false}
									onChange={new_tab_id => {
										this.setState({
											additional_info_tab_id: new_tab_id,
										});
									}}
									selectedTabId={this.state.additional_info_tab_id}>
									<Tab id='comment' title='Opomba' panelClassName='flex-grow' panel={
										<TextArea className='table-full-h' fill={true}
											onChange={(e) => {
												this.setState({
													note: e.target.value,
												});
											}}
											value={this.state.note} />
									} />
									
									<Tab id='offers' title='Dokumenti' panelClassName='flex-grow h-40' panel={
										this.state.reservation === null || this.state.reservation.id_reservation === null ? null :
											<Documents
												id_document={this.state.reservation.id_reservation}
												document_type='reservation'
												deep={true} />
									} />
									
									{true ? false :
										<Tab id='invoices' title='Računi agencije' panelClassName='flex-grow' panel={
											<Text>Računi agencije</Text>
										} />
									}
									
									{true ? false :
										<Tab id='tax' title='Provizija' panelClassName='flex-grow' panel={
											<Text>Provizija</Text>
										} />
									}
									
									{true ? false :
										<Tab id='income-estimate' title='Ocena prihodka' panel={
											<Text>Ocena prihodka</Text>
										} />
									}
									
									{true ? false :
										<Tab id='agency' title='Agencija' panelClassName='flex-grow' panel={
											<Text>Agencija</Text>
										} />
									}
								</Tabs>
							</div>
						</div>
						<div className='flex flex-col mt-2 pt-2 pb-2 px-4 reservation-services'>
							{reservation_items_without_person_day.length == 0 ? null :
								<>
									<div>
										<h5 className='bp5-heading'>Storitve na rezervaciji</h5>
									</div>
									<div className='flex flex-row flex-wrap'>
										{reservation_items_without_person_day.map((item, idx) => {
											const id_item_price_list = this.state.check_in === null ? null :
												PriceHelper.FindBestPriceListMatch(moment(this.state.check_in), item.item.price_lists);
											const price              = id_item_price_list === null ? 0 : parseFloat(item.item.price_lists[id_item_price_list].price);
											
											return <Checkbox
												className='w-1/4'
												key={'reservation-item-' + idx}
												label={
													item.item.internal_code
													+ ' • ' +
													item.item.title
													+ ' • ' +
													price
														.toLocaleString(
															undefined,
															{minimumFractionDigits: 2, maximumFractionDigits: 2}
														)
													+ ' €'
												}
												checked={item.selected}
												disabled={item.mandatory}
												onChange={event => {
													if (item.mandatory) {
														return;
													}
													
													const reservation_items = this.state.reservation_items;
													reservation_items[idx].selected = event.target.checked;
													
													this.setState({ reservation_items });
												}} />;
										})}
									</div>
								</>
							}
						</div>
						<div className='flex flex-grow flex-row'>
							<div className='w-full flex flex-col px-4 pb-4 pt-2'>
								<div>
									<h5 className='bp5-heading'>
										Osebe na rezervaciji
									</h5>
									<div className='flex flex-row'>
										<Button
											icon='plus'
											intent='primary'
											className='mr-4'
											onClick={() => {
												if (
													this.state.main_table_items_sort_ids.length == 0 &&
													this.state.id_customer !== null &&
													this.props.customers[this.state.id_customer] !== undefined &&
													(
														this.props.customers[this.state.id_customer] !== undefined &&
														this.props.customers[this.state.id_customer].type == 'natural'
													)
												) {
													this.setState({ copy_customer_to_guest: true });
												}
												else {
													this.openEditReservationCustomerDialog(null);
												}
											}}
											elementRef={ref => {
												this.addPersonRef.current= ref;
											}} />
										<ButtonGroup
											className='mr-2'>
											<Button
												icon='tick'
												onClick={() => {
													this.setMainTableItemsChecked(true);
												}} />
											<Button
												icon='square'
												onClick={() => {
													this.setMainTableItemsChecked(false);
												}} />
										</ButtonGroup>
										
										<Button
											disabled={!any_checked_items || this.state.main_table_items_changed === true}
											className='mr-2'
											onClick={() => {
												this.openInvoiceDialog(
													this.state.main_table_items_checked,
													this.state.customer_items_customers_selected
												);
											}}>
											Izdelaj račun
										</Button>
										
										<ButtonGroup
											className='mr-2'>
											<Button disabled={!any_checked_items || !can_log_out_customers} onClick={() => {
												const state = {
													main_table_items: {...this.state.main_table_items},
													guest_book_items: {...this.state.guest_book_items},
													main_table_items_changed: true,
												};
												
												for (let i=0; i<this.state.main_table_items_checked.length; i++) {
													const id_reservation_customer = this.state.main_table_items_checked[i];
													
													const reservation_customer       = {...this.state.main_table_items[id_reservation_customer]};
													reservation_customer.check_out   = this.toIsoStringWithTime(new Date());
													reservation_customer.checked_out = true;
													
													state.main_table_items[reservation_customer.id_reservation_customer] = reservation_customer;
													
													if (reservation_customer.id_guest_book !== null) {
														const guest_book_item = {...
															state.guest_book_items[reservation_customer.id_guest_book] ||
															this.props.guest_book_items[reservation_customer.id_guest_book]
														};
														guest_book_item.etourism_status = 'waiting';
														state.guest_book_items[reservation_customer.id_guest_book] = guest_book_item;
													}
												}
												
												this.setState(state);
											}}>
												Odjavi s trenutnim datumom/časom
											</Button>
											<Button disabled={!any_checked_items || !can_log_out_customers} onClick={() => {
												const main_table_items = {...this.state.main_table_items};
												for (let i=0; i<this.state.main_table_items_checked.length; i++) {
													const id_reservation_customer = this.state.main_table_items_checked[i];
													
													const reservation_customer       = {...this.state.main_table_items[id_reservation_customer]};
													reservation_customer.checked_out = true;
													
													main_table_items[reservation_customer.id_reservation_customer] = reservation_customer;
												}
												
												this.setState({ main_table_items, main_table_items_changed: true });
												this.setMainTableItemsChecked(false);
											}}>
												Označi kot odjavljen
											</Button>
										</ButtonGroup>
										
										<Button disabled={!any_checked_items || this.state.main_table_items_changed === true} onClick={async () => {
											const dispatch = this.props.dispatch;
											const token    = this.props.token;
											
											const id_guest_books = [];
											for (let i=0; i<this.state.main_table_items_checked.length; i++) {
												const customer = this.props.reservation_customers[this.state.main_table_items_checked[i]];
												
												if (customer               === undefined) continue;
												if (customer.id_guest_book === null     ) continue;
												
												id_guest_books.push(customer.id_guest_book);
											}
											
											this.increaseLoading();
											await sendReservationCustomersToETourism(
												this.props.api_url,
												id_guest_books,
												'normal',
												token
											);
											await loadGuestBookItems(this.props.api_url, dispatch, token);
											this.decreaseLoading();
											
											this.setMainTableItemsChecked(false);
										}}>
											Pošlji na e-Turizem
										</Button>
										{!any_checked_items || this.state.main_table_items_changed === false ? null :
											<div className='pt-1 ml-2' style={{ color: '#db3737' }}>
												Spremenili ste podatke o gostih. Pred pošiljanjem shranite rezervacijo.
											</div>
										} 
										
										<div className='flex-1'></div>
										
										<Checkbox
											checked={this.state.guests_checked_in}
											className='mt-1 mb-0'
											onChange={event => this.setState({ guests_checked_in: event.target.checked })}>
											Gosti prijavljeni
										</Checkbox>
									</div>
								</div>
								<div className='flex-grow overflow-y-auto' style={{ flexBasis: '0' }}>
									<ReservationGuests
										main_table_items                ={this.state.main_table_items}
										main_table_items_sort_ids       ={this.state.main_table_items_sort_ids}
										main_table_items_sort_field     ={this.state.main_table_items_sort_field}
										main_table_items_sort_direction ={this.state.main_table_items_sort_direction}
										main_table_items_checked        ={this.state.main_table_items_checked}
										customers                       ={this.props.customers}
										countries                       ={this.props.countries}
										guest_book_items                ={this.props.guest_book_items}
										guest_book_items_state          ={this.state.guest_book_items}
										openDateTimePickerDialog        ={this.openDateTimePickerDialog}
										openInvoiceDialog               ={this.openInvoiceDialog}
										onCheckedItem                   ={this.setMainTableItemChecked}
										setMainTableItemsSort           ={this.setMainTableItemsSort}
										editItem                        ={id_reservation_customer => {
											this.openEditReservationCustomerDialog(
												this.state.main_table_items[id_reservation_customer]
											)
										}}
										deleteItem                       ={this.deleteMainTableItem}
										documents                        ={customer_documents}
										invoices                         ={this.props.invoices}
										openEditInvoiceDialog            ={this.openEditInvoiceDialog}
										openDocumentListDialog           ={this.openDocumentListDialog}
										getAge                           ={this.getAge}
										display_copy_guest_to_reservation_customer={this.state.id_customer === null || this.state.id_customer == -1}
										copyGuestToReservationCustomer   ={this.copyGuestToReservationCustomer} />
								</div>
							</div>
							<div className='flex flex-col' style={{ width: '35%' }}>
								<div className='px-4 pb-4 pt-2 flex flex-col flex-grow' style={{ backgroundColor: 'rgb(195, 210, 218)' }}>
									<h5 className='bp5-heading'>Dodatne storitve/artikli gosta</h5>
									
									<div className='flex-grow relative'>
										<div className='overflow-y-auto absolute inset-0 pr-2'>
										{this.state.main_table_items_sort_ids.map(id_reservation_customer => {
											const user = this.state.main_table_items[id_reservation_customer];
											
											return <div key={'services-user-' + user.id_customer} className='pl-1'>
												<div className='flex flex-row items-center'>
													<Checkbox
														checked={this.state.customer_items_customers_selected.indexOf(user.id_reservation_customer) != -1}
														onChange={event => {
															let ids = [...this.state.customer_items_customers_selected];
															
															if (event.target.checked === false) {
																ids.splice(ids.indexOf(user.id_reservation_customer), 1);
															}
															else {
																ids.push(user.id_reservation_customer);
															}
															
															this.setState({ customer_items_customers_selected: ids });
														}} />
													<h6 className='bp5-heading mt-2 flex-grow'>
														{ this.props.customers[user.id_customer] === undefined
															? ''
															: (this.props.customers[user.id_customer].surname + ' ' + this.props.customers[user.id_customer].name)
														}
													</h6>
													<Button
														icon='plus'
														minimal={false}
														small={true}
														onClick={() => {
															this.setState({
																add_edit_item_with_count_dialog_visible: true,
																add_edit_item_with_count_dialog_id_reservation_customer: user.id_reservation_customer,
																add_edit_item_with_count_dialog_quantity: 0,
																add_edit_item_with_count_dialog_id_item: null,
															});
														}} />
												</div>
												
												{this.state.customer_items.filter(item => item.id_reservation_customer == user.id_reservation_customer).map(item => {
													return <div
														key={'services-user-' + item.id_reservation_customer + '-' + item.id_item_price_list}
														className='flex flex-row'>
														<Button className='flex-grow' alignText='left' small={true} minimal={true} fill={true} onClick={() => {
															this.setState({
																add_edit_item_with_count_dialog_visible: true,
																add_edit_item_with_count_dialog_id_reservation_customer: item.id_reservation_customer,
																add_edit_item_with_count_dialog_quantity: item.quantity,
																add_edit_item_with_count_dialog_id_item: item.id_item,
															});
														}}>
															<Icon icon='edit' />
															<span className='ml-2'>
																<span className='mr-4 font-bold'>{
																	toLocal(parseFloat(item.quantity, 0)) +
																	'x'
																}</span>
																<span>{
																	this.props.items[item.id_item].internal_code +
																	' • ' +
																	this.props.items[item.id_item].title
																}</span>
															</span>
														</Button>
														<div className='w-20 text-right mr-2'>{
															toLocal(
																parseFloat(item.quantity) *
																parseFloat(this.props.items[item.id_item].price_lists[item.id_item_price_list].price),
															2) +
															' €'
														}</div>
														<Button
															icon='trash'
															intent='danger'
															minimal={true}
															small={true}
															onClick={() => {
																this.setState({
																	delete_request__id_reservation_customer: item.id_reservation_customer,
																	delete_request__id_item: item.id_item,
																});
															}} />
													</div>;
												})}
											</div>;
										})}
										</div>
									</div>
								</div>
								
								<div className='px-4 pb-4 pt-4 flex flex-col' style={{ backgroundColor: 'rgb(170, 191, 203)', borderRadius: '0px 0px 6px 0px' }}>
									<div className='flex flex-row font-bold'>
										<h5 className='bp5-heading'>Informativni izračun</h5>
										<div></div>
									</div>
									<div className='flex flex-row'>
										<div className='flex-grow'>Storitve na rezervaciji</div>
										<div>
											{
												parseFloat(calculated_reservation_price.accommodation_items_price)
													.toLocaleString(
														undefined,
														{minimumFractionDigits: 2, maximumFractionDigits: 2}
													)
												+ ' €'
											}
										</div>
									</div>
									<div className='flex flex-row'>
										<div className='flex-grow'>Dodatne storitve/artikli gosta</div>
										<div>
											{
												parseFloat(calculated_reservation_price.additional_customer_items_price)
													.toLocaleString(
														undefined,
														{minimumFractionDigits: 2, maximumFractionDigits: 2}
													)
												+ ' €'
											}
										</div>
									</div>
									<div className='flex flex-row'>
										<div className='flex-grow'>Turistična taksa</div>
										<div>
											{
												parseFloat(calculated_reservation_price.total_tourist_tax)
													.toLocaleString(
														undefined,
														{minimumFractionDigits: 2, maximumFractionDigits: 2}
													)
												+ ' €'
											}
										</div>
									</div>
									<div className='flex flex-row font-bold mt-2'>
										<div className='flex-grow'>Skupaj za plačilo</div>
										<div>
											{
												parseFloat(calculated_reservation_price.total)
													.toLocaleString(
														undefined,
														{minimumFractionDigits: 2, maximumFractionDigits: 2}
													)
												+ ' €'
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		);
	}
}
Reservation.propTypes = {
	closeReservation: PropTypes.func,
	deleteReservation: PropTypes.func,
	reservation: PropTypes.object,
};

function mapStateToProps(state) {
	return {
		channels:                                 state.CodeTablesSlice.channels,
		customers:                                state.CodeTablesSlice.customers,
		countries:                                state.CodeTablesSlice.countries,
		accommodations:                           state.CodeTablesSlice.accommodations,
		accommodation_items:                      state.CodeTablesSlice.accommodation_items,
		accommodation_item_places:                state.CodeTablesSlice.accommodation_item_places,
		accommodation_item_place_items:           state.CodeTablesSlice.accommodation_item_place_items,
		market_segmentation:                      state.CodeTablesSlice.market_segmentation,
		measuring_units:                          state.CodeTablesSlice.measuring_units,
		items:                                    state.CodeTablesSlice.items,
		tax_rates:                                state.CodeTablesSlice.tax_rates,
		tourist_taxes:                            state.CodeTablesSlice.tourist_taxes,
		reservations:                             state.ReservationSlice.reservations,
		reservation_customers:                    state.ReservationSlice.reservation_customers,
		reservation_customers_by_id_reservations: state.ReservationSlice.reservation_customers_by_id_reservations,
		reservation_customer_items:               state.ReservationSlice.reservation_customer_items,
		api_url:                                  state.CodeTablesSlice.api_url,
		token:                                    state.UserSlice.token,
		user:                                     state.UserSlice.user,
		client:                                   state.ConstantsSlice.client,
		invoices:                                 state.BusinessSlice.invoices,
		guest_book_items:                         state.GuestSlice.guest_book_items,
		document_associations:                    state.DocumentSlice.document_associations_id_documents,
		document_types:                           state.DocumentSlice.document_types,
		general_settings:                         state.SettingsSlice.general,
		current_id_cash_register:                 state.SettingsSlice.current_id_cash_register,
		cash_registers:                           state.CashRegisterSlice.cash_registers,
		warehouses:                               state.CodeTablesSlice.warehouses,
	};
}

export default connect(mapStateToProps)(Reservation);
