import moment from 'moment';

const PriceHelper = {
	FindBestPriceListMatch: (date, price_lists) => {
		// find active (i.e. valid_until is null or larger than date) price list
		//   with most current valid_from (but not larger than date)
		let target_date = moment(date).format('YYYY-MM-DD');
		let curr_valid_from         = null;
		let curr_id_item_price_list = null;
		for (let id_item_price_list in price_lists) {
			const valid_from  = price_lists[id_item_price_list].valid_from;
			const valid_until = price_lists[id_item_price_list].valid_until;
			
			if (
				valid_from <= target_date &&
				(valid_until     === null || valid_until >= target_date) &&
				(curr_valid_from === null || valid_from  >= curr_valid_from)
			) {
				curr_valid_from         = valid_from;
				curr_id_item_price_list = id_item_price_list;
			}
		}
		
		return curr_id_item_price_list;
	},
	// when changing this function, change it in Reservations.php as well
	FindBestUserWorkTypeMatch: (date, id_user, id_work_type, user_work_types) => {
		// find active (i.e. valid_until is null or larger than date) item
		//   with most current valid_from (but not larger than date)
		let target_date            = moment(date).format('YYYY-MM-DD');
		let curr_valid_from        = null;
		let curr_id_user_work_type = null;
		for (let id_user_work_type in user_work_types) {
			const item = user_work_types[id_user_work_type];
			
			const valid_from  = item.valid_from;
			const valid_until = item.valid_until;
			
			if (item.id_user != id_user || item.id_work_type != id_work_type) {
				continue;
			}
			
			if (
				valid_from <= target_date &&
				(valid_until     === null || valid_until >= target_date) &&
				(curr_valid_from === null || valid_from  >= curr_valid_from)
			) {
				curr_valid_from        = valid_from;
				curr_id_user_work_type = id_user_work_type;
			}
		}
		
		return curr_id_user_work_type;
	},
	// when changing this function, change it in Reservations.php as well
	FindBestUserDailyLogbookActivityMatch: (date, id_user, id_daily_logbook_activity, user_daily_logbook_activities) => {
		// find active (i.e. valid_until is null or larger than date) item
		//   with most current valid_from (but not larger than date)
		let target_date                         = moment(date).format('YYYY-MM-DD');
		let curr_valid_from                     = null;
		let curr_id_user_daily_logbook_activity = null;
		for (let id_user_daily_logbook_activity in user_daily_logbook_activities) {
			const item = user_daily_logbook_activities[id_user_daily_logbook_activity];
			
			const valid_from  = item.valid_from;
			const valid_until = item.valid_until;
			
			if (item.id_user != id_user || item.id_daily_logbook_activity != id_daily_logbook_activity) {
				continue;
			}
			
			if (
				valid_from <= target_date &&
				(valid_until     === null || valid_until >= target_date) &&
				(curr_valid_from === null || valid_from  >= curr_valid_from)
			) {
				curr_valid_from        = valid_from;
				curr_id_user_daily_logbook_activity = id_user_daily_logbook_activity;
			}
		}
		
		return curr_id_user_daily_logbook_activity;
	},
};

export default PriceHelper;
