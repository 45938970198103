import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	Alignment,
	Button,
	Classes,
	Dialog,
	FormGroup,
	InputGroup,
	Intent,
	Navbar,
	NumericInput,
	Checkbox,
} from '@blueprintjs/core';

import TextWithTooltip from '../TextWithTooltip';
import { AppToaster }  from '../AppToaster';

class AddEditMeasuringUnit extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			id_measuring_unit:   props.item === null ? -1 : props.item.id_measuring_unit,
			title:               props.item === null ? '' : props.item.title,
			decimal_digit_count: props.item === null ? 0  : props.item.decimal_digit_count,
			ord:                 props.item === null ? 1    : props.item.ord,
			visible:             props.item === null ? true : props.item.visible,
			validation_failed_fields: [],
		};
	}
	
	render() {
		return <Dialog
			isOpen={true}
			usePortal={true}
			canOutsideClickClose={true}
			canEscapeKeyClose={true}
			onClose={() => this.props.closeAddEditItemDialog()}
			className='add-edit-measuring-unit-dialog'>
			<div className='flex flex-col flex-grow'>
				<Navbar fixedToTop={false} className='bp5-dark'>
					<Navbar.Group>
						<Navbar.Heading>Dodaj / uredi merilno enoto</Navbar.Heading>
					</Navbar.Group>
					<Navbar.Group align={Alignment.RIGHT}>
						<Button minimal={true} icon='cross' onClick={() => this.props.closeAddEditItemDialog()} />
					</Navbar.Group>
				</Navbar>
				<div className={Classes.DIALOG_BODY}>
					<div className='flex flex-1 flex-col'>
						<FormGroup
							labelFor='add-edit-measuring-unit-dialog--visible'
							className='flex-1'>
							<TextWithTooltip
								label='Aktiven'
								tooltip='Aktiven' />
							<Checkbox checked={this.state.visible} onChange={event => {
								this.setState({ visible: event.target.checked });
							}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-measuring-unit-dialog--title'
							className={
								'flex-1 ' +
								(this.state.validation_failed_fields.indexOf('title') == -1 ?
									'' : 'validation-failed')
							}>
							<TextWithTooltip
								label='Naziv'
								tooltip='Naziv' />
							<InputGroup
								id='add-edit-measuring-unit-dialog--title'
								value={this.state.title}
								onChange={event => {
									this.setState({
										title: event.target.value,
									});
								}} />
						</FormGroup>
						
						<FormGroup
							labelFor='add-edit-measuring-unit-dialog--decimal-digit-count'
							className='flex-1'>
							<TextWithTooltip
								label='Število decimalk'
								tooltip='Število decimalk' />
							<NumericInput
								id='add-edit-measuring-unit-dialog--decimal-digit-count'
								value={this.state.decimal_digit_count}
								min={0}
								minorStepSize={1}
								onValueChange={val => {
									this.setState({
										decimal_digit_count: val,
									});
								}} />
						</FormGroup>
						<FormGroup
							labelFor='add-edit-measuring-unit-dialog--ord'
							className='flex-1'>
							<TextWithTooltip
								label='Vrstni red'
								tooltip='Vrstni red' />
							<NumericInput
								id='add-edit-measuring-unit-dialog--ord'
								value={this.state.ord}
								min={0}
								minorStepSize={1}
								onValueChange={val => {
									this.setState({
										ord: val,
									});
								}} />
						</FormGroup>
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							minimal={true}
							onClick={() => this.props.closeAddEditItemDialog()}>
							Prekliči
						</Button>
						<Button
							intent={Intent.PRIMARY}
							onClick={() => {
								const new_item = {
									id_measuring_unit:   this.state.id_measuring_unit,
									title:               this.state.title,
									decimal_digit_count: this.state.decimal_digit_count,
									ord:                 this.state.ord,
									serial_internal_code: this.props.item === null ? null : this.props.item.serial_internal_code,
									visible:             this.state.visible,
								};
								
								// validate missing/empty fields
								const validation_failed_fields       = [];
								const validation_failed_field_titles = [];
								
								if (new_item.title.length == 0) {
									validation_failed_fields.push('title');
									validation_failed_field_titles.push('naziv');
								}
								if (new_item.decimal_digit_count.length == 0) {
									validation_failed_fields.push('decimal_digit_count');
									validation_failed_field_titles.push('število decimalk');
								}
								
								this.setState({ validation_failed_fields });
								
								if (validation_failed_fields.length > 0) {
									AppToaster.show({
										message: <div>
											Za shranjevanje, izpolnite vsaj polja:
											<br />
											{validation_failed_field_titles.map(title => <div key={title}>{'• ' + title}</div>)}
										</div>,
										intent: 'danger',
										icon: 'issue'
									});
									return;
								}
								
								// save item
								this.props.closeAddEditItemDialog(new_item);
							}}>
							Shrani in zapri
						</Button>
					</div>
				</div>
			</div>
		</Dialog>;
	}
}
AddEditMeasuringUnit.propTypes = {
	closeAddEditItemDialog: PropTypes.func,
	item: PropTypes.object,
};

export default AddEditMeasuringUnit;
