import React, { useFfect } from 'react';

import moment from 'moment';

import PrintHelper   from '../../helpers/Print';
import InvoiceHelper from '../../helpers/Invoice';
import T             from '../../helpers/Translation';

import {
	CustomerDetails,
} from './Common';

function formatNumber(val, decimal_digit_count) {
	decimal_digit_count = decimal_digit_count === undefined ? 2 : decimal_digit_count;
	
	let rounded_val = Math.properRound(parseFloat(val), decimal_digit_count);
	
	return rounded_val
		.toLocaleString(
			undefined,
			{
				minimumFractionDigits: decimal_digit_count,
				maximumFractionDigits: decimal_digit_count,
			}
		);
}

function CompanyDetails(props) {
	return <div className='print-acquisition-company-details'>
		<div>{props.company_data.subject_long_name}</div>
		<div>{
			[
				props.company_data.subject_address,
				props.company_data.subject_post_code + ' ' + props.company_data.subject_city,
			]
				.filter(x => x !== null && x.length > 0)
				.join(', ')
		}</div>
		<div>{
			[
				props.company_data.subject_phone_1 === null || props.company_data.subject_phone_1.length == 0 ? null :
					'T: ' + props.company_data.subject_phone_1,
				props.company_data.subject_phone_2 === null || props.company_data.subject_phone_2.length == 0 ? null :
					'M: ' + props.company_data.subject_phone_2,
			]
				.filter(x => x !== null && x.length > 0)
				.join(', ')
		}</div>
	</div>;
}

function AcquisitionDetails(props) {
	const book_date          = props.item.book_date          === null ? null : moment(props.item.book_date);
	const delivery_note_date = props.item.delivery_note_date === null ? null : moment(props.item.delivery_note_date);
	const warehouse          = props.warehouses[props.item.id_warehouse];
	
	const type_titles = {
		'acquisition':       'Prevzem',
		'write-off':         'Odpis zaloge',
		'reclamation':       'Reklamacija',
		'representation':    'Reprezentanca',
		'commission':        'Komisija',
		'debt-release':      'Razdolžnica',
		'internal-transfer': 'Prenos med skladišči',
	};
	let type_title = type_titles[props.item.type];
	
	return <div className='print-acquisition-acquisition-details'>
		<div style={{ gridColumn: '1 / 3' }} className='font-large-6 deemphasized mb-05-line'>
			PREVZEM
		</div>
		
		<div className='emphasized mb-05-line font-large-1'>Številka:</div>
		<div className='emphasized mb-05-line font-large-1'>{props.item.document_number}</div>
		
		<div>Datum prevzema:</div>
		<div>{delivery_note_date === null ? '' : delivery_note_date.format('DD. MM. YYYY')}</div>
		
		<div>Datum knjiženja:</div>
		<div>{book_date === null ? '' : book_date.format('DD. MM. YYYY')}</div>
		
		<div>Številka dobavnice:</div>
		<div>{props.item.delivery_note_number}</div>
		
		<div>Skladišče:</div>
		<div>{warehouse.title}</div>
		
		<div>Vrsta prevzema:</div>
		<div>{type_title}</div>
	</div>;
}

function ItemsTable(props) {
	let items = [];
	
	items.push([
		'Šifra • EAN • Naziv',
	]);
	items.push([
		'Kol.',
		'NC\nbrez DDV',
		'Nabavni\nDDV (%)',
		'NC\nz DDV',
		'Popust\n(%)',
		'NC s pop.\nbrez DDV',
		'NC s pop.\nz DDV',
		'Znesek\nbrez DDV',
		'Znesek\nz DDV',
		'Prodajni\nDDV (%)',
		'PC\nz DDV',
	]);
	
	const item_items = [...props.item.items];
	/*item_items.sort((a, b) => {
		if      (a.ord < b.ord) return -1;
		else if (a.ord > b.ord) return  1;
		return 0;
	});*/
	
	for (let i=0; i<item_items.length; i++) {
		const item      = item_items[i];
		const item_item = props.items[item.id_item];
		
		const discount         = item.purchase_discount;
		const quantity         = item.quantity;
		
		const purchase_price_without_vat = item.purchase_price_without_vat;
		const purchase_vat               = item.purchase_id_tax_rate === null || item.purchase_id_tax_rate == -1 ? 0 : props.tax_rates[item.purchase_id_tax_rate].value;
		
		let vat = item.id_tax_rate === null || item.id_tax_rate == -1 ? 0 : props.tax_rates[item.id_tax_rate].value;
		
		console.log({ subitem: item });
		items.push([
			item_item.internal_code + ' • ' + (item_item.ean.length == 0 ? '' : (item_item.ean + ' • ')) + item_item.title,
		]);
		items.push([
			formatNumber(quantity),
			formatNumber(purchase_price_without_vat, 4),
			formatNumber(purchase_vat),
			formatNumber(purchase_price_without_vat * (1 + purchase_vat / 100), 4),
			formatNumber(discount),
			formatNumber(purchase_price_without_vat * (1 - discount / 100), 4),
			formatNumber(purchase_price_without_vat * (1 - discount / 100) * (1 + purchase_vat / 100), 4),
			formatNumber(purchase_price_without_vat * quantity * (1 - discount / 100)),
			formatNumber(purchase_price_without_vat * quantity * (1 - discount / 100) * (1 + purchase_vat / 100)),
			formatNumber(vat),
			formatNumber(item.price),
		]);
	}
	
	return <div className='print-acquisition-items-table-container'>
		<div className='print-acquisition-items-table'>
			{items.map((item, idx) => {
				const cls = idx <= 1 ?
					'font-small print-acquisition-table-header-cell header-cell'
					:
					'font-small print-acquisition-table-cell';
				
				if (item.length == 1) {
					return <React.Fragment key={'item-' + idx}>
						<div className={
							idx <= 1 ? cls : 'emphasized ' + cls
						} style={{ gridColumn: '1 / 12' }}>{item[0]}</div>
					</React.Fragment>;
				}
				if (idx <= 1) {
					return <React.Fragment key={'item-' + idx}>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap', paddingLeft: '0.3cm' }} dangerouslySetInnerHTML={{__html: item[ 0].replaceAll('\n', '<br />')}}></div>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html: item[ 1].replaceAll('\n', '<br />')}}></div>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html: item[ 2].replaceAll('\n', '<br />')}}></div>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html: item[ 3].replaceAll('\n', '<br />')}}></div>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html: item[ 4].replaceAll('\n', '<br />')}}></div>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html: item[ 5].replaceAll('\n', '<br />')}}></div>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html: item[ 6].replaceAll('\n', '<br />')}}></div>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html: item[ 7].replaceAll('\n', '<br />')}}></div>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html: item[ 8].replaceAll('\n', '<br />')}}></div>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html: item[ 9].replaceAll('\n', '<br />')}}></div>
						<div className={cls + ' text-right'} style={{ whiteSpace: 'nowrap' }} dangerouslySetInnerHTML={{__html: item[10].replaceAll('\n', '<br />')}}></div>
					</React.Fragment>;
				}
				return <React.Fragment key={'item-' + idx}>
					<div className={cls + ' text-right pb-02'} style={{ paddingLeft: '0.3cm' }}>{ item[ 0] }</div>
					<div className={cls + ' text-right pb-02'}>{ item[ 1] }</div>
					<div className={cls + ' text-right pb-02'}>{ item[ 2] }</div>
					<div className={cls + ' text-right pb-02'}>{ item[ 3] }</div>
					<div className={cls + ' text-right pb-02'}>{ item[ 4] }</div>
					<div className={cls + ' text-right pb-02'}>{ item[ 5] }</div>
					<div className={cls + ' text-right pb-02'}>{ item[ 6] }</div>
					<div className={cls + ' text-right pb-02'}>{ item[ 7] }</div>
					<div className={cls + ' text-right pb-02'}>{ item[ 8] }</div>
					<div className={cls + ' text-right pb-02'}>{ item[ 9] }</div>
					<div className={cls + ' text-right pb-02'}>{ item[10] }</div>
				</React.Fragment>;
			})}
		</div>
	</div>;
}

function getSums(items, tax_rates) {
	const sums = {
		purchase_price_without_vat: 0,
		purchase_price_with_vat:    0,
		discount:                   0,
		quantity:                   0,
		count:                      0,
	};
	
	for (let i=0; i<items.length; i++) {
		const item = items[i];
		
		const purchase_vat = item.purchase_id_tax_rate === null || item.purchase_id_tax_rate == -1 ? 0 : tax_rates[item.purchase_id_tax_rate].value;
		
		sums.purchase_price_without_vat += parseFloat(item.purchase_price_without_vat) * parseFloat(item.quantity);
		sums.purchase_price_with_vat    += parseFloat(item.purchase_price_without_vat) * parseFloat(item.quantity) * (1 + parseFloat(purchase_vat) / 100);
		sums.discount                   += parseFloat(item.purchase_price_without_vat) * parseFloat(item.quantity) * (parseFloat(item.purchase_discount) / 100);
		sums.quantity                   += parseFloat(item.quantity);
		sums.count                      += 1;
	}
	
	return sums;
}

function Sums(props) {
	const sums = getSums(props.items, props.tax_rates);
	
	let print_items = [
		[
			sums.discount == 0 ? '' : 'Znesek popusta EUR',
			sums.discount == 0 ? '' : formatNumber(sums.discount, 2),
			false,
			'Znesek brez DDV EUR',
			formatNumber(sums.purchase_price_without_vat, 2),
			true,
		],
		[ '', '', false, 'Znesek davka EUR', formatNumber(sums.purchase_price_with_vat - sums.purchase_price_without_vat, 2), false ],
		[ '', '', false, 'Znesek z DDV EUR', formatNumber(sums.purchase_price_with_vat,                                   2), true  ],
	];
	
	return <div className='print-acquisition-sums'>
		{print_items.map((item, idx) => {
			if (item === null) return null;
			
			let cls_1 = 'text-right ' + (item[2] ? 'emphasized' : '');
			let cls_2 = 'text-right ' + (item[5] ? 'emphasized' : '');
			
			return <React.Fragment key={'sum-' + idx}>
				<div></div>
				<div className={cls_1}>{item[0]}</div>
				<div className={cls_1}>{item[1]}</div>
				<div></div>
				<div className={cls_2}>{item[3]}</div>
				<div className={cls_2}>{item[4]}</div>
			</React.Fragment>;
		})}
	</div>;
}

function Counts(props) {
	const sums = getSums(props.items, props.tax_rates);
	
	return <div className='print-acquisition-counts'>
		<div>Število vrstic:</div>
		<div className={'text-right'}>{ formatNumber(sums.count, 0) }</div>
		<div></div>
		
		<div>Skupna količina:</div>
		<div className={'text-right'}>{ formatNumber(sums.quantity, 0) }</div>
		<div></div>
	</div>;
}

function Note(props) {
	return <div className='print-acquisition-note'>
		{ props.item.note.split('\n').map((line, idx) => <div key={'line-' + idx}>{ line }</div>) }
	</div>;
}

export default function Acquisition(props) {
	const {
		item,
		items,
		tax_rates,
		warehouses,
		customers,
		countries,
		company_data,
	} = props;
	
	let cls = 'print-document acquisition';
	
	const supplier         = customers[item.supplier_id_customer];
	const supplier_country = supplier.id_country === null ? null : countries[supplier.id_country];
	
	return <div className={cls}>
		<div className='print-acquisition-main'>
			<CompanyDetails
				company_data={company_data} />
			<CustomerDetails
				lang={'sl'}
				item={{
					customer_name:                                  supplier.name,
					customer_surname:                               supplier.surname,
					customer_type:                                  supplier.type,
					customer_display_company_long_name_on_invoices: supplier.display_company_long_name_on_invoices,
					customer_company_long_name:                     supplier.company_long_name,
					customer_company_name:                          supplier.company_name,
					customer_address:                               supplier.address,
					customer_post_code:                             supplier.post_code,
					customer_post_office:                           supplier.post_office,
					customer_vat_registered:                        supplier.vat_registered,
					customer_vat_prefix:                            supplier.vat_prefix,
					customer_tax_number:                            supplier.tax_number,
					customer_internal_code:                         supplier.internal_code,
				}}
				customer_country={supplier_country} />
			<AcquisitionDetails
				item={item}
				warehouses={warehouses} />
			<Counts items={item.items} tax_rates={tax_rates} />
			<Sums items={item.items} tax_rates={tax_rates} />
			<ItemsTable item={item} tax_rates={tax_rates} items={items} />
			
			<Note item={item} />
		</div>
	</div>;
}
